import styled from 'styled-components';

const UploadLabel = styled.div`
    height: 100px;
    display: flex;
    align-items: center;
    font-size: 14px;
    opacity: 0.5;
`;

const List = styled.div`
    margin-top: 25px;
`;

const Image = styled.img`
    max-width: 100%;
    margin-bottom: 15px;
    display: block;
`;

const Remove = styled.div`
    width: 24px;
    height: 24px;
    border-radius: 50%;
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #fff;
    font-size: 9px;
    color: red;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    opacity: 0;
`;

const Item = styled.div`
    position: relative;
    &:hover {
        ${Remove} {
            opacity: 1;
        }
    }
`;

export const CMSUploadHeroImagesSC = {
    UploadLabel,
    List,
    Image,
    Item,
    Remove,
};
