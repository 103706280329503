import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { NotFoundPage } from 'units/common/pages/not-found/not-found.component';
import { AccountPage } from 'units/settings/pages/account/account.component';
import { BillingPage } from 'units/settings/pages/billing/billing.component';
//import { DomainPage } from 'units/settings/pages/domain/domain.component';
//import { SettingsIndexPage } from 'units/settings/pages/index/settings.component';
import { LanguagePage } from 'units/settings/pages/language/language.component';
//import { PaymentPage } from 'units/settings/pages/payment/payment.component';
import { EmailTemplatesRoutes } from 'units/settings/pages/templates/routes';
//import { ThirdPartyPage } from 'units/settings/pages/third-party/third-party.component';
import { SettingsUserRoutes } from 'units/settings/pages/users/routes';

import { AppUrls } from 'shared/appUrls';

const SettingsPageLazy = React.lazy(() => import('pages').then(m => ({ default: m.SettingsPage })));
const PaymentIntegrationPageLazy = React.lazy(() => import('pages').then(m => ({ default: m.PaymentIntegrationPage })));
const CustomDomainPageLazy = React.lazy(() => import('pages').then(m => ({ default: m.CustomDomainPage })));
const ThirdPartyPageLazy = React.lazy(() => import('pages').then(m => ({ default: m.ThirdPartyPage })));

export const SettingsRouter = () => (
    <Switch>
      <Route exact path={AppUrls.settings.index} component={SettingsPageLazy} />
      <Route path={AppUrls.settings.payment} component={PaymentIntegrationPageLazy} />
      <Route path={AppUrls.settings.domain} component={CustomDomainPageLazy} />
      <Route path={AppUrls.settings.thirdParty} component={ThirdPartyPageLazy} />
      <Route path={AppUrls.settings.emailTemplates.index} component={EmailTemplatesRoutes} />
      <Route path={AppUrls.settings.account} component={AccountPage} />
      <Route path={AppUrls.settings.users.index} component={SettingsUserRoutes} />
      <Route path={AppUrls.settings.billing} component={BillingPage} />
      <Route path={AppUrls.settings.languages} component={LanguagePage} />
      <Route component={NotFoundPage} />
    </Switch>
);
