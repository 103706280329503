import { useDispatch } from 'react-redux';
import { useState, useCallback } from 'react';
import { AxiosError } from 'axios';

import { addIsFetching, removeIsFetching } from 'units/app/redux/action-creators';
import { AsyncAppThunk } from 'redux/root-types';

export interface AppPispatchOptions {
    fetchingKey?: string;
    handleError?: (error: AxiosError) => void;
}

// Wrap redux dispatch with isFetching and basic error handling logic.
export const useAsyncDispatch = (options?: AppPispatchOptions) => {
    const dispatch = useDispatch();
    const [isFetching, setFetching] = useState(false);

    const fetchDispatch = useCallback(
        async (action: AsyncAppThunk) => {
            setFetching(true);

            // Add fetching key to global store.
            if (options?.fetchingKey) {
                dispatch(addIsFetching(options.fetchingKey));
            }

            try {
                // Waiting for promise from async action to be resolved.
                await dispatch(action);
            } catch (error) {
                if (options?.handleError) {
                    options.handleError(error);
                }
            } finally {
                setFetching(false);

                // Remove fetching key from global store.
                if (options?.fetchingKey) {
                    dispatch(removeIsFetching(options.fetchingKey));
                }
            }
        },
        [dispatch],
    );

    return {
        dispatch: fetchDispatch,
        isFetching,
    };
};
