import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';

import { SecondaryButton } from 'units/common/components/buttons/secondary/secondary.component';
import { getImage, ImageName } from 'components/styles/images';
import { LayoutSC } from 'units/common/components/layout/layout-components.styles';
import { ProductsUrls } from '../../urls';
import { ProductList } from './product-list/product-list.component';
import { Product } from '../../api/types';

import { getProducts, deleteProduct } from '../../api/products-service';
import { AppStrings } from 'config/strings';
import { ConfirmModal } from 'units/common/components/modal/confirm-modal/confirm-modal.component';
import { useStateToggle } from 'units/common/hooks/useStateToggle';
import { NotificationContext } from 'shared/providers';
import { Preloader } from 'shared/components/ui';

export const ProductsPage = () => {
    const { showNotification } = useContext(NotificationContext);
    const history = useHistory();

    const addNewProductClickHandler = useCallback(() => history.push(ProductsUrls.addProduct), [
        history,
    ]);
    const [products, setProducts] = useState<Array<Product>>([]);
    const [productCount, setProductCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [paseSize, setPageSize] = useState(0);
    const [search, setSearch] = useState('');
    const [productForDetetion, setDeletionProduct] = useState<Product>();
    const [isDeleteProductModalOpen, deleteProductModal] = useStateToggle(false);
    const [isLoading, setLoading] = useState(true);
    const [isDataExist, setDataExist] = useState<boolean>(false);

    useEffect(() => {
        fetchProducts(search === '');
    }, [currentPage, search]);

    const fetchProducts = async (onLoad = false) => {
        try {
            const { data, pageSize, productCount } = await getProducts({
                page: currentPage,
                search,
            });
            if (onLoad) {
                setDataExist(productCount > 0);
            }
            setProducts(data);
            setPageSize(pageSize);
            setProductCount(productCount);
        } catch (error) {
            showNotification('error', AppStrings.errorNotification);
        }
        setLoading(false);
    };

    const openDeleteModal = (id: string) => {
        setDeletionProduct(products.find(item => item.id === id));
        deleteProductModal.turnIn();
    };

    const confirmDeteleProduct = async () => {
        try {
            if (productForDetetion && productForDetetion.id) {
                deleteProductModal.turnOff();
                await deleteProduct(productForDetetion.id);
                await fetchProducts(search === '');
                showNotification('success', `${productForDetetion.name} successfully deleted.`);
            }
        } catch (error) {
            showNotification('error', AppStrings.errorNotification);
        }
    };

    return (
        <>
            <Helmet>
                <title>Products</title>
            </Helmet>
            <LayoutSC.PageTitleContainer>
                <h1>Products</h1>
                <SecondaryButton isWide onClick={addNewProductClickHandler}>
                    + Add New Product
                </SecondaryButton>
            </LayoutSC.PageTitleContainer>

            {isLoading ? (
                <Preloader.Page height={480} />
            ) : (
                <>
                    {!isDataExist ? (
                        <LayoutSC.NoItemsContainer>
                            <div>{getImage(ImageName.no_products)}</div>
                            <p>There are no products added yet. You can change it!</p>
                        </LayoutSC.NoItemsContainer>
                    ) : (
                        <ProductList
                            data={products}
                            paseSize={paseSize}
                            productCount={productCount}
                            setCurrentPage={setCurrentPage}
                            setSearch={setSearch}
                            deleteProduct={openDeleteModal}
                            search={search}
                        />
                    )}
                    <ConfirmModal
                        isOpen={isDeleteProductModalOpen}
                        title={`Are you sure you want to delete ${productForDetetion?.name}?`}
                        subtitle="This action can not be undone."
                        buttonTitle={{ confirm: 'Delete', refuse: 'Cancel' }}
                        confirmHandler={confirmDeteleProduct}
                        refuseHandler={deleteProductModal.turnOff}
                        onCloseModal={deleteProductModal.turnOff}
                    />
                </>
            )}
        </>
    );
};
