import { yup } from 'config/yup';
import { CommonVM } from 'units///common/constants';

// Homepage validation messages
const hpVM = {
    hero: {
        // conversation sticker
        cs: {
            icon: {
                required: 'Icon is required when sticker is active',
            },
        },
    },
    usp: {
        icon: {
            required: 'Requred when section is active',
        },
    },
    // text block
    tb: {
        // text link
        tl: {
            required: (name: string) => `${name} required when link is active`,
        },
    },
    footer: {
        // social links
        sl: {
            required: (name: string) => `${name} required when link is active`,
            mathes: (name: string) => `Url doesn't match ${name} link`,
        },
    },
};

const InstagramRegex = /(https?:\/\/(?:www\.)?instagram\.com\/([^/?#&]+)).*/;
const FacebookRegex = /(https?:\/\/(?:www\.)?(facebook|fb)\.com\/([^/?#&]+)).*/;
const TiktokRegex = /(https?:\/\/(?:www\.)?tiktok\.com\/([^/?#&]+)).*/;
const LinkedinRegex = /(https?:\/\/(?:www\.)?linkedin\.com\/([^/?#&]+)).*/;
const TwitterRegex = /(https?:\/\/(?:www\.)?twitter\.com\/([^/?#&]+)).*/;
const SnapchatRegex = /(https?:\/\/(?:www\.)?snapchat\.com\/([^/?#&]+)).*/;

const YupSocialLink = (name: string, regex: RegExp) =>
    yup.object({
        isActive: yup.boolean(),
        url: yup.string().when('isActive', {
            is: v => v,
            then: yup
                .string()
                .required(hpVM.footer.sl.required(name))
                .url(CommonVM.url(name))
                .matches(regex, hpVM.footer.sl.mathes(name)),
        }),
    });

export const CMSCustomizationHomePageValidationSchema = yup.object({
    hero: yup.object({
        CTAcopy: yup.string().max(25, CommonVM.maxSymbols(25)),
        // .required(CommonVM.required('CTA copy')),
        timerTitle: yup.string().max(15, CommonVM.maxSymbols(15)),
        conversationSticker: yup.object({
            isActive: yup.boolean(),
            icon: yup.mixed().when('isActive', {
                is: v => v,
                then: yup.mixed(),
                // .required(hpVM.hero.cs.icon.required).nullable(false),
            }),
        }),
    }),
    usp: yup.object({
        isActive: yup.boolean(),
        usp1: yup.object().when('isActive', {
            is: v => v,
            then: yup.object({
                icon: yup.mixed(),
                // .required(hpVM.usp.icon.required),
                title: yup.string().max(40, CommonVM.maxSymbols(40)),
                // .required(CommonVM.required('USP 1 title')),
                description: yup.string().max(200, CommonVM.maxSymbols(200)),
            }),
        }),
        usp2: yup.object().when('isActive', {
            is: v => v,
            then: yup.object({
                icon: yup.mixed().required(hpVM.usp.icon.required),
                title: yup.string().max(40, CommonVM.maxSymbols(40)),
                // .required(CommonVM.required('USP 2 title')),
                description: yup.string().max(200, CommonVM.maxSymbols(200)),
            }),
        }),
        usp3: yup.object().when('isActive', {
            is: v => v,
            then: yup.object({
                icon: yup.mixed().required(hpVM.usp.icon.required),
                title: yup.string().max(40, CommonVM.maxSymbols(40)),
                // .required(CommonVM.required('USP 3 title')),
                description: yup.string().max(200, CommonVM.maxSymbols(200)),
            }),
        }),
    }),
    textBlock: yup.object({
        isActive: yup.boolean(),
        renderMode: yup.string().oneOf(['picture', 'title']),
        picture: yup.mixed().when('isActive', {
            is: v => v,
            then: yup.mixed().when('renderMode', {
                is: rm => rm === 'picture',
                then: yup.mixed(),
                // .required(CommonVM.required('Picture')),
            }),
        }),
        title: yup.string().when('isActive', {
            is: v => v,
            then: yup.string().when('renderMode', {
                is: rm => rm === 'title',
                then: yup.string().max(70, CommonVM.maxSymbols(70)),
                // .required(CommonVM.required('Title'))
            }),
        }),
        description: yup.string().when('isActive', {
            is: v => v,
            then: yup.string().max(900, CommonVM.maxSymbols(900)),
            // .required(CommonVM.required('Description'))
        }),
        textLink: yup.object().when('isActive', {
            is: v => v,
            then: yup.object({
                isActive: yup.boolean(),
                name: yup.string().when('isActive', {
                    is: v => v,
                    then: yup.string().max(15, CommonVM.maxSymbols(15)),
                    // .required(hpVM.tb.tl.required('Name'))
                }),
                target: yup.string().when('isActive', {
                    is: v => v,
                    then: yup.string().url(CommonVM.url('Target')),
                    // .required(hpVM.tb.tl.required('Target'))
                }),
            }),
        }),
    }),
    productCards: yup.object({
        sectionTitle: yup.string().max(25, CommonVM.maxSymbols(25)),
        // .required(CommonVM.required('Section title'))
        CTAText: yup.string().max(25, CommonVM.maxSymbols(25)),
        // .required(CommonVM.required('CTA text'))
        loadMoreLink: yup.string().max(25, CommonVM.maxSymbols(25)),
        // .required(CommonVM.required('“Load more” button'))
    }),
    newsletterOption: yup.object({
        isActive: yup.boolean(),
        icon: yup.mixed().when('isActive', {
            is: v => v,
            then: yup.mixed(),
            // .required(CommonVM.required('Icon')),
        }),
        title: yup.string().when('isActive', {
            is: v => v,
            then: yup.string().max(25, CommonVM.maxSymbols(25)),
            // .required(CommonVM.required('Title'))
        }),
        subTitle: yup.string().max(80, CommonVM.maxSymbols(80)),
        fieldPlaceholder: yup.string(),
    }),
    footer: yup.object({
        title: yup.string().max(25, CommonVM.maxSymbols(25)),
        // .required(CommonVM.required('Footer title'))
        staticLinks: yup.object({
            disclaimer: yup.object({
                name: yup.string(),
                url: yup.string().when('name', {
                    is: v => v,
                    then: yup.string(),
                    // .required(CommonVM.required('URL')).url(CommonVM.url('URL')),
                    otherwise: yup.string(),
                }),
            }),
            termsAndConditions: yup.object({
                name: yup.string(),
                url: yup.string().when('name', {
                    is: v => v,
                    then: yup.string(),
                    // .required(CommonVM.required('URL')).url(CommonVM.url('URL')),
                    otherwise: yup.string(),
                }),
            }),
            dataProtection: yup.object({
                name: yup.string(),
                url: yup.string().when('name', {
                    is: v => v,
                    then: yup.string(),
                    // .required(CommonVM.required('URL')).url(CommonVM.url('URL')),
                    otherwise: yup.string(),
                }),
            }),
        }),
        socialLinks: yup.object({
            instagram: YupSocialLink('Instagram', InstagramRegex),
            tiktok: YupSocialLink('Tiktok', TiktokRegex),
            facebook: YupSocialLink('Facebook', FacebookRegex),
            linkedin: YupSocialLink('Linkedin', LinkedinRegex),
            twitter: YupSocialLink('Twitter', TwitterRegex),
            snapchat: YupSocialLink('Snapchat', SnapchatRegex),
        }),
        footerLinks: yup
            .array()
            .of(
                yup.object().shape(
                    {
                        name: yup.string().when('url', {
                            is: v => v,
                            then: yup.string().max(25, CommonVM.maxSymbols(25)),
                            // .required(CommonVM.required('Name'))
                            otherwise: yup.string(),
                        }),
                        url: yup.string().when('name', {
                            is: v => v,
                            then: yup.string(),
                            // .required(CommonVM.required('URL')), //.url(CommonVM.url('URL')),
                            otherwise: yup.string(),
                        }),
                    },
                    [['name', 'url']],
                ),
            )
            .max(4),
    }),
});
