const TOKEN_STORE_NAME = "authorization";

const setToken = (token:string) => localStorage.setItem(TOKEN_STORE_NAME, token);

const getToken = () => localStorage.getItem(TOKEN_STORE_NAME);

const removeToken = () => localStorage.removeItem(TOKEN_STORE_NAME);

export const AuthHelper = {
    setToken,
    getToken,
    removeToken
}