import React from 'react';
import clsx from 'clsx';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import { useCompanyLanguages } from 'units/app/hooks/selectors/useCompanyLanguages';
import { CompanyLanguages } from 'units/app/redux/types';

import styles from './FormProgress.module.scss';
import { Icon } from 'shared/components/ui';

export type FormLangsProgressType = {
    en: number;
    de: number;
    fr: number;
    it: number;
    total: number;
};

type FormProgressProps = {
    className?: string;
    progress: FormLangsProgressType;
    selectedLanguage: CompanyLanguages;
    onSelect: (lang: CompanyLanguages) => void;
};

export const FormProgress: React.FC<FormProgressProps> = ({
    className,
    selectedLanguage,
    progress,
    onSelect,
}) => {
    const languages = useCompanyLanguages();

    if (!languages) return null;

    return (
        <div className={clsx(styles.root, className)}>
            {languages.map(lang => (
                <div
                    className={clsx(styles.item, {
                        [styles['is-current']]: selectedLanguage === lang,
                        [styles['in-progress']]: progress[lang] !== 0,
                    })}
                    onClick={onSelect.bind(null, lang)}
                    key={`language-${lang}`}
                >
                    <CircularProgressbar
                        className={styles.progress}
                        value={Math.round((100 / progress.total) * progress[lang])}
                        strokeWidth={4}
                        styles={buildStyles({
                            pathColor: '#1CA8FF',
                            trailColor: '#C1CBDE',
                        })}
                    />
                    <div className={styles.text}>{lang}</div>
                    <div
                        className={clsx(styles.done, {
                            [styles['is-finished']]: progress.total === progress[lang],
                        })}
                    >
                        <Icon name="checkbox" />
                    </div>
                </div>
            ))}
        </div>
    );
};
