import React, { FC } from 'react';

import { Status } from '../../../create-auction/constants';
import { StatusSC } from './status.styles';

interface Props {
  status: {
    name: string,
    value: number
  },
}

export const StatusComp: FC<Props> = ({ status }) => {
  const isDraft = status.value === Status.draft;
  let name = '';

  if (isDraft) {
    name = 'Unscheduled';
  }

  return (
    <StatusSC.Container>
      <label>{name || status.name}</label>
    </StatusSC.Container>
  )
};
