import React, { FC } from 'react';

import { CMSThemeId } from 'units/cms/store/types';
import { CMSThemeListSC, CMSThemeListFontFamily } from './theme-list.styles';
import { CMSThemeListItem } from './theme-list-item';

export type CMSThemeListItemType = {
    id: CMSThemeId;
    title: string;
    customFont?: CMSThemeListFontFamily;
};

type Props = {
    themes: Array<CMSThemeListItemType>;
    selectedTheme: CMSThemeId;
    handleThemeClick: (themeId: CMSThemeId) => void;
};

export const CMSThemeList: FC<Props> = ({ themes, selectedTheme, handleThemeClick }) => (
    <CMSThemeListSC.ListContainer>
        {themes.map(theme => (
            <CMSThemeListItem
                key={theme.id}
                theme={theme}
                handleThemeClick={handleThemeClick}
                selected={selectedTheme === theme.id}
            />
        ))}
    </CMSThemeListSC.ListContainer>
);
