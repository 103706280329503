import styled, { css } from 'styled-components';

import { MediaQueries } from 'units/landing-page/config';
import { LPAnimationsCS } from 'units/landing-page/animations';

const Root = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 24px;
    box-sizing: border-box;

    @media (max-width: ${MediaQueries.tablet}) {
        margin-top: 49px;
    }

    @media (max-width: ${MediaQueries.mobile}) {
        margin-top: 4px;
    }
`;

const TitleCss = css`
    max-width: 600px;
    margin-bottom: 45px;

    ${LPAnimationsCS.ZoomInTitle}

    @media (max-width: ${MediaQueries.tablet}) {
        max-width: 480px;
        margin-bottom: 24px;
    }

    @media (max-width: ${MediaQueries.mobile}) {
        max-width: 300px;
        margin-bottom: 16px;
    }
`;

const TextCss = css`
    ${LPAnimationsCS.ZoomInHeroText}

    @media (max-width: ${MediaQueries.tablet}) {
        max-width: 530px;
    }
`;

const OverviewContainer = styled.div`
    position: relative;
    margin-bottom: 100px;
    margin-top: 33px;
    padding-left: 2.3%;
    margin-left: -25px;
    margin-right: -25px;
    width: 100%;
    max-width: 1200px;
    min-height: 835px;

    @media (max-width: ${MediaQueries.tablet}) {
        margin-top: 16px;
        width: initial;
        min-height: auto;
    }

    @media (max-width: ${MediaQueries.mobile}) {
        margin-top: 24px;
    }
`;

const ImagesCSS = css`
    position: absolute;
    z-index: 2;
`;

const OverviewImage = styled.img`
    width: 100%;
    height: auto;
    max-width: 1142px;

    ${LPAnimationsCS.FadeInHeroOverview}
`;

const SupportImage = styled.img`
    width: 34.334%;
    left: -12px;
    top: 29%;
    margin-top: 100px;
    ${ImagesCSS}

    ${LPAnimationsCS.FadeInHeroSupport}

  @media (max-width: ${MediaQueries.tablet}) {
        margin-top: 0;
    }
`;

const PreviewImage = styled.img`
    width: 53.5%;
    right: -9px;
    top: 26%;
    margin-top: 50px;
    ${ImagesCSS}

    ${LPAnimationsCS.FadeInHeroPreview}

  @media (max-width: ${MediaQueries.tablet}) {
        margin-top: 0;
    }
`;

export const LPHeroSC = {
    TitleCss,
    TextCss,
    Root,
    OverviewContainer,
    OverviewImage,
    SupportImage,
    PreviewImage,
};
