import { useFormikContext } from 'formik';
import { useCallback } from 'react';

type UseFormikHideApiError = (errorKey: string) => [boolean, (state: boolean) => void];

// Hooks that set to formik status errors that need to be hidden.
export const useFormikHideApiError: UseFormikHideApiError = (errorKey) => {
  const { status = {}, setStatus } = useFormikContext();

  const hiddenErrors: Array<string> = status?.hiddenErrors || [];
  const isErrorHidden: boolean = hiddenErrors.includes(errorKey) || false;

  const setHideError = useCallback((hide: boolean) => {    
    let newHiddenErrors = hiddenErrors;
    if (hide && !newHiddenErrors.includes(errorKey)) {
      newHiddenErrors.push(errorKey);
    } else {
      newHiddenErrors = newHiddenErrors.filter((e) => e !== errorKey);
    }

    setStatus({
      ...status,
      hiddenErrors: newHiddenErrors,
    })
  }, [status]);

  return [isErrorHidden, setHideError];
};
