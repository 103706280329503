import { yup } from 'config/yup';
import { CommonVM } from 'units/common/constants';

export const CMSCustomizationProductDetailPageValidationSchema = yup.object({
    hero: yup.object({
        CTAcopy: yup.string().max(25, CommonVM.maxSymbols(25)),
        // .required(CommonVM.required('CTA copy')),
        conversationSticker: yup.object({ isActive: yup.boolean() }),
        graph1: yup.object({
            title: yup.string().max(25, CommonVM.maxSymbols(25)),
            // .required(CommonVM.required('Graph 1 title')),
        }),
        graph2: yup.object({
            title: yup.string().max(25, CommonVM.maxSymbols(25)),
            // .required(CommonVM.required('Graph 2 title')),
        }),
    }),
    /*   relatedProducts: yup.object({
    sectionTitle: yup.string().required(CommonVM.required('Section title')).max(40, CommonVM.maxSymbols(40))
  }), */
    newsletterOptIn: yup.object({
        isActive: yup.boolean(),
    }),
});
