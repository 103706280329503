import styled from 'styled-components';

const Bold = styled.span`
    font-weight: bold;
    color: #000;
    a {
        color: inherit;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
`;

const EmptyState = styled.div`
    min-height: 240px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const PastUsageSC = {
    Bold,
    EmptyState,
};
