import React from 'react';
import { useFormikContext } from 'formik';
import { useLocation } from 'react-router-dom';

import { AuctionValues } from '../types';
import { CreateAuctionSC } from '../create-auction.styles';
import { AuctionConfigurationSC } from './auction-configuration.styles';
import { FormikFormInput } from 'units/common/components/formik/input/form-input.component';
import { FormikRadioButtonGroup } from 'units/common/components/formik/radio/radio-group.component';
import { useFormikFieldErrors } from 'units/common/hooks/useFieldErrors';
import { Discount, Auction } from '../constants';
import { getAuctionStatuses } from '../helpers';
import { useWorkingMode } from '../hooks';

const radioButtons = [
    { id: Discount.currency, label: 'CHF' },
    { id: Discount.persentage, label: '%' },
];

export const AuctionConfiguration = () => {
    const { values } = useFormikContext<AuctionValues>();
    const fieldError = useFormikFieldErrors<AuctionValues>();
    const { isActive } = getAuctionStatuses(values);
    const { isViewMode, isEditMode, isCreateMode } = useWorkingMode(useLocation());

    const isBuyNow = values.auctionType === Auction.buyNow;
    const configLabel = isBuyNow
        ? 'Please, set discount'
        : 'Set your minimum price, offer interval and custom triggers';

    return (
        <CreateAuctionSC.Wrapper isViewMode={isViewMode}>
            {(isCreateMode || isViewMode || (isEditMode && !isActive)) && (
                <AuctionConfigurationSC.Label>{configLabel}</AuctionConfigurationSC.Label>
            )}

            {isBuyNow ? (
                <>
                    <AuctionConfigurationSC.RowContainer>
                        <FormikFormInput
                            label="UVP"
                            name="uvp"
                            placeholder="Enter UVP"
                            withMargins
                            withRounding
                            type="money"
                            error={fieldError.uvp}
                            disabled
                        />
                        <AuctionConfigurationSC.AdditionalBlock>
                            CHF
                        </AuctionConfigurationSC.AdditionalBlock>
                    </AuctionConfigurationSC.RowContainer>

                    <AuctionConfigurationSC.RowContainer>
                        <FormikFormInput
                            label="Discount"
                            name="discount"
                            placeholder="1"
                            type={values.discountType === Discount.persentage ? 'step' : 'number'}
                            withMargins
                            withRounding={values.discountType === Discount.persentage}
                            min={5}
                            max={95}
                            step={5}
                            error={fieldError.discount}
                            disabled={isActive}
                            allowNegative={false}
                            onKeyPress={event => {
                                if (event.which < 48 || event.which > 57) {
                                    event.preventDefault();
                                }
                            }}
                        />

                        <AuctionConfigurationSC.AdditionalBlock>
                            <FormikRadioButtonGroup
                                radioList={radioButtons}
                                margin="0 0 24px"
                                name="discountType"
                                disabled={isActive}
                            />
                        </AuctionConfigurationSC.AdditionalBlock>
                    </AuctionConfigurationSC.RowContainer>
                </>
            ) : (
                <>
                    <AuctionConfigurationSC.RowContainer>
                        <FormikFormInput
                            label="Minimum offer"
                            name="minOffer"
                            placeholder="Enter your minimum offer"
                            withMargins
                            withRounding
                            type="money"
                            error={fieldError.minOffer}
                            disabled={isActive}
                        />
                        <AuctionConfigurationSC.AdditionalBlock>
                            CHF
                        </AuctionConfigurationSC.AdditionalBlock>
                    </AuctionConfigurationSC.RowContainer>

                    <AuctionConfigurationSC.RowContainer>
                        <FormikFormInput
                            label="Offer interval"
                            name="offerInterval"
                            placeholder="Enter offer interval"
                            withMargins
                            type="number"
                            error={fieldError.offerInterval}
                            disabled={isActive}
                            allowNegative={false}
                        />
                        <AuctionConfigurationSC.AdditionalBlock>
                            CHF <span>(Minimum bid CHF 5)</span>
                        </AuctionConfigurationSC.AdditionalBlock>
                    </AuctionConfigurationSC.RowContainer>

                    <AuctionConfigurationSC.RowContainer>
                        <FormikFormInput
                            label="Minimum product pieces"
                            name="minProdPieces"
                            placeholder="Enter your min product pieces"
                            withMargins
                            type="number"
                            error={fieldError.minProdPieces}
                            disabled={isActive}
                            allowNegative={false}
                        />
                        <AuctionConfigurationSC.AdditionalBlock>
                            pcs <span>(Minimum value pcs 1)</span>
                        </AuctionConfigurationSC.AdditionalBlock>
                    </AuctionConfigurationSC.RowContainer>
                </>
            )}
        </CreateAuctionSC.Wrapper>
    );
};
