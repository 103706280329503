import React from 'react';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import { useLocation } from 'react-router-dom';

import { DatePicker } from 'units/common/components/date-picker/date-picker.component';
import { DateTimeSelectSC } from './date-time-select.styles';
import { CreateAuctionSC } from '../create-auction.styles';
import { AuctionValues } from '../types';
import { useFormikFieldErrors } from 'units/common/hooks/useFieldErrors';
import { isDaysEqual, roundTime, timeInterval, getAuctionStatuses } from '../helpers';
import { useWorkingMode } from '../hooks';

export const DateTimeSelect = () => {
    const { values, setFieldValue, setFieldTouched } = useFormikContext<AuctionValues>();
    const fieldError = useFormikFieldErrors<AuctionValues>();
    const { isActive } = getAuctionStatuses(values);
    const { isViewMode } = useWorkingMode(useLocation());

    const today = new Date();
    const startMinDate = dayjs().toDate();
    const startMinTime = isDaysEqual(today, values.startDate)
        ? dayjs(roundTime(today)).toDate()
        : dayjs().startOf('day').toDate();
    const startMaxTime =
        isDaysEqual(values.startDate, values.endDate) && values.endTime
            ? dayjs(
                  roundTime(
                      dayjs(values.endTime)
                          .subtract(timeInterval * 2, 'minute')
                          .toDate(),
                  ),
              ).toDate()
            : dayjs(values.startDate).endOf('day').toDate();

    let endMinDate = dayjs(values.startDate).toDate();
    let endMinTime = isDaysEqual(values.startDate, values.endDate)
        ? dayjs(roundTime(values.startTime)).toDate()
        : dayjs().startOf('day').toDate();
    const endMaxTime = dayjs(values.endDate).endOf('day').toDate();

    if (isActive) {
        endMinDate = dayjs(values.endDateCopy).toDate();
        endMinTime = isDaysEqual(values.endDateCopy, values.endDate)
            ? dayjs(values.endTimeCopy).toDate()
            : dayjs().startOf('day').toDate();
    }

    const onChangeDateTime = async (field: string, date: Date) => {
        await setFieldValue(field, date);

        if (field === 'startDate' && values.startTime) {
            setFieldTouched('startTime', true);
        }
        if (field === 'endDate' && values.endTime) {
            setFieldTouched('endTime', true);
        }
    };

    const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        event.preventDefault();
        return null;
    };

    const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        setFieldTouched(event.currentTarget.name, true);
    };

    return (
        <CreateAuctionSC.Wrapper isViewMode={isViewMode}>
            {!isViewMode && (
                <DateTimeSelectSC.RowContainer>
                    <DatePicker
                        name="startDate"
                        label="Start date"
                        selected={values.startDate}
                        minDate={startMinDate}
                        maxDate={values.endDate}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="DD/MM/YYYY"
                        onChange={(date: Date) => onChangeDateTime('startDate', date)}
                        onKeyDown={onKeyDown}
                        onBlur={onBlur}
                        error={fieldError.startDate}
                        disabled={isActive}
                    />
                    <DatePicker
                        name="startTime"
                        label="Start time"
                        selected={values.startTime}
                        minDate={startMinDate}
                        maxDate={values.endDate}
                        minTime={startMinTime}
                        maxTime={startMaxTime}
                        placeholderText="00 : 00"
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={timeInterval}
                        timeCaption="Time"
                        dateFormat="HH : mm"
                        timeFormat="HH : mm"
                        onChange={(date: Date) => onChangeDateTime('startTime', date)}
                        onKeyDown={onKeyDown}
                        onBlur={onBlur}
                        error={fieldError.startTime}
                        disabled={isActive}
                    />
                </DateTimeSelectSC.RowContainer>
            )}

            <DateTimeSelectSC.RowContainer>
                <DatePicker
                    name="endDate"
                    label="End date"
                    selected={values.endDate}
                    minDate={endMinDate}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="DD/MM/YYYY"
                    onChange={(date: Date) => onChangeDateTime('endDate', date)}
                    onKeyDown={onKeyDown}
                    onBlur={onBlur}
                    error={fieldError.endDate}
                />
                <DatePicker
                    name="endTime"
                    label="End time"
                    selected={values.endTime}
                    minDate={endMinDate}
                    minTime={endMinTime}
                    maxTime={endMaxTime}
                    placeholderText="00 : 00"
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={timeInterval}
                    timeCaption="Time"
                    dateFormat="HH : mm"
                    timeFormat="HH : mm"
                    onChange={(date: Date) => onChangeDateTime('endTime', date)}
                    onKeyDown={onKeyDown}
                    onBlur={onBlur}
                    error={fieldError.endTime}
                />
            </DateTimeSelectSC.RowContainer>
        </CreateAuctionSC.Wrapper>
    );
};
