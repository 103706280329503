import styled from 'styled-components';

import { Font } from 'components/types/enums';

import { TextStyle15 } from 'components/styles/text.styles';
import { Color } from 'components/styles/constants.styles';

const Text = styled.div`
    ${TextStyle15};
    text-align: center;
`;

const ButtonsWrapper = styled.div`
    justify-content: center;
    display: flex;

    button {
        margin: 0 13px;
    }
`;

const Product = styled.div`
    border: 1px solid ${Color.grey};
    border-radius: 8px;
    height: 72px;
    margin-top: 24px;
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 11px;
    padding-right: 20px;
`;

const Info = styled.div`
    display: flex;
    align-items: center;
    font-family: ${Font.bold};
    img {
        max-width: 85px;
        margin-right: 15px;
        max-height: 54px;
    }
`;

const Quantity = styled.div`
    font-size: 14px;
    color: ${Color.grey};
`;

export const FulFillModalSC = {
    Text,
    ButtonsWrapper,
    Product,
    Info,
    Quantity
}
