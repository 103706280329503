import * as actionCreators from './action-creators';
import * as cmsActionCreators from '../../cms/store/action-creators';
import { userService } from './service';
import {
    RegistrationUser,
    EmailConfirmation,
    LoginUser,
    ConfirmResetPassword,
    UpdateUserAccountData,
    SignOutError,
    RegistrationErrorRes,
    EmailConfirmationError,
    ResendEmailConfirmationError,
    ResetPasswordError,
    ConfirmResetPasswordError,
    FetchUserAccountDataError,
    UpdateUserAccountErrorRes,
    LoginUserError,
    AcceptInvitationDataReq,
    AcceptInvitationErrorRes,
    LoginWelcome,
} from './types';
import { history } from 'helpers/history';
import { UserUrls } from '../urls';
import { AppUrls } from 'app/urls';
import { ApiClient } from 'config/api/api';
import { AsyncAppThunk } from 'redux/root-types';
import { transformErrorFromApi, handleTokenError } from 'redux/tools/error-handling';
import { parseUpdateUserAccountDataError } from '../tools/parsers/updateUserAccountData';
import { AppStrings } from 'config/strings';
import { clearAppReducer, setCompanyLanguages } from 'units/app/redux/action-creators';
import { initializeAppWithAuthorizedUserThunk } from 'units/app/redux/thunks';
import { removeSettingsStripeData } from 'store/actions';
import { NotificationStatusType } from 'shared/components/ui';
import { CMSThemeId } from 'units/cms/store/types';
import { api } from 'api';

export const signUpThunk = (
    user: RegistrationUser,
    registrationSuccessCallback: () => void,
): AsyncAppThunk => async dispatch => {
    dispatch(actionCreators.registrationRequest(user.email));

    try {
        const { data } = await userService.registration(user);

        dispatch(actionCreators.registrationSuccess(data));
        registrationSuccessCallback();
    } catch (error) {
        dispatch(
            actionCreators.registrationFailure(transformErrorFromApi<RegistrationErrorRes>(error)),
        );
    }
};

export const confirmSignUpEmailThunk = (
    { uid, token }: EmailConfirmation,
    callback: (type: NotificationStatusType, text: string) => void,
): AsyncAppThunk => async dispatch => {
    dispatch(actionCreators.confirmEmailRequest());

    try {
        await userService.confirmEmail({ uid, token });

        dispatch(actionCreators.confirmEmailSuccess());

        setTimeout(() => {
            // Navigate user to login and give a tip.
            if (history.location.pathname !== UserUrls.login) {
                history.push(UserUrls.login);
            }
            callback('success', AppStrings.registrationSuccess);
        }, 3000);
    } catch (error) {
        // If something went wrong during the confirmation, send user to login page with error state.
        dispatch(
            actionCreators.confirmEmailFailure(
                transformErrorFromApi<EmailConfirmationError>(error),
            ),
        );
        history.push(UserUrls.login);
    }
};

export const resendConfirmSignUpEmailThunk = (
    email: string,
    callback: (type: NotificationStatusType, text: string) => void,
): AsyncAppThunk => async dispatch => {
    dispatch(actionCreators.resendConfirmEmailRequest());

    try {
        await userService.resendConfirmEmail(email);

        dispatch(actionCreators.resendConfirmEmailSuccess());
        callback('success', AppStrings.confirmationEmailSend);
    } catch (error) {
        if (error.response) {
            callback('error', AppStrings.errorNotification);
        }
        dispatch(
            actionCreators.resendConfirmEmailFailure(
                transformErrorFromApi<ResendEmailConfirmationError>(error),
            ),
        );
    }
};

export const signInThunk = ({ password, email }: LoginUser): AsyncAppThunk => async dispatch => {
    dispatch(actionCreators.loginRequest());

    try {
        const {
            data: { auth_token, welcomeflow_completed, user_type },
        } = await userService.loginUser({ password, email });

        dispatch(actionCreators.loginSuccess(auth_token, email, welcomeflow_completed));
        ApiClient.setAccessToken(auth_token);

        await dispatch(initializeAppWithAuthorizedUserThunk());

        if (user_type !== 'immo') {
            welcomeflow_completed
                ? history.push(AppUrls.home)
                : history.push(UserUrls.signInWelcome);
        }
    } catch (error) {
        dispatch(actionCreators.loginFailure(transformErrorFromApi<LoginUserError>(error)));
    }
};

export const resetPasswordThunk = (
    email: string,
    onSuccessHandler?: () => void,
): AsyncAppThunk => async dispatch => {
    dispatch(actionCreators.resetPasswordRequest(email));

    try {
        await userService.resetPassword(email);

        dispatch(actionCreators.resetPasswordSuccess());
        if (onSuccessHandler) {
            onSuccessHandler();
        }
    } catch (error) {
        dispatch(
            actionCreators.resetPasswordFailure(transformErrorFromApi<ResetPasswordError>(error)),
        );
    }
};

export const confirmResetPasswordThunk = (
    confirmResetPasswordData: ConfirmResetPassword,
    callback: (type: NotificationStatusType, text: string) => void,
): AsyncAppThunk => async dispatch => {
    dispatch(actionCreators.confirmResetPasswordRequest());

    try {
        await userService.confirmResetPassword(confirmResetPasswordData);

        dispatch(actionCreators.confirmResetPasswordSuccess());
        history.push(UserUrls.login);
        callback('success', AppStrings.resetPasswordSuccess);
    } catch (error) {
        const apiError = transformErrorFromApi<ConfirmResetPasswordError>(error);

        dispatch(actionCreators.confirmResetPasswordFailure(apiError));
        handleTokenError(apiError, AppStrings.resetPasswordErr);
    }
};

export const resetPasswordCheckTokenThunk = (
    token: string,
    uid: string,
    callback: (type: NotificationStatusType, text: string) => void,
): AsyncAppThunk => async dispatch => {
    try {
        await userService.resetPasswordCheckToken(token);

        dispatch(actionCreators.resetPasswordSetUserId({ uid, token }));
        history.push(UserUrls.resetPassword);
    } catch (error) {
        history.push(UserUrls.login);
        callback('error', AppStrings.resetPasswordErr);
    }
};

type SignOutThunkParams = {
    withoutRedirectToLogin?: boolean;
};

export const signOutThunk = (
    callback: (type: NotificationStatusType, text: string) => void,
    options?: SignOutThunkParams,
): AsyncAppThunk => async (dispatch, getState) => {
    const {
        user: { token, user },
    } = getState();

    dispatch(actionCreators.signOutRequest(token));
    try {
        await userService.signOut({ token, email: user?.email || '' });
        dispatch(removeSettingsStripeData());

        dispatch(actionCreators.signOutSuccess());
        dispatch(clearAppReducer());
        ApiClient.removeAccessToken();

        if (!options?.withoutRedirectToLogin) {
            history.push(UserUrls.login);
        }
    } catch (error) {
        if (error.response) {
            callback('error', AppStrings.errorNotification);
        }

        dispatch(actionCreators.signOutFailure(transformErrorFromApi<SignOutError>(error)));
    }
};

export const fetchMyAccountDataThunk = (): AsyncAppThunk => async dispatch => {
    dispatch(actionCreators.fetchMyAccountDataRequest());

    try {
        const { data } = await userService.fetchUserAccountData();

        if (data.user_type?.slug === 'immo') {
            dispatch(cmsActionCreators.cmsSetActiveTheme(CMSThemeId.Estate));
        }

        dispatch(actionCreators.fetchMyAccountDataSuccess(data));
    } catch (error) {
        dispatch(
            actionCreators.fetchMyAccountDataFailure(
                transformErrorFromApi<FetchUserAccountDataError>(error),
            ),
        );
    }
};

export const updateMyAccountDataThunk = (
    newAccountData: UpdateUserAccountData,
    callback: (type: NotificationStatusType, text: string) => void,
): AsyncAppThunk => async dispatch => {
    dispatch(actionCreators.updateMyAccountDataRequest());

    try {
        await userService.updateUserAccountData(newAccountData);

        dispatch(actionCreators.updateMyAccountDataSuccess(newAccountData));
        callback('success', 'Your data has been successfully updated');
    } catch (error) {
        dispatch(
            actionCreators.updateMyAccountDataFailure(
                parseUpdateUserAccountDataError(
                    transformErrorFromApi<UpdateUserAccountErrorRes>(error),
                ),
            ),
        );
        callback('error', 'Oops, something went wrong. Please try again.');
    }
};

export const updateCompanyEmailThunk = (email: string): AsyncAppThunk => async dispatch => {
    try {
        await api.settingsService.updateCompanyEmail(email);
        dispatch(actionCreators.updateCompanyEmail(email));
    } catch (e) {
        console.log('error: updateCompanyEmailThunk');
    }
};

export const storeWelcomeFlowDataThunk = (
    values: LoginWelcome,
): AsyncAppThunk => async dispatch => {
    try {
        await userService.storeWelcomeFlowData(values);
        const valuesToSave = {
            firstName: values?.firstName || '',
            lastName: values?.lastName || '',
        };

        dispatch(actionCreators.welcomeFlowRequestSuccess(valuesToSave));
        dispatch(setCompanyLanguages(values.languages || []));

        history.push(AppUrls.home);
    } catch (error) {}
};

export const acceptInvitationThunk = (
    values: AcceptInvitationDataReq,
    callback: (type: NotificationStatusType, text: string) => void,
): AsyncAppThunk => async dispatch => {
    dispatch(actionCreators.acceptInvitationDataRequest());

    try {
        await userService.acceptInvitation(values);

        dispatch(actionCreators.acceptInvitationDataSuccess());
        callback('success', AppStrings.acceptInvitationSuccess);
        history.push(UserUrls.login);
    } catch (error) {
        const apiErrors = transformErrorFromApi<AcceptInvitationErrorRes>(error);

        dispatch(actionCreators.acceptInvitationDataFailure(apiErrors));
        handleTokenError(apiErrors, AppStrings.acceptInvitationErr);
    }
};

export const acceptInvitationCheckTokenThunk = (
    token: string,
    callback: (type: NotificationStatusType, text: string) => void,
): AsyncAppThunk => async dispatch => {
    try {
        await userService.acceptInvitationCheckToken(token);

        dispatch(actionCreators.acceptInvitationSetToken(token));
        history.push(UserUrls.createPassword);
    } catch (error) {
        history.push(UserUrls.login);
        callback('error', AppStrings.acceptInvitationErr);
    }
};
