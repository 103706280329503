import styled from 'styled-components';

import { Color } from './constants.styles';

export enum IconUnicodes {
  eye_off = '"\\e904"',
  eye = '"\\e905"',
  check_circle_filled = '"\\e908"',
  minus_circle_filled = '"\\e909"',
  search = '"\\e900"',
  angle_down = '"\\e90c"',
  angle_up = '"\\e90d"',
  angle_right = '"\\e90e"',
  angle_left = '"\\e90f"',
  settings = '"\\e901"',
  donut_chart = '"\\e902"',
  cart = '"\\e903"',
  hammer = '"\\e906"',
  items = '"\\e907"',
  monitor = '"\\e90a"',
  squares_filled = '"\\e90b"',
  arrow_left = '"\\e910"',
  close = '"\\e911"',
  trash = '"\\e912"',
  pensil = '"\\e913"',
  info = '"\\e914"',
  dots = '"\\e915"',
}

interface IconProps {
  name: IconUnicodes;
  color?: Color;
  size?: number;
}

export const IconSC = styled.span<IconProps>`
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  font-size: ${props => (props.size ? `${props.size}px` : 'inherit')};
  color: ${props => props.color};

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &:before {
    content: ${props => props.name};
  }
`;
