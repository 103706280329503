import * as yup from 'yup';

import { UserInputValidationErrors } from 'units/common/constants';

export const accountSettingsValidationSchema = yup.object({
  firstName: yup.string()
    .required(UserInputValidationErrors.firstName.required),
  lastName: yup.string()
    .required(UserInputValidationErrors.lastName.required),
  email: yup.string()
    // .required(UserInputValidationErrors.email.required)
    .email(UserInputValidationErrors.email.notValid),
  phone: yup.string()
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      UserInputValidationErrors.phone.notValid
    ),
  companyName: yup.string()
    .required(UserInputValidationErrors.companyName.required),
});
