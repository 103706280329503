import * as yup from 'yup';
import { EditorState, convertToRaw } from 'draft-js';

import { ProductGeneralInfoValidationMessage as message } from './general-info/constants';
import { ProductSeoValidationMessage as seoMessage } from './seo/constants';
import { EditProductDescription } from './store/types';

// To define the type of data.
const isEditState = (value: any): value is EditorState =>
    (value as EditorState).getCurrentContent !== undefined;

const DESCRIPTION_MAX_LENGTH = 1200;

export const getProductGeneralInfoValidationSchema = (minUvp: number) => {
    return yup.object({
        productName: yup
            .string()
            .required(message.productName.required)
            .max(100, message.productName.max),
        quantityAtStock: yup
            .number()
            .required(message.quantityAtStock.required)
            .min(1, message.quantityAtStock.min)
            .nullable(),
        usp1: yup.string().required(message.usp.required(1)).max(60, message.usp.max),
        usp2: yup.string().required(message.usp.required(2)).max(60, message.usp.max),
        usp3: yup.string().required(message.usp.required(3)).max(60, message.usp.max),
        uvp: yup
            .number()
            .required(message.uvp.required)
            .nullable()
            .min(minUvp, `Can't be less than ${minUvp}`),
        productCatchPhrase: yup.string(),
        deliveryDays: yup
            .number()
            .required(message.deliveryDays.required)
            .max(60, message.deliveryDays.max)
            .nullable(),
        shippingCost: yup
            .number()
            .required(message.shippingCost.required)
            .min(0.1, `Value should be greater than or equal to 0.1`)
            .nullable(),
        pictures: yup.array().min(1, message.pictures.required),
        descriptions: yup
            .array()
            .of(
                yup.object({
                    type: yup.string().max(50, message.descriptionType.max),
                    description: yup.mixed().when('type', {
                        is: v => v && v.length > 0,
                        then: yup
                            .mixed()
                            .test(
                                'richtext test required',
                                message.descriptionText.required,
                                description => {
                                    if (isEditState(description)) {
                                        return (
                                            description.getCurrentContent().hasText() ||
                                            description
                                                .getCurrentContent()
                                                .getFirstBlock()
                                                .getType() !== 'unstyled'
                                        );
                                    }
                                    return false;
                                },
                            )
                            .test('richtext test max', message.descriptionText.max, description => {
                                if (isEditState(description)) {
                                    let totalLength = 0;
                                    const content = convertToRaw(description.getCurrentContent());
                                    content.blocks.forEach(b => {
                                        totalLength += b.text.length;
                                    });

                                    return totalLength < DESCRIPTION_MAX_LENGTH;
                                }
                                return false;
                            }),
                    }),
                }),
            )
            .min(1, message.description.required)
            .test('test descriptions', '', TestDescriptions as any),
    });
};

export const productSeoValidationSchema = yup.object({
    seoPageTitle: yup.string().max(50, seoMessage.seoTitle.max),
    seoPageDescription: yup.string().max(150, seoMessage.seoDescription.max),
});

function TestDescriptions(this: yup.TestContext<any>, descriptions: Array<EditProductDescription>) {
    if (!descriptions) {
        return false;
    }

    const isEveryFilled = descriptions.every(
        description => description?.description && description.type,
    );
    if (isEveryFilled) {
        return true;
    }

    return this.createError({
        message:
            descriptions.length > 1
                ? message.description.everyShouldBeFilled
                : message.description.required,
    });
}
