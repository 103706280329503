import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from 'redux/root-types';
import { Typography } from 'shared/components/ui';
import { ParseHelper } from 'shared/helpers';
import { AuctionUrls } from 'units/auctions/urls';
import { SecondaryButton } from 'units/common/components/buttons/secondary/secondary.component';
import { ProductsUrls } from 'units/products/urls';
import { UserState } from 'units/user/redux/types';
import dayjs from 'dayjs';

import styles from './OverviewHeader.module.scss';

export const OverviewHeaderSection: React.FC = () => {
    const { user } = useSelector<RootState, UserState>(state => state.user);

    const history = useHistory();

    const createNewAuctionHandler = useCallback(() => history.push(AuctionUrls.createAuction), [
        history,
    ]);

    const addNewProductHandler = useCallback(() => history.push(ProductsUrls.addProduct), [
        history,
    ]);

    const lastLoginFormated = dayjs(user?.lastLogin).format('DD.MM.YYYY HH:mm');

    return (
        <div className={styles.root}>
            <div className={styles['title-section']}>
                <Typography.Title htmlElement="h2" className={styles.title}>
                    {`Welcome, ${ParseHelper.getFullName(user)}`}
                </Typography.Title>
                <Typography.Text
                    className={styles['last-login']}
                >{`Last Login: ${lastLoginFormated} Uhr`}</Typography.Text>
            </div>
            <div className={styles['buttons-section']}>
                <SecondaryButton isWide onClick={createNewAuctionHandler}>
                    + Create New Auction
                </SecondaryButton>
                <SecondaryButton isWide onClick={addNewProductHandler}>
                    + Add New Product
                </SecondaryButton>
            </div>
        </div>
    );
};
