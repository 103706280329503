import React, { FC } from 'react';

import {
  CMSCustomizationLanguageProgressContainer,
  CMSCustomizationLanguageProgressContainerProps,
} from '../../../progress-container/progress-container';
import { CMSProductDetailPageNewsletterAccordion } from './newsletter-accordion.component';
import { CmsAcordionSection } from 'units/cms/components/cms-acordion-section/cmsAcordionSection.component';

type Props = Pick<CMSCustomizationLanguageProgressContainerProps, 'selectedLanguage' | 'onLanguageChange' | 'initialProgress'>;

export const CMSProductDetailPageNewsletterAccordionContainer: FC<Props> = (props) => (
    <CmsAcordionSection withDevider={false}>
      <CMSCustomizationLanguageProgressContainer
        hideProgress
        {...props}
      >
        {() => <CMSProductDetailPageNewsletterAccordion />}
      </CMSCustomizationLanguageProgressContainer>
    </CmsAcordionSection>
);
