import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { Range } from 'react-date-range';
import { useHistory } from 'react-router-dom';
import { Button, Icon, Typography } from 'shared/components/ui';
import { Pagination } from 'units/common/components/pagination/pagination.component';
import { Table } from 'units/common/components/table/table.component';
import { OrderItemType } from 'units/orders/types';
import { OverviewService } from 'units/overview/api';
import { DatePickerDropdown, SlideDropdown } from '../../ui';
import 'dayjs/locale/de';

import styles from './OverviewOrders.module.scss';

const columns = [
    {
        Header: 'Date and time',
        accessor: 'date',
    },
    {
        Header: 'Order',
        accessor: 'order',
    },
];

const ITEMS_PER_PAGE = 6;
dayjs.locale('de');

export const OverviewOrdersSection: React.FC = () => {
    const [orders, setOrders] = useState<any>([]);
    const [pagesLength, setPagesLength] = useState(0);
    // const [currPage, setCurrPage] = useState<number>(1);
    const history = useHistory();

    const loadOrders = useCallback(async (page: number, startsAt?: string, endsAt?: string) => {
        const data = await OverviewService.getOrders(page, ITEMS_PER_PAGE, startsAt, endsAt);
        setPagesLength(Math.ceil(data.count / ITEMS_PER_PAGE));
        prepareData(data.results);
    }, []);

    const onPaginationChange = (selectedItem: { selected: number }) => {
        loadOrders(
            selectedItem.selected + 1,
            dayjs(range[0].startDate).format('YYYY-MM-DD'),
            dayjs(range[0].endDate).format('YYYY-MM-DD'),
        );
        // setCurrPage(selectedItem.selected + 1);
    };

    // useEffect(() => {
    //     loadOrders(currPage);
    // const int = setInterval(loadOrders.bind(null, currPage), 5000);
    // setRefreshInterval(int);
    // return () => clearInterval(int);
    // }, []);

    const prepareData = (data: OrderItemType[] | null) => {
        const result = data
            ? data.map(({ id, created_at, user }) => ({
                  id,
                  date: `${dayjs(created_at).format('DD.MM.YYYY, HH:mm')} Uhr`,
                  order: (
                      <div className={styles.order}>
                          {`Order #${id} was placed by ${user?.email}`}
                      </div>
                  ),
              }))
            : [];
        setOrders(result);
    };

    const onDetailPage = ({ id }: { id: string }) => {
        history.push(`/orders/${id}`);
    };

    const getButtonLabel = () => {
        if (
            dayjs(range[0].startDate).isSame(dayjs().startOf('week'), 'day') &&
            dayjs(range[0].endDate).isSame(dayjs().endOf('week'), 'day')
        ) {
            return 'This week';
        } else {
            return `${dayjs(range[0].startDate).format('DD.MM.YYYY')}-${dayjs(
                range[0].endDate,
            ).format('DD.MM.YYYY')}`;
        }
    };

    const [range, setRange] = useState<Range[]>([
        {
            startDate: dayjs().startOf('week').toDate(),
            endDate: dayjs().endOf('week').toDate(),
            key: 'selection',
        },
    ]);

    useEffect(() => {
        loadOrders(
            1,
            dayjs(range[0].startDate).format('YYYY-MM-DD'),
            dayjs(range[0].endDate).format('YYYY-MM-DD'),
        );
        // setCurrPage(0);
    }, [range]);

    return (
        <div className={styles.section}>
            <div className={styles['left-section']}>
                <div className={styles.orders}>
                    <div className={styles.header}>
                        <Typography.Title htmlElement="h4">Order log</Typography.Title>
                        <SlideDropdown
                            className={styles.slide}
                            content={<DatePickerDropdown range={range} setRange={setRange} />}
                            position="right"
                        >
                            <Button className={styles.button}>
                                <span>{getButtonLabel()}</span>
                                <Icon className={styles.arrow} name="arrow-right" />
                            </Button>
                        </SlideDropdown>
                    </div>
                    {orders.length > 0 ? (
                        <div className={styles.table}>
                            <Table
                                columns={columns}
                                data={orders}
                                havePadding={false}
                                onRowClick={onDetailPage}
                            />
                            <Pagination
                                pageSize={ITEMS_PER_PAGE}
                                pageCount={pagesLength}
                                onPageChange={onPaginationChange}
                            />
                        </div>
                    ) : (
                        <Typography.Title className={styles.empty} htmlElement="h4">
                            There are no orders for selected dates
                        </Typography.Title>
                    )}
                </div>
            </div>
        </div>
    );
};
