import dayjs from 'dayjs';
import React from 'react';
import { Typography } from 'shared/components/ui';
import { ParseHelper } from 'shared/helpers';
import { Table } from '../../../../../common/components/table/table.component';
import { InvoiceType } from '../../types';
import { PastUsageSC } from './past-usage.styles';

type PastUsageProps = {
    invoices: InvoiceType[];
};

export const PastUsage: React.FC<PastUsageProps> = ({ invoices }) => {
    const columns = React.useMemo<any>(
        () => [
            {
                Header: 'Date',
                accessor: 'date',
            },
            {
                Header: 'Gross amount',
                accessor: 'amount',
            },
            {
                Header: 'Fee',
                accessor: 'fee',
            },
            {
                Header: '',
                accessor: 'view',
            },
        ],
        [],
    );

    const prepareData = (data: InvoiceType[]) =>
        data.map(({ end_date, total_amount, total_application_fees_amount, invoice_file }) => ({
            date: dayjs(end_date).format('DD.MM.YYYY'),
            amount: (
                <PastUsageSC.Bold>
                    {ParseHelper.formatCurrency(parseInt(total_amount))}
                </PastUsageSC.Bold>
            ),
            fee: (
                <PastUsageSC.Bold>
                    {ParseHelper.formatCurrency(parseInt(total_application_fees_amount))}
                </PastUsageSC.Bold>
            ),
            view: (
                <PastUsageSC.Bold>
                    <a href={invoice_file} target="_blank" rel="noreferrer">
                        View
                    </a>
                </PastUsageSC.Bold>
            ),
        }));

    return Array.from(prepareData(invoices)).length > 0 ? (
        <Table columns={columns} data={prepareData(invoices)} />
    ) : (
        <PastUsageSC.EmptyState>
            <Typography.Title htmlElement="h4">There are no past invoices</Typography.Title>
        </PastUsageSC.EmptyState>
    );
};
