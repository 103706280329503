import React from 'react';

import {
    LPAdvantages,
    LPContactSection,
    LPControlDescription,
    LPFeatures,
    LPHero,
    LPPricing,
} from '../../components/sections';

import rightBg from '../../images/right-bg.svg';
import leftBg from '../../images/left-bg.svg';

import { LPHomeSC } from './home.styles';
import { LandingPageSC } from '../../index.styles';

export const LPHome = () => (
    <>
        <LPHomeSC.RightBg src={rightBg} alt="pattern" />
        <LPHomeSC.LeftBg src={leftBg} alt="pattern" />
        <LandingPageSC.ContentWrapper>
            <LPHero />
            <LPAdvantages />
            <LPFeatures />
            <LPControlDescription />
            <LPPricing />
            <LPContactSection />
        </LandingPageSC.ContentWrapper>
    </>
);
