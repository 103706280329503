import clsx from 'clsx';
import React, { FC, useEffect, useState } from 'react';
import { Icon } from '..';
import styles from './Checkbox.module.scss';

interface CheckboxProps {
    label?: string;
    onChange?: (state: boolean) => void;
    checked?: boolean;
}

export const Checkbox: FC<CheckboxProps> = ({ label, onChange, checked }) => {
    const [active, setActive] = useState(false);

    useEffect(() => {
        checked && setActive(checked);
    }, [checked]);

    const changeValue = () => {
        setActive(!active);
        onChange && onChange(!active);
    };

    return (
        <div
            className={clsx(styles.checkbox, active && styles.checked)}
            onClick={() => changeValue()}
        >
            <div className={styles.box}>
                <Icon name="checkbox" />
            </div>
            <span className={styles.label}>{label}</span>
        </div>
    );
};
