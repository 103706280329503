import styled from 'styled-components';

import { FormInputErrorText } from 'components/styles/text.styles';

const RowContainer = styled.div`
  display: flex;
`;

const Error = styled.p`
  ${FormInputErrorText}
  margin: 8px 0 0 0;
`;

export const DateTimeSelectSC = {
  RowContainer,
  Error,
};
