import { InvitedUser } from '../../api/types';
import { UserEditFormValues } from '../../user/user-edit.component';

export const parseUserEditFormValues = (user: InvitedUser): UserEditFormValues => ({
  email: user.email,
  firstName: user.firstName,
  lastName: user.lastName,
  phone: user.phone || '',
  roleId: `${user.roleId}`,
});
