import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import { GlobalStyle } from 'components/styles/global.styles';
import { LayoutSC } from 'units/common/components/layout/layout-components.styles';
import { Header } from 'units/common/components/header/header.component';
import { Router } from './navigation/Router';
import { RootState } from 'redux/root-types';
import { UserState } from 'units/user/redux/types';
import { ApiClient } from 'config/api/api';
import { useAsyncDispatch } from 'helpers/hooks/useAsyncDispatch';
import { setUpAuthInterceptorsAction } from 'config/api/configure';
import { AppSidebar } from './sidebar';
import { initializeAppWithAuthorizedUserThunk } from 'units/app/redux/thunks';
import { clearAppReducer } from 'units/app/redux/action-creators';
import { UserUrls } from 'units/user/urls';
import { OtherScreens } from './other-screens/OtherScreens';
import { LandingPageUrls } from 'units/landing-page/urls';

//import { ImmoLayout } from 'shared/components/themes/immo/grid';
//import { NotAuthorizedRouter } from 'shared/routers';

/* const NotAuthorizedRouter = React.lazy(() => import('shared/routers/NotAuthorizedRouter')); */
const NotAuthorizedRouterLazy = React.lazy(() =>
    import('shared/routers').then(module => ({ default: module.NotAuthorizedRouter })),
);

const ImmoLayoutLazy = React.lazy(() =>
    import('shared/themes/immo/grid').then(module => ({ default: module.ImmoLayout })),
);

export const App = () => {
    const [isAppInitialized, setAppInitialized] = useState(false);
    const [isOtherScreenActive, setOtherScreenActive] = useState(false);
    const dispatch = useDispatch();
    const { dispatch: asyncDispatch } = useAsyncDispatch();
    const { token, user } = useSelector<RootState, UserState>(state => state.user);
    const isRegistration = !!useRouteMatch({ path: UserUrls.registration, exact: true });
    const isRegistrationActivation = !!useRouteMatch({ path: UserUrls.confirmEmail, exact: true });
    const isLanding = !!useRouteMatch(LandingPageUrls.landingPage);

    useEffect(() => {
        (async () => {
            dispatch(setUpAuthInterceptorsAction());

            if (token) {
                ApiClient.setAccessToken(token);

                await asyncDispatch(initializeAppWithAuthorizedUserThunk());
            }
            setAppInitialized(true);
        })();

        return () => {
            dispatch(clearAppReducer());
        };
    }, []);

    if (user && user.user_type?.slug === 'immo') {
        return <ImmoLayoutLazy />;
    }

    if (user && user.user_type?.slug === 'normal') {
        return (
            <NormalApp
                {...{
                    isAppInitialized,
                    isLanding,
                    isOtherScreenActive,
                    isRegistration,
                    isRegistrationActivation,
                    setOtherScreenActive,
                }}
            />
        );
    }

    return (
        <LayoutSC.Wrapper>
            <GlobalStyle />
            {isAppInitialized && (
                <>
                    <LayoutSC.Content>
                        <Header logoOnly={isOtherScreenActive} />

                        <NotAuthorizedRouterLazy />
                    </LayoutSC.Content>
                </>
            )}
        </LayoutSC.Wrapper>
    );
};

type NormalAppProps = {
    isAppInitialized: boolean;
    isOtherScreenActive: boolean;
    isRegistration: boolean;
    isRegistrationActivation: boolean;
    isLanding: boolean;
    setOtherScreenActive: (isActive: boolean) => void;
};

const NormalApp: React.FC<NormalAppProps> = ({
    isAppInitialized,
    isOtherScreenActive,
    isLanding,
    isRegistration,
    isRegistrationActivation,
    setOtherScreenActive,
}) => {
    const { pathname } = useLocation();
    const { push: navigate } = useHistory();

    return (
        <LayoutSC.Wrapper>
            <GlobalStyle />
            {isAppInitialized && (
                <>
                    <AppSidebar disabled={isOtherScreenActive} />
                    <LayoutSC.Content>
                        <Header logoOnly={isOtherScreenActive} />
                        <OtherScreens
                            disabled={isRegistration || isRegistrationActivation || isLanding}
                            onFlip={() => navigate(pathname)}
                            setOtherScreenActive={setOtherScreenActive}
                        >
                            <Router />
                        </OtherScreens>
                    </LayoutSC.Content>
                </>
            )}
        </LayoutSC.Wrapper>
    );
};
