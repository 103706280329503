import React, { FC } from 'react';
import { useField } from 'formik';

import { FontFamilyPreviewSC } from './font-family-preview.styles';
import { IconSC, IconUnicodes } from 'components/styles/icons.styles';

interface Props {
  name: string;
  label?: string;
}

export const FontFamilyPreview: FC<Props> = ({ name, label }) => {
  const [field, , ]  = useField(name);

  return (
    <FontFamilyPreviewSC.Container>
      {label && (
        <FontFamilyPreviewSC.LabelWrapper>
          <FontFamilyPreviewSC.Label>{label}</FontFamilyPreviewSC.Label>
          <FontFamilyPreviewSC.Info>
            <IconSC name={IconUnicodes.info} />
            <FontFamilyPreviewSC.Tooltip>
              You can change a font family for all text in H1 dropdown
            </FontFamilyPreviewSC.Tooltip>
          </FontFamilyPreviewSC.Info>
          
        </FontFamilyPreviewSC.LabelWrapper>
      )}
      <FontFamilyPreviewSC.Field>
        {field.value}
      </FontFamilyPreviewSC.Field>
    </FontFamilyPreviewSC.Container>
  )
};
