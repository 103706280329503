import { http } from 'api/http';

import { FinancePaginationType, FinanceProofsStatusType } from 'shared/types';

const get = async (status: FinanceProofsStatusType): Promise<FinancePaginationType | null> => {
    const { data } = await http.get<FinancePaginationType>(`/proofs/list/?status=${status}`);

    return data;
};

const changeStatus = async (status: FinanceProofsStatusType, id: number, value: string) => {
    const data: any = {
        status,
    };

    if (status === 'ACCEPTED') {
        data.amount = value;
    } else {
        data.declined_reason = value;
    }

    return http.put(`/proofs/update/${id}/`, data);
};

const loadMore = async (url: string): Promise<FinancePaginationType | null> => {
    const { data } = await http.get<FinancePaginationType>(url);

    return data;
};

export const createFinanceService = () => ({
    get,
    changeStatus,
    loadMore,
});
