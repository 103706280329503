import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as ReactLink, useHistory } from 'react-router-dom';

import { getImage, ImageName } from 'components/styles/images';

import { usePageScroll } from 'helpers/hooks';
import { useIsLandingPage } from 'units/landing-page/hooks';

import { LPTypography } from '../../ui';

import { Navigation, NavigationItem } from 'units/landing-page/mocks';
import { LPFooterSC } from './footer.styles';

const payments: Array<ImageName> = [
  ImageName.visa, ImageName.mastercard, ImageName.amex, ImageName.apple, ImageName.android,
];

export const LPFooter: FC = () => {
  const { t } = useTranslation();

  return (
    <LPFooterSC.Root>
      <LPFooterSC.Footer>
        <LPFooterSC.Content>
          <LPFooterSC.Description>
            <LPTypography.H2 styles={LPFooterSC.DescriptionTitleCss}>
              {t('lp_footer_title')}
            </LPTypography.H2>
            <LPTypography.Text styles={LPFooterSC.DescriptionTextCss}>
              {t('lp_footer_description')}
            </LPTypography.Text>
          </LPFooterSC.Description>

          <LPFooterSC.Navigation>
            <ul>
              {Navigation.footer.map((item, index) => (
                <Link key={`Footer-main-nav-item-${index}`} data={item} external>{t(item.translation)}</Link>
              ))}
            </ul>
          </LPFooterSC.Navigation>

          <LPFooterSC.Navigation isSub>
            <ul>
              {Navigation.footerSubNav.map((item, index) => (
                <Link key={`Footer-main-sub-nav-item-${index}`} data={item}>{t(item.translation)}</Link>
              ))}
            </ul>
          </LPFooterSC.Navigation>

          <LPFooterSC.Payments>
            <LPFooterSC.PaymentsWrapper>
              <LPFooterSC.PaymentsTitle>
                {t('lp_footer_payment_title')}
              </LPFooterSC.PaymentsTitle>
              <LPFooterSC.PaymentsList>
                {payments.map((image, index) => getImage(image, `LPFooter-image-${index}`))}
              </LPFooterSC.PaymentsList>
            </LPFooterSC.PaymentsWrapper>
          </LPFooterSC.Payments>

        </LPFooterSC.Content>
        <LPFooterSC.Bottom>
          <LPFooterSC.MadeBy>
            {'Made with <3 by mindnow'}
          </LPFooterSC.MadeBy>
          <LPFooterSC.Stripe>
            <span>{t('lp_footer_stripe')}</span>
            {getImage(ImageName.stripe)}
          </LPFooterSC.Stripe>
        </LPFooterSC.Bottom>
      </LPFooterSC.Footer>
    </LPFooterSC.Root>
  );
}

type LinkProps = {
  key:string;
  data:NavigationItem;
  external?:boolean
}

const Link:React.FC<LinkProps> = ({ children, data, external = false }) => {
  const { scrollToSection } = usePageScroll();
  const { takeMeTo } = useIsLandingPage();

  const {location: { pathname }} = useHistory();
  const isLandingPage = pathname === '/landing';

  if(data.section) {
    return (
      <li onClick={() => isLandingPage
        ? data.section && scrollToSection(data.section)
        : data.section && takeMeTo(data.section, scrollToSection)
    }>
        <span>{children}</span>
      </li>
    )
  }

  if(data.url) {
    return (
      <li>
          {external && <a href={data.url}>{children}</a>}
          {!external && (
            <ReactLink to={data.url}>{children}</ReactLink>
          )}
      </li>
    )
  }

  return null;
}
