import React, { FC, useMemo, useState, useCallback, useContext } from 'react';
import { Formik, FormikHelpers, FormikErrors } from 'formik';
import { useDispatch } from 'react-redux';

import { prepareMissedAuctionsProgress } from 'units/cms/helpers/prepare-customization-progress/missed-auctions';
import { useCMSCustomizationLanguage } from 'units/cms/hooks/customization-tab/useCMSCustomizationLanguage';
import { useCMSCustomizationSettings } from 'units/cms/hooks/selectors/useCMSCustomizationSettings';
import { CMSCustomizationPage } from 'units/cms/store/types';
import { useCMSCustomizationFormikSettings } from 'units/cms/hooks/customization-tab/useCMSCustomizationFormikSettings';
import { CMSMissedAuctionsCustomizationSettings } from 'units/cms/store/types/customization/missed-auctions';
import { createOverallUpdatesForCMSMissedAuctions } from 'units/cms/helpers/local-settings/missed-auctions';
import { EmptyFunction } from 'units/common/constants';
import { CMSCustomizationMissedAuctionsSectionName as SectionId } from 'units/cms/types';
import { CMSCustomizationMissedAuctionsValidationSchema } from './validationSchema';
import { CMSCustomizationSidebarForm } from 'units/cms/pages/customization/components/sidebar-form/cms-sidebar-form.component';
import { CMSCustomizationMissedAuctionsAccordions } from './accordions/accordions-missed-auctions.component';
import { CompanyLanguages } from 'units/app/redux/types';
import { isEmptyObject } from 'units/cms/store/helpers';
import { scrollTop } from 'helpers/general';
import { saveCMSCustomizationSettingsConfigThunk, saveAndPublishCMSCustomizationSettingsConfigThunk, updateCMSCustomizationMissedAuctionsThunk } from 'units/cms/store/thunks';
import { NotificationContext } from 'shared/providers';

export const CMSMissedAuctionsSectionHeaders: { [key in SectionId]: string } = {
  'general': 'General',
}

type CMSCustomizationSubmitHandler = (
  values: any,
  helpers: Pick<FormikHelpers<any>, 'setSubmitting' | 'validateForm' | 'setErrors' | 'setTouched'>
) => void;

export const CMSCustomizationMissedAuctionsPage: FC = () => {
  const { showNotification } = useContext(NotificationContext);
  const [activeSections, setActiveSections] = useState<Array<'general'>>(['general']);
  const [selectedLanguage, { changeLanguage, defaultLanguage }] = useCMSCustomizationLanguage();
  const initialMissedAuctionsOverallSettings = useCMSCustomizationSettings(CMSCustomizationPage.MissedAuctions);
  const [overallSettings, { updateSettings }] = useCMSCustomizationFormikSettings<CMSMissedAuctionsCustomizationSettings>(
    initialMissedAuctionsOverallSettings,
    createOverallUpdatesForCMSMissedAuctions,
  );
  const dispatch = useDispatch();

  // ----- Memoized values
  const sectionProgress = useMemo(
    () => prepareMissedAuctionsProgress(initialMissedAuctionsOverallSettings).sectionProgress,
    [initialMissedAuctionsOverallSettings],
  );

  // Formik values from overall settings based on selected language.
  const formikInitialValues = useMemo(
    () => overallSettings[selectedLanguage],
    [selectedLanguage, overallSettings],
  );

// ----- Form language
const handleLanguageChange = useCallback((
  language: CompanyLanguages,
  selectedLanguageSettings: CMSMissedAuctionsCustomizationSettings,
  { resetForm }: Pick<FormikHelpers<any>, 'resetForm'>,
) => {
  if (language === selectedLanguage) {
    return;
  }
  
  const updatedOverallSettings = updateSettings(selectedLanguageSettings, selectedLanguage);
  // Reset form with settings of new selected language
  resetForm({
    values: updatedOverallSettings[language],
  });
  changeLanguage(language);
}, [selectedLanguage, updateSettings, changeLanguage]);

  // ----- Submitting
  // Call direct from save button. Need to alow save in any time and without filling all required fields.
  // Skiping formik pre-validation and validation.
  const handleSaveForm: CMSCustomizationSubmitHandler = useCallback((values, helpers) => {
    helpers.setSubmitting(true);
  
    const finalOverallSettings = updateSettings(values, selectedLanguage);
    dispatch(saveCMSCustomizationSettingsConfigThunk(CMSCustomizationPage.MissedAuctions, finalOverallSettings, showNotification));

    helpers.setSubmitting(false);
  }, [selectedLanguage, updateSettings]);


  // Call direct from save and publish button.
  // Need to validate each language form and find first language and section error and show user.
  const handleSaveAndPublishForm: CMSCustomizationSubmitHandler = useCallback(async (values, helpers) => {
    helpers.setSubmitting(true);
    helpers.setTouched(values);
    
    const finalOverallSettings = updateSettings(values, selectedLanguage);

    const languageErrorList = await Promise.all(Object.entries(finalOverallSettings)
      .map(([lng, settings]) => new Promise<{ lng: CompanyLanguages, errors: FormikErrors<any> }>((res) => {
        helpers.validateForm(settings).then(errors => res({
          lng: lng as CompanyLanguages,
          errors,
        }))
      }))
    );

    // Find selected language errors or fist from list.
    const languageFormError = languageErrorList.find(le => le.lng === selectedLanguage && !isEmptyObject(le.errors))
      || languageErrorList.find(le => !isEmptyObject(le.errors));
    
    if (languageFormError) {
      const { errors, lng } = languageFormError;
      const firstSectionWithErrors = Object.keys(languageFormError.errors)[0] as 'general';

      // Set errors and touched after language changed.
      setTimeout(() => {
        helpers.setErrors(errors);
        helpers.setTouched(errors as any);
      }, 0);

      scrollTop();

      // If section with error is not expanded or there are several expanded sections.
      if (!activeSections.every(s => s === firstSectionWithErrors)) {
        setActiveSections([firstSectionWithErrors]);
      }

      changeLanguage(lng as CompanyLanguages);

      showNotification('error', `Please, update ${CMSMissedAuctionsSectionHeaders[firstSectionWithErrors]} before publish`);
    } else {
      dispatch(saveAndPublishCMSCustomizationSettingsConfigThunk(CMSCustomizationPage.MissedAuctions, finalOverallSettings, showNotification));
    }

    helpers.setSubmitting(false);
  }, [updateSettings, selectedLanguage, changeLanguage, setActiveSections, activeSections]);  

  // ----- Handle accordions
  const handleAccordionExpandedChange = useCallback((
    expanded: Array<string>,
    languageSettings: CMSMissedAuctionsCustomizationSettings,
    helpers: Pick<FormikHelpers<CMSMissedAuctionsCustomizationSettings>, 'resetForm'>,
  ) => {
      setActiveSections(expanded as Array<'general'>);
      handleLanguageChange(defaultLanguage, languageSettings, helpers);
    },
    [setActiveSections, defaultLanguage, handleLanguageChange],
  );

  const onUpdate = useCallback(async (values:CMSMissedAuctionsCustomizationSettings) => {
    dispatch(updateCMSCustomizationMissedAuctionsThunk(values, selectedLanguage));
  }, [selectedLanguage]);

  // ----- Render
  if (!formikInitialValues || !sectionProgress) {
    return null;
  }

  return (
    <Formik
      initialValues={formikInitialValues}
      validationSchema={CMSCustomizationMissedAuctionsValidationSchema}
      onSubmit={EmptyFunction}
      validate={onUpdate}
    >
      <CMSCustomizationSidebarForm
        selectedLanguage={selectedLanguage}
        savedSettings={initialMissedAuctionsOverallSettings}
        onSave={handleSaveForm}
        onSaveAndPublish={handleSaveAndPublishForm}
      >
        <CMSCustomizationMissedAuctionsAccordions
          sectionProgress={sectionProgress}
          activeSections={activeSections}
          handleAccordionExpandedChange={handleAccordionExpandedChange}
          selectedLanguage={selectedLanguage}
          onLanguageChange={handleLanguageChange}
        />
      </CMSCustomizationSidebarForm>
    </Formik>
  );
};
