import { TransitionStatus } from 'react-transition-group/Transition';
import styled from 'styled-components';

import { sizes } from '../styles/constants.styles';

type AnimationProps = {
  duration: number;
}

const slideAbsoluteStyle = `
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`
const appSidebarMargin = 8;
const cmsSidebarMargin = 40;

export const SidebarSlideAnimationContainer = styled.div<AnimationProps & { cmsSidebar: boolean }>`
  display: flex;
  flex-direction: column;

  transition: ${({ duration }) => `${duration}ms ease-in-out`};

  width: ${({ cmsSidebar }) => cmsSidebar
    ? parseInt(sizes.cms_sidebar_width) + cmsSidebarMargin
    : parseInt(sizes.sidebar_width) + appSidebarMargin
  }px;
`;

export const SidebarSlideAnimationWrapper = styled.div<AnimationProps & { status: TransitionStatus }>`
  position: static;
  display: flex;
  flex-direction: column;
  flex: 1;
  transition: ${({ duration }) => `${duration}ms ease-in-out`};
  transform: translateX(-500px);

  ${({ status }) => {
    switch(status) {
      case 'entered':
        return 'transform: translateX(0px);';
      case 'exited':
        return 'transform: translateX(-500px);'
      case 'entering':
        return slideAbsoluteStyle;
      case 'exiting':
        return slideAbsoluteStyle;
      default: return '';
    }
  }}
`;
