import styled from 'styled-components';

import { Color, sizes } from 'components/styles/constants.styles';

const CTAContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  button {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const MockThemes = styled.div`
  height: 344px;
  border-radius: ${sizes.border_radius};
  margin-bottom: 40px;

  background-color: ${Color.semi_grey};
`;

export const CMSThemesSidebarSC = {
  CTAContainer,
  MockThemes,
};
