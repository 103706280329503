import styled from 'styled-components';

import { TextStyle1 } from 'components/styles/text.styles';
import { Color } from 'components/styles/constants.styles';

const Container = styled.div`
  display: flex;
  & > * {
    margin-right: 24px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const Tab = styled.div<{ selected?: boolean }>`
  ${TextStyle1}

  font-size: 16px;
  line-height: 24px;
  cursor: pointer;

  ${({ selected }) => selected && `
    color: ${Color.lightish_blue};
    border-bottom: 2px solid ${Color.lightish_blue};
  `}
`;

export const TabSwitchSC = {
  Container,
  Tab,
}
