import deepmerge from 'deepmerge';

import { CompanyLanguages } from 'units/app/redux/types';
import { TrackListItem } from 'units/common/hooks/useTrackProgress';

// types and internal helpers

export type CMSProgressInitialSection<S extends string, K extends string> = {
  name: S;
  initialProgress: Array<K>;
}

type FilledProgressSections<S extends string, K extends string> = {
  [sectionName in S]: Array<K>;
}

type FilledProgress<S extends string, K extends string> = {
  [language in CompanyLanguages]: FilledProgressSections<S, K>;
}

export type CMSUpdateFilledProgress<S extends string, K extends string> = {
  sectionName: S;
  progressKeys: Array<K>;
}

// Filled progress

const initializeFilledProgress = <S extends string, K extends string>(
  sectionList: Array<CMSProgressInitialSection<S, K>>,
  languages: Array<CompanyLanguages>,
) => {
  const languageSections = sectionList.reduce((res, section) => ({
    ...res,
    [section.name]: [...section.initialProgress],
  }), {} as FilledProgressSections<S, K>);

  const result = languages.reduce((res, lng) => ({
    ...res,
    [lng]: deepmerge(languageSections, {}),
  }), {} as FilledProgress<S, K>);

  return result;
}

const updateFilledProgress = <S extends string, K extends string>(
  filledProgress: FilledProgress<S, K>,
  language: CompanyLanguages,
  updates: Array<CMSUpdateFilledProgress<S, K>>,
) => {
  if (filledProgress.hasOwnProperty(language)) {
    const progressToUpdate = filledProgress[language];
    
    updates.forEach(({ sectionName, progressKeys }) => {
      const section = progressToUpdate[sectionName];
      if (section) {
        const keysToAdd = progressKeys.filter(k => !section.includes(k));
        section.push(...keysToAdd);
      }
    });
  }
}

// Required progress

export type CMSCustomizationCommonProgress<S extends string, K extends string> = RequiredProgress<S, K>;

type RequiredProgress<S extends string, K extends string> = {
  [section in S]: Array<K>;
}

const initializeRequiredProgress = <S extends string, K extends string>(sections: Array<CMSProgressInitialSection<S, K>>) => (
  sections.reduce((res, section) => ({
      ...res,
      [section.name]: [...section.initialProgress]
  }), {} as RequiredProgress<S, K>)
);

const updateRequiredProgress = <S extends string, K extends string>(
  requiredProgress: RequiredProgress<S, K>,
  sectionName: S,
  progressKeys: Array<K>,
) => {
  if (requiredProgress.hasOwnProperty(sectionName)) {
    const keysToAdd = progressKeys.filter(k => !requiredProgress[sectionName].includes(k));
    requiredProgress[sectionName].push(...keysToAdd);
  }
}

// Final sections progress

export type SectionProgress<S extends string, K extends string> = {
  [section in S]: {
    progressFields: Array<K>;
    trackList: Array<TrackListItem>;
    commonFields: Array<K>;
  }
}

const prepareSectionProgress = <S extends string, K extends string>(
  requiredProgress: RequiredProgress<S, K>,
  filledProgress: FilledProgress<S, K>,
  commonFields: RequiredProgress<S, K>,
) => Object.entries(requiredProgress).reduce((res, [sectionName, requiredFields]) => {
  const sectionTrackList: Array<TrackListItem> = Object.entries(filledProgress).map(([language, sections]) => ({
    key: language,
    initialFields: sections[sectionName as S] || [],
  }));

  const sectionCommonFields = commonFields[sectionName as S] || [];
  
  return {
    ...res,
    [sectionName]: {
      progressFields: requiredFields as Array<K>,
      trackList: sectionTrackList,
      commonFields: sectionCommonFields,
    },
  }
}, {} as SectionProgress<S, K>);

export const prepareCustomizationProgress = {
  initializeFilledProgress,
  updateFilledProgress,
  initializeRequiredProgress,
  updateRequiredProgress,
  prepareSectionProgress,
};
