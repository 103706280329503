import styled from 'styled-components';

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const InputsWrapper = styled.div`
  margin-bottom: 32px;
`;

const CTAContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  button:first-child {
    margin-right: 16px;
  }
`;

export const UserEditSC = {
  InputsWrapper,
  CTAContainer,
  TitleContainer,
};
