import React, { FC } from 'react';

import { Form } from 'formik';

import { SharedSC } from '../welcome-shared.styles';
import { ConnectStripeSC } from './connect-stripe.style';

import { getImage, ImageName } from 'components/styles/images';
import { ProgressSteps } from 'units/common/components/progress-steps/progress-steps.component';
interface Props {
  navigate(goBack?: boolean): any;
}

export const ConnectStripe: FC<Props> = ({ navigate }) => (
  <Form>
    <SharedSC.Wrapper>
      {getImage(ImageName.high_five)}
      <SharedSC.PageTitle>Finally, let’s integrate stripe</SharedSC.PageTitle>
      <SharedSC.PageSubtitle width='649px' margin='8px 0 40px 0'>
        We offer you a seemless payment processing with stripe. You need to set up this connection
        to proceed in publishing your storefont.
      </SharedSC.PageSubtitle>

      <ConnectStripeSC.StripeButton margin="0 0 40px 0" disabled>
        Connect to Stripe
      </ConnectStripeSC.StripeButton>

      <SharedSC.ButtonContainer>
        <SharedSC.WelcomeTransparentButton onClick={() => navigate(true)}>
          Back
        </SharedSC.WelcomeTransparentButton>

        <SharedSC.WelcomePrimaryButton type="submit">Skip</SharedSC.WelcomePrimaryButton>
      </SharedSC.ButtonContainer>

      <SharedSC.PageSubtitle margin='96px 0 32px 0'>
        You are already logged in. Only 1 simple step left to start your work.
      </SharedSC.PageSubtitle>
      <ProgressSteps step={3} />
    </SharedSC.Wrapper>
  </Form>
);
