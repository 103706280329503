import styled from 'styled-components';

import { sizes } from 'components/styles/constants.styles';
import { PageTitleText, SectionTitleText, TextStyle25 } from 'components/styles/text.styles';
import { MediaQueries } from 'units/landing-page/config';
interface PageTitleProps {
    smallPadding?: boolean;
}

interface PageTitleContainerProps {
    margin?: string;
    marginTop?: number;
    marginBottom?: number;
}

const Wrapper = styled.div`
    display: flex;
    min-height: 100vh;
    /* max-width: 1440px; */
    margin: 0 auto;
`;

const Content = styled.main`
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 24px ${sizes.lg_padding};
    padding-bottom: 8px;

    @media (max-width: ${MediaQueries.mobile}) {
        padding: 24px ${sizes.sm_padding};
    }
`;

const PageTitleContainer = styled.div<PageTitleContainerProps>`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: ${({ marginBottom }) => marginBottom || 24}px;
    margin-top: ${({ marginTop }) => marginTop || 40}px;
    ${margin =>
        margin &&
        `
    margin: ${margin};
  `}

    h1 {
        margin: 0;
        ${PageTitleText}
    }
`;

const PageTitle = styled('h1')<PageTitleProps>`
    margin: 0;
    padding: ${({ smallPadding }) => (smallPadding ? '16px ' : sizes.lg_padding)} 0;

    ${PageTitleText}
`;

const SectionTitle = styled.h2`
    margin: 0;
    padding: ${sizes.lg_padding} 0;

    ${SectionTitleText}
`;

const NoItemsContainer = styled.div`
    margin: 170px auto 0;
    text-align: center;

    div {
        display: flex;
        justify-content: center;
        margin-bottom: 32px;
    }

    p {
        margin: 0;
        ${TextStyle25}
        line-height: 2;
        text-align: center;
    }
`;

export const LayoutSC = {
    Wrapper,
    Content,
    PageTitle,
    SectionTitle,
    PageTitleContainer,
    NoItemsContainer,
};
