import update from 'immutability-helper';

import * as actionTypes from './action-types';

import { CMSRootState, CMSThemeId, CMSRootActions, CMSCustomizationPage } from './types';
import { CMSDefaultThemeSettingsConfig, CMSDefaultCustomizationConfig } from './constants';
import { getConfigPageName } from './helpers';
import { RootState } from 'redux/root-types';
import { CompanyLanguages } from 'units/app/redux/types';

const initState: CMSRootState = {
    config: {
        themes: {
            activeTheme: CMSThemeId.Traditional,
        },
        themeSettings: CMSDefaultThemeSettingsConfig,
        customization: CMSDefaultCustomizationConfig,
    },

    tempPayload: {
        selectedTheme: CMSThemeId.Traditional,
        isThemeOverviewLoading: true,
        isSaved: true,
        selectedPage: CMSCustomizationPage.Home,
        imagesToDelete: [],
        isCustomizationConfigLoaded: false,
        selectedModal: 'general',
        selectedLanguage: CompanyLanguages.en,
    },
    googleFonts: [],
    availableThemes: [],
    isDirty: false,
};

export function cmsRootReducer(state: CMSRootState = initState, action: CMSRootActions) {
    const currentTheme = state.tempPayload.selectedTheme;

    switch (action.type) {
        // Themes tab

        case actionTypes.CMS_SET_AVAILABLE_THEMES:
            return update(state, {
                availableThemes: {
                    $set: action.themes,
                },
            });

        case actionTypes.CMS_SELECT_THEME:
            return update(state, {
                tempPayload: {
                    selectedTheme: {
                        $set: action.themeId,
                    },
                },
            });

        case actionTypes.CMS_SELECT_ACTIVE_THEME:
            return update(state, {
                tempPayload: {
                    selectedTheme: {
                        $set: state.config.themes.activeTheme,
                    },
                },
            });

        case actionTypes.SET_IMMO_HERO_SECTION_AUCTIONS:
            return update(state, {
                config: {
                    customization: {
                        homepage: {
                            carouselAuctions: {
                                $set: action.auctions,
                            },
                        },
                    },
                },
            });

        case actionTypes.CMS_SET_ACTIVE_THEME:
            const activeTheme = action.themeId;

            return update(state, {
                config: {
                    themes: {
                        activeTheme: {
                            $set: activeTheme,
                        },
                    },
                },
            });

        // Theme settings tab

        case actionTypes.CMS_POPULATE_OVERALL_THEME_SETTINGS_CONFIG:
            const template = action.preselectedTheme || currentTheme;
            const settings = state.config.customization.shopModals.settings;

            Object.values(settings).forEach(value => {
                if (value) {
                    value.general = action.config[template].settings.colors.forms;
                }
            });

            return update(state, {
                config: {
                    themeSettings: {
                        $set: action.config,
                    },
                    customization: {
                        shopModals: {
                            $set: {
                                settings,
                                isPublished: action.config[template].isPublished,
                            },
                        },
                    },
                },
                tempPayload: {
                    selectedTheme: {
                        $set: template,
                    },
                },
            });

        case actionTypes.CMS_SET_THEME_SETTINGS_CONFIG:
            const themeToUpdate = action.theme || currentTheme;
            const shopModalsFormState = state.config.customization.shopModals.settings;

            Object.values(shopModalsFormState).forEach(value => {
                if (value) {
                    value.general = action.themeSettingsConfig.settings.colors.forms;
                }
            });

            return update(state, {
                config: {
                    themeSettings: {
                        $merge: {
                            [themeToUpdate]: action.themeSettingsConfig,
                        },
                    },
                    customization: {
                        shopModals: {
                            settings: {
                                $set: shopModalsFormState,
                            },
                        },
                    },
                },
            });

        // Customization tab

        case actionTypes.CMS_CUSTOMIZATION_SET_PAGE:
            return update(state, {
                tempPayload: {
                    selectedPage: {
                        $set: action.page,
                    },
                },
            });

        case actionTypes.CMS_SET_SELECTED_MODAL:
            return update(state, {
                tempPayload: {
                    selectedModal: {
                        $set: action.modal,
                    },
                },
            });

        case actionTypes.CMS_DIRTY_STATE:
            return update(state, {
                isDirty: {
                    $set: action.value,
                },
            });

        case actionTypes.CMS_POPULATE_CUSTOMIZATION_CONFIG:
            return update(state, {
                config: {
                    customization: { $set: action.config },
                },
                tempPayload: {
                    isCustomizationConfigLoaded: { $set: true },
                },
            });

        case actionTypes.CMS_SET_CUSTOMIZATION_PAGE_CONFIG: {
            const pageName = getConfigPageName(action.page);
            return update(state, {
                config: { customization: { [pageName]: { $set: action.config } } },
            });
        }

        case actionTypes.CMS_UPDATE_SHOP_MODAL_CUSTOMIZATION_CONFIG:
            const themeSettingsToUpdate = state.config.themeSettings[currentTheme];
            themeSettingsToUpdate.settings.colors.forms = action.config.general;

            const customizationToUpdate = state.config.customization.shopModals;
            customizationToUpdate.settings[action.selectedLanguage] = action.config;

            Object.values(customizationToUpdate.settings).forEach(value => {
                if (value) value.general = action.config.general;
            });

            return update(state, {
                config: {
                    themeSettings: {
                        $merge: {
                            [currentTheme]: { ...themeSettingsToUpdate },
                        },
                    },
                    customization: {
                        shopModals: {
                            $set: { ...customizationToUpdate },
                        },
                    },
                },
            });

        case actionTypes.CMS_UPDATE_SELECTED_LANGUAGE:
            return update(state, {
                tempPayload: {
                    selectedLanguage: {
                        $set: action.language,
                    },
                },
            });

        case actionTypes.CMS_UPDATE_MISSED_AUCTIONS_CUSTOMIZATION_CONFIG:
            return update(state, {
                config: {
                    customization: {
                        missedAuctions: {
                            $set: { ...action.missedAuctions },
                        },
                    },
                },
            });

        case actionTypes.CMS_UPDATE_PRODUCT_DETAIL_CUSTOMIZATION_CONFIG:
            return update(state, {
                config: {
                    customization: {
                        productDetailPage: {
                            $set: { ...action.data },
                        },
                    },
                },
            });

        case actionTypes.CMS_UPDATE_HOME_CUSTOMIZATION_CONFIG:
            return update(state, {
                config: {
                    customization: {
                        homepage: {
                            $set: { ...action.data },
                        },
                    },
                },
            });

        // Additional

        case actionTypes.CMS_THEME_OVERIVEW_IS_LOADING:
            return update(state, {
                tempPayload: {
                    isThemeOverviewLoading: {
                        $set: true,
                    },
                },
            });

        case actionTypes.CMS_THEME_OVERVIEW_LOADING_ENDED:
            return update(state, {
                tempPayload: {
                    isThemeOverviewLoading: {
                        $set: false,
                    },
                },
            });

        case actionTypes.CMS_SET_CONFIG_SAVE_STATUS:
            return update(state, {
                tempPayload: {
                    isSaved: {
                        $set: action.isSaved,
                    },
                },
            });

        case actionTypes.CMS_SET_GOOGLE_FONTS:
            return update(state, {
                googleFonts: {
                    $set: action.items,
                },
            });

        case actionTypes.CMS_MARK_IMAGES_TO_DELETE:
            return update(state, {
                tempPayload: {
                    imagesToDelete: {
                        $push: action.imageIds,
                    },
                },
            });

        // Clearing

        case actionTypes.CMS_CLEAR_TEMP_PAYLOAD:
            return update(state, {
                tempPayload: {
                    $set: {
                        selectedTheme: action.theme || CMSThemeId.Traditional,
                        isThemeOverviewLoading: true,
                        isSaved: true,
                        selectedPage: CMSCustomizationPage.Home,
                        imagesToDelete: [],
                        isCustomizationConfigLoaded: false,
                        selectedModal: 'general',
                        selectedLanguage: CompanyLanguages.en,
                    },
                },
            });

        default: {
            return state;
        }
    }
}

export const cmsSelectedPageSelector = (state: RootState) => state.cms.tempPayload.selectedPage;
export const cmsSelectedThemeSelector = (state: RootState) => state.cms.tempPayload.selectedTheme;
export const cmsSelectedModalSelector = (state: RootState) => state.cms.tempPayload.selectedModal;
export const cmsCustomizationThemeSelector = (state: RootState) => state.cms.config.customization;
export const cmsSelectedLanguageSelector = (state: RootState) =>
    state.cms.tempPayload.selectedLanguage;
export const cmsThemeStylesSelector = (state: RootState) =>
    state.cms.config.themeSettings[state.cms.tempPayload.selectedTheme].settings;
export const cmsCustomizationThemeStylesSelector = (state: RootState) =>
    state.cms.config.customization.shopModals.settings[state.cms.tempPayload.selectedLanguage]
        ?.general;
export const cmsSettingDirtyState = (state: RootState) => state.cms.isDirty;
