import React, { FC } from 'react';

import { CmsAcordionSectionSC } from './cmsAcordionSection.styles';

export type CMSAccordionTitleStyle = 'default' | 'black';

interface Props {
  title?: string;
  titleStyle?: CMSAccordionTitleStyle; 
  lastSection?: boolean;
  withDevider?: boolean;
  sectionMargin?: number | string;
}

export const CmsAcordionSection: FC<Props> = ({
  title,
  titleStyle = 'default',
  lastSection,
  children,
  withDevider = true,
  sectionMargin,
}) => (
  <CmsAcordionSectionSC.Container marginBottom={sectionMargin}>
    { title && (
      <CmsAcordionSectionSC.Title titleStyle={titleStyle}>
        {title}
      </CmsAcordionSectionSC.Title>
    )}

    <CmsAcordionSectionSC.Content>
      {children}
    </CmsAcordionSectionSC.Content>

    { withDevider && <CmsAcordionSectionSC.Devider lastSection={lastSection} /> }
  </CmsAcordionSectionSC.Container>
);
