import React from 'react';

import { IconSC, IconUnicodes } from 'components/styles/icons.styles';

import { WebsiteButtonSC } from './website-button.styles';
import { CMSUrls } from 'units//cms/urls';

export const WebsiteButton = () => (
    <WebsiteButtonSC to={CMSUrls.index}>
        Website
        <IconSC name={IconUnicodes.angle_right} />
    </WebsiteButtonSC>
);
