import React, { Suspense } from 'react';
import { Router as BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { HelmetProvider } from 'react-helmet-async';

import { NotificationProvider } from 'shared/providers';

import { store, persistor } from 'redux/store';
import { history } from 'helpers/history';
import { App } from './app';
import 'config/i18n';
import { ScrollToTop } from 'helpers/hooks';

export const Root = () => (
    <Suspense fallback={null}>
        <HelmetProvider>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <BrowserRouter history={history}>
                        <NotificationProvider>
                            <ScrollToTop />
                            <App />
                        </NotificationProvider>
                    </BrowserRouter>
                </PersistGate>
            </Provider>
        </HelmetProvider>
    </Suspense>
);
