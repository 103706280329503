import deepmerge from 'deepmerge';

import {
    CMSThemeSettingsResponse,
    CMSThemeSettingsOverallConfig,
    CMSThemeSettings,
} from '../types/theme-settings';
import { CMSThemeId, CMSImageToSave, CMSImageToDelete, CMSUploadedImage } from '../types';
import {
    CMSDefaultTraditionalThemeSettings,
    CMSDefaultLuxuryThemeSettings,
    CMSDefaultFunkyThemeSettings,
    CMSDefaultMinimalThemeSettings,
    CMSDefaultModernThemeSettings,
    CMSDefaultEstateThemeSettings,
} from '../constants';
import { CMSFormikThemeSettings } from '../../types';
import { isCMSSavedImage, CMSImageKey, findCMSUploadedImage, handleCMSImageStoring } from './index';

export const getDefaultThemeStyles = (theme: CMSThemeId) => {
    let data = CMSDefaultTraditionalThemeSettings;

    if (theme === CMSThemeId.Funky) data = CMSDefaultFunkyThemeSettings;
    if (theme === CMSThemeId.Luxury) data = CMSDefaultLuxuryThemeSettings;
    if (theme === CMSThemeId.Minimal) data = CMSDefaultMinimalThemeSettings;
    if (theme === CMSThemeId.Modern) data = CMSDefaultModernThemeSettings;
    if (theme === CMSThemeId.Estate) data = CMSDefaultEstateThemeSettings;

    return data;
};

// Convert array of theme settings from response to
// theme settings overall config and find active theme
export const parseCMSThemeSettingsListResponse = (
    themeSettingsList: Array<CMSThemeSettingsResponse>,
    options?: {
        mergeWithDefault?: boolean;
        slug: string;
    },
) => {
    let activeTheme = options?.slug === 'immo' ? CMSThemeId.Estate : CMSThemeId.Traditional;

    const config = themeSettingsList.reduce((overallConfig, tsettings) => {
        if (tsettings.active) {
            activeTheme = tsettings.theme;
        }

        const defaultTheme = getDefaultThemeStyles(tsettings.theme);

        return {
            ...overallConfig,
            [tsettings.theme as CMSThemeId]: {
                settings: options?.mergeWithDefault
                    ? cmsMergeThemeSettingsWithDefault(tsettings.settings, defaultTheme)
                    : tsettings.settings,
                isPublished: tsettings.settings_published,
            },
        };
    }, {} as CMSThemeSettingsOverallConfig);

    return { config, activeTheme };
};

// Deep merge theme settings with default ones.
export const cmsMergeThemeSettingsWithDefault = (
    themeSettings: CMSThemeSettings,
    defaultData: CMSThemeSettings,
) => deepmerge(defaultData, themeSettings);

const buildCMSThemeSettingsImagesToSave = ({ logo, favicon }: CMSFormikThemeSettings) => {
    const imagesToSave: Array<CMSImageToSave> = [];

    if (logo && !isCMSSavedImage(logo)) {
        imagesToSave.push({
            key: CMSImageKey.logo,
            image: logo,
        });
    }

    if (favicon && !isCMSSavedImage(favicon)) {
        imagesToSave.push({
            key: CMSImageKey.favicon,
            image: favicon,
        });
    }

    return imagesToSave;
};

// Inject uploaded images into theme settings if have one under uploadedImages.
// Need to do all this steps to get typescript understand that we return exactly CMSThemeSettings.
const injectCMSThemeSettingsUploadedImages = (
    themeSettings: CMSFormikThemeSettings,
    uploadedImages: Array<CMSUploadedImage>,
): CMSThemeSettings => {
    const [uploadedLogo, uploadedFavicon] = [CMSImageKey.logo, CMSImageKey.favicon].map(key =>
        findCMSUploadedImage(uploadedImages, key),
    );

    const [oldLogo, oldFavicon] = [themeSettings.logo, themeSettings.favicon].map(img =>
        isCMSSavedImage(img) ? img : null,
    );

    return {
        ...themeSettings,
        logo: uploadedLogo || oldLogo,
        favicon: uploadedFavicon || oldFavicon,
    };
};

// Here we handle the logic with saving new images to db and retrieving uploaded ones.
// Deleting images that we marked from form as one that need to be deleted.
export const prepareCMSThemeSettingsToSaveWithImages = async (
    themeSettings: CMSFormikThemeSettings,
    imagesToDelete: Array<CMSImageToDelete>,
) => {
    const imagesToSave = buildCMSThemeSettingsImagesToSave(themeSettings);
    const uploadedImages = await handleCMSImageStoring(imagesToSave, imagesToDelete);
    return injectCMSThemeSettingsUploadedImages(themeSettings, uploadedImages);
};
