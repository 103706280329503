import React, { FC } from 'react';

import { CmsAcordionSection } from 'units/cms/components/cms-acordion-section/cmsAcordionSection.component';
import { FormikToggleButton } from 'units/common/components/formik/toggle/formik-toggle';

export const CMSProductDetailPageNewsletterAccordion: FC = () => (
    <>
      <CmsAcordionSection sectionMargin={32} withDevider={false}>
        <FormikToggleButton
          name="newsletterOptIn.isActive"
          label="Active"
        />
      </CmsAcordionSection>
    </>
);
