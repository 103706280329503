import React from 'react';

import { CmsAcordionSection } from 'units/cms/components/cms-acordion-section/cmsAcordionSection.component';
import { FormikRadioButtonGroup } from 'units/common/components/formik/radio/radio-group.component';
import { FormikSelect } from 'units/common/components/formik/select/select.component';
import { FontFamilyPreview } from './font-family-preview/font-family-preview.component';
import { useCMSGoogleFonts } from 'units/cms/hooks/selectors/useCMSGoogleFonts';
import { CmsTypographySC } from './cms-typography.styles';
import { useTranslation } from 'react-i18next';

const h1RadioList = [
    { id: '48', label: '48' },
    { id: '32', label: '32' },
    { id: '24', label: '24' },
];

const h2RadioList = [
    { id: '32', label: '32' },
    { id: '24', label: '24' },
    { id: '16', label: '16' },
];

const h3RadioList = [
    { id: '26', label: '26' },
    { id: '18', label: '18' },
    { id: '14', label: '14' },
];

const pRadioList = [
    { id: '18', label: '18' },
    { id: '16', label: '16' },
];

export const CMSTypographyAccordion = () => {
    const { t } = useTranslation();
    const fonts = useCMSGoogleFonts();

    return (
        <>
            <CmsAcordionSection title={t('theme_settings_typography_h1')}>
                <FormikSelect
                    options={fonts}
                    label={t('theme_settings_typography_font_family')}
                    margin="0 0 0 0 !important"
                    name="typography.heading.fontFamily"
                    placeholder="Choose a font family from a list"
                />
                <CmsTypographySC.RadioWrapper>
                    <FormikRadioButtonGroup
                        margin="0 0 0 0"
                        label={t('theme_settings_typography_font_size')}
                        radioList={h1RadioList}
                        name="typography.heading.fontSizes.h1"
                    />
                </CmsTypographySC.RadioWrapper>
            </CmsAcordionSection>

            <CmsAcordionSection title={t('theme_settings_typography_h2')}>
                <FontFamilyPreview
                    label={t('theme_settings_typography_font_family')}
                    name="typography.heading.fontFamily"
                />
                <CmsTypographySC.RadioWrapper>
                    <FormikRadioButtonGroup
                        margin="0 0 0 0"
                        label={t('theme_settings_typography_font_size')}
                        radioList={h2RadioList}
                        name="typography.heading.fontSizes.h2"
                    />
                </CmsTypographySC.RadioWrapper>
            </CmsAcordionSection>

            <CmsAcordionSection title={t('theme_settings_typography_h3')}>
                <FontFamilyPreview
                    label={t('theme_settings_typography_font_family')}
                    name="typography.heading.fontFamily"
                />
                <CmsTypographySC.RadioWrapper>
                    <FormikRadioButtonGroup
                        margin="0 0 0 0"
                        label={t('theme_settings_typography_font_size')}
                        radioList={h3RadioList}
                        name="typography.heading.fontSizes.h3"
                    />
                </CmsTypographySC.RadioWrapper>
            </CmsAcordionSection>

            <CmsAcordionSection title={t('theme_settings_typography_paragraph')} lastSection>
                <FormikSelect
                    options={fonts}
                    label={t('theme_settings_typography_font_family')}
                    margin="0 0 0 0 !important"
                    name="typography.paragraphBody.fontFamily"
                    placeholder="Choose a font family from a list"
                />
                <CmsTypographySC.RadioWrapper>
                    <FormikRadioButtonGroup
                        margin="0 0 0 0"
                        label={t('theme_settings_typography_font_size')}
                        radioList={pRadioList}
                        name="typography.paragraphBody.fontSize"
                    />
                </CmsTypographySC.RadioWrapper>
            </CmsAcordionSection>
        </>
    );
};
