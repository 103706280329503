import React, { useState, InputHTMLAttributes, FC, useCallback, useMemo } from 'react';
import { useField, useFormikContext } from 'formik';

import { UserInputSC } from 'units/common/components/form/fields/user-input/user-input.styles';
import { IconSC, IconUnicodes } from 'components/styles/icons.styles';
import { Color } from 'components/styles/constants.styles';
import { CreatePasswordInputRequirementsPanel, CreatePasswordInputRequirement } from './requirements-panel/requirements-panel.component';
import { Appi18nStrings } from 'config/strings';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: string;
  name: string;
}

const PasswordRequirementId = {
  min: 'password_min_requirement',
  oneUpperCase: 'password_one_upper_case_requirement',
  oneLowerCase: 'password_one_lower_case_requirement',
  oneNumber: 'password_one_number_requirement',
  oneSpecialCharacter: 'password_one_special_character_requirement',
}

const passwordRequirements: Array<CreatePasswordInputRequirement> = [
  { id: PasswordRequirementId.min, title: Appi18nStrings.passwordRequirements.min, isCorrect: false },
  { id: PasswordRequirementId.oneUpperCase, title: Appi18nStrings.passwordRequirements.oneUpperCase, isCorrect: false },
  { id: PasswordRequirementId.oneLowerCase, title: Appi18nStrings.passwordRequirements.oneLowerCase, isCorrect: false },
  { id: PasswordRequirementId.oneNumber, title: Appi18nStrings.passwordRequirements.oneNumber, isCorrect: false },
  { id: PasswordRequirementId.oneSpecialCharacter, title: Appi18nStrings.passwordRequirements.oneSpecialCharacter, isCorrect: false },
];

export const CreatePasswordInput: FC<Props> = ({ label, type, name, error, ...props }) => {
  const [showFieldValue, setShowFieldValue] = useState(false);
  const [showRequirements, setShowRequirements] = useState(false);
  const [field] = useField(name);
  const { handleBlur } = useFormikContext();

  const requirements = useMemo(() => {
    const password = field.value;

    const errors = {
      [PasswordRequirementId.min]: password.length >= 8,
      [PasswordRequirementId.oneNumber]: /[0-9]/.test(password),
      [PasswordRequirementId.oneUpperCase]: /[A-Z]/.test(password),
      [PasswordRequirementId.oneLowerCase]: /[a-z]/.test(password),
      [PasswordRequirementId.oneSpecialCharacter]: /(?=.*?[#?!@$%^&*-])/.test(password),
    };

    return passwordRequirements.map(requirement => ({
      ...requirement,
      isCorrect: errors[requirement.id],
    }));
  }, [field.value]);

  const blurHandler = useCallback(
    e => {
      setShowRequirements(false);
      handleBlur(e);
    },
    [handleBlur],
  );

  return (
    <UserInputSC.InputContainer className={props.className}>
      {label && <UserInputSC.Label>{label}</UserInputSC.Label>}
      <UserInputSC.InputWrapper>
        <UserInputSC.Input
          {...props}
          {...field}
          type={showFieldValue ? 'text' : 'password'}
          onFocus={() => setShowRequirements(true)}
          onBlur={blurHandler}
        />
        <UserInputSC.ShowPasswordButton
          onClick={() => setShowFieldValue(!showFieldValue)}
          type="button"
          tabIndex={-1}
        >
          {showFieldValue ? (
            <IconSC color={Color.cloudy_blue} name={IconUnicodes.eye_off} size={25} />
          ) : (
            <IconSC color={Color.cloudy_blue} name={IconUnicodes.eye} size={25} />
          )}
        </UserInputSC.ShowPasswordButton>
        {showRequirements && <CreatePasswordInputRequirementsPanel requirements={requirements} />}
      </UserInputSC.InputWrapper>
      {error && <UserInputSC.Error>{error}</UserInputSC.Error>}
    </UserInputSC.InputContainer>
  );
};
