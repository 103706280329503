export enum RefundStatusEnum {
    Canceled = 1,
    Failed = 2,
    Pending = 3,
    Succeeded = 4 
}

export type RefundOrder = {
    amount:string;
}

export type RefundItemType = {
    amount:string;
    created_at:string;
    external_id:string;
    id:number;
    payment:number;
    status: RefundStatusEnum;
    succeeded_at:string | null;
    canceled_at: null | string;
    failed_at: null | string;
    failure_reason: null | string;
}