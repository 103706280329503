import { ApiClient, apiPath } from 'config/api/api';
import { OrdersType, OverviewStatusInfo, OverviewStatusInfoResponse } from '../types';

const getOverviewStatuses = async (): Promise<OverviewStatusInfo> => {
    const api = ApiClient.getInstance();

    const { data } = await api.get<OverviewStatusInfoResponse>(`${apiPath}/orders/overview-info/`);

    return {
        unpaidOrders: data.unpaid_orders,
        unfulfilledOrders: data.unfulfilled_orders,
        totalIncomeDifference: +data.total_income_difference,
        totalOrderDifference: data.total_order_difference,
        weekTotalIncome: +data.week_total_income,
        weekTotalOrdersCount: data.week_total_orders_count,
        refunded: +data.refunded,
    };
};

const getOrders = async (
    page: number,
    pageSize: number,
    startsAt?: string,
    endsAt?: string,
): Promise<OrdersType> => {
    const api = ApiClient.getInstance();
    const { data } = await api.get<OrdersType>(
        `${apiPath}/orders/?page=${page}&page_size=${pageSize}${
            startsAt && `&starts_at=${startsAt}`
        }${endsAt && `&ends_at=${endsAt}`}`,
    );
    return data;
};

const loadMoreOrders = async (nextLink: string): Promise<OrdersType> => {
    const api = ApiClient.getInstance();
    nextLink = nextLink.replace('http', 'https');
    const { data } = await api.get<OrdersType>(nextLink);
    return data;
};

export const OverviewService = {
    getOverviewStatuses,
    getOrders,
    loadMoreOrders,
};

// const getOrders = async (
//     page: number,
//     pageSize: number,
//     startsAt?: string,
//     endsAt?: string,
// ): Promise<OrdersType> => {
//     const api = ApiClient.getInstance();
//     const { data } = await api.get<OrdersType>(
//         `${apiPath}/orders/?page=${page}&page_size=${pageSize}${
//             startsAt ? `?starts_at=${startsAt}` : ''
//         }${endsAt ? `?ends_at=${endsAt}` : ''}`,
//     );
//     return data;
// };
