import styled from 'styled-components';

const Group = styled.div`
  display: flex;
`;

const CircleWrapper = styled.div<{ isSelected?: boolean, marginRight?: string }>`
  margin-right: ${({ marginRight }) => marginRight || '24px'};
  
  &:last-child {
    margin-right: 0;
  }

  box-sizing: border-box;
  opacity: 0.32;

  ${({ isSelected }) => isSelected && `
    opacity: 1;
  `}
`;

export const CircleProgressGroupSC = {
  Group,
  CircleWrapper,
};
