import clsx from 'clsx';
import React, { FC, useState } from 'react';
import { Checkbox, Icon } from 'shared/components/ui';
import styles from './auctions-filters.module.scss';

const STATUS_LIST = ['Active', 'Scheduled', 'Unscheduled', 'Ended'];

interface AuctionsFiltersProps {
    onChange?: (statuses: string[]) => void;
}

export const AuctionsFilters: FC<AuctionsFiltersProps> = ({ onChange }) => {
    const [statuses, setStatuses] = useState(['Active', 'Scheduled', 'Unscheduled', 'Ended']);
    const [toggle, setToggle] = useState(false);

    const updateStatus = (type: string, state: boolean) => {
        const arr = statuses;
        const index = arr.findIndex(s => s === type);
        if (!state) arr.splice(index, 1);
        if (state) arr.push(type);
        setStatuses(arr);
        onChange && onChange(arr);
    };

    return (
        <div className={styles.filter}>
            <div className={styles.toggler} onClick={() => setToggle(!toggle)}>
                Filters by status <Icon name="filter" />
            </div>
            <div className={clsx(styles.dropdownWrapper, toggle && styles.toggled)}>
                <div className={styles.filterDropdown} onMouseLeave={() => setToggle(false)}>
                    {STATUS_LIST.map(s => (
                        <Checkbox
                            key={s}
                            label={s}
                            onChange={state => updateStatus(s, state)}
                            checked={statuses.includes(s)}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};
