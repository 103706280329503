import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, FormikHelpers } from 'formik';

import { ContactRequestValues, sendContactRequest } from 'units/landing-page/api';

import { LPButton, LPFormElement, LPInputError, LPTypography } from '../../ui';

import { contactFormValidationSchema } from './validation';

import handIcon from '../../../images/icons/hand.svg';
import pencilIcon from '../../../images/icons/pencil.svg';

import { LPContactSectionSC } from './contact.styles';
import { LandingPageSC } from 'units/landing-page/index.styles';
import { NotificationContext } from 'shared/providers';

const formInitialValues = {
    email: '',
    message: '',
    first_name: '',
    last_name: '',
    company: '',
};

export const LPContactSection = () => {
    const { showNotification } = useContext(NotificationContext);
    const { t } = useTranslation();

    const onSubmit = async (
        values: ContactRequestValues,
        formikHelpers: FormikHelpers<ContactRequestValues>,
    ) => {
        const { status } = await sendContactRequest(values);

        if (status === 200) {
            formikHelpers.resetForm();
            showNotification('success', t('lp_form_success'));
        }
    };

    return (
        <LPContactSectionSC.Root id="demo">
            <LandingPageSC.SectionSeparator />
            <LPTypography.H2>{t('lp_contact_title')}</LPTypography.H2>
            <LPTypography.Text styles={LPContactSectionSC.TextCss}>
                {t('lp_contact_subtitle')}
            </LPTypography.Text>
            <LPButton.Outline
                styles={LPContactSectionSC.PhoneBtnCss}
                onClick={() => window.open('tel:+1800229933')}
            >
                <LPContactSectionSC.HandIcon src={handIcon} alt="hand icon" />
                +41 43 508 98 43
            </LPButton.Outline>

            <LPContactSectionSC.Separate>
                <LPContactSectionSC.PencilIcon src={pencilIcon} alt="pencil icon" />
            </LPContactSectionSC.Separate>

            <Formik
                initialValues={formInitialValues}
                validationSchema={contactFormValidationSchema}
                onSubmit={onSubmit}
                isInitialValid={false}
            >
                {({ values, errors, touched, isValid, handleChange, handleBlur, handleSubmit }) => (
                    <LPContactSectionSC.Form onSubmit={handleSubmit}>
                        <LPContactSectionSC.InputLine>
                            <LPContactSectionSC.InputWrapper>
                                <LPFormElement.Input
                                    name="first_name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.first_name}
                                    placeholder={t('lp_contact_first_name_label')}
                                />
                                {errors.first_name && touched.first_name && (
                                    <LPInputError>{t(errors.first_name)}</LPInputError>
                                )}
                            </LPContactSectionSC.InputWrapper>
                            <LPContactSectionSC.InputWrapper>
                                <LPFormElement.Input
                                    name="last_name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.last_name}
                                    placeholder={t('lp_contact_surname_label')}
                                />
                                {errors.last_name && touched.last_name && (
                                    <LPInputError>{t(errors.last_name)}</LPInputError>
                                )}
                            </LPContactSectionSC.InputWrapper>
                        </LPContactSectionSC.InputLine>
                        <LPContactSectionSC.InputLine>
                            <LPContactSectionSC.InputWrapper>
                                <LPFormElement.Input
                                    name="company"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.company}
                                    placeholder={t('lp_contact_componies_label')}
                                />
                                {errors.company && touched.company && (
                                    <LPInputError>{t(errors.company)}</LPInputError>
                                )}
                            </LPContactSectionSC.InputWrapper>
                            <LPContactSectionSC.InputWrapper>
                                <LPFormElement.Input
                                    name="email"
                                    type="email"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                    placeholder={t('lp_contact_email_label')}
                                />
                                {errors.email && touched.email && (
                                    <LPInputError>{t(errors.email)}</LPInputError>
                                )}
                            </LPContactSectionSC.InputWrapper>
                        </LPContactSectionSC.InputLine>
                        <LPContactSectionSC.TextareaWrapper>
                            <LPFormElement.Textarea
                                name="message"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.message}
                                placeholder={t('lp_contact_message_label')}
                                rows={5}
                            />
                            {errors.message && touched.message && (
                                <LPInputError>{t(errors.message)}</LPInputError>
                            )}
                        </LPContactSectionSC.TextareaWrapper>
                        <LPButton.Primary
                            disabled={!isValid}
                            styles={LPContactSectionSC.SubmitBtnCss}
                        >
                            {t('lp_contact_submit_text')}
                        </LPButton.Primary>
                    </LPContactSectionSC.Form>
                )}
            </Formik>
        </LPContactSectionSC.Root>
    );
};
