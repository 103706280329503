import React, { useState } from 'react';
import clsx from 'clsx';

import styles from './Dropdown.module.scss';
import { Icon } from 'shared/components/ui';
import { useTranslation } from 'react-i18next';

export type ImmoDropdownItemType = {
    id: string;
    value: string;
    image?: string | null;
};

type ImmoDropdownProps = {
    className?: string;
    data: ImmoDropdownItemType[];
    active: ImmoDropdownItemType | null;
    isReadOnly?: boolean;
    onSelect: (value: ImmoDropdownItemType) => void;
    onBlur: () => void;
};

export const ImmoDropdown: React.FC<ImmoDropdownProps> = ({
    data,
    className,
    active,
    isReadOnly,
    onSelect,
    onBlur,
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const { t } = useTranslation();

    return (
        <div
            className={clsx(styles.root, className, { [styles['is-open']]: isOpen && !isReadOnly })}
            onClick={onBlur}
            onMouseLeave={setIsOpen.bind(null, false)}
        >
            <div
                className={clsx(styles.active, { [styles['have-active']]: active !== null })}
                onMouseEnter={setIsOpen.bind(null, true)}
            >
                <span>{active?.value || t('dropdown_not_selected')}</span>
                <Icon name="arrow-right" />
            </div>
            {!isReadOnly && (
                <ul className={styles.dropdown}>
                    {data.map(item => (
                        <li
                            key={item.id}
                            onClick={() => {
                                onSelect(item);
                                setIsOpen(false);
                            }}
                        >
                            {item.image && <img src={item.image} alt="" />}
                            {item.value}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};
