import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/root-types';
import { UserState } from 'units/user/redux/types';

import { CMSTabSwitch } from '../tab-switch/tab-switch.component';
import { CMSHeaderSC } from './cms-header.styles';
import { PageSelector } from './page-selector/page-selector.component';

//const FAKE_PROGRESS_DURATION = FAKE_OVERVIEW_LOADING_DURATION - 80;

export const CMSHeader = () => {
    const { user } = useSelector<RootState, UserState>(state => state.user);
    /* const isSaved = useCMSConfigSavedStatus();
  const isPublished = useCMSConfigPublishedStatus(); */
    //const [isThemeOverviewLoading] = useCMSThemeOverviewLoadingManager();

    /*   const statusMark = useMemo(() => {
    let text = 'Your website will look like this when you save changes.';

    if (isSaved) {
      text = !isPublished ? 'Unpublished changes in this section.' : 'Your website looks like this.';
    }

    return {
      text,
      highlighted: isSaved && !isPublished,
    }
  }, [isSaved, isPublished]); */

    return (
        <>
            <CMSHeaderSC.TabsWrapper>
                <CMSTabSwitch />
                {user && user.user_type && user.user_type.slug !== 'immo' && <PageSelector />}
            </CMSHeaderSC.TabsWrapper>

            <CMSHeaderSC.StatusContainer>
                {/*         { isThemeOverviewLoading
          ? (
            <ProgressBar.Fake
              duration={FAKE_PROGRESS_DURATION}
              progressColor={Color.lightish_blue}
              fillColor={Color.white}
            />
          ) : ( */}
                {/* <CMSHeaderSC.StatusMark highlighted={statusMark.highlighted}>
              { statusMark.text }
            </CMSHeaderSC.StatusMark> */}
                {/* //)} */}
            </CMSHeaderSC.StatusContainer>
        </>
    );
};
