import React, { useCallback, useContext } from 'react';
import { Formik, Form } from 'formik';
import { useSelector } from 'react-redux';

import { EnterNewPassword } from '../reset-password/enter-new-password/enter-new-password.component';
import { resetPasswordNewPasswordValidationSchema } from '../reset-password/validation';
import { UserState } from '../../redux/types';
import { acceptInvitationThunk } from '../../redux/thunks';
import { RootState } from 'redux/root-types';
import { UserErrorKeys } from '../../constants';
import { useAsyncDispatch } from 'helpers/hooks/useAsyncDispatch';
import { AppStrings } from 'config/strings';
import { NotificationContext } from 'shared/providers';

const initialValues = {
  password: '',
  repeatPassword: '',
};

export const CreatePassword = () => {
  const { showNotification } = useContext(NotificationContext);
  const { error: userError, tempPayload: { invitationToken } } = useSelector<RootState, UserState>((state) => state.user);
  const { dispatch: fetchDispatch } = useAsyncDispatch();

  const submitHandler = useCallback((values, actions) => {
    if (invitationToken) {
      fetchDispatch(acceptInvitationThunk({
        token: invitationToken,
        password: values.password,
        repeatPassword: values.repeatPassword,
      }, showNotification));
      actions.setSubmitting(false);
    } else {
      showNotification('error', AppStrings.acceptInvitationErr);
    }
  }, [fetchDispatch, invitationToken]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={resetPasswordNewPasswordValidationSchema}
      onSubmit={submitHandler}
    >
      <Form>
        <EnterNewPassword userError={userError?.[UserErrorKeys.acceptInvitation]} />
      </Form>
    </Formik>
  );
}
