import React, { FC, useCallback, useContext } from 'react';
import { FileRejection } from 'react-dropzone';

import { FileUploader } from 'units/common/components/uploader/file-uploader.component';
import { FileUploaderErrors } from 'units/common/components/uploader/base-uploader.component';
import { CMSUploaderSC } from './cms-uploader.styles';
import { SecondaryButton } from 'units/common/components/buttons/secondary/secondary.component';
import { getImgSize } from 'helpers/general';
import { NotificationContext } from 'shared/providers';
import { useTranslation } from 'react-i18next';

export type CMSUploaderProps = {
    label?: string;
    title?: string;
    subtitle?: string;
    loadingTitle?: string;
    error?: string;
    disabled?: boolean;
    maxDimension: {
        height: number;
        width: number;
    };
    dimensionErrorMessage: string;
    previewFile?: { id: string; src: string };
    saveFiles: (files: Array<File>) => void;
    deleteFile: (id: string) => void;
};

const FAKE_UPLOADING_DURATION = 1000;
const acceptTypes = 'image/jpeg, image/png, image/jpg, image/webp, .ico';
const defaultGeneralErrorMessages = {
    [FileUploaderErrors.FILE_INVALID_TYPE]: 'Invalid file type',
    [FileUploaderErrors.FILE_TOO_LARGE]: 'File is too large',
    [FileUploaderErrors.FILE_TOO_MANY]: 'Too many files',
    [FileUploaderErrors.FILE_TOO_SMALL]: 'File is too small',
};

export const CMSUploader: FC<CMSUploaderProps> = ({
    label,
    error,
    maxDimension,
    dimensionErrorMessage,
    saveFiles,
    deleteFile,
    previewFile,
    disabled,
    ...props
}) => {
    const { t } = useTranslation();
    const { showNotification } = useContext(NotificationContext);

    const handleFileUploaded = useCallback(
        (files: Array<File>) => {
            // As CMSUploader used only with single file.
            const image = files[0];
            const imageUrl = URL.createObjectURL(image);
            // Handle image dimension
            getImgSize(imageUrl, dimension => {
                if (
                    dimension.height > maxDimension.height ||
                    dimension.width > maxDimension.width
                ) {
                    showNotification('error', dimensionErrorMessage);
                } else {
                    saveFiles([image]);
                }

                URL.revokeObjectURL(imageUrl);
            });
        },
        [saveFiles],
    );

    const handleFileRejected = useCallback((files: Array<FileRejection>) => {
        const errorCode = files[0].errors[0].code as FileUploaderErrors;
        const errorMessage = defaultGeneralErrorMessages[errorCode];

        showNotification('error', errorMessage);
    }, []);

    return (
        <CMSUploaderSC.Wrapper>
            {label && (
                <CMSUploaderSC.Label disabled={disabled}>
                    {label}
                    {error && <CMSUploaderSC.ErrorLabel>{error}</CMSUploaderSC.ErrorLabel>}
                </CMSUploaderSC.Label>
            )}
            <CMSUploaderSC.Container disabled={disabled}>
                {previewFile ? (
                    <CMSUploaderSC.PreviewContainer>
                        <CMSUploaderSC.PreviewImage src={previewFile.src} alt="preview" />
                        <SecondaryButton onClick={deleteFile.bind(null, previewFile.id)}>
                            {t('delete')}
                        </SecondaryButton>
                    </CMSUploaderSC.PreviewContainer>
                ) : (
                    <FileUploader
                        onFileUploaded={handleFileUploaded}
                        onFileRejected={handleFileRejected}
                        type="secondary"
                        fakeUploading
                        multiple={false}
                        fakeUploadingDuration={FAKE_UPLOADING_DURATION}
                        acceptTypes={acceptTypes}
                        hasError={!!error}
                        {...props}
                    />
                )}
            </CMSUploaderSC.Container>
        </CMSUploaderSC.Wrapper>
    );
};
