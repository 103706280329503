import React, { FC, ButtonHTMLAttributes } from 'react';

import { PrimaryButtonSC, PrimaryButtonStyleProps } from './primary.styles';

type Props = ButtonHTMLAttributes<HTMLButtonElement> & React.PropsWithChildren<PrimaryButtonStyleProps>;

export const PrimaryButton: FC<Props> = ({ children, ...props }) => (
  <PrimaryButtonSC {...props}>
    {children}
  </PrimaryButtonSC>
);
