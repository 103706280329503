import clsx from 'clsx';
import React from 'react';

import styles from './PreloaderIcon.module.scss';

type PreloaderIconProps = {
    className?:string;
    color?:string;
}

export const PreloaderIcon:React.FC<PreloaderIconProps> = ({ className, color = "#4b67f8" }) => (
    <span className={clsx(styles.root, className)}>
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="25 25 50 50">
            <circle cx="50" cy="50" r="20" fill="none" strokeWidth="5" stroke={color} strokeLinecap="round" strokeDashoffset="0" strokeDasharray="100, 200">
                <animateTransform attributeName="transform" attributeType="XML" type="rotate" from="0 50 50" to="360 50 50" dur="2.5s" repeatCount="indefinite"/>
                <animate attributeName="stroke-dashoffset" values="0;-30;-124" dur="1.25s" repeatCount="indefinite"/>
                <animate attributeName="stroke-dasharray" values="0,200;110,200;110,200" dur="1.25s" repeatCount="indefinite"/>
            </circle>
        </svg>
    </span>
)