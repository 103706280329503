import styled from 'styled-components';

import { RichTextEditor } from './rich-text-editor.component';
import { Color, sizes } from 'components/styles/constants.styles';
import { FormInputText } from 'components/styles/text.styles';
import { CommonTextAreaStyles } from '../form/fields/common-input.styles';

export const BaseRichTextEditor = styled(RichTextEditor).attrs({
  wrapperClassName: 'wrapper',
  editorClassName: 'editor',
  toolbarClassName: 'toolbar',
})`
  .editor {
    ${CommonTextAreaStyles}
    padding: 12px 20px;
    height: unset;
    min-height: 150px;
    max-height: 150px;

    border-top-left-radius: 0;
    border-top-right-radius: 0;
    
    ${FormInputText};
  }

  .toolbar {
    margin-bottom: 0;
    border: none;
    border-bottom: 1px solid ${Color.pale_grey};
    border-top-left-radius: ${sizes.border_radius};
    border-top-right-radius: ${sizes.border_radius};
  }

  .public-DraftEditorPlaceholder-inner {
    color: ${Color.cloudy_blue};
  }
`;
