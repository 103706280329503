import styled from 'styled-components';

import { TextStyle13 } from 'components/styles/text.styles';
import { Color } from 'components/styles/constants.styles';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  button {
    align-self: center;
  }
`;

const Text = styled.div`
  ${TextStyle13}

  color: ${Color.charcoal_grey};
  line-height: 20px;
  text-align: center;
  margin-bottom: 24px;
`;

export const SuccessModalSC = {
  Container,
  Text,
};
