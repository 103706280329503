import styled from 'styled-components';

import { Color, sizes } from 'components/styles/constants.styles';
import { TextStyle9 } from 'components/styles/text.styles';

const Dropdown = styled.div`
  z-index: 1;
  right: 50%;
  width: 100px;
  position: absolute;
  font-weight: normal !important;
  padding: 4px ${sizes.sm_padding};
  color: ${Color.bluey_grey} !important;
  background-color: ${Color.pale_grey};
  border-radius: ${sizes.border_radius};

  div, a {
    ${TextStyle9};
    margin: 8px 0;
    text-decoration: none;

    &:hover {
      color: ${Color.lightish_blue};
    }
  }
`;

const Container = styled.div`
  text-align:  center;
  position: relative;
  
  &:hover {
    cursor: pointer;

    span {
      color: ${Color.black};
    }
  }

  span {
    font-size: 16px;
  }
`;

export const ActionsSC = {
  Container,
  Dropdown,
};
