type ProductInfoInput = {
    name: string;
    label: string;
    subLabel?: string;
    placeholder: string;
    widthPercents?: number; // default is 100.
    postfix?: string;
    type?: string;
    allowNegative?: boolean;
    decimalScale?: number;
};

export const productInfoForm: Array<ProductInfoInput> = [
    {
        name: 'productName',
        label: 'Product name',
        placeholder: 'Enter product name',
        widthPercents: 80,
    },
    {
        name: 'quantityAtStock',
        label: 'Quantity at stock',
        placeholder: 'Enter quantity at stock',
        type: 'number',
        allowNegative: false,
        widthPercents: 20,
        decimalScale: 0,
    },
    {
        name: 'usp1',
        label: 'USP 1',
        placeholder: 'Enter USP 1 of your product',
        widthPercents: 50,
    },
    {
        name: 'usp2',
        label: 'USP 2',
        placeholder: 'Enter USP 2 of your product',
        widthPercents: 50,
    },
    {
        name: 'usp3',
        label: 'USP 3',
        placeholder: 'Enter USP 3 of your product',
        widthPercents: 50,
    },
    {
        name: 'uvp',
        label: 'UVP',
        subLabel: 'Please add the price including VAT',
        placeholder: 'Enter UVP (including VAT)',
        widthPercents: 50,
        postfix: 'CHF',
        type: 'money',
    },
    {
        name: 'productCatchPhrase',
        label: 'Product catch phrase',
        placeholder: 'Write your product catch phrase',
    },
    {
        name: 'deliveryDays',
        label: 'Days for delivery',
        subLabel: 'After auction completion',
        placeholder: 'Enter days quantity for delivery',
        widthPercents: 50,
        postfix: 'days',
        type: 'number',
        allowNegative: false,
        decimalScale: 0,
    },
    {
        name: 'shippingCost',
        label: 'Shipping cost',
        placeholder: 'Enter shipping cost',
        widthPercents: 50,
        postfix: 'CHF',
        type: 'money',
    },
];

export const ProductGeneralInfoValidationMessage = {
    productName: {
        required: 'Product name is required',
        max: 'Max length is 100 symbols',
    },
    quantityAtStock: {
        required: 'Quantity is required',
        min: 'At least one product',
    },
    usp: {
        required: (number: number) => `USP${number} is required`,
        max: 'Max length is 60 symbols',
    },
    uvp: {
        required: 'UVP is required',
    },
    productCatchPhrase: {
        required: 'Catch phrase is required',
    },
    deliveryDays: {
        required: 'Delivery days field is required',
        max: 'Not higher than 60 days',
    },
    shippingCost: {
        required: 'Shipping cost is required',
    },
    pictures: {
        required: 'Please upload at least one picture',
    },
    description: {
        required: 'At least one description should be filled',
        everyShouldBeFilled: 'Please fill in all the descriptions that you added',
    },
    descriptionType: {
        max: 'Max length is 50 symbols',
        required: 'Description type is required',
    },
    descriptionText: {
        max: 'Max length is 1200 symbols',
        required: 'Description is required',
    },
};
