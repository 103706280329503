import React, { FC } from 'react';
import { useField } from 'formik';

import { ColorPicker } from 'units/common/components/color-picker/color-picker.component';

interface Props {
  name: string;
  label: string;
  disabled?: boolean;
}

export const FormikColorPicker: FC<Props> = ({ name, ...props }) => {
  const [field, , helpers]  = useField(name);

  const handleChange = (color: any) => {
    helpers.setValue(color.hex);
  };

  return (
    <ColorPicker color={field.value} onChange={handleChange} {...props} >
    </ColorPicker>
  )
};
