export const UserUrls = {
  login: '/sign-in',
  registration: '/sign-up',
  resetPassword: '/reset-password',
  confirmEmail: '/activation/:uid/:token',
  confirmResetPassword: '/password/reset/:uid/:token',
  acceptInvitation: '/accept-activation/:token',
  createPassword: '/create-password',
  signInWelcome: '/sign-in/welcome',
};
