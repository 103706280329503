import styled from 'styled-components';

import { FormInputText, FormInputLabelText, UserDropdownText } from 'components/styles/text.styles';
import { CommonInputStyles } from 'units/common/components/form/fields/common-input.styles';
import { Color, sizes } from 'components/styles/constants.styles';

const Container = styled.div`
  margin-bottom: ${sizes.sm_margin};
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: baseline;
`;

const Label = styled.label`
  display: flex;
  margin-bottom: 8px;
  ${FormInputLabelText}
`;

const Field = styled.div`
  ${FormInputText};
  ${CommonInputStyles};
  width: 100%;
  padding: ${sizes.xs_padding} 20px;
`;

const Info = styled.div`
  position: relative;

  span {
    cursor: pointer;
    font-size: 12px;
    margin-left: 8px;
    color: ${Color.bluey_grey};

    &:hover + div {
      display: block;
    }
  }
`;

const Tooltip = styled.div`
  ${UserDropdownText};
  top: 10px;
  left: 20px;
  width: 210px;
  display: none;
  position: absolute;
  color: ${Color.bluey_grey};
  background-color: ${Color.white};
  border-radius: ${sizes.border_radius};
  padding: ${sizes.xs_padding} ${sizes.sm_padding};
`;

export const FontFamilyPreviewSC = {
  Container,
  LabelWrapper,
  Label,
  Field,
  Info,
  Tooltip,
};
