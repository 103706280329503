import styled from 'styled-components';
import ReactSelect from 'react-select';

import { Color, sizes } from 'components/styles/constants.styles';
import {
    FormSubLabelText,
    FormInputLabelText,
    UserDropdownText,
    TextStyle10,
    FormInputErrorText,
    FormInputPostfixText,
    TextStyle9,
} from 'components/styles/text.styles';
import { Font } from 'components/types/enums';

const SelectContainer = styled.div<{ margin?: string; padding?: string }>`
    padding: 0 0 ${sizes.sm_padding};

    ${({ margin }) =>
        margin &&
        `
    margin: ${margin};
  `}

    ${({ padding }) =>
        padding &&
        `
    padding: ${padding};
  `}
`;

const ReactSelectContainer = styled(ReactSelect)`
    pointer-events: all !important;

    .select__placeholder {
        ${FormSubLabelText};
    }

    .select__control {
        border: none;
        border-radius: ${sizes.border_radius};
    }

    .select__control--is-focused {
        border-color: ${Color.white} !important;
        box-shadow: none;
    }

    .select__value-container {
        padding-left: 20px;
    }

    .select__indicator-separator {
        display: none;
    }

    .select__menu {
        margin-top: 0;
        border-radius: ${sizes.border_radius};
    }

    .select__menu-list {
        max-height: 200px;
    }

    .select__option {
        padding-left: 20px;
        ${UserDropdownText};

        &:hover {
            cursor: pointer;
            font-weight: 700;
            font-family: ${Font.bold};
        }
    }

    .select__option--is-focused {
        ${TextStyle10};
        background-color: unset;
    }

    .select__option--is-selected {
        ${TextStyle9};
        background-color: unset;
    }

    .select__control--is-disabled {
        background-color: #9da7b7;
        opacity: 0.3;
        cursor: not-allowed;
    }

    .select__single-value--is-disabled {
        color: initial;
    }

    .select__single-value {
        ${FormInputPostfixText};
        line-height: unset;
        height: 100%;
        display: flex;
        align-items: center;
        padding-bottom: 2px;
        font-family: 'GT Walsheim Pro Bold';
        font-weight: 700;
    }

    .select__indicator svg {
        width: 18px;
    }
`;

const LabelContainer = styled.div`
    display: flex;
    margin-bottom: 8px;
`;

const Label = styled.label`
    ${FormInputLabelText};
`;

const Error = styled.p`
    ${FormInputErrorText}

    margin: 8px 0 0 0;
`;

export const SelectSC = {
    SelectContainer,
    ReactSelectContainer,
    LabelContainer,
    Label,
    Error,
};
