import React, { FC, useState, useEffect, useContext, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import { OptionsType } from 'react-select';
import { useHistory, useParams, useLocation } from 'react-router-dom';

import { AuctionUrls } from '../../urls';
import { BackButton } from 'units/common/components/back-button/back-button.component';
import { IOption } from 'units/common/components/select/select.component';
import { CreateAuctionForm } from './form/create-auction-form.component';
import { AppStrings } from 'config/strings';
import { buyNowValidationSchema, auctionValidationSchema } from './validation';
import { AuctionValues } from './types';
import { createAuction, editAuction, getAuctionData } from './api/create-auction.service';
import { getProducts } from 'units/products/api/products-service';
import { deleteAuction } from '../index/api/auctions.service';
import { Product } from 'units/products/api/types';
import { initValues, Auction, Status } from './constants';
import { getAuctionStatuses } from './helpers';
import { useWorkingMode } from './hooks';
import { NotificationContext } from 'shared/providers';

export const CreateAuction: FC = () => {
    const { showNotification } = useContext(NotificationContext);
    const { auctionId } = useParams<{ auctionId: string }>();
    const [products, setProducts] = useState<Array<Product>>([]);
    const [options, setOptions] = useState<OptionsType<IOption>>([]);
    const [initialValues, setInitialValues] = useState<AuctionValues>(initValues);
    const [auctionType, setAuctionType] = useState(initialValues.auctionType);
    const validationSchema =
        auctionType === Auction.buyNow ? buyNowValidationSchema : auctionValidationSchema;
    const history = useHistory();
    const { isEditMode, isViewMode, isCreateMode } = useWorkingMode(useLocation());
    const formRef = useRef<any>();

    useEffect(() => {
        getData();
    }, [auctionId]);

    const getData = async () => {
        const { data } = await getProducts({ unpublished: true });

        if (isEditMode || isViewMode) {
            const { product, auction } = await getAuctionData(auctionId);
            setInitialValues(auction);
            data.unshift(product);
        }

        setProducts(data);
        setOptions(
            data.map(item => ({
                label: item.name,
                value: item.id,
            })),
        );
    };

    const onSubmit = async (values: AuctionValues, status: any) => {
        if (isEditMode) {
            handleEditAuction(values, status);
        } else {
            handleCreateAuction(values, status);
        }
    };

    const handleEditAuction = async (values: AuctionValues, status: any) => {
        try {
            const { isScheduled, isDraft, isActive } = getAuctionStatuses(values);
            let successMsg = '';

            if (isScheduled) {
                if (status === Status.scheduled) {
                    successMsg = AppStrings.auctionUpdateSuccess;
                } else {
                    successMsg = AppStrings.auctionUnscheduleSuccess;
                }
            } else if (isDraft) {
                if (status === Status.scheduled) {
                    successMsg = AppStrings.auctionScheduleSuccess;
                } else {
                    successMsg = AppStrings.auctionDraftUpdateSuccess;
                }
            } else if (isActive) {
                successMsg = AppStrings.auctionActiveUpdateSuccess;
            }

            await editAuction(values, status);
            await getData();
            showNotification('success', successMsg);
        } catch (error: any) {
            if (error.response.data.error_code) {
                if (error.response.data.error_code === 'START_TIME_INVALID') {
                    formRef?.current?.setTouched('startTime', true);
                } else if (error.response.data.error_code === 'END_TIME_INVALID') {
                    formRef?.current?.setTouched('endTime', true);
                }

                showNotification('error', error.response.data.detail[0]);
            } else {
                showNotification('error', AppStrings.errorNotification);
            }
        }
    };

    const handleCreateAuction = async (values: AuctionValues, status: any) => {
        try {
            let successMsg = AppStrings.auctionScheduleSuccess;
            let redirectTo = AuctionUrls.index;
            const { id } = await createAuction(values, status);

            if (status === Status.draft) {
                successMsg = AppStrings.auctionSaveSuccess;
                redirectTo = `${AuctionUrls.index}/edit/${id}`;
            }

            showNotification('success', successMsg);
            history.push(redirectTo);
        } catch (error: any) {
            if (error.response.data.error_code) {
                showNotification('error', error.response.data.detail[0]);
            } else {
                showNotification('error', AppStrings.errorNotification);
            }
        }
    };

    const onDelete = async () => {
        try {
            await deleteAuction(auctionId);
            showNotification('success', AppStrings.auctionDeleteSuccess);
            history.push(AuctionUrls.index);
        } catch {
            showNotification('error', AppStrings.errorNotification);
        }
    };

    const validate = (values: AuctionValues) => {
        try {
            validateYupSchema(values, validationSchema, true, values);
        } catch (err) {
            return yupToFormErrors(err);
        }
        return {};
    };

    return (
        <>
            <Helmet>
                {isCreateMode && <title>Create New Auction</title>}
                {isEditMode && <title>Edit Auction</title>}
                {isViewMode && <title>View Auction</title>}
            </Helmet>
            <BackButton destinationText="Auctions" to={AuctionUrls.index} />

            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                onSubmit={onSubmit}
                validate={validate}
                innerRef={formRef}
            >
                <CreateAuctionForm
                    options={options}
                    products={products}
                    onSubmit={onSubmit}
                    onDelete={onDelete}
                    setAuctionType={setAuctionType}
                />
            </Formik>
        </>
    );
};
