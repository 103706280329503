import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { NavbarSC } from './navbar.styles';
import { IconSC, IconUnicodes } from 'components/styles/icons.styles';
import { RootState } from 'redux/root-types';
import { UserState } from 'units/user/redux/types';
import { UserRoleId } from 'components/types/enums';

interface NavbarLink {
    to: string;
    title: string;
    icon: IconUnicodes;
    isExternal?: boolean;
    allowedTo: UserRoleId[];
}

const navbarLinks: Array<NavbarLink> = [
    {
        to: '/overview',
        title: 'overview',
        icon: IconUnicodes.squares_filled,
        allowedTo: [UserRoleId.Admin, UserRoleId.Owner, UserRoleId.User],
    },
    {
        to: '/products',
        title: 'products',
        icon: IconUnicodes.items,
        allowedTo: [UserRoleId.Admin, UserRoleId.Owner, UserRoleId.User],
    },
    {
        to: '/auctions',
        title: 'auctions',
        icon: IconUnicodes.hammer,
        allowedTo: [UserRoleId.Admin, UserRoleId.Owner, UserRoleId.User],
    },
    {
        to: '/orders',
        title: 'orders',
        icon: IconUnicodes.cart,
        allowedTo: [UserRoleId.Admin, UserRoleId.Owner],
    },
    {
        to: '/analytics',
        title: 'analytics',
        icon: IconUnicodes.donut_chart,
        allowedTo: [UserRoleId.Admin, UserRoleId.Owner],
    },
    {
        to: '/settings',
        title: 'settings',
        icon: IconUnicodes.settings,
        allowedTo: [UserRoleId.Admin, UserRoleId.Owner, UserRoleId.User],
    },
];

const disabledProps = {
    onClick: (e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault(),
    style: { opacity: 0.4 },
};

export const Navbar = () => {
    const { user } = useSelector<RootState, UserState>(state => state.user);

    return (
        <NavbarSC>
            {navbarLinks.map(({ to, title, icon, isExternal, allowedTo }) =>
                isExternal ? (
                    <a
                        href={to}
                        key={to}
                        {...(!allowedTo.find(i => i === user?.role?.id) &&
                            disabledProps)} /* target="_blank" rel="noopener noreferrer" */
                    >
                        <IconSC name={icon} />
                        {title}
                    </a>
                ) : (
                    <NavLink
                        to={to}
                        key={to}
                        activeClassName="active"
                        {...(!allowedTo.find(i => i === user?.role?.id) && disabledProps)}
                    >
                        <IconSC name={icon} />
                        {title}
                    </NavLink>
                ),
            )}
        </NavbarSC>
    );
};
