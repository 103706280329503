import { http } from 'api/http';

import { AnalyticChartType } from 'shared/types';

const getInfo = async (): Promise<AnalyticChartType | null> => {
    const { data } = await http.get<AnalyticChartType>('/auctions/analytics/overview/');

    return data;
};

export const createAnalyticService = () => ({
    getInfo,
});
