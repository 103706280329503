import {
    AppUser,
    RegistrationErrorRes,
    EmailConfirmationError,
    LoginUserError,
    ResetPasswordError,
    ConfirmResetPasswordError,
    SignOutError,
    ResendEmailConfirmationError,
    FetchUserAccountDataError,
    UpdateUserAccountData,
    UpdateUserAccountErrorRes,
    FetchUserAccountData,
    AcceptInvitationErrorRes,
} from './types';
import * as actionTypes from './action-types';
import { ErrorValue } from 'redux/types';
import { CustomDomainStatusEnum } from 'shared/types';

// Registration
export const registrationRequest = (userEmail: string) =>
    ({ type: actionTypes.USER_REGISTER_REQUEST, userEmail } as const);
export const registrationSuccess = (user: AppUser) =>
    ({ type: actionTypes.USER_REGISTER_SUCCESS, user } as const);
export const registrationFailure = (error: RegistrationErrorRes) =>
    ({ type: actionTypes.USER_REGISTER_FAILURE, error } as const);

// Confirm email
export const confirmEmailRequest = () =>
    ({ type: actionTypes.USER_CONFIRM_EMAIL_REQUEST } as const);
export const confirmEmailSuccess = () =>
    ({ type: actionTypes.USER_CONFIRM_EMAIL_SUCCESS } as const);
export const confirmEmailFailure = (error?: EmailConfirmationError) =>
    ({ type: actionTypes.USER_CONFIRM_EMAIL_FAILURE, error } as const);

// Resend confirmation email
export const resendConfirmEmailRequest = () =>
    ({ type: actionTypes.USER_RESEND_CONFIRM_EMAIL_REQUEST } as const);
export const resendConfirmEmailSuccess = () =>
    ({ type: actionTypes.USER_RESEND_CONFIRM_EMAIL_SUCCESS } as const);
export const resendConfirmEmailFailure = (error?: ResendEmailConfirmationError) =>
    ({
        type: actionTypes.USER_RESEND_CONFIRM_EMAIL_FAILURE,
        error,
    } as const);

// Login
export const loginRequest = () => ({ type: actionTypes.USER_LOGIN_REQUEST } as const);
export const loginSuccess = (token: string, email: string, isWelcomeflowCompeted?: boolean) =>
    ({
        type: actionTypes.USER_LOGIN_SUCCESS,
        token,
        email,
        isWelcomeflowCompeted,
    } as const);
export const loginFailure = (error: LoginUserError) =>
    ({ type: actionTypes.USER_LOGIN_FAILURE, error } as const);

// Reset password
export const resetPasswordRequest = (email: string) =>
    ({ type: actionTypes.USER_RESET_PASSWORD_REQUEST, email } as const);
export const resetPasswordSuccess = () =>
    ({ type: actionTypes.USER_RESET_PASSWORD_SUCCESS } as const);
export const resetPasswordFailure = (error?: ResetPasswordError) =>
    ({ type: actionTypes.USER_RESET_PASSWORD_FAILURE, error } as const);
export const resetPasswordSetUserId = (userId: { uid: string; token: string } | null) =>
    ({
        type: actionTypes.USER_RESET_PASSWORD_USER_ID,
        userId,
    } as const);

// Confirm reset password
export const confirmResetPasswordRequest = () =>
    ({ type: actionTypes.USER_CONFIRM_RESET_PASSWORD_REQUEST } as const);
export const confirmResetPasswordSuccess = () =>
    ({ type: actionTypes.USER_CONFIRM_RESET_PASSWORD_SUCCESS } as const);
export const confirmResetPasswordFailure = (error?: ConfirmResetPasswordError) =>
    ({
        type: actionTypes.USER_CONFIRM_RESET_PASSWORD_FAILURE,
        error,
    } as const);

// Sign Out
export const signOutRequest = (token: string) =>
    ({ type: actionTypes.SIGNOUT_REQUEST, token } as const);
export const signOutSuccess = () => ({ type: actionTypes.SIGNOUT_SUCCESS } as const);
export const signOutFailure = (error?: SignOutError) =>
    ({ type: actionTypes.SIGNOUT_FAILURE, error } as const);

// Get info about me
export const fetchMyAccountDataRequest = () =>
    ({ type: actionTypes.USER_FETCH_MY_ACCOUNT_DATA_REQUEST } as const);
export const fetchMyAccountDataSuccess = (user: FetchUserAccountData) =>
    ({ type: actionTypes.USER_FETCH_MY_ACCOUNT_DATA_SUCCESS, user } as const);
export const fetchMyAccountDataFailure = (error?: FetchUserAccountDataError) =>
    ({
        type: actionTypes.USER_FETCH_MY_ACCOUNT_DATA_FAILURE,
        error,
    } as const);

// Update info about me
export const updateMyAccountDataRequest = () =>
    ({ type: actionTypes.USER_UPDATE_MY_ACCOUNT_DATA_REQUEST } as const);
export const updateMyAccountDataSuccess = (user: UpdateUserAccountData) =>
    ({
        type: actionTypes.USER_UPDATE_MY_ACCOUNT_DATA_SUCCESS,
        user,
    } as const);
export const updateMyAccountDataFailure = (error?: UpdateUserAccountErrorRes) =>
    ({
        type: actionTypes.USER_UPDATE_MY_ACCOUNT_DATA_FAILURE,
        error,
    } as const);
export const updateCustomDomainData = (domain: string | null) =>
    ({ type: actionTypes.USER_UPDATE_CUSTOM_DOMAIN_DATA, domain } as const);
export const updateCustomDomainStatus = (status: CustomDomainStatusEnum) =>
    ({ type: actionTypes.USER_UPDATE_CUSTOM_DOMAIN_STATUS, status } as const);
export const updateCompanyEmail = (email: string) =>
    ({ type: actionTypes.USER_UPDATE_COMPANY_EMAIL, email } as const);

// Accept invitation
export const acceptInvitationSetToken = (token: string) =>
    ({ type: actionTypes.USER_ACCEPT_INVITATION_USER_TOKEN, token } as const);
export const acceptInvitationDataRequest = () =>
    ({ type: actionTypes.USER_ACCEPT_INVITATION_REQUEST } as const);
export const acceptInvitationDataSuccess = () =>
    ({ type: actionTypes.USER_ACCEPT_INVITATION_SUCCESS } as const);
export const acceptInvitationDataFailure = (error?: AcceptInvitationErrorRes) =>
    ({
        type: actionTypes.USER_ACCEPT_INVITATION_FAILURE,
        error,
    } as const);

// General
export const clearUserStore = () => ({ type: actionTypes.USER_CLEAR_STORE } as const);

// Welcome flow
export const welcomeFlowRequestSuccess = (values: { firstName: string; lastName: string }) =>
    ({
        type: actionTypes.USER_WELCOME_FLOW_REQUEST_SUCCESS,
        values,
    } as const);

/* eslint-disable @typescript-eslint/no-explicit-any */

export const setUserErrorState = (key: string, value: ErrorValue) =>
    ({ type: actionTypes.USER_SET_ERROR_STATE, key, value } as const);
export const setUserTempPayload = (key: string, value: any) =>
    ({ type: actionTypes.USER_SET_TEMP_PAYLOAD, key, value } as const);

/* eslint-enable @typescript-eslint/no-explicit-any */
