/* eslint-disable import/named */
import styled, { FlattenSimpleInterpolation } from 'styled-components';
/* eslint-enable import/named */

import { MediaQueries } from 'units//landing-page/config';

const BG = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    border-radius: 16px;
    background: linear-gradient(263deg, #f1e6ff 0%, #f3f6fc 100%);
    transition: all 300ms ease-out;
`;

const Root = styled.div<{ styles?:FlattenSimpleInterpolation }>`
    position: relative;
    background-color: #f3f6fc;
    border-radius: 16px;
    overflow: hidden;

    input,
    textarea {
        padding: 31px 40px;
        position: relative;
        z-index: 5;
        font-size: 16px;
        width: 100%;
        border: none;
        outline: none;
        background-color: transparent;
        &:focus + ${BG} {
            opacity: 1;
        }
    }

    textarea {
        resize: none;
    }


    @media (max-width: ${MediaQueries.tablet}) {
        input,
        textarea {
            font-size: 14px;
            padding: 19px 30px;
        }
    }

    @media (max-width: ${MediaQueries.mobile}) {
        border-radius: 12px;

        input,
        textarea {
            font-size: 14px;
            padding: 11px 18px;
        }
    }

    ${props => props.styles}
`;

export const LPFormElementSC = {
    Root,
    BG
}
