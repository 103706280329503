import React from 'react';
import clsx from 'clsx';

import styles from './InputError.module.scss';

type ImmoInputErrorProps = {
    className?: string;
};

export const ImmoInputError: React.FC<ImmoInputErrorProps> = ({ className, children }) => (
    <div className={clsx(styles.root, className)}>{children}</div>
);
