export enum CustomDomainStatusEnum {
    Broken = 1,
    Healthy = 2,
    Pending = 3,
}

export type StripeAccountData = {
    account_id: string;
    charges_enabled: boolean;
    company: string;
    details_submitted: string;
    email: null | string;
    id: string;
};

export type CustomDomainResponseType = {
    actual_domain: string;
    custom_domain: string;
    custom_domain_status: CustomDomainStatusEnum;
    id: string;
    name: string;
};

export type EmailTemplateItemType = {
    available_vars: string[];
    body: string;
    from_email: string;
    id: string;
    language: string;
    state: string;
    subject: string;
    type: string;
};

export type UpdateEmailTemplateType = {
    body: string;
    subject: string;
};
