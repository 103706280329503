import { Dispatch } from 'redux';
import { AxiosError } from 'axios';

import { ApiClient } from './api';
import { clearUserStore } from 'units/user/redux/action-creators';
import { history } from 'helpers/history';
import { UserUrls } from 'units/user/urls';
import { clearAppReducer } from 'units/app/redux/action-creators';

export const setUpAuthInterceptorsAction = () => (dispatch: Dispatch) => {
	ApiClient.getInstance().interceptors.response.use(
		response => response,
		(error: AxiosError) => {
			// Unauthorized error handling
			if (error.response?.status === 401) {
				dispatch(clearUserStore());
				dispatch(clearAppReducer());
				ApiClient.removeAccessToken();
        history.push(UserUrls.login);  
			}

			return Promise.reject(error);
		}
	)
};
