/* eslint-disable @typescript-eslint/ban-ts-comment */

import * as yup from 'yup';

import { UserInputValidationErrors } from 'units/common/constants';

declare module 'yup' {
    export interface StringSchema {
        /**
         * Check for phone number validity.
         *
         * @param {String} [errorMessage=DEFAULT_MESSAGE] The error message to return if the validation fails.
         */
        phone(errorMessage?: string): StringSchema;
    }
}

yup.addMethod(yup.string, 'phone', function phoneValid(
    errorMessage: string = UserInputValidationErrors.phone.notValid,
) {
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
    // @ts-ignore
    return this.test('phone', errorMessage, value => {
        if (value && value.length > 0) {
            return phoneRegExp.test(value);
        }
        return true;
    });
});

export { yup };

/* eslint-enable @typescript-eslint/ban-ts-comment */
