import React, { FC } from 'react';
import { Form, useFormikContext } from 'formik';

import { CMSRootSC } from 'units/cms/styles';
import { PrimaryButton } from 'units/common/components/buttons/primary/primary.component';
import { TransparentButton } from 'units/common/components/buttons/transparent/transparent.component';
import { CMSSubmitType } from 'units/cms/types';
import { useCMSConfigSaveStatusManager } from 'units/cms/hooks/useCMSConfigSaveStatusManager';
import { useCMSThemeSettings } from 'units/cms/hooks/selectors/useCMSThemeSettings';
import { useCMSCompare } from 'units/cms/hooks/useCMSCompare';
import { useTranslation } from 'react-i18next';

interface Props {
    children: React.ReactNode;
    beforeSubmit: (submitType: CMSSubmitType) => void;
}

export const CMSThemeSettingsForm: FC<Props> = ({ children, beforeSubmit }) => {
    const { values, handleSubmit } = useFormikContext();
    const formikInitialThemeSettings = useCMSThemeSettings();
    const [, { setSaveStatus }] = useCMSConfigSaveStatusManager();

    useCMSCompare(values, formikInitialThemeSettings, setSaveStatus);

    const { t } = useTranslation();

    return (
        <Form>
            {children}
            <CMSRootSC.CTAContainer marginTop="16px">
                <PrimaryButton
                    type="button"
                    onClick={() => {
                        beforeSubmit('save');
                        handleSubmit();
                    }}
                >
                    {t('theme_settings_save')}
                </PrimaryButton>
                <TransparentButton
                    type="button"
                    onClick={() => {
                        beforeSubmit('save & publish');
                        handleSubmit();
                    }}
                >
                    {t('theme_settings_save_and_publish')}
                </TransparentButton>
            </CMSRootSC.CTAContainer>
        </Form>
    );
};
