import React, { FC } from 'react';

import { BaseFormikWrapper, IBaseFormikWrapper } from '../wrapper/base-formik-wrapper.component';
import { UserInput, IUserInput } from '../../form/fields/user-input/user-input.component';

type Props = IUserInput & Omit<IBaseFormikWrapper<HTMLInputElement>, 'component'>;

export const FormikUserInput: FC<Props> = (props) => (
  <BaseFormikWrapper {...props} component={UserInput} />
);

