import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useCMSConfigSavedStatus } from './selectors/useCMSConfigStatus';
import { cmsSetConfigSaveStatus } from '../store/action-creators';

type Hook = () => [
  boolean,
  {
    turnSaveStatusOn: () => void,
    turnSaveStatusOff: () => void,
    setSaveStatus: (status: boolean) => void,
  }, 
];

export const useCMSConfigSaveStatusManager: Hook = () => {
  const dispatch = useDispatch();
  const currentSaveStatus = useCMSConfigSavedStatus();

  const setSaveStatus = useCallback((newSaveStatus: boolean) => {
    if (currentSaveStatus !== newSaveStatus) {
      dispatch(cmsSetConfigSaveStatus(newSaveStatus));
    }
  }, [currentSaveStatus]);

  const turnSaveStatusOn = useCallback(() => setSaveStatus(true), [setSaveStatus]);
  const turnSaveStatusOff = useCallback(() => setSaveStatus(false), [setSaveStatus]);

  return [currentSaveStatus, {
    turnSaveStatusOn,
    turnSaveStatusOff,
    setSaveStatus,
  }];
};
