import clsx from 'clsx';
import React from 'react';

import styles from './ChartLegend.module.scss';

type LegendProps = {
    className?: string;
};

export const ChartLegend: React.FC<LegendProps> = ({ className }) => {
    return (
        <div className={clsx(styles.legend, className)}>
            <div className={styles.item}>
                <div className={clsx(styles.dot, styles['is-blue'])} />
                <div className={styles.label}>Male</div>
            </div>
            <div className={styles.item}>
                <div className={clsx(styles.dot, styles['is-red'])} />
                <div className={styles.label}>Female</div>
            </div>
            <div className={styles.item}>
                <div className={clsx(styles.dot, styles['is-orange'])} />
                <div className={styles.label}>Unspecified</div>
            </div>
        </div>
    );
};
