import React, { FC } from 'react';

import { ProgressBarColorProps, ProgressBarSC } from './progress-bar.styles';

type FakeProgressProps = ProgressBarColorProps & {
  duration?: number;
}

const FakeProgressBar: FC<FakeProgressProps> = ({ duration, fillColor, progressColor }) => (
  <ProgressBarSC.Container>
    <ProgressBarSC.LoadedPart
      fakeAnimation
      fakeDuration={duration}
      progressColor={progressColor}
    ></ProgressBarSC.LoadedPart>
    <ProgressBarSC.UnLoadedPart
      fakeAnimation
      fakeDuration={duration}
      fillColor={fillColor}
    ></ProgressBarSC.UnLoadedPart>
  </ProgressBarSC.Container>
);

export const ProgressBar = {
  Fake: FakeProgressBar,
};
