import styled from 'styled-components';

import { Color } from 'components/styles/constants.styles';
import { MediaQueries } from 'units/landing-page/config';

const Root = styled.div`
    color: ${Color.error_red};
    font-size: 14px;
    margin-top: 5px;
    margin-left: 15px;

    @media (max-width: ${MediaQueries.tablet}) {
        font-size: 12px;
    }

    @media (max-width: ${MediaQueries.mobile}) {
        font-size: 10px;
        margin-left: 10px;
    }
`;

export const LPInputErrorSC = {
    Root,
}
