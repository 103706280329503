import React, { useState, useEffect, ReactNode, useCallback, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Formik, FormikHelpers } from 'formik';
import { useSelector } from 'react-redux';

import { Loading } from 'units/common/components/loading/loading.component';
import { LayoutSC } from 'units/common/components/layout/layout-components.styles';
import { BackButton } from 'units/common/components/back-button/back-button.component';
import { getImage, ImageName } from 'components/styles/images';
import { SettingsUrls } from '../../urls';
import { UsersSC } from './users.styles';
import { Table } from 'units/common/components/table/table.component';
import { Modal } from 'units/common/components/modal/modal.component';
import { InviteUserForm } from './form/invite-user.form';
import { PrimaryButton } from 'units/common/components/buttons/primary/primary.component';
import { SecondaryButton } from 'units/common/components/buttons/secondary/secondary.component';
import { RootState } from 'redux/root-types';
import { UserState } from 'units/user/redux/types';
import { inviteUserValidationSchema } from './validation';
import { parseInvitedUserError } from './tools/parsers/invitedUser';
import { transformErrorFromApi } from 'redux/tools/error-handling';
import { AppStrings } from 'config/strings';
import { useStateToggle } from 'units/common/hooks/useStateToggle';

import { InvitedUserResp, InviteUserError, InviteUserValues } from './api/types';
import { getRoleList, getInvitedUsers, inviteUser } from './api/users-user-service';
import { UserRole } from 'components/types';
import { getRoleName } from 'helpers/roles';
import { NotificationContext } from 'shared/providers';

interface TableData {
  fullName: ReactNode;
  role: ReactNode;
  status: ReactNode;
  edit: ReactNode;
}

const prepareTableData = (data: Array<InvitedUserResp>) => data.map(item => {
  const status = item.is_active ? 'Active' : 'Pending';

  return {
    fullName: <UsersSC.Text>{`${item.first_name} ${item.last_name}`}</UsersSC.Text>,
    role: <UsersSC.Text>{getRoleName(item.role)}</UsersSC.Text>,
    status: <UsersSC.Text className={status.toLowerCase()}>{status}</UsersSC.Text>,
    edit: <UsersSC.Edit><Link to={`${SettingsUrls.users.index}/${item.id}`}>Edit</Link></UsersSC.Edit>,
  }
});

const columns = [
  {
    Header: 'Full name',
    accessor: 'fullName',
  },
  {
    Header: 'Role',
    accessor: 'role',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: '',
    accessor: 'edit',
  },
];

export const UsersPage = () => {
  const { showNotification } = useContext(NotificationContext);
  const { user } = useSelector<RootState, UserState>((state) => state.user);
  const [errorsFromServer, setErrorsFromServer] = useState<InviteUserError | null>(null);
  const [isInviteModalOpen, inviteModal] = useStateToggle(false);
  const [isLoading, setLoading] = useState(true);
  const [roles, setRoles] = useState<Array<UserRole>>([]);
  const [users, setUsers] = useState<Array<TableData>>([]);
  const isDataExist = !!users?.length;
 
  const initialValues: InviteUserValues = {
    roleId: roles.find(item => item.role === 'User')?.id.toString(),
    firstName: '',
    lastName: '',
    email: '',
  };

  const openModal = () => {
    if (!roles.length) {
      inviteModal.turnOff();
      showNotification('error', AppStrings.errorNotification);
    } else {
      inviteModal.turnIn();
    }
  };

  const handleSubmit = async (data: InviteUserValues, helpers: FormikHelpers<InviteUserValues>) => {
    try {
      await inviteUser(data);
      
      inviteModal.turnOff();
      showNotification('success', `${data.firstName} ${data.lastName} was successfully invited`);

      setUsers(prepareTableData(await getInvitedUsers()));
      setErrorsFromServer(null);
    } catch (error) {
      if (error.response) {
        const err = parseInvitedUserError(transformErrorFromApi<InviteUserError>(error));
        setErrorsFromServer(err);
      } else {
        showNotification('error', AppStrings.errorNotification);
      }
    } finally {
      // clear hidden errors
      helpers.setStatus({});
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setRoles(await getRoleList());
        setUsers(prepareTableData(await getInvitedUsers()));
      } catch (error) {
        showNotification('error', AppStrings.errorNotification);
      }
      setLoading(false);
    })();
  }, []);

  const invitedUserModalCloseHandler = useCallback(() => {
    inviteModal.turnOff();
    setErrorsFromServer(null);
  }, []);

  return (
    <>
      <Helmet>
        <title>User accounts</title>
      </Helmet>
      <BackButton destinationText={'Settings'} to={SettingsUrls.index} />
      <UsersSC.TitleContainer>
        <LayoutSC.PageTitle smallPadding>User accounts</LayoutSC.PageTitle>
        {isDataExist && <SecondaryButton isWide onClick={openModal}>+ Invite New User</SecondaryButton>}
      </UsersSC.TitleContainer>
      
      {isLoading ? <Loading /> : (
        <>
          {!isDataExist && (
            <UsersSC.NoUsersContainer>
              <div>{getImage(ImageName.no_users)}</div>
              <UsersSC.Message>You have no users yet</UsersSC.Message>
              <PrimaryButton onClick={openModal}>
                Invite user
              </PrimaryButton>
            </UsersSC.NoUsersContainer>
          )}
          
          {isDataExist && (
            <Table
              data={users}
              pageSize={12}
              columns={columns}
            />
          )}

          <Modal
            title={'Invite new user'}
            titlePosition={'left'}
            isOpen={isInviteModalOpen}
            onCloseModal={invitedUserModalCloseHandler}
          >
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={inviteUserValidationSchema}
            >
              {roles && user?.role && (
                <InviteUserForm
                  myRole={user?.role}
                  roles={roles}
                  onCloseModal={invitedUserModalCloseHandler}
                  additionalErrors={errorsFromServer}
                />
              )}
            </Formik>
          </Modal>
        </>
      )}
    </>
  );
};
