import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { usePageScroll } from 'helpers/hooks';
import { useDeviceDetector, useIsLandingPage } from 'units/landing-page/hooks';

import { LPButton, LPLanguageSwitcher } from '../../ui';
import { EcatchLogo } from 'units/common/components/logo/logo.component';

import { Navigation } from 'units/landing-page/mocks';
import { LPHeaderSC } from './header.styles';

type LPHeaderProps = {
    isScroll: boolean;
};

export const LPHeader: React.FC<LPHeaderProps> = ({ isScroll }) => {
    const { device } = useDeviceDetector();
    const { t } = useTranslation();
    const router = useHistory();
    const { scrollTop } = usePageScroll();

    // const onHelpClick = () => {
    //     window.open('https://ecatch.tawk.help', '_blank');
    // };

    return (
        <LPHeaderSC.Root isScroll={isScroll} id="header-section">
            <LPHeaderSC.Header>
                <LPHeaderSC.LeftPart>
                    <LPHeaderSC.LogoWrapper>
                        <EcatchLogo.Image onClick={scrollTop.bind(null, false)} />
                    </LPHeaderSC.LogoWrapper>
                    {device !== 'mobile' && <NavList />}
                </LPHeaderSC.LeftPart>

                <LPHeaderSC.RightPart>
                    {device !== 'mobile' && (
                        <>
                            <LPLanguageSwitcher />
                            {/* <LPButton.Outline
                                styles={LPHeaderSC.QuestionMarkBtnCss}
                                onClick={onHelpClick}
                            >
                                ?
                            </LPButton.Outline> */}
                        </>
                    )}
                    <LPButton.Outline onClick={() => router.push('/sign-in')}>
                        {t('lp_pricing_modal_submit')}
                    </LPButton.Outline>
                </LPHeaderSC.RightPart>
            </LPHeaderSC.Header>
        </LPHeaderSC.Root>
    );
};

const NavList = () => {
    const { t } = useTranslation();
    const { scrollToSection } = usePageScroll();
    const { takeMeTo } = useIsLandingPage();
    const {
        location: { pathname },
    } = useHistory();

    const isLandingPage = pathname === '/landing';

    return (
        <LPHeaderSC.Navigation>
            <LPHeaderSC.List>
                {Navigation.header.map(
                    link =>
                        link.section && (
                            <LPHeaderSC.LinkItem
                                key={link.translation}
                                onClick={() =>
                                    isLandingPage
                                        ? link.section && scrollToSection(link.section)
                                        : link.section && takeMeTo(link.section, scrollToSection)
                                }
                            >
                                {t(link.translation)}
                            </LPHeaderSC.LinkItem>
                        ),
                )}
            </LPHeaderSC.List>
        </LPHeaderSC.Navigation>
    );
};
