import React, { FC, useCallback } from 'react';
import { EditorState } from 'draft-js';
import { Editor, EditorProps } from 'react-draft-wysiwyg';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { BaseFormInputLayout, IBaseFormInputLayout } from '../form/fields/form-input/base-form-input-layout.component';

const defaultOptions = {
  options: ['inline', 'list'],
  inline: {
    options: ['bold', 'italic'],
  },
  list: {
    options: ['unordered', 'ordered'],
  }
};

type Props = EditorProps & IBaseFormInputLayout & {
  className?: string;
  editorState: EditorState;
  handleEditorStateChange: (editorState: EditorState) => void;
}

export const RichTextEditor: FC<Props> = ({
  className,
  editorState,
  handleEditorStateChange,
  // layout props
  label,
  subLabel,
  error,
  withMargins,
  widthPercents,
  ...props
}) => {
  const editorStateChangeHandler = useCallback((state: EditorState) => {
    handleEditorStateChange(state);
  }, [handleEditorStateChange]);  

  return (
    <BaseFormInputLayout
      label={label}
      subLabel={subLabel}
      error={error}
      withMargins={withMargins}
      widthPercents={widthPercents}
    >
      <Editor
        {...props}
        wrapperClassName={className}
        editorState={editorState}
        onEditorStateChange={editorStateChangeHandler}
        toolbar={defaultOptions}
      />
   </BaseFormInputLayout>
  )
}
