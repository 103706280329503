import React from 'react';
import ReactDOM from 'react-dom';

import { Root } from './app/root';

import './index.scss';
import './config/i18n';

ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById('root'),
);
