import React, { useEffect, useRef, useCallback, useContext } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { Helmet } from 'react-helmet-async';

import { useAsyncDispatch } from 'helpers/hooks/useAsyncDispatch';
import { useCMSThemeSettings } from 'units/cms/hooks/selectors/useCMSThemeSettings';
import { useCMSGoogleFonts } from 'units/cms/hooks/selectors/useCMSGoogleFonts';
import { Accordion } from 'units/common/components/accordion/accordion.component';
import {
    fetchCMSGoogleFontsThunk,
    saveCMSThemeSettingsThunk,
    saveAndPublishCMSThemeSettingsThunk,
    saveCMSThemeSettingThunk,
} from 'units/cms/store/thunks';
import { CMSFaviconAccordion } from './cms-favicon/cms-favicon.component';
import { CMSLogoAccordion } from './cms-logo/cms-logo.component';
import { CMSThemeSettings } from 'units/cms/store/types/theme-settings';
import { scrollTop } from 'helpers/general';
import { CMSColorsAccordion } from './cms-colors/cms-colors.component';
import { CMSTypographyAccordion } from './cms-typography/cms-typography.component';
import { CMSSubmitType, CMSFormikThemeSettings } from 'units/cms/types';
import { CMSThemeSettingsForm } from './theme-settings-form/cms-theme-settings-form.component';
import { NotificationContext } from 'shared/providers';
import { useTranslation } from 'react-i18next';

export const CMSThemeSettingsSidebar = () => {
    const { showNotification } = useContext(NotificationContext);
    const { t } = useTranslation();
    const formikInitialThemeSettings: CMSFormikThemeSettings = useCMSThemeSettings();

    const { dispatch } = useAsyncDispatch();
    const fonts = useCMSGoogleFonts();

    const submitRef = useRef<string>('save');

    useEffect(() => {
        if (!fonts.length) {
            dispatch(fetchCMSGoogleFontsThunk(showNotification));
        }
    }, []);

    const handleBeforeSubmit = useCallback(
        (submitType: CMSSubmitType) => (submitRef.current = submitType),
        [],
    );

    const handleSubmit = useCallback(
        async (values: CMSFormikThemeSettings, helpers: FormikHelpers<CMSThemeSettings>) => {
            if (submitRef.current === 'save') {
                await dispatch(saveCMSThemeSettingsThunk(values, showNotification));
            } else {
                await dispatch(saveAndPublishCMSThemeSettingsThunk(values, showNotification));
            }

            scrollTop();
            helpers.setSubmitting(false);
        },
        [],
    );

    const onUpdate = useCallback(async (values: CMSFormikThemeSettings) => {
        await dispatch(saveCMSThemeSettingThunk(values, showNotification));
    }, []);

    return (
        <>
            <Helmet>
                <title>CMS - Theme settings</title>
            </Helmet>
            <Formik
                initialValues={formikInitialThemeSettings}
                onSubmit={handleSubmit}
                validate={onUpdate}
                enableReinitialize
            >
                <CMSThemeSettingsForm beforeSubmit={handleBeforeSubmit}>
                    <Accordion
                        items={[
                            { header: t('theme_settings_colors'), content: <CMSColorsAccordion /> },
                            {
                                header: t('theme_settings_typography'),
                                content: <CMSTypographyAccordion />,
                            },
                            {
                                header: t('theme_settings_favicon'),
                                content: <CMSFaviconAccordion />,
                            },
                            { header: t('theme_settings_logo'), content: <CMSLogoAccordion /> },
                        ]}
                        preExpanded={['Colors']}
                    />
                </CMSThemeSettingsForm>
            </Formik>
        </>
    );
};
