import styled from 'styled-components';

import { Color, sizes } from 'components/styles/constants.styles';
import { TextStyle1 } from 'components/styles/text.styles';

const Container = styled.div`
  &.Modal {
    left: 50%;
    right: auto;
    bottom: auto;
    outline: none;
    overflow: auto;
    position: absolute;
    background: ${Color.white};
    transform: translateX(-50%);
    width: ${sizes.modal_width};
    padding: ${sizes.lg_padding};
    top: ${sizes.modal_margin_top};
    Webkit-overflow-scrolling: touch;
    border-radius: ${sizes.border_radius};
  }
`;

const Close = styled.div`
  color: ${Color.bluey_grey};
  position: absolute;
  font-size: 10px;
  padding: 5px;
  right: 15px;
  top: 15px;

  &:hover {
    cursor: pointer;
  }
`;

const Title = styled.div`
  margin: -5px 0;
  padding: 0 0 ${sizes.md_padding};
  text-align: center;

  &.left {
    text-align: left;
  }
  &.center {
    text-align: center;
  }
  &.right {
    text-align: right;
  }
`;

interface TitleProps {
  success?: boolean;
}

const Text = styled.div<TitleProps>`
  ${TextStyle1};

  text-align: inherit;
  line-height: 32px;
  color: ${props => props.success ? Color.success_green : 'inherit'};

  p {
    margin: 5px 0;
  }
`;

export const ReactModalSC = {
  Container,
  Close,
  Title,
  Text,
};
