import { CMSThemeId } from './types';
import { CMSThemeSettings } from './types/theme-settings';
import { CMSCustomizationConfig } from './types/customization';
import { CMSHomePageCustomizationSettings } from './types/customization/homepage';
import { CMSProductDetailPageCustomizationSettings } from './types/customization/product-detail-page';
import { CMSMissedAuctionsCustomizationSettings } from './types/customization/missed-auctions';
import { CMSShopModalsCustomizationSettings } from 'units/cms/store/types/customization/shop-modals';

export const DEFAULT_THEME_ID = CMSThemeId.Traditional;

// Theme settings

export const CMSDefaultTraditionalThemeSettings: CMSThemeSettings = {
    colors: {
        text: {
            heroAndNavigation: '#000000',
            heading: '#000000',
            body: '#808080',
            salePrive: '#FF4C4C',
            footer: '#cccccc',
        },
        buttons: {
            primary: '#FF4C4C',
            secondary: '#000000',
            textLink: '#000000',
        },
        background: {
            primary: '#ffffff',
            secondary: '#f2f2f2',
            tertiary: '#ffffff',
            footer: '#000000',
        },
        forms: {
            placeholder: '#a6a6a6',
            border: '#E6E6E6',
            background: '#ffffff',
        },
    },
    typography: {
        heading: {
            fontFamily: 'Yeseva One',
            fontSizes: {
                h1: '48',
                h2: '32',
                h3: '26',
            },
        },
        paragraphBody: {
            fontFamily: 'Manrope',
            fontSize: '18',
        },
    },
    favicon: null,
    logo: null,
};

export const CMSDefaultModernThemeSettings: CMSThemeSettings = {
    colors: {
        text: {
            heroAndNavigation: '#000000',
            heading: '#000000',
            body: '#000000',
            salePrive: '#FF4C4C',
            footer: '#cccccc',
        },
        buttons: {
            primary: '#FF4C4C',
            secondary: '#000000',
            textLink: '#000000',
        },
        background: {
            primary: '#ffffff',
            secondary: '#FFFFFF',
            tertiary: '#ffffff',
            footer: '#000000',
        },
        forms: {
            placeholder: '#E6E6E6',
            border: '#E6E6E6',
            background: '#ffffff',
        },
    },
    typography: {
        heading: {
            fontFamily: 'Manrope',
            fontSizes: {
                h1: '48',
                h2: '32',
                h3: '26',
            },
        },
        paragraphBody: {
            fontFamily: 'Manrope',
            fontSize: '18',
        },
    },
    favicon: null,
    logo: null,
};

export const CMSDefaultMinimalThemeSettings: CMSThemeSettings = {
    colors: {
        text: {
            heroAndNavigation: '#000000',
            heading: '#000000',
            body: '#adadad',
            salePrive: '#fc4e51',
            footer: '#cccccc',
        },
        buttons: {
            primary: 'blue',
            secondary: '#3d3d3d',
            textLink: '#0f0f0f',
        },
        background: {
            primary: '#f2f2f2',
            secondary: '#ffffff',
            tertiary: '#ffffff',
            footer: '#000000',
        },
        forms: {
            placeholder: '#fc4e51',
            border: '#181818',
            background: '#ffffff',
        },
    },
    typography: {
        heading: {
            fontFamily: 'Adamina',
            fontSizes: {
                h1: '48',
                h2: '24',
                h3: '18',
            },
        },
        paragraphBody: {
            fontFamily: 'Roboto',
            fontSize: '18',
        },
    },
    favicon: null,
    logo: null,
};

export const CMSDefaultLuxuryThemeSettings: CMSThemeSettings = {
    colors: {
        text: {
            heroAndNavigation: '#000000',
            heading: '#000000',
            body: '#adadad',
            salePrive: '#fc4e51',
            footer: '#cccccc',
        },
        buttons: {
            primary: 'yellow',
            secondary: '#3d3d3d',
            textLink: '#0f0f0f',
        },
        background: {
            primary: '#f2f2f2',
            secondary: '#ffffff',
            tertiary: '#ffffff',
            footer: '#000000',
        },
        forms: {
            placeholder: '#fc4e51',
            border: '#181818',
            background: '#ffffff',
        },
    },
    typography: {
        heading: {
            fontFamily: 'Adamina',
            fontSizes: {
                h1: '48',
                h2: '24',
                h3: '18',
            },
        },
        paragraphBody: {
            fontFamily: 'Roboto',
            fontSize: '18',
        },
    },
    favicon: null,
    logo: null,
};

export const CMSDefaultFunkyThemeSettings: CMSThemeSettings = {
    colors: {
        text: {
            heroAndNavigation: '#000000',
            heading: '#000000',
            body: '#adadad',
            salePrive: '#fc4e51',
            footer: '#cccccc',
        },
        buttons: {
            primary: 'pink',
            secondary: '#3d3d3d',
            textLink: '#0f0f0f',
        },
        background: {
            primary: '#f2f2f2',
            secondary: '#ffffff',
            tertiary: '#ffffff',
            footer: '#000000',
        },
        forms: {
            placeholder: '#fc4e51',
            border: '#181818',
            background: '#ffffff',
        },
    },
    typography: {
        heading: {
            fontFamily: 'Adamina',
            fontSizes: {
                h1: '48',
                h2: '24',
                h3: '18',
            },
        },
        paragraphBody: {
            fontFamily: 'Roboto',
            fontSize: '18',
        },
    },
    favicon: null,
    logo: null,
};

export const CMSDefaultEstateThemeSettings: CMSThemeSettings = {
    colors: {
        text: {
            heroAndNavigation: '#000000',
            heading: '#000000',
            body: '#000000',
            salePrive: '#1CA8FF',
            footer: '#cccccc',
        },
        buttons: {
            primary: '#1CA8FF',
            secondary: '#010101',
            textLink: '#000000',
        },
        background: {
            primary: '#ffffff',
            secondary: '#ffffff',
            tertiary: '#ffffff',
            footer: '#000000',
        },
        forms: {
            placeholder: '#BFBFBF',
            border: '#E6E6E6',
            background: '#ffffff',
        },
    },
    typography: {
        heading: {
            fontFamily: 'Adamina',
            fontSizes: {
                h1: '48',
                h2: '24',
                h3: '18',
            },
        },
        paragraphBody: {
            fontFamily: 'Roboto',
            fontSize: '18',
        },
    },
    favicon: null,
    logo: null,
};

export const CMSDefaultThemeSettingsConfig = {
    [CMSThemeId.Traditional]: {
        settings: CMSDefaultTraditionalThemeSettings,
    },
    [CMSThemeId.Modern]: {
        settings: CMSDefaultModernThemeSettings,
    },
    [CMSThemeId.Minimal]: {
        settings: CMSDefaultMinimalThemeSettings,
    },
    [CMSThemeId.Luxury]: {
        settings: CMSDefaultLuxuryThemeSettings,
    },
    [CMSThemeId.Funky]: {
        settings: CMSDefaultFunkyThemeSettings,
    },
    [CMSThemeId.Estate]: {
        settings: CMSDefaultEstateThemeSettings,
    },
};

// Customization

// --- Homepage
export const CMSDefaultHomepageSettings: CMSHomePageCustomizationSettings = {
    hero: {
        CTAcopy: '',
        timerTitle: '',
        conversationSticker: {
            isActive: false,
            color: '#fc4e51',
            icon: null,
        },
    },
    usp: {
        isActive: true,
        usp1: {
            icon: null,
            title: '',
            description: '',
        },
        usp2: {
            icon: null,
            title: '',
            description: '',
        },
        usp3: {
            icon: null,
            title: '',
            description: '',
        },
    },
    textBlock: {
        isActive: true,
        renderMode: 'picture',
        picture: null,
        title: '',
        description: '',
        textLink: {
            isActive: false,
            name: '',
            target: '',
        },
    },
    productCards: {
        sectionTitle: '',
        loadMoreLink: '',
        CTAText: '',
    },
    newsletterOption: {
        isActive: true,
        title: '',
        subTitle: '',
        fieldPlaceholder: '',
        icon: null,
    },
    footer: {
        title: '',
        footerLinks: Array(4).fill({
            name: '',
            url: '',
        }),
        staticLinks: {
            disclaimer: {
                name: '',
                url: '',
            },
            termsAndConditions: {
                name: '',
                url: '',
            },
            dataProtection: {
                name: '',
                url: '',
            },
        },
        socialLinks: {
            instagram: {
                isActive: false,
                url: '',
            },
            tiktok: {
                isActive: false,
                url: '',
            },
            facebook: {
                isActive: false,
                url: '',
            },
            linkedin: {
                isActive: false,
                url: '',
            },
            twitter: {
                isActive: false,
                url: '',
            },
            snapchat: {
                isActive: false,
                url: '',
            },
        },
    },
};

// --- Product detail page
export const CMSDefaultDetailPageSettings: CMSProductDetailPageCustomizationSettings = {
    hero: {
        CTAcopy: '',
        conversationSticker: { isActive: false },
        graph1: {
            color: '#000000',
            title: '',
        },
        graph2: {
            color: '#fc4e51',
            title: '',
        },
    },
    relatedProducts: { sectionTitle: '', products: [] },
    newsletterOptIn: { isActive: true },
};

// --- Missed auctions
export const CMSDefaultMissedAuctionsSettings: CMSMissedAuctionsCustomizationSettings = {
    general: {
        title: '',
        newsletterOptIn: true,
    },
};

export const CMSCustomizationPageConfig = {
    settings: {},
    isPublished: false,
};

export const CMSDefaultCustomizationConfig: CMSCustomizationConfig = {
    homepage: {
        settings: {},
        carouselAuctions: [],
        isPublished: false,
    },
    productDetailPage: {
        settings: {},
        isPublished: false,
    },
    missedAuctions: {
        settings: {},
        isPublished: false,
    },
    shopModals: {
        settings: {},
        isPublished: false,
    },
};

// --- Shop modals
export const CMSDefaultShopModalsSettings: CMSShopModalsCustomizationSettings = {
    general: CMSDefaultTraditionalThemeSettings.colors.forms,
    register: {
        title: '',
        subtitle: '',
        button: '',
    },
    login1: {
        title: '',
        subtitle: '',
        button: '',
    },
    login2: {
        title: '',
        subtitle: '',
        button: '',
    },
    offerConfirmation: {
        title: '',
        subtitle: '',
        bindingNotification: '',
        button: '',
        confirmButtonIcon: null,
    },
    offerDenial: {
        title: '',
        subtitle: '',
        button: '',
        denyButtonIcon: null,
    },
    offerWon: {
        title: '',
        subtitle: '',
        button: '',
        winButtonIcon: null,
    },
    resetPassword: {
        title: '',
        button: '',
        /* 


    mainContent: '',
    additionalContent: '',
    sentEmailIcon: null, */
        //enterPasswordIcon: null
    },
};
