import React, { FC } from 'react';
import { Props as ReactSelectProps } from 'react-select';

import { SelectSC } from './select.styles';

export interface IOption<T = string> {
    label: string;
    value: T;
}

export interface ISelect extends ReactSelectProps {
    label?: string;
}

export const Select: FC<ISelect> = ({ label, className, error, margin, padding, ...props }) => (
    <SelectSC.SelectContainer className={className} margin={margin} padding={padding}>
        {label && (
            <SelectSC.LabelContainer>
                <SelectSC.Label>{label}</SelectSC.Label>
            </SelectSC.LabelContainer>
        )}
        <SelectSC.ReactSelectContainer classNamePrefix={'select'} {...props} />
        {error && <SelectSC.Error>{error}</SelectSC.Error>}
    </SelectSC.SelectContainer>
);
