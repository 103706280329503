/* eslint-disable import/named */
import styled, { FlattenSimpleInterpolation } from 'styled-components';
/* eslint-enable import/named */

import { Color } from 'components/styles/constants.styles';

import {
    LPSubtitleText,
    LPTitleText,
    LPCardTitleText,
    TextStyle3,
    TextStyle6,
} from 'components/styles/text.styles';

import { MediaQueries } from 'units/landing-page/config';

type GeneralProps = {
    styles?: FlattenSimpleInterpolation;
};

const H2 = styled.h2<GeneralProps>`
    ${LPTitleText}

    @media (max-width: ${MediaQueries.tablet}) {
        font-size: 40px;
        line-height: 48px;
    }

    @media (max-width: ${MediaQueries.mobile}) {
        font-size: 24px;
        line-height: 32px;
    }

    ${props => props.styles}
`;

const H3 = styled.h3<GeneralProps>`
    ${LPCardTitleText}

    @media (max-width: ${MediaQueries.tablet}) {
        font-size: 20px;
        line-height: 24px;
    }

    ${props => props.styles}
`;

const H4 = styled.h4<GeneralProps>`
    ${TextStyle3};
    font-weight: 700;

    @media (max-width: ${MediaQueries.tablet}) {
        font-size: 16px;
        line-height: 1.5;
    }

    ${props => props.styles}
`;

const H5 = styled.h5<GeneralProps>`
    ${TextStyle6};
    letter-spacing: normal;
    color: ${Color.black};
    text-align: left;

    @media (max-width: ${MediaQueries.tablet}) {
        font-size: 16px;
        line-height: 1.5;
    }

    ${props => props.styles}
`;

const Text = styled.p<GeneralProps>`
    ${LPSubtitleText}

    @media (max-width: ${MediaQueries.tablet}) {
        font-size: 14px;
        line-height: 20px;
    }

    @media (max-width: ${MediaQueries.mobile}) {
        font-size: 12px;
        line-height: 16px;
    }

    ${props => props.styles}
`;

export const LPTypographySC = {
    H2,
    H3,
    H4,
    H5,
    Text,
};
