import { css } from 'styled-components';

import { Color } from './constants.styles';
import { Font } from '../types/enums';

// fonts
export const getFont = (font: Font) => `${font}, sans-serif`;

export const fonts = {
  regular: getFont(Font.regular),
  medium: getFont(Font.medium),
  bold: getFont(Font.bold),
  black: getFont(Font.black),
  manropeBold: getFont(Font.manropeBold),
  yesevaOneRegular: getFont(Font.yesevaOneRegular),
  mayanmarMNRegular: getFont(Font.mayanmarMNRegular),
};

// Fonts with sans-serif
export const FontSS = fonts;

// elements
export const getCircleWithContent = (size: number, color: Color) => css`
  width: ${size}px;
  height: ${size}px;
  flex-shrink: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;
  background-color: ${color};
`;

// text
export interface EllipsisProps {
  nowrap?: boolean;
}

export const alignMe = (justify: string, align: string) => css`
  display: flex;
  align-items: ${align};
  justify-content: ${justify};
`;

export const Ellipsis = css<EllipsisProps>`
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ nowrap }) => nowrap && 'white-space: nowrap;'}
`;
