import React, { useCallback } from 'react';
import { useField } from 'formik';

import { BaseFormikWrapper, IBaseFormikWrapper } from '../wrapper/base-formik-wrapper.component';
import { BaseToggleButton, IBaseToggleButton } from '../../toggle-button/base-toggle-button.component';

type Props = IBaseToggleButton & Omit<IBaseFormikWrapper<HTMLInputElement>, 'component'>;

export const FormikToggleButton = (props: Props) => {
  const [{ value },,{ setValue }] = useField(props.name);

  const handleToggleChange = useCallback(({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setValue(target.checked);
  }, []);

  return (
    <BaseFormikWrapper
      component={BaseToggleButton}
      onChange={handleToggleChange}
      checked={!!value}
      {...props}
    />
  )
}
