import React from 'react';
import clsx from 'clsx';

import { LoadingIcon } from '../LoadingIcon/LoadingIcon';

import styles from './Preloader.module.scss';

type PreloaderProps = {
    height?: number;
    headerMargin?: boolean;
};

const Base: React.FC<PreloaderProps> = ({ height, headerMargin }) => (
    <div
        className={clsx(styles.root, { [styles['header-margin']]: headerMargin })}
        {...(height && { style: { height } })}
    >
        <LoadingIcon />
    </div>
);

const Content: React.FC<PreloaderProps> = ({ ...props }) => <Base {...props} />;

type PageProps = {
    height?: number;
};

const Page: React.FC<PageProps> = ({ height }) => <Base height={height} />;

export const Preloader = {
    Content,
    Page,
};
