import React, { FC } from 'react';

import { DescriptionFieldSetSC } from './description-fieldset.style';
import { FormikFormInput } from 'units/common/components/formik/input/form-input.component';
import { IconSC, IconUnicodes } from 'components/styles/icons.styles';
import { Color } from 'components/styles/constants.styles';
import { FormikRichTextEditor } from 'units/common/components/formik/rich-text-editor/form-rich-text-editor.component';

interface Props {
    prefixName: string;
    error?: {
        type: string;
        description: string;
    };
    withDivider?: boolean;
    withRemoveButton?: boolean;
    placeholder?: string;
    onRemoveButtonClick?: () => void;
}

export const DescriptionFieldSet: FC<Props> = ({
    prefixName,
    error,
    withDivider,
    withRemoveButton,
    onRemoveButtonClick,
    placeholder,
}) => {
    // const [placeholder, setPlaceholder] = useState('Enter description');

    // const checkPlaceholder = (content: any) => {
    //     if (content.blocks[0].type !== 'unstyled') {
    //         console.log('alooo');
    //         setPlaceholder('');
    //     }
    // };

    return (
        <DescriptionFieldSetSC.Container withDivider={!!withDivider}>
            <FormikFormInput
                name={`${prefixName}.type`}
                label="Description type"
                placeholder="Enter description type"
                error={error?.type}
            />
            <FormikRichTextEditor
                name={`${prefixName}.description`}
                label="Description"
                placeholder={placeholder}
                error={error?.description}
                // onChange={checkPlaceholder}
            />
            {withRemoveButton && (
                <DescriptionFieldSetSC.RemoveButton onClick={onRemoveButtonClick}>
                    <IconSC color={Color.bluey_grey} name={IconUnicodes.trash} size={16} />
                </DescriptionFieldSetSC.RemoveButton>
            )}
        </DescriptionFieldSetSC.Container>
    );
};
