/* eslint-disable no-nested-ternary */

import clsx from 'clsx';
import React, { FC } from 'react';
import { Icon } from 'shared/components/ui';
import styles from './table-sort.module.scss';

interface TableSortProps {
    column?: any;
}

export const TableSort: FC<TableSortProps> = ({ column }) => {
    if (!column) return null;
    return (
        <span className={styles.sortArrows}>
            {column.isSorted ? (
                column.isSortedDesc ? (
                    <Icon name="dropdown" className={clsx(styles.active)} />
                ) : (
                    <Icon name="dropdown" className={clsx(styles.active, styles.up)} />
                )
            ) : (
                <span className={styles.initialState}>
                    <Icon name="dropdown" className={styles.up} />
                    <Icon name="dropdown" />
                </span>
            )}
        </span>
    );
};
/* eslint-enable no-nested-ternary */
