import React, { FC, ButtonHTMLAttributes, ReactNode } from 'react';

import { SecondaryButtonSC } from './secondary.styles';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  children?: string | ReactNode;
  isWide?: boolean;
}

export const SecondaryButton: FC<Props> = ({ children, ...props }) => (
  <SecondaryButtonSC {...props}>
    {children}
  </SecondaryButtonSC>
);
