import React, { FC } from 'react';

import { Modal, ModalGeneralProps } from '../modal.component';
import { SuccessModalSC } from './success-modal.styles';
import { TransparentButton } from '../../buttons/transparent/transparent.component';

export interface ConfirmModalProps extends ModalGeneralProps {
  buttonTitle: string;
  text: string;
  confirmHandler: () => void;
}

// Have not tested with custom children as we don't have task for now.
export const SuccessModal:FC<ConfirmModalProps> = ({
  confirmHandler,
  buttonTitle,
  text,
  ...rest
}) => (
  <Modal
    titlePosition="center"
    success
    {...rest}
  >
    <SuccessModalSC.Container>
        <SuccessModalSC.Text>
          { text }
        </SuccessModalSC.Text>
        <TransparentButton type="button" onClick={confirmHandler}>
          { buttonTitle || 'OK' }
        </TransparentButton>
    </SuccessModalSC.Container>
  </Modal>
);
