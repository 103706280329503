import React, { FC, useMemo } from 'react';

import { BaseToggleButtonSC } from './base-toggle-button.styles';
import { generateId } from 'helpers/general';

export interface IBaseToggleButton {
  id?: string;
  name?: string;
  label?: string | React.ReactNode;
  initialChecked?: boolean;
  checked?: boolean;
  onChange?: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

export const BaseToggleButton: FC<IBaseToggleButton> = ({
  label,
  initialChecked,
  name,
  id,
  checked,
  onChange,
  disabled,
}) => {
  const inputId = useMemo(() => id || generateId(), [id]);

  return (
    <BaseToggleButtonSC.Container disabled={disabled}>
      <BaseToggleButtonSC.ToggleContainer>
        <BaseToggleButtonSC.Input
          type="checkbox"
          id={inputId}
          name={name}
          defaultChecked={checked != null ? initialChecked : undefined}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
        />
        <BaseToggleButtonSC.Slider></BaseToggleButtonSC.Slider>
      </BaseToggleButtonSC.ToggleContainer>
      { label && (
        <BaseToggleButtonSC.Label htmlFor={inputId}>
          { label }
        </BaseToggleButtonSC.Label>
      ) }
    </BaseToggleButtonSC.Container>
  );
}
