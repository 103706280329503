import styled from 'styled-components';

import { TextStyle8 } from 'components/styles/text.styles';
import { Color } from 'components/styles/constants.styles';

import { alignMe } from 'components/styles/helpers.styles';

const ContentContainer = styled.div`
    width: 649px;
    height: 91px;
    ${alignMe('space-between', 'center')};
    flex-direction: column;
    margin-bottom: 40px;
`;

const PageSubtitle = styled.h5`
    ${TextStyle8};

    text-align: center;
    color: ${Color.black};
`;

const Email = styled.h3`
    ${TextStyle8};

    font-size: 32px;
    text-align: center;
    line-height: 1.25;
    color: ${Color.black};
`;

export const ReadyToGoSC = {
    PageSubtitle,
    ContentContainer,
    Email,
}
