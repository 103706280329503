const index = '/auctions';
const createAuction = `${index}/create-auction`;
const editAuction = `${index}/edit/:auctionId`;
const viewAuction = `${index}/view/:auctionId`;

export const AuctionUrls = {
  index,
  createAuction,
  editAuction,
  viewAuction,
};
