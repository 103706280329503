import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { TextStyle16 } from 'components/styles/text.styles';
import { PrimaryButtonStyle } from 'components/styles/button.styles';
import { MediaQueries } from 'units/landing-page/config';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Message = styled.div`
    ${TextStyle16}
    max-width: 647px;
    margin: 28px 0 48px 0;
`;

const Icon = styled.div`
    img {
        width: 246px;
        height: 246px;
    }
`;

const Button = styled(Link)`
    ${PrimaryButtonStyle}

    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    text-transform: uppercase;
    margin-top: 40px;
    width: 278px;
    height: 72px;
    @media (max-width: ${MediaQueries.mobile}) {
        width: 100%;
    }
`;

export const SmallScreenTemplateSC = {
    Container,
    Message,
    Icon,
    Button
}
