import styled, { keyframes } from 'styled-components';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import { Color, sizes, transition } from 'components/styles/constants.styles';
import { TextStyle13, ItemTitleText } from 'components/styles/text.styles';

const FadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Container = styled(Accordion)``;

const Item = styled(AccordionItem)``;

const ItemHeading = styled(AccordionItemHeading)``;

const ItemButton = styled(AccordionItemButton)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 0 ${sizes.md_padding} 0;
  border: none;

  ${TextStyle13};
  cursor: pointer;
  color: ${Color.charcoal_grey};
  line-height: 1;
  user-select: none;

  span {
    color: ${Color.bluey_grey};
  }

  &[aria-expanded='true'],
  &[aria-selected='true'] {
    ${ItemTitleText};
    color: ${Color.lightish_blue};

    span {
      transform: rotate(180deg);
      color: ${Color.lightish_blue};
      animation: ${FadeIn} ${transition} ease-in;
    }
  }

  &:active,
  &:focus {
    outline: none;
  }

  &:hover {
    color: ${Color.lightish_blue};

    span {
      color: ${Color.lightish_blue};
    }
  }
`;

const ItemPannel = styled(AccordionItemPanel)`
  padding: 0 0 ${sizes.md_padding} 0;
  animation: ${FadeIn} ${transition} ease-in;
`;

export const AccordionSC = {
  Container,
  Item,
  ItemHeading,
  ItemButton,
  ItemPannel,
};
