import React, { TextareaHTMLAttributes } from 'react';
import clsx from 'clsx';

import styles from './Textarea.module.scss';

type ImmoTextareaProps = {
    className?: string;
    height: number;
};

export const ImmoTextarea: React.FC<
    TextareaHTMLAttributes<HTMLTextAreaElement> & ImmoTextareaProps
> = ({ className, height, ...props }) => (
    <textarea className={clsx(styles.root, className)} style={{ height }} {...props} />
);
