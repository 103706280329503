import styled, { css } from 'styled-components';

import { sizes, Color } from 'components/styles/constants.styles';
import {
  FormInputText,
  FormInputLabelText,
  FormInputSubLabelText,
  FormInputPostfixText,
  FormInputErrorText,
} from 'components/styles/text.styles';
import { CommonInputStyles, CommonTextAreaStyles } from '../common-input.styles';

interface InputWrapperProps {
  withMargins?: boolean;
  widthPercents?: number;
  paddingBottom?: string;
  disabled?: boolean;
}

interface InputProps {
  withBackground?: boolean;
  height?: string | number;
  spaceForClearBtn?: boolean;
}

const CommonFormInputStyle = css<InputProps>`
  padding: 12px 20px;
  ${FormInputText};

  ${({ withBackground }) => withBackground && `
    background-color: ${Color.pale_grey};
  `}
`;

const Label = styled.label`
  ${FormInputLabelText}
`;

const InputContainer = styled('div')<InputWrapperProps>`
  display: flex;
  flex-direction: column;
  padding-bottom: ${({ paddingBottom }) => paddingBottom || '24px'};

  ${({ widthPercents, withMargins }) => withMargins
    ? `width: calc(${widthPercents}% - ${sizes.grid_margin} - ${sizes.grid_margin});`
    : `width: ${widthPercents};`
  }

  ${({ withMargins }) => withMargins &&
    `margin-left: ${sizes.grid_margin};
      margin-right: ${sizes.grid_margin};`
  }

  ${({ disabled }) => disabled && css`
    ${Label}{
      color: ${Color.disabled_text_grey};
    }
  `}  
`;

const LabelContainer = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

const SubLabel = styled.span`
  margin-left: 8px;

  ${FormInputSubLabelText}
`;

const InputWrapper = styled.div`
  display: flex;
  position: relative;
`;

const InputPostfix = styled.span`
  margin-left: 14px;
  align-self: center;

  ${FormInputPostfixText}
`;

const BaseInput = styled.input<InputProps>`
  width: 100%;
  flex: 1;

  ${CommonInputStyles}
  ${CommonFormInputStyle}

  ${({ spaceForClearBtn }) => spaceForClearBtn ? 'padding-right: 40px' : ''};
`;

const ClearBtn = styled.div`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    cursor: pointer;
`;

const BaseTextArea = styled.textarea<InputProps>`
  ${CommonTextAreaStyles}
  ${CommonFormInputStyle}

  ${({ height }) => height && `height: ${typeof height === 'string' ? height : height + 'px'};`}

  resize: none;
`;

const Error = styled.p`
  ${FormInputErrorText}

  margin: 8px 0 0 0;
`;

export const FormInputSC = {
  Label,
  InputContainer,
  Error,
  InputPostfix,
  InputWrapper,
  SubLabel,
  LabelContainer,
  BaseTextArea,
  BaseInput,
  ClearBtn,
};
