import styled from 'styled-components';

import { sizes } from 'components/styles/constants.styles';
import { PrimaryButtonStyle } from 'components/styles/button.styles';

const FormElement = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -${sizes.grid_margin};
  padding-top: 8px;
`;

const SaveButton = styled.button`
  width: 135px;
  margin: 16px ${sizes.grid_margin} 0 auto;

  ${PrimaryButtonStyle};
  text-transform: none;
  line-height: 16px;
`;

export const AccountSC = {
  FormElement,
  SaveButton,
};
