/* eslint-disable max-len */
import React from 'react';

export const NotFoundIcon = () => (
  <svg width="146" height="146" viewBox="0 0 146 146" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path d="M145.715 72.7149C145.715 113.032 113.032 145.715 72.7149 145.715C32.3975 145.715 -0.285156 113.032 -0.285156 72.7149C-0.285156 32.3975 32.3975 -0.285156 72.7149 -0.285156C113.032 -0.285156 145.715 32.3975 145.715 72.7149Z" fill="#A9B7FF"/>
      <path d="M98.6902 140.96C90.6189 144.031 81.8649 145.715 72.7142 145.715C63.5636 145.715 54.8095 144.031 46.7383 140.96L52.7087 123.665L57.5185 109.742H87.91L92.7186 123.665L98.6902 140.96Z" fill="#4B67F8"/>
      <path d="M92.7188 123.665L48.5352 135.752L57.5187 109.742H87.9101L92.7188 123.665Z" fill="#324DDB"/>
      <path d="M142.886 22.5618V92.9163C140.502 101.211 136.685 108.902 131.724 115.701H13.7081C8.74791 108.902 4.9306 101.211 2.54688 92.9163V22.5618C2.54688 17.7231 6.46777 13.7988 11.3065 13.7988H134.123C138.962 13.7988 142.886 17.7231 142.886 22.5618Z" fill="#4B67F8"/>
      <path d="M11.3065 97.646C9.76149 97.646 8.50391 96.3895 8.50391 94.8445V22.5607C8.50391 21.0158 9.76149 19.7582 11.3065 19.7582H134.122C135.668 19.7582 136.924 21.0158 136.924 22.5607V94.8445C136.924 96.3895 135.667 97.646 134.122 97.646H11.3065Z" fill="#FD9D40"/>
      <path d="M35.0893 71.5843H20.8616C19.0983 71.5843 17.6992 70.4291 17.6992 68.3005C17.6992 67.8148 17.8206 67.1454 18.186 66.4771L32.2322 38.9327C33.1433 37.1694 34.6638 36.5 36.1843 36.5C37.8261 36.5 40.9272 37.8979 40.9272 40.0878C40.9272 40.4521 40.8047 40.8174 40.6231 41.243L29.8607 62.8279H35.0893V58.5718C35.0893 56.5656 37.4608 55.7146 39.8322 55.7146C42.2026 55.7146 44.5741 56.5656 44.5741 58.5718V62.8279H47.1895C49.1344 62.8279 50.1079 65.0179 50.1079 67.2067C50.1079 69.3955 48.6487 71.5843 47.1895 71.5843H44.5741V78.0292C44.5741 79.9752 42.2026 80.9487 39.8322 80.9487C37.4608 80.9487 35.0893 79.9752 35.0893 78.0292V71.5843Z" fill="#4B67F8"/>
      <path d="M112.628 71.5843H98.4006C96.6373 71.5843 95.2383 70.4291 95.2383 68.3005C95.2383 67.8148 95.3597 67.1454 95.7239 66.4771L109.77 38.9327C110.682 37.1694 112.203 36.5 113.723 36.5C115.365 36.5 118.465 37.8979 118.465 40.0878C118.465 40.4521 118.344 40.8174 118.162 41.243L107.399 62.8279H112.628V58.5718C112.628 56.5656 115 55.7146 117.371 55.7146C119.743 55.7146 122.113 56.5656 122.113 58.5718V62.8279H124.729C126.673 62.8279 127.647 65.0179 127.647 67.2067C127.647 69.3955 126.188 71.5843 124.729 71.5843H122.113V78.0292C122.113 79.9752 119.742 80.9487 117.371 80.9487C115 80.9487 112.628 79.9752 112.628 78.0292V71.5843Z" fill="#4B67F8"/>
      <path d="M95.1699 58.4926C95.1699 70.8947 85.1159 80.9487 72.7139 80.9487C60.3118 80.9487 50.2578 70.8947 50.2578 58.4926C50.2578 46.0905 60.3118 36.0366 72.7139 36.0366C85.1159 36.0366 95.1699 46.0905 95.1699 58.4926Z" fill="white"/>
      <path d="M64.0279 71.9283L59.2782 67.1787C58.5531 66.4547 58.5531 65.2795 59.2782 64.5544L78.7758 45.0568C79.5009 44.3317 80.6761 44.3317 81.4001 45.0568L86.1498 49.8064C86.8749 50.5305 86.8749 51.7056 86.1498 52.4308L66.6522 71.9283C65.9271 72.6535 64.7519 72.6535 64.0279 71.9283Z" fill="#F85563"/>
      <path d="M59.2782 49.8064L64.0279 45.0568C64.7519 44.3317 65.9271 44.3317 66.6522 45.0568L86.1498 64.5544C86.8749 65.2795 86.8749 66.4547 86.1498 67.1787L81.4001 71.9283C80.6761 72.6535 79.5009 72.6535 78.7758 71.9283L59.2782 52.4308C58.5531 51.7056 58.5531 50.5305 59.2782 49.8064Z" fill="#F85563"/>
    </g>
    <defs>
    <clipPath id="clip0">
    <rect width="146" height="146" fill="white"/>
    </clipPath>
    </defs>
  </svg>
);

/* eslint-enable max-len */
