import { overallUpdatesCreator } from '.';

export const HomepageSharedFields = [
  // Hero section
  'hero.conversationSticker.isActive', 'hero.conversationSticker.icon',

  // USP section
  'usp.isActive', 'usp.usp1.icon', 'usp.usp2.icon', 'usp.usp3.icon',

  // Text block
  'textBlock.isActive', 'textBlock.renderMode', 'textBlock.picture', 'textBlock.textLink.isActive', 'textBlock.textLink.target',

  // Newsletter
  'newsletterOption.isActive', 'newsletterOption.icon',

  // Footer
  'footer.socialLinks.facebook.isActive', 'footer.socialLinks.instagram.isActive', 'footer.socialLinks.twitter.isActive',
  'footer.socialLinks.snapchat.isActive', 'footer.socialLinks.linkedin.isActive', 'footer.socialLinks.tiktok.isActive',
  'footer.socialLinks.facebook.url', 'footer.socialLinks.instagram.url', 'footer.socialLinks.twitter.url',
  'footer.socialLinks.snapchat.url', 'footer.socialLinks.linkedin.url', 'footer.socialLinks.tiktok.url',
  'footer.footerLinks.[0].url', 'footer.footerLinks.[1].url',
  'footer.footerLinks.[2].url', 'footer.footerLinks.[3].url',
];

export const createOverallUpdatesForCMSHomepage = overallUpdatesCreator(HomepageSharedFields);
