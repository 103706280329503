import React from 'react';
import clsx from 'clsx';
import { Icon, Typography } from 'shared/components/ui';
import { FileUpload } from '../FileUpload/FileUpload';

import styles from './PhotosLoader.module.scss';
import { ObjectImageType } from 'shared/types';
import { useTranslation } from 'react-i18next';

type PhotosLoaderProps = {
    className?: string;
    photos: null | File[] | ObjectImageType[];
    onSelect: (files: any) => void;
    makePrimary: (index: number) => void;
    remove: (index: number) => void;
};

export const PhotosLoader: React.FC<PhotosLoaderProps> = ({
    className,
    onSelect,
    makePrimary,
    photos,
    remove,
}) => {
    const { t } = useTranslation();

    return (
        <div className={clsx(styles.root, className)}>
            <Typography.Title htmlElement="h4">{t('new_object_photos')}</Typography.Title>
            <Typography.Text>{t('new_object_photos_description')}</Typography.Text>
            <div className={styles.list}>
                <FileUpload
                    className={styles.loader}
                    onSelect={onSelect}
                    accept=".png, .jpg, .jpeg"
                >
                    <div className={styles.icon}>
                        <Icon name="close" />
                    </div>
                </FileUpload>
                {photos &&
                    (photos as any).map((item: any, index: number) => (
                        <div key={JSON.stringify(item)} className={styles['image-wrapper']}>
                            {index === 0 && (
                                <div className={styles.primary}>
                                    {t('new_object_photos_primary')}
                                </div>
                            )}
                            {index > 0 && (
                                <div
                                    className={styles['make-primary']}
                                    onClick={makePrimary.bind(null, index)}
                                >
                                    {t('new_object_photos_make_primary')}
                                </div>
                            )}
                            <div className={styles.remove} onClick={remove.bind(null, index)}>
                                <Icon name="close" />
                            </div>
                            <img
                                className={styles.image}
                                src={item instanceof File ? URL.createObjectURL(item) : item.image}
                                alt={item instanceof File ? item?.name : 'image'}
                            />
                        </div>
                    ))}
            </div>
        </div>
    );
};
