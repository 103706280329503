import styled, { css } from 'styled-components';

import { Font } from 'components/types/enums';

import { Color } from 'components/styles/constants.styles';
import { MediaQueries } from 'units/landing-page/config';
import { LPAnimationsCS } from 'units/landing-page/animations';

const customQuery = '1170px';

const QuestionMarkBtnCss = css`
  padding: 6px 14px;

  @media (max-width: ${MediaQueries.tablet}) {
    padding-left: 14px;
    padding-right: 14px;
  }
`;

const Root = styled.div<{ isScroll:boolean}>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  height: 170px;
  display: flex;
  align-items: center;
  transition: height 400ms ease-in-out, background-color 100ms ease-in-out;
  ${props => props.isScroll && `
    height: 100px;
    background-color: ${Color.white};
    border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
  `}
  @media (max-width: ${MediaQueries.tablet}) {
    height: 105px;
    ${props => props.isScroll && `
      height: 89px;
    `}
  }
  @media (max-width: ${MediaQueries.mobile}) {
    height: 145px;
    ${props => props.isScroll && `
      height: 72px;
    `}
  }
`;

const Header = styled.header`
  width: 100%;
  max-width: 1250px;
  padding-left: 37px;
  padding-right: 37px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 5;
  @media (max-width: ${MediaQueries.tablet}) {
    padding-left: 15px;
    padding-right: 15px;
  }
  @media (max-width: ${MediaQueries.mobile}) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const LeftPart = styled.div`
  display: flex;
  width: 65%;
  align-items: center;

  img {
    height: 60px;
    cursor: pointer;

    @media (max-width: ${MediaQueries.tablet}) {
      height: 40px;
    }

    @media (max-width: ${MediaQueries.mobile}) {
      margin-left: 60px;
    }
  }
`;

const RightPart = styled.div`
  display: flex;
  align-items: center;
  width: 35%;
  justify-content: flex-end;

  button {
    margin-left: 12%;
  }

  ${LPAnimationsCS.FadeDownHeader}

  @media (max-width: ${MediaQueries.tablet}) {
    button {
      margin-left: 8%;
    }
  }

  @media (max-width: ${MediaQueries.mobile}) {
    width: 50%;
  }
`;

const LogoWrapper = styled.div`
  ${LPAnimationsCS.ZoomInLogo}
`;

const Navigation = styled.nav`
  margin-left: 10%;

  ${LPAnimationsCS.FadeDownHeader}

  @media (max-width: ${customQuery}) {
    margin-left: 6%;
  }

  @media (max-width: ${MediaQueries.tablet}) {
    margin-left: 5%;
  }
`;

const List = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0;
  padding-left: 0;
`;

const LinkItem = styled.li`
  margin-left: 27%;
  font-size: 16px;
  font-family: ${Font.medium};
  color: ${Color.black};
  transition: color 300ms ease-in;
  cursor: pointer;
  &:hover {
    color: ${Color.watermelon};
  }

  :first-child {
    margin-left: 0;
  }

  @media (max-width: ${customQuery}) {
    margin-left: 13%;
  }

  @media (max-width: ${MediaQueries.tablet}) {
    margin-left: 10%;
  }
`;

export const LPHeaderSC = {
  Root,
  Header,
  LeftPart,
  RightPart,
  LogoWrapper,
  Navigation,
  LinkItem,
  List,

  QuestionMarkBtnCss
};
