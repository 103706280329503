import React, { FC } from 'react';

import { CmsAcordionSection } from 'units/cms/components/cms-acordion-section/cmsAcordionSection.component';
import { FormikFormInput } from 'units/common/components/formik/input/form-input.component';
import { TrackProgressField } from 'units/common/hooks/useTrackProgress';
import { CMSRootSC } from 'units/cms/styles';
import { useTranslation } from 'react-i18next';

type Props = {
    updateProgress: (field: TrackProgressField) => void;
};

export const CMSHomepageProductCardAccordion: FC<Props> = ({ updateProgress }) => {
    const { t } = useTranslation();

    return (
        <CmsAcordionSection withDevider={false}>
            <CMSRootSC.SectionTitle>
                {t('customization_product_cards_subtitle')}
            </CMSRootSC.SectionTitle>
            <FormikFormInput
                name="productCards.sectionTitle"
                label={t('customization_product_cards_section_title')}
                placeholder={t('customization_product_cards_section_title_placeholder')}
                paddingBottom="0"
                afterChange={({ target }) => {
                    updateProgress({ name: 'sectionTitle', isFilled: !!target.value });
                }}
            />
            <FormikFormInput
                name="productCards.loadMoreLink"
                label={t('customization_product_cards_load_more_button')}
                placeholder={t('customization_product_cards_load_more_button_placeholder')}
                paddingBottom="0"
                afterChange={({ target }) => {
                    updateProgress({ name: 'loadMoreLink', isFilled: !!target.value });
                }}
            />
            <FormikFormInput
                name="productCards.CTAText"
                label={t('customization_product_cards_cta_text')}
                placeholder={t('customization_product_cards_cta_text_placeholder')}
                paddingBottom="0"
                afterChange={({ target }) => {
                    updateProgress({ name: 'CTAText', isFilled: !!target.value });
                }}
            />
        </CmsAcordionSection>
    );
};
