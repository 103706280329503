import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { getImage, ImageName } from 'components/styles/images';
import { useStateToggle } from 'units/common/hooks/useStateToggle';

import { UserUrls } from 'units/user/urls';
import { LPPricingModal } from '../../grid';

import { LPButton, LPTextLabel, LPTypography } from '../../ui';

import priceBG from '../../../images/price-bg.svg';

import { PricingId, pricingList } from './pricing.mocks';

import { LPPricingSC } from './pricing.styles';
import { usePageScroll } from 'helpers/hooks';

export const LPPricing = () => {
    const { t } = useTranslation();
    const { scrollTop } = usePageScroll();
    const history = useHistory();
    const [isHelpModalOpen, { turnIn: openModal, turnOff: closeModal }] = useStateToggle(false);

    const navigateToRegistrationPage = useCallback(() => {
        history.push(UserUrls.registration);
    }, [history]);

    const handlePricingButtonClick = useCallback(
        (pricingId: PricingId) => {
            if (pricingId === PricingId.STANDARD) {
                scrollTop(true);
                return;
            }
            openModal();
        },
        [navigateToRegistrationPage],
    );

    return (
        <LPPricingSC.Root id="price">
            <LPPricingModal isOpen={isHelpModalOpen} closeModalHandler={closeModal} />
            <LPPricingSC.PriceList>
                <LPPricingSC.Background src={priceBG} alt="" />
                {pricingList.map(item => (
                    <LPPricingSC.PriceItem key={item.id}>
                        <LPPricingSC.PriceBody>
                            <LPTextLabel styles={LPPricingSC.LabelCss}>{t(item.label)}</LPTextLabel>
                            <LPPricingSC.PriceHead>
                                {item.percent && (
                                    <LPPricingSC.Percent>{item.percent}</LPPricingSC.Percent>
                                )}
                                <LPTypography.H3>{t(item.title)}</LPTypography.H3>
                                <LPTypography.Text styles={LPPricingSC.TextCss}>
                                    {t(item.text)}
                                </LPTypography.Text>
                            </LPPricingSC.PriceHead>
                            <LPPricingSC.DescriptionList>
                                {item.descriptions.map((text, index) => (
                                    <LPPricingSC.DescriptionItem
                                        key={`${item.id}-description-${index}`}
                                    >
                                        {getImage(ImageName.check_red)}
                                        {t(text)}
                                    </LPPricingSC.DescriptionItem>
                                ))}
                            </LPPricingSC.DescriptionList>
                            <LPButton.Primary
                                styles={LPPricingSC.ButtonCss}
                                onClick={handlePricingButtonClick.bind(null, item.id)}
                            >
                                {t(item.submit)}
                            </LPButton.Primary>
                        </LPPricingSC.PriceBody>
                    </LPPricingSC.PriceItem>
                ))}
            </LPPricingSC.PriceList>
        </LPPricingSC.Root>
    );
};
