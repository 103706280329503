import styled from 'styled-components';

import { Font } from 'components/types/enums';
import { Color } from 'components/styles/constants.styles';

const CTAContainer = styled.div<{ marginTop?: string }>`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  ${({ marginTop }) => marginTop ? `margin-top: ${marginTop};` : ''};

  button {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const SectionTitle = styled.div<{ marginBottom?: string }>`
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom};` }
  
  font-family: ${Font.regular};
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: normal;
  text-align: left;
  color: ${Color.bluey_grey};

  span {
    font-weight: ${Font.bold};
    color: #fda347;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const CMSRootSC = {
  CTAContainer,
  SectionTitle,
};
