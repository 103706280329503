import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Router } from '../../router';

import { LPMobileHamburger } from '../../ui';

import { LPFooter } from '../footer/footer.component';
import { LPHeader } from '../header/header.component';
import { LPMobileMenu } from '../mobile-menu/mobile-menu.component';

import { useStateToggle } from 'units//common/hooks/useStateToggle';
import { useDeviceDetector } from 'units//landing-page/hooks';

import { LPLayout } from './layout.styles';

export const Layout = () => {
  const { pathname } = useLocation();
  const [showMobileMenu, { toggle, turnOff }] = useStateToggle(false);
  const [isScroll, setIsScroll] = useState(false);

  const { device } = useDeviceDetector();

  const onScroll = () => {
    setIsScroll(window.pageYOffset > 10);
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname])

  return (
    <>
      {device === 'mobile' && (
        <>
          <LPMobileMenu isOpen={showMobileMenu} closeMenu={turnOff} />
          <LPMobileHamburger isScroll={isScroll} isOpen={showMobileMenu} onClick={toggle} />
        </>
      )}
      <LPLayout.Wrapper>
        <LPHeader isScroll={isScroll} />
        <Router />
        <LPFooter />
      </LPLayout.Wrapper>
    </>
  );
};
