import React from 'react';
import clsx from 'clsx';
import { Icon } from 'shared/components/ui';
import { Link } from 'react-router-dom';

import styles from './StatusButton.module.scss';
import { AppUrls } from 'app/urls';

type StatusButtonProps = {
    type: 'unfulfilled' | 'unpaid';
    num: number;
};

export const StatusButton = ({ type, num }: StatusButtonProps) => {
    return (
        <Link
            className={clsx(styles.button, {
                [styles['is-red']]: num > 0,
            })}
            to={AppUrls.orders.index}
        >
            <Icon className={styles.icon} name={type === 'unfulfilled' ? 'cart' : 'cheque'} />
            <div className={styles.label}>
                <span className={clsx(styles.number)}>{num}</span>
                {` ${type === 'unfulfilled' ? 'orders to be fulfilled' : 'unpaid orders'}`}
            </div>
            <div className={styles.arrow}>
                <Icon name="arrow-right" />
            </div>
        </Link>
    );
};
