import React from 'react';
import { useLocation } from 'react-router-dom';
import { useFormikContext, useField } from 'formik';

import { AuctionValues, Condition } from '../types';
import { CreateAuctionSC } from '../create-auction.styles';
import { AuctionConditionSC } from './auction-condition.styles';
import { SecondaryButton } from 'units/common/components/buttons/secondary/secondary.component';
import { useFormikFieldErrors } from 'units/common/hooks/useFieldErrors';
import { Auction } from '../constants';
import { ConditionItem } from './condition-item/condition-item.component';
import { getAuctionStatuses } from '../helpers';
import { useWorkingMode } from '../hooks';

export const AuctionCondition = () => {
  const [field, , helpers] = useField<Array<Condition>>('conditions');
  const { values } = useFormikContext<AuctionValues>();
  const fieldError = useFormikFieldErrors<AuctionValues>();
  const isAuction = values.auctionType === Auction.auction;
  const { isActive, isScheduled, isDraft } = getAuctionStatuses(values);
  const { isViewMode, isEditMode, isCreateMode } = useWorkingMode(useLocation());

  const addConditionHandler = () => {
    if (field.value.length < 8) {
      helpers.setValue([...field.value, { value: null, place: null}]);
    }
  };

  return isAuction ? (
    <CreateAuctionSC.Wrapper isViewMode={isViewMode}>
      <CreateAuctionSC.Devider />
      <AuctionConditionSC.SectionTitle>
        <CreateAuctionSC.SectionTitle>
          Auction conditions (Optional)
        </CreateAuctionSC.SectionTitle>
        {field.value.length < 8 && ((isEditMode && (isScheduled || isDraft)) || isCreateMode) && (
          <SecondaryButton
            isWide
            type='button'
            onClick={addConditionHandler}
          >
            + Add new condition
          </SecondaryButton>
        )}
      </AuctionConditionSC.SectionTitle>

      {(isCreateMode || isViewMode || (isEditMode && !isActive)) && (
        <AuctionConditionSC.Subtitle>
          Adding auction conditions allows you to choose the number of products that can be won once a certain price is reached.
          The default option is 1 product.
        </AuctionConditionSC.Subtitle>
      )}

      <AuctionConditionSC.ConditionContainer>
        {field.value.map((item, index) => (
          <ConditionItem
            key={`condition-${index}`}
            label='When offer is &#8805;'
            name={`conditions.${index}`}
            disabled={isActive}
            error={(field.value?.[index].value || field.value?.[index].place) ? fieldError.conditions?.[index] : undefined}
          />
        ))}
      </AuctionConditionSC.ConditionContainer>
    </CreateAuctionSC.Wrapper>
  ) : null;
};
