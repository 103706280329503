import { ApiClient, apiPath } from 'config/api/api';

export type ContactRequestValues = {
    email: string;
    message: string;
    first_name: string;
    last_name: string;
    company: string;
};

export type EmailContactRequestValues = {
    email: string;
    message: string;
};

export const sendContactRequest = async (
    data: ContactRequestValues | EmailContactRequestValues,
) => {
    const api = ApiClient.getInstance();
    return api.post(`${apiPath}/customers/contact/`, data);
};
