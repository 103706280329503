import React, { FC } from 'react';

import { getImage, ImageName } from 'components/styles/images';
import { LayoutSC } from 'units/common/components/layout/layout-components.styles';
import { Table } from 'units/common/components/table/table.component';
import { TableData } from '../../types';
import { AuctionsFilters } from '../auctions-filters/auctions-filters.component';
import styles from './auctions-list.module.scss';

interface Props {
    columns: Array<{ Header: string; accessor: string }>;
    data: Array<TableData>;
    numOfPages?: number;
    onPageChange?: (page: number) => void;
    onFilterChange?: (filters: string[]) => void;
    onSorting?: (sorting: any) => void;
}

export const AuctionsList: FC<Props> = ({
    columns,
    data,
    numOfPages,
    onPageChange,
    onFilterChange,
    onSorting,
}) => {
    const isDataExist = !!data?.length;

    return (
        <>
            <div className={styles.filtersWrapper}>
                <AuctionsFilters onChange={onFilterChange} />
            </div>
            {!isDataExist && (
                <LayoutSC.NoItemsContainer>
                    <div>{getImage(ImageName.no_auctions)}</div>
                    <p>There are no auctions created yet. You can change it!</p>
                </LayoutSC.NoItemsContainer>
            )}
            {isDataExist && (
                <Table
                    pageSize={10}
                    columns={columns}
                    data={data}
                    numOfPages={numOfPages}
                    onPageChange={onPageChange}
                    onSort={onSorting}
                />
            )}
        </>
    );
};
