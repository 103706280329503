import styled, { css } from 'styled-components';

import { fonts } from 'components/styles/helpers.styles';
import { MediaQueries } from 'units/landing-page/config';

const PhoneBtnCss = css`
    padding: 20px 65px;
    margin-top: 39px;

    @media (max-width: ${MediaQueries.tablet}) {
        padding: 9px 39px;
        margin-top: 31px;
    }

    @media (max-width: ${MediaQueries.mobile}) {
        padding: 6px 34px;
        margin-top: 24px;
        img {
            max-width: 24px;
            height: auto;
            margin-right: 8px;
        }
    }
`;

const SubmitBtnCss = css`
    margin-top: 33px;
    span {
        padding: 28px 132px;
    }

    @media (max-width: ${MediaQueries.tablet}) {
        margin-top: 22px;
        span {
            padding: 16px 99px;
        }
    }

    @media (max-width: ${MediaQueries.mobile}) {
        span {
            padding: 9px 82px;
        }
    }
`;

const TextCss = css`
    font-size: 24px;
    line-height: 32px;
    max-width: 610px;
    margin-top: 15px;
    font-family: ${fonts.bold};

    @media (max-width: ${MediaQueries.tablet}) {
        font-size: 20px;
        line-height: 28px;
    }

    @media (max-width: ${MediaQueries.mobile}) {
        font-size: 16px;
        line-height: 24px;
    }
`;

const Root = styled.section`
    padding-top: 140px;
    width: 100%;
    max-width: 850px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 160px;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: ${MediaQueries.tablet}) {
        margin-bottom: 80px;
        padding-top: 120px;
    }

    @media (max-width: ${MediaQueries.mobile}) {
        margin-bottom: 56px;
        padding-top: 95px;
    }
`;

const SubTitle = styled.div`
    margin-top: 20px;
    max-width: 650px;
    font-size: 24px;
    text-align: center;
    font-family: ${fonts.bold};
    margin-bottom: 20px;
`;

const HandIcon = styled.img`
    margin-right: 18px;
`;

const InputLine = styled.div`
    display: flex;
    width: 100%;

    @media (max-width: ${MediaQueries.mobile}) {
        display: flex;
        flex-direction: column;
    }
`;

const InputWrapper = styled.div`
    padding: 10px;
    width: 50%;

    @media (max-width: ${MediaQueries.mobile}) {
        padding: 0;
        width: 100%;
        margin-bottom: 18px;
    }
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

const TextareaWrapper = styled.div`
    padding: 10px;
    width: 100%;

    @media (max-width: ${MediaQueries.mobile}) {
        padding: 0;
    }
`;

const Separate = styled.div`
    position: relative;
    background-color: #E5E5E5;
    width: 100%;
    height: 1px;
    margin: 53px 0 42px;

    @media (max-width: ${MediaQueries.tablet}) {
        margin: 42px 0 34px;
    }

    @media (max-width: ${MediaQueries.mobile}) {
        margin: 32px 20px;
        img {
            padding: 0 12px;
            margin-top: -10px;
            margin-left: -20px;
            box-sizing: content-box;
            max-width: 16px;
        }
    }
`;

const PencilIcon = styled.img`
    background-color: #fff;
    position: absolute;
    padding: 0 18px;
    margin-top: -12px;
    margin-left: -30px;
    top: 50%;
    left: 50%;
`;

export const LPContactSectionSC = {
    Root,
    SubTitle,
    HandIcon,
    InputLine,
    InputWrapper,
    Form,
    TextareaWrapper,
    Separate,
    PencilIcon,

    PhoneBtnCss,
    SubmitBtnCss,
    TextCss
};
