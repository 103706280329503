import { AsyncAppThunk } from 'redux/root-types';
import { cmsRootServices } from './services';
import * as actionCreators from './action-creators';
import { AppStrings } from 'config/strings';
import { CMSThemeId, CMSCustomizationPage } from './types';
import { history } from 'helpers/history';
import { CMSUrls } from '../urls';
import {
    parseCMSThemeSettingsListResponse,
    prepareCMSThemeSettingsToSaveWithImages,
    parseCMSFetchCustomizationConfigResponse,
    prepareCMSCustomizationPageToSaveWithImages,
    getConfigPageName,
    getDefaultThemeStyles,
} from './helpers';
import { CMSFormikThemeSettings } from '../types';
import { CMSCustomizationConfig, CMSCustomizationOverallSettings } from './types/customization';
import { CompanyLanguages } from 'units/app/redux/types';
import { CMSShopModalsCustomizationSettings } from './types/customization/shop-modals';
import { NotificationStatusType } from 'shared/components/ui';
import { CMSMissedAuctionsCustomizationSettings } from './types/customization/missed-auctions';
import { CMSProductDetailPageCustomizationSettings } from './types/customization/product-detail-page';
import {
    CMSHomePageCustomizationSettings,
    CMSHomePageFormikCustomizationSettings,
} from './types/customization/homepage';
import { CMSDefaultHomepageSettings } from './constants';

//const fontWeightRequirements = ["200", "regular", "700", "800"];

// Themes tab

export const fetchCMSAvailableThemesThunk = (
    callback: (type: NotificationStatusType, text: string) => void,
): AsyncAppThunk => async dispatch => {
    try {
        const themes = await cmsRootServices.fetchThemeList();

        dispatch(actionCreators.cmsSetAvailableThemes(themes));
    } catch (error) {
        callback('error', AppStrings.errorNotification);
    }
};

export const saveCMSSelectedThemeThunk = (
    callback: (type: NotificationStatusType, text: string) => void,
): AsyncAppThunk => async (dispatch, getState) => {
    try {
        const {
            cms: {
                tempPayload: { selectedTheme },
            },
        } = getState();

        await cmsRootServices.saveActiveTheme(selectedTheme);

        dispatch(actionCreators.cmsSetActiveTheme(selectedTheme));
        setTimeout(() => history.push(CMSUrls.themeSettings), 500);
        callback('success', 'Template successfully saved');
    } catch (error) {
        callback('error', AppStrings.errorNotification);
    }
};

// Theme settings tab

export const fetchCMSThemeSettingsListThunk = (
    callback: (type: NotificationStatusType, text: string) => void,
): AsyncAppThunk => async (dispatch, getState) => {
    try {
        const { user } = getState().user;

        const themeSettingsList = await cmsRootServices.fetchThemeSettingsList();
        const { config, activeTheme } = parseCMSThemeSettingsListResponse(themeSettingsList, {
            mergeWithDefault: true,
            slug: user?.user_type?.slug || '',
        });

        dispatch(actionCreators.cmsSetActiveTheme(activeTheme));
        dispatch(actionCreators.cmsPopulateOverallThemeSettingsConfig(config, activeTheme));
    } catch (error) {
        callback('error', AppStrings.errorNotification);
    }
};

export const resetCMSThemeSettingsListThunk = (
    callback: (type: NotificationStatusType, text: string) => void,
): AsyncAppThunk => async (dispatch, getState) => {
    try {
        const {
            tempPayload: { selectedTheme },
            config: { themeSettings },
        } = getState().cms;
        const themeIdToSave = selectedTheme;
        const defaultSettings = getDefaultThemeStyles(selectedTheme);

        await cmsRootServices.publishThemeSettings(themeIdToSave, defaultSettings);

        themeSettings[themeIdToSave].settings = defaultSettings;

        dispatch(
            actionCreators.cmsPopulateOverallThemeSettingsConfig(themeSettings, themeIdToSave),
        );
        callback('success', 'Theme styles successfully updated!');
        dispatch(actionCreators.cmsDirtyState(false));
    } catch (error) {
        callback('error', AppStrings.errorNotification);
    }
};

export const saveCMSThemeSettingsThunk = (
    themeSettings: CMSFormikThemeSettings,
    callback: (type: NotificationStatusType, text: string) => void,
    themeId?: CMSThemeId,
): AsyncAppThunk => async (dispatch, getState) => {
    try {
        const {
            tempPayload: { selectedTheme, imagesToDelete },
        } = getState().cms;
        const themeIdToSave = themeId || selectedTheme;

        const themeSettingsToSave = await prepareCMSThemeSettingsToSaveWithImages(
            themeSettings,
            imagesToDelete,
        );

        const { settings_published } = await cmsRootServices.saveThemeSettings(
            themeIdToSave,
            themeSettingsToSave,
        );

        dispatch(
            actionCreators.cmsSetThemeSettingsConfig(
                {
                    settings: themeSettingsToSave,
                    isPublished: settings_published,
                },
                themeIdToSave,
            ),
        );

        dispatch(actionCreators.cmsDirtyState(false));

        callback('success', 'Template successfully saved');
    } catch (error) {
        callback('error', AppStrings.errorNotification);
    }
};

export const saveCMSThemeSettingThunk = (
    themeSettings: CMSFormikThemeSettings,
    callback: (type: NotificationStatusType, text: string) => void,
    themeId?: CMSThemeId,
): AsyncAppThunk => async (dispatch, getState) => {
    try {
        const {
            tempPayload: { selectedTheme, imagesToDelete },
        } = getState().cms;
        const themeIdToSave = themeId || selectedTheme;

        const themeSettingsToSave = await prepareCMSThemeSettingsToSaveWithImages(
            themeSettings,
            imagesToDelete,
        );

        dispatch(
            actionCreators.cmsSetThemeSettingsConfig(
                {
                    settings: themeSettingsToSave,
                },
                themeIdToSave,
            ),
        );

        dispatch(actionCreators.cmsDirtyState(true));
    } catch (error) {
        callback('error', AppStrings.errorNotification);
    }
};

export const saveAndPublishCMSThemeSettingsThunk = (
    themeSettings: CMSFormikThemeSettings,
    callback: (type: NotificationStatusType, text: string) => void,
    themeId?: CMSThemeId,
): AsyncAppThunk => async (dispatch, getState) => {
    try {
        const {
            tempPayload: { selectedTheme, imagesToDelete },
        } = getState().cms;
        const themeIdToSave = themeId || selectedTheme;

        const themeSettingsToSave = await prepareCMSThemeSettingsToSaveWithImages(
            themeSettings,
            imagesToDelete,
        );

        await cmsRootServices.publishThemeSettings(themeIdToSave, themeSettingsToSave);

        dispatch(
            actionCreators.cmsSetThemeSettingsConfig(
                {
                    settings: themeSettingsToSave,
                    isPublished: true,
                },
                themeIdToSave,
            ),
        );

        dispatch(actionCreators.cmsDirtyState(false));

        callback('success', 'Template successfully saved and published');
    } catch (error) {
        callback('error', AppStrings.errorNotification);
    }
};

// Customization tab

export const fetchCMSCustomizationConfigThunk = (
    callback: (type: NotificationStatusType, text: string) => void,
): AsyncAppThunk => async (dispatch, getState) => {
    try {
        const { languages } = getState().app.companySettings;
        const userType = getState().user.user?.user_type;

        const configResponse = await cmsRootServices.fetchCustomizationConfig();

        const config = parseCMSFetchCustomizationConfigResponse(configResponse, {
            mergeWithDefault: true,
            cmsLanguages: languages,
        });

        let newConfig: null | CMSCustomizationConfig = null;

        if (userType?.slug === 'immo') {
            newConfig = {
                ...config,
                ...{
                    homepage: {
                        settings: config.homepage.settings,
                        carouselAuctions: [],
                        isPublished: config.homepage.isPublished,
                    },
                },
            };
        }

        dispatch(actionCreators.cmsPopulateCustomizationConfig(newConfig || config));
    } catch (error) {
        callback('error', AppStrings.errorNotification);
    }
};

export const saveCMSCustomizationSettingsConfigThunk = <S>(
    page: CMSCustomizationPage,
    settings: CMSCustomizationOverallSettings<S>,
    callback: (type: NotificationStatusType, text: string) => void,
): AsyncAppThunk => async (dispatch, getState) => {
    try {
        const {
            tempPayload: { imagesToDelete },
            config: { customization },
        } = getState().cms;

        const userType = getState().user.user?.user_type;

        const pageConfigName = getConfigPageName(page);
        const pageSettingsToSave = await prepareCMSCustomizationPageToSaveWithImages(
            page,
            settings,
            imagesToDelete,
        );

        // const isPublished = pageConfigName ? customization[pageConfigName].isPublished : false;
        const pageConfig: any = {
            settings: pageSettingsToSave,
            isPublished: false,
        };

        if (pageConfigName === 'homepage' && userType?.slug === 'immo') {
            pageConfig.carouselAuctions = customization.homepage.carouselAuctions;
        }

        await cmsRootServices.saveCustomizationConfig({
            [pageConfigName]: pageConfig,
        });

        dispatch(actionCreators.cmsSetCustomizationPageConfig(page, pageConfig));
        dispatch(actionCreators.cmsDirtyState(false));

        callback('success', AppStrings.dataSuccessfullyUpdated);
    } catch (error) {
        console.log(error);
        callback('error', AppStrings.errorNotification);
    }
};

export const updateCMSCustomizationSettingsConfigThunk = (
    configs: CMSShopModalsCustomizationSettings,
    selectedLanguage: CompanyLanguages,
): AsyncAppThunk => async (dispatch, getState) => {
    dispatch(actionCreators.cmsUpdateShopModalCustomizationConfig(configs, selectedLanguage));
    dispatch(actionCreators.cmsSetConfigSaveStatus(false));
};

export const updateCMSCustomizationMissedAuctionsThunk = (
    data: CMSMissedAuctionsCustomizationSettings,
    selectedLanguage: CompanyLanguages,
): AsyncAppThunk => async (dispatch, getState) => {
    const values = getState().cms.config.customization.missedAuctions;
    values.settings[selectedLanguage] = data;

    Object.values(values.settings).forEach(value => {
        if (value)
            value.general.newsletterOptIn = !!values.settings[selectedLanguage]?.general
                .newsletterOptIn;
    });

    dispatch(actionCreators.cmsUpdateMissedAuctionsCustomizationConfig(values));
    dispatch(actionCreators.cmsDirtyState(true));
};

export const updateCMSHomeAuctionsThunk = (
    data: CMSHomePageFormikCustomizationSettings,
    selectedLanguage: CompanyLanguages,
): AsyncAppThunk => async (dispatch, getState) => {
    const values = getState().cms.config.customization.homepage;
    values.settings[selectedLanguage] = data as CMSHomePageCustomizationSettings;

    Object.values(values.settings).forEach(value => {
        if (value) {
            value.hero.conversationSticker =
                values.settings[selectedLanguage]?.hero.conversationSticker ||
                CMSDefaultHomepageSettings.hero.conversationSticker;

            value.textBlock.isActive = !!values.settings[selectedLanguage]?.textBlock.isActive;
            value.textBlock.textLink.isActive = !!values.settings[selectedLanguage]?.textBlock
                .textLink.isActive;
            value.textBlock.textLink.target =
                values.settings[selectedLanguage]?.textBlock.textLink.target || '';

            value.newsletterOption.isActive = !!values.settings[selectedLanguage]?.newsletterOption
                .isActive;

            value.footer.socialLinks.facebook.isActive = !!values.settings[selectedLanguage]?.footer
                .socialLinks.facebook.isActive;
            value.footer.socialLinks.instagram.isActive = !!values.settings[selectedLanguage]
                ?.footer.socialLinks.instagram.isActive;
            value.footer.socialLinks.linkedin.isActive = !!values.settings[selectedLanguage]?.footer
                .socialLinks.linkedin.isActive;
            value.footer.socialLinks.snapchat.isActive = !!values.settings[selectedLanguage]?.footer
                .socialLinks.snapchat.isActive;
            value.footer.socialLinks.tiktok.isActive = !!values.settings[selectedLanguage]?.footer
                .socialLinks.tiktok.isActive;
            value.footer.socialLinks.twitter.isActive = !!values.settings[selectedLanguage]?.footer
                .socialLinks.twitter.isActive;
        }
    });

    dispatch(actionCreators.cmsUpdateHomeCustomizationConfig(values));
    dispatch(actionCreators.cmsDirtyState(true));
};

export const updateCMSCustomizationProductDetailThunk = (
    data: CMSProductDetailPageCustomizationSettings,
    selectedLanguage: CompanyLanguages,
): AsyncAppThunk => async (dispatch, getState) => {
    const values = getState().cms.config.customization.productDetailPage;
    values.settings[selectedLanguage] = data;

    Object.values(values.settings).forEach(value => {
        if (value) {
            value.hero.graph1.color =
                values.settings[selectedLanguage]?.hero.graph1.color || '#fff';
            value.hero.graph2.color =
                values.settings[selectedLanguage]?.hero.graph2.color || '#fff';
        }
    });

    dispatch(actionCreators.cmsUpdateProductDetailtCustomizationConfig(values));
    dispatch(actionCreators.cmsDirtyState(true));
};

export const saveAndPublishCMSCustomizationSettingsConfigThunk = <S>(
    page: CMSCustomizationPage,
    settings: CMSCustomizationOverallSettings<S>,
    callback: (type: NotificationStatusType, text: string) => void,
): AsyncAppThunk => async (dispatch, getState) => {
    try {
        const {
            tempPayload: { imagesToDelete },
            config: { customization },
        } = getState().cms;

        const userType = getState().user.user?.user_type;

        const pageConfigName = getConfigPageName(page);
        const pageSettingsToSave = await prepareCMSCustomizationPageToSaveWithImages(
            page,
            settings,
            imagesToDelete,
        );

        const pageConfig: any = {
            settings: Object.entries(pageSettingsToSave).reduce(
                (res, [lng, settings]) => ({
                    ...res,
                    [lng as CompanyLanguages]: {
                        ...settings,
                        meta: {
                            ...settings?.meta,
                            hasBeenPublished: true,
                        },
                    },
                }),
                {},
            ),
            isPublished: true,
        };

        if (pageConfigName === 'homepage' && userType?.slug === 'immo') {
            pageConfig.carouselAuctions = customization.homepage.carouselAuctions;
        }

        await cmsRootServices.saveAndPublishCustomizationConfig({
            [pageConfigName]: pageConfig,
        });

        dispatch(actionCreators.cmsSetCustomizationPageConfig(page, pageConfig));
        dispatch(actionCreators.cmsDirtyState(false));
        callback('success', AppStrings.dataSuccessfullyUpdated);
    } catch (error) {
        callback('error', AppStrings.errorNotification);
    }
};

// Additional

export const fetchCMSGoogleFontsThunk = (
    callback: (type: NotificationStatusType, text: string) => void,
): AsyncAppThunk => async dispatch => {
    try {
        const data = await cmsRootServices.fetchCMSGoogleFonts();
        //const filtered = data.items.filter(item => fontWeightRequirements.every(v => item.variants.includes(v)))
        dispatch(
            actionCreators.cmsSetGoogleFonts(
                data.items.map(item => ({ value: item.family, label: item.family })),
            ),
        );
    } catch (error) {
        callback('error', AppStrings.errorNotification);
    }
};
