import { ApiClient, apiPath } from 'config/api/api';

const getProducts = async () => {
    const api = ApiClient.getInstance();

    return api.get(`${apiPath}/products/analytics/list/`);
};

const getProductData = async (productId: string) => {
    const api = ApiClient.getInstance();

    return api.get(`${apiPath}/products/analytics/${productId}`);
};

const getAuctions = async (productID: string) => {
    const api = ApiClient.getInstance();

    return api.get(`${apiPath}/auctions/list/product/${productID}/`);
};

const getAuctionData = async (auctionID: string) => {
    const api = ApiClient.getInstance();

    return api.get(`${apiPath}/auctions/analytics/${auctionID}/`);
};

const getChartAge = async (auctionID: string) => {
    const api = ApiClient.getInstance();

    return api.get(`${apiPath}/auctions/analytics/charts/${auctionID}/age/`);
};

const getChartDaytime = async (auctionID: string) => {
    const api = ApiClient.getInstance();

    return api.get(`${apiPath}/auctions/analytics/charts/${auctionID}/daytime/`);
};

const getChartWeekday = async (auctionID: string) => {
    const api = ApiClient.getInstance();

    return api.get(`${apiPath}/auctions/analytics/charts/${auctionID}/weekday/`);
};

const getChartRegions = async (auctionID: string) => {
    const api = ApiClient.getInstance();

    return api.get(`${apiPath}/auctions/analytics/charts/${auctionID}/region/`);
};

const getProductChartAge = async (productId: string) => {
    const api = ApiClient.getInstance();

    return api.get(`${apiPath}/products/analytics/chart/${productId}/age/`);
};

const getProductChartDaytime = async (productId: string) => {
    const api = ApiClient.getInstance();

    return api.get(`${apiPath}/products/analytics/chart/${productId}/daytime/`);
};

const getProductChartWeekday = async (productId: string) => {
    const api = ApiClient.getInstance();

    return api.get(`${apiPath}/products/analytics/chart/${productId}/weekday/`);
};

const getProductChartRegions = async (productId: string) => {
    const api = ApiClient.getInstance();

    return api.get(`${apiPath}/products/analytics/chart/${productId}/region/`);
};

export const AnalyticsService = {
    getProducts,
    getProductData,
    getAuctions,
    getAuctionData,
    getChartAge,
    getChartDaytime,
    getChartWeekday,
    getChartRegions,
    getProductChartAge,
    getProductChartDaytime,
    getProductChartWeekday,
    getProductChartRegions,
};
