import { InferValueTypes } from 'redux/types';
import * as actionCreators from './action-creators';

export enum CompanyLanguages {
  de = 'de',
  en = 'en',
  fr = 'fr',
  it = 'it',
}

export type CompanySettings = {
  languages: Array<CompanyLanguages>;
}

export enum ThirdPartyIntegrationSource {
  mailchimp = 'MAIL_CHIMP',
  google = 'GOOGLE_ANALYTICS'
}

export type ThirdPartyIntegrationSourceData = {
  source:ThirdPartyIntegrationSource;
  key:string;
  isActive:boolean;
}

// ------ reducer state

export type AppState = {
  isFetching: Array<string>;
  isAppInitializedWithAuthorizedUser: boolean;
  companySettings: CompanySettings;
  integrations: Array<ThirdPartyIntegrationSourceData>;
}

// ------ api calls

export type CompanySettingsResponse = {
  required_languages: Array<CompanyLanguages>;
}

export type AppActions = ReturnType<InferValueTypes<typeof actionCreators>>;

export type ThirdPartyIntegration = {
  source:  ThirdPartyIntegrationSource;
  active:boolean;
  config: { api_key?: string, tracking_id?: string };
}
