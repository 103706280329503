import { overallUpdatesCreator } from '.';

export const ProductDetailPageSharedFields = [
  // Hero
  'hero.conversationSticker.isActive', 'hero.graph1.color', 'hero.graph2.color',
  // Related products
  'relatedProducts.products',
  // Newsletter
  'newsletterOptIn.isActive'
];

export const createOverallUpdatesForCMSProductDetailPage = overallUpdatesCreator(ProductDetailPageSharedFields);
