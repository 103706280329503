import { Reducer } from 'redux';

import { RootState } from 'redux/root-types';
import { StripeAccountData } from 'shared/types';

import { SettingActions } from 'store/actions';

import { SET_SETTINGS_STRIPE_ACCOUNT_DATA, SET_SETTINGS_STRIPE_ACCOUNT_LOADING, REMOVE_SETTINGS_STRIPE_ACCOUNT_DATA } from 'store/types';

export type State = {
    stripeAccount?: null | StripeAccountData;
    isLoadingStripeAccount: boolean;
};

const initialState:State = {
    isLoadingStripeAccount: true
};

export const settingsReducer:Reducer<State, SettingActions> = (state = initialState, action) => {
    switch (action.type) {
        case SET_SETTINGS_STRIPE_ACCOUNT_DATA:
            return { ...state, stripeAccount: action.data };
        case SET_SETTINGS_STRIPE_ACCOUNT_LOADING:
            return { ...state, isLoadingStripeAccount: action.state };
        case REMOVE_SETTINGS_STRIPE_ACCOUNT_DATA:
            return { isLoadingStripeAccount: true };
        default:
            return state;
    }
};

export const stripeAccountSelector = (state:RootState) => state.settings.stripeAccount;
export const stripeAccountLoadingSelector = (state:RootState) => state.settings.isLoadingStripeAccount;