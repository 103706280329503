import React, { useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { Formik, FormikHelpers } from 'formik';

import { useDispatch, useSelector } from 'react-redux';

import { LayoutSC } from 'units/common/components/layout/layout-components.styles';
import { LanguageSC } from './language.styles';
import { BackButton } from 'units/common/components/back-button/back-button.component';

import { ChooseLanguage } from './choose-language.component';
import { SettingsUrls } from '../../urls';
import { RootState } from 'redux/root-types';
import { CompanySettings } from 'units/app/redux/types';
import { languageSettingsValidationSchema } from './validation';
import { updateComanySettingsThunk } from 'units/app/redux/thunks';
import { SuccessModal } from 'units/common/components/modal/success-modal/success-modal.component';
import { useStateToggle } from 'units/common/hooks/useStateToggle';

export const LanguagePage = () => {
  const [isSuccessModalOpen, handleSuccessModal] = useStateToggle(false);
  const companySettings = useSelector<RootState, CompanySettings>(({ app }) => app.companySettings);
  const dispatch = useDispatch();

  const handleSubmit = useCallback(async (values: CompanySettings, helpers: FormikHelpers<CompanySettings>) => {
    await dispatch(updateComanySettingsThunk(values.languages, handleSuccessModal.turnIn));
    helpers.setStatus({});
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>Storefront Language</title>
      </Helmet>
      <BackButton destinationText={'Settings'} to={SettingsUrls.index} />
      <LayoutSC.PageTitle smallPadding>Storefront Language Settings</LayoutSC.PageTitle>

      <LanguageSC.PageInfo>
          Choose the languages you want to be supported in your storefront. Please be aware that your additional storefront language will
          only be published once the content is added to the CMS and the products.
      </LanguageSC.PageInfo>
      <Formik
        initialValues={companySettings}
        onSubmit={handleSubmit}
        validationSchema={languageSettingsValidationSchema}
        enableReinitialize
      >
        <ChooseLanguage savedValues={companySettings.languages} />
      </Formik>
      <SuccessModal
        isOpen={isSuccessModalOpen}
        title="Languages successfully updated"
        buttonTitle="I understand"
        onCloseModal={handleSuccessModal.turnOff}
        confirmHandler={handleSuccessModal.turnOff}
        text={'We’ve updated your language settings. Please update your products and storefront content. ' +
          'Your new language will be available for your clients with your next publish in the CMS.'}
      />
    </>
  );
  };
