import React, { FC } from 'react';
import { Form} from 'formik';

import { FormikUserInput } from 'units/common/components/formik/input/user-input.component';
import { useFormikFieldErrors } from 'units/common/hooks/useFieldErrors';
import { LoginWelcome } from '../../../redux/types';
import { WelcomeSC } from './welcome.styles';
import { Appi18nStrings } from 'config/strings';
import { SharedSC } from '../welcome-shared.styles';
import { getImage, ImageName } from 'components/styles/images';
import { useIsFormikSubmitEnabled } from 'units/common/hooks/useIsSubmitEnabled';
import { ProgressSteps } from 'units/common/components/progress-steps/progress-steps.component';

export const WelcomeForm: FC = () => {
  const fieldErrors = useFormikFieldErrors<LoginWelcome>();
  const isSubmitEnabled = useIsFormikSubmitEnabled();

  return (
    <SharedSC.Wrapper>
      {getImage(ImageName.success)}
      <SharedSC.PageTitle>Welcome to Ecatch!</SharedSC.PageTitle>
      <SharedSC.PageSubtitle margin="0 0 40px 0">
        You are almost ready to go. Let’s complete two simple steps and you are all set up.
      </SharedSC.PageSubtitle>

      <WelcomeSC.WelcomeForm as={Form}>
        <WelcomeSC.InnerFormWrapper>
          <FormikUserInput
            name="firstName"
            label="First name"
            placeholder="Enter your first name"
            type="text"
            error={fieldErrors.firstName}
          />
          <FormikUserInput
            name="lastName"
            label="Last name"
            placeholder="Enter your last name"
            type="text"
            error={fieldErrors.lastName}
          />
        </WelcomeSC.InnerFormWrapper>

        <SharedSC.WelcomePrimaryButton type="submit" disabled={!isSubmitEnabled}>
          {Appi18nStrings.next}
        </SharedSC.WelcomePrimaryButton>
      </WelcomeSC.WelcomeForm>

      <SharedSC.PageSubtitle margin="96px 0 32px 0">
        You are already logged in. Only 3 simple steps left to start your work.
      </SharedSC.PageSubtitle>
      <ProgressSteps step={1} />
    </SharedSC.Wrapper>
  );
};
