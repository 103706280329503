import React, { FC } from 'react';

import { RadioSC } from './radio.styles';

interface Props {
  label?: string;
  children: React.ReactNode;
  margin?: string;
}

export const RadioButtonGroup: FC<Props> = ({ label, children, margin }) => (
  <RadioSC.GroupContainer margin={margin}>
    {label && (<legend>{label}</legend>)}
    <div>{children}</div>
  </RadioSC.GroupContainer>
);
