import styled from 'styled-components';

import { TextStyle2, TextStyle8 } from 'components/styles/text.styles';
import { Color } from 'components/styles/constants.styles';
import { PrimaryButtonStyle } from 'components/styles/button.styles';
import { Font } from 'components/types/enums';

const Wrapper = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const PageTitle = styled.h1`
    ${TextStyle2};

    margin: 24px 0 8px 0;
`;

const PageSubtitle = styled.h3<{ margin?: string; width?: string }>`
    ${TextStyle8};

    width: ${({ width }) => width || ''};
    margin: ${({ margin }) => margin};
    color: ${Color.black};
    text-align: center;
`;

const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

const WelcomePrimaryButton = styled.button<{
    width?: string;
    height?: string;
    background?: string;
}>`
    ${PrimaryButtonStyle};

    min-width: ${({ width }) => width || '178px'};
    height: ${({ height }) => height || '72px'};
    background: ${({ background }) => background};
`;

const WelcomeTransparentButton = styled.button`
    min-width: 173px;
    height: 72px;
    border: none;
    background: ${Color.transparent};
    color: ${Color.lightish_blue};
    font-family: ${Font.bold};
    font-size: 14px;
    font-weight: 700;
    outline: none;
    text-transform: uppercase;
    cursor: pointer;
`;

export const SharedSC = {
    Wrapper,
    PageSubtitle,
    PageTitle,
    ButtonContainer,
    WelcomePrimaryButton,
    WelcomeTransparentButton,
};
