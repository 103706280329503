import styled from 'styled-components';

import { Color } from 'components/styles/constants.styles';
import { TextStyle3, FormInputErrorText } from 'components/styles/text.styles';

import { alignMe } from 'components/styles/helpers.styles';

const LanguageForm = styled.div`
  width: 530px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const CheckboxTitle = styled.h3`
    ${TextStyle3};
`;

const ErrorMessageContainer = styled.p`
    ${FormInputErrorText};
`;

const CheckboxContainer = styled.div`
    width: 100%;
    ${alignMe('center', 'flex-start')};
    flex-direction: column;
    margin: 20px 0 48px 0;

    label {
        display: block;
        margin-bottom: 16px;
        ${alignMe('flex-start', 'center')};
        flex-direction: row-reverse;
    }
     input {
        height: 0;
        width: 0;
        opacity: 0;
        cursor: pointer;
    }
    input:checked ~ span {
        border-radius: 4px;
        border:2px solid ${Color.lightish_blue};
        background-color: ${Color.lightish_blue};
    }
    input:checked ~ span:after {
        display: block;
    }
    span {
        height: 28px;
        width: 28px;
        margin-right: 13px;
        border-radius: 4px;
        border: 2px solid #d0ddf2;
        cursor: pointer;
        background-color: ${Color.white};
    }
    span:after {
        content: "";
        display: none;
        width: 9px;
        height: 13px;
        border: solid white;
        border-width: 0 2.8px 2.8px 0;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        margin: 4px 0 0 7px;
    }
`;

export const LanguagesSC = {
    LanguageForm,
    CheckboxContainer,
    CheckboxTitle,
    ErrorMessageContainer,
}
