import { EditorState } from 'draft-js';

import { EditProductCommonFields } from './store/types';

export const productDefaultDescriptionFieldSet = {
  type: '',
  description: EditorState.createEmpty(),
}

export enum EditProductTabs {
  general = 'generalInfo',
  seo = 'seo',
}

export const editProductCommonFields = [
  'quantityAtStock',
  'deliveryDays',
  'shippingCost',
  'pictures',
  'uvp',
];

export const productCommonDefaultValues: EditProductCommonFields = {
  quantityAtStock: null,
  deliveryDays: null,
  shippingCost: null,
  pictures: [],
  uvp: null,
}

export const productFormDefaultValues = {
  [EditProductTabs.general]: {
    productName: '',
    usp1: '',
    usp2: '',
    usp3: '',
    productCatchPhrase: '',
    descriptions: [productDefaultDescriptionFieldSet],
  }, 
  [EditProductTabs.seo]: {
    seoPageTitle: '',
    seoPageDescription: '',
  },
};

export const fieldFromServer: Record<string, string> = {
  quantityAtStock: 'quantity',
};
