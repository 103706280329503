import React, { FC, useCallback } from 'react';

import { CmsAcordionSection } from 'units///cms/components/cms-acordion-section/cmsAcordionSection.component';
import { FormikToggleButton } from 'units///common/components/formik/toggle/formik-toggle';
import { TrackProgressField } from 'units///common/hooks/useTrackProgress';
import { useCMSFormikImageUploadHelper } from 'units///cms/hooks/useCMSImageUploadHelper';
import { CMSCustomizationHomePageUSPList } from './usp-section-list.component';
import { useTranslation } from 'react-i18next';

type Props = {
    updateProgress: (field: TrackProgressField) => void;
};

export type CMSCustomizationHomepageUSPSection = 'usp1' | 'usp2' | 'usp3';

const USPSections: Array<CMSCustomizationHomepageUSPSection> = ['usp1', 'usp2', 'usp3'];

export const CMSHomepageUSPAccordion: FC<Props> = ({ updateProgress }) => {
    const { t } = useTranslation();

    const [usp1Icon, usp1IconHelper] = useCMSFormikImageUploadHelper('usp.usp1.icon');
    const [usp2Icon, usp2IconHelper] = useCMSFormikImageUploadHelper('usp.usp2.icon');
    const [usp3Icon, usp3IconHelper] = useCMSFormikImageUploadHelper('usp.usp3.icon');

    const handleIconSave = useCallback(
        (section: CMSCustomizationHomepageUSPSection, files: Array<File>) => {
            const storeFirstImage = {
                usp1: usp1IconHelper,
                usp2: usp2IconHelper,
                usp3: usp3IconHelper,
            }[section].storeFirstImage;

            storeFirstImage(files);
            updateProgress({ name: `${section}.icon`, isFilled: true });
        },
        [updateProgress],
    );

    const handleIconDelete = useCallback(
        (section: CMSCustomizationHomepageUSPSection, id: string) => {
            const deleteImage = {
                usp1: usp1IconHelper,
                usp2: usp2IconHelper,
                usp3: usp3IconHelper,
            }[section].deleteImage;

            deleteImage(id);
            updateProgress({ name: `${section}.icon`, isFilled: false });
        },
        [updateProgress],
    );

    return (
        <>
            <CmsAcordionSection sectionMargin={32} withDevider={false}>
                <FormikToggleButton
                    name="usp.isActive"
                    label={t('active')}
                    afterChange={({ target }) => {
                        updateProgress({ name: 'usp.isActive', isFilled: !!target.checked });
                    }}
                />
            </CmsAcordionSection>
            <CMSCustomizationHomePageUSPList
                sections={USPSections.map(s => {
                    const iconProps = {
                        usp1: {
                            iconPreview: usp1Icon || undefined,
                            iconError: usp1IconHelper.error,
                        },
                        usp2: {
                            iconPreview: usp2Icon || undefined,
                            iconError: usp2IconHelper.error,
                        },
                        usp3: {
                            iconPreview: usp3Icon || undefined,
                            iconError: usp3IconHelper.error,
                        },
                    }[s];
                    return { key: s, ...iconProps };
                })}
                saveIcon={handleIconSave}
                deleteIcon={handleIconDelete}
                updateProgress={updateProgress}
            />
        </>
    );
};
