import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import { Preloader, Typography } from 'shared/components/ui';

import { LayoutSC } from 'units/common/components/layout/layout-components.styles';
import { Pagination } from 'units/common/components/pagination/pagination.component';
import { Table } from 'units/common/components/table/table.component';
import { OrdersService } from '../../api';
import { OrderItemType, OrderPaymentStatusEnum, OrderStatusEnum } from '../../types';
import { OrderUrls } from '../../urls';

import EmtyImageSrc from 'assets/images/empty-orders.svg';

import { OrdersSC } from './orders.styles';

const columns = [
    {
        Header: 'Order number',
        accessor: 'number',
    },
    {
        Header: 'Order date',
        accessor: 'date',
    },
    {
        Header: 'Payment status',
        accessor: 'payment',
    },
    {
        Header: 'Status',
        accessor: 'status',
    },
    {
        Header: 'Order sum',
        accessor: 'sum',
    },
];

const ITEMS_PER_PAGE = 10;

const formatNumber = (value: number) => (value > 9 ? value : `0${value}`);

const prepareDate = (value: string) => {
    const date = new Date(value);
    const days = `${formatNumber(date.getDate())}.${formatNumber(
        date.getMonth() + 1,
    )}.${date.getFullYear()}`;
    const time = `${formatNumber(date.getHours())}:${formatNumber(date.getMinutes())}`;

    return `${days} ${time}`;
};

export const Orders = () => {
    const [orders, setOrders] = useState<any>(null);
    const [pagesLength, setPagesLength] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const history = useHistory();

    const onDetailPage = ({ id }: { id: string }) => {
        history.push(`${OrderUrls.index}/${id}`);
    };

    const loadOrders = useCallback(async (page: number) => {
        const { data } = await OrdersService.getAll(page, ITEMS_PER_PAGE);
        setPagesLength(Math.ceil(data.count / ITEMS_PER_PAGE));
        prepareData(data.results);
        setIsLoading(false);
    }, []);

    const onPaginationChange = (selectedItem: { selected: number }) => {
        loadOrders(selectedItem.selected + 1);
    };

    const prepareData = useCallback((data: Array<OrderItemType> | null) => {
        const result = data
            ? data.map(({ id, created_at, status, payment, amount }) => ({
                  id,
                  date: prepareDate(created_at),
                  number: `#${id}`,
                  payment: (
                      <OrdersSC.Black>
                          {payment
                              ? OrderPaymentStatusEnum[payment.status].replace('_', ' ')
                              : 'Unpaid'}
                      </OrdersSC.Black>
                  ),
                  status: <OrdersSC.Black>{OrderStatusEnum[status]}</OrdersSC.Black>,
                  sum: <OrdersSC.Bold>{amount}</OrdersSC.Bold>,
              }))
            : [];

        setOrders(result);
    }, []);

    useEffect(() => {
        loadOrders(1);
    }, []);

    if (isLoading) return <Preloader.Page height={600} />;

    return (
        <>
            <Helmet>
                <title>Orders</title>
            </Helmet>
            <LayoutSC.PageTitleContainer>
                <h1>Orders</h1>
            </LayoutSC.PageTitleContainer>
            <OrdersSC.Root>
                {!(orders && orders.length) && <Empty />}
                {orders !== null && (
                    <>
                        <Table columns={columns} data={orders} onRowClick={onDetailPage} />
                        <Pagination
                            pageSize={ITEMS_PER_PAGE}
                            pageCount={pagesLength}
                            // itemsCount={orders}
                            onPageChange={onPaginationChange}
                        />
                    </>
                )}
            </OrdersSC.Root>
        </>
    );
};

const Empty = () => (
    <OrdersSC.Empty>
        <img src={EmtyImageSrc} alt="" />
        <Typography.Text>There are no orders added yet.</Typography.Text>
    </OrdersSC.Empty>
);
