import React, { FC, useCallback } from 'react'
import { useFormikContext, FormikValues, FormikContextType } from 'formik';

import {
  CircleProgressGroupProps,
  CircleProgressGroup,
} from 'units/products/components/circle-progress/circle-progress-group/circle-progress-group.component';
import { CompanyLanguages } from 'units/app/redux/types';

type Helpers = Pick<FormikContextType<FormikValues>, 'values' | 'resetForm' | 'validateForm'>;

interface Props extends Omit<CircleProgressGroupProps, 'onClick'> {
  onClick: (languageId: CompanyLanguages, { values, resetForm }: Helpers) => void;
}

// Extends circle click handler to our needs.
export const EditProductLanguageProgressGroup: FC<Props> = (props) => {
  const { values, resetForm, validateForm } = useFormikContext<FormikValues>();

  const languageChangeHandler = useCallback((languageId: string) => {
    props.onClick(languageId as CompanyLanguages, { values, resetForm, validateForm });
  }, [values, resetForm, props.onClick]);

  return <CircleProgressGroup {...props} onClick={languageChangeHandler} />;
}
