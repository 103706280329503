import styled from 'styled-components';

import { FormSectionText, TextStyle8 } from 'components/styles/text.styles';
import { Color, sizes } from 'components/styles/constants.styles';

interface WrapperProps {
  isViewMode?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  ${({ isViewMode }) => isViewMode &&
    'pointer-events: none;'
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SectionTitle = styled.h2`
  margin-bottom: ${sizes.sm_padding};
  margin-top: 0;
  ${FormSectionText}
`;

const FormContainer = styled.div`
  margin-top: 8px;
`;

const Devider = styled.div`
  height: 1px;
  width: 100%;
  margin: ${sizes.md_padding} 0 ${sizes.lg_padding} 0;
  background: ${Color.cloudy_blue}
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: inline-block;
  margin: ${sizes.sm_padding} 0 ${sizes.lg_padding} 0;

  button {
    float: right;
    margin-left: ${sizes.sm_padding};
  }
`;

const SubLabel = styled.div`
  ${TextStyle8};
  line-height: unset;
  margin: 0 0 ${sizes.md_padding};
`;

export const CreateAuctionSC = {
  TitleContainer,
  FormContainer,
  SectionTitle,
  Devider,
  ButtonContainer,
  SubLabel,
  Wrapper,
};
