/* eslint-disable @typescript-eslint/no-empty-function */

export const UserInputValidationErrors = {
  companyName: { required: 'Company name is required' },
  email: {
    required: 'Email is required',
    notValid: 'Please enter valid email',
  },
  password: {
    min: 'At least 8 characters',
    required: 'Password is required',
    oneNumber: 'At least one number character',
    oneLowerCase: 'At least one lowercase letter',
    oneUpperCase: 'At least one uppercase letter',
    oneSpecialCharacter: 'At least one special character',
  },
  repeatPassword: {
    match: 'Passwords don\'t match. Please check and try again.',
    required: 'Repeat password is required',
  },
  firstName: { required: 'First name is required' },
  lastName: { required: 'Last name is required' },
  phone: { notValid: 'Not valid phone' },
  billing: {
    street: {
      required: 'Street is required'
    },
    number: {
      required: 'Number is required'
    },
    country: {
      required: 'Country is required'
    },
    city: {
      required: 'City is required'
    },
    zipCode: {
      max: 'Limit to 5 characters',
      required: 'Zip code is required',
      notValid: 'The field should have digits only.'
    },
  }
};

// Common validation messages
export const CommonVM = {
  required: (fName: string) => `${fName} is required`,
  maxSymbols: (max: number) => `Max length is ${max} symbols`,
  url: (fName: string) => `${fName} should be valid URL`,
}


export const EmptyFunction = () => {};

/* eslint-enable @typescript-eslint/no-empty-function */
