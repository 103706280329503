import React, { FC } from 'react';
import { Field, FieldProps } from 'formik';

import { RadioButtonGroup } from '../../radio/radio-group.component';
import { RadioButton } from '../../radio/radio-button.component';

type ButtonChangeEvent = React.ChangeEvent<HTMLInputElement>;

interface Props {
  margin?: string;
  radioList: Array<{
    id: string;
    label: string;
  }>,
  withBackground?: boolean,
  name: string;
  disabled?: boolean;
  label?: string;
  afterChange?: (evt: ButtonChangeEvent, id: string) => void;
}

export const FormikRadioButtonGroup: FC<Props> = ({ radioList, margin, name, label, afterChange, ...rest }) => (
  <RadioButtonGroup margin={margin} label={label}>
    { radioList.map(r => (
      <Field
        key={`radio_${r.id}_${r.label}`}
        name={name}
        
      >
        {(props: FieldProps) => {
          const onChange = (evt: ButtonChangeEvent) => {
            if (afterChange) {
              afterChange(evt, r.id);
            }
            props.field.onChange(evt);
          }
          return <RadioButton id={r.id} label={r.label} {...rest} {...props} field={{ ...props.field, onChange }} />
        }}
      </Field>
    ))}
  </RadioButtonGroup>
);
