import React, { FC, useMemo, useCallback } from 'react'
import { DropzoneRootProps, DropzoneInputProps } from 'react-dropzone';

import { UploaderDropzoneStyleType } from './uploader.styles';
import { BaseUploaderProps, BaseUploader, BaseUploaderContainerProps } from './base-uploader.component';
import { FileUploaderSecondaryContainer } from './uploader-container/secondary-container.component';
import { FileUploaderPrimaryContainer } from './uploader-container/primary-container.component';

type TextProps = {
  title?: string
  subtitle?: string;
  loadingTitle?: string;
}

type UploaderRenderProps = {
  rootProps: DropzoneRootProps;
  inputProps: DropzoneInputProps;
  isUploading: boolean;
}

export type FileUploaderContainerProps = UploaderRenderProps & TextProps & {
  fakeUploadingDuration?: number;
  cancelUploadingHandler?: () => void;
  uploaderId?: string;
  hasError: boolean;
}

export type FileUploaderProps = BaseUploaderProps & TextProps & {
  type: UploaderDropzoneStyleType;
  hasError?: boolean;
};

export const FileUploader: FC<FileUploaderProps> = ({
  title,
  subtitle,
  loadingTitle,
  hasError,
  type,
  ...uploaderProps
}) => {
  const uiProps = useMemo(() => ({
    title,
    subtitle,
    loadingTitle,
    hasError: hasError || false,
  }), [
    title,
    subtitle,
    loadingTitle,
    hasError
  ]);

  const renderUploaderContainer = useCallback((renderProps: BaseUploaderContainerProps) => {
    switch(type) {
      case 'primary':
        return <FileUploaderPrimaryContainer
          {...uiProps}
          {...renderProps}
        />
      case 'secondary':
        return <FileUploaderSecondaryContainer
          {...uiProps}
          {...renderProps}
        />
      default:
        return <FileUploaderPrimaryContainer
        {...uiProps}
        {...renderProps}
      />
    }
  }, [type, uiProps]);

  return (
    <BaseUploader
      {...uploaderProps}
      renderUploader={renderUploaderContainer}
    />
  );
}
