import React from 'react';
import clsx from 'clsx';

import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { ColorIcon, ColorIconName, Icon, Typography } from 'shared/components/ui';
import { useNavigate } from 'shared/hooks';
import { userRoleSelector } from 'units/user/redux/reducer';

import styles from './SettingItem.module.scss';
import { useTranslation } from 'react-i18next';

type ImmoSettingItemProps = {
    data: {
        url: string;
        image: string;
        title: string;
        description: string;
        translationKey: string;
        allowedTo: Array<string>;
        isDisabled: boolean;
    };
};

export const ImmoSettingItem: React.FC<ImmoSettingItemProps> = ({ data }) => {
    const navigate = useNavigate();
    const userRole = useSelector(userRoleSelector);

    const { t } = useTranslation();

    if (!data.allowedTo.includes(userRole || '')) return null;

    return (
        <>
            <Helmet>
                <title>Settings</title>
            </Helmet>
            <div
                className={clsx(styles['block-item'], { [styles['is-disabled']]: data.isDisabled })}
                onClick={() => !data.isDisabled && navigate(data.url)}
                {...(data.isDisabled && { title: 'Coming soon' })}
            >
                <div className={styles['image-wrapper']}>
                    <ColorIcon name={data.image as ColorIconName} />
                </div>
                <div className={styles['text-wrapper']}>
                    <Typography.Title htmlElement="h4">
                        {t(`settings_item_${data.translationKey}`)}
                    </Typography.Title>
                    <Typography.Text className={styles.description}>
                        {t(`settings_item_${data.translationKey}_description`)}
                    </Typography.Text>
                </div>
                <div className={styles['icon-wrapper']}>
                    <Icon name="arrow-right" />
                </div>
            </div>
        </>
    );
};
