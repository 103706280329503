import React, {FC, useState, useCallback, useEffect } from 'react';
import NumberFormat, { NumberFormatProps, NumberFormatValues } from 'react-number-format';
import { useField } from 'formik';

interface InputProps {
  withRounding?: boolean;
}

type Props = React.InputHTMLAttributes<HTMLInputElement> & NumberFormatProps & InputProps;

const decimalScale = 2;

export const BaseMoneyInput: FC<Props> = ({ withRounding, value, ...props }) => {
  const [inputValue, setInputValue] = useState<string | number | undefined>('');
  const [,, { setValue, setTouched }] = useField({ name: props.name || '' });

  useEffect(() => {
    withRounding && setInputValue(value);
  }, [value]);

  const roundToFive = useCallback((values: NumberFormatValues) => {
    const roundTo = 5;

    if (values.formattedValue) {
      const result = (Math.round(parseFloat(values.formattedValue) * Math.pow(10, decimalScale) / roundTo) * roundTo / Math.pow(10, decimalScale)).toFixed(decimalScale);
      setInputValue(result)
    }
  }, []);

  const onBlurInput = () => {
    setTouched(true);
    if(withRounding) setValue(inputValue);
  }

  return (
    <NumberFormat
      decimalScale={decimalScale}
      fixedDecimalScale
      isNumericString
      allowNegative={false}
      {...props}
      type="text"
      value={withRounding ? inputValue : value}
      onValueChange={withRounding ? roundToFive : undefined}
      onBlur={onBlurInput}
    />
  );
};
