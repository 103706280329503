import { useSelector } from 'react-redux';

import { RootState } from 'redux/root-types';
import { CMSThemeId } from '../../store/types';
import { CMSThemesTitle } from '../../constants';

// Hook to retrieve selected on sidebar theme and its title.
export const useCMSSelectedTheme = () => {
  const selectedTheme = useSelector<RootState, CMSThemeId>(({ cms }) => cms.tempPayload.selectedTheme);

  return {
    id: selectedTheme,
    title: CMSThemesTitle[selectedTheme],
  };
};
