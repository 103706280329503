import { useMemo } from 'react';
import { FormikErrors, FormikValues } from 'formik';

import { Auction } from './constants';

export const useIsConditionsValid = (errors: FormikErrors<FormikValues>, values: FormikValues) =>
    useMemo(() => {
        let isValid = true;
        const condErr = errors.conditions;

        if (Array.isArray(condErr)) {
            for (let index = 0; index < condErr.length; index++) {
                const value = values.conditions[index];
                const error = condErr[index];

                if (
                    (value.place || value.value) &&
                    typeof error === 'object' &&
                    (error.value || error.place)
                ) {
                    isValid = false;
                    break;
                }
            }
        }

        return isValid;
    }, [errors, values]);

export const useNotRequiredFields = (type: string) =>
    useMemo(() => {
        const alwaysIgnoredFields = [
            'auctionId',
            'auctionStatus',
            'endDateCopy',
            'endTimeCopy',
            'isArchived',
            'quantity',
        ];
        const ignoredAccordingToType =
            type === Auction.buyNow
                ? ['minOffer', 'offerInterval', 'conditions', 'minProdPieces']
                : ['uvp', 'discount', 'discountType'];

        return alwaysIgnoredFields.concat(ignoredAccordingToType);
    }, [type]);

export const useErrorsWithoutConditions = (errors: FormikErrors<FormikValues>) =>
    useMemo(() => {
        const errWithoutConditions = { ...errors };
        delete errWithoutConditions.conditions;

        return errWithoutConditions;
    }, [errors]);

export const useWorkingMode = (location: any) =>
    useMemo(() => {
        const path = location.pathname;

        return {
            isCreateMode: path.includes('create'),
            isEditMode: path.includes('edit'),
            isViewMode: path.includes('view'),
        };
    }, [location]);

export const useIsTimeValid = (errors: FormikErrors<FormikValues>) =>
    useMemo(() => {
        const { startTime, endTime } = errors;

        return (
            (!startTime && !endTime) ||
            (typeof startTime === 'string' &&
                startTime.includes('required') &&
                typeof endTime === 'string' &&
                endTime.includes('required'))
        );
    }, [errors]);
