import {
    prepareCustomizationProgress as helpers,
    CMSProgressInitialSection,
    CMSUpdateFilledProgress,
    CMSCustomizationCommonProgress,
  } from '../prepare-customization-progress';
  import { CMSCustomizationMissedAuctionsSectionName, CMSCustomizationMissedAuctionsProgressField } from '../../types';
  import { CMSMissedAuctionsCustomizationSettings } from '../../store/types/customization/missed-auctions';
  import { CompanyLanguages } from 'units/app/redux/types';
  import { CMSCustomizationOverallSettings } from '../../store/types/customization';
  
  // Alias
  type HP_SN = CMSCustomizationMissedAuctionsSectionName;
  type HP_PK = CMSCustomizationMissedAuctionsProgressField;
  
  export const prepareMissedAuctionsProgress = (
    overallSettings: CMSCustomizationOverallSettings<CMSMissedAuctionsCustomizationSettings>,
  ) => {
    // Hold one language settings which gonna be used in common comparison.
    const commonSettings = Object.values(overallSettings)[0];
  
    if (!commonSettings) {
      return {};
    }
  
    const languages = Object.keys(overallSettings) as Array<CompanyLanguages>;
    // Holds sections with fields that is required for progress.
    const requiredProgress = helpers.initializeRequiredProgress<HP_SN, HP_PK>(missedAuctionsInitialSections);
    // Holds sections with fields that already filled based on overallSettings.
    const filledProgress = helpers.initializeFilledProgress<HP_SN, HP_PK>(missedAuctionsInitialSections.map((s) => ({
      ...s,
      initialProgress: [],
    })), languages);
  
    // Prefilled progress
    for (const [lng, lngSettings] of Object.entries(overallSettings)) {
      if (!lngSettings) {
        continue;
      }
  
      const language = lng as CompanyLanguages;
      const updates = prepareMissedAuctionsFilledProgress(lngSettings);
      helpers.updateFilledProgress(filledProgress, language, updates);
    }
  
    return { sectionProgress: helpers.prepareSectionProgress(requiredProgress, filledProgress, commonFields) };
  }
  
  const prepareMissedAuctionsFilledProgress = ({ general }: CMSMissedAuctionsCustomizationSettings) => {
    const updates: Array<CMSUpdateFilledProgress<HP_SN, HP_PK>> = [];
  
    // General section
    const sectionKeys: Array<HP_PK> = [];
  
    general.title && sectionKeys.push('title');
    updates.push({ sectionName: 'general', progressKeys: sectionKeys });
  
    return updates;
  }
  
const missedAuctionsInitialSections: Array<CMSProgressInitialSection<HP_SN, HP_PK>> = [{
  name: 'general',
  initialProgress: ['title'],
}];
  
export const commonFields: CMSCustomizationCommonProgress<HP_SN, HP_PK> = {
  general: [],
};

