import React, { FC, useCallback, useMemo } from 'react';
import { useFormikContext } from 'formik';

import { CmsAcordionSection } from 'units/cms/components/cms-acordion-section/cmsAcordionSection.component';
import { FormikToggleButton } from 'units/common/components/formik/toggle/formik-toggle';
import { FormikFormInput } from 'units/common/components/formik/input/form-input.component';
import { getImage, ImageName } from 'components/styles/images';
import { CMSFooterSocialLinksSC } from './footer-social-links.styles';
import { CMSHomePageFormikCustomizationSettings } from 'units/cms/store/types/customization/homepage';
import { TrackProgressField } from 'units/common/hooks/useTrackProgress';

type LabelProps = {
    iconName: ImageName;
    label: string;
};

type SocialLink = {
    id: string;
    name: string;
    label: string;
    toggleLabel: React.ReactNode;
    placeholder: string;
};

const ToggleLabel: FC<LabelProps> = ({ iconName, label }) => (
    <CMSFooterSocialLinksSC.ToggleLabel>
        {getImage(iconName)}
        {label}
    </CMSFooterSocialLinksSC.ToggleLabel>
);

const namePrefix = 'footer.socialLinks';
const SocialLinks: Array<SocialLink> = [
    {
        id: 'instagram',
        name: `${namePrefix}.instagram`,
        label: 'Instagram link',
        toggleLabel: <ToggleLabel iconName={ImageName.instagram} label="Instagram" />,
        placeholder: 'Add an Instagram link',
    },
    {
        id: 'tiktok',
        name: `${namePrefix}.tiktok`,
        label: 'Tiktok link',
        toggleLabel: <ToggleLabel iconName={ImageName.tiktok} label="Tiktok" />,
        placeholder: 'Add a Tiktok link',
    },
    {
        id: 'facebook',
        name: `${namePrefix}.facebook`,
        label: 'Facebook link',
        toggleLabel: <ToggleLabel iconName={ImageName.facebook} label="Facebook" />,
        placeholder: 'Add a Facebook link',
    },
    {
        id: 'linkedin',
        name: `${namePrefix}.linkedin`,
        label: 'LinkedIn link',
        toggleLabel: <ToggleLabel iconName={ImageName.linkedin} label="Linkedin" />,
        placeholder: 'Add a LinkedIn link',
    },
    {
        id: 'twitter',
        name: `${namePrefix}.twitter`,
        label: 'Twitter link',
        toggleLabel: <ToggleLabel iconName={ImageName.twitter} label="Twitter" />,
        placeholder: 'Add a Twitter link',
    },
    {
        id: 'snapchat',
        name: `${namePrefix}.snapchat`,
        label: 'Snapchat link',
        toggleLabel: <ToggleLabel iconName={ImageName.snapchat} label="Snapchat" />,
        placeholder: 'Add a Snapchat link',
    },
];

type FooterSocialLinkProps = {
    link: SocialLink;
    updateProgress: (field: TrackProgressField) => void;
    disabled?: boolean;
    disableToggle: boolean;
};

const FooterSocialLink: FC<FooterSocialLinkProps> = ({
    link,
    updateProgress,
    disabled,
    disableToggle,
}) => {
    const handleURLAfterChange = useCallback(
        ({ target }: React.ChangeEvent<HTMLInputElement>) => {
            updateProgress({ name: `${link.id}.url`, isFilled: !!target.value });
        },
        [updateProgress, link],
    );

    const handleToggleAfterChange = useCallback(
        ({ target }: React.ChangeEvent<HTMLInputElement>) => {
            updateProgress({ name: `${link.id}.isActive`, isFilled: target.checked });
        },
        [updateProgress, link],
    );

    return (
        <CmsAcordionSection withDevider={false}>
            <FormikToggleButton
                name={`${link.name}.isActive`}
                label={link.toggleLabel}
                afterChange={handleToggleAfterChange}
                disabled={disableToggle ? disabled : false}
            />
            <FormikFormInput
                name={`${link.name}.url`}
                label={link.label}
                placeholder={link.placeholder}
                disabled={disabled}
                afterChange={handleURLAfterChange}
            />
        </CmsAcordionSection>
    );
};

export const CMSFooterSocialLinks: FC<Pick<FooterSocialLinkProps, 'updateProgress'>> = ({
    updateProgress,
}) => {
    const {
        values: {
            footer: { socialLinks },
        },
    } = useFormikContext<CMSHomePageFormikCustomizationSettings>();

    const isActive = useCallback(
        (linkName: string) => {
            const link = Object.entries(socialLinks).find(([key]) => key === linkName);
            if (!link) {
                return false;
            }
            return link[1].isActive;
        },
        [socialLinks],
    );

    const activeItems = useMemo(() => {
        let count = 0;
        if (socialLinks) Object.values(socialLinks).forEach(item => item.isActive && ++count);

        return count;
    }, [socialLinks]);

    return (
        <CmsAcordionSection title="Social network links" withDevider={false}>
            {SocialLinks.map(link => (
                <FooterSocialLink
                    key={link.id}
                    link={link}
                    disabled={!isActive(link.id)}
                    updateProgress={updateProgress}
                    disableToggle={activeItems >= 4}
                />
            ))}
        </CmsAcordionSection>
    );
};
