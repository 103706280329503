import styled from 'styled-components';

import { Color } from 'components/styles/constants.styles';

export type ProgressBarColorProps = {
  progressColor?: Color;
  fillColor?: Color;
}

type Props = ProgressBarColorProps & {
  width?: number;
  fakeAnimation?: boolean;
  fakeDuration?: number;
}

const LoadedPart = styled.div<Props>`
  height: 100%;
  width: ${({ width, fakeAnimation }) => fakeAnimation ? 0 : width}%;
  margin-right: 5px;
  border-radius: 6px;

  background-color: ${({ progressColor }) => progressColor || '#fd9d40'};
  
  @keyframes loading {
    from { width: 0%; }
    to { width: 100%; margin-right: 0 }
  }

  ${({ fakeAnimation, fakeDuration }) => fakeAnimation ? `
    animation: loading forwards ease-in-out;
    animation-duration: ${fakeDuration || 1000}ms;
  ` : 'transition: width 100ms ease-in-out;'}
`;

const UnLoadedPart = styled.div<Props>`
  height: 100%;
  width: ${({ width, fakeAnimation }) => fakeAnimation ? 100 : width}%;
  border-radius: 6px;

  background-color: ${({ fillColor }) => fillColor || '#ecf2fd'};
  
  @keyframes hiding {
    from { width: 100%; }
    to { width: 0%; }
  }

  ${({ fakeAnimation, fakeDuration }) => fakeAnimation ? `
    animation: hiding forwards ease-in-out;
    animation-duration: ${fakeDuration || 1000}ms;
  ` : 'transition: width 100ms ease-in-out;'}
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 8px;
  overflow-x: hidden;
  border-radius: 6px;
`;

export const ProgressBarSC = {
  Container,
  LoadedPart,
  UnLoadedPart,
};
