import React, { FC, useCallback } from 'react';

import { CMSThemeId } from 'units/cms/store/types';
import { CMSThemeListSC } from './theme-list.styles';
import { CMSThemeListItemType } from './theme-list.component';

interface Props {
    theme: CMSThemeListItemType;
    selected?: boolean;
    handleThemeClick: (id: CMSThemeId) => void;
}

export const CMSThemeListItem: FC<Props> = ({ theme, selected, handleThemeClick }) => {
    const onItemClick = useCallback(() => handleThemeClick(theme.id), [theme, handleThemeClick]);

    return (
        <CMSThemeListSC.ListItem onClick={onItemClick} selected={selected}>
            <CMSThemeListSC.ItemTitle font={theme.customFont}>
                {theme.title}
            </CMSThemeListSC.ItemTitle>
        </CMSThemeListSC.ListItem>
    );
};
