import { CompanyLanguages } from './redux/types';

// Available languages for company (to choose on welcome flow).
export const AvailableCompanyLanguages: Array<CompanyLanguages> = [
  CompanyLanguages.de,
  CompanyLanguages.en,
  CompanyLanguages.fr,
  CompanyLanguages.it,
];

const LanguageTitle = {
  [CompanyLanguages.en]: 'English',
  [CompanyLanguages.de]: 'German',
  [CompanyLanguages.fr]: 'French',
  [CompanyLanguages.it]: 'Italian',
}

export const languages = AvailableCompanyLanguages.map(l => ({ id: l, title: LanguageTitle[l] }));
