import { UserRoleId } from 'components/types/enums';

export const getRoleName = (roleId: UserRoleId) => {
    switch (roleId) {
        case UserRoleId.Admin:
            return 'Admin';
        case UserRoleId.Owner:
            return 'Owner';
        case UserRoleId.User:
            return 'User';
        default:
            return 'User';
    }
};
