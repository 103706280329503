import { createAnalyticService } from './services/analytic';
import { createAuctionService } from './services/auction';
import { authService } from './services/auth';
import { customizationService } from './services/customization';
import { createFinanceService } from './services/finance';
import { createObjectService } from './services/object';
import { createOrderService } from './services/order';
import { createOverviewService } from './services/overview';
import { createSettingsService } from './services/settings';

const createApi = () => ({
    settingsService: createSettingsService(),
    orderService: createOrderService(),
    objectService: createObjectService(),
    financeService: createFinanceService(),
    authService: authService(),
    overviewService: createOverviewService(),
    analyticService: createAnalyticService(),
    auctionService: createAuctionService(),
    customizationService: customizationService(),
});

export const api = createApi();
