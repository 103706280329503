import * as actionCreators from '../action-creators';

import { InferValueTypes } from 'redux/types';
import { CMSThemeSettingsOverallConfig } from './theme-settings';
import { CMSCustomizationConfig } from './customization';
import { CMSCustomizationShopModalsSectionName } from 'units/cms/types';
import { CompanyLanguages } from 'units/app/redux/types';

export type CMSTheme = {
  id: CMSThemeId;
  name: string;
}

export enum CMSThemeId {
  Traditional = 1,
  Modern = 2,
  Minimal = 3,
  Funky = 4,
  Luxury = 5,
  Estate = 6
}

// activeTheme - saved theme which gonna be used in storefront.
export type CMSThemesConfig = {
  activeTheme: CMSThemeId;
}

export type CMSConfigForAPI = true;

export type CMSGoogleFonts = Array<{
  value: string;
  label: string;
}>

export type CMSConfig = {
  themes: CMSThemesConfig;
  themeSettings: CMSThemeSettingsOverallConfig;
  customization: CMSCustomizationConfig;
}

export type CMSRootState = {
  config: CMSConfig;
  tempPayload: {
    selectedTheme: CMSThemeId;
    isThemeOverviewLoading: boolean;
    isCustomizationConfigLoaded: boolean;
    isSaved: boolean;
    selectedPage: CMSCustomizationPage;
    imagesToDelete: Array<CMSImageToDelete>;
    selectedModal: CMSCustomizationShopModalsSectionName;
    selectedLanguage: CompanyLanguages;
  },
  availableThemes: Array<CMSTheme>;
  googleFonts: CMSGoogleFonts;
  isDirty:boolean;
}

export type CMSImage = {
  id: string;
  src: string;
}

export type CMSRootActions = ReturnType<InferValueTypes<typeof actionCreators>>;

export enum CMSCustomizationPage {
  Home = 'Home',
  ProductDetail = 'ProductDetail',
  MissedAuctions = 'MissedAuctions',
  ShopModals = 'ShopModals',
}

export type CMSImageToSave = {
  key: string;
  image: File;
}

export type CMSImageToDelete = string;

export type CMSUploadedImage = {
  id: string;
  key: string;
  src: string;
}
