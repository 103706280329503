import { useHistory } from 'react-router-dom';
import { useCallback, useMemo } from 'react';

import { CMSTab } from 'units/cms/types';
import { CMSTabs, CMSThemesTab } from 'units/cms/constants';

type UseCMSTabsNavigation = () => {
  selectedTab: CMSTab;
  navigateToTab: (newTab: CMSTab) => void;
}

export const useCMSTabsNavigation: UseCMSTabsNavigation = () => {
  const history = useHistory();

  const selectedTab = useMemo(
    () => CMSTabs.find((t) => t.url === history.location.pathname) || CMSThemesTab,
    [history.location],
  );

  const handleTabSwitch = useCallback((newTab: CMSTab) => {
    if (selectedTab?.id === newTab.id) {
      return;
    }

    history.push(newTab.url);
  }, [selectedTab]);

  return {
    selectedTab,
    navigateToTab: handleTabSwitch,
  }
};
