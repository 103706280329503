import { useSelector } from 'react-redux';

import { CMSCustomizationPage } from '../../store/types';
import { RootState } from 'redux/root-types';
import { CMSHomePageCustomizationOverallSettings } from '../../store/types/customization/homepage';
import { CMSProductDetailPageCustomizationOverallSettings } from '../../store/types/customization/product-detail-page';
import { CMSMissedAuctionsCustomizationOverallSettings } from '../../store/types/customization/missed-auctions';
import { CMSShopModalsCustomizationOverallSettings } from '../../store/types/customization/shop-modals';

type HookReturn<T> = T extends CMSCustomizationPage.ProductDetail
  ? CMSProductDetailPageCustomizationOverallSettings
  : T extends CMSCustomizationPage.MissedAuctions
    ? CMSMissedAuctionsCustomizationOverallSettings
    : T extends CMSCustomizationPage.ShopModals
      ? CMSShopModalsCustomizationOverallSettings
      : CMSHomePageCustomizationOverallSettings;

// Hook to retrieve customization settings based on page.
export const useCMSCustomizationSettings = <T extends CMSCustomizationPage>(
  customizationPage: T,
) => useSelector<RootState, HookReturn<T>>(({ cms: { config } }) => {
  switch(customizationPage) {
    case CMSCustomizationPage.Home:
      return config.customization.homepage.settings as HookReturn<T>;
    case CMSCustomizationPage.ProductDetail:
      return config.customization.productDetailPage.settings as HookReturn<T>;
    case CMSCustomizationPage.MissedAuctions:
      return config.customization.missedAuctions.settings as HookReturn<T>;
    case CMSCustomizationPage.ShopModals:
      return config.customization.shopModals.settings as HookReturn<T>;
    default:
      return config.customization.homepage.settings as HookReturn<T>;
  }
});
