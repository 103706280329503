import React, { FC, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    fetchCMSAvailableThemesThunk,
    fetchCMSThemeSettingsListThunk,
    fetchCMSCustomizationConfigThunk,
} from './store/thunks';
import { CMSOverviewRoot } from './components/overview-root';
import { useAsyncDispatch } from 'helpers/hooks';
import { CMSHeader } from './components/cms-header/cms-header.component';
import { useCMSAvailableThemes } from './hooks/selectors/useCMSAvailableThemes';
import { cmsClearTempPayload } from './store/action-creators';
import { NotificationContext } from 'shared/providers';

import { cmsSettingDirtyState } from './store/reducer';
import { UserState } from 'units/user/redux/types';
import { RootState } from 'redux/root-types';
import { CMSThemeId } from './store/types';

export const CMSRoot: FC = () => {
    const dispatch = useDispatch();
    const { showNotification } = useContext(NotificationContext);
    const { dispatch: asyncDispatch } = useAsyncDispatch();
    const availableThemes = useCMSAvailableThemes();
    const dirtySetting = useSelector(cmsSettingDirtyState);
    const { user } = useSelector<RootState, UserState>(state => state.user);

    useEffect(() => {
        if (dirtySetting) {
            window.onbeforeunload = function () {
                return true;
            };
        }

        return () => {
            window.onbeforeunload = null;
        };
    }, [dirtySetting]);

    useEffect(() => {
        if (!availableThemes.length) {
            asyncDispatch(fetchCMSAvailableThemesThunk(showNotification));
        }

        asyncDispatch(fetchCMSThemeSettingsListThunk(showNotification));
        asyncDispatch(fetchCMSCustomizationConfigThunk(showNotification));

        return () => {
            dispatch(
                cmsClearTempPayload(
                    user && user.user_type?.slug === 'immo'
                        ? CMSThemeId.Estate
                        : CMSThemeId.Traditional,
                ),
            );
        };
    }, []);

    return (
        <>
            <CMSHeader />
            <CMSOverviewRoot />
        </>
    );
};
