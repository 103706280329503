import styled from 'styled-components';

import { Color, sizes } from 'components/styles/constants.styles';
import { FormInputErrorText } from 'components/styles/text.styles';

const mainContentSidePadding = sizes.lg_padding;
const sidebarMargin = '8px';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;

  max-width: ${sizes.content_width};
`;

const ContentContainer = styled.div`
  flex: 1;
  margin-bottom: 40px;
  padding-bottom: 76px;
  padding-top: 24px;
`;

const FormBottomControl = styled.div`
  position: fixed;
  z-index: 1;
  bottom: 0;
  width: ${'calc(100% - ' + sizes.sidebar_width + ' - ' + sidebarMargin + ')'};

  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: -${mainContentSidePadding};
  padding: 8px ${mainContentSidePadding};
  
  background-color: ${Color.white};
`;

const ErrorText = styled.span<React.CSSProperties>`
  ${({ marginLeft }) => marginLeft && `
    margin-left: ${marginLeft}px;
  `}

  ${FormInputErrorText}
  font-weight: 400;
`;

export const EditProductSC = {
  FormBottomControl,
  ContentContainer,
  Container,
  ErrorText,
};
