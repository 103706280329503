import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { CMSSidebarRoutes } from '../../routes';
import { CMSSidebarSC } from './styles';
import { EcatchLogo } from 'units/common/components/logo/logo.component';
import { BackButton } from 'units/common/components/back-button/back-button.component';
import { AppUrls } from 'app/urls';
import { Support } from 'units/common/components/layout/sidebar/support/support.component';
import { useCMSActiveTheme } from '../../hooks/selectors/useCMSActiveTheme';
import { useCMSConfirmModalManager } from '../../hooks/useCMSConfirmModalManager';
import { CMSConfirmModalStrings } from '../../constants';
import { ConfirmModal } from 'units/common/components/modal/confirm-modal/confirm-modal.component';
import { cmsSettingDirtyState } from 'units/cms/store/reducer';
import { useTranslation } from 'react-i18next';

export const CMSSidebarRoot = () => {
    const history = useHistory();
    const { title: activeThemeTitle } = useCMSActiveTheme();
    const [confirmModalProps, confirmModal] = useCMSConfirmModalManager({
        ...CMSConfirmModalStrings.backToDashboard,
    });
    const dirtySetting = useSelector(cmsSettingDirtyState);

    const handleBackButtonClick = useCallback(() => {
        const navigate = history.push.bind(null, AppUrls.index);

        if (!dirtySetting) {
            navigate();
        } else {
            confirmModal.openModal({
                confirmHandler: navigate,
            });
        }
    }, [dirtySetting]);

    const { t } = useTranslation();

    return (
        <>
            <CMSSidebarSC.Root>
                <CMSSidebarSC.LogoWrapper>
                    <EcatchLogo.Link />
                </CMSSidebarSC.LogoWrapper>
                <CMSSidebarSC.BackButtonWrapper>
                    <BackButton onClick={handleBackButtonClick} destinationText={t('dashboard')} />
                </CMSSidebarSC.BackButtonWrapper>
                <CMSSidebarSC.SidebarTitle>
                    {t('customization_sidebar_title')}
                    <CMSSidebarSC.SidebarSubTitle>
                        {`${t('customization_sidebar_subtitle')} ${activeThemeTitle}`}
                    </CMSSidebarSC.SidebarSubTitle>
                </CMSSidebarSC.SidebarTitle>

                <CMSSidebarRoutes />

                <Support />
            </CMSSidebarSC.Root>
            <ConfirmModal {...confirmModalProps} />
        </>
    );
};
