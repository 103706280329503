import styled, { css } from 'styled-components';

import { TextStyle8 } from 'components/styles/text.styles';
import { Font } from 'components/types/enums';
import { Color } from 'components/styles/constants.styles';

type DropzoneState = 'isDragAccept' | 'isDragReject' | 'isDragActive' | 'isUploading';

export type UploaderDropzoneStyleType = 'primary' | 'secondary';

type DropzoneStateForStyle = {
  [key in DropzoneState]?: boolean;
}

export type UploaderDropzoneStateColors = {
  [key in DropzoneState]?: string;
}

const primaryStateColors: UploaderDropzoneStateColors = {
  'isDragActive': Color.transparent_hover,
}

const secondaryStateColors: UploaderDropzoneStateColors = {
  'isUploading': Color.white,
}


type ContainerProps = DropzoneStateForStyle & {
  hasError: boolean;
  hideDashedBorder?: boolean;
  dropzoneStateColors?: UploaderDropzoneStateColors;
}

const getDropzoneColor = (props: DropzoneStateForStyle & {
  dropzoneStateColors?: UploaderDropzoneStateColors;
}) => {
  // if (props.isDragAccept) {
  //     return '#00e676';
  // }
  // if (props.isDragReject) {
  //     return '#ff1744';
  // }
  if (props.isUploading) {
    return props.dropzoneStateColors?.isUploading || 'unset';
  }
  if (props.isDragActive) {
    return props.dropzoneStateColors?.isDragActive || 'unset';
  }

  return 'unset';
}

/* eslint-disable max-len */
/* eslint-disable quotes */

/* https://kovart.github.io/dashed-border-generator/ */
const getDashedBorder = (highlighted: boolean) => highlighted
  ? `background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23F51010FF' stroke-width='2' stroke-dasharray='5%2c 5' stroke-dashoffset='8' stroke-linecap='square'/%3e%3c/svg%3e");`
  : `background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23C8D2E1FF' stroke-width='2' stroke-dasharray='5%2c 5' stroke-dashoffset='8' stroke-linecap='square'/%3e%3c/svg%3e");`

const BaseContainerStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  outline: none;
  padding: 20px;

  min-height: 260px;
  border-radius: 8px;
  
  overflow: unset;
`;

const PrimaryContainer = styled.div<ContainerProps>`
  ${BaseContainerStyle}

  ${({ hasError }) => getDashedBorder(hasError)}

  background-color: ${(props) => getDropzoneColor({
    ...props,
    dropzoneStateColors: primaryStateColors,
  })};
`;

const SecondaryContainer = styled.div<ContainerProps>`
  ${BaseContainerStyle}
  min-height: 224px;

  ${({ hasError, hideDashedBorder }) => !hideDashedBorder && getDashedBorder(hasError)}

  background-color: ${(props) => getDropzoneColor({
    ...props,
    dropzoneStateColors: secondaryStateColors,
  })};
`;

/* eslint-enable max-len */
/* eslint-enable quotes */

const BaseTitleStyle = css`
  font-family: ${Font.regular};
  font-size: 14px;
  color: ${Color.black};

  margin-bottom: 24px;
`;

const PrimaryTitle = styled.div`
  ${BaseTitleStyle};
`;

const SecondaryTitle = styled.div`
  ${BaseTitleStyle};

  font-size: 16px;
`;

const Subtitle = styled.p`
  margin-top: 24px;
  
  ${TextStyle8}
  text-align: center;
`;

const ProgressBarWrapper = styled.div`
  width: 100%;
  margin-bottom: 24px;
`;

export const BaseUploaderSC = {
  PrimaryContainer,
  SecondaryContainer,
  PrimaryTitle,
  SecondaryTitle,
  Subtitle,
  ProgressBarWrapper,
};
