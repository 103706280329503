import React from 'react';
import clsx from 'clsx';

import styles from './InputWrapper.module.scss';

type InputWrapperProps = {
    className?:string;
    label?:string;
}

export const InputWrapper:React.FC<InputWrapperProps> = ({ className, label, children }) => (
    <div className={clsx(styles.root, className)}>
        {label && <div className={styles.title}>{label}</div>}
        {children}
    </div>
)