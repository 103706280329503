import React, { InputHTMLAttributes } from 'react';
import clsx from 'clsx';

import styles from './Radio.module.scss';

type ImmoRadioProps = {
    colorStyle?: 'white' | 'gray';
};

export const ImmoRadio: React.FC<InputHTMLAttributes<HTMLInputElement> & ImmoRadioProps> = ({
    colorStyle = 'white',
    children,
    className,
    ...props
}) => (
    <label className={clsx(styles.root, className)}>
        <input className={styles.input} {...props} />
        <div className={clsx(styles.radio, styles[`is-${colorStyle}`])} />
        <span>{children}</span>
    </label>
);
