import styled from 'styled-components';

import { TextStyle4, TextStyle3, TextStyle5 } from 'components/styles/text.styles';
import { PrimaryButtonStyle } from 'components/styles/button.styles';
import { MediaQueries } from 'units//landing-page/config';

const SubTitle = styled.p`
  margin-bottom: 48px;

  ${TextStyle4}
  @media (max-width: ${MediaQueries.mobile}) {
    text-align: center;
    max-width: 450px;
  }
`;

const Button = styled.button`
  ${PrimaryButtonStyle}

  margin-bottom: 32px;
  padding: 24px 82px;
`;

const ResendText = styled.span`
  ${TextStyle3}
`;

const ResendButton = styled.button`
  ${TextStyle5};
  padding: 0;
  border: none;
  
  background: none;
  text-decoration: none;
  cursor: pointer;
  outline: none;
`;

export const CheckEmailSC = {
  SubTitle,
  Button,
  ResendText,
  ResendButton,
};
