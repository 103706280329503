import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { NotFoundIcon } from './icons.component';
import { NotFoundSC } from './not-found.styles';

export const NotFoundPage = () => {
    const { t } = useTranslation();
    return (
        <NotFoundSC.Container>
            <NotFoundSC.IconWrapper>
                <NotFoundIcon />
            </NotFoundSC.IconWrapper>
            <NotFoundSC.Subtitle>{t('not_found_text')}</NotFoundSC.Subtitle>
            <NotFoundSC.Button as={Link} to="/">
                {t('not_found_button')}
            </NotFoundSC.Button>
        </NotFoundSC.Container>
    );
};
