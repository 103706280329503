import axios, { AxiosInstance } from 'axios';

export const apiPath = '/v1';

export class ApiClient {
    public static client: AxiosInstance;

    public static getInstance: () => AxiosInstance = () => {
        if (!ApiClient.client) {
            ApiClient.initializeAxios();
        }

        return ApiClient.client;
    };

    public static initializeAxios: (accessToken?: string) => void = accessToken => {
        ApiClient.client = axios.create({
            baseURL: process.env.REACT_APP_BASE_API_URL,
        });

        if (accessToken) {
            ApiClient.setAccessToken(accessToken);
        }
    };

    public static setAccessToken: (accessToken: string) => void = accessToken => {
        if (!ApiClient.client) {
            ApiClient.initializeAxios();
        }
        ApiClient.client.defaults.headers.common.Authorization = `Token ${accessToken}`;
    };

    public static removeAccessToken: () => void = () => {
        if (!ApiClient.client) {
            ApiClient.initializeAxios();
        }

        ApiClient.removeHeader('Authorization');
    };

    public static removeHeader: (name: string) => void = name => {
        delete ApiClient.client.defaults.headers.common[name];
    };

    public static setHeader: (name: string, value: string) => void = (name, value) => {
        if (!ApiClient) {
            ApiClient.initializeAxios();
        }
        ApiClient.client.defaults.headers.common[name] = value;
    };
}
