import { Helmet } from 'react-helmet-async';
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { Formik, FormikHelpers } from 'formik';

import { transformErrorFromApi } from 'redux/tools/error-handling';
import { LayoutSC } from 'units/common/components/layout/layout-components.styles';
import { BackButton } from 'units/common/components/back-button/back-button.component';
import { UserEditForm } from './form/user-edit-form.component';
import { userEditValidationSchema } from './validation';
import { getInvitedUserById, updateInvitedUserById, deleteInvitedUserById, getRoleList, resendInvitationLink } from '../api/users-user-service';
import { UpdateInvitedUserErrorRes, UpdateInvitedUserError } from '../api/types';
import { SettingsUrls } from 'units/settings/urls';
import { parseInvitedUserError } from '../tools/parsers/invitedUser';
import { parseUserEditFormValues } from '../tools/parsers/form'
import { AppStrings } from 'config/strings';
import { ConfirmModal } from 'units/common/components/modal/confirm-modal/confirm-modal.component';
import { useStateToggle } from 'units/common/hooks/useStateToggle';
import { UserRole } from 'components/types';
import { UserRoleId } from 'components/types/enums';
import { UserEditSC } from './user-edit.styles';
import { TransparentButton } from 'units/common/components/buttons/transparent/transparent.component';
import { NotificationContext } from 'shared/providers';

type UserEditParams = {
  userId: string;
}

export type UserEditFormValues = {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  roleId: string;
}

const formInitialValues: UserEditFormValues = {
  email: '',
  firstName: '',
  lastName: '',
  phone: '',
  roleId: `${UserRoleId.Owner}`,
};

type Props = RouteComponentProps<UserEditParams>;

export const UserEdit: FC<Props> = ({
  match: { params: {
      userId,
  }}
}) => {
  const { showNotification } = useContext(NotificationContext);
  const [editUser, setEditUser] = useState<UserEditFormValues | null>(null);
  const [allowResendInvitationLink, setAllowResendInvitationLink] = useState(false);
  const [errorsFromServer, setErrorsFromServer] = useState<UpdateInvitedUserError | null>(null);
  const [isConfirmDeleteModalOpen, confirmModal] = useStateToggle(false);
  const [allowedRoles, setAllowedRoles] = useState<Array<UserRole>>([]);
  const history = useHistory();

  useEffect(() => {
    (async () => {
      try {
        const user = await getInvitedUserById(userId);
        const roles = await getRoleList();

        setAllowResendInvitationLink(!user.isActive);
        setEditUser(parseUserEditFormValues(user));
        setAllowedRoles(roles);
      } catch (error) {
        showNotification('error', AppStrings.errorNotification);
      }
    })();
  }, []);

  const submitHandler = useCallback(async (values: UserEditFormValues, helpers: FormikHelpers<UserEditFormValues>) => {
    try {
      await updateInvitedUserById(userId, values);
      
      setErrorsFromServer(null);
      showNotification('success', AppStrings.dataSuccessfullyUpdated);
    } catch (error) {
      if (error.response) {
        const err = parseInvitedUserError(transformErrorFromApi<UpdateInvitedUserErrorRes>(error));
        setErrorsFromServer(err);
      } else {
        showNotification('success', AppStrings.errorNotification);
      }
    } finally {
      // clear hidden errors
      helpers.setStatus({});
    }
  }, []);

  const deleteUser = useCallback(async () => {
    try {
      await deleteInvitedUserById(userId);
      confirmModal.turnOff();
      history.push(SettingsUrls.users.index);
      showNotification('success', 'User has been successfully deleted.');
    } catch (error) {
      showNotification('error', AppStrings.errorNotification);
    }
  }, [userId]);

  const resendInvitationLinkHandler = useCallback(async () => {
    try {
      if (editUser?.email) {
        await resendInvitationLink(editUser.email);
        showNotification('success', 'A new invitation was successfully sent.')
      }
    } catch (error) {
      showNotification('error', AppStrings.errorNotification);
    }
  }, [editUser]);

  return (
    <>
      <Helmet>
        <title>Edit user</title>
      </Helmet>
      <BackButton destinationText="User Accounts" to={SettingsUrls.users.index} />
      <UserEditSC.TitleContainer>
        <LayoutSC.PageTitle smallPadding>
          Edit user accounts
        </LayoutSC.PageTitle>
        { allowResendInvitationLink && 
          <TransparentButton type="button" onClick={resendInvitationLinkHandler}>Resend Invitation</TransparentButton>
        } 
      </UserEditSC.TitleContainer>
      <Formik
        initialValues={editUser || formInitialValues}
        validationSchema={userEditValidationSchema}
        onSubmit={submitHandler}
        enableReinitialize
      >
        <UserEditForm
          additionalErrors={errorsFromServer}
          deleteUserClickHandler={confirmModal.turnIn}
          allowedRoles={allowedRoles}
        />
      </Formik>
      <ConfirmModal
        isOpen={isConfirmDeleteModalOpen}
        title={`Are you sure you want to delete the user ${editUser?.firstName} ${editUser?.lastName}?`}
        subtitle="This action can not be undone."
        buttonTitle={{ confirm: 'Delete', refuse: 'Cancel' }}
        confirmHandler={deleteUser}
        refuseHandler={confirmModal.turnOff}
        onCloseModal={confirmModal.turnOff}
      />
    </>
  );
}
