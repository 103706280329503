export const AppStrings = {
    dataSuccessfullyUpdated: 'Data is successfully updated',
    errorNotification: 'Oops, something went wrong. Please try again.',
    // Registration
    registrationSuccess: 'Please enter the email and password',
    confirmationEmailSend: 'A confirmation email has been sent again.',
    // Reset password
    resetPasswordErr: 'Link expired, request a password reset again.',
    resetPasswordSuccess:
        'Your password was successfully reset. Please sign in with the newly set password.',
    resetPasswordResendSuccess: 'An email with reset link has been sent again.',
    // Accept invitation
    acceptInvitationErr:
        'Invitation link expired, please contact your dashboard administrator to receive a new link.',
    acceptInvitationSuccess:
        'Your password was successfully created. Please sign in with the newly set password.',
    // Auctions
    auctionSaveSuccess: 'Auction successfully saved!',
    auctionScheduleSuccess: 'Auction successfully scheduled!',
    auctionDeleteSuccess: 'Auction successfully deleted',
    auctionArchiveSuccess: 'Auction successfully archived',
    auctionUpdateSuccess: 'Auction successfully updated!',
    auctionUnscheduleSuccess: 'Auction successfully saved and unscheduled!',
    auctionDraftUpdateSuccess: 'Changes successfully saved!',
    auctionActiveUpdateSuccess: 'Changes successfully saved and published!',
    // Third party integrations
    mailChimpIntegrationSuccess: 'Mailchimp successfully integrated',
};

/**
 * The idea to start adding all the texts in frontend to this object
 * to be able easily switch on translation.
 *
 * now = {
 *  // signup
 *  login: 'Log in',
 *  signUpForgotPassword: 'Forgot password?',
 * }
 *
 * then = {
 * // signup
 *  login: 'login_string',
 *  signUpForgotPassword: 'sign_up_forgot_password',
 * }
 *
 * Where 'login_string' and 'sign_up_forgot_password' will be added in translation file.
 */

export const Appi18nStrings = {
    // Signup
    login: 'Log in',

    //welcome flow
    next: 'Next',
    passwordRequirements: {
        min: 'Min. 8 characters',
        oneUpperCase: 'One uppercase letter',
        oneLowerCase: 'One lowercase letter',
        oneNumber: 'One number character',
        oneSpecialCharacter: 'One special character',
    },

    // Picture uploader
    pictureUploader: {
        pictureIsLarger: 'Max. size for pictures is 1,5 MB',
        invalidType: 'Invalid file type. Please upload PNG, JPG, JPEG or WebP',
    },
};
