import { FC, useEffect, useContext } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { useAsyncDispatch } from 'helpers/hooks/useAsyncDispatch';
import { acceptInvitationCheckTokenThunk, signOutThunk } from '../../redux/thunks';
import { useAuthenticated } from 'units//common/hooks/useAuthenticated';
import { NotificationContext } from 'shared/providers';

type AcceptInvitationParams = {
  token: string,
}

export const AcceptInvitation: FC<RouteComponentProps<AcceptInvitationParams>> = ({
  match: { params: { token } }
}) => {
  const { showNotification } = useContext(NotificationContext);
  const { dispatch } = useAsyncDispatch();
  const isAuthenticated = useAuthenticated();

  useEffect(() => {
    (async() => {
      if (isAuthenticated) {
        await dispatch(signOutThunk(showNotification, { withoutRedirectToLogin: true }));
      }
      dispatch(acceptInvitationCheckTokenThunk(token, showNotification));
    })();
  }, [token]);

  return null;
};
