import styled from 'styled-components';

import { Color } from 'components/styles/constants.styles';
import { PrimaryButtonStyle } from 'components/styles/button.styles';

interface ButtonProps {
    isWide?: boolean;
}

export const SecondaryButtonSC = styled('button')<ButtonProps>`
    ${PrimaryButtonStyle};
    background-color: ${Color.watermelon};
    text-transform: none;
    min-width: 105px;
    font-size: 12px;
    line-height: 120%;

    &:hover {
        background-color: ${Color.watermelon_two};
    }

    ${({ isWide }) =>
        isWide &&
        `
    min-width: 185px;
  `}
`;
