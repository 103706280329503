import React from 'react';
import { Helmet } from 'react-helmet-async';

import { SupportSC } from './support.styles';
import { getImage, ImageName } from 'components/styles/images';
import { useTranslation } from 'react-i18next';

export const Support = () => {
    const { t } = useTranslation();

    const handleStartChat = () => {
        window.Tawk_API.showWidget();
        window.Tawk_API.maximize();
    };

    return (
        <SupportSC.Container>
            <Helmet
                script={[
                    {
                        type: 'text/javascript',
                        innerHTML: `
            var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
            (function(){
              var s1=document.createElement("script"), s0=document.getElementsByTagName("script")[0];
              s1.async=true;
              s1.src='https://embed.tawk.to/5fc5ee5b920fc91564cc3ec2/1eoejpnr3';
              s1.charset='UTF-8';
              s1.setAttribute('crossorigin','*');
              s0.parentNode.insertBefore(s1,s0);
            })();

            Tawk_API.onLoad = function() {
              Tawk_API.hideWidget();
            };
          `,
                    },
                ]}
            />
            <SupportSC.Content>
                <SupportSC.Image>{getImage(ImageName.support)}</SupportSC.Image>
                <SupportSC.MainBlock>
                    <SupportSC.Title>{t('customization_support_title')}</SupportSC.Title>
                    <SupportSC.Subtitle>{t('customization_support_subtitle')}</SupportSC.Subtitle>
                    <SupportSC.Button onClick={handleStartChat}>
                        {t('customization_support_button')}
                    </SupportSC.Button>
                </SupportSC.MainBlock>
            </SupportSC.Content>
        </SupportSC.Container>
    );
};
