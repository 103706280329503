import * as React from 'react';
import { Switch, Route, RouteComponentProps } from 'react-router-dom';

import { TemplatesPage } from './pages/index/templates.component';
import { TemplateEditPage } from './pages/edit/template-edit.component';
import { SettingsUrls } from '../../urls';

export const EmailTemplatesRoutes: React.FC<RouteComponentProps> = () => (
    <Switch>
        <Route exact path={SettingsUrls.templates.index} component={TemplatesPage} />
        <Route path={SettingsUrls.templates.edit} component={TemplateEditPage} />
    </Switch>
);
