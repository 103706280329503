import React, { FC } from 'react';

import { Modal, ModalGeneralProps } from '../modal.component';
import { ConfirmModalSC } from './confirm-modal.styles';
import { TransparentButton } from '../../buttons/transparent/transparent.component';
import { PrimaryButton } from '../../buttons/primary/primary.component';

export interface ConfirmModalProps extends ModalGeneralProps {
  subtitle?: string;
  noCancelButton?: boolean;
  buttonTitle: {
    confirm: string;
    refuse?: string;
  }
  confirmHandler: () => void;
  refuseHandler?: () => void;
}

// Have not tested with custom children as we don't have task for now.
export const ConfirmModal:FC<ConfirmModalProps> = ({
  confirmHandler,
  refuseHandler,
  buttonTitle,
  subtitle,
  children,
  noCancelButton,
  ...rest
}) => (
  <Modal
    titlePosition="center"
    {...rest}
  >
    <>
      { subtitle && (
        <ConfirmModalSC.Subtitle dangerouslySetInnerHTML={{__html: subtitle}} />
      )}
      { children }
      <ConfirmModalSC.ButtonsContainer>
        {!noCancelButton && (
          <TransparentButton type="button" onClick={refuseHandler}>
            { buttonTitle.refuse || 'Cancel' }
          </TransparentButton>
        )}
        <PrimaryButton type="button" onClick={confirmHandler}>
          { buttonTitle.confirm || 'Yes' }
        </PrimaryButton>
      </ConfirmModalSC.ButtonsContainer>
    </>
  </Modal>
);
