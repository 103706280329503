import {
    prepareCustomizationProgress as helpers,
    CMSProgressInitialSection,
    CMSUpdateFilledProgress,
    CMSCustomizationCommonProgress,
  } from '.';
  import { CMSCustomizationShopModalsSectionName, CMSCustomizationShopModalsProgressField } from '../../types';
  import { CMSShopModalsCustomizationSettings } from '../../store/types/customization/shop-modals';
  import { CompanyLanguages } from 'units/app/redux/types';
  import { CMSCustomizationOverallSettings } from '../../store/types/customization';
  
  // Alias
  type HP_SN = CMSCustomizationShopModalsSectionName;
  type HP_PK = CMSCustomizationShopModalsProgressField;
  
  export const preparShopModalsProgress = (
    overallSettings: CMSCustomizationOverallSettings<CMSShopModalsCustomizationSettings>,
  ) => {
    // Hold one language settings which gonna be used in common comparison.
    const commonSettings = Object.values(overallSettings)[0];
  
    if (!commonSettings) {
      return {};
    }
  
    const languages = Object.keys(overallSettings) as Array<CompanyLanguages>;
    // Holds sections with fields that is required for progress.
    const requiredProgress = helpers.initializeRequiredProgress<HP_SN, HP_PK>(shopModalsInitialSections);
    // Holds sections with fields that already filled based on overallSettings.
    const filledProgress = helpers.initializeFilledProgress<HP_SN, HP_PK>(shopModalsInitialSections.map((s) => ({
      ...s,
      initialProgress: [],
    })), languages);
  
    // Prefilled progress
    for (const [lng, lngSettings] of Object.entries(overallSettings)) {
      if (!lngSettings) {
        continue;
      }
  
      const language = lng as CompanyLanguages;
      const updates = prepareShopModalsFilledProgress(lngSettings);
      helpers.updateFilledProgress(filledProgress, language, updates);
    }
  
    return { sectionProgress: helpers.prepareSectionProgress(requiredProgress, filledProgress, commonFields) };
  }
  
  const prepareShopModalsFilledProgress = ({
      register, login1, login2, offerConfirmation, offerDenial, offerWon, resetPassword
    }: CMSShopModalsCustomizationSettings) => {
    const updates: Array<CMSUpdateFilledProgress<HP_SN, HP_PK>> = [];
  
    // Register section
    let sectionKeys: Array<HP_PK> = [];
  
    register.title && sectionKeys.push('title');
    register.button && sectionKeys.push('button');
    updates.push({ sectionName: 'register', progressKeys: sectionKeys });
  
    // Login1 section
    sectionKeys = [];
  
    login1.title && sectionKeys.push('title');
    login1.button && sectionKeys.push('button');
    updates.push({ sectionName: 'login1', progressKeys: sectionKeys });

    // Login2 section
    sectionKeys = [];
  
    login2.title && sectionKeys.push('title');
    login2.button && sectionKeys.push('button');
    updates.push({ sectionName: 'login2', progressKeys: sectionKeys });

    // Offer confirmation section
    sectionKeys = [];
  
    offerConfirmation.title && sectionKeys.push('title');
    offerConfirmation.bindingNotification && sectionKeys.push('bindingNotification');
    offerConfirmation.button && sectionKeys.push('button');
    updates.push({ sectionName: 'offerConfirmation', progressKeys: sectionKeys });

    // Offer denial section
    sectionKeys = [];
  
    offerDenial.title && sectionKeys.push('title');
    offerDenial.button && sectionKeys.push('button');
    updates.push({ sectionName: 'offerDenial', progressKeys: sectionKeys });

    // Offer won section
    sectionKeys = [];
  
    offerWon.title && sectionKeys.push('title');
    offerWon.button && sectionKeys.push('button');
    updates.push({ sectionName: 'offerWon', progressKeys: sectionKeys });

    // Reset password section
    sectionKeys = [];
  
    //resetPassword.mainContent && sectionKeys.push('mainContent');
    resetPassword.title && sectionKeys.push('title');
    resetPassword.button && sectionKeys.push('button');
    updates.push({ sectionName: 'resetPassword', progressKeys: sectionKeys });

    return updates;
  }
  
const shopModalsInitialSections: Array<CMSProgressInitialSection<HP_SN, HP_PK>> = [{
  name: 'general',
  initialProgress: [],
}, {
    name: 'register',
    initialProgress: ['title', 'button']
}, {
    name: 'login1',
    initialProgress: ['title', 'button']
}, {
    name: 'login2',
    initialProgress: ['title', 'button']
}, {
    name: 'offerConfirmation',
    initialProgress: ['title', 'bindingNotification', 'button']
}, {
    name: 'offerDenial',
    initialProgress: ['title', 'button']
}, {
    name: 'offerWon',
    initialProgress: ['title', 'button']
}, {
    name: 'resetPassword',
    initialProgress: ['title', 'button']
}];
  
export const commonFields: CMSCustomizationCommonProgress<HP_SN, HP_PK> = {
  general: [],
  register: [],
  login1: [],
  login2: [],
  offerConfirmation: [],
  offerDenial: [],
  offerWon: [],
  resetPassword: []
};

