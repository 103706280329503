export enum Font {
  regular = 'GT Walsheim Pro Regular',
  medium = 'GT Walsheim Pro Medium',
  bold = 'GT Walsheim Pro Bold',
  black = 'GT Walsheim Pro Black',
  manropeBold = 'Manrope Bold',
  yesevaOneRegular = 'YesevaOne Regular',
  mayanmarMNRegular = 'MyanmarMN Regular',
}

export enum UserRoleId {
  'Owner' = 1,
  'Admin' = 2,
  'User' = 3,
}
