import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { LPHome, LPPrivacyPolicy, LPTerms, LPImprint } from '../pages';
import { LandingPageUrls } from '../urls';

export const Router = () => (
    <Switch>
        <Route exact path={LandingPageUrls.landingPage}>
            <LPHome />
        </Route>
        <Route path={LandingPageUrls.privacyPolicy}>
            <LPPrivacyPolicy />
        </Route>
        <Route path={LandingPageUrls.termsAndConditions}>
            <LPTerms />
        </Route>
        <Route path={LandingPageUrls.imprintPage}>
            <LPImprint />
        </Route>
    </Switch>
)
