// sizes
export const sizes = {
  // font sizes
  base_font_size: '16px',
  // base line-height
  base_line_height: '16px',

  // layout
  xs_padding: '12px',
  sm_padding: '16px',
  md_padding: '24px',
  lg_padding: '32px',
  xlg_padding: '40px',

  xs_margin: '12px',
  sm_margin: '16px',
  md_margin: '24px',
  lg_margin: '32px',
  xlg_margin: '40px',

  // gutter: '15px',
  border_radius: '8px',
  // container_width: '1170px',
  grid_margin: '10px',

  modal_margin_top: '196px',
  modal_width: '613px',

  sidebar_width: '204px',
  cms_sidebar_width: '272px',

  content_width: '1148px',
};

// colors
export enum Color {
  black = '#000000',
  white = '#ffffff',
  transparent = 'transparent',
  transparent_hover = '#ebeeff',

  // grey
  grey = '#A9B1C0',
  bluey_grey = '#9da7b7',
  charcoal_grey = '#2b303a',
  pale_grey = '#f5f6fb',
  modal_overlay = 'rgba(78,78,78, 0.8)',
  disabled_bg_grey = 'rgba(128,128,128, 0.08)',
  disabled_text_grey = '#8c8c8c',

  // blue
  cloudy_blue = '#c1cbde',
  cloudy_blue_two = '#b6c3d9',
  lightish_blue = '#4b67f8',
  windows_blue = '#3768c8',

  // red
  watermelon = '#f85563',
  watermelon_two = '#ff525c',

  // semi transparent
  semi_grey = 'rgba(0, 0, 0, 0.08)',

  // alert
  success_green = '#3BB272',
  error_red = '#F85563',

  // orange
  orange = '#fd9d40',

  // lp
  lp_white = '#fefeff',

  // color picker
  cp_black = '#474747',
}

// misc
export const transition = '0.2s';
export const box_shadow = `2px 2px 15px ${Color.semi_grey}`;
