import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { CMSTabs } from '../../constants';
import { CMSTabSwitchSC } from './tab-switch.styles';
import { useCMSTabsNavigation } from '../../hooks/useCMSTabsNavigation';
/* import { ConfirmModal } from 'units/common/components/modal/confirm-modal/confirm-modal.component';
import { useCMSConfirmModalManager } from '../../hooks/useCMSConfirmModalManager'; */
import { CMSTab } from '../../types';
import { useCMSConfigPublishedStatus } from '../../hooks/selectors/useCMSConfigStatus';
import { userCompanySelector } from 'units/user/redux/reducer';
import { CustomDomainStatusEnum } from 'shared/types';
import { useTranslation } from 'react-i18next';

export const CMSTabSwitch = () => {
    const { selectedTab, navigateToTab } = useCMSTabsNavigation();
    /* const isProgressSaved = useCMSConfigSavedStatus(); */
    const isProgressPublished = useCMSConfigPublishedStatus();
    /*   const [confirmModalProps, confirmModal] = useCMSConfirmModalManager({
    ...CMSConfirmModalStrings.switchTabs,
  }); */

    const company = useSelector(userCompanySelector);

    const openStorefront = useCallback(() => {
        if (company) {
            window.open(
                `https://${
                    company.custom_domain &&
                    company.custom_domain_status === CustomDomainStatusEnum.Healthy
                        ? company.custom_domain
                        : company.actual_domain
                }`,
                '_blank',
            );
        }
    }, [company]);

    const handleTabClick = useCallback(
        (tab: CMSTab) => {
            if (selectedTab === tab) {
                return;
            }

            navigateToTab(tab);

            /*     if (isProgressSaved) {
      navigate();
    } else {
      confirmModal.openModal({
        confirmHandler: navigate,
      });
    } */
        },
        [navigateToTab, selectedTab],
    );

    const { t } = useTranslation();

    return (
        <>
            <CMSTabSwitchSC.LeftPart>
                <CMSTabSwitchSC.TabsContainer>
                    {CMSTabs.map(tab => (
                        <CMSTabSwitchSC.Tab
                            key={tab.id}
                            onClick={handleTabClick.bind(null, tab)}
                            active={tab.id === selectedTab.id}
                            highlighted={!isProgressPublished && tab.id === selectedTab.id}
                        >
                            {t(tab.title)}
                        </CMSTabSwitchSC.Tab>
                    ))}
                </CMSTabSwitchSC.TabsContainer>
                <CMSTabSwitchSC.DomainLink onClick={openStorefront}>
                    {t('customization_open_storefront')}
                </CMSTabSwitchSC.DomainLink>
            </CMSTabSwitchSC.LeftPart>
            {/*       <ConfirmModal {...confirmModalProps} /> */}
        </>
    );
};
