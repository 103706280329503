import clsx from 'clsx';
import React from 'react';
import styles from './Card.module.scss';

interface CardProps {
    className?: string;
}

export const Card: React.FC<CardProps> = ({ children, className }) => {
    return <div className={clsx(styles.card, className)}>{children}</div>;
};
