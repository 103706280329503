import React from 'react';

import { FormInputSC } from './form-input.styles';

export interface IBaseFormInputLayout {
  label?: string;
  subLabel?: string;
  error?: string;
  hideError?: boolean;
  withMargins?: boolean;
  widthPercents?: number;
  paddingBottom?: string;
  disabled?: boolean;
}

export const BaseFormInputLayout: React.FC<IBaseFormInputLayout> = ({
  label,
  subLabel,
  error,
  hideError,
  withMargins,
  widthPercents,
  paddingBottom,
  disabled,
  ...props
}) => (
  <FormInputSC.InputContainer
    withMargins={withMargins}
    widthPercents={widthPercents || 100}
    paddingBottom={paddingBottom}
    disabled={disabled}
  >
    {label && (
      <FormInputSC.LabelContainer>
        <FormInputSC.Label>{label}</FormInputSC.Label>
        { subLabel && <FormInputSC.SubLabel>{subLabel}</FormInputSC.SubLabel> }
      </FormInputSC.LabelContainer>
    )}
    { props.children }
    {(!hideError && error) && <FormInputSC.Error>{error}</FormInputSC.Error>}
  </FormInputSC.InputContainer>
);
