import {
  EditProductGeneralInfo,
  EditProductSeo,
  EditProductUpdateFormOptions,
  EditProductSavedPicture,
  EditProductLanguageDescriptions,
  EditProductForm,
  EditProductCommonFields,
  EditProductError,
} from './types';
import * as actionTypes from './action-types';
import { CompanyLanguages } from 'units//app/redux/types';

export const editProductUpdateLanguageTabForm = (
  data: EditProductGeneralInfo | EditProductSeo,
  options: EditProductUpdateFormOptions,
) => ({ type: actionTypes.EDIT_PRODUCT_UPDATE_LANGUAGE_TAB_FORM, data, options } as const);

export const editProductInitializeReducer = (
  productLanguages: Array<CompanyLanguages>,
) => ({ type: actionTypes.EDIT_PRODUCT_INITIALIZE_REDUCER, productLanguages } as const);

export const editProductClearReducer = () => ({ type: actionTypes.EDIT_PRODUCT_CLEAR_REDUCER } as const);

export const editProductUpdatePictures = (
  pictures: Array<File | EditProductSavedPicture>,
) => ({ type: actionTypes.EDIT_PRODUCT_UPDATE_PICTURES, pictures } as const);

export const editProductUpdateCommonFields = (
  data: Partial<EditProductCommonFields>,
) => ({ type: actionTypes.EDIT_PRODUCT_UPDATE_COMMON_FIELDS, data } as const);

export const populateFormData = (
  data: EditProductForm,
) => ({ type: actionTypes.EDIT_PRODUCT_POPULATE_API_DATA, data } as const);

export const addProductImageToRemove = (
  id:string
) => ({ type: actionTypes.EDIT_PRODUCT_MARK_IMAGE_TO_REMOVE, id } as const);

export const editProductAddDescriptionsFieldset = (
  newQuantity: number,
  updateDescriptionProgress: (descriptions: EditProductLanguageDescriptions) => void,
) => ({ type: actionTypes.EDIT_PRODUCT_ADD_DESCRIPTIONS_FIELDSET, newQuantity, updateDescriptionProgress } as const);

export const editProductRemoveDescriptionsByIndex = (
  index: number,
  updateDescriptionProgress: (descriptions: EditProductLanguageDescriptions) => void,
) => ({ type: actionTypes.EDIT_PRODUCT_REMOVE_DESCRIPTIONS_FIELDSET_BY_INDEX, index, updateDescriptionProgress } as const);

export const editProductIsLoaded = () => ({ type: actionTypes.EDIT_PRODUCT_IS_LOADED } as const);

export const setEditProductError = (error: EditProductError) => ({ type: actionTypes.EDIT_PRODUCT_SET_ERROR, error } as const);

export const setIsPublished = (isPublished: boolean) => ({ type: actionTypes.EDIT_PRODUCT_SET_IS_PUBLISHED, isPublished } as const);
