import styled from 'styled-components';

import { sizes } from 'components/styles/constants.styles';
import { PageTitleText } from 'components/styles/text.styles';

export const LoadingSC = styled.h1`
  margin: 0;
  padding: ${sizes.lg_padding} 0;

  ${PageTitleText}
`;
