import React, { useMemo } from 'react';
import clsx from 'clsx';

import styles from './Typography.module.scss';

type TypographyProps = {
  className?: string;
};

type TitleProps = {
  htmlElement: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
};

type TextProps = {
  wysiwyg?:string;
};

const Title: React.FC<TypographyProps & TitleProps> = ({ className, htmlElement, children }) => (
  React.createElement(htmlElement, { className: clsx(styles.title, className) }, children)
)

const Text: React.FC<TypographyProps & TextProps> = ({
  className,
  children,
  wysiwyg
}) => {
  const classNames = useMemo(() => clsx(styles.text, className), [className]);

  if (wysiwyg) return <div className={clsx(classNames, styles.wysiwyg)} dangerouslySetInnerHTML={{ __html: wysiwyg }} />

  return (
    <p className={classNames}>
      {children}
    </p>
  );
};

export const Typography = {
    Title,
    Text,
};
