import { useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';

import { useCMSActiveTheme } from './selectors/useCMSActiveTheme';
import { useAsyncDispatch } from 'helpers/hooks';
import { cmsSelectTheme, cmsSelectActiveTheme } from '../store/action-creators';
import { CMSThemeId } from '../store/types';
import { saveCMSSelectedThemeThunk } from '../store/thunks';
import { useCMSSelectedTheme } from './selectors/useCMSSelectedTheme';
import { useCMSAvailableThemes } from './selectors/useCMSAvailableThemes';
import { DEFAULT_THEME_ID } from '../store/constants';
import { NotificationContext } from 'shared/providers';

export const useCMSThemeManager = () => {
  const { showNotification } = useContext(NotificationContext);
  const { dispatch: asyncDispatch } = useAsyncDispatch();
  const dispatch = useDispatch();
  const availableThemes = useCMSAvailableThemes();
  const activeTheme = useCMSActiveTheme();
  const selectedTheme = useCMSSelectedTheme();

  const handleChangeSelectedTheme = useCallback(
    (themeId: CMSThemeId) => dispatch(cmsSelectTheme(themeId)),
    [],
  );

  const handleSelectDefaultTheme = useCallback(() => {
    dispatch(cmsSelectTheme((availableThemes.find(t => t.id === DEFAULT_THEME_ID) || availableThemes[0]).id));
  }, [availableThemes]);

  const handleSaveSelectedTheme = useCallback(
    () => asyncDispatch(saveCMSSelectedThemeThunk(showNotification)),
    [],
  );

  const handleSelectActiveTheme = useCallback(
    () => dispatch(cmsSelectActiveTheme()),
    [],
  );

  return {
    availableThemes,
    activeTheme,
    selectedTheme,
    selectTheme: handleChangeSelectedTheme,
    selectDefaultTheme: handleSelectDefaultTheme,
    selectActiveTheme: handleSelectActiveTheme,
    saveSelectedTheme: handleSaveSelectedTheme,
  };
};
