import clsx from 'clsx';
import React from 'react';

import styles from './StatusBar.module.scss';

type StatusBarProps = {
    breakpoints: number[];
};

export const StatusBar: React.FC<StatusBarProps> = ({ breakpoints }) => {
    return (
        <div className={styles.section}>
            <div
                className={clsx(styles.bar, styles['is-blue'])}
                style={{ width: `${breakpoints[0]}%` }}
            ></div>
            <div
                className={clsx(styles.bar, styles['is-red'])}
                style={{ width: `${breakpoints[1]}%` }}
            ></div>
            <div
                className={clsx(styles.bar, styles['is-orange'])}
                style={{ width: `${breakpoints[2]}%` }}
            ></div>
        </div>
    );
};
