const index = '/settings';
const payment = `${index}/payment`;
const domain = `${index}/domain`;
const third_party = `${index}/third_party`;
const account = `${index}/account`;
const language = `${index}/language`;

const users = {
  index: `${index}/users`,
  userEdit: `${index}/users/:userId`,
};

const templates = {
  index: `${index}/email-templates`,
  edit: `${index}/email-templates/:templateId`,
}

const billing = `${index}/billing`;

export const SettingsUrls = {
  index,
  payment,
  domain,
  third_party,
  templates,
  account,
  users,
  billing,
  language,
};
