import { useState, useCallback, useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { CompanyLanguages } from 'units/app/redux/types';
import { useCompanyLanguages } from 'units/app/hooks/selectors/useCompanyLanguages';
import { cmsUpdateSelectedLanguage } from 'units/cms/store/action-creators';

type Hook = (initialLanguage?: CompanyLanguages) => [
  CompanyLanguages,
  { changeLanguage: (lng: CompanyLanguages) => void;
    selectDefaultLanguage: () => void;
    defaultLanguage: CompanyLanguages;
  }
];

export const useCMSCustomizationLanguage: Hook = (initialLanguage) => {
  const dispatch = useDispatch();
  const languages = useCompanyLanguages();
  const [selectedLanguage, setSelectedLanguage] = useState(initialLanguage || languages[0]);

  const defaultLanguage = useMemo(() => initialLanguage || languages[0], [initialLanguage, languages])

  useEffect(() => {
    dispatch(cmsUpdateSelectedLanguage(selectedLanguage));
  }, [selectedLanguage])

  const changeLanguage = useCallback((language: CompanyLanguages) => {
    if (selectedLanguage !== language) {
      setSelectedLanguage(language);
    }
  }, [selectedLanguage]);

  const selectDefaultLanguage = useCallback(() => {
    changeLanguage(defaultLanguage);
  }, [changeLanguage, languages, initialLanguage]);

  return [selectedLanguage, { changeLanguage, selectDefaultLanguage, defaultLanguage }];
}
