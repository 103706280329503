import React from 'react';

/* eslint-disable import/named */
import { FlattenSimpleInterpolation } from 'styled-components';
/* eslint-enable import/named */

import { LPContainerSC } from './container.styles';

type ContainerProps = {
    styles?:FlattenSimpleInterpolation;
};

export const Container: React.FC<ContainerProps> = ({ children, styles }) => (
    <LPContainerSC.Root styles={styles}>{children}</LPContainerSC.Root>
)
