import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';

import styles from './SlideDropdown.module.scss';

type SlideDownDropdownProps = {
    className?: string;
    position?: 'left' | 'center' | 'right';
    content: React.ReactNode;
};

export const SlideDropdown: React.FC<SlideDownDropdownProps> = ({
    className,
    content,
    position = 'center',
    children,
}) => {
    const [height, setHeight] = useState<number>(0);
    const rootRef = useRef<HTMLDivElement | null>(null);
    const [showDropdown, setShowDropdown] = useState(false);

    useEffect(() => {
        if (rootRef.current) {
            setHeight(rootRef.current.clientHeight);
        }
    }, []);

    return (
        <div
            ref={rootRef}
            className={clsx(styles.root, className, styles[`is-${position}`], {
                [styles['is-active']]: showDropdown,
            })}
            onMouseEnter={setShowDropdown.bind(null, true)}
            onMouseLeave={setShowDropdown.bind(null, false)}
        >
            <div className={styles.label} onClick={setShowDropdown.bind(null, !showDropdown)}>
                {children}
            </div>
            <div className={styles.dropdown} style={{ top: height }}>
                {content}
            </div>
        </div>
    );
};
