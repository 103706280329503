import React, { FC, ChangeEvent } from 'react';

import { ProductListSC } from './product-list.styles';
import { ProductItem } from '../product-item/product-item.component';
import { Pagination } from 'units/common/components/pagination/pagination.component';
import { BaseFormInput } from 'units/common/components/form/fields/form-input/base-form-input.component';
import { Product } from '../../../api/types';
import { getImage, ImageName } from 'components/styles/images';
import { PrimaryButton } from 'units/common/components/buttons/primary/primary.component';

interface Props {
    paseSize: number;
    productCount: number;
    data: Array<Product>;
    search: string;
    setSearch: (search: string) => void;
    deleteProduct: (id: string) => void;
    setCurrentPage: (page: number) => void;
}

export const ProductList: FC<Props> = ({
    data,
    paseSize,
    productCount,
    deleteProduct,
    setCurrentPage,
    setSearch,
    search,
}) => {
    const onChange = (event: ChangeEvent<HTMLInputElement>) => setSearch(event.currentTarget.value);
    const onClear = () => setSearch('');
    return (
        <ProductListSC.Wrapper>
            <ProductListSC.Search>
                <BaseFormInput
                    value={search}
                    name="search"
                    placeholder="Search products"
                    widthPercents={50}
                    onChange={onChange}
                    onClear={!!search ? onClear : undefined}
                />
            </ProductListSC.Search>

            {productCount ? (
                <ProductListSC.Container>
                    {data.map(item => (
                        <ProductItem key={item.id} {...item} deleteProduct={deleteProduct} />
                    ))}
                </ProductListSC.Container>
            ) : (
                <ProductListSC.NoItemsContainer>
                    <div>{getImage(ImageName.empty_box)}</div>
                    <p>No products matching your search</p>
                    <PrimaryButton onClick={onClear}>Go back</PrimaryButton>
                </ProductListSC.NoItemsContainer>
            )}

            <Pagination
                pageSize={paseSize}
                // itemsCount={productCount}
                pageCount={Math.ceil(productCount / paseSize)}
                onPageChange={page => setCurrentPage(page.selected + 1)}
            />
        </ProductListSC.Wrapper>
    );
};
