import styled from 'styled-components';

import { PageTitleText, TextStyle8 } from 'components/styles/text.styles';
import { sizes } from 'components/styles/constants.styles';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: ${sizes.cms_sidebar_width};
  margin-top: 32px;
  margin-left: 40px;
`;

const LogoWrapper = styled.div`
  width: 92px;
  margin-bottom: 8px;

  img {
    width: 100%;
  }
`;

const BackButtonWrapper = styled.div`
  margin-bottom: 16px;
`;

const SidebarTitle = styled.h1`
  margin-bottom: 24px;
  
  ${PageTitleText}
`;

const SidebarSubTitle = styled.p`
  margin-top: 8px;

  ${TextStyle8}
`;

export const CMSSidebarSC = {
  Root,
  LogoWrapper,
  BackButtonWrapper,
  SidebarTitle,
  SidebarSubTitle,
};
