import styled from 'styled-components';

const InputWrapper = styled.div`
  width: 100%;
  margin-bottom: 40px;
`;

export const InitResetPasswordSC = {
  InputWrapper,
};
