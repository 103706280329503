import styled from 'styled-components';

import { sizes, Color } from 'components/styles/constants.styles';
import { TextStyle8 } from 'components/styles/text.styles';

const StatusContainer = styled.div`
  height: 14px;
  margin-bottom: ${sizes.sm_margin};
`;

const StatusMark = styled.p<{ highlighted?: boolean }>`
  ${TextStyle8}

  line-height: 1;

  ${({ highlighted }) => highlighted && `
    color: ${Color.watermelon};
  `}
`;

const TabsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: ${sizes.md_margin};
  justify-content: space-between;
`;

export const CMSHeaderSC = {
  StatusContainer,
  StatusMark,
  TabsWrapper,
};
