import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Table } from 'units/common/components/table/table.component';
import { LayoutSC } from 'units/common/components/layout/layout-components.styles';
import { SettingsUrls } from '../../../../urls';
import { BackButton } from '../../../../../common/components/back-button/back-button.component';
import { TemplatesService } from '../../api';
import { Preloader } from '../../../../../../shared/components/ui';
import { EmailTemplateListType } from '../../types';
import { UserEditSC } from './templates.styles';
import { Link } from 'react-router-dom';
import styles from './Templates.module.scss';

const columns = [
    {
        Header: 'Title',
        accessor: 'subject',
    },
    {
        Header: 'Description',
        accessor: 'body',
    },
    {
        Header: 'State',
        accessor: 'state',
    },
    {
        Header: '',
        accessor: 'id',
    },
];

const prepareData = (data: any) =>
    data.map((item: any) => ({
        ...item,
        state: <UserEditSC.StatusComponent>{item.state}</UserEditSC.StatusComponent>,
        id: (
            <UserEditSC.LinkComponent>
                <Link to={`${SettingsUrls.templates.index}/${item.id}`}>Edit</Link>
            </UserEditSC.LinkComponent>
        ),
    }));

export const TemplatesPage = () => {
    const { t } = useTranslation();
    const [templates, setTemplates] = useState<EmailTemplateListType>([]);
    const [isLoading, setIsLoading] = useState(true);

    const loadTemplates = async () => {
        const { data } = await TemplatesService.getAll();
        setIsLoading(false);
        setTemplates(prepareData(data));
    };

    useEffect(() => {
        loadTemplates();
    }, []);

    if (isLoading) return <Preloader.Page height={600} />;

    return (
        <>
            <Helmet>
                <title>{t('templates_page_title')}</title>
            </Helmet>
            <BackButton destinationText={'Settings'} to={SettingsUrls.index} />
            <LayoutSC.PageTitle smallPadding={true}>{t('templates_page_title')}</LayoutSC.PageTitle>
            <div className={styles.table}>
                <Table columns={columns} data={templates} />
            </div>
        </>
    );
};
