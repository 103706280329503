import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Color } from '../../components/styles/constants.styles';
import { IconSC, IconUnicodes } from 'components/styles/icons.styles';
import { EllipsisProps, Ellipsis , fonts } from 'components/styles/helpers.styles';
import { LayoutSC } from '../common/components/layout/layout-components.styles';


const Regular = styled.div`
  font-family: ${fonts.regular};
`;

const Medium = styled.div`
  font-family: ${fonts.medium};
`;

const Bold = styled.div`
  font-family: ${fonts.bold};
`;

const Black = styled.div`
  font-family: ${fonts.black};
`;

const EllipsisExample = styled('div')<EllipsisProps>`
  max-width: 200px;
  border: 1px solid #000;
  padding: 10px;

  ${Ellipsis}
`;

export const DevPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('development_page_tite')}</title>
      </Helmet>
      <LayoutSC.PageTitle>{t('development_page_tite')}</LayoutSC.PageTitle>
      <div className="fonts">
        <Regular>The quick brown fox jumps over the lazy dog</Regular>
        <Medium>The quick brown fox jumps over the lazy dog</Medium>
        <Bold>The quick brown fox jumps over the lazy dog</Bold>
        <Black>The quick brown fox jumps over the lazy dog</Black>
      </div>
      <div className="icons">
        <IconSC color={Color.bluey_grey} name={IconUnicodes.eye_off} />
        <IconSC color={Color.bluey_grey} name={IconUnicodes.eye} />
        <IconSC color={Color.watermelon} name={IconUnicodes.minus_circle_filled} />
        <IconSC color={Color.windows_blue} name={IconUnicodes.check_circle_filled} />
        <IconSC name={IconUnicodes.search} size={20} />
        <IconSC name={IconUnicodes.angle_down} />
        <IconSC name={IconUnicodes.angle_up} />
        <IconSC name={IconUnicodes.angle_right} />
        <IconSC name={IconUnicodes.angle_left} />
        <IconSC name={IconUnicodes.squares_filled} />
        <IconSC name={IconUnicodes.monitor} />
        <IconSC name={IconUnicodes.items} />
        <IconSC name={IconUnicodes.hammer} />
        <IconSC name={IconUnicodes.cart} />
        <IconSC name={IconUnicodes.donut_chart} />
        <IconSC name={IconUnicodes.settings} />
      </div>
      <EllipsisExample nowrap>The quick brown fox jumps over the lazy dog</EllipsisExample>
    </>
  );
};
