import React, { FC } from 'react';

import { Form, useFormikContext, FormikValues } from 'formik';

import { getImage, ImageName } from 'components/styles/images';
import { SharedSC } from '../welcome-shared.styles';
import { ProgressSteps } from 'units/common/components/progress-steps/progress-steps.component';
import { LanguagesSC } from 'units/common/components/checkbox/choose-languages-checkboxes/choose-languages.styles';
import { Checkbox } from 'units/common/components/checkbox/choose-languages-checkboxes/choose-languages-checkboxes';
import { languages } from 'units/app/constants';

interface Props {
  back(goBack: boolean): any;
}

export const ChooseLanguage: FC<Props> = ({ back }) => {
  const { values } = useFormikContext<FormikValues>();
  const isDisabled = !!values.languages?.length;

  return (
    <SharedSC.Wrapper>
      {getImage(ImageName.shuttle)}
      <SharedSC.PageTitle>We are almost there!</SharedSC.PageTitle>
      <SharedSC.PageSubtitle margin="0 0 40px 0">
        In order to set up your storefront settings, we need to know one additional thing.
      </SharedSC.PageSubtitle>

      <LanguagesSC.LanguageForm as={Form}>
        <LanguagesSC.CheckboxTitle>
          What languages do you want to support in your shop?
        </LanguagesSC.CheckboxTitle>

        <Checkbox languages={languages} />

        <SharedSC.ButtonContainer>
          <SharedSC.WelcomeTransparentButton onClick={() => back(true)}>
            Back
          </SharedSC.WelcomeTransparentButton>
          <SharedSC.WelcomePrimaryButton type="submit" disabled={!isDisabled}>
            Next
          </SharedSC.WelcomePrimaryButton>
        </SharedSC.ButtonContainer>
      </LanguagesSC.LanguageForm>

      <SharedSC.PageSubtitle margin="96px 0 32px 0">
        You are already logged in. Only 2 simple steps left to start your work.
      </SharedSC.PageSubtitle>
      <ProgressSteps step={2} />
    </SharedSC.Wrapper>
  );
};
