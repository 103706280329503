import {
    CreateObjectServerAvailabilityType,
    CreateObjectServerPropertyType,
    CreateObjectServerType,
    ObjectFormValuesType,
    ObjectPreviewItemType,
    ObjecyFormLangType,
} from 'shared/types';

import moment from 'moment';

const prepareObjectType = (type: string): CreateObjectServerPropertyType => {
    switch (type) {
        case 'investment':
            return 'Investment Property';
        case 'condominium':
            return 'Condominium';
        case 'single':
            return 'Single Family House';
        default:
            return 'Investment Property';
    }
};

const prepareClientObjectType = (type: CreateObjectServerPropertyType): string => {
    switch (type) {
        case 'Investment Property':
            return 'investment';
        case 'Condominium':
            return 'condominium';
        case 'Single Family House':
            return 'single';
        default:
            return 'Investment Property';
    }
};

const prepareObjectAvailability = (type: string): CreateObjectServerAvailabilityType => {
    switch (type) {
        case 'immediately':
            return 'now';
        case 'byAgreement':
            return 'agreement';
        case 'select':
            return 'planned';
        default:
            return 'now';
    }
};

const prepareObjectClientAvailability = (type: CreateObjectServerAvailabilityType): string => {
    switch (type) {
        case 'now':
            return 'immediately';
        case 'agreement':
            return 'byAgreement';
        case 'planned':
            return 'select';
        default:
            return 'now';
    }
};

const prepareServerLangObject = ({
    name,
    description,
    seoDescription,
    seoTitle,
}: ObjecyFormLangType) => ({
    product_name: name,
    product_catch_phrase: 'cool',
    description: [
        {
            description_type: 'string',
            description_body: description,
        },
    ],
    SEO: {
        page_title: seoTitle,
        page_body: seoDescription,
    },
});

const prepareServerObject = (data: ObjectFormValuesType): FormData => {
    const langData: any = {};
    const formData = new FormData();
    const result: CreateObjectServerType = {
        property_type: prepareObjectType(data.type),
        post_code: Number(data.postCode),
        availability: prepareObjectAvailability(data.availability),
        city: data.city,
        street_and_number: data.address,
        product_info: langData,
        parking: false,
        construction_year: Number(data.constructionYear),
        shipping_cost: 5,
        quantity: 1,
    };

    if (data.type === 'investment') {
        result.living_space = Number(data.area);
        result.rental_income_per_year = Number(data.rentalPerYear);
        result.gross_yield = Number(data.grossYield);
    }

    if (data.type === 'condominium') {
        result.living_space = Number(data.livingArea);
        result.floor = Number(data.floor);
        result.number_of_rooms = Number(data.roomsNumber);
        result.parking = data.parking;
    }

    if (data.type === 'single') {
        result.plot_size = Number(data.plotSize);
        result.number_of_rooms = Number(data.roomsNumber);
        result.living_space = Number(data.livingSpace);
    }

    if (data.de) {
        langData.de = prepareServerLangObject(data.de);
    }
    if (data.en) {
        langData.en = prepareServerLangObject(data.en);
    }
    if (data.it) {
        langData.it = prepareServerLangObject(data.it);
    }
    if (data.fr) {
        langData.fr = prepareServerLangObject(data.fr);
    }

    if (data.attachment && data.attachment instanceof File) {
        formData.append('pdf', data.attachment);
    }

    if (result.availability === 'planned') {
        result.available_at = moment(data.availabilityDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
    }

    for (const [key, value] of Object.entries(result)) {
        formData.append(key, key === 'product_info' ? JSON.stringify(value) : value);
    }

    return formData;
};

const prepareObjectPreview = (data: any): ObjectPreviewItemType => ({
    id: data.id,
    city: data.city,
    image: data.images[0]?.image || null,
    name: data.name,
    postCode: data.post_code,
    type: data.property_type,
    address: data.street_and_number,
});

const prepareObjectClientData = ({
    post_code,
    property_type,
    living_space,
    construction_year,
    pdf,
    availability,
    floor,
    gross_yield,
    street_and_number,
    city,
    plot_size,
    rental_income_per_year,
    parking,
    number_of_rooms,
    available_at,
    product_info,
    images,
}: any): ObjectFormValuesType => {
    return {
        postCode: post_code || '',
        type: prepareClientObjectType(property_type),
        area: living_space || '',
        constructionYear: construction_year || '',
        attachment: pdf,
        availabilityDate:
            availability === 'now' || availability === 'agreement'
                ? ''
                : moment(available_at).format('DD-MM-YYYY') || '',
        availability: prepareObjectClientAvailability(availability),
        floor: floor || '',
        grossYield: gross_yield || '',
        livingArea: living_space || '',
        roomsNumber: number_of_rooms || '',
        livingSpace: living_space || '',
        parking: parking,
        rentalPerYear: rental_income_per_year || '',
        plotSize: plot_size || '',
        photos: images || [],
        city: city || '',
        address: street_and_number || '',
        en: {
            name: product_info?.en?.product_name || '',
            description: product_info?.en?.description[0]?.description_body || '',
            seoTitle: product_info?.en?.SEO?.page_title || '',
            seoDescription: product_info?.en?.SEO?.page_body || '',
        },
        de: {
            name: product_info?.de?.product_name || '',
            description: product_info?.de?.description[0]?.description_body || '',
            seoTitle: product_info?.de?.SEO?.page_title || '',
            seoDescription: product_info?.de?.SEO?.page_body || '',
        },
        fr: {
            name: product_info?.fr?.product_name || '',
            description: product_info?.fr?.description[0]?.description_body || '',
            seoTitle: product_info?.fr?.SEO?.page_title || '',
            seoDescription: product_info?.fr?.SEO?.page_body || '',
        },
        it: {
            name: product_info?.it?.product_name || '',
            description: product_info?.it?.description[0]?.description_body || '',
            seoTitle: product_info?.it?.SEO?.page_title || '',
            seoDescription: product_info?.it?.SEO?.page_body || '',
        },
    };
};

export const ObjectMapper = {
    prepareServerObject,
    prepareObjectPreview,
    prepareObjectClientData,
};
