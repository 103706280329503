import { useState, useCallback } from 'react';

import { CompanyLanguages } from 'units/app/redux/types';


type ProductErrors = {
  [key in CompanyLanguages | string]: number | null;
}

type UpdateLanguagesValidation = (haveErrors:boolean, language:CompanyLanguages, tab:number | null) => void;

export const useProductValidation = (): [
    Array<ProductErrors>,
    UpdateLanguagesValidation
] => {
  const [validationProblems, setValidationProblems] = useState<Array<ProductErrors>>([]);

  const updateLanguagesValidation = useCallback((haveErrors:boolean, language:CompanyLanguages, tab:number | null = null) => {
    let updatedValidation:Array<ProductErrors> = [];

    if(haveErrors) {
      updatedValidation = [...validationProblems, ...[{[language]: tab || null }]]
    } else {
      updatedValidation = validationProblems.filter(item => !(Object.keys(item)[0] === language && item[language] === tab));
    }

    setValidationProblems(updatedValidation);
  }, [validationProblems])

  return [validationProblems, updateLanguagesValidation];
};
