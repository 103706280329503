import * as React from 'react';
import { Switch, Route, RouteComponentProps } from 'react-router-dom';

import { ProductsUrls } from './urls';
import { ProductsPage } from './pages/index/products.component';
import { EditProductPage } from './pages/product/product.component';

export const ProductsRoutes: React.FC<RouteComponentProps> = () => (
  <Switch>
    <Route exact path={ProductsUrls.index} component={ProductsPage} />
    <Route path={ProductsUrls.addProduct} exact component={EditProductPage} />
    <Route path={ProductsUrls.updateProduct} exact component={EditProductPage} />
  </Switch>
);
