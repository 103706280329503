import React, { useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';

import { useCMSCustomizationPageNavigation } from 'units//cms/hooks/useCMSCustomizationPageNavigation';
import { CMSCustomizationHomepageSidebar } from './homepage';
import { CMSCustomizationPage } from 'units//cms/store/types';
import { CMSCustomizationMissedAuctionsPage } from './missed-auctions';
import { CMSCustomizationProductDetailPage } from './product-detail';
import { CMSCustomizationShopModalsPage } from './shop-modals';
import { useCMSIsCustomizationConfigLoaded } from 'units//cms/hooks/selectors/useCMSIsCustomizationConfigLoaded';

export const CMSCustomizationSidebar = () => {
    const { selectedPage, resetSelectedPage } = useCMSCustomizationPageNavigation();
    const isConfigLoaded = useCMSIsCustomizationConfigLoaded();

    useEffect(() => {
        resetSelectedPage();
    }, []);

    //fix me

    const renderCustomizationPageSidebar = useCallback(() => {
        switch (selectedPage) {
            case CMSCustomizationPage.Home:
                return <CMSCustomizationHomepageSidebar />;
            case CMSCustomizationPage.MissedAuctions:
                return <CMSCustomizationMissedAuctionsPage />;
            case CMSCustomizationPage.ProductDetail:
                return <CMSCustomizationProductDetailPage />;
            case CMSCustomizationPage.ShopModals:
                return <CMSCustomizationShopModalsPage />;
            default:
                return <CMSCustomizationHomepageSidebar />;
        }
    }, [selectedPage]);

    return (
        <>
            <Helmet>
                <title>CMS - Customization</title>
            </Helmet>
            {isConfigLoaded ? renderCustomizationPageSidebar() : <div>Loading...</div>}
        </>
    );
};
