import styled from 'styled-components';

import { Color } from 'components/styles/constants.styles';

const OverviewMock = styled.img`
  width: 100%;
`;

const OverviewContainer = styled.div`
  position: relative;
  flex: 1;

  iframe {
    width: 100%;
    height: 100%;
  }
`;

const OverviewLoading = styled.div`
  height: 100%;
  background-color: ${Color.white};
`;

const OverviewLoadingCover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;

  opacity: 0.65;
  background-color: ${Color.white};
`;

export const CMSOverviewSC = {
  OverviewMock,
  OverviewContainer,
  OverviewLoading,
  OverviewLoadingCover,
};
