import React, { FC } from 'react';
import { Form, useFormikContext } from 'formik';
import { useSelector } from 'react-redux';

import { AccountSC } from '../account.styles';
import { FormikFormInput } from 'units/common/components/formik/input/form-input.component';
import { UpdateUserAccountData, UserState } from 'units/user/redux/types';
import { UserErrorKeys } from 'units/user/constants';
import { RootState } from 'redux/root-types';
import { useIsSubmitEnabled } from 'units/common/hooks/useIsSubmitEnabled';
import { useFormikFieldErrors } from 'units/common/hooks/useFieldErrors';
import { useFormikApiErrors } from 'units/common/components/formik/hooks/useFormikApiErrors';

interface Props {
  allowEditCompany: boolean;
  allowEditEmail: boolean;
}

export const AccountForm: FC<Props> = ({ allowEditCompany = false, allowEditEmail = false }) => {
  const { values, errors } = useFormikContext<UpdateUserAccountData>();
  const { error: userError } = useSelector<RootState, UserState>((state) => state.user);

  const apiErrors = useFormikApiErrors(userError?.[UserErrorKeys.updateMyAccountData]);
  const fieldError = useFormikFieldErrors<UpdateUserAccountData>({ additionalErrors: apiErrors });
  const isSubmitEnabled = useIsSubmitEnabled(errors, values, { notRequired: ['phone']});

  return (
    <AccountSC.FormElement as={Form} className="test">
      <FormikFormInput
        name="firstName"
        label="First name"
        placeholder="Enter your first name"
        withMargins
        widthPercents={50}
        error={fieldError.firstName}
      />
      <FormikFormInput
        name="lastName"
        label="Last name"
        placeholder="Enter your last name"
        withMargins
        widthPercents={50}
        error={fieldError.lastName}
      />
      <FormikFormInput
        name="email"
        label="Email"
        placeholder="Enter your email"
        type="email"
        withMargins
        widthPercents={50}
        disabled={!allowEditEmail}
      />
      <FormikFormInput
        name="phone"
        label="Phone (optional)"
        placeholder="Enter your phone number"
        withMargins
        widthPercents={50}
        error={fieldError.phone}
      />
      <FormikFormInput
        name="companyName"
        label="Company name"
        placeholder="Enter your company name"
        withMargins
        error={fieldError.companyName}
        disabled={!allowEditCompany}
      />
      <AccountSC.SaveButton type="submit" disabled={!isSubmitEnabled}>Save</AccountSC.SaveButton>
    </AccountSC.FormElement>
  )
}
