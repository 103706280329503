import React, { FC, useState, useEffect } from 'react';

import { CheckEmailSC } from './check-email.styles';
import { AuthSC } from 'units/common/styles/auth/auth.styles';

type CheckEmailTexts = {
  title: string,
  subTitle: string,
  CTA: string,
}

interface Props {
  texts: CheckEmailTexts,
  resendClickHandler?: () => void,
  CTAClickHandler: () => void,
}

export const CheckEmail: FC<Props> = ({ texts, resendClickHandler, CTAClickHandler }) => {
  const [countdown, setCountdown] = useState(20);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (countdown > 0) {
        setCountdown(countdown - 1);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [countdown]);

  return (
      <AuthSC.AuthPageContainer mobileMarginTop="60px" tabletMarginTop="64px">
        <AuthSC.AuthPageTitle marginBottom="32px">{texts.title}</AuthSC.AuthPageTitle>
        <CheckEmailSC.SubTitle>{texts.subTitle}</CheckEmailSC.SubTitle>
        <CheckEmailSC.Button
          type="button"
          onClick={CTAClickHandler}
        >
          {texts.CTA}
        </CheckEmailSC.Button>
        <CheckEmailSC.ResendText>
          {'Didn’t receive the link? '}
          {countdown > 0 ? countdown : <CheckEmailSC.ResendButton type="button" onClick={resendClickHandler}>Resend</CheckEmailSC.ResendButton>}
        </CheckEmailSC.ResendText>
      </AuthSC.AuthPageContainer>
  );
};
