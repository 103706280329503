import React from 'react';
import clsx from 'clsx';

import styles from './Tab.module.scss';
import { useTranslation } from 'react-i18next';

export type TabDataType = {
    key: string;
    name: string;
    lable: string;
    labelColor?: string;
};

type TabProps = {
    className?: string;
    active: string;
    onChange: (key: string) => void;
    data: TabDataType[];
};

export const Tab: React.FC<TabProps> = ({ className, active, data, onChange }) => {
    const { t } = useTranslation();

    return (
        <div className={clsx(styles.root, className)}>
            <ul>
                {data.map(item => (
                    <li
                        key={item.key}
                        className={clsx(
                            { [styles['is-active']]: active === item.key },
                            { [styles['is-empty']]: item.lable === '0' },
                        )}
                        onClick={onChange.bind(null, item.key)}
                    >
                        {t(item.name)}
                        <span
                            {...(active === item.key &&
                                item.labelColor && {
                                    style: { color: item.labelColor },
                                })}
                        >
                            {item.lable}
                        </span>
                    </li>
                ))}
            </ul>
        </div>
    );
};
