/* eslint-disable @typescript-eslint/no-non-null-assertion */

import React, { useState, useCallback, useMemo, useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { Formik, Form, FormikHelpers, FormikErrors } from 'formik';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { LayoutSC } from 'units/common/components/layout/layout-components.styles';
import { BackButton } from 'units/common/components/back-button/back-button.component';
import { ProductsUrls } from '../../urls';
import { PrimaryButton } from 'units/common/components/buttons/primary/primary.component';
import { EditProductSC } from './product.styles';
import { ProductGeneralInfoForm } from './general-info/general-info.component';
import { ProductSeoForm } from './seo/product-seo.component';
import { EditProductTabs, editProductCommonFields } from './constants';
import {
    EditProductFormikValues,
    EditProductGeneralInfo,
    EditProductSeo,
    EditProductDescription,
    EditProductFormikGeneralInfo,
    EditProductCommonFields,
} from './store/types';
import {
    editProductUpdateCommonFields,
    editProductUpdateLanguageTabForm,
    editProductClearReducer,
    editProductInitializeReducer,
} from './store/action-creators';
import { EditProductTabSwitch } from './tab-switch/product-tab-switch';
import { EditProductLanguageProgressGroup } from './circle-progress/product-circle-progress.component';
import { productSeoValidationSchema, getProductGeneralInfoValidationSchema } from './validation';
import { saveEditProductThunk, updateProductThunk, fetchEditProductThunk } from './store/thunks';
import { useAsyncDispatch } from 'helpers/hooks/useAsyncDispatch';
import { useProductValidation } from './hooks';
import { useTrackProgress, TrackProgressField } from 'units/common/hooks/useTrackProgress';
import { useEditProductFormikInitialValues } from './hooks/selectors/useEditProductFormikInitialValues';
import { useEditProductPageTitle } from './hooks/useEditProductPageTitle';
import { useEditProductCanBeSaved } from './hooks/useEditProductCanBeSaved';
import { CompanyLanguages } from 'units/app/redux/types';
import { useCompanyLanguages } from 'units/app/hooks/selectors/useCompanyLanguages';
import { NotificationContext } from 'shared/providers';
import { Preloader } from 'shared/components/ui/';

const tabs = [
    { id: EditProductTabs.general, title: 'General information' },
    { id: EditProductTabs.seo, title: 'SEO' },
];

const scrollTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
};

const progressFields = [
    'productName',
    'usp1',
    'usp2',
    'usp3',
    'uvp',
    'deliveryDays',
    'shippingCost',
    'pictures',
    'descriptions',
    'quantityAtStock',
];

// To define the type of data.
const isEditProductGeneralInfo = (
    data: EditProductFormikValues,
): data is EditProductFormikGeneralInfo =>
    (data as EditProductFormikGeneralInfo).productName !== undefined;

export const EditProductPage = () => {
    const { showNotification } = useContext(NotificationContext);
    const [loading, setLoading] = useState(false);

    // Tools
    const dispatch = useDispatch();
    const { dispatch: asyncDispatch, isFetching } = useAsyncDispatch();

    // Primary
    const editProductLanguages = useCompanyLanguages();
    const { id: productId } = useParams<{ id?: string }>();
    const [selectedTab, selectTab] = useState(0);
    const tabId = useMemo(() => tabs[selectedTab].id, [selectedTab]);
    const [selectedLanguage, selectLanguage] = useState(
        editProductLanguages.includes(CompanyLanguages.en)
            ? CompanyLanguages.en
            : editProductLanguages[0],
    );

    // Formik
    const formikInitialValues = useEditProductFormikInitialValues(selectedLanguage, tabId);
    const validationSchema = useMemo(() => {
        const generalValues = formikInitialValues as EditProductFormikGeneralInfo;
        let minUvp = 0;

        if (generalValues && generalValues.minimumOfferAmount && !generalValues.maxUvp) {
            minUvp = generalValues.minimumOfferAmount;
        }

        if (generalValues && !generalValues.minimumOfferAmount && generalValues.maxUvp) {
            minUvp = generalValues.maxUvp;
        }

        if (generalValues && generalValues.minimumOfferAmount && generalValues.maxUvp) {
            minUvp =
                generalValues.minimumOfferAmount > generalValues.maxUvp
                    ? generalValues.minimumOfferAmount
                    : generalValues.maxUvp;
        }

        return selectedTab === 0
            ? getProductGeneralInfoValidationSchema(minUvp)
            : productSeoValidationSchema;
    }, [selectedTab, formikInitialValues]);

    // Progress
    const [initialProgressFields, setInitialProgressFields] = useState<
        { [key in CompanyLanguages]?: Array<string> }
    >({});
    const productTrackList = useMemo(
        () =>
            editProductLanguages.map(language => ({
                key: language,
                initialFields: initialProgressFields[language],
            })),
        [initialProgressFields],
    );
    const [trackProgressList, setProgress] = useTrackProgress({
        progressFields,
        commonFields: editProductCommonFields,
        trackList: productTrackList,
    });
    const langaugeProgress = useMemo(
        () => trackProgressList.map(tp => ({ id: tp.key, value: tp.value, text: tp.key })),
        [trackProgressList],
    );

    // Validation
    // TODO: Make general hook to find errors under the languages
    const [formsValidation, updateFormValidation] = useProductValidation();

    // For render
    const pageTitle = useEditProductPageTitle(!productId, selectedLanguage);
    const isProductCanBeSaved = useEditProductCanBeSaved(productId);

    // Initialize reducer store
    useEffect(() => {
        dispatch(editProductInitializeReducer(editProductLanguages));

        return () => {
            dispatch(editProductClearReducer());
        };
    }, [editProductLanguages]);

    // Fetch Edit product
    useEffect(() => {
        if (productId) {
            asyncDispatch(
                fetchEditProductThunk(productId, languageKeys => {
                    setInitialProgressFields({
                        ...editProductLanguages.reduce(
                            (acc, key) => ({ ...acc, [key]: editProductCommonFields }),
                            {},
                        ),
                        ...languageKeys.reduce(
                            (acc, key) => ({ ...acc, [key]: progressFields }),
                            {},
                        ),
                    });
                }),
            );
        }
    }, [productId]);

    // Save part of all form data to redux. Using on tab and language changes and when submiting the form.
    const saveFormPartData = useCallback(
        (partData: EditProductFormikValues, { tabId, language }) => {
            dispatch(
                editProductUpdateLanguageTabForm(partData, { tab: tabId, language: language }),
            );

            if (isEditProductGeneralInfo(partData)) {
                const commonFields: EditProductCommonFields = {
                    quantityAtStock: partData.quantityAtStock,
                    uvp: partData.uvp,
                    pictures: partData.pictures,
                    deliveryDays: partData.deliveryDays,
                    shippingCost: partData.shippingCost,
                };

                dispatch(editProductUpdateCommonFields(commonFields));
            }
        },
        [],
    );

    const updateCurrentFormPartData = useCallback(
        (partData: EditProductFormikValues) =>
            saveFormPartData(partData, { tabId, language: selectedLanguage }),
        [tabId, selectedLanguage],
    );

    // Handle tab changing - Save current part data and switch to new tab.
    const tabSelectHandler = useCallback(
        async (newTabIndex: number, { values, validateForm }) => {
            // Prevent unnecessary calls.
            if (newTabIndex === selectedTab) {
                return;
            }

            const formErrors = await validateForm();

            updateFormsValidation(formErrors, selectedTab);

            saveFormPartData(values, { tabId, language: selectedLanguage });
            selectTab(newTabIndex);
        },
        [tabId, selectedLanguage, selectedTab],
    );

    // Close selectedLanguage to setProgress
    const updateSelectedLanguageProgress = useCallback(
        (field: TrackProgressField) => setProgress(selectedLanguage, field),
        [selectedLanguage, setProgress],
    );

    const updateFormsValidation = (
        formErrors: FormikErrors<EditProductSeo> | FormikErrors<EditProductGeneralInfo> | any,
        tab: number | null = null,
    ) => {
        // Remove commonFields from errors to prevent
        // multiple jumps between languages and
        // multiple popup errors
        Object.keys(formErrors).forEach(errKey => {
            if (editProductCommonFields.includes(errKey)) {
                delete formErrors[errKey];
            }
        });

        const haveErrors = Object.keys(formErrors).length > 0;

        updateFormValidation(haveErrors, selectedLanguage, tab);
    };

    // Handle language changing - Save current part data and switch to new language.
    const languageSelectHandler = useCallback(
        async (newLanguageId: CompanyLanguages, { values, resetForm, validateForm }) => {
            const formErrors = await validateForm();
            updateFormsValidation(formErrors, selectedTab > 0 ? selectedTab : null);

            // Prevent unnecessary calls.
            if (newLanguageId === selectedLanguage) {
                return;
            }

            saveFormPartData(values, { tabId, language: selectedLanguage });
            // Need to reset form as we have the same initialValues type and formik won't update inputs values.
            resetForm();

            selectLanguage(newLanguageId);

            scrollTop();
        },
        [tabId, selectedLanguage],
    );

    // Save current part of form to redux store and only then make request to the server.
    const submitHandler = useCallback(
        async (
            values: EditProductFormikValues,
            formikHelpers: FormikHelpers<EditProductFormikValues>,
        ) => {
            saveFormPartData(values, { tabId, language: selectedLanguage });

            if (!trackProgressList.every(({ value }) => value >= 1)) {
                showNotification('error', 'Please fill all the languages');
                return;
            }

            const problem = formsValidation.find(
                item =>
                    !(
                        Object.keys(item)[0] === selectedLanguage &&
                        item[selectedLanguage] === (selectedTab || null)
                    ),
            );

            if (problem) {
                const formErrors = await formikHelpers.validateForm();

                updateFormsValidation(formErrors, selectedTab > 0 ? selectedTab : null);

                const [lang, value] = Object.entries(problem)[0];

                if (lang !== selectedLanguage) {
                    selectLanguage(lang as CompanyLanguages);
                }

                const tab = value === null ? 0 : 1;

                if (tab !== selectedTab) {
                    selectTab(tab);
                }

                scrollTop();
                setTimeout(formikHelpers.submitForm);
                showNotification('error', 'Please fill all the data according requirements');
                return;
            }

            setLoading(true);
            if (productId) {
                asyncDispatch(updateProductThunk(productId, showNotification));
            } else {
                asyncDispatch(saveEditProductThunk(showNotification));
            }
        },
        [tabId, selectedLanguage, trackProgressList],
    );

    // Used to update description progress when add or remove one.
    const updateDescriptionProgress = useCallback(
        (newDescriptions: { [key in CompanyLanguages]: Array<EditProductDescription> }) => {
            Object.entries(newDescriptions).forEach(([lng, descriptions]) => {
                const isFilled = descriptions.every(
                    description =>
                        description.type && description.description.getCurrentContent().hasText(),
                );

                setProgress(lng, { name: 'descriptions', isFilled });
            });
        },
        [setProgress],
    );

    const renderTabContent = useCallback(() => {
        switch (tabId) {
            // Pass update progress to component where it needed
            case EditProductTabs.general:
                return (
                    <ProductGeneralInfoForm
                        updateProgress={updateSelectedLanguageProgress}
                        saveCurrentForm={updateCurrentFormPartData}
                        updateDescriptionProgress={updateDescriptionProgress}
                    />
                );
            case EditProductTabs.seo:
                return <ProductSeoForm />;
            default:
                return null;
        }
    }, [
        tabId,
        updateSelectedLanguageProgress,
        updateCurrentFormPartData,
        updateDescriptionProgress,
    ]);

    if (loading) return <Preloader.Page />;

    return (
        <>
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>
            <BackButton destinationText={'Products'} to={ProductsUrls.index} />
            <LayoutSC.PageTitleContainer marginTop={16}>
                <h1>{pageTitle}</h1>
            </LayoutSC.PageTitleContainer>
            {formikInitialValues ? (
                <Formik
                    initialValues={formikInitialValues}
                    enableReinitialize={true}
                    onSubmit={submitHandler}
                    validationSchema={validationSchema}
                >
                    <>
                        <EditProductTabSwitch
                            tabs={tabs}
                            selectedTabIndex={selectedTab}
                            onTabClick={tabSelectHandler}
                        />
                        <EditProductSC.Container>
                            <Form>
                                <EditProductSC.ContentContainer>
                                    {renderTabContent()}
                                </EditProductSC.ContentContainer>
                                <EditProductSC.FormBottomControl>
                                    <EditProductLanguageProgressGroup
                                        onClick={languageSelectHandler}
                                        progressList={langaugeProgress}
                                        selectedId={selectedLanguage}
                                    />
                                    <PrimaryButton
                                        type="submit"
                                        disabled={isFetching || !isProductCanBeSaved}
                                    >
                                        {productId ? 'Update' : 'Save'}
                                    </PrimaryButton>
                                </EditProductSC.FormBottomControl>
                            </Form>
                        </EditProductSC.Container>
                    </>
                </Formik>
            ) : null}
        </>
    );
};

/* eslint-enable @typescript-eslint/no-non-null-assertion */
