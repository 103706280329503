export const ImmoUrls = {
    index: '/',
    home: '/',
    overview: '/overview',
    analytics: '/analytics',
    auction: {
        index: '/auction',
        create: '/auction/create',
        update: '/auction/create/:id',
    },
    objects: {
        index: '/objects',
        create: '/objects/create',
        update: '/objects/create/:id',
    },
    financialProofs: '/financial-proofs',
    customization: {
        index: '/cms',
        themes: '/cms/themes',
        themeSettings: '/cms/theme-settings',
        customization: '/cms/customization',
    },
    user: {
        login: '/sign-in',
        registration: '/sign-up',
        resetPassword: '/reset-password',
        confirmEmail: '/activation/:uid/:token',
        confirmResetPassword: '/password/reset/:uid/:token',
        acceptInvitation: '/accept-activation/:token',
        createPassword: '/create-password',
        signInWelcome: '/sign-in/welcome',
    },
    settings: {
        index: '/settings',
        account: '/settings/account',
        storefrontLanguage: '/settings/storefront-language',
        thirdPartyIntegrations: '/settings/integrations',
        emailTemplates: {
            index: '/settings/email-templates',
            update: '/settings/email-templates/update/:id',
        },
    },
};
