import React, {FC } from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

type Props = React.InputHTMLAttributes<HTMLInputElement> & NumberFormatProps;

export const BaseRoundNumberInput: FC<Props> = (props) => (
  <NumberFormat
    isNumericString
    decimalSeparator={false}
    {...props}
    type="text"
  />
);
