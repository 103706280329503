import styled from 'styled-components';

import { Color } from 'components/styles/constants.styles';
import { alignMe } from 'components/styles/helpers.styles';

const Container = styled.div`
    ${alignMe('flex-start', 'center')};
`;

const Stick = styled.span<{selected?: Array<number>}>`
    min-width: 173px;
    height: 8px;
    border-radius: 6px;
    background: ${Color.white};

    &:not(:last-child) {
        margin-right: 5px;
    }
    
    ${({selected}) => selected && selected.map((select) => `
        &:nth-child(${select}) {
            background: ${Color.watermelon};
        }
    `)}
`;

export const ProgressSticksSC = {
    Container,
    Stick,
}
