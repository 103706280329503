import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { MediaQueries } from 'units/landing-page/config';

export const LogoSC = styled(Link)`
  display: inline-block;
  img {
    @media (max-width: ${MediaQueries.tablet}) {
      width: 113px;
      height: 48px;
    }
  }
`;
