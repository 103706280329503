import React, { FC, useEffect, useMemo, useCallback } from 'react';
import { useField } from 'formik';
import { EditorState } from 'draft-js';
import { useDispatch } from 'react-redux';

import { GeneralFormSC } from 'units//common/components/form/form.styles';
import { DescriptionFieldSet } from './fieldset/description-fieldset.component';
import { EditProductGeneralInfo, EditProductDescription } from '../../store/types';
import { useFormikFieldErrors } from 'units//common/hooks/useFieldErrors';
import { ProductDescriptionSC } from './product-description.styles';
import {
    editProductAddDescriptionsFieldset,
    editProductRemoveDescriptionsByIndex,
} from '../../store/action-creators';
import { EditProductSC } from '../../product.styles';
import { TrackProgressField } from 'units//common/hooks/useTrackProgress';

interface Props {
    updateProgress: (
        field: TrackProgressField,
        options?: {
            forAllLanguages?: boolean;
        },
    ) => void;
    saveForm: () => void;
    updateDescriptionProgress: () => void;
}

export const ProductDescriptionForm: FC<Props> = ({
    updateProgress,
    saveForm,
    updateDescriptionProgress,
}) => {
    const dispatch = useDispatch();
    const [{ value: descriptions }, meta, helpers] = useField<Array<EditProductDescription>>(
        'descriptions',
    );
    const fieldError = useFormikFieldErrors<EditProductGeneralInfo>();

    // Check if some of fieldset is complitely filled and update progress.
    useEffect(() => {
        if (!descriptions) {
            return;
        }
        const isFilled = descriptions.every(
            description =>
                (description.type && description.description.getCurrentContent().hasText()) ||
                description.description.getCurrentContent().getFirstBlock().getType() !==
                    'unstyled',
        );

        updateProgress({ name: 'descriptions', isFilled });
    }, [descriptions, updateProgress]);

    const generalError = useMemo(
        () => meta.touched && typeof meta.error === 'string' && meta.error,
        [meta],
    );

    const handleRemoveDescription = useCallback(
        (index: number) => {
            const filteredDescriptions = descriptions.filter((d, i) => i !== index);
            helpers.setValue(filteredDescriptions);
            filteredDescriptions[0].description.getCurrentContent();

            saveForm();

            dispatch(editProductRemoveDescriptionsByIndex(index, updateDescriptionProgress));
        },
        [descriptions, saveForm, updateDescriptionProgress],
    );

    const handleAddDescription = useCallback(() => {
        const descriptionsQuantity = descriptions.length;
        if (descriptionsQuantity < 3) {
            helpers.setValue([
                ...descriptions,
                {
                    type: '',
                    description: EditorState.createEmpty(),
                },
            ]);

            saveForm();

            dispatch(
                editProductAddDescriptionsFieldset(
                    descriptionsQuantity + 1,
                    updateDescriptionProgress,
                ),
            );
        }
    }, [descriptions, saveForm, updateDescriptionProgress]);

    const sectionArrayCount = useMemo(() => `(${descriptions?.length || 0} / 3)`, [descriptions]);

    // When selected tab changed values can be without 'descriptions'
    if (!descriptions) {
        return null;
    }

    return (
        <>
            <GeneralFormSC.SectionTitle>
                Description{' '}
                <ProductDescriptionSC.ArrayIndicator>
                    {sectionArrayCount}
                </ProductDescriptionSC.ArrayIndicator>
                {generalError && (
                    <EditProductSC.ErrorText marginLeft={12}>
                        {generalError}
                    </EditProductSC.ErrorText>
                )}
                {descriptions.length < 3 && (
                    <ProductDescriptionSC.AddDescriptionButton
                        type="button"
                        onClick={handleAddDescription}
                    >
                        Add description
                    </ProductDescriptionSC.AddDescriptionButton>
                )}
            </GeneralFormSC.SectionTitle>
            {descriptions.map((d, index) => (
                <DescriptionFieldSet
                    key={`description_${index}`}
                    prefixName={`descriptions.${index}`}
                    error={fieldError.descriptions?.[index]}
                    withDivider={index + 1 < descriptions.length}
                    withRemoveButton={index > 0}
                    onRemoveButtonClick={handleRemoveDescription.bind(null, index)}
                    placeholder={
                        d.description.getCurrentContent().getFirstBlock().getType() !== 'unstyled'
                            ? ''
                            : 'Enter description'
                    }
                />
            ))}
        </>
    );
};
