import { ThunkAction } from 'redux-thunk';
import { api } from 'api';

import { State } from 'store/reducers/settings.reducer';

import { REMOVE_SETTINGS_STRIPE_ACCOUNT_DATA, SET_SETTINGS_STRIPE_ACCOUNT_DATA, SET_SETTINGS_STRIPE_ACCOUNT_LOADING } from "store/types";
import { StripeAccountData } from 'shared/types';

export const setSettingsStripeData = (data:StripeAccountData | null) => ({
  type: SET_SETTINGS_STRIPE_ACCOUNT_DATA,
  data,
});

export const changeLoadingStripeData = (state:boolean) => ({
  type: SET_SETTINGS_STRIPE_ACCOUNT_LOADING,
  state,
});

export const removeSettingsStripeData = () => ({
  type: REMOVE_SETTINGS_STRIPE_ACCOUNT_DATA
});

export type SettingActions =
  | ReturnType<typeof setSettingsStripeData>
  | ReturnType<typeof changeLoadingStripeData>
  | ReturnType<typeof removeSettingsStripeData>

type ThunkResult<R> = ThunkAction<R, State, undefined, SettingActions>;

const loadStripeAccountData = (): ThunkResult<void> => async (dispatch) => {
  dispatch(changeLoadingStripeData(true));
  const { data, status } = await api.settingsService.getStripeAccountInfo();
  dispatch(setSettingsStripeData(status !== 404 ? data : null));
  dispatch(changeLoadingStripeData(false));
};

const createStripeAccountData = (): ThunkResult<void> => async (dispatch) => {
  dispatch(changeLoadingStripeData(true));
  const { data } = await api.settingsService.createStripeAccountInfo();
  dispatch(setSettingsStripeData(data));
  dispatch(changeLoadingStripeData(false));
};

export const SettingThunkActions = {
  loadStripeAccountData,
  createStripeAccountData
};