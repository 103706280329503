import { api } from 'api';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/root-types';
import { Checkbox } from 'shared/components/ui';
import { AuctionPagination } from 'shared/types';
import { setHomeHeroSectionAuctions } from 'units/cms/store/action-creators';
import { CMSRootState } from 'units/cms/store/types';
import { UploadHeroImages } from '../upload-hero-images/upload-hero-images.component';

import { CMSSelectAuctionsSC } from './select-auctions.styles';

type AuctionItemType = {
    name: string;
    id: string;
    isSelected: boolean;
};

export const SelectAuctions = () => {
    const {
        config: { customization },
    } = useSelector<RootState, CMSRootState>(state => state.cms);
    const [auctions, setAuctions] = useState<AuctionItemType[] | null>(null);
    const dispatch = useDispatch();

    useEffect(() => {
        api.auctionService.getAll('active').then(({ data }) => mapAuctions(data));
    }, []);

    const mapAuctions = (auctions: null | AuctionPagination) => {
        let result: AuctionItemType[] = [];

        if (auctions && auctions.results.length) {
            result = auctions.results.map(item => ({
                name: `${item.auction_name.slice(0, 25)}${
                    item.auction_name.length > 25 ? '...' : ''
                }`,
                id: item.id,
                isSelected: customization.homepage.carouselAuctions?.includes(item.id) || false,
            }));
        }

        setAuctions(result);
    };

    const onChangeAcitve = (state: boolean, id: string) => {
        let list = customization.homepage.carouselAuctions || [];

        if (state) {
            list = [...list, ...[id]];
        } else {
            list = list.filter(value => value !== id);
        }

        dispatch(setHomeHeroSectionAuctions(list));
    };

    return (
        <CMSSelectAuctionsSC.Wrapper>
            {auctions === null && 'Loading...'}
            {auctions !== null && auctions.length <= 0 && <UploadHeroImages />}
            {auctions !== null &&
                auctions.length > 0 &&
                auctions?.map(item => (
                    <CMSSelectAuctionsSC.AuctionItem key={item.id} title={item.name}>
                        <Checkbox
                            checked={item.isSelected}
                            label={item.name}
                            onChange={state => onChangeAcitve(state, item.id)}
                        />
                    </CMSSelectAuctionsSC.AuctionItem>
                ))}
        </CMSSelectAuctionsSC.Wrapper>
    );
};
