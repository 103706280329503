import { LPAnalytic, LPCommerce, LPOptimization, LPWhiteLabel } from '../../illustrations';

type Feature = {
    illustration:any;
    label:string;
    title:string;
    text:string;
}
  
export const featuresList: Array<Feature> = [
    {
        illustration: LPWhiteLabel,
        label: 'lp_feature_item_one_label',
        title: 'lp_feature_item_one_title',
        text: 'lp_feature_item_one_text'
    },
    {
        illustration: LPOptimization,
        label: 'lp_feature_item_two_label',
        title: 'lp_feature_item_two_title',
        text: 'lp_feature_item_two_text'
    },
    {
        illustration: LPCommerce,
        label: 'lp_feature_item_three_label',
        title: 'lp_feature_item_three_title',
        text: 'lp_feature_item_three_text'
    },
    {
        illustration: LPAnalytic,
        label: 'lp_feature_item_four_label',
        title: 'lp_feature_item_four_title',
        text: 'lp_feature_item_four_text'
    }
];
