
import React, { FC } from 'react';

import { CmsAcordionSection } from 'units/cms/components/cms-acordion-section/cmsAcordionSection.component';
import { FormikFormInput } from 'units/common/components/formik/input/form-input.component';
import { TrackProgressField } from 'units/common/hooks/useTrackProgress';
import { FormikFormTextArea } from 'units/common/components/formik/textarea/form-textarea.comonent';
import { CMSRootSC } from 'units/cms/styles';
import { CMSUploader } from 'units/cms/components/cms-uploader/cms-uploader.component';
import { useCMSFormikImageUploadData } from 'units/cms/hooks/useCMSFormikImageUploadData';

type Props = {
  updateProgress: (field: TrackProgressField) => void;
}

export const CMSShopModalsOfferWonAccordion: FC<Props> = ({ updateProgress }) => {
    const CMSUploaderData = useCMSFormikImageUploadData({ imageKey: 'offerWon.winButtonIcon' });

    return (
      <CmsAcordionSection sectionMargin={32} withDevider={false}>
        <CMSRootSC.SectionTitle>
            Your user will receive this confirmation when he sets a winning offer
        </CMSRootSC.SectionTitle>
       <FormikFormInput
          name="offerWon.title"
          label="Title"
          placeholder="Won"
          paddingBottom="0"
          afterChange={({ target }) => {
            updateProgress({ name: 'title', isFilled: !!target.value });
          }}
        />
        <FormikFormTextArea
          subLabel="Optional"
          name="offerWon.subtitle"
          label="Subtitle"
          placeholder="Enter a subtitle text if needed"
        />
        <FormikFormInput
          name="offerWon.button"
          label="Won button text"
          placeholder="Won"
          paddingBottom="0"
          afterChange={({ target }) => {
            updateProgress({ name: 'button', isFilled: !!target.value });
          }}
        />
        <CMSUploader
          label="Won button icon"
          title="Drag and drop a picture here or"
          subtitle="Max size 100 px * 100 px"
          loadingTitle="Icon is loading"
          maxDimension={{
            height: 100,
            width: 100,
          }}
          dimensionErrorMessage="Max size 100 px * 100 px"
          {...CMSUploaderData}
        />
      </CmsAcordionSection>
    );
};
