import styled from 'styled-components';
import { CSSProperties } from 'react';
import { Link } from 'react-router-dom';

import { TextStyle2, TextStyle5 } from 'components/styles/text.styles';
import { PrimaryButtonStyle } from 'components/styles/button.styles';
import { MediaQueries } from 'units/landing-page/config';

type AuthPageContainerProps = {
  mobileMarginTop?: string;
  tabletMarginTop?: string;
}

const AuthPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-bottom: 100px;
  @media (max-width: ${MediaQueries.tablet}) {
    justify-content: unset;
    ${({ tabletMarginTop }: AuthPageContainerProps) => tabletMarginTop ? `margin-top: ${tabletMarginTop}` : ''};
  }
  @media (max-width: ${MediaQueries.mobile}) {
    justify-content: unset;
    ${({ mobileMarginTop }: AuthPageContainerProps) => mobileMarginTop ? `margin-top: ${mobileMarginTop}` : ''};
  }
`;

const AuthPageTitle = styled.h1`
  margin-bottom: ${({ marginBottom }: CSSProperties) => marginBottom || '40px'};

  ${TextStyle2}
  @media (max-width: ${MediaQueries.mobile}) {
    font-size: 32px;
    margin-bottom: 24px;
    text-align: center;
  }
`;

const AuthPageForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 530px;
  @media (max-width: ${MediaQueries.mobile}) {
    width: 100%;
    text-align: center;
  }
  margin-bottom: ${({ marginBottom }: CSSProperties) => marginBottom || '32px'};
`;

const AuthPageInputList = styled.div`
  margin-bottom: ${({ marginBottom }: CSSProperties) => marginBottom || '48px'};
  width: 100%;

  & > * {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const AuthPagePrimaryButton = styled.button`
  ${PrimaryButtonStyle};

  padding: 24px 0;
  min-width: 278px;
  height: 72px;

  @media (max-width: ${MediaQueries.mobile}) {
    width: 100%;
  }
`;

const AuthPageAdditionalLink = styled(Link)`
  ${TextStyle5};

  text-decoration: none;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
`;

export const AuthSC = {
  AuthPageContainer,
  AuthPageForm,
  AuthPageTitle,
  AuthPageInputList,
  AuthPagePrimaryButton,
  AuthPageAdditionalLink,
};
