import update from 'immutability-helper';

import * as actionTypes from './action-types';
import { UserState, UserActions } from './types';
import { UserErrorKeys } from '../constants';
import { updateErrorState } from 'redux/tools/error-handling';
import { parseSignUpError } from '../tools/parsers/signupError';
import { RootState } from 'redux/root-types';
import { AuthHelper } from 'shared/helpers';

const initState: UserState = {
    token: '',
    user: null,
    error: {},
    tempPayload: {
        invitationToken: null,
        resetPassword: {
            prefilledEmail: null,
        },
    },
    resetPasswordUserId: null,
};

export function userReducer(state: UserState = initState, action: UserActions) {
    switch (action.type) {
        // Registration
        case actionTypes.USER_REGISTER_REQUEST:
            return update(state, {
                tempPayload: {
                    $merge: { registration: { email: action.userEmail } },
                },
            });
        case actionTypes.USER_REGISTER_SUCCESS:
            return update(state, {
                user: {
                    $set: action.user,
                },
                error: {
                    $merge: { [UserErrorKeys.registration]: null },
                },
            });
        case actionTypes.USER_REGISTER_FAILURE:
            return {
                ...state,
                error: updateErrorState(
                    state.error,
                    UserErrorKeys.registration,
                    parseSignUpError(action.error),
                ),
            };

        // Confirm email
        case actionTypes.USER_CONFIRM_EMAIL_REQUEST:
            return state;
        case actionTypes.USER_CONFIRM_EMAIL_SUCCESS:
            return update(state, {
                error: {
                    $merge: {
                        [UserErrorKeys.confirmEmail]: null,
                    },
                },
                tempPayload: {
                    $merge: { registration: undefined },
                },
            });
        case actionTypes.USER_CONFIRM_EMAIL_FAILURE:
            return {
                ...state,
                error: updateErrorState(state.error, UserErrorKeys.confirmEmail, action.error),
            };

        // Resend confirm email
        case actionTypes.USER_RESEND_CONFIRM_EMAIL_REQUEST:
            return state;
        case actionTypes.USER_RESEND_CONFIRM_EMAIL_SUCCESS:
            return update(state, {
                error: {
                    $merge: {
                        [UserErrorKeys.resendConfirmEmail]: null,
                        [UserErrorKeys.confirmEmail]: null,
                    },
                },
            });
        case actionTypes.USER_RESEND_CONFIRM_EMAIL_FAILURE:
            return {
                ...state,
                error: updateErrorState(
                    state.error,
                    UserErrorKeys.resendConfirmEmail,
                    action.error,
                ),
            };

        // Login
        case actionTypes.USER_LOGIN_REQUEST:
            return state;

        case actionTypes.USER_LOGIN_SUCCESS:
            AuthHelper.setToken(action.token);

            return update(state, {
                token: { $set: action.token },
                user: {
                    $set: {
                        email: action.email,
                        isWelcomeflowCompeted: action.isWelcomeflowCompeted,
                    },
                },
                error: {
                    $merge: { [UserErrorKeys.login]: null },
                },
                tempPayload: {
                    $merge: {
                        resetPassword: {
                            // after success login don't need prefilled email for reset password.
                            prefilledEmail: null,
                        },
                    },
                },
            });

        case actionTypes.USER_LOGIN_FAILURE:
            return {
                ...state,
                error: updateErrorState(state.error, UserErrorKeys.login, action.error),
            };

        // Reset password
        case actionTypes.USER_RESET_PASSWORD_REQUEST:
            const newUser = state.user
                ? {
                      $merge: { email: action.email },
                  }
                : {
                      $set: { email: action.email },
                  };

            return update(state, {
                user: newUser,
                tempPayload: {
                    $merge: { resetPassword: { prefilledEmail: null } },
                },
            });
        case actionTypes.USER_RESET_PASSWORD_SUCCESS:
            return update(state, {
                error: {
                    $merge: { [UserErrorKeys.resetPassword]: null },
                },
            });
        case actionTypes.USER_RESET_PASSWORD_FAILURE:
            return {
                ...state,
                error: updateErrorState(state.error, UserErrorKeys.resetPassword, action.error),
            };
        case actionTypes.USER_RESET_PASSWORD_USER_ID:
            return {
                ...state,
                resetPasswordUserId: action.userId,
            };

        // Confirm reset password
        case actionTypes.USER_CONFIRM_RESET_PASSWORD_REQUEST:
            return {
                ...state,
                error: {
                    ...state.error,
                    [UserErrorKeys.confirmResetPassword]: null,
                },
            };
        case actionTypes.USER_CONFIRM_RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                resetPasswordUserId: null,
            };
        case actionTypes.USER_CONFIRM_RESET_PASSWORD_FAILURE:
            return {
                ...state,
                error: updateErrorState(
                    state.error,
                    UserErrorKeys.confirmResetPassword,
                    action.error,
                ),
            };

        // Sign Out
        case actionTypes.SIGNOUT_REQUEST:
            return {
                ...state,
                error: {
                    ...state.error,
                    [UserErrorKeys.signOut]: null,
                },
            };
        case actionTypes.SIGNOUT_SUCCESS:
            AuthHelper.removeToken();
            return {
                ...state,
                token: '',
                user: null,
            };
        case actionTypes.SIGNOUT_FAILURE:
            return {
                ...state,
                error: updateErrorState(state.error, UserErrorKeys.signOut, action.error),
            };

        // Get info about me
        case actionTypes.USER_FETCH_MY_ACCOUNT_DATA_REQUEST:
            return {
                ...state,
                error: {
                    ...state.error,
                    [UserErrorKeys.fetchMyAccountData]: null,
                },
            };
        case actionTypes.USER_FETCH_MY_ACCOUNT_DATA_SUCCESS:
            return update<UserState>(state, {
                user: {
                    $merge: action.user,
                },
            });
        case actionTypes.USER_FETCH_MY_ACCOUNT_DATA_FAILURE:
            return {
                ...state,
                error: updateErrorState(
                    state.error,
                    UserErrorKeys.fetchMyAccountData,
                    action.error,
                ),
            };

        // Update info about me
        case actionTypes.USER_UPDATE_MY_ACCOUNT_DATA_REQUEST:
            return state;

        case actionTypes.USER_UPDATE_MY_ACCOUNT_DATA_SUCCESS:
            const userEmail = state.user?.email ?? '';
            return {
                ...state,
                user: {
                    ...state.user,
                    ...action.user,
                    email: userEmail,
                },
                error: {
                    ...state.error,
                    [UserErrorKeys.updateMyAccountData]: null,
                },
            };
        case actionTypes.USER_UPDATE_MY_ACCOUNT_DATA_FAILURE:
            return {
                ...state,
                error: updateErrorState(
                    state.error,
                    UserErrorKeys.updateMyAccountData,
                    action.error,
                ),
            };
        case actionTypes.USER_UPDATE_CUSTOM_DOMAIN_DATA:
            return update(state, {
                user: {
                    company: {
                        $merge: { custom_domain: action.domain },
                    },
                },
            });
        case actionTypes.USER_UPDATE_CUSTOM_DOMAIN_STATUS:
            return update(state, {
                user: {
                    company: {
                        $merge: { custom_domain_status: action.status },
                    },
                },
            });
        case actionTypes.USER_UPDATE_COMPANY_EMAIL:
            return update(state, {
                user: {
                    company: {
                        $merge: { email: action.email },
                    },
                },
            });

        // Accept invite
        case actionTypes.USER_ACCEPT_INVITATION_USER_TOKEN:
            return update(state, {
                tempPayload: {
                    $merge: { invitationToken: action.token },
                },
            });
        case actionTypes.USER_ACCEPT_INVITATION_REQUEST:
            return update(state, {
                error: {
                    $merge: { [UserErrorKeys.acceptInvitation]: null },
                },
            });
        case actionTypes.USER_ACCEPT_INVITATION_SUCCESS:
            return update(state, {
                tempPayload: {
                    $merge: { invitationToken: null },
                },
                error: {
                    $merge: { [UserErrorKeys.acceptInvitation]: null },
                },
            });
        case actionTypes.USER_ACCEPT_INVITATION_FAILURE:
            return {
                ...state,
                error: updateErrorState(state.error, UserErrorKeys.acceptInvitation, action.error),
            };

        // Additional
        case actionTypes.USER_CLEAR_STORE:
            return initState;

        case actionTypes.USER_SET_ERROR_STATE:
            return update(state, {
                error: {
                    $merge: {
                        [action.key]: action.value,
                    },
                },
            });

        case actionTypes.USER_SET_TEMP_PAYLOAD:
            return update(state, {
                tempPayload: {
                    $merge: {
                        [action.key]: action.value,
                    },
                },
            });

        // Welcome flow
        case actionTypes.USER_WELCOME_FLOW_REQUEST_SUCCESS:
            return update(state, {
                user: {
                    $merge: {
                        ...action.values,
                        isWelcomeflowCompeted: true,
                    },
                },
            });

        default:
            return state;
    }
}

export const userRoleSelector = (state: RootState) => state.user.user?.role?.role;
export const userCompanySelector = (state: RootState) => state.user.user?.company;
