import styled from 'styled-components';

import { TextStyle8 } from 'components/styles/text.styles';
import { transition } from 'components/styles/constants.styles';

export const BackButtonSC = styled.div`
    cursor: pointer;
    transition: ${transition};

    ${TextStyle8};

    > span {
        padding-right: 6px;
    }
    max-width: 200px;
    &:hover {
        transform: translateX(-6px);
    }
`;
