import { ApiClient, apiPath } from 'config/api/api';
import { EmailTemplateListType, EmailTemplateType, EmailUpdateType } from '../types';

const getAll = async () => {
    const api = ApiClient.getInstance();
    return api.get<EmailTemplateListType>(`${apiPath}/companies/email-templates/`);
}

const getEmailTemplate = async (emailId: string) => {
    const api = ApiClient.getInstance();
    return api.get<EmailTemplateType>(`${apiPath}/companies/email-templates/${emailId}/`);
}

const updateEmailTemplate = async (uuid: string, emailBody: EmailUpdateType) => {
    const api = ApiClient.getInstance();
    return api.put(`${apiPath}/companies/email-templates/${uuid}/`, emailBody);
};

const resetEmailTemplate = async (uuid: string) => {
    const api = ApiClient.getInstance();
    return api.patch(`${apiPath}/companies/email-templates/${uuid}/`);
};

export const TemplatesService = {
    getAll,
    getEmailTemplate,
    updateEmailTemplate,
    resetEmailTemplate
}