import { http } from 'api/http';
import { ResponseDataType } from 'api/types';

import {
    CustomDomainResponseType,
    EmailTemplateItemType,
    StripeAccountData,
    UpdateEmailTemplateType,
} from 'shared/types';

const getStripeAccountInfo = async (): Promise<ResponseDataType<null | StripeAccountData>> => {
    return http.get<null | StripeAccountData>('/payments/stripe/account');
};

const createStripeAccountInfo = async (): Promise<ResponseDataType<null | StripeAccountData>> => {
    return http.post<null | StripeAccountData>('/payments/stripe/account/');
};

const addCustomDomain = async (
    value: string | null,
): Promise<ResponseDataType<CustomDomainResponseType>> => {
    return http.patch<CustomDomainResponseType>('/companies/current', { custom_domain: value });
};

const getAllEmailTemplates = async (): Promise<ResponseDataType<EmailTemplateItemType[]>> => {
    return http.get<EmailTemplateItemType[]>('/companies/email-templates/');
};

const getEmailTemplate = async (id: string): Promise<ResponseDataType<EmailTemplateItemType>> => {
    return http.get<EmailTemplateItemType>(`/companies/email-templates/${id}/`);
};

const updateEmailTemplate = async (id: string, data: UpdateEmailTemplateType) => {
    return http.put(`/companies/email-templates/${id}/`, data);
};

const resetEmailTemplate = async (
    id: string,
): Promise<ResponseDataType<UpdateEmailTemplateType>> => {
    return http.patch(`/companies/email-templates/${id}/`);
};

const updateCompanyEmail = async (
    email: string,
): Promise<ResponseDataType<UpdateEmailTemplateType>> => {
    return http.patch('/companies/current', { email });
};

export const createSettingsService = () => ({
    getStripeAccountInfo,
    createStripeAccountInfo,
    addCustomDomain,
    getAllEmailTemplates,
    getEmailTemplate,
    updateEmailTemplate,
    resetEmailTemplate,
    updateCompanyEmail,
});
