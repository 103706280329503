import { overallUpdatesCreator } from '.';

export const ShopModalsSharedFields = [
    // Offer confirmation
    'offerConfirmation.confirmButtonIcon',
    // Offer denial
    'offerDenial.denyButtonIcon',
    // Offer won
    'offerWon.winButtonIcon',
    // Reset password
    'resetPassword.sentEmailIcon', 'resetPassword.enterPasswordIcon'
];

export const createOverallUpdatesForCMSShopModals = overallUpdatesCreator(ShopModalsSharedFields);
