import clsx from 'clsx';
import React from 'react';
import { Typography } from 'shared/components/ui';

import styles from './StatusCard.module.scss';

type StatusCardProps = {
    title: string;
    icon: string;
    mainLabel: string | number;
    footer?: React.ReactNode;
    className?: string;
};

export const StatusCard: React.FC<StatusCardProps> = ({
    title,
    icon,
    mainLabel,
    footer,
    className,
}) => {
    return (
        <div className={clsx(styles.card, className)}>
            <div className={styles.status}>
                <div>
                    <Typography.Title htmlElement="h4" className={styles.title}>
                        {title}
                    </Typography.Title>
                    <Typography.Title htmlElement="h2" className={styles.total}>
                        {mainLabel}
                    </Typography.Title>
                </div>
                <div className={styles.icon}>
                    <img src={icon} alt="icon" />
                </div>
            </div>
            {footer && <div className={styles.footer}>{footer}</div>}
        </div>
    );
};
