import { AsyncAppThunk } from 'redux/root-types';
import * as appServices from './services';
import * as actionCreators from './action-creators';

import { parseCompanySettingsResponse, parseThirdPartyIntegrationsResponse } from './helpers';
import { fetchMyAccountDataThunk } from 'units/user/redux/thunks';
import { CompanySettings, ThirdPartyIntegration, ThirdPartyIntegrationSource } from './types';
import { NotificationStatusType } from 'shared/components/ui';
import { AppStrings } from 'config/strings';

export const fetchCompanySettingsThunk = (): AsyncAppThunk => async dispatch => {
    try {
        const settingsResponse = await appServices.appFetchCompanySettings();

        dispatch(actionCreators.setCompanySettings(parseCompanySettingsResponse(settingsResponse)));
    } catch (error) {
        console.log('settings error: ', error);
    }
};

export const fetchThirdPartyIntegrationsThunk = (): AsyncAppThunk => async dispatch => {
    try {
        const thirdPartyIntegrations = await appServices.appFetchThirdPartyIntegrations();
        dispatch(
            actionCreators.setThirdPartyIntegrations(
                parseThirdPartyIntegrationsResponse(thirdPartyIntegrations),
            ),
        );
    } catch (error) {
        console.log('settings error: ', error);
    }
};

export const createThirdPartyIntegrationThunk = (
    integration: ThirdPartyIntegration,
    callback?: (type: NotificationStatusType, text: string) => void,
): AsyncAppThunk => async dispatch => {
    try {
        const thirdPartyIntegration = await appServices.appCreateThirdPartyIntegration(integration);

        dispatch(
            actionCreators.setThirdPartyIntegration(
                parseThirdPartyIntegrationsResponse([thirdPartyIntegration])[0],
            ),
        );
        callback && callback('success', 'API key successfully inserted');
    } catch (error) {
        callback && callback('error', AppStrings.errorNotification);
    }
};

export const updateThirdPartyIntegrationThunk = (
    integration: ThirdPartyIntegration,
    callback?: (type: NotificationStatusType, text: string) => void,
): AsyncAppThunk => async dispatch => {
    try {
        const thirdPartyIntegration = await appServices.appUpdateThirdPartyIntegration(
            integration.source,
            integration,
        );

        dispatch(
            actionCreators.updateThirdPartyIntegration(
                parseThirdPartyIntegrationsResponse([thirdPartyIntegration])[0],
            ),
        );
        callback &&
            callback(
                'success',
                integration.source === ThirdPartyIntegrationSource.mailchimp
                    ? 'API key successfully updated'
                    : 'Tracking ID successfully updated',
            );
    } catch (error) {
        callback && callback('error', AppStrings.errorNotification);
    }
};

export const initializeAppWithAuthorizedUserThunk = (): AsyncAppThunk => async dispatch => {
    try {
        dispatch(actionCreators.setAppInitializedWithAuthorizedUser(false));

        await Promise.all([
            dispatch(fetchCompanySettingsThunk()),
            dispatch(fetchMyAccountDataThunk()),
            dispatch(fetchThirdPartyIntegrationsThunk()),
        ]);

        dispatch(actionCreators.setAppInitializedWithAuthorizedUser(true));
    } catch (error) {}
};

export const updateComanySettingsThunk = (
    languages: CompanySettings['languages'],
    openSuccessModal: () => void,
): AsyncAppThunk => async dispatch => {
    try {
        const settingsResponse = await appServices.updateCompanySettings(languages);

        dispatch(actionCreators.setCompanySettings(parseCompanySettingsResponse(settingsResponse)));
        openSuccessModal();
    } catch (error) {}
};
