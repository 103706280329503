import React, { FC, useState, useCallback, useMemo, useContext } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';

import { HeaderSC, RegisterButton } from './header.styles';
import { IconSC, IconUnicodes } from 'components/styles/icons.styles';
import { Color } from 'components/styles/constants.styles';
import { SettingsUrls } from 'units/settings/urls';
import { signOutThunk } from 'units/user/redux/thunks';
import { RootState } from 'redux/root-types';
import { UserState } from 'units/user/redux/types';
import { EcatchLogo } from '../logo/logo.component';
import { UserUrls } from 'units/user/urls';
import { useAsyncDispatch } from 'helpers/hooks/useAsyncDispatch';
import { Appi18nStrings } from 'config/strings';
import { AppUrls } from 'app/urls';
import { loginWelcome } from 'units/user/constants';
import { NotificationContext } from 'shared/providers';

const pagesWithoutHeader = [
    AppUrls.landing.landingPage,
    AppUrls.landing.imprintPage,
    AppUrls.landing.privacyPolicy,
    AppUrls.landing.termsAndConditions,
];

export const Header: FC<{ logoOnly?: boolean }> = ({ logoOnly }) => {
    const { showNotification } = useContext(NotificationContext);
    const [isDropdownOpened, setIsDropdownOpened] = useState(false);
    const { dispatch } = useAsyncDispatch();
    const { token, user } = useSelector<RootState, UserState>(state => state.user);
    const location = useLocation();
    const userEmail = user ? user.email : '';

    const isWelcomeFlow = location.pathname.includes(loginWelcome.url);

    const isVisible = useMemo(
        () => !pagesWithoutHeader.find(i => location.pathname.search(i) >= 0),
        [location.pathname],
    );

    const isNotAuthPage = useMemo(
        () =>
            location.pathname !== UserUrls.login &&
            location.pathname !== UserUrls.registration &&
            !location.pathname.includes('/activation/') && // UserUrls.confirmEmail
            !isWelcomeFlow,
        [location],
    );

    const openUserDropdown = useCallback(() => {
        setIsDropdownOpened(true);
    }, []);

    const closeUserDropdown = useCallback(() => {
        setIsDropdownOpened(false);
    }, []);

    const signOut = useCallback(() => {
        dispatch(signOutThunk(showNotification));
        setIsDropdownOpened(false);
    }, [dispatch, token, userEmail]);

    const userAva =
        'https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortFlat&accessoriesType=Blank&hairColor=' +
        'BrownDark&facialHairType=Blank&clotheType=BlazerSweater&eyeType=Default&eyebrowType=Default&mouthType=Default&skinColor=Light';

    if (!isVisible) {
        return null;
    }

    return (
        <HeaderSC.Wrapper>
            {token && !isWelcomeFlow && !logoOnly ? (
                <HeaderSC.UserInfo onMouseEnter={openUserDropdown} onMouseLeave={closeUserDropdown}>
                    <HeaderSC.UserAvatar src={userAva} />
                    <HeaderSC.UserEmail>{userEmail}</HeaderSC.UserEmail>
                    <IconSC
                        name={isDropdownOpened ? IconUnicodes.angle_up : IconUnicodes.angle_down}
                        color={Color.bluey_grey}
                        size={10}
                    />
                    {isDropdownOpened && !logoOnly && (
                        <HeaderSC.UserDropdown>
                            {/*               <NavLink to={SettingsUrls.account} activeClassName="active">
                Profile
              </NavLink> */}
                            <NavLink to={SettingsUrls.account} activeClassName="active">
                                Settings
                            </NavLink>
                            <a href="https://ecatch.tawk.help" target="_blank" rel="noreferrer">
                                Help
                            </a>
                            <HeaderSC.SignOut onClick={signOut}>Sign Out</HeaderSC.SignOut>
                        </HeaderSC.UserDropdown>
                    )}
                </HeaderSC.UserInfo>
            ) : (
                <>
                    <HeaderSC.LogoWrapper>
                        {isWelcomeFlow ? <EcatchLogo.Image /> : <EcatchLogo.Link />}
                    </HeaderSC.LogoWrapper>

                    {!logoOnly && isNotAuthPage && (
                        <div>
                            <HeaderSC.LoginButton to={UserUrls.login}>
                                {Appi18nStrings.login}
                            </HeaderSC.LoginButton>
                            <RegisterButton to={UserUrls.registration}>Register</RegisterButton>
                        </div>
                    )}
                </>
                // <div>login_btn</div>
            )}
        </HeaderSC.Wrapper>
    );
};
