import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { BackButtonSC } from './back-button.styles';
import { IconSC, IconUnicodes } from 'components/styles/icons.styles';
import { useTranslation } from 'react-i18next';

interface BackButtonProps {
    destinationText: string;
    to?: string;
    onClick?: () => void;
}

export const BackButton: React.FC<BackButtonProps> = ({ destinationText, to, onClick }) => {
    const history = useHistory();

    const { t } = useTranslation();

    const goBack = useCallback(() => {
        to ? history.push(to) : history.goBack();
    }, [history, to]);

    return (
        <BackButtonSC onClick={onClick || goBack}>
            <IconSC name={IconUnicodes.arrow_left} size={12} />
            {t('back_to')} {destinationText}
        </BackButtonSC>
    );
};
