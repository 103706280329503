import { yup } from 'config/yup';
import { CommonVM } from 'units/common/constants';

export const CMSCustomizationMissedAuctionsValidationSchema = yup.object({
    general: yup.object({
        title: yup.string().max(35, CommonVM.maxSymbols(35)),
        // .required(CommonVM.required('Title')),
        newsletterOptIn: yup.boolean(),
    }),
});
