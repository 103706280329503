import React from 'react';
import clsx from 'clsx';

// import { Icon } from '../Icon/Icon';

import styles from './Notification.module.scss';

export type NotificationStatusType = 'success' | 'error';

type NotificationProps = {
    className?: string;
    type: NotificationStatusType;
    isOpen: boolean;
    text: string;
    onClose: () => void;
};

export const Notification: React.FC<NotificationProps> = ({
    className,
    type,
    isOpen,
    text,
    onClose,
}) => (
    <div
        className={clsx(
            styles.root,
            className,
            { [styles['is-open']]: isOpen },
            styles[`is-${type}`],
        )}
    >
        {text}
        <div className={styles.icon} onClick={onClose}>
            <div className={styles.close}></div>
        </div>
    </div>
);
