import React, { FC, useEffect, useCallback, useContext } from 'react';
import { Form, useFormikContext } from 'formik';
import { OptionsType } from 'react-select';
import { useHistory, useLocation } from 'react-router-dom';

import { CreateAuctionSC } from '../create-auction.styles';
import { LayoutSC } from 'units/common/components/layout/layout-components.styles';
import { SecondaryButton } from 'units/common/components/buttons/secondary/secondary.component';
import { ProductSelect } from '../product-select/product-select.component';
import { Product } from 'units/products/api/types';
import { IOption } from 'units/common/components/select/select.component';

import { DateTimeSelect } from '../date-time-select/date-time-select.component';
import { AuctionConfiguration } from '../auction-configuration/auction-configuration.component';
import { PrimaryButton } from 'units/common/components/buttons/primary/primary.component';
import { TransparentButton } from 'units/common/components/buttons/transparent/transparent.component';
import { AuctionCondition } from '../auction-condition/auction-condition.component';
import { AuctionValues } from '../types';
import { useIsSubmitEnabled } from 'units/common/hooks/useIsSubmitEnabled';
import { Status } from '../constants';
import { AuctionTypeSelect } from '../auction-type-select/auction-type-select.component';
import {
    useWorkingMode,
    useNotRequiredFields,
    useIsConditionsValid,
    useErrorsWithoutConditions,
    useIsTimeValid,
} from '../hooks';
import { isCustomFieldValid, getAuctionStatuses } from '../helpers';
import { AuctionUrls } from '../../../urls';
import { NotificationContext } from 'shared/providers';
import { AuctionConfigurationSC } from '../auction-configuration/auction-configuration.styles';
import { FormikFormInput } from 'units/common/components/formik/input/form-input.component';

interface Props {
    options: OptionsType<IOption>;
    products: Array<Product>;
    onSubmit: (values: AuctionValues, status: number) => void;
    setAuctionType: (auctionType: string) => void;
    onDelete: () => void;
}

export const CreateAuctionForm: FC<Props> = ({
    options,
    products,
    onSubmit,
    setAuctionType,
    onDelete,
}) => {
    const { showNotification } = useContext(NotificationContext);
    const { values, errors, handleSubmit, setFieldTouched, setFieldValue } = useFormikContext<
        AuctionValues
    >();
    const history = useHistory();
    const notRequired = useNotRequiredFields(values.auctionType);
    const isConditionsValid = useIsConditionsValid(errors, values);
    const errWithoutConditions = useErrorsWithoutConditions(errors);
    const { isEditMode, isViewMode, isCreateMode } = useWorkingMode(useLocation());
    const { isActive, isDraft, isScheduled, isArchived } = getAuctionStatuses(values);
    const isSubmitEnabled = useIsSubmitEnabled(errWithoutConditions, values, { notRequired });
    const isTimeValid = useIsTimeValid(errors);
    const isSubmitDisabled = !isSubmitEnabled || !isConditionsValid || !isTimeValid;
    const productName = products.find(item => item.id === values.productId)?.name || '';
    let pageTitle = 'Create new auction';

    if (isEditMode) {
        pageTitle = `Edit ${productName}`;
    }
    if (isViewMode) {
        pageTitle = `View ${productName}`;
    }

    const selectedProductName = (selectedId: string) =>
        `${values.auctionType === '1' ? 'Buy now:' : 'Auction:'} ${
            products.find(prod => prod.id === selectedId)?.name
        }`;

    useEffect(() => {
        (async () => {
            await setAuctionType(values.auctionType);
            setFieldTouched('auctionType');
            if (values.productId) {
                setFieldValue('auctionName', selectedProductName(values.productId));
            }
        })();
    }, [values.auctionType]);

    useEffect(() => {
        setFieldValue(
            'quantity',
            products.find(item => item.id === values.productId)?.quantity || 0,
        );
        if (values.productId) {
            setFieldValue('auctionName', selectedProductName(values.productId));
        }
    }, [values.productId]);

    const triggerTimeFields = useCallback(() => {
        setFieldTouched('auctionName');
        setFieldTouched('startDate');
        setFieldTouched('endDate');
        setFieldTouched('endTime');
        setFieldTouched('startTime');
    }, []);

    const saveAsDraft = async () => {
        triggerTimeFields();
        if (
            isTimeValid &&
            isCustomFieldValid(errors, 'discount') &&
            isCustomFieldValid(errors, 'offerInterval') &&
            isCustomFieldValid(errors, 'minProdPieces')
        ) {
            if (!errors.productId && isConditionsValid) {
                onSubmit(values, Status.draft);
            } else {
                handleSubmit();
            }
        }
    };

    const saveAsScheduled = () => {
        triggerTimeFields();
        if (isEditMode && isSubmitDisabled) {
            showNotification('error', 'Please fill all the mandatory fields');
            handleSubmit();
        } else {
            onSubmit(values, Status.scheduled);
        }
    };

    const saveAsActive = () => {
        if (!isSubmitDisabled) {
            onSubmit(values, Status.active);
        }
    };

    const handleGotIt = () => {
        history.push(AuctionUrls.index);
    };

    return (
        <Form>
            <CreateAuctionSC.TitleContainer>
                <LayoutSC.PageTitle smallPadding>{pageTitle}</LayoutSC.PageTitle>
                {isEditMode &&
                    (values.auctionStatus === Status.scheduled ||
                        values.auctionStatus === Status.draft) && (
                        <SecondaryButton type="button" isWide onClick={onDelete}>
                            Delete auction
                        </SecondaryButton>
                    )}
            </CreateAuctionSC.TitleContainer>

            <CreateAuctionSC.FormContainer>
                <AuctionTypeSelect />

                <ProductSelect
                    options={options}
                    products={products}
                    onChange={() => setFieldTouched('productId', true)}
                />

                <AuctionConfigurationSC.RowContainer>
                    <FormikFormInput
                        subLabel="*visible only to you"
                        label="Auction name"
                        name="auctionName"
                        placeholder="Auction name"
                        withMargins
                        withRounding
                    />
                </AuctionConfigurationSC.RowContainer>

                <CreateAuctionSC.Devider />

                <CreateAuctionSC.SectionTitle>Auction time</CreateAuctionSC.SectionTitle>
                <DateTimeSelect />
                <CreateAuctionSC.Devider />

                <CreateAuctionSC.SectionTitle>Auction configuration</CreateAuctionSC.SectionTitle>

                <AuctionConfiguration />

                <AuctionCondition />

                <CreateAuctionSC.ButtonContainer>
                    {isEditMode && (
                        <>
                            {isScheduled && (
                                <>
                                    <PrimaryButton type="button" onClick={saveAsScheduled}>
                                        Save
                                    </PrimaryButton>
                                    <TransparentButton type="button" onClick={saveAsDraft}>
                                        Save & Unschedule
                                    </TransparentButton>
                                </>
                            )}
                            {isDraft && !isArchived && (
                                <>
                                    <PrimaryButton type="button" onClick={saveAsDraft}>
                                        Save
                                    </PrimaryButton>
                                    <TransparentButton type="button" onClick={saveAsScheduled}>
                                        Save & Schedule
                                    </TransparentButton>
                                </>
                            )}
                            {isActive && (
                                <PrimaryButton type="button" onClick={saveAsActive}>
                                    Save & Publish
                                </PrimaryButton>
                            )}
                        </>
                    )}
                    {isCreateMode && (
                        <>
                            <PrimaryButton type="button" onClick={saveAsDraft}>
                                Save
                            </PrimaryButton>
                            <TransparentButton
                                type="button"
                                onClick={saveAsScheduled}
                                disabled={isSubmitDisabled}
                            >
                                Save & Schedule
                            </TransparentButton>
                        </>
                    )}
                    {isViewMode && (
                        <PrimaryButton type="button" onClick={handleGotIt}>
                            Got it
                        </PrimaryButton>
                    )}
                </CreateAuctionSC.ButtonContainer>
            </CreateAuctionSC.FormContainer>
        </Form>
    );
};
