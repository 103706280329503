import React, { FC, useState, useCallback } from 'react';

import { Formik, FormikHelpers } from 'formik';
import { useDispatch } from 'react-redux';

import { ChooseLanguage } from '../choose-languages/choose-languages.component';
import { ConnectStripe } from '../connect-stripe/connect-stripe.component';
import { ReadyToGo } from '../ready-to-go/ready-to-go.component';
import { WelcomeForm } from '../welcome-form/welcome-form.component';
import {  welcomeFormValidationSchema } from './validation';
import { LoginWelcome } from '../../../redux/types';

import { storeWelcomeFlowDataThunk } from 'units/user/redux/thunks';
import { CompanyLanguages } from 'units/app/redux/types';

export enum WelcomeFlowComponentSteps {
  WelcomeForm,
  ChooseLanguage,
  ConnectStripe,
  ReadyToGo,
}

const formikDefaultValues: LoginWelcome = {
  firstName: '',
  lastName: '',
  languages: [CompanyLanguages.en],
  stripeApi: '',
};

export const Welcome: FC = () => {
  const [store, setStore] = useState<LoginWelcome>(formikDefaultValues);
  const [currentStep, setCurrentStep] = useState(WelcomeFlowComponentSteps.WelcomeForm);
  const validate = currentStep === WelcomeFlowComponentSteps.WelcomeForm ? welcomeFormValidationSchema : null;
  const dispatch = useDispatch();

  const formikInitialValues = useCallback(() => {
    switch (currentStep) {
      case WelcomeFlowComponentSteps.WelcomeForm:
        return { firstName: store.firstName, lastName: store.lastName };
      case WelcomeFlowComponentSteps.ChooseLanguage:
        return {
          languages: store.languages 
        };
      default:
        return store;
    }
  }, [currentStep, store]);

  const submitWelcomeFlowData = useCallback(() => {
    dispatch(storeWelcomeFlowDataThunk(store));
  }, [store]);

  const navigate = useCallback(
    (goBack = false) => {
      goBack ? setCurrentStep(currentStep - 1) : setCurrentStep(currentStep + 1);
    },
    [currentStep],
  );

  const handleSubmit = useCallback(
    (values: LoginWelcome, actions: FormikHelpers<LoginWelcome>) => {
      if (currentStep === WelcomeFlowComponentSteps.WelcomeForm) {
        setCurrentStep(currentStep + 1);
        setStore(store => ({ ...store, firstName: values.firstName, lastName: values.lastName }));
        actions.setSubmitting(false);
      } else if (currentStep === WelcomeFlowComponentSteps.ChooseLanguage) {
        setCurrentStep(currentStep + 1);
        setStore(store => ({ ...store, languages: values.languages }));
        actions.setSubmitting(false);
      } else if (currentStep === WelcomeFlowComponentSteps.ConnectStripe) {
        setCurrentStep(currentStep + 1);
        actions.setSubmitting(false);
      } else if (currentStep === WelcomeFlowComponentSteps.ReadyToGo) {
        submitWelcomeFlowData();
        actions.setSubmitting(false);
      }
    },
    [currentStep],
  );

  const currentScreen = useCallback(() => {
    switch (currentStep) {
      case WelcomeFlowComponentSteps.WelcomeForm:
        return <WelcomeForm />;
      case WelcomeFlowComponentSteps.ChooseLanguage:
        return <ChooseLanguage back={navigate} />;
      case WelcomeFlowComponentSteps.ConnectStripe:
        return <ConnectStripe navigate={navigate} />;
      case WelcomeFlowComponentSteps.ReadyToGo:
        return <ReadyToGo />;
      default:
        return;
    }
  }, [currentStep, navigate]);

  return (
    <Formik
      initialValues={formikInitialValues()}
      onSubmit={handleSubmit}
      validationSchema={validate}
      enableReinitialize
    >
      {currentScreen}
    </Formik>
  );
};
