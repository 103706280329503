import React, { FC } from 'react';

import {
  CMSCustomizationLanguageProgressContainer,
  CMSCustomizationLanguageProgressContainerProps,
} from '../../../progress-container/progress-container';
import { CmsAcordionSection } from 'units//cms/components/cms-acordion-section/cmsAcordionSection.component';
import { CMSHomepageProductCardAccordion } from './product-card-accordion.component';

type Props = Pick<CMSCustomizationLanguageProgressContainerProps, 'selectedLanguage' | 'onLanguageChange' | 'initialProgress'>;

export const CMSHomepageProductCardAccordionContainer: FC<Props> = (props) => (
  <CmsAcordionSection lastSection>
    <CMSCustomizationLanguageProgressContainer
      {...props}
    >
      {({ updateCurrentProgress }) => (
        <CMSHomepageProductCardAccordion updateProgress={updateCurrentProgress} />
      )}
    </CMSCustomizationLanguageProgressContainer>
  </CmsAcordionSection>
)
