import styled, { css } from 'styled-components';

import { sizes, Color } from 'components/styles/constants.styles';
import { Font } from 'components/types/enums';
import { FontSS } from 'components/styles/helpers.styles';

export enum CMSThemeListFontFamily {
    Default,
    Manrope,
    YesevaOne,
    MyanmarMN,
}

const CMSThemeListFontGeneralStyle = css`
    font-size: 24px;
    line-height: 32px;
    text-align: left;
    letter-spacing: 0;
    color: ${Color.charcoal_grey};
    font-weight: normal;
`;

const ListContainer = styled.ul`
    display: flex;
    flex-direction: column;
    margin-bottom: ${sizes.xlg_margin};
`;

const ItemTitle = styled.p<{ font?: CMSThemeListFontFamily }>`
    ${CMSThemeListFontGeneralStyle}

    ${({ font }) => {
        switch (font) {
            case CMSThemeListFontFamily.Manrope:
                return css`
                    font-family: ${FontSS.manropeBold};
                    font-weight: 700;
                    color: ${Color.black};
                `;
            case CMSThemeListFontFamily.MyanmarMN:
                return css`
                    font-family: ${Font.mayanmarMNRegular};
                `;
            case CMSThemeListFontFamily.YesevaOne:
                return css`
                    font-family: ${Font.yesevaOneRegular};
                `;
            default:
                return css`
                    font-family: ${Font.regular};
                `;
        }
    }};
`;

const ListItem = styled.li<{ selected?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 78px;
    margin-bottom: 8px;
    border: unset;
    border-radius: ${sizes.border_radius};

    cursor: pointer;
    user-select: none;
    background-color: ${Color.white};

    &:last-child {
        margin-bottom: 0;
    }

    ${({ selected }) =>
        selected &&
        `
    border: 2px ${Color.lightish_blue} solid;

    ${ItemTitle} {
      color: ${Color.lightish_blue};
    }
  `}
`;

export const CMSThemeListSC = {
    ListContainer,
    ListItem,
    ItemTitle,
};
