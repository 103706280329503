import React, { FC, useCallback } from 'react';
import { useFormikContext, FormikValues, FormikErrors } from 'formik';

import { TabSwitch, TabSwitchProps } from 'units//common/components/tab-switch/tab-switch.component';
import { EditProductFormikValues, EditProductGeneralInfo, EditProductSeo } from '../store/types';

interface Props extends Omit<TabSwitchProps, 'onTabClick'> {
  onTabClick: (
    tabIndex: number,
    { values, validateForm }: 
      { values: FormikValues,
        validateForm: () => Promise<FormikErrors<EditProductGeneralInfo> | FormikErrors<EditProductSeo>>
      }
  ) => void;
}

export const EditProductTabSwitch: FC<Props> = (props) => {
  const { values, validateForm } = useFormikContext<EditProductFormikValues>();

  const tabClickHandler = useCallback((tabIndex: number) => {
    props.onTabClick(tabIndex, { values, validateForm });
  }, [props.onTabClick, values]);

  return <TabSwitch {...props} onTabClick={tabClickHandler} />;
}
