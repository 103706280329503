import React from 'react';
import clsx from 'clsx';

import styles from './InputError.module.scss';

type InputErrorProps = {
    className?:string;
}

export const InputError:React.FC<InputErrorProps> = ({ className, children }) => (
    <div className={clsx(styles.root, className)}>{children}</div>
)