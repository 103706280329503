import React from 'react';
/* eslint-disable import/named */
import { FlattenSimpleInterpolation } from 'styled-components';
/* eslint-enable import/named */

import { LPTypographySC } from './typography.styles';

type LPTopographyProps = {
    styles?:FlattenSimpleInterpolation;
} 

const H2:React.FC<LPTopographyProps> = ({ children, styles }) => (
    <LPTypographySC.H2 styles={styles}>{children}</LPTypographySC.H2>
)

const H3:React.FC<LPTopographyProps> = ({ children, styles }) => (
    <LPTypographySC.H3 styles={styles}>{children}</LPTypographySC.H3>
)

const H4:React.FC<LPTopographyProps> = ({ children, styles }) => (
    <LPTypographySC.H4 styles={styles}>{children}</LPTypographySC.H4>
)

const H5:React.FC<LPTopographyProps> = ({ children, styles }) => (
    <LPTypographySC.H5 styles={styles}>{children}</LPTypographySC.H5>
)

const Text:React.FC<LPTopographyProps> = ({ children, styles }) => (
    <LPTypographySC.Text styles={styles}>{children}</LPTypographySC.Text>
)

export const LPTypography = {
    H2,
    H3,
    H4,
    H5,
    Text
}
