import update from 'immutability-helper';

import {
  EditProductActions,
  EditProductReducer,
  EditProductDescription,
  EditProductLanguagesForm,
  EditProductLanguageDescriptions,
  EditProduct,
} from './types';
import {
  productFormDefaultValues,
  productDefaultDescriptionFieldSet,
  productCommonDefaultValues,
} from '../constants';
import * as actionTypes from './action-types';
import { CompanyLanguages } from 'units/app/redux/types';

const initialState: EditProductReducer = {
  form: {
    commonFields: {...productCommonDefaultValues},
    languages: {},
  },
  imagesToRemove: [],
  isPublished: false,
  serverError: null,
};

export const editProductReducer = (state: EditProductReducer = initialState, action: EditProductActions) => {
  switch(action.type) {
    case actionTypes.EDIT_PRODUCT_UPDATE_LANGUAGE_TAB_FORM: {
      return update(state, {
        form: {
          languages: {
            [action.options.language]: {
              [action.options.tab]: {
                $set: action.data
              },
            }
          }
        }
      })
    }

    case actionTypes.EDIT_PRODUCT_POPULATE_API_DATA: {
      const languages = Object.keys(action.data.languages).reduce((acc, cur) => ({
        ...acc,
        [cur as CompanyLanguages]: { $set: action.data.languages[cur as CompanyLanguages] }
      }), {} as { [key in CompanyLanguages]: { $set: EditProduct | undefined } });

      return update(state, {
        form: {
          commonFields: { $set: action.data.commonFields },
          languages
        },
      })
    }

    case actionTypes.EDIT_PRODUCT_MARK_IMAGE_TO_REMOVE: {
      return update(state, {
        imagesToRemove: {
          $push: [action.id]
        },
      })
    }

    case actionTypes.EDIT_PRODUCT_INITIALIZE_REDUCER: {      
      const initialLanguagesForm = action.productLanguages.reduce((form, lng) => ({
        ...form,
        [lng]: {...productFormDefaultValues},
      }), {} as EditProductLanguagesForm);
      
      return update(state, {
        form: {
          $set: {
            commonFields: {...productCommonDefaultValues},
            languages: initialLanguagesForm,
          },
        },
        imagesToRemove: {
          $set: [],
        }
      });
    }

    case actionTypes.EDIT_PRODUCT_CLEAR_REDUCER: {
      return update(state, {
        form: {
          $set: {
            commonFields: {...productCommonDefaultValues},
            languages: {},
          },
        },
        imagesToRemove: {
          $set: [],
        },
        editProductIsLoaded: {
          $set: false,
        },
        serverError: {
          $set: null,
        },
        isPublished: {
          $set: false,
        },
      });
    }

    case actionTypes.EDIT_PRODUCT_UPDATE_COMMON_FIELDS: {
      return update(state, {
        form: {
          commonFields: {
            $merge: action.data,
          }
        }
      })
    }

    case actionTypes.EDIT_PRODUCT_ADD_DESCRIPTIONS_FIELDSET: {
      let newState = state;

      Object.keys(state.form.languages).forEach((lng) => {
        const language = lng as CompanyLanguages;
        
        newState = updateEditProductLanguageDescriptions(newState, language, updateDescriptionsQuantity(
          state.form.languages[language]?.generalInfo.descriptions || [],
          action.newQuantity
        ));
      });
      
      const descriptions = Object.entries(newState.form.languages).reduce((descriptionsPerLanguage, [lng, lngForm]) => ({
        ...descriptionsPerLanguage,
        [lng as CompanyLanguages]: lngForm?.generalInfo.descriptions || [],
      }), {} as EditProductLanguageDescriptions);

      action.updateDescriptionProgress(descriptions);
      
      return newState;
    }

    case actionTypes.EDIT_PRODUCT_REMOVE_DESCRIPTIONS_FIELDSET_BY_INDEX: {
      let newState = state;

      Object.keys(state.form.languages).forEach((lng) => {
        const language = lng as CompanyLanguages;
        
        newState = updateEditProductLanguageDescriptions(newState, language, removeDescriptionsFieldsetByIndex(
          state.form.languages[language]?.generalInfo.descriptions || [],
          action.index
        ))
      });

      const descriptions = Object.entries(newState.form.languages).reduce((descriptionsPerLanguage, [lng, lngForm]) => ({
        ...descriptionsPerLanguage,
        [lng as CompanyLanguages]: lngForm?.generalInfo.descriptions || [],
      }), {} as EditProductLanguageDescriptions);

      action.updateDescriptionProgress(descriptions);

      return newState;
    }

    case actionTypes.EDIT_PRODUCT_IS_LOADED: {
      return update(state, {
        editProductIsLoaded: {
          $set: true,
        }
      })
    }

    case actionTypes.EDIT_PRODUCT_SET_ERROR: {
      return update(state, {
        serverError: {
          $set: action.error,
        }
      })
    }

    case actionTypes.EDIT_PRODUCT_SET_IS_PUBLISHED: {
      return update(state, {
        isPublished: {
          $set: action.isPublished,
        }
      })
    }

    default: return state;
  }
};

const updateDescriptionsQuantity = (descriptions: Array<EditProductDescription>, newQuantity: number) => {
  if (descriptions.length > newQuantity) {
    return descriptions.slice(0, newQuantity - 1);
  } else if (descriptions.length < newQuantity) {
    return [
      ...descriptions,
      ...Array(newQuantity - descriptions.length)
        .fill(null).map(() => ({...productDefaultDescriptionFieldSet}))
    ];
  }
  return descriptions;
}

const removeDescriptionsFieldsetByIndex = (descriptions: Array<EditProductDescription>, index: number) => {
  if (descriptions[index]) {
    return update(descriptions, {$splice: [[index, 1]]})
  }
  return descriptions;
}

const updateEditProductLanguageDescriptions = (
  state: EditProductReducer,
  language: CompanyLanguages,
  newDescriptions: Array<EditProductDescription>,
) => update(state, {
  form: {
    languages: {
      [language]: {
        generalInfo: {
          descriptions: {
            $set: newDescriptions,
          }
        }
}}}});
