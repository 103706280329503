import React, { FC } from 'react';
import { useField } from 'formik';

import { AuthSC } from 'units/common/styles/auth/auth.styles';
import { InitResetPasswordSC } from './init-reset-password.styles';
import { ErrorValue } from 'redux/types';
import { FormikUserInput } from 'units/common/components/formik/input/user-input.component';
import { useFormikApiErrors } from 'units/common/components/formik/hooks/useFormikApiErrors';
import { Appi18nStrings } from 'config/strings';

interface Props {
    userError?: ErrorValue;
}

export const InitResetPassword: FC<Props> = ({ userError }) => {
    const [, meta] = useField('email');
    const isSubmitEnabled = meta.value && !meta.error;

    const apiError = useFormikApiErrors(userError);
    const fieldError = (meta.touched && meta.error) || apiError?.email?.[0];

    return (
        <AuthSC.AuthPageContainer>
            <AuthSC.AuthPageTitle>Reset password</AuthSC.AuthPageTitle>
            <AuthSC.AuthPageForm>
                <InitResetPasswordSC.InputWrapper>
                    <FormikUserInput
                        name="email"
                        label="Email"
                        placeholder="Enter your email to restore password"
                        type="email"
                        error={fieldError}
                    />
                </InitResetPasswordSC.InputWrapper>
                <AuthSC.AuthPagePrimaryButton type="submit" disabled={!isSubmitEnabled}>
                    Reset Password
                </AuthSC.AuthPagePrimaryButton>
            </AuthSC.AuthPageForm>
            <AuthSC.AuthPageAdditionalLink to="/sign-in">
                {Appi18nStrings.login}
            </AuthSC.AuthPageAdditionalLink>
        </AuthSC.AuthPageContainer>
    );
};
