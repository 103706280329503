import styled from 'styled-components';

import { TextStyle4 } from 'components/styles/text.styles';
import { PrimaryButtonStyle } from 'components/styles/button.styles';

const SubTitle = styled.h2`
  ${TextStyle4}
`;

const Button = styled.button`
  ${PrimaryButtonStyle}
  text-decoration: none;
`;

export const EmailActivationSC = {
  SubTitle,
  Button,
};
