import React, { useCallback, useMemo } from 'react';
import { useLocation} from 'react-router-dom';
import { ValueType } from 'react-select';

import { CMSUrls } from 'units/cms/urls';
import { PageSelectorSC } from './page-selector.styles';
import { CMSCustomizationPage } from 'units/cms/store/types/index';
import { Select, IOption } from 'units/common/components/select/select.component';
import { useCMSCustomizationPageNavigation } from 'units/cms/hooks/useCMSCustomizationPageNavigation';
import { useCMSConfigSavedStatus } from 'units/cms/hooks/selectors/useCMSConfigStatus';
import { CMSConfirmModalStrings } from 'units/cms/constants';
import { ConfirmModal } from 'units/common/components/modal/confirm-modal/confirm-modal.component';
import { useCMSConfirmModalManager } from 'units/cms/hooks/useCMSConfirmModalManager';

const selectorOptions = [
  { value: CMSCustomizationPage.Home, label: 'Homepage' },
  { value: CMSCustomizationPage.ProductDetail, label: 'Product detail page' },
  { value: CMSCustomizationPage.MissedAuctions, label: 'Missed auctions' },
  { value: CMSCustomizationPage.ShopModals, label: 'Shop modals' },
];

export const PageSelector = () => {
  const { pathname } = useLocation();
  const isProgressSaved = useCMSConfigSavedStatus();
  const { selectedPage, setSelectedPage } = useCMSCustomizationPageNavigation();
  const [confirmModalProps, confirmModal] = useCMSConfirmModalManager({
    ...CMSConfirmModalStrings.switchCustomizationPages,
  });

  // If progress is not saved - show confirm modal, otherwise change page.
  const handlePageChange = useCallback((option: ValueType<IOption, boolean>) => {
    const newPage = (option as IOption<CMSCustomizationPage>).value;

    if (newPage === selectedPage) {
      return;
    }

    const changePage = setSelectedPage.bind(null, newPage);

    if (isProgressSaved) {
      changePage();
    } else {
      confirmModal.openModal({
        confirmHandler: changePage,
      });
    }
  }, [selectedPage, confirmModal.openModal, isProgressSaved]);

  const selectedOption = useMemo(
    () => selectorOptions.find(option => option.value === selectedPage) || null,
    [selectorOptions, selectedPage]
  );

  // Don't render selector if it's not a customiztion page.
  if (!pathname.includes(CMSUrls.customization)) {
    return null;
  }

  return (
    <>
      <PageSelectorSC.Container>
        <Select
          options={selectorOptions}
          value={selectedOption}
          onChange={handlePageChange}
          padding="0"
        />
      </PageSelectorSC.Container>
      <ConfirmModal {...confirmModalProps} />
    </>
  )
};
