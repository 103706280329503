import React, { FC } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import { CircleProgressSC } from './circle-progress.style';
import { IconSC, IconUnicodes } from 'components/styles/icons.styles';
import { Color } from 'components/styles/constants.styles';

interface Props {
  value: number;
  text: string;
}

export const CircleProgressWrapper: FC<Props> = ({ value, text, ...props }) => {
  if (value > 1 || value < 0) {
    throw new Error(`Value should be between 0 and 1. You passed ${value} with text ${text}`);
  }

  const actualValue = Math.round((value + Number.EPSILON) * 100) / 100;
  
  return (
    <CircleProgressSC.Wrapper>
      <CircularProgressbar
        value={actualValue}
        text={text}
        maxValue={1}
        strokeWidth={6}
        background
        {...props}
      />
      { actualValue === 1 && (
        <CircleProgressSC.CompleteMark>
          <CircleProgressSC.CompleteMarkBg />
          <IconSC color={Color.lightish_blue} name={IconUnicodes.check_circle_filled} size={16} />
        </CircleProgressSC.CompleteMark>
      )}
    </CircleProgressSC.Wrapper>
  );
}
