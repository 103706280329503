import React from 'react';
import { DateRangePicker, Range } from 'react-date-range';
import 'dayjs/locale/de';
import { locale } from './range';

import styles from './DatePickerDropdown.module.scss';

type DatePickerProps = {
    range: Range[];
    setRange: React.Dispatch<React.SetStateAction<Range[]>>;
};

export const DatePickerDropdown: React.FC<DatePickerProps> = ({ range, setRange }) => {
    return (
        <div className={styles.container}>
            <DateRangePicker
                onChange={range => {
                    setRange([range.selection]);
                }}
                moveRangeOnFirstSelection={false}
                months={2}
                rangeColors={['#4b67f8']}
                ranges={range}
                direction="horizontal"
                preventSnapRefocus={true}
                calendarFocus="forwards"
                weekStartsOn={1}
                showDateDisplay={false}
                staticRanges={locale()}
                // showMonthAndYearPickers={false}
                // scroll={{ enabled: true }}
                // retainEndDateOnFirstSelection={true}
            />
        </div>
    );
};
