import styled, { css } from 'styled-components';

import { Color } from 'components/styles/constants.styles';

import { MediaQueries } from '../../config';

const ContainerCSS = css`
    margin-top: 26px;
    max-width: 990px;
    margin-bottom: 122px;
    @media (max-width: ${MediaQueries.tablet}) {
        margin-top: 44px;
    }
    @media (max-width: ${MediaQueries.mobile}) {
        margin-top: 10px;
        margin-bottom: 100px;
    }
`;

const TitleCSS = css`
    text-align: left;
    margin-bottom: 56px;
    @media (max-width: ${MediaQueries.tablet}) {
        margin-bottom: 42px;
    }
    @media (max-width: ${MediaQueries.mobile}) {
        margin-bottom: 30px;
    }
`;

const SubTitleCSS = css`
    text-align: left;
    margin-bottom: 26px;
    @media (max-width: ${MediaQueries.mobile}) {
        margin-bottom: 14px;
    }
`;

const ParagraphCSS = css`
    margin-bottom: 20px;
    text-align: left;
`;

const BoxContainer = styled.div`
   display: flex;
   margin-bottom: 56px;
    @media (max-width: ${MediaQueries.tablet}) {
      margin-bottom: 0;
    }
    @media (max-width: ${MediaQueries.mobile}) {
        flex-wrap: wrap;
        margin-bottom: 0;
    } 
`;

const Box = styled.div`
    flex: 0.5;
    li {
        margin-top: 6px;
        &:first-child {
            margin-top: 0;
        }
    }
    a {
        color: ${Color.black};
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
    @media (max-width: ${MediaQueries.tablet}) {
        margin-bottom: 40px;
    }
    @media (max-width: ${MediaQueries.mobile}) {
        flex: 0 0 100%;
        margin-bottom: 40px;
    }
`;

const DisclaimerContainer = styled.div`
    margin-bottom: 56px;
    & p:not(:last-child) {
        margin-bottom: 20px;
    }
    @media (max-width: ${MediaQueries.tablet}) {
        margin-bottom: 40px;
    }
    @media (max-width: ${MediaQueries.mobile}) {
        margin-bottom: 40px;
    } 
`;

const DefaultContainer = styled.div`
    text-align: left;
    &:not(:last-child) {
       margin-bottom: 56px;
    }
    @media (max-width: ${MediaQueries.mobile}) {
        &:not(:last-child) {
            margin-bottom: 40px;
         }
    } 
    @media (max-width: ${MediaQueries.tablet}) {
        &:not(:last-child) {
            margin-bottom: 32px;
         }
    }
`;

export const LPImprintSC = {
    DisclaimerContainer,
    DefaultContainer,
    BoxContainer,
    Box,

    ContainerCSS,
    TitleCSS,
    SubTitleCSS,
    ParagraphCSS
};
