import { http } from 'api/http';
import { OverviewBidPaginationType, OverviewInfoType } from 'shared/types';

const getInfo = async (): Promise<OverviewInfoType | null> => {
    const { data } = await http.get<OverviewInfoType>('/orders/overview-info/');

    return data;
};

const getBids = async (
    companyId: string,
    url?: string,
): Promise<OverviewBidPaginationType | null> => {
    const { data } = await http.get<OverviewBidPaginationType>(
        url || `/bids/overview/?company_id=${companyId}`,
    );

    return data;
};

export const createOverviewService = () => ({
    getInfo,
    getBids,
});
