import React, { FC } from 'react';

import {
  CMSCustomizationLanguageProgressContainer,
  CMSCustomizationLanguageProgressContainerProps,
} from '../../../progress-container/progress-container';
import { CmsAcordionSection } from 'units/cms/components/cms-acordion-section/cmsAcordionSection.component';
import { FormikFormInput } from 'units/common/components/formik/input/form-input.component';
import { FormikToggleButton } from 'units/common/components/formik/toggle/formik-toggle';
import { CMSRootSC } from 'units/cms/styles';
import { CMSConfirmModalStrings } from 'units/cms/constants';
import { useCMSConfirmModalManager } from 'units/cms/hooks/useCMSConfirmModalManager';
import { ConfirmModal } from 'units/common/components/modal/confirm-modal/confirm-modal.component';
import { useCMSCustomizationPageNavigation } from 'units/cms/hooks/useCMSCustomizationPageNavigation';
import { CMSCustomizationPage } from 'units/cms/store/types';
import { useCMSConfigSavedStatus } from 'units/cms/hooks/selectors/useCMSConfigStatus';

type Props = Pick<CMSCustomizationLanguageProgressContainerProps, 'selectedLanguage' | 'onLanguageChange' | 'initialProgress'>;

export const CMSMissedAuctionsGeneralAccordionContainer: FC<Props> = (props) => {
  const { setSelectedPage } = useCMSCustomizationPageNavigation();
  const isProgressSaved = useCMSConfigSavedStatus();
  const [confirmModalProps, confirmModal] = useCMSConfirmModalManager({
    ...CMSConfirmModalStrings.switchCustomizationPages,
  });
  const redirectToHomepage = () => setSelectedPage(CMSCustomizationPage.Home);
  const onHomepageLinkClick = () => !isProgressSaved
    ? confirmModal.openModal({ confirmHandler: redirectToHomepage }) :
    redirectToHomepage();

  return (
    <>
    <CmsAcordionSection withDevider={false}>
      <CMSCustomizationLanguageProgressContainer
        {...props}
      >
        {({ updateCurrentProgress }) => (
            <>
                <CmsAcordionSection sectionMargin={32} withDevider={false}>
                    <FormikFormInput
                        name="general.title"
                        placeholder="Missed auctions"
                        label="Title"
                        paddingBottom="0"
                        afterChange={({ target }) => {
                            updateCurrentProgress({ name: 'title', isFilled: !!target.value });
                        }}
                    />
                    <CmsAcordionSection
                      title="Newsletter opt in"
                      titleStyle="black"
                      withDevider={false}
                    >
                        <FormikToggleButton
                            name="general.newsletterOptIn"
                            label="Active"
                        />
                    </CmsAcordionSection>
                    <CMSRootSC.SectionTitle>
                      In order to customize the newsletter opt-in or the product cards, go to{' '}
                      <span onClick={onHomepageLinkClick}>Homepage</span>
                    </CMSRootSC.SectionTitle>
                </CmsAcordionSection>
            </>
        )}
      </CMSCustomizationLanguageProgressContainer>
    </CmsAcordionSection>
    <ConfirmModal {...confirmModalProps} />
    </>
  );
};
