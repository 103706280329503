import React, { FC } from 'react';

import { SmallScreenTemplateSC } from './SmallScreenTemplate.styles';
import { getImage, ImageName } from 'components/styles/images';

interface Props {
    message: string;
    imageName: ImageName;
}

export const SmallScreenTemplate: FC<Props> = ({ message, imageName }) => (
    <SmallScreenTemplateSC.Container>
        <SmallScreenTemplateSC.Message>
            {message}
        </SmallScreenTemplateSC.Message>
        <SmallScreenTemplateSC.Icon>
            {getImage(imageName)}
        </SmallScreenTemplateSC.Icon>
        <SmallScreenTemplateSC.Button to="/landing">
             Back to landing page
        </SmallScreenTemplateSC.Button>
    </SmallScreenTemplateSC.Container>
);

