import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import styles from './analytics.module.scss';

// import { ComingSoon } from 'shared/components/ui';

import { LayoutSC } from 'units/common/components/layout/layout-components.styles';
import { ProductAuctionSelect } from '../components/ProductAuctionSelect/ProductAuctionSelect';
import { AnalyticsService } from '../api/analytics-service';
import { Product, Auction, SelectData } from './types';
import { useTranslation } from 'react-i18next';
import { LineChart } from '../components/LineChart/LineChart';
import { PieChart } from '../components/PieChart/PieChart';
import { BarChart } from '../components/BarChart/BarChart';
import { ShortInfo } from '../components/ShortInfo/ShortInfo';

export const Analytics = () => {
    const [products, setProducts] = useState([]);
    const [auctions, setAuctions] = useState(null);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const [selectedProductId, setSelectedProductId] = useState('');
    const [selectedAuction, setSelectedAuction] = useState<SelectData>({ value: '', label: '' });
    const [empty, setEmpty] = useState<boolean>(true);
    const { t } = useTranslation();

    useEffect(() => {
        AnalyticsService.getProducts().then(resp => {
            const list = resp.data.map((p: Product) => ({ label: p.name, value: p.id }));
            setProducts(list);
        });
    }, []);

    const checkEmptyState = (chartData: any) => {
        const empty =
            chartData?.male === 0 && chartData?.female === 0 && chartData?.unspecified === 0;
        setEmpty(empty);
    };

    const loadAuctions = (e: SelectData) => {
        setLoading(true);
        setSelectedProductId(e.value);
        setSelectedAuction({ value: 'all', label: t('analytics_page_all_auctions') });
        AnalyticsService.getAuctions(e.value)
            .then(resp => {
                const list = resp.data.map((a: Auction) => ({
                    label: a.auction_name || 'unknown name',
                    value: a.id,
                }));
                setAuctions(list);
            })
            .catch(err => setLoading(false));

        AnalyticsService.getProductData(e.value).then(response => {
            setLoading(false);
            setData(response.data);
            checkEmptyState(response?.data?.bid_percentage_by_gender);
        });
    };

    const loadAnalyticsData = (e: SelectData) => {
        setSelectedAuction(e);
        if (e.value !== 'all') {
            AnalyticsService.getAuctionData(e.value).then(response => {
                setData(response.data);
                checkEmptyState(response?.data?.bid_percentage_by_gender);
            });
        } else {
            AnalyticsService.getProductData(selectedProductId).then(response => {
                setData(response.data);
                checkEmptyState(response?.data?.bid_percentage_by_gender);
            });
        }
    };

    return (
        <>
            <Helmet>
                <title>{t('analytics_page_title')}</title>
            </Helmet>
            <LayoutSC.PageTitle>{t('analytics_page_title')}</LayoutSC.PageTitle>
            <div className={styles.subtitle}>{t('analytics_page_subtitle')}</div>
            <div className={styles.info}>{t('analytics_page_info')}</div>

            <ProductAuctionSelect
                products={products}
                auctions={auctions}
                onProductSelect={loadAuctions}
                onAuctionSelect={loadAnalyticsData}
                selectedAuction={selectedAuction}
                isLoading={loading}
            />

            <ShortInfo data={data} />

            <div className={styles.graphs}>
                <LineChart
                    selectedAuction={selectedAuction}
                    auctionId={selectedAuction.value || selectedProductId}
                    productId={selectedProductId}
                    empty={empty}
                />
                <PieChart data={data} empty={empty} />
            </div>
            <BarChart
                selectedAuction={selectedAuction}
                auctionId={selectedAuction.value || selectedProductId}
                productId={selectedProductId}
                empty={empty}
            />
        </>
    );
};
