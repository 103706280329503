import { useSelector } from 'react-redux';

import { RootState } from 'redux/root-types';
import { EditProductFormikValues, EditProductFormikGeneralInfo, EditProductFormikSeo } from '../../store/types';
import { EditProductTabs } from '../../constants';
import { CompanyLanguages } from 'units/app/redux/types';

export const useEditProductFormikInitialValues = (language: CompanyLanguages, tab: EditProductTabs) => {
  const values = useSelector<RootState, EditProductFormikValues | undefined>(({ editProduct }) => {
    const languageTabForm = editProduct.form.languages[language]?.[tab];

    if (!languageTabForm) {
      return undefined;
    }

    const resultForGeneralTab = {
      ...languageTabForm,
      ...editProduct.form.commonFields,
    };
    
    switch (tab) {
      case EditProductTabs.general: 
        return resultForGeneralTab as EditProductFormikGeneralInfo;
      case EditProductTabs.seo:
        return languageTabForm as EditProductFormikSeo;
      default:
        return resultForGeneralTab;
    }
  });

  return values;
}
