import { CMSTabId, CMSTab } from './types';
import { CMSUrls } from './urls';
import { CMSThemeId } from './store/types';

export const CMSThemesTab: CMSTab = {
    id: CMSTabId.themes,
    title: 'customization_tab_themes',
    url: CMSUrls.themes,
};

export const CMSThemeSettingsTab: CMSTab = {
    id: CMSTabId.themeSettings,
    title: 'customization_tab_theme_settings',
    url: CMSUrls.themeSettings,
};

export const CMSCustomizationTab: CMSTab = {
    id: CMSTabId.customization,
    title: 'customization_tab_customization',
    url: CMSUrls.customization,
};

export const CMSThemesTitle = {
    [CMSThemeId.Traditional]: 'Traditional',
    [CMSThemeId.Modern]: 'Modern',
    [CMSThemeId.Minimal]: 'Minimal',
    [CMSThemeId.Funky]: 'Funky',
    [CMSThemeId.Luxury]: 'Luxury',
    [CMSThemeId.Estate]: 'Estate',
};

// ms
export const FAKE_OVERVIEW_LOADING_DURATION = 300;

export const CMSTabs: Array<CMSTab> = [CMSThemesTab, CMSThemeSettingsTab, CMSCustomizationTab];

export const CMSConfirmModalStrings = {
    switchTabs: {
        title: 'Are you sure you want to leave the website builder?',
        subTitle: 'Your unsaved changes will be lost',
        confirmTitle: 'I am sure',
        refuseTitle: 'Cancel',
    },
    backToDashboard: {
        title: 'Are you sure you want to leave the website builder?',
        subTitle: 'Your unsaved changes will be lost',
        confirmTitle: 'I am sure',
        refuseTitle: 'Cancel',
    },
    switchCustomizationPages: {
        title: 'Are you sure you want to switch pages?',
        subTitle: 'Your unsaved changes will be lost',
        confirmTitle: 'I am sure',
        refuseTitle: 'Cancel',
    },
};
