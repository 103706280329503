import styled from 'styled-components';

import {  TextStyle5, TextStyle3 } from 'components/styles/text.styles';

const ForgotPasswordLink = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 48px;
  margin-top: -26px;
  
  ${TextStyle5}
  
  a {
    text-decoration: none;
    color: inherit;
    outline: none;

    &:visited {
      color: inherit;
    }

    &:active {
      color: inherit;
    }
  }
`;

const RegistrationText = styled.span`
  ${TextStyle3}
`;

export const SignInSC = {
  ForgotPasswordLink,
  RegistrationText,
};
