import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { getImage } from 'components/styles/images';

import { LPTypography } from '../../ui';

import { AdvantageMocks } from './advantages.mocks';
import { LandingPageSC } from 'units/landing-page/index.styles';
import { LPAdvantagesSC } from './advantages.styles';

export const LPAdvantages: FC = () => {
  const { t } = useTranslation();

  return (
    <LPAdvantagesSC.Root id="benefits">
      <LandingPageSC.SectionSeparator />
      <LPTypography.H2>
        {t('lp_benefit_title')}
      </LPTypography.H2>
      <LPTypography.Text styles={LPAdvantagesSC.TextCss}>
        {t('lp_benefit_text')}
      </LPTypography.Text>
      <LPAdvantagesSC.CardList>
        {AdvantageMocks.advantages.map((item, index) => (
          <LPAdvantagesSC.Card key={`LPAdvantages-box-${index}`}>
            <LPAdvantagesSC.CardBody>
              <LPAdvantagesSC.CardLogo>
                {getImage(item.logoName)}
              </LPAdvantagesSC.CardLogo>
              <LPTypography.H3>
                {t(item.title)}
              </LPTypography.H3>
              <LPTypography.Text styles={LPAdvantagesSC.CardTextCss}>
                {t(item.description)}
              </LPTypography.Text>
            </LPAdvantagesSC.CardBody>
          </LPAdvantagesSC.Card>
        ))}
      </LPAdvantagesSC.CardList>
      <LPTypography.Text styles={LPAdvantagesSC.CompanyTitleCss}>
        {t('lp_benefit_companies_title')}
      </LPTypography.Text>
      <LPAdvantagesSC.LogoList>
        {AdvantageMocks.companies.map((logo, index) => (
          <LPAdvantagesSC.LogoItem key={`LPAdvantages-logo-${index}`}>
            <img src={logo} alt="company"  />
          </LPAdvantagesSC.LogoItem>
        ))}
      </LPAdvantagesSC.LogoList>
    </LPAdvantagesSC.Root>
  )
}
