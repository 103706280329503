import { ApiClient, apiPath } from 'config/api/api';
import { BillingData } from '../types';

const getBilling = async () => {
    const api = ApiClient.getInstance();
    return api.get(`${apiPath}/companies/billing-settings/`);
};

const updateBilling = async (billing: BillingData) => {
    const api = ApiClient.getInstance();
    return api.patch(`${apiPath}/companies/billing-settings/`, billing);
};

const getInvoices = async () => {
    const api = ApiClient.getInstance();
    return api.get(`${apiPath}/payments/invoice/list`);
};

const isDeletable = async () => {
    const api = ApiClient.getInstance();
    return api.get(`${apiPath}/users/deletable/`);
};

const deleteAccount = async (reason: string) => {
    const api = ApiClient.getInstance();
    return api.delete(`${apiPath}/users/delete/`, { data: { reason } });
};

export const BillingService = {
    getBilling,
    updateBilling,
    getInvoices,
    isDeletable,
    deleteAccount,
};
