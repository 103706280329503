import { getClient } from './client';
import { HeadersType } from './helpers';
import { ResponseDataType } from './types';

const url = process.env.REACT_APP_BASE_API_URL;
const version = '/v1';

const get = <T>(path: string, headers?: HeadersType): Promise<ResponseDataType<T>> =>
    getClient<T>(`${url}${version}${path}`, 'GET', headers);

const remove = <T>(path: string, body?: any, headers?: HeadersType): Promise<ResponseDataType<T>> =>
    getClient<T>(`${url}${version}${path}`, 'DELETE', body, headers);

const post = <T>(path: string, body?: any, headers?: HeadersType): Promise<ResponseDataType<T>> =>
    getClient<T>(`${url}${version}${path}`, 'POST', body, headers);

const put = <T>(path: string, body?: any, headers?: HeadersType): Promise<ResponseDataType<T>> =>
    getClient<T>(`${url}${version}${path}`, 'PUT', body, headers);

const patch = <T>(path: string, body?: any, headers?: HeadersType): Promise<ResponseDataType<T>> =>
    getClient<T>(`${url}${version}${path}`, 'PATCH', body, headers);

export const http = {
    get,
    post,
    put,
    patch,
    remove,
};
