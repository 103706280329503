import React, { FC, useCallback, useMemo } from 'react';
import { useField } from 'formik';
import { ValueType, OptionsType } from 'react-select';

import { Select, ISelect, IOption } from '../../select/select.component';
import { UserInputSC } from '../../form/fields/user-input/user-input.styles';

interface Props extends ISelect {
    name: string;
    options: OptionsType<IOption>;
    label?: string;
    onChange?: () => void;
}

export const FormikSelect: FC<Props> = ({ name, options, onChange, label, ...props }) => {
    const [field, meta, helpers] = useField(name);

    const handleChange = useCallback((option: ValueType<IOption, boolean>) => {
        helpers.setValue((option as IOption).value);
        onChange && onChange();
    }, []);

    const handleBlur = useCallback(() => {
        helpers.setTouched(true);
    }, []);

    const selectedOption = useMemo(
        () => options.find(option => option.value === field.value) || null,
        [options, field.value],
    );

    return (
        <>
            {label && <UserInputSC.Label>{label}</UserInputSC.Label>}
            <Select
                options={options}
                value={selectedOption}
                error={meta.touched && meta.error}
                onBlur={handleBlur}
                onChange={handleChange}
                {...props}
            />
        </>
    );
};
