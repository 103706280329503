import styled from 'styled-components';

import { sizes } from 'components/styles/constants.styles';
import { TextStyle25 } from 'components/styles/text.styles';

const Wrapper = styled.div`
    max-width: 100%;
    margin-bottom: ${sizes.md_padding};
    @media screen and (min-width: 1850px) {
        margin-right: 20%;
    }
`;

const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    @media screen and (min-width: 1600px) {
        grid-template-columns: repeat(4, 1fr);
    }

    column-gap: 20px;
    row-gap: 20px;
    margin-bottom: 24px;
`;

const Search = styled.div`
    width: 50%;
    margin-bottom: 8px;
`;

const NoItemsContainer = styled.div`
    margin: 170px auto 0;
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    div {
        display: flex;
        justify-content: center;
        margin-bottom: 32px;
    }

    p {
        margin: 0 0 24px 0;
        ${TextStyle25}
        line-height: 2;
        text-align: center;
    }
`;

export const ProductListSC = {
    Container,
    Search,
    Wrapper,
    NoItemsContainer,
};
