import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { useFormikContext } from 'formik';

import { Auction } from '../constants';
import { AuctionValues } from '../types';
import { getAuctionStatuses } from '../helpers';
import { useWorkingMode } from '../hooks';
import { CreateAuctionSC } from '../create-auction.styles';
import { FormikRadioButtonGroup } from 'units/common/components/formik/radio/radio-group.component';

const radioButtons = [
  { id: Auction.buyNow, label: 'Buy now', },
  { id: Auction.auction, label: 'Auction', },
];

export const AuctionTypeSelect: FC = () => {
  const { values } = useFormikContext<AuctionValues>();
  const { isActive, isDraft, isScheduled } = getAuctionStatuses(values);
  const { isEditMode, isViewMode, isCreateMode } = useWorkingMode(useLocation());

  if (isViewMode) {
    return null;
  }

  return (
    <>
      {(isCreateMode || (isEditMode && !isActive)) && (
        <CreateAuctionSC.SectionTitle>
          Choose a product
        </CreateAuctionSC.SectionTitle>
      )}
      {isEditMode && !isActive && (
          <CreateAuctionSC.SubLabel>
            You can change a product if needed
          </CreateAuctionSC.SubLabel>
      )}
      {(isCreateMode || (isEditMode && (isDraft || isScheduled))) && (
        <FormikRadioButtonGroup
          radioList={radioButtons}
          margin='0 0 24px'
          name='auctionType'
        />
      )}
    </>
  );
};
