import React from 'react';

import { Button, Modal, Typography } from 'shared/components/ui';

import styles from './Confirm.module.scss';

type ConfirmModalProps = {
    isOpen: boolean;
    title: string;
    text: string;
    cancelButtonText?: string;
    confirmButtonText?: string;
    icon?: string;
    onClose: () => void;
    onCancel?: () => void;
    onConfirm: () => void;
};

export const ConfirmModal: React.FC<ConfirmModalProps> = ({
    isOpen,
    title,
    text,
    icon,
    cancelButtonText,
    confirmButtonText,
    onClose,
    onCancel,
    onConfirm,
    children,
}) => (
    <Modal className={styles.root} isOpen={isOpen} maxWidth={613} onClose={onClose}>
        <Typography.Title className={styles.title} htmlElement="h3">
            {title}
        </Typography.Title>
        {icon && <img className={styles.icon} src={icon} alt=""></img>}
        <Typography.Text className={styles.text} wysiwyg={text} />
        {children}
        <div className={styles.buttons}>
            {onCancel && (
                <Button className={styles.button} type="transparent" onClick={onCancel}>
                    {cancelButtonText || 'Cancel'}
                </Button>
            )}
            <Button className={styles.button} onClick={onConfirm}>
                {confirmButtonText || 'Confirm'}
            </Button>
        </div>
    </Modal>
);
