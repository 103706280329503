import { http } from 'api/http';
import { ResponseDataType } from 'api/types';

import { UserAccountItemType } from 'shared/types/auth';

const getAccountType = async (): Promise<ResponseDataType<UserAccountItemType[]>> => {
    return http.get('/users/user-types-list/');
};

export const authService = () => ({
    getAccountType,
});
