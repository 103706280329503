import React, { FC } from 'react';

import { CMSOverviewSC } from './styles';
import { CMSOverviewRoutes } from '../../routes';
//import { useCMSThemeOverviewLoadingManager } from '../../hooks/useCMSThemeOverviewLoadingManager';

export const CMSOverviewRoot: FC = () => {
  //const [isThemeOverviewLoading] = useCMSThemeOverviewLoadingManager();

  return (
    <CMSOverviewSC.OverviewContainer>
      <CMSOverviewRoutes />
      {/* { isThemeOverviewLoading && <CMSOverviewSC.OverviewLoadingCover /> } */}
    </CMSOverviewSC.OverviewContainer>
  );
}
