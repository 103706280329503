import React from 'react';
import { OverviewStatusInfo } from 'units/overview/types';
import { StatusButton } from '../../ui';
import WalletIcon from 'assets/images/wallet.svg';
import BagIcon from 'assets/images/bag.svg';

import styles from './OverviewInfo.module.scss';
import { ParseHelper } from 'shared/helpers';
import { StatusCard, Typography } from 'shared/components/ui';
import clsx from 'clsx';

type OverviewInfoPageProps = {
    info: OverviewStatusInfo;
};

export const OverviewInfoSection: React.FC<OverviewInfoPageProps> = ({ info }) => {
    return (
        <div className={styles.info}>
            <StatusCard
                title="Sales this week"
                icon={WalletIcon}
                mainLabel={ParseHelper.formatCurrency(info?.weekTotalIncome)}
                footer={
                    <>
                        <Typography.Text className={styles.balance}>
                            <span
                                className={clsx({
                                    [styles['is-positive']]: info?.totalIncomeDifference > 0,
                                    [styles['is-negative']]: info?.totalIncomeDifference < 0,
                                })}
                            >
                                {`${ParseHelper.numberSign(
                                    info?.totalIncomeDifference,
                                )} ${ParseHelper.formatCurrency(info?.totalIncomeDifference, 2)}`}
                                {' compared to last week'}
                            </span>
                        </Typography.Text>
                        <Typography.Text className={styles.balance}>
                            <span>
                                <span className={styles.hidden}>
                                    {ParseHelper.numberSign(info?.totalIncomeDifference)}
                                </span>
                                {` ${ParseHelper.formatCurrency(info?.refunded, 2)}`}
                                {' refunded this week'}
                            </span>
                        </Typography.Text>
                    </>
                }
            />
            <StatusCard
                title="Orders this week"
                icon={BagIcon}
                mainLabel={info?.weekTotalOrdersCount}
                footer={
                    <Typography.Text className={styles.balance}>
                        <span
                            className={clsx({
                                [styles['is-positive']]: info?.totalOrderDifference > 0,
                                [styles['is-negative']]: info?.totalOrderDifference < 0,
                            })}
                        >
                            {`${ParseHelper.numberSign(
                                info?.totalOrderDifference,
                            )} ${info?.totalOrderDifference.toString().replace('-', '')}`}
                            {' compared to last week'}
                        </span>
                    </Typography.Text>
                }
            />
            <div className={styles.buttons}>
                <StatusButton num={info?.unfulfilledOrders} type="unfulfilled" />
                <StatusButton num={info?.unpaidOrders} type="unpaid" />
            </div>
        </div>
    );
};
