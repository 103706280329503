import * as yup from 'yup';

import { CompanyLanguages } from 'units/app/redux/types';

export const languageSettingsValidationSchema = yup.object({
    languages: yup.array()
        .of(yup.string().oneOf([CompanyLanguages.en, CompanyLanguages.de, CompanyLanguages.fr, CompanyLanguages.it]))
        .required()
        .max(4)
});
