import React, { FC } from 'react';
import { useField } from 'formik';

import { CMSCustomizationHomepageUSPSection as USPSection } from './usp-accordion.component';
import { CmsAcordionSection } from 'units/cms/components/cms-acordion-section/cmsAcordionSection.component';
import { CMSUploader } from 'units/cms/components/cms-uploader/cms-uploader.component';
import { FormikFormInput } from 'units/common/components/formik/input/form-input.component';
import { FormikFormTextArea } from 'units/common/components/formik/textarea/form-textarea.comonent';
import { TrackProgressField } from 'units/common/hooks/useTrackProgress';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

interface Props {
    sections: Array<{
        key: USPSection;
        iconPreview?: { id: string; src: string };
        iconError?: string;
    }>;
    saveIcon: (section: USPSection, files: Array<File>) => void;
    deleteIcon: (section: USPSection, id: string) => void;
    updateProgress: (field: TrackProgressField) => void;
}

enum USPSectionLabels {
    'usp1' = 'USP 1',
    'usp2' = 'USP 2',
    'usp3' = 'USP 3',
}

const staticUSPSection = (section: USPSection, sectionLabel: USPSectionLabels, t: TFunction) => ({
    section,
    title: {
        name: `usp.${section}.title`,
        progressName: `${section}.title`,
        label: `${sectionLabel} ${t('customization_usp_title')}`,
        placeholder: t('customization_usp_title_placeholder'),
    },
    description: {
        name: `usp.${section}.description`,
        progressName: `${section}.description`,
        label: `${sectionLabel} ${t('customization_usp_description')}`,
        placeholder: t('customization_usp_description_placeholder'),
    },
    icon: {
        label: sectionLabel,
        title: t('customization_hero_icon_description'),
        subtitle: t('theme_settings_max_size', { width: 150, height: 150 }),
        loadingTitle: t('customization_hero_icon_loading'),
        maxDimension: {
            height: 150,
            width: 150,
        },
        dimensionErrorMessage: t('theme_settings_max_size', { width: 150, height: 150 }),
    },
});

const isLastSection = (index: number, length: number) => index === length - 1;

export const CMSCustomizationHomePageUSPList: FC<Props> = ({
    saveIcon,
    deleteIcon,
    sections,
    updateProgress,
}) => {
    const { t } = useTranslation();
    const [{ value: isSectionActive }] = useField<boolean>('usp.isActive');

    return (
        <>
            {sections.map((section, index) => {
                const sectionProps = staticUSPSection(
                    section.key,
                    USPSectionLabels[section.key],
                    t,
                );

                return (
                    <CmsAcordionSection
                        key={section.key}
                        withDevider={!isLastSection(index, sections.length)}
                    >
                        <CMSUploader
                            {...sectionProps.icon}
                            saveFiles={files => saveIcon(section.key, files)}
                            deleteFile={id => deleteIcon(section.key, id)}
                            previewFile={section.iconPreview}
                            error={section.iconError}
                            disabled={!isSectionActive}
                        />
                        <FormikFormInput
                            {...sectionProps.title}
                            paddingBottom="0"
                            disabled={!isSectionActive}
                            afterChange={({ target }) => {
                                updateProgress({
                                    name: sectionProps.title.progressName,
                                    isFilled: !!target.value,
                                });
                            }}
                        />
                        <FormikFormTextArea
                            {...sectionProps.description}
                            paddingBottom={!isLastSection(index, sections.length) ? '0' : undefined}
                            disabled={!isSectionActive}
                            afterChange={({ target }) => {
                                updateProgress({
                                    name: sectionProps.description.progressName,
                                    isFilled: !!target.value,
                                });
                            }}
                        />
                    </CmsAcordionSection>
                );
            })}
        </>
    );
};
