import React, { FC, useMemo } from 'react';
import { NumberFormatProps } from 'react-number-format';

import { BaseFormInputLayout, IBaseFormInputLayout } from './base-form-input-layout.component';
import { FormInputSC } from './form-input.styles';
import { BaseMoneyInput } from '../money-input/money-input';
import { BaseNumberInput } from '../number-input/number-input';
import { BaseRoundNumberInput } from '../round-number-input/round-number-input';

import { ImageName, getImage } from 'components/styles/images';
import { BaseStepInput } from '../step-input/step-input';

export type IBaseFormInput = IBaseFormInputLayout
  & React.InputHTMLAttributes<HTMLInputElement>
  & Omit<NumberFormatProps, 'type'>
  & {
  postfix?: string;
  withBackground?: boolean;
  onClear?: () => void;
}

export const BaseFormInput: FC<IBaseFormInput> = React.memo(({
  // BaseFormInput props
  postfix,
  withBackground,
  // BaseFormInputLayout props
  label,
  subLabel,
  error,
  hideError,
  withMargins,
  widthPercents,
  paddingBottom,
  disabled,
  onClear,
  // input props
  type,
  ...inputProps
}) => {
  const as = useMemo(() => {
    switch(type) {
      case 'money': return BaseMoneyInput;
      case 'number': return BaseNumberInput;
      case 'round-number': return BaseRoundNumberInput;
      case 'step': return BaseStepInput;
      default: return 'input';
    }
  }, [type]);

  return (
    <BaseFormInputLayout
      label={label}
      subLabel={subLabel}
      error={error}
      hideError={hideError}
      withMargins={withMargins}
      widthPercents={widthPercents}
      paddingBottom={paddingBottom}
      disabled={disabled}
    >
      <FormInputSC.InputWrapper>
        <FormInputSC.BaseInput
          {...inputProps}
          as={as}
          type={type || 'text'}
          withBackground={withBackground}
          disabled={disabled}
          spaceForClearBtn={!!onClear}
        />
        { postfix && <FormInputSC.InputPostfix>{ postfix }</FormInputSC.InputPostfix> }
        {!!onClear && <FormInputSC.ClearBtn onClick={onClear}>{getImage(ImageName.clear)}</FormInputSC.ClearBtn>}
      </FormInputSC.InputWrapper>
    </BaseFormInputLayout>
  );
});
