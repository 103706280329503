import React, { useCallback, FC } from 'react';
import { useFormikContext, FormikHelpers } from 'formik';

import { Accordion } from 'units//common/components/accordion/accordion.component';
import { CMSProductDetailPageCustomizationSettings } from 'units//cms/store/types/customization/product-detail-page';
import { CMSProductDetailPageSectionHeaders } from '..';
import { CMSCustomizationLanguageProgressContainerProps } from '../../../progress-container/progress-container';
import { SectionProgress } from 'units//cms/helpers/prepare-customization-progress';
import { CMSCustomizationProductDetailPageSectionName } from 'units//cms/types';
import { CMSProductDetailPageHeroAccordionContainer } from '../hero/hero-accordion.container';
import { CMSProductDetailPageNewsletterAccordionContainer } from '../newsletter/newsletter-accordion.container';

type SectionCommonProps = Pick<CMSCustomizationLanguageProgressContainerProps, 'selectedLanguage' | 'onLanguageChange'>;

type Props = SectionCommonProps & {
  sectionProgress: SectionProgress<CMSCustomizationProductDetailPageSectionName, any>;
  activeSections: Array<string>;
  handleAccordionExpandedChange: (
    expanded: Array<string>,
    languageSettings: CMSProductDetailPageCustomizationSettings,
    { resetForm }: Pick<FormikHelpers<CMSProductDetailPageCustomizationSettings>, 'resetForm'>,
  ) => void;
}

export const CMSCustomizationProductDetailPageAccordions: FC<Props> = ({
  handleAccordionExpandedChange,
  sectionProgress,
  activeSections,
  ...sectionCommonProps
}) => {
  const { values, resetForm } = useFormikContext<CMSProductDetailPageCustomizationSettings>();

  const accordionOnChange = useCallback((expanded: Array<string>) => {
    handleAccordionExpandedChange(expanded, values, { resetForm });
  }, [handleAccordionExpandedChange, values, resetForm]);

  return (
    <Accordion
      onChange={accordionOnChange}
      items={[
        {
          header: CMSProductDetailPageSectionHeaders.hero,
          uuid: 'hero',
          content: (
            <CMSProductDetailPageHeroAccordionContainer
              initialProgress={sectionProgress.hero}
              {...sectionCommonProps}
            />
          )
        },
/*         {
          header: CMSProductDetailPageSectionHeaders.relatedProducts,
          uuid: 'relatedProducts',
          content: (
            (
              <CMSProductDetailPageRelatedProductsAccordionContainer
                initialProgress={sectionProgress.relatedProducts}
                {...sectionCommonProps}
              />
            )
          )
        }, */
        {
          header: CMSProductDetailPageSectionHeaders.newsletterOptIn,
          uuid: 'newsletterOptIn',
          content: (
            <CMSProductDetailPageNewsletterAccordionContainer
              initialProgress={sectionProgress.newsletterOptIn}
              {...sectionCommonProps}
            />
          )
        },
      ]}
      preExpanded={['hero']}
    />
  );
}
