import React, { useCallback, FC, useMemo, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet-async';

import { TransparentButton } from 'units/common/components/buttons/transparent/transparent.component';
import { CMSCustomizationTab, CMSThemesTitle } from 'units/cms/constants';
import { PrimaryButton } from 'units/common/components/buttons/primary/primary.component';
import { CMSThemesSidebarSC } from './sidebar.styles';
import { CMSThemeId } from 'units/cms/store/types';
import { CMSThemeList } from '../theme-list/theme-list.component';
import { CMSThemeListFontFamily } from '../theme-list/theme-list.styles';
import { useCMSTabsNavigation } from 'units/cms/hooks/useCMSTabsNavigation';
import { useCMSThemeManager } from 'units/cms/hooks/useCMSThemeManager';
import { resetCMSThemeSettingsListThunk } from 'units/cms/store/thunks';
import { NotificationContext } from 'shared/providers';
import { useTranslation } from 'react-i18next';

const getFontFamilyForTheme = (themeId: CMSThemeId) => {
    switch (themeId) {
        case CMSThemeId.Traditional:
            return CMSThemeListFontFamily.YesevaOne;
        case CMSThemeId.Modern:
            return CMSThemeListFontFamily.Manrope;
        case CMSThemeId.Luxury:
            return CMSThemeListFontFamily.MyanmarMN;
        default:
            return CMSThemeListFontFamily.Default;
    }
};

export const CMSThemesSidebar: FC = () => {
    const { showNotification } = useContext(NotificationContext);
    const { t } = useTranslation();
    const {
        availableThemes,
        selectedTheme,
        activeTheme,
        selectTheme,
        selectActiveTheme,
        saveSelectedTheme,
    } = useCMSThemeManager();
    const dispatch = useDispatch();
    const { navigateToTab } = useCMSTabsNavigation();
    //const [isThemeOverviewLoading] = useCMSThemeOverviewLoadingManager();

    useEffect(
        () => () => {
            selectActiveTheme();
        },
        [],
    );

    // Is theme allowed to be saved (make ative for the storefront).
    const isAllowedToSave = useMemo(() => activeTheme.id !== selectedTheme.id, [
        activeTheme.id,
        selectedTheme.id,
    ]);

    const navigateToCustomization = useCallback(() => navigateToTab(CMSCustomizationTab), [
        navigateToTab,
    ]);

    const handleSelectTheme = useCallback(
        (themeId: CMSThemeId) => {
            /*     if (isThemeOverviewLoading) {
      return;
    } */

            selectTheme(themeId);
        },
        [selectTheme],
    );

    const handleSwitchToDefaultTheme = useCallback(() => {
        dispatch(resetCMSThemeSettingsListThunk(showNotification));
    }, []);

    const handleSaveSelectedTheme = useCallback(() => saveSelectedTheme(), [saveSelectedTheme]);

    const themes = useMemo(
        () =>
            availableThemes.map(({ id, name }) => ({
                id: id,
                title: CMSThemesTitle[id] || name,
                customFont: getFontFamilyForTheme(id),
            })),
        // .slice(0, 3), // TODO remove slice when other themes are implemented
        [availableThemes],
    );

    return (
        <>
            <Helmet>
                <title>CMS - Theme</title>
            </Helmet>
            <CMSThemeList
                themes={themes}
                selectedTheme={selectedTheme.id}
                handleThemeClick={handleSelectTheme}
            />
            <CMSThemesSidebarSC.CTAContainer>
                <PrimaryButton
                    onClick={isAllowedToSave ? handleSaveSelectedTheme : navigateToCustomization}
                >
                    {isAllowedToSave
                        ? t('customization_save_template', { title: selectedTheme.title })
                        : t('customization_go_to_customization')}
                </PrimaryButton>
                <TransparentButton
                    onClick={isAllowedToSave ? navigateToCustomization : handleSwitchToDefaultTheme}
                >
                    {isAllowedToSave
                        ? t('customization_go_to_customization')
                        : t('customization_default_style')}
                </TransparentButton>
            </CMSThemesSidebarSC.CTAContainer>
        </>
    );
};
