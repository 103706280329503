import { useMemo, useState, useCallback } from 'react';

import { ConfirmModalProps } from 'units/common/components/modal/confirm-modal/confirm-modal.component';
import { EmptyFunction } from 'units/common/constants';

type ModalHandlers = {
  confirmHandler?: () => void;
  refuseHandler?: () => void;
}

type ModalOptions = {
  title: string;
  subTitle: string;
  confirmTitle: string;
  refuseTitle: string;
} & ModalHandlers;

type Hook = (options: ModalOptions) => [
  ConfirmModalProps,
  {
    openModal: (handlers: ModalHandlers) => void;
    closeModal: () => void;
  }
];

// Hook to use in pair with ConfirmModal.
export const useCMSConfirmModalManager: Hook = ({
  title,
  subTitle,
  confirmTitle,
  refuseTitle,
  confirmHandler,
  refuseHandler,
}) => {
  const [modalState, setModalState] = useState(() => ({
    isOpen: false,
    confirmHandler: confirmHandler || EmptyFunction,
    refuseHandler: refuseHandler || EmptyFunction,
  }));

  const closeModal = useCallback(() => {
    setModalState((state) => ({
      ...state,
      isOpen: false,
    }));
  }, []);

  const handleConfirmClick = useCallback(() => {
    modalState.confirmHandler();
    closeModal();
  }, [modalState.confirmHandler, closeModal]);

  const handleRefuseClick = useCallback(() => {
    modalState.refuseHandler();
    closeModal();
  }, [modalState.refuseHandler, closeModal]);

  const handleOpenModal = useCallback((handlers?: ModalHandlers) => (
    setModalState((state) => {
      const newState = {
        ...state,
        isOpen: true,
      };

      if (handlers?.confirmHandler) {
        newState.confirmHandler = handlers.confirmHandler;
      }
      
      if (handlers?.refuseHandler) {
        newState.refuseHandler = handlers.refuseHandler;
      }

      return newState;
    })
  ), []);

  const handleCloseModal = useCallback(
    () => closeModal(),
    [closeModal],
  );

  const modalProps: ConfirmModalProps = useMemo(() => ({
    isOpen: modalState.isOpen,
    title: title,
    subtitle: subTitle,
    buttonTitle: {
      confirm: confirmTitle,
      refuse: refuseTitle,
    },
    confirmHandler: handleConfirmClick,
    refuseHandler: handleRefuseClick,
    onCloseModal: handleCloseModal,
  }), [
    modalState.isOpen,
    title,
    subTitle,
    confirmTitle,
    refuseTitle,
    handleConfirmClick,
    handleRefuseClick,
    handleCloseModal,
  ]);

  return [modalProps, {
    openModal: handleOpenModal,
    closeModal: handleCloseModal,
  }];
};
