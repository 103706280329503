import React, { FC, useState, useEffect, useCallback } from 'react';
import { Form, useFormikContext } from 'formik';

import { CMSRootSC } from 'units/cms/styles';
import { PrimaryButton } from 'units/common/components/buttons/primary/primary.component';
import { TransparentButton } from 'units/common/components/buttons/transparent/transparent.component';
import { useCMSConfigSaveStatusManager } from 'units/cms/hooks/useCMSConfigSaveStatusManager';
import { useCMSCompare } from 'units/cms/hooks/useCMSCompare';
import { CompanyLanguages } from 'units/app/redux/types';
import { CMSCustomizationOverallSettings } from 'units/cms/store/types/customization';
import { CMSCustomizationSubmitHandler } from '../sidebar/homepage';
import { useTranslation } from 'react-i18next';

type Props = {
    children: React.ReactNode;
    savedSettings: CMSCustomizationOverallSettings<any>;
    selectedLanguage: CompanyLanguages;
    onSave: CMSCustomizationSubmitHandler;
    onSaveAndPublish: CMSCustomizationSubmitHandler;
};

type LanguageStatus = {
    [lng in CompanyLanguages]?: boolean;
};

const compareOptions = {
    wait: 1000,
    trailing: true,
    leading: false,
};

const useCustomzationCompare = (
    savedOverallSettings: CMSCustomizationOverallSettings<any>,
    currentSettings: any,
    selectedLanguage: CompanyLanguages,
) => {
    // Status of comparing language settings. Only if every language settings are equal, it's save status true.
    const [languageStatus, setLanguageStatus] = useState<LanguageStatus>({});
    const [, { setSaveStatus }] = useCMSConfigSaveStatusManager();

    const handleCompare = useCallback(
        (isEqual: boolean) => {
            setLanguageStatus(state => ({
                ...state,
                [selectedLanguage]: isEqual,
            }));
        },
        [selectedLanguage],
    );

    useCMSCompare(
        currentSettings,
        savedOverallSettings[selectedLanguage],
        handleCompare,
        compareOptions,
    );

    useEffect(() => {
        setLanguageStatus({});
    }, [savedOverallSettings]);

    useEffect(() => {
        const isSaved = Object.values(languageStatus).every(status => status);
        isSaved ? setSaveStatus(true) : setSaveStatus(false);
    }, [languageStatus]);
};

export const CMSCustomizationSidebarForm: FC<Props> = ({
    children,
    savedSettings,
    selectedLanguage,
    onSave,
    onSaveAndPublish,
}) => {
    const { t } = useTranslation();
    const { values, setSubmitting, validateForm, setErrors, setTouched } = useFormikContext();

    useCustomzationCompare(savedSettings, values, selectedLanguage);

    return (
        <Form>
            {children}
            <CMSRootSC.CTAContainer>
                <PrimaryButton
                    id="submit-button"
                    type="button"
                    onClick={() => {
                        onSave(values, { setSubmitting, validateForm, setErrors, setTouched });
                    }}
                >
                    {t('theme_settings_save')}
                </PrimaryButton>
                <TransparentButton
                    type="button"
                    onClick={() => {
                        onSaveAndPublish(values, {
                            setSubmitting,
                            validateForm,
                            setErrors,
                            setTouched,
                        });
                    }}
                >
                    {t('theme_settings_save_and_publish')}
                </TransparentButton>
            </CMSRootSC.CTAContainer>
        </Form>
    );
};
