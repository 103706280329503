import * as React from 'react';
import { Switch, Route, RouteComponentProps } from 'react-router-dom';

import { UsersPage } from './users.component';
import { UserEdit } from './user/user-edit.component';
import { SettingsUrls } from '../../urls';

export const SettingsUserRoutes: React.FC<RouteComponentProps> = () => (
  <Switch>
    <Route exact path={SettingsUrls.users.index} component={UsersPage} />
    <Route path={SettingsUrls.users.userEdit} component={UserEdit} />
  </Switch>
);
