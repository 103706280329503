import styled from 'styled-components';

import { Color, sizes } from 'components/styles/constants.styles';
import { TextStyle8 } from 'components/styles/text.styles';

const Table = styled.table`
  font-size: 14px;
  line-height: 24px;
  width: 100%;
  color: #9DA7B7;
  th,
  td{
    padding: 8px;
  }
  th{
    font-weight: normal;
    text-align: left;
    padding-bottom: 24px;
  }
  // width: 100%;
  // background: ${Color.white};
  // padding: ${sizes.sm_padding} 0;
  // margin: ${sizes.sm_padding} 0;
  // border-radius: ${sizes.border_radius};
  // border-spacing: 0;
  //
  // tbody:before {
  //   content: "-";
  //   display: block;
  //   line-height: ${sizes.base_line_height};
  //   color: transparent;
  // }
  //
  // th,
  // td {
  //   ${TextStyle8};
  //   margin: 0;
  //   padding: 8px;
  //   text-align: left;
  //   font-weight: normal;
  //   color: ${Color.bluey_grey};
  // }
  //
  // td:last-child {
  //   text-align: right;
  // }
  //
  // tr td:first-child,
  // tr th:first-child {
  //   padding-left: 32px;
  // }

  .active-row {
    background: ${Color.pale_grey};
  }
`;

const TableBox = styled.div`
  border-radius: 10px;
  background-color: #fff;
  padding: 12px 24px;
  margin-top: ${sizes.sm_padding};
  margin-bottom: ${sizes.sm_padding};
`;

export const TableSC = {
  Table,
  TableBox
};
