import styled from 'styled-components';

import { Color, sizes } from 'components/styles/constants.styles';
import { UserDropdownText } from 'components/styles/text.styles';

const Tooltip = styled.div`
    position: relative;
    display: none;

    span {
        font-size: 12px;

        &:hover {
            cursor: pointer;
        }

        &:hover ~ label {
            display: block;
        }
    }

    label {
        ${UserDropdownText};
        top: 20px;
        z-index: 1;
        left: -190px;
        width: 200px;
        height: 56px;
        display: none;
        position: absolute;
        font-weight: normal !important;
        color: ${Color.bluey_grey} !important;
        background-color: ${Color.pale_grey};
        border-radius: ${sizes.border_radius};
        padding: ${sizes.xs_padding} ${sizes.sm_padding};
    }
`;

const Container = styled.div`
    display: flex;
    label {
        color: ${Color.black};
        font-weight: 700;
        margin-right: 7px;
    }

    &:hover ${Tooltip} {
        display: block;
    }
`;

export const StatusSC = {
    Container,
    Tooltip,
};
