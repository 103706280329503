import { yup } from 'config/yup';
import { UserInputValidationErrors } from 'units/common/constants';

export const inviteUserValidationSchema = yup.object({
  firstName: yup.string()
    .required(UserInputValidationErrors.firstName.required),
  lastName: yup.string()
    .required(UserInputValidationErrors.lastName.required),
  email: yup.string()
    .email(UserInputValidationErrors.email.notValid)
    .required(UserInputValidationErrors.email.required),
});
