import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { FormikHelpers } from 'formik';
import dayjs from 'dayjs';

import { ParseHelper } from 'shared/helpers';

import { NotificationContext } from 'shared/providers';

import { api } from 'api';
import { OrdersService } from '../../api';

import { ConfirmModal } from 'shared/components/modals';
import { Typography } from 'shared/components/ui';

import { BackButton } from 'units/common/components/back-button/back-button.component';
import { TransparentButton } from 'units/common/components/buttons/transparent/transparent.component';
import { SecondaryButtonSC } from 'units/common/components/buttons/secondary/secondary.styles';

import { OrderUrls } from '../../urls';
import { RefundOrder } from 'shared/types';
import { OrderDetailType, OrderPaymentStatusEnum, OrderStatusEnum } from '../../types';
import { FulFillModal, RefundModal } from '../../components';

import { DetailSC } from './detail.styles';

import wallet from '../../images/wallet.svg';

type HistoryItem = {
    date: dayjs.Dayjs;
    text: string;
};

export const Detail = () => {
    const { id } = useParams<{ id: string }>();
    const { showNotification } = useContext(NotificationContext);
    const [detail, setDetail] = useState<null | OrderDetailType>(null);
    const [fulFillModalState, setFullFillModalState] = useState(false);
    const [refundModalState, setRefundModalState] = useState(false);
    const [confirmCancelModalState, setConfirmCancelModalState] = useState(false);
    const [inProgress, setInProgress] = useState<boolean>(false);

    const actionsHistory: HistoryItem[] = useMemo(() => {
        const result: HistoryItem[] = [];

        if (detail) {
            if (detail.data.created_at)
                result.push({ text: 'Order placed', date: dayjs(detail.data.created_at) });
            if (detail.payment?.created_at)
                result.push({ date: dayjs(detail.payment.created_at), text: 'Payment received' });
            if (detail.data.status_changed_at && detail.data.status !== OrderStatusEnum.Unfulfilled)
                result.push({
                    text:
                        detail.data.status === OrderStatusEnum.Cancelled
                            ? 'Offer cancelled'
                            : 'Order fulfilled',
                    date: dayjs(detail.data.status_changed_at),
                });

            detail.payment?.refunds.forEach(item => {
                if (item.created_at)
                    result.push({ date: dayjs(item.created_at), text: 'Refund issued' });
                if (item.canceled_at)
                    result.push({ date: dayjs(item.canceled_at), text: 'Refund canceled' });
                if (item.failed_at)
                    result.push({ date: dayjs(item.failed_at), text: 'Refund failed' });
            });
        }

        return result.sort((a: HistoryItem, b: HistoryItem) => a.date.diff(b.date));
    }, [detail]);

    const alreadyRefunded = useMemo(() => {
        let refunds = 0;

        if (detail && detail.payment && detail.payment.refunds) {
            detail.payment.refunds.forEach(item => (refunds += Number(item.amount)));
        }

        return refunds;
    }, [detail]);

    const allRefunded = useMemo(() => {
        let refunds = 0;

        if (detail && detail.payment && detail.payment.refunds) {
            detail.payment.refunds.forEach(item => {
                if (!item.failed_at && !item.canceled_at) {
                    refunds += Math.round((Number(item.amount) + Number.EPSILON) * 100) / 100;
                }
            });
        }

        return refunds;
    }, [detail]);

    const anyFailedRefund = useMemo(() => {
        if (detail && detail.payment)
            return !!detail.payment.refunds.find(item => item.failed_at !== null);

        return false;
    }, [detail]);

    const canRefund = useMemo(() => {
        if (detail) return Number(detail.total) - alreadyRefunded;

        return 0;
    }, [alreadyRefunded, detail]);

    useEffect(() => {
        OrdersService.get(id).then(({ data }) => setDetail(data));
    }, []);

    const onRefund = async (values: RefundOrder, helpers: FormikHelpers<RefundOrder>) => {
        setInProgress(true);
        if (detail && !inProgress) {
            const { data, status } = await api.orderService.refund(detail.data.id, values);

            if (status === 201) {
                helpers.resetForm();
                setRefundModalState(false);
                const toUpdate = { ...detail };
                toUpdate.current_balance = ParseHelper.preparePrice(
                    (
                        Math.round(
                            (Number(toUpdate.current_balance) -
                                Number(values.amount) +
                                Number.EPSILON) *
                                100,
                        ) / 100
                    ).toString(),
                );
                detail.payment?.refunds.push(data);
                setDetail(toUpdate);

                setInProgress(false);

                showNotification('success', 'Your refund was successful');
            } else {
                showNotification('success', 'Something went wrong. Please try again');
            }
        }
    };

    if (!detail) {
        return null;
    }

    const updateStatus = (status: OrderStatusEnum) => {
        setDetail({ ...detail, ...{ data: { ...detail.data, ...{ status } } } });
    };

    const onCancelConfirm = () => {
        if (id) {
            try {
                api.orderService.action(id, OrderStatusEnum.Cancelled);
                updateStatus(OrderStatusEnum.Cancelled);
                setConfirmCancelModalState(false);
                showNotification('success', 'Order was successfully canceled');
                setDetail({
                    ...detail,
                    data: { ...detail.data, status_changed_at: new Date().toString(), status: 3 },
                });
            } catch (e) {
                showNotification('success', 'Something went wrong. Please try again');
            }
        }
    };

    const onConfirm = () => {
        if (id) {
            try {
                api.orderService.action(id, OrderStatusEnum.Fulfilled);
                updateStatus(OrderStatusEnum.Fulfilled);
                setDetail({
                    ...detail,
                    data: { ...detail.data, status_changed_at: new Date().toString(), status: 1 },
                });
                setFullFillModalState(false);
                showNotification('success', 'Order successfully fulfilled');
            } catch (e) {
                showNotification('success', 'Something went wrong. Please try again');
            }
        }
    };

    return (
        <>
            <Helmet>
                <title>Order</title>
            </Helmet>
            <BackButton destinationText="Orders" to={OrderUrls.index} />
            <DetailSC.Header>
                <Typography.Title htmlElement="h2">Order №{detail.data.id}</Typography.Title>
                <div>
                    {detail.data.status === OrderStatusEnum.Unfulfilled && (
                        <>
                            {allRefunded === Number(detail?.total) && (
                                <TransparentButton
                                    onClick={setConfirmCancelModalState.bind(null, true)}
                                >
                                    Cancel order
                                </TransparentButton>
                            )}
                            {canRefund > 0 && !!detail.payment && (
                                <DetailSC.FullFillBtn
                                    onClick={setFullFillModalState.bind(null, true)}
                                >
                                    Fulfill order
                                </DetailSC.FullFillBtn>
                            )}
                        </>
                    )}
                </div>
            </DetailSC.Header>
            <DetailSC.Content>
                <DetailSC.ContentLeft>
                    <DetailSC.ProductBlock>
                        <div>
                            <DetailSC.Status>
                                <DetailSC.Title marginRight={8}>Product</DetailSC.Title>
                                <DetailSC.Text>{OrderStatusEnum[detail.data.status]}</DetailSC.Text>
                            </DetailSC.Status>
                            <DetailSC.Title>{detail.product.name}</DetailSC.Title>
                            <DetailSC.PriceWrapper>
                                <DetailSC.Text>Definitive price</DetailSC.Text>
                                <DetailSC.Price>CHF {detail.definite_price}</DetailSC.Price>
                            </DetailSC.PriceWrapper>
                        </div>
                        <DetailSC.IconWrapper>
                            <img src={wallet} alt="" />
                        </DetailSC.IconWrapper>
                    </DetailSC.ProductBlock>
                    <DetailSC.PaymentBlock>
                        <DetailSC.PaymentBlockTitle>
                            <DetailSC.Title>Payment status</DetailSC.Title>
                            <DetailSC.PaymentStatus
                                isSuccess={
                                    detail.payment
                                        ? detail.payment.status !==
                                          OrderPaymentStatusEnum.Refund_failed
                                        : false
                                }
                            >
                                {detail.payment
                                    ? OrderPaymentStatusEnum[detail.payment.status].replace(
                                          '_',
                                          ' ',
                                      )
                                    : 'Unpaid'}
                            </DetailSC.PaymentStatus>
                        </DetailSC.PaymentBlockTitle>
                        <DetailSC.PaymentLine>
                            <DetailSC.Text>Subtotal</DetailSC.Text>
                            <DetailSC.PaymentValue>CHF {detail.subtotal}</DetailSC.PaymentValue>
                        </DetailSC.PaymentLine>
                        <DetailSC.PaymentLine>
                            <DetailSC.Text>7,7% VAT</DetailSC.Text>
                            <DetailSC.PaymentValue>CHF {detail.vat}</DetailSC.PaymentValue>
                        </DetailSC.PaymentLine>
                        <DetailSC.PaymentLine>
                            <DetailSC.Text>Delivery cost</DetailSC.Text>
                            <DetailSC.PaymentValue>
                                CHF {detail.delivery_cost}
                            </DetailSC.PaymentValue>
                        </DetailSC.PaymentLine>
                        <DetailSC.PaymentLine isTotal>
                            <DetailSC.Text>TOTAL</DetailSC.Text>
                            <DetailSC.PaymentValue>CHF {detail.total}</DetailSC.PaymentValue>
                        </DetailSC.PaymentLine>
                        {allRefunded > 0 && (
                            <DetailSC.PaymentLine>
                                <DetailSC.Text>Refund</DetailSC.Text>
                                <DetailSC.PaymentValue>
                                    CHF {ParseHelper.preparePrice(allRefunded.toString())}
                                </DetailSC.PaymentValue>
                            </DetailSC.PaymentLine>
                        )}
                        <DetailSC.PaymentLine>
                            <DetailSC.Text>Current balance</DetailSC.Text>
                            <DetailSC.PaymentValue>
                                CHF{' '}
                                {ParseHelper.preparePrice(
                                    allRefunded === Number(detail?.total)
                                        ? `-${allRefunded}`
                                        : detail.current_balance,
                                )}
                            </DetailSC.PaymentValue>
                        </DetailSC.PaymentLine>
                        {(detail.data.status === OrderStatusEnum.Unfulfilled ||
                            detail.data.status === OrderStatusEnum.Fulfilled) &&
                            !!detail.payment &&
                            canRefund > 0 &&
                            !anyFailedRefund && (
                                <DetailSC.PaymentFooter>
                                    <SecondaryButtonSC
                                        onClick={setRefundModalState.bind(null, true)}
                                    >
                                        Issue a refund
                                    </SecondaryButtonSC>
                                </DetailSC.PaymentFooter>
                            )}
                    </DetailSC.PaymentBlock>
                </DetailSC.ContentLeft>
                <DetailSC.ContentRight>
                    <DetailSC.CustomerBlock>
                        <DetailSC.Title>Customer</DetailSC.Title>
                        <DetailSC.CustomerName>
                            {detail.customer_data.first_name} {detail.customer_data.last_name}
                        </DetailSC.CustomerName>
                        <DetailSC.CustomerInfo>
                            <DetailSC.Text>
                                <a href={`mailto:${detail.customer_data.customer}`}>
                                    {detail.customer_data.customer}
                                </a>
                                <br />
                                {detail.customer_data.country}
                            </DetailSC.Text>
                            <DetailSC.Text>
                                {detail.customer_data.postcode} {detail.customer_data.city}
                                <br />
                                {detail.customer_data.street}
                            </DetailSC.Text>
                            {/*                             {detail.data.status !== OrderStatusEnum.Cancelled && (
                                <SecondaryButtonSC>
                                    Block
                                </SecondaryButtonSC>
                            )} */}
                        </DetailSC.CustomerInfo>
                    </DetailSC.CustomerBlock>
                    <DetailSC.HistoryBlock>
                        <DetailSC.Title>Order history</DetailSC.Title>
                        <DetailSC.HistoryBody>
                            {actionsHistory.length <= 0 && (
                                <DetailSC.NoHistory>There is no history.</DetailSC.NoHistory>
                            )}
                            {actionsHistory.map((item, index) => (
                                <DetailSC.HistoryLine key={`history-item-${index}`}>
                                    <DetailSC.Text>
                                        {item.date.format('DD.MM.YYYY.HH:mm')}
                                    </DetailSC.Text>
                                    <DetailSC.HistoryLineRight>
                                        {item.text}
                                    </DetailSC.HistoryLineRight>
                                </DetailSC.HistoryLine>
                            ))}
                        </DetailSC.HistoryBody>
                    </DetailSC.HistoryBlock>
                </DetailSC.ContentRight>
            </DetailSC.Content>

            <FulFillModal
                isOpen={fulFillModalState}
                data={detail.product}
                onClose={setFullFillModalState.bind(null, false)}
                onConfirm={onConfirm}
            />
            <RefundModal
                isOpen={refundModalState}
                maxValue={canRefund}
                onClose={setRefundModalState.bind(null, false)}
                onSubmit={onRefund}
            />
            <ConfirmModal
                isOpen={confirmCancelModalState}
                text="This action can not be undone"
                title={`Are you sure you wand to cancel Order №${id}?`}
                onClose={setConfirmCancelModalState.bind(null, false)}
                onCancel={setConfirmCancelModalState.bind(null, false)}
                onConfirm={onCancelConfirm}
            />
        </>
    );
};
