import debounce from 'lodash.debounce';
import { useEffect, useMemo } from 'react';
import deepEqual from 'deep-equal';

import { useIsMounted } from 'helpers/hooks';

type Hook = (
  toCompare: any,
  compareWith: any,
  onCompare?: (isEqual: boolean) => void,
  options?: {
    wait?: number;
    trailing?: boolean;
    leading?: boolean;
  }
) => void;

// Hook to check deep equal of two values and call omCompare with result.
export const useCMSCompare: Hook = (toCompare, compareWith, onCompare, options) => {
  const isMounted = useIsMounted();
  const debounceOptions = useMemo(() => ({
    wait: options?.wait !== undefined ? options.wait : 800,
    trailing: options?.trailing !== undefined ? options.trailing : true,
    leading: options?.leading !== undefined ? options.leading : false,
  }), [options]);

  const checkEqual = debounce((x, y) => {
    isMounted && onCompare && onCompare(deepEqual(x, y));
  }, debounceOptions.wait, {
    trailing: debounceOptions.trailing,
    leading: debounceOptions.leading,
  });

  useEffect(
    () => checkEqual(compareWith, toCompare),
    [compareWith, toCompare]
  );
};
