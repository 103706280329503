// Registration
export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_FAILURE = 'USER_REGISTER_FAILURE';

// Confirm email
export const USER_CONFIRM_EMAIL_REQUEST = 'USER_CONFIRM_EMAIL_REQUEST';
export const USER_CONFIRM_EMAIL_SUCCESS = 'USER_CONFIRM_EMAIL_SUCCESS';
export const USER_CONFIRM_EMAIL_FAILURE = 'USER_CONFIRM_EMAIL_FAILURE';

// Resend confirm email
export const USER_RESEND_CONFIRM_EMAIL_REQUEST = 'USER_RESEND_CONFIRM_EMAIL_REQUEST';
export const USER_RESEND_CONFIRM_EMAIL_SUCCESS = 'USER_RESEND_CONFIRM_EMAIL_SUCCESS';
export const USER_RESEND_CONFIRM_EMAIL_FAILURE = 'USER_RESEND_CONFIRM_EMAIL_FAILURE';

// Login
export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE';

// Reset password
export const USER_RESET_PASSWORD_REQUEST = 'USER_RESET_PASSWORD_REQUEST';
export const USER_RESET_PASSWORD_SUCCESS = 'USER_RESET_PASSWORD_SUCCESS';
export const USER_RESET_PASSWORD_FAILURE = 'USER_RESET_PASSWORD_FAILURE';
export const USER_RESET_PASSWORD_USER_ID = 'USER_RESET_PASSWORD_USER_ID';

// Reset password confirm
export const USER_CONFIRM_RESET_PASSWORD_REQUEST = 'USER_CONFIRM_RESET_PASSWORD_REQUEST';
export const USER_CONFIRM_RESET_PASSWORD_SUCCESS = 'USER_CONFIRM_RESET_PASSWORD_SUCCESS';
export const USER_CONFIRM_RESET_PASSWORD_FAILURE = 'USER_CONFIRM_RESET_PASSWORD_FAILURE';

// Sign Out
export const SIGNOUT_REQUEST = 'SIGNOUT_REQUEST';
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS';
export const SIGNOUT_FAILURE = 'SIGNOUT_FAILURE';

// Get info about me
export const USER_FETCH_MY_ACCOUNT_DATA_REQUEST = 'USER_FETCH_MY_ACCOUNT_DATA_REQUEST';
export const USER_FETCH_MY_ACCOUNT_DATA_SUCCESS = 'USER_FETCH_MY_ACCOUNT_DATA_SUCCESS';
export const USER_FETCH_MY_ACCOUNT_DATA_FAILURE = 'USER_FETCH_MY_ACCOUNT_DATA_FAILURE';

// Update info about me
export const USER_UPDATE_MY_ACCOUNT_DATA_REQUEST = 'USER_UPDATE_MY_ACCOUNT_DATA_REQUEST';
export const USER_UPDATE_MY_ACCOUNT_DATA_SUCCESS = 'USER_UPDATE_MY_ACCOUNT_DATA_SUCCESS';
export const USER_UPDATE_MY_ACCOUNT_DATA_FAILURE = 'USER_UPDATE_MY_ACCOUNT_DATA_FAILURE';
export const USER_UPDATE_CUSTOM_DOMAIN_DATA = 'USER_UPDATE_CUSTOM_DOMAIN_DATA';
export const USER_UPDATE_CUSTOM_DOMAIN_STATUS = 'USER_UPDATE_CUSTOM_DOMAIN_STATUS';
export const USER_UPDATE_COMPANY_EMAIL = 'USER_UPDATE_COMPANY_EMAIL';

// Clear
export const USER_CLEAR_STORE = 'USER_CLEAR_STORE';

// Accept invitation
export const USER_ACCEPT_INVITATION_USER_TOKEN = 'USER_ACCEPT_INVITATION_USER_TOKEN';
export const USER_ACCEPT_INVITATION_REQUEST = 'USER_ACCEPT_INVITATION_REQUEST';
export const USER_ACCEPT_INVITATION_SUCCESS = 'USER_ACCEPT_INVITATION_SUCCESS';
export const USER_ACCEPT_INVITATION_FAILURE = 'USER_ACCEPT_INVITATION_FAILURE';

// Welcome flow
export const USER_WELCOME_FLOW_REQUEST_SUCCESS = 'USER_WELCOME_FLOW_DATA_SUCCESS';

// General
export const USER_SET_ERROR_STATE = 'USER_SET_ERROR_STATE';
export const USER_SET_TEMP_PAYLOAD = 'USER_SET_TEMP_PAYLOAD';
