import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { TextStyle3, TextStyle5 } from 'components/styles/text.styles';

const LoginText = styled.span`
  ${TextStyle3}
`;

const LoginLink = styled(Link)`
  ${TextStyle5};

  text-decoration: none;
`;

export const SignUpSC = {
  LoginText,
  LoginLink,
};
