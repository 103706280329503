import { AuthHelper } from 'shared/helpers';

export type HeadersType = Record<string, string>;

const DEFAULT_HEADERS: HeadersType = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
};

export const getHeaders = (headers?: HeadersType): HeadersType => {
    const maybeToken = AuthHelper.getToken();
    if (maybeToken)
        return { ...(headers || DEFAULT_HEADERS), ...{ Authorization: `Token ${maybeToken}` } };
    return DEFAULT_HEADERS;
};
