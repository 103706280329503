import { ApiClient, apiPath } from 'config/api/api';
import { ProductListResp, Product, ProductResp } from './types';

type ProductsQueryArgs = {
    page?: number;
    search?: string;
    withoutAuction?: boolean;
    unpublished?: boolean;
    activeProducts?: boolean;
};

const getQueryStr = ({
    page,
    search,
    withoutAuction,
    unpublished,
    activeProducts,
}: ProductsQueryArgs): string => {
    let queryStr = '';

    if (withoutAuction) {
        queryStr = '?without-auction=true';
    } else if (unpublished) {
        queryStr = '?unpublished=true';
    } else if (activeProducts) {
        queryStr = '?active_products=true';
    } else {
        if (page && search) {
            queryStr = `?page=${page}&search=${encodeURIComponent(search)}`;
        } else if (page) {
            queryStr = `?page=${page}`;
        } else if (search) {
            queryStr = `?search=${encodeURIComponent(search)}`;
        }
    }

    return queryStr;
};

const prepareProductList = (data: Array<ProductResp>): Array<Product> =>
    data.map(item => ({
        id: item.id,
        images: item.images,
        name: item.name,
        published: item.published,
        status: item.published ? 'Published' : 'Unpublished',
        quantity: item.quantity || '-',
        uvp: item.uvp,
        startTime: item.auction_start_datetime,
        endTime: item.auction_end_datetime,
    }));

export const getProducts = async ({
    page,
    search,
    withoutAuction,
    unpublished,
    activeProducts,
}: ProductsQueryArgs) => {
    const api = ApiClient.getInstance();
    const { data } = await api.get<ProductListResp>(
        `${apiPath}/products/list/${getQueryStr({
            page,
            search,
            withoutAuction,
            unpublished,
            activeProducts,
        })}
        `,
    );

    return {
        data: Array.isArray(data) ? prepareProductList(data) : prepareProductList(data.results),
        pageSize: data.countItemsOnPage || 0,
        productCount: data.count || 0,
        hasProducts: data.has_products,
    };
};

export const deleteProduct = async (id: string) => {
    const api = ApiClient.getInstance();

    await api.delete(`${apiPath}/products/${id}/`);
};
