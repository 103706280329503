import React, { InputHTMLAttributes } from 'react';
import clsx from 'clsx';

import styles from './Checkbox.module.scss';

type CheckboxProps = {
    label: string;
    checked?: boolean;
};

export const Checkbox: React.FC<CheckboxProps & InputHTMLAttributes<HTMLInputElement>> = ({
    className,
    checked,
    label,
    disabled,
    ...props
}) => (
    <label
        className={clsx(styles.root, className, {
            [styles['is-disabled']]: disabled,
        })}
    >
        <input
            className={styles.input}
            type="checkbox"
            checked={checked}
            disabled={disabled}
            {...props}
        />
        <div className={styles.icon} />
        {label && (
            <span className={clsx(styles.label, { [styles.checked]: checked })}>{label}</span>
        )}
    </label>
);
