import React, { FC, ReactElement } from 'react';

import { AccordionSC } from './accordion.styles';
import { IconSC, IconUnicodes } from 'components/styles/icons.styles';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/root-types';
import { UserState } from 'units/user/redux/types';

interface Props {
    items: Array<{
        header: string;
        content: string | ReactElement;
        // uuid should be w/o spaces.
        uuid?: string;
    }>;
    preExpanded?: Array<string>;
    expanded?: Array<string>;
    onChange?: (args: Array<string>) => void;
}

const removeUUIDWhitespaces = (uuid: string) => uuid.split(' ').join('-');

export const Accordion: FC<Props> = ({ items, preExpanded, expanded, onChange }) => {
    const { user } = useSelector<RootState, UserState>(state => state.user);

    return (
        <AccordionSC.Container
            allowZeroExpanded
            preExpanded={preExpanded?.map(removeUUIDWhitespaces)}
            onChange={onChange}
        >
            {items.map(item => {
                const itemUUID = item.uuid || removeUUIDWhitespaces(item.header);

                if (user?.user_type?.slug === 'immo' && item.uuid === 'newsletterOption')
                    return null;

                return (
                    <AccordionSC.Item
                        key={item.header}
                        uuid={itemUUID}
                        dangerouslySetExpanded={expanded ? expanded.includes(itemUUID) : undefined}
                    >
                        <AccordionSC.ItemHeading>
                            <AccordionSC.ItemButton>
                                {item.header}
                                <IconSC name={IconUnicodes.angle_down} size={12} />
                            </AccordionSC.ItemButton>
                        </AccordionSC.ItemHeading>
                        <AccordionSC.ItemPannel>{item.content}</AccordionSC.ItemPannel>
                    </AccordionSC.Item>
                );
            })}
        </AccordionSC.Container>
    );
};
