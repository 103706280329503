import React, { FC, useContext, useEffect, useMemo } from 'react';

import { useDeviceDetector } from 'units/landing-page/hooks';
import { SmallScreenTemplate } from 'app/other-screens/SmallScreenTemplate';
import { ImageName } from 'components/styles/images';
import { useAsyncDispatch } from 'helpers/hooks';
import { NotificationContext } from 'shared/providers';
import { confirmSignUpEmailThunk } from 'units/user/redux/thunks';

export const AltResponsiveRegistrationView: FC<{
    onFlip?: () => void;
    token: string;
    uid: string;
}> = ({ children, onFlip, token, uid }) => {
    const { detected, device, isDashboardUsableOnFlip } = useDeviceDetector(onFlip);

    const { dispatch } = useAsyncDispatch();
    const { showNotification } = useContext(NotificationContext);

    useEffect(() => {
        if (!isDashboardUsableOnFlip && (device === 'mobile' || device === 'tablet')) {
            dispatch(confirmSignUpEmailThunk({ uid, token }, showNotification));
        }
    }, [dispatch, token, uid, device]);

    const content = useMemo(() => {
        if (isDashboardUsableOnFlip) {
            return (
                <SmallScreenTemplate
                    message="Please, tilt your screen to use the dashboard"
                    imageName={ImageName.tablet}
                />
            );
        } else if (device === 'mobile' || device === 'tablet') {
            return (
                <SmallScreenTemplate
                    message="Dear user, your registration was successful. You can reach your dashboard through the desktop"
                    imageName={ImageName.target}
                />
            );
        } else if (detected) {
            return <>{children}</>;
        } else {
            return null;
        }
    }, [device, isDashboardUsableOnFlip, children]);

    return content;
};
