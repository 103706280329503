import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Preloader } from 'shared/components/ui';
import { OverviewService } from '../api';
import {
    OverviewHeaderSection,
    OverviewInfoSection,
    OverviewOrdersSection,
} from '../components/sections';
import { OverviewStatusInfo } from '../types';

export const Overview = () => {
    const [info, setInfo] = useState<OverviewStatusInfo | null>(null);
    const [isLoading, setIsLoading] = useState(true);

    const getOverviewInfo = async () => {
        const info = await OverviewService.getOverviewStatuses();
        setInfo(info);
        setIsLoading(false);
    };

    useEffect(() => {
        getOverviewInfo();
        const interval = setInterval(getOverviewInfo.bind(null), 30000);
        return () => clearInterval(interval);
    }, []);

    if (isLoading) return <Preloader.Page height={600} />;
    return (
        <div>
            <Helmet>
                <title>Overview</title>
            </Helmet>
            <OverviewHeaderSection />
            {info && <OverviewInfoSection info={info} />}
            <OverviewOrdersSection />
        </div>
    );
};
