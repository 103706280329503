import React, { FC, useCallback } from 'react';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';

import { RootState } from 'redux/root-types';
import { productInfoForm } from './constants';
import { GeneralFormSC } from 'units/common/components/form/form.styles';
import { ProductPicturesForm } from './pictures/product-pictures.component';
import { ProductDescriptionForm } from './description/product-description.component';
import { FormikFormInput } from 'units/common/components/formik/input/form-input.component';
import { useFormikFieldErrors } from 'units/common/hooks/useFieldErrors';
import { EditProductFormikGeneralInfo, EditProductReducer, EditProductError } from '../store/types';
import { TrackProgressField } from 'units/common/hooks/useTrackProgress';
import { fieldFromServer } from '../constants';

type EditProductInfoErrors = Omit<EditProductFormikGeneralInfo, 'descriptions' | 'pictures'>;

interface Props {
    updateProgress: (field: TrackProgressField) => void;
    saveCurrentForm: (currentForm: EditProductFormikGeneralInfo) => void;
    updateDescriptionProgress: any;
}

export const ProductGeneralInfoForm: FC<Props> = ({
    updateProgress,
    saveCurrentForm,
    updateDescriptionProgress,
}) => {
    const { values } = useFormikContext<EditProductFormikGeneralInfo>();
    const formikFieldError = useFormikFieldErrors<EditProductFormikGeneralInfo>();
    const { serverError } = useSelector<RootState, EditProductReducer>(
        ({ editProduct }) => editProduct,
    );

    const fieldError = useCallback(
        (fieldName: string) => {
            const formikErr = formikFieldError[fieldName as keyof EditProductInfoErrors];
            const serverErr = serverError
                ? serverError[fieldFromServer[fieldName] as keyof EditProductError]
                : undefined;

            return formikErr || serverErr;
        },
        [formikFieldError, serverError],
    );

    const inputAfterChangeHandler = useCallback(
        ({ target }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            if (!target) {
                return;
            }

            updateProgress({ name: target.name, isFilled: !!target.value });
        },
        [updateProgress],
    );

    const saveForm = useCallback(() => saveCurrentForm(values), [values]);

    return (
        <>
            <GeneralFormSC.InputsGrid>
                {productInfoForm.map(productInput => (
                    <FormikFormInput
                        key={`product-info-${productInput.name}`}
                        {...productInput}
                        withMargins
                        error={fieldError(productInput.name)}
                        afterChange={inputAfterChangeHandler}
                    />
                ))}
            </GeneralFormSC.InputsGrid>
            <ProductPicturesForm updateProgress={updateProgress} />
            <ProductDescriptionForm
                updateProgress={updateProgress}
                saveForm={saveForm}
                updateDescriptionProgress={updateDescriptionProgress}
            />
        </>
    );
};
