import { RefundItemType } from 'shared/types';
import { ProductItem } from 'units/products/pages/product/store/types';
import { AppUser } from 'units/user/redux/types';

export type ServerPaginationLinksType = {
    next: string | null;
    previous: string | null;
};

export enum OrderStatusEnum {
    Fulfilled = 1,
    Unfulfilled = 2,
    Cancelled = 3,
}

export enum OrderPaymentStatusEnum {
    Paid = 1,
    Refunded = 2,
    Partially_refunded = 3,
    Refund_pending = 4,
    Refund_failed = 5,
}

export type OrderPaymentType = {
    amount: string;
    client_email: string;
    created_at: string;
    external_id: string;
    id: number;
    refunds: RefundItemType[];
    status: OrderPaymentStatusEnum;
};

export type OrderItemType = {
    amount: string;
    auction: string;
    created_at: string;
    id: string;
    status: OrderStatusEnum;
    user: AppUser;
    payment: OrderPaymentType;
    status_changed_at: null | string;
};

export type OrdersListType = {
    count: number;
    countItemsOnPage: number;
    current_page: number;
    links: ServerPaginationLinksType;
    results: Array<OrderItemType>;
};

export type OrderDetailType = {
    current_balance: string;
    customer_data: OrderCustomerDataType;
    data: OrderItemType;
    definite_price: string;
    delivery_cost: string;
    product: ProductItem;
    subtotal: string;
    total: string;
    vat: string;
    payment: OrderPaymentType | null;
};

export type OrderCustomerDataType = {
    city: string;
    country: string;
    customer: string;
    first_name: string;
    gender: string;
    last_name: string;
    postcode: string;
    street: string;
};
