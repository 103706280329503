import styled from 'styled-components';

import { Color, sizes } from 'components/styles/constants.styles';
import { ItemTitleText, TextStyle6 } from 'components/styles/text.styles';

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const NoUsersContainer = styled.div`
  margin: ${sizes.lg_padding} auto;
  text-align: center;

  button {
    margin: ${sizes.md_padding} 0;
  }
`;

const Message = styled.div`
  ${ItemTitleText}
  line-height: 2;
  margin-top: ${sizes.sm_padding};
  text-align: center;
`;

const Edit = styled.div`
  ${TextStyle6};
  a {
    text-decoration: none;
    color: ${Color.black};
  }
`;

const Text = styled.span`
  color: ${Color.black};

  &.active {
    color: ${Color.success_green};
  }
  &.pending {
    color: ${Color.orange};
  }
`;

export const UsersSC = {
  NoUsersContainer,
  TitleContainer,
  Message,
  Text,
  Edit,
};
