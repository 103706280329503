import update, { Spec } from 'immutability-helper';
import deepmerge from 'deepmerge';
import get from 'lodash.get';
import toPath from 'lodash.topath';
import cloneDeep from 'lodash.clonedeep';

import {
  CMSCustomizationOverallSettings,
  CMSFetchCustomizationConfigResponse,
  CMSCustomizationConfig,
  CMSCustomizationFormikSettings,
  CMSCustomizationSettings,
} from '../types/customization';
import { CMSHomePageFormikCustomizationSettings } from '../types/customization/homepage';
import { CMSShopModalsCustomizationSettings } from '../types/customization/shop-modals';
import { CMSCustomizationPage, CMSUploadedImage, CMSImageToSave, CMSImageToDelete, CMSImage } from '../types';
import { handleCMSImageStoring, CMSImageKey, findCMSUploadedImage, isCMSSavedImage, isEmptyObject } from '.';
import { CompanyLanguages } from 'units/app/redux/types';
import {
  CMSCustomizationPageConfig,
  CMSDefaultHomepageSettings,
  CMSDefaultDetailPageSettings,
  CMSDefaultMissedAuctionsSettings,
  CMSDefaultShopModalsSettings
} from '../constants';
import { HomepageSharedFields } from 'units/cms/helpers/local-settings/homepage';
import { ProductDetailPageSharedFields } from 'units/cms/helpers/local-settings/product-detail-page';
import { MissedAuctionsSharedFields } from 'units/cms/helpers/local-settings/missed-auctions';
import { ShopModalsSharedFields } from 'units/cms/helpers/local-settings/shop-modals';
import { hasSameValue } from 'units/cms/helpers/local-settings/index';

// To define if settings is formik homepage settings
export const isCMSHomePageFormikSettings = (
  overallSettings: CMSCustomizationOverallSettings<CMSCustomizationFormikSettings>,
  page: CMSCustomizationPage,
): overallSettings is CMSCustomizationOverallSettings<CMSHomePageFormikCustomizationSettings> => page === CMSCustomizationPage.Home;


const injectCMSCustomizationPageUploadedImages = (
  page: CMSCustomizationPage,
  settings: CMSCustomizationOverallSettings<CMSHomePageFormikCustomizationSettings | CMSShopModalsCustomizationSettings>,
  uploadedImages: Array<CMSUploadedImage>
): CMSCustomizationOverallSettings<CMSCustomizationSettings> => {
  switch (page) {
    case CMSCustomizationPage.Home:
      const commonLanguageSettings = Object.values(settings)
        .find(lngSettings => lngSettings?.meta?.hasBeenPublished)
        || Object.values(settings)[0];

      if (!commonLanguageSettings) {
        return settings as CMSCustomizationOverallSettings<CMSCustomizationSettings>;
      }

      const [conversationStickerIconUploaded, usp1IconUploaded, usp2IconUploaded,
        usp3IconUploaded, textBlockPictureUploaded, newsletterIconUploaded] = [
        CMSImageKey.conversationStickerIcon, CMSImageKey.usp1Icon, CMSImageKey.usp2Icon, CMSImageKey.usp3Icon,
        CMSImageKey.textBlockPicture, CMSImageKey.newsletterIcon,
      ].map(
        (key) => findCMSUploadedImage(uploadedImages, key),
      );
      
      return Object.entries(settings).reduce((res, [lng, languageSettings]) => languageSettings ? ({
        ...res,
        [lng as CompanyLanguages]: update(languageSettings, {
          hero: { conversationSticker: { icon: { $apply: (old) => conversationStickerIconUploaded || old } }},
          usp: {
            usp1: { icon: { $apply: (old) => usp1IconUploaded || old }},
            usp2: { icon: { $apply: (old) => usp2IconUploaded || old }},
            usp3: { icon: { $apply: (old) => usp3IconUploaded || old }},
          },
          textBlock: {
            picture: { $apply: (old) => textBlockPictureUploaded || old },
          },
          newsletterOption: {
            icon: { $apply: (old) => newsletterIconUploaded || old },
          }
        })
        
      }) : res, {} as CMSCustomizationOverallSettings<CMSCustomizationSettings>);
    case CMSCustomizationPage.ShopModals: {
      const commonLanguageSettings = Object.values(settings)
        .find(lngSettings => lngSettings?.meta?.hasBeenPublished)
        || Object.values(settings)[0];

      if (!commonLanguageSettings) {
        return settings as CMSCustomizationOverallSettings<CMSCustomizationSettings>;
      }

      const [confirmButtonIconUploaded, denyButtonIconUploaded, winButtonIconUploaded,
        /* sentEmailIconUploaded, enterPasswordIconUploaded */] = [
        CMSImageKey.confirmButtonIcon, CMSImageKey.denyButtonIcon, CMSImageKey.winButtonIcon,
        CMSImageKey.sentEmailIcon, CMSImageKey.enterPasswordIcon,
      ].map(
        (key) => findCMSUploadedImage(uploadedImages, key),
      );
      
      return Object.entries(settings).reduce((res, [lng, languageSettings]) => languageSettings ? ({
        ...res,
        [lng as CompanyLanguages]: update(languageSettings, {
          offerConfirmation: { confirmButtonIcon: { $apply: old => confirmButtonIconUploaded || old } },
          offerDenial: { denyButtonIcon: { $apply: old => denyButtonIconUploaded || old } },
          offerWon: { winButtonIcon: { $apply: old => winButtonIconUploaded || old } },
          resetPassword: {
            //sentEmailIcon: { $apply: old => sentEmailIconUploaded || old },
            //enterPasswordIcon: { $apply: old => enterPasswordIconUploaded || old }
          }
        })
      }): res, {} as CMSCustomizationOverallSettings<CMSCustomizationSettings>);
    }
    default:
      return settings as CMSCustomizationOverallSettings<CMSCustomizationSettings>;
  }
};

const pushNewImageToList = (img: CMSImage | null | File, imgKey: CMSImageKey, imgList: Array<CMSImageToSave>) => {
  if (img && !isCMSSavedImage(img)) {
    imgList.push({
      key: imgKey,
      image: img,
    })
  }
}

const buildCMSCustomizationPageImagesToSave = (
  page: CMSCustomizationPage,
  settings: CMSCustomizationOverallSettings<CMSHomePageFormikCustomizationSettings | any>
) => {
  const imagesToSave: Array<CMSImageToSave> = [];

  switch(page) {
    case CMSCustomizationPage.Home: {
      if (!isCMSHomePageFormikSettings(settings, page)) {
        break;
      }

      const commonLanguageSettings = Object.values(settings)
        .find(lngSettings => lngSettings?.meta?.hasBeenPublished)
        || Object.values(settings)[0];

      if (!commonLanguageSettings) {
        break;
      }

      [ { img: commonLanguageSettings.hero.conversationSticker.icon, key: CMSImageKey.conversationStickerIcon },
        { img: commonLanguageSettings.usp.usp1.icon, key: CMSImageKey.usp1Icon },
        { img: commonLanguageSettings.usp.usp2.icon, key: CMSImageKey.usp2Icon },
        { img: commonLanguageSettings.usp.usp3.icon, key: CMSImageKey.usp3Icon },
        { img: commonLanguageSettings.textBlock.picture, key: CMSImageKey.textBlockPicture },
        { img: commonLanguageSettings.newsletterOption.icon, key: CMSImageKey.newsletterIcon },
      ].forEach(i => pushNewImageToList(i.img, i.key, imagesToSave));

      break;
    }
    case CMSCustomizationPage.ShopModals: {
      const commonLanguageSettings = Object.values(settings)
        .find(lngSettings => lngSettings?.meta?.hasBeenPublished)
        || Object.values(settings)[0];

      if (!commonLanguageSettings) {
        break;
      }

      [ { img: commonLanguageSettings.offerConfirmation.confirmButtonIcon, key: CMSImageKey.confirmButtonIcon },
        { img: commonLanguageSettings.offerDenial.denyButtonIcon, key: CMSImageKey.denyButtonIcon },
        { img: commonLanguageSettings.offerWon.winButtonIcon, key: CMSImageKey.winButtonIcon },
        { img: commonLanguageSettings.resetPassword.sentEmailIcon, key: CMSImageKey.sentEmailIcon },
        { img: commonLanguageSettings.resetPassword.enterPasswordIcon, key: CMSImageKey.enterPasswordIcon },
      ].forEach(i => pushNewImageToList(i.img, i.key, imagesToSave));
     break; 
    }
    default: break;
  }

  return imagesToSave;
};

export const prepareCMSCustomizationPageToSaveWithImages = async (
  page: CMSCustomizationPage,
  settings: CMSCustomizationOverallSettings<any>,
  imagesToDelete: Array<CMSImageToDelete>,
) => {
  const imagesToSave = buildCMSCustomizationPageImagesToSave(page, settings);
  const uploadedImages = await handleCMSImageStoring(imagesToSave, imagesToDelete);
  return injectCMSCustomizationPageUploadedImages(page, settings, uploadedImages);
}

// Helper to deep merge each language in overall settings with default settings
const mergeCustomizationOverallSettingsWithDefault = <S = any> (
  settings: CMSCustomizationOverallSettings<S>,
  defaultSettings: S,
  cmsLanguages: Array<CompanyLanguages>,
) => (
  cmsLanguages.reduce((result, lng) => {
    const customizationSettings = settings[lng];
    
    return ({
      ...result,
      [lng as CompanyLanguages]: customizationSettings
        ? deepmerge<S>(defaultSettings, customizationSettings, { arrayMerge: combineMerge })
        : defaultSettings,
    })
  }, {} as CMSCustomizationOverallSettings<S>)
);

// Mutates the spec object used for immutability-helper update method
export const updateSpecObj = (spec: { [key in string]: any }, object: any, path: string, value: any) => {
  if (!hasSameValue(path, object, value)) {
    const splitPath = toPath(path);
    const lastIndex = splitPath.length - 1;
    let nested = spec;
    splitPath.forEach((p, i) => {
      nested[p] = i === lastIndex ? { $set: value } : { ...nested[p] };
      nested = nested[p];
    });
  }
};


// Helper to merge the default homepage settings data with shared fields data
const mergeDefaultWithSharedFields = <T>(
  currentSettings: CMSCustomizationOverallSettings<T>,
  defaultSettings: T,
  sharedFieds: Array<string>
) => {
  if (isEmptyObject(currentSettings)) {
    return cloneDeep(defaultSettings);
  }
  const spec = {} as Spec<T, never>;
  sharedFieds.forEach(path => {
    updateSpecObj(spec, defaultSettings, path, get(currentSettings[Object.keys(currentSettings)[0] as CompanyLanguages], path));
  });
  return update(defaultSettings, spec);
}

// Parse response to customization config and merge default if needed.
export const parseCMSFetchCustomizationConfigResponse = (
  configResponse: CMSFetchCustomizationConfigResponse,
  options: {
    mergeWithDefault?: boolean;
    cmsLanguages: Array<CompanyLanguages>
  }
): CMSCustomizationConfig => {
  const { mergeWithDefault, cmsLanguages } = options;
  const { settings: {
    homepage = CMSCustomizationPageConfig,
    productDetailPage = CMSCustomizationPageConfig,
    missedAuctions = CMSCustomizationPageConfig,
    shopModals = CMSCustomizationPageConfig
  }} = configResponse;
  
  const defaultHomapageSettings = mergeDefaultWithSharedFields(homepage.settings, CMSDefaultHomepageSettings, HomepageSharedFields);
  const defaultProductDetailPageSettings = mergeDefaultWithSharedFields(
    productDetailPage.settings,
    CMSDefaultDetailPageSettings,
    ProductDetailPageSharedFields
  );
  const defaultMissedAuctionsSettings = mergeDefaultWithSharedFields(
    missedAuctions.settings,
    CMSDefaultMissedAuctionsSettings,
    MissedAuctionsSharedFields
  );
  const defaultShopModalsSettings = mergeDefaultWithSharedFields(
    shopModals.settings,
    CMSDefaultShopModalsSettings,
    ShopModalsSharedFields
  );
  return {
    homepage: {
      isPublished: homepage.isPublished,
      settings: mergeWithDefault
        ? mergeCustomizationOverallSettingsWithDefault(homepage.settings, defaultHomapageSettings, cmsLanguages)
        : homepage.settings,
    },
    productDetailPage: {
      isPublished: productDetailPage.isPublished,
      settings: mergeWithDefault
        ? mergeCustomizationOverallSettingsWithDefault(productDetailPage.settings, defaultProductDetailPageSettings, cmsLanguages)
        : productDetailPage.settings
    },
    missedAuctions: {
      isPublished: missedAuctions.isPublished,
      settings: mergeWithDefault
        ?  mergeCustomizationOverallSettingsWithDefault(missedAuctions.settings, defaultMissedAuctionsSettings, cmsLanguages)
        : missedAuctions.settings
    },
    shopModals: {
      isPublished: shopModals.isPublished,
      settings: mergeWithDefault
        ?  mergeCustomizationOverallSettingsWithDefault(shopModals.settings, defaultShopModalsSettings, cmsLanguages)
        : shopModals.settings
    }
  }
}

// Took from https://www.npmjs.com/package/deepmerge#arraymerge-example-combine-arrays
const combineMerge = (target: Array<any>, source: Array<any>, options: any) => {
  const destination = target.slice()

  source.forEach((item, index) => {
      if (typeof destination[index] === 'undefined') {
          destination[index] = options.cloneUnlessOtherwiseSpecified(item, options)
      } else if (options.isMergeableObject(item)) {
          destination[index] = deepmerge(target[index], item, options)
      } else if (target.indexOf(item) === -1) {
          destination.push(item)
      }
  })
  return destination
}
