import React, { FC } from 'react';

import { Form } from 'formik';

import { getImage, ImageName } from 'components/styles/images';
import { SharedSC } from '../welcome-shared.styles';
import { ReadyToGoSC } from './ready-to-go.styles';
 
export const ReadyToGo: FC = () => (
  <Form>
    <SharedSC.Wrapper>
      {getImage(ImageName.achievement)}
      <SharedSC.PageTitle>Awesome, you are ready to go!</SharedSC.PageTitle>
      <ReadyToGoSC.ContentContainer>
        <ReadyToGoSC.PageSubtitle>
          If you need anything, please do not hesitate to contact us at:
        </ReadyToGoSC.PageSubtitle>
        <ReadyToGoSC.Email>ecatch@mindnow.io</ReadyToGoSC.Email>
      </ReadyToGoSC.ContentContainer>

      <SharedSC.WelcomePrimaryButton type="submit" >
        Let’s start
      </SharedSC.WelcomePrimaryButton>
    </SharedSC.Wrapper>
  </Form>
);
