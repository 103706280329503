import React, { InputHTMLAttributes } from 'react';
import { useDropzone } from 'react-dropzone';
import clsx from 'clsx';

import styles from './FileUpload.module.scss';

type FileUploadProps = {
    className?: string;
    accept?: string | string[];
    haveError?: boolean;
    onSelect: (files: File[]) => void;
};

export const FileUpload: React.FC<InputHTMLAttributes<HTMLInputElement> & FileUploadProps> = ({
    children,
    className,
    haveError = false,
    accept,
    onSelect,
    ...props
}) => {
    const { getRootProps, getInputProps } = useDropzone({
        onDrop: onSelect,
        accept,
    });

    return (
        <div
            {...getRootProps()}
            className={clsx(styles.root, className, { [styles['have-error']]: haveError })}
        >
            {children}
            <input {...getInputProps()} className={styles.input} {...props} />
        </div>
    );
};
