import React, { useContext, useEffect, useMemo, useState } from 'react';
import moment from 'moment';

import { Button, Icon, Modal, Typography } from 'shared/components/ui';

import { useNavigate } from 'shared/hooks';
import { AuctionItemType } from 'shared/types';
import { ImmoUrls } from '../../urls';
import { ParseHelper } from 'shared/helpers';

import styles from './AuctionItem.module.scss';
import { useTranslation } from 'react-i18next';
import { ImmoInput } from '../Input/Input';
import { api } from 'api';
import { NotificationContext } from 'shared/providers';
import { IconSC, IconUnicodes } from 'components/styles/icons.styles';
import { Color } from 'components/styles/constants.styles';

const formatValue = (value: number): string => (value <= 9 ? `0${value}` : value.toString());

const calculateTimeLeft = (endsIn: Date, timerEnds?: () => void) => {
    let timeLeft = {
        days: '00',
        hours: '00',
        minutes: '00',
        seconds: '00',
    };

    if (!endsIn) return timeLeft;

    const difference = +endsIn - +new Date();

    if (difference > 0) {
        timeLeft = {
            days: formatValue(Math.floor(difference / (1000 * 60 * 60 * 24))),
            hours: formatValue(Math.floor((difference / (1000 * 60 * 60)) % 24)),
            minutes: formatValue(Math.floor((difference / 1000 / 60) % 60)),
            seconds: formatValue(Math.floor((difference / 1000) % 60)),
        };
    } else {
        timerEnds && timerEnds();
    }

    return timeLeft;
};

type AuctionItemProps = {
    data: AuctionItemType;
    onDelete: (id: string) => void;
    refreshActiveAuctions: () => Promise<void>;
};

export const AuctionItem: React.FC<AuctionItemProps> = ({
    data,
    onDelete,
    refreshActiveAuctions,
}) => {
    const { showNotification } = useContext(NotificationContext);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const date = useMemo(() => {
        if (!data) return new Date();
        return new Date(data.ends_at);
    }, [data]);

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(date));
    const [showGenerateLinkModal, setShowGenerateLinkModal] = useState(false);

    useEffect(() => {
        let timer: number | null = null;
        if (data.status.display_name === 'Active') {
            timer = setInterval(() => {
                setTimeLeft(
                    calculateTimeLeft(date, () => {
                        if (timer !== null) clearInterval(timer);
                    }),
                );
            }, 1000);
        }

        return () => {
            if (timer !== null) clearInterval(timer);
        };
    }, [date]);

    const image = useMemo(() => (data.product.images && data.product.images[0]?.image) || '', [
        data,
    ]);

    const copyLinkToClipboard = () => {
        let ownerUrl = data.access_secret_url ?? '';
        if (!ownerUrl) return;

        if (!/^https?:\/\//.test(ownerUrl)) {
            ownerUrl = 'https://' + ownerUrl;
        }

        navigator.clipboard.writeText(ownerUrl);
        showNotification('success', t('auction_item_copy_link_for_owner_success'));
    };

    const deleteGeneratedLink = async () => {
        try {
            await api.auctionService.deleteLinkForOwner(data.id);
            await refreshActiveAuctions();
            showNotification('success', t('auction_item_delete_link_for_owner_success'));
        } catch (error) {
            showNotification('error', t('auction_item_delete_link_for_owner_error'));
        }
    };

    return (
        <div className={styles.root}>
            <div className={styles.label}>{data.product.property_type}</div>
            {data.status.display_name === 'Active' && (
                <div
                    className={styles.edit}
                    onClick={() => navigate(ImmoUrls.auction.update.replace(':id', data.id))}
                >
                    <Icon name="edit" />
                </div>
            )}
            {data.status.display_name !== 'Active' && (
                <ItemOptions
                    type={data.status.display_name}
                    onDelete={onDelete.bind(null, data.id)}
                    id={data.id}
                />
            )}
            <img src={image} alt="" />
            <div className={styles.body}>
                <Typography.Title htmlElement="h3">
                    #{data.publish_order} {data.auction_name}
                </Typography.Title>
                {data.status.display_name === 'Active' && (
                    <>
                        {data.access_secret_url ? (
                            <div className={styles.buttonsWrapper}>
                                <Button type="immo-bordered" onClick={copyLinkToClipboard}>
                                    {t('auction_item_copy_link_for_owner')}
                                </Button>
                                <Button
                                    className={styles.deleteLink}
                                    type="red"
                                    onClick={deleteGeneratedLink}
                                >
                                    {t('auction_item_delete_link_for_owner')}
                                </Button>
                            </div>
                        ) : (
                            <Button type="immo" onClick={() => setShowGenerateLinkModal(true)}>
                                {t('auction_item_generate_link')}
                            </Button>
                        )}
                        <GenerateLinkModal
                            auctionId={data.id}
                            refreshActiveAuctions={refreshActiveAuctions}
                            isOpen={showGenerateLinkModal}
                            onClose={() => setShowGenerateLinkModal(false)}
                        />
                        <br />
                        <InfoLine title={t('auction_item_biddings')}>{data.all_bids}</InfoLine>
                        <InfoLine title={t('auction_item_highest_bid')}>
                            {ParseHelper.formatCurrency(data.highest_bid)}
                        </InfoLine>
                        <InfoLine title={t('auction_item_auction_ends')}>
                            {timeLeft.days}d {timeLeft.hours}h {timeLeft.minutes}m
                        </InfoLine>
                    </>
                )}
                {data.status.display_name === 'Scheduled' && (
                    <>
                        <InfoLine title={t('auction_item_minimum_offer')}>
                            {ParseHelper.formatCurrency(Number(data.offer_minimum))}
                        </InfoLine>
                        <InfoLine title={t('auction_item_auction_starts')}>
                            {moment(data.starts_at).format('MMMM DD [at] HH:mm')}
                        </InfoLine>
                    </>
                )}
                {data.status.display_name === 'Ended' && (
                    <>
                        <InfoLine title={t('auction_item_biddings')}>{data.all_bids}</InfoLine>
                        <InfoLine title={t('auction_item_final_bids')}>
                            {ParseHelper.formatCurrency(data.highest_bid)}
                        </InfoLine>
                        <InfoLine title={t('auction_item_auction_ended')}>
                            {moment(data.ends_at).format('MMMM DD - HH:mm')}
                        </InfoLine>
                        <InfoLine title={t('auction_item_winner')}>{data.winner}</InfoLine>
                    </>
                )}
            </div>
        </div>
    );
};

type InfoLineProps = {
    title: string;
};

const InfoLine: React.FC<InfoLineProps> = ({ title, children }) => {
    return (
        <div className={styles.info}>
            <div className={styles['info-name']}>{title}</div>
            <div className={styles.value}>{children}</div>
        </div>
    );
};

type ItemOptionsProps = {
    id: string;
    type: string;
    onDelete: () => void;
};

const ItemOptions: React.FC<ItemOptionsProps> = ({ id, onDelete, type }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <div className={styles.options}>
            <div className={styles['options-icon']}>
                <div className={styles.dot} />
                <div className={styles.dot} />
                <div className={styles.dot} />
            </div>
            <div className={styles.dropdown}>
                <ul>
                    <li onClick={() => navigate(ImmoUrls.auction.update.replace(':id', id))}>
                        {type === 'Scheduled' ? t('auction_item_edit') : t('auction_item_view')}
                    </li>
                    <li onClick={onDelete}>
                        {type === 'Scheduled'
                            ? t('auction_item_delete')
                            : t('auction_item_delete_permanently')}
                    </li>
                </ul>
            </div>
        </div>
    );
};

interface GenerateLinkModalProps {
    auctionId: string;
    isOpen: boolean;
    onClose: () => void;
    refreshActiveAuctions: () => Promise<void>;
}

export const GenerateLinkModal: React.FC<GenerateLinkModalProps> = ({
    auctionId,
    isOpen,
    onClose,
    refreshActiveAuctions,
}) => {
    const { t } = useTranslation();
    const { showNotification } = useContext(NotificationContext);

    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const generateLinkForOwner = async () => {
        try {
            setIsLoading(true);
            await api.auctionService.generateLinkForOwner(auctionId, password);
            await refreshActiveAuctions();
            showNotification('success', t('auction_item_generate_link_now_success'));
            onClose();
        } catch (err) {
            showNotification('error', t('auction_item_generate_link_now_error'));
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            maxWidth={600}
            onClose={onClose}
            className={styles.generateLinkModal}
        >
            <Typography.Title htmlElement="h3">{t('auction_item_generate_link')}</Typography.Title>
            <Typography.Text>{t('password')}</Typography.Text>
            <div className={styles.passwordWrapper}>
                <ImmoInput
                    type={showPassword ? 'text' : 'password'}
                    placeholder={t('password_placeholder')}
                    onChange={e => {
                        setPassword(e.target.value);
                    }}
                />
                <div
                    className={styles.passwordEyeIcon}
                    onClick={() => setShowPassword(value => !value)}
                >
                    {showPassword ? (
                        <IconSC color={Color.cloudy_blue} name={IconUnicodes.eye_off} size={25} />
                    ) : (
                        <IconSC color={Color.cloudy_blue} name={IconUnicodes.eye} size={25} />
                    )}
                </div>
            </div>
            <div className={styles.buttons}>
                <Button type="immo-bordered" onClick={onClose}>
                    {t('cancel')}
                </Button>
                <Button
                    htmlType="submit"
                    type="immo"
                    isDisabled={isLoading || !password}
                    onClick={generateLinkForOwner}
                >
                    {t('auction_item_generate_link_now')}
                </Button>
            </div>
        </Modal>
    );
};
