import React from 'react';

import { PrimaryButton } from 'units/common/components/buttons/primary/primary.component';
import { TransparentButton } from 'units/common/components/buttons/transparent/transparent.component';
import { Modal } from 'units/common/components/modal/modal.component';

import { ProductItem } from 'units/products/pages/product/store/types';

import { FulFillModalSC } from './FulFillModal.styles';

type FulFillModalProps = {
    data:ProductItem;
    isOpen:boolean;
    onClose: () => void;
    onConfirm: () => void;
}

export const FulFillModal:React.FC<FulFillModalProps> = ({ onClose, isOpen, data, onConfirm }) => (
    <Modal isOpen={isOpen} onCloseModal={onClose} title='Fulfill order'>
        <FulFillModalSC.Text>
            A fulfillment confirmation will be sent to your<br />client automatically.
        </FulFillModalSC.Text>
        <FulFillModalSC.Product>
            <FulFillModalSC.Info>
                {data && data.images && data.images.length && <img src={data.images[0].image} alt="" />}
                <span>{data.name}</span>
            </FulFillModalSC.Info>
            <FulFillModalSC.Quantity>
                Quantity: 1
            </FulFillModalSC.Quantity>
        </FulFillModalSC.Product>
        <FulFillModalSC.ButtonsWrapper>
            <TransparentButton onClick={onClose}>
                Cancel
            </TransparentButton>
            <PrimaryButton onClick={onConfirm}>
                Confirm
            </PrimaryButton>
        </FulFillModalSC.ButtonsWrapper>
    </Modal>
)
