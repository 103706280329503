import React from 'react';

import { UploadPictureSC } from './upload-picture.style';

export const ProductPictureFakeUploading = () => (
  <UploadPictureSC.Loading>
    <UploadPictureSC.LoadedPart></UploadPictureSC.LoadedPart>
    <UploadPictureSC.UnLoadedPart></UploadPictureSC.UnLoadedPart>
  </UploadPictureSC.Loading>
);
