export const usePageScroll = () => {
    const scrollTop = (emailFocus?: boolean) => {
        scrollTo(0);
        if (emailFocus) {
            setTimeout(() => document.getElementById('email-contact-input')?.focus(), 1000);
        }
    };

    const scrollTo = (position: number) => {
        window.scrollTo({
            top: position,
            behavior: 'smooth',
        });
    };

    const scrollToSection = (id: string) => {
        let position = 0;

        if (id) {
            const element = document.getElementById(id);

            if (element) {
                position = element.getBoundingClientRect().top + window.scrollY;
            }
        }

        scrollTo(position);
    };

    return { scrollToSection, scrollTop };
};
