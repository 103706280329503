import styled from 'styled-components';

const WelcomeForm = styled.div`
  width: 530px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InnerFormWrapper = styled.div`
    width: 100%;
    margin-bottom: 48px;

    & div:first-child {
        margin-bottom: 24px;
    }
`;

export const WelcomeSC = {
    WelcomeForm,
    InnerFormWrapper,
}
