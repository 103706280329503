import { convertFromRaw, EditorState } from 'draft-js';

import { EditProductTabs } from '../pages/product/constants';

import { 
    EditProductDescription,
    EditProductSavedPicture,
    EditProductSeo,
    ProductDescription,
    ProductImage,
    ProductItem,
    ProductSeo,
    EditProductLanguagesForm,
    EditProductCommonFields,
    EditProductForm
} from '../pages/product/store/types';
import { CompanyLanguages } from 'units/app/redux/types';

const prepareProductDescription = (data: Array<ProductDescription>): Array<EditProductDescription> => data.map(
  ({ description_body, description_type }) => ({
    type: description_type,
    description: EditorState.createWithContent(convertFromRaw(JSON.parse(description_body))),
  })
);
  
const prepareProductPictures = (data: Array<ProductImage>): Array<EditProductSavedPicture> => (
  data.map(({ id, image }) => ({ id, src: image }))
);
  
export const prepareProductSeo = (data:ProductSeo):EditProductSeo => ({
  seoPageTitle: data.page_title,
  seoPageDescription: data.page_body
});

export const prepareEditProductForm = (data: ProductItem) => {
  const languagesForm: EditProductLanguagesForm = {};

  for(const [lang, value] of Object.entries(data.product_info)) {
    languagesForm[lang as CompanyLanguages] = {
      [EditProductTabs.general]: {
        productName: value.product_name,
        usp1: value.USP.USP_1,
        usp2: value.USP.USP_2,
        usp3: value.USP.USP_3,
        productCatchPhrase: value.product_catch_phrase,
        descriptions: prepareProductDescription(value.description),
        maxUvp: data.max_bid_amount || 0,
        minimumOfferAmount: data.minimum_offer_amount || 0
      },
      [EditProductTabs.seo]: prepareProductSeo(value.SEO)
    }
  }

  const commonFields: EditProductCommonFields = {
    uvp: parseFloat(data.uvp),
    deliveryDays: data.days_for_delivery,
    shippingCost: parseFloat(data.shipping_cost),
    pictures: prepareProductPictures(data.images),
    quantityAtStock: data.quantity,
  }

  const form: EditProductForm = {
    languages: languagesForm,
    commonFields,
  };

  return form;
};
