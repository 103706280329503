import { useSelector } from 'react-redux';

import { CMSThemeId } from '../../store/types';
import { CMSThemeSettings } from '../../store/types/theme-settings';
import { RootState } from 'redux/root-types';
import { useCMSActiveTheme } from './useCMSActiveTheme';

// Hook to retrieve cms theme settings by theme passed as prop or active from store.
export const useCMSThemeSettings = (theme?: CMSThemeId) => {
  const { id: themeId } = useCMSActiveTheme();

  const themeSettings = useSelector<RootState, CMSThemeSettings>(
    ({ cms: { config } }) => config.themeSettings[theme || themeId].settings,
  );

  return themeSettings;
};
