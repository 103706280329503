import moment from 'moment';

import { ApiClient, apiPath } from 'config/api/api';
import { BuyNowAuctionValues, AuctionValues, RegularAuctionValues, Condition } from '../types';
import { Auction, Discount, Status } from '../constants';
import { DateHelper } from 'shared/helpers';

type DateTime = Date | null | undefined;

const convertLocalToUtc = (date: DateTime, time: DateTime) => {
    const momDate = moment(date);
    const momTime = moment(time);
    let mergedDate = null;

    if (date && time) {
        mergedDate = momDate
            .set({ hour: momTime.get('hour'), minute: momTime.get('minute') })
            .utc();
        return {
            date: mergedDate.format('YYYY-MM-DD'),
            time: mergedDate.format('HH:mm'),
            mergedDate,
        };
    } else if (date) {
        return { date: momDate.format('YYYY-MM-DD'), time: null, mergedDate };
    } else if (time) {
        return { date: null, time: momTime.utc().format('HH:mm'), mergedDate };
    }

    return { date: null, time: null, mergedDate };
};

const convertUtcToLocal = (date: string | null) => {
    const data = DateHelper.convertUtcToLocal(date);

    return { date: moment(data, 'YYYY-MM-DD').toDate(), time: moment(data, 'HH:mm').toDate() };
};

const getBuyNowBody = (values: BuyNowAuctionValues, status: any) => {
    const startDateTime = convertLocalToUtc(values.startDate, values.startTime);
    const endDateTime = convertLocalToUtc(values.endDate, values.endTime);

    return {
        starts_at: startDateTime.mergedDate,
        ends_at: endDateTime.mergedDate,
        discount: {
            type: Number(values.discountType),
            value: values.discount || null,
        },
        type: Number(Auction.buyNow),
        product: values.productId,
        uvp: values.uvp || null,
        status,
        is_published: status === Status.scheduled,
        auction_name: values.auctionName,
    };
};

const getRegularAuctionBody = (values: RegularAuctionValues, status: any) => {
    const startDateTime = convertLocalToUtc(values.startDate, values.startTime);
    const endDateTime = convertLocalToUtc(values.endDate, values.endTime);

    const conditions = values.conditions
        .filter(item => item.value)
        .map(item => ({
            greater_than: item.value || undefined,
            product_pcs: item.place || undefined,
        }));

    return {
        starts_at: startDateTime.mergedDate,
        ends_at: endDateTime.mergedDate,
        offer_interval: values.offerInterval || null,
        type: Number(Auction.auction),
        offer_minimum: values.minOffer || null,
        minimum_product_pcs: values.minProdPieces || null,
        product: values.productId,
        conditions,
        status,
        is_published: status === Status.scheduled,
        auction_name: values.auctionName,
    };
};

export const createAuction = async (values: AuctionValues, status: any) => {
    const api = ApiClient.getInstance();
    const bodyReq =
        values.auctionType === Auction.buyNow
            ? getBuyNowBody(values, status)
            : getRegularAuctionBody(values, status);

    const { data } = await api.post(`${apiPath}/auctions/create/`, bodyReq);

    return { id: data.id };
};

export const editAuction = async (values: AuctionValues, status: any) => {
    const api = ApiClient.getInstance();
    const bodyReq =
        values.auctionType === Auction.buyNow
            ? getBuyNowBody(values, status)
            : getRegularAuctionBody(values, status);

    const response = await api.patch(`${apiPath}/auctions/update/${values.auctionId}/`, bodyReq);
    return response;
};

export const getAuctionData = async (id: string) => {
    const api = ApiClient.getInstance();
    const { data } = await api.get(`${apiPath}/auctions/retrieve/${id}/`);

    const startDateTime = convertUtcToLocal(data.starts_at);
    const endDateTime = convertUtcToLocal(data.ends_at);
    const greaterConditions = data.conditions;
    const discount = data.discount;
    const conditions: Array<Condition> = [
        { value: null, place: null },
        { value: null, place: null },
        { value: null, place: null },
        { value: null, place: null },
    ];

    if (greaterConditions) {
        greaterConditions.forEach(
            (item: { greater_than: number; product_pcs: number }, index: number) => {
                conditions[index] = { value: item.greater_than, place: item.product_pcs };
            },
        );
    }

    return {
        auction: {
            auctionId: data.id,
            auctionStatus: data.status.value,
            auctionType: data.type.value.toString(),
            productId: data.product.id,
            startDate: startDateTime.date,
            startTime: startDateTime.time,
            endDate: endDateTime.date,
            endTime: endDateTime.time,
            endDateCopy: endDateTime.date,
            endTimeCopy: endDateTime.time,
            uvp: data.uvp || null,
            discount: discount ? discount.value : null,
            discountType: discount ? discount.type.toString() : Discount.currency,
            minOffer: data.offer_minimum || null,
            minProdPieces: data.minimum_product_pcs || null,
            offerInterval: data.offer_interval || null,
            conditions,
            isArchived: data.is_archived,
            quantity: data.product.quantity,
            auctionName: data.auction_name,
        },
        product: data.product,
    };
};
