import styled from 'styled-components';

import { CMSAccordionTitleStyle } from './cmsAcordionSection.component';
import { Color, sizes } from 'components/styles/constants.styles';
import { TextStyle13, FormInputLabelText } from 'components/styles/text.styles';

interface DeviderProps {
  lastSection?: boolean;
}

type SectionProps = {
  marginBottom?: string | number;
}

const Container = styled.div<SectionProps>`
  ${({ marginBottom }) => marginBottom && `
    margin-bottom: ${typeof marginBottom === 'number' ? marginBottom + 'px' : marginBottom} !important;
  `}
`;

const Title = styled.div<{ titleStyle: CMSAccordionTitleStyle }>`
  margin-bottom: ${sizes.xs_margin};

  ${({ titleStyle }) => titleStyle === 'default'
    ? TextStyle13
    : FormInputLabelText
  }
`;

const Content = styled.div`
  > div {
    margin-bottom: ${sizes.sm_margin};
  }
`;

const Devider = styled.div<DeviderProps>`
  height: 1px;
  width: 100%;
  background: ${Color.cloudy_blue};
  margin: ${sizes.lg_margin} 0 ${sizes.md_margin} 0;
  ${({ lastSection }) => lastSection && 'margin-bottom: 0;'}
`;

export const CmsAcordionSectionSC = {
  Container,
  Content,
  Devider,
  Title,
};
