import React, { FC } from 'react';

import { TabSwitchSC } from './tab-switch.style';

type Tab = {
  id: string;
  title: string;
}

export interface TabSwitchProps {
  tabs: Array<Tab>;
  selectedTabIndex: number;
  onTabClick: (tabIndex: number) => void;
}

export const TabSwitch: FC<TabSwitchProps> = ({ tabs, selectedTabIndex, onTabClick }) => (
  <TabSwitchSC.Container>
    { tabs.map((t, index) => (
      <TabSwitchSC.Tab
        key={t.id}
        selected={index === selectedTabIndex}
        onClick={onTabClick.bind(null, index)}
      >
        { t.title }
      </TabSwitchSC.Tab>
    )) }
  </TabSwitchSC.Container>
)
