export const APP_ADD_IS_FETCHING = 'APP_ADD_IS_FETCHING';
export const APP_REMOVE_IS_FETCHING = 'APP_REMOVE_IS_FETCHING';

export const APP_SET_INITIALIZED_WITH_AUTHORIZED_USER = 'APP_SET_INITIALIZED_WITH_AUTHORIZED_USER';

// company settings

export const APP_SET_COMPANY_SETTINGS = 'APP_SET_COMPANY_SETTINGS';
export const APP_SET_COMPANY_LANGUAGES = 'APP_SET_COMPANY_LANGUAGES';

// third party integrations

export const APP_SET_THIRD_PARTY_INTEGRATIONS = 'APP_SET_THIRD_PARTY_INTEGRATIONS';
export const APP_SET_THIRD_PARTY_INTEGRATION = 'APP_SET_THIRD_PARTY_INTEGRATION';
export const APP_UPDATE_THIRD_PARTY_INTEGRATION = 'APP_UPDATE_THIRD_PARTY_INTEGRATION';

// clearing

export const APP_CLEAR_REDUCER = 'APP_CLEAR_REDUCER';
