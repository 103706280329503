import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';

import { IconSC, IconUnicodes } from 'components/styles/icons.styles';
import { Status } from '../../../create-auction/constants';
import { ActionsSC } from './actions.styles';
import { Auction } from '../../types';
import { AppUrls } from 'app/urls';

interface Props {
    data: Auction;
    onDelete: (id: string) => void;
    onArchive: (id: string) => void;
}

export const Actions: FC<Props> = ({ data, onDelete, onArchive }) => {
    const [isDropdownOpened, setIsDropdownOpened] = useState(false);
    const status = data.status.value;
    const isScheduled = status === Status.scheduled;
    const isArchived = data.isArchived;
    const isActive = status === Status.active;
    const isDraft = status === Status.draft;
    const isEnded = status === Status.ended;

    const handleMouseEnter = (event: React.MouseEvent) => {
        setIsDropdownOpened(true);
        event.currentTarget.parentElement?.parentElement?.classList.add('active-row');
    };

    const handleMouseLeave = (event: React.MouseEvent) => {
        setIsDropdownOpened(false);
        event.currentTarget.parentElement?.parentElement?.classList.remove('active-row');
    };

    const handleDelete = () => {
        onDelete(data.id);
    };

    const handleArchive = () => {
        onArchive(data.id);
    };

    const getAuctionLink = (mode: string) => `${AppUrls.auctions.index}/${mode}/${data.id}`;

    const setArchivedTab = () => localStorage.setItem('archivedTab', '1');

    return (
        <ActionsSC.Container onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <IconSC name={IconUnicodes.dots} />
            {isDropdownOpened && (
                <ActionsSC.Dropdown>
                    {isArchived ? (
                        <Link to={getAuctionLink('view')} onClick={setArchivedTab}>
                            <div>View</div>
                        </Link>
                    ) : (
                        <>
                            {isScheduled && (
                                <>
                                    <Link to={getAuctionLink('edit')}>
                                        <div>Edit</div>
                                    </Link>
                                    <div onClick={handleDelete}>Delete</div>
                                </>
                            )}
                            {isActive && (
                                <Link to={getAuctionLink('edit')}>
                                    <div>Edit</div>
                                </Link>
                            )}
                            {isDraft && (
                                <>
                                    <div onClick={handleArchive}>Archive</div>
                                    <Link to={getAuctionLink('edit')}>
                                        <div>Edit</div>
                                    </Link>
                                    <div onClick={handleDelete}>Delete</div>
                                </>
                            )}
                            {isEnded && (
                                <>
                                    <div onClick={handleArchive}>Archive</div>
                                    <Link to={getAuctionLink('view')}>
                                        <div>View</div>
                                    </Link>
                                </>
                            )}
                        </>
                    )}
                </ActionsSC.Dropdown>
            )}
        </ActionsSC.Container>
    );
};
