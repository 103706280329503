import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Container } from '../../components/grid';
import { LPTypography } from '../../components/ui';

import { LPPrivacyPolicySC } from './privacy-policy.styles';

export const LPPrivacyPolicy: FC = () => {
  const { t } = useTranslation();

  return (
    <Container styles={LPPrivacyPolicySC.ContainerCSS}>
      <LPTypography.H2 styles={LPPrivacyPolicySC.TitleCSS}>{t('lp_policy_privacy_title')}</LPTypography.H2>
      <LPPrivacyPolicySC.TextBox>
        <LPTypography.Text>
          {t('lp_privacy_policy_title_first_row')}
        </LPTypography.Text>
        <LPTypography.Text>
          {t('lp_policy_privacy_title_second_row')}
        </LPTypography.Text>
      </LPPrivacyPolicySC.TextBox>

      <LPPrivacyPolicySC.TextBox>
        <LPTypography.H3 styles={LPPrivacyPolicySC.SubTitleCSS}>
          {t('lp_privacy_policy_analytics_title')}
        </LPTypography.H3>
        <LPTypography.Text>
          {t('lp_privacy_policy_analytics_first-row')}
        </LPTypography.Text>
        <LPTypography.Text>
          {t('lp_privacy_policy_analytics_second_row')}
        </LPTypography.Text>
      </LPPrivacyPolicySC.TextBox>

      <LPPrivacyPolicySC.TextBox>
        <LPTypography.H3 styles={LPPrivacyPolicySC.SubTitleCSS}>
          {t('lp_privacy_policy_interaction_title')}
        </LPTypography.H3>
        <LPTypography.Text>
          {t('lp_privacy_policy_interaction_first_row')}
        </LPTypography.Text>
        <LPTypography.Text>
          {t('lp_privacy_policy_interaction_second_row')}
        </LPTypography.Text>
      </LPPrivacyPolicySC.TextBox>

      <LPPrivacyPolicySC.TextBox>
        <LPTypography.H3 styles={LPPrivacyPolicySC.SubTitleCSS}>
          {t('lp_privacy_policy_traffic_title')}
        </LPTypography.H3>
        <LPTypography.Text>
          {t('lp_privacy_policy_traffic_first_row')}
        </LPTypography.Text>
        <LPTypography.Text>
          {t('lp_privacy_policy_traffic_second_row')}
        </LPTypography.Text>
      </LPPrivacyPolicySC.TextBox>

      <LPPrivacyPolicySC.TextBox>
        <LPTypography.H3 styles={LPPrivacyPolicySC.SubTitleCSS}>
          {t('lp_privacy_policy_payments_title')}
        </LPTypography.H3>
        <LPTypography.Text>
          {t('lp_privacy_policy_payments_first_row')}
        </LPTypography.Text>
        <LPTypography.Text>
          {t('lp_privacy_policy_payments_second_row')}
        </LPTypography.Text>
      </LPPrivacyPolicySC.TextBox>

      <LPPrivacyPolicySC.TextBox>
        <LPTypography.H3 styles={LPPrivacyPolicySC.SubTitleCSS}>
          {t('lp_privacy_policy_contacts_messages_title')}
        </LPTypography.H3>
        <LPTypography.Text>
          {t('lp_privacy_policy_contacts_messages_first_row')}
        </LPTypography.Text>
        <LPTypography.Text>
          {t('lp_privacy_policy_contacts_messages_second_row')}
        </LPTypography.Text>
      </LPPrivacyPolicySC.TextBox>

      <LPPrivacyPolicySC.TextBox marginBottom="20px">
        <LPTypography.H3 styles={LPPrivacyPolicySC.SubTitleCSS}>
          {t('lp_privacy_policy_further_title')}
        </LPTypography.H3>
        <LPTypography.Text>
          {t('lp_privacy_policy_further_first_row')}
        </LPTypography.Text>
        <LPTypography.Text>
          {t('lp_privacy_policy_further_second_row')}
        </LPTypography.Text>
        <LPTypography.Text>
          {t('lp_privacy_policy_further_third_row')}
        </LPTypography.Text>
      </LPPrivacyPolicySC.TextBox>

      <LPPrivacyPolicySC.TextBox marginBottom="20px">
        <LPTypography.H3 styles={LPPrivacyPolicySC.SubTitleCSS}>
          {t('lp_privacy_policy_contact_details_title')}
        </LPTypography.H3>
        <ul>
          <li>
            {t('lp_privacy_policy_contact_details_first_row')}
          </li>
          <li>
            MindNow AG
          </li>
          <li>
            Okenstr. 6
          </li>
          <li>
            {t('lp_privacy_policy_contact_details_fourth_row')}
          </li>
          <li>
            {t('lp_privacy_policy_contact_details_fifth_row')}
          </li>
          <li>
            {t('lp_privacy_policy_contact_details_sixth_row')}
            <a href="mailto:hello@mindnow.io">hello@mindnow.io</a>
          </li>
        </ul>
      </LPPrivacyPolicySC.TextBox>
    </Container>
  );
};
