import styled from 'styled-components';

import { Font } from 'components/types/enums';
import { Color } from 'components/styles/constants.styles';

const Container = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
`;

const Slider = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  border-radius: 32px;

  background-color: ${Color.bluey_grey};
  transition: 0.4s;
  pointer-events: none;

  &:before {
    content: "";
    position: absolute;
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    border-radius: 32px;

    background-color: #fff;
    transition: 0.2s;
    pointer-events: none;
  }
`;

const Input = styled.input.attrs({
  type: 'checkbox',
})`
  opacity: 0;
  width: 100%;
  height: 100%;

  cursor: pointer;
  pointer-events: all;

  &:checked + ${Slider} {
    background-color: ${Color.lightish_blue};
  }

  &:checked + ${Slider}:before {
    transform: translateX(10px);
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

const ToggleContainer = styled.div`
  position: relative;
  width: 30px;
  height: 20px;
  border-radius: 32px;
  pointer-events: none;
`;

const Label = styled.label`
  margin-left: 8px;

  user-select: none;

  font-family: ${Font.regular};
  font-size: 14px;
  color: ${Color.bluey_grey};
`;

export const BaseToggleButtonSC = {
  Container,
  ToggleContainer,
  Slider,
  Input,
  Label,
};
