import {
  prepareCustomizationProgress as helpers,
  CMSProgressInitialSection,
  CMSUpdateFilledProgress,
  CMSCustomizationCommonProgress,
} from '../prepare-customization-progress';
import { CMSCustomizationProductDetailPageSectionName, CMSCustomizationProductDetailPageProgressField } from '../../types';
import { CMSProductDetailPageCustomizationSettings } from '../../store/types/customization/product-detail-page';
import { CompanyLanguages } from 'units/app/redux/types';
import { CMSCustomizationOverallSettings } from '../../store/types/customization';

// Alias
type HP_SN = CMSCustomizationProductDetailPageSectionName;
type HP_PK = CMSCustomizationProductDetailPageProgressField;

export const prepareProductDetailPageProgress = (
  overallSettings: CMSCustomizationOverallSettings<CMSProductDetailPageCustomizationSettings>,
) => {
  // Hold one language settings which gonna be used in common comparison.
  const commonSettings = Object.values(overallSettings)[0];

  if (!commonSettings) {
    return {};
  }

  const languages = Object.keys(overallSettings) as Array<CompanyLanguages>;
  // Holds sections with fields that is required for progress.
  const requiredProgress = helpers.initializeRequiredProgress<HP_SN, HP_PK>(productDetailPageInitialSections);
  // Holds sections with fields that already filled based on overallSettings.
  const filledProgress = helpers.initializeFilledProgress<HP_SN, HP_PK>(productDetailPageInitialSections.map((s) => ({
    ...s,
    initialProgress: [],
  })), languages);

  // Prefilled progress
  for (const [lng, lngSettings] of Object.entries(overallSettings)) {
    if (!lngSettings) {
      continue;
    }

    const language = lng as CompanyLanguages;
    const updates = prepareProductDetailPageFilledProgress(lngSettings);
    helpers.updateFilledProgress(filledProgress, language, updates);
  }

  return { sectionProgress: helpers.prepareSectionProgress(requiredProgress, filledProgress, commonFields) };
}

const prepareProductDetailPageFilledProgress = ({ hero, relatedProducts, newsletterOptIn }: CMSProductDetailPageCustomizationSettings) => {
  const updates: Array<CMSUpdateFilledProgress<HP_SN, HP_PK>> = [];

  // Hero section
  let sectionKeys: Array<HP_PK> = [];

  hero.CTAcopy && sectionKeys.push('CTAcopy');
  hero.graph1.title && sectionKeys.push('graph1.title');
  hero.graph2.title && sectionKeys.push('graph2.title');
  updates.push({ sectionName: 'hero', progressKeys: sectionKeys });
  

  // Related products section
  sectionKeys = [];

  relatedProducts.sectionTitle && sectionKeys.push('sectionTitle');
  updates.push({ sectionName: 'relatedProducts', progressKeys: sectionKeys });

  return updates;
}

const productDetailPageInitialSections: Array<CMSProgressInitialSection<HP_SN, HP_PK>> = [{
  name: 'hero',
  initialProgress: ['CTAcopy', 'graph1.title', 'graph2.title'],
},{
  name: 'relatedProducts',
  initialProgress: ['sectionTitle'],
}, {
  name: 'newsletterOptIn',
  initialProgress: [],
}];

export const commonFields: CMSCustomizationCommonProgress<HP_SN, HP_PK> = {
  hero: [],
  relatedProducts: [],
  newsletterOptIn: [],
};
