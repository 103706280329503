import { http } from "api/http";
import { ResponseDataType } from "api/types";

import { RefundOrder } from "shared/types";
import { OrderStatusEnum } from "units/orders/types";

const refund = (id:string, data:RefundOrder):Promise<ResponseDataType<any>> => {
    return http.post<any>(`/orders/${id}/refunds/`, data);
}

const action = (id:string | number, status:OrderStatusEnum):Promise<ResponseDataType<any>> => {
    return http.patch(`/orders/${id}`, { status })
}

export const createOrderService = () => ({
    refund,
    action
})