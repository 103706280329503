import { createStore, applyMiddleware, Middleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import { persistStore } from 'redux-persist';

import { rootReducer } from './root-reducer';

const middlewares: Array<Middleware> = [thunk];
if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_REDUX_LOGS === 'on') {
    middlewares.push(logger);
}

export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middlewares)));

export const persistor = persistStore(store);
