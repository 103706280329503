import deepEqual from 'deep-equal';
import get from 'lodash.get';

import { CMSOverallFieldToUpdate, CreateOverallUpdatesParams } from '../../hooks/customization-tab/useCMSCustomizationFormikSettings';

// Helpers

// Compare two slices of settings for equality with lodash get.
export const arePartsOfSettingsEqual = (getPath: string, settings1: any, settings2: any) => deepEqual(
  get(settings1, getPath),
  get(settings2, getPath)
);

// Compare a slice of settings with a value for equality with lodash get.
export const hasSameValue = (getPath: string, settings: any, value: any) => deepEqual(get(settings, getPath), value);

export const overallUpdatesCreator = (sharedFields: Array<string>) => <T>({
  currentSettings,
  newSettings,
}: CreateOverallUpdatesParams<T>) => {
  const overallUpdates: Array<CMSOverallFieldToUpdate> = [];
  sharedFields.forEach(getPath => {
    // Check if shared field is equal between languages and update with current settings if not.
    if (!arePartsOfSettingsEqual(getPath, newSettings, currentSettings)) {
      overallUpdates.push({ path: getPath, value: get(newSettings, getPath) });
    }
  });

  return overallUpdates;
};

/* TODO: remove if unnecessary

// Checks if field cms config already has some filled-in value.
export const hasRealValue = (getPath: string, settings: any) => {
  const value = get(settings, getPath);
  const isNotBoolean = typeof value !== 'boolean';
  const isNotEmptyArray = Array.isArray(value) && !!value.length;
  const isNotColor = !checkIfColor(value);
  return !!value && isNotBoolean && isNotEmptyArray && isNotColor;
};

function checkIfColor(strColor: string){
  const s = new Option().style;
  s.color = strColor;
  // invalid CSS property value will not persist
  const simpleCompare = s.color === strColor;
  const regexCompare = /^#[0-9A-F]{6}$/i.test(strColor);
  return simpleCompare || regexCompare;
}
*/
