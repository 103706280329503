/* eslint-disable import/named */
import styled, { FlattenSimpleInterpolation } from 'styled-components';
/* eslint-enable import/named */

import { fonts } from 'components/styles/helpers.styles';
import { MediaQueries } from 'units/landing-page/config';

const Root = styled.div<{ styles?:FlattenSimpleInterpolation }>`
    padding: 16px 45px;
    background: linear-gradient(45deg, #F1E6FF 0%, #F3F6FC 100%);
    border-radius: 16px;
    display: inline-block;
    text-align: center;
    font-family: ${fonts.bold};

    @media (max-width: ${MediaQueries.tablet}) {
      font-size: 14px;
      padding: 12px 25px;
    }

    ${props => props.styles};
`;

export const LPTextLabelSC = {
  Root
};
