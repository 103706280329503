import styled from 'styled-components';

import { Form as FormikForm } from 'formik';

const Form = styled(FormikForm)`
  height: 100%;
`;

export const ResetPasswordSC = {
  Form
};
