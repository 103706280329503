import React, { FC } from 'react';

import { ConditionItemSC } from './condition-item.styles';
import { AuctionConfigurationSC } from '../../auction-configuration/auction-configuration.styles';
import { FormikFormInput } from 'units/common/components/formik/input/form-input.component';

interface Props {
  label: string;
  name: string;
  error?: {
    value: string;
    place: string;
  },
  disabled?: boolean;
}

export const ConditionItem: FC<Props> = ({ label, name, error, disabled }) => (
  <ConditionItemSC>
    <AuctionConfigurationSC.RowContainer>
      <FormikFormInput
        label={label}
        name={`${name}.value`}
        placeholder='0'
        withMargins
        withBackground
        type='money'
        error={error?.value}
        disabled={disabled}
      />
      <AuctionConfigurationSC.AdditionalBlock>CHF</AuctionConfigurationSC.AdditionalBlock>
    </AuctionConfigurationSC.RowContainer>
    <AuctionConfigurationSC.RowContainer>
      <FormikFormInput
        label='place'
        name={`${name}.place`}
        placeholder='0'
        withMargins
        withBackground
        type='number'
        error={error?.place}
        disabled={disabled}
      />
      <AuctionConfigurationSC.AdditionalBlock>pcs in the auction</AuctionConfigurationSC.AdditionalBlock>
    </AuctionConfigurationSC.RowContainer>
  </ConditionItemSC>
);
