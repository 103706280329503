import React, { InputHTMLAttributes } from 'react';
import clsx from 'clsx';

import styles from './ToggleSwitch.module.scss';

export const ToggleSwitch:React.FC<InputHTMLAttributes<HTMLInputElement>> = ({ className, ...props }) => (
    <label className={clsx(styles.root, className)}>
        <input className={styles.input} type="checkbox" {...props} />
        <span className={styles.slider} />
    </label>
)