import React, { useState, useCallback, useMemo, FC, useEffect, useContext } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { EnterNewPassword } from './enter-new-password/enter-new-password.component';
import { InitResetPassword } from './init-reset-password/init-reset-password.component';
import { resetPasswordEmailValidationSchema, resetPasswordNewPasswordValidationSchema } from './validation';
import { ResetPasswordValues, UserState } from '../../redux/types';
import { resetPasswordThunk, confirmResetPasswordThunk } from '../../redux/thunks';
import { resetPasswordSetUserId, setUserTempPayload, setUserErrorState } from '../../redux/action-creators';
import { RootState } from 'redux/root-types';
import { UserErrorKeys } from '../../constants';
import { CheckEmail } from '../../components/check-email/check-email.component';
import { useAsyncDispatch } from 'helpers/hooks/useAsyncDispatch';
import { AppUrls } from 'app/urls';
import { AppStrings } from 'config/strings';
import { ResetPasswordSC } from './reset-password.styles';
import { NotificationContext } from 'shared/providers';

export enum ResetPasswordSteps {
  EnterEmail,
  CheckEmail,
  NewPassword,
}

const ResetPasswordValidationSchemas = [
  resetPasswordEmailValidationSchema,
  null,
  resetPasswordNewPasswordValidationSchema,
];

const checkEmailText = {
  title: 'Check your email to reset your password',
  subTitle: 'Please check your inbox and follow the link to reset a password',
  CTA: 'Back to login',
};

const initialValues: ResetPasswordValues = {
  email: '',
  password: '',
  repeatPassword: '',
};

export const ResetPassword: FC = () => {
  const {
    resetPasswordUserId,
    error: userError,
    user,
    tempPayload,
  } = useSelector<RootState, UserState>((state) => state.user);
  const { showNotification } = useContext(NotificationContext);
  const isNewPasswordStep = !!(resetPasswordUserId?.token && resetPasswordUserId?.uid);
  const [currentStep, setCurrentStep] = useState(isNewPasswordStep ? ResetPasswordSteps.NewPassword : ResetPasswordSteps.EnterEmail);
  const { dispatch: fetchDispatch } = useAsyncDispatch();
  const dispatch = useDispatch();
  const history = useHistory();

  const submitHandler = useCallback(async (values: ResetPasswordValues, actions: FormikHelpers<ResetPasswordValues>) => {
    if (currentStep === ResetPasswordSteps.EnterEmail) {
      await fetchDispatch(resetPasswordThunk(values.email, () => {
        setCurrentStep(currentStep + 1);
        actions.setTouched({});
        actions.setSubmitting(false);  
      }));
    } else if (currentStep === ResetPasswordSteps.NewPassword) {
      await fetchDispatch(confirmResetPasswordThunk({
        uid: resetPasswordUserId?.uid ?? '',
        token: resetPasswordUserId?.token ?? '',
        password: values.password,
        repeatPassword: values.repeatPassword,
      }, showNotification));
      actions.setSubmitting(false);
    }

    // clear hiddenErrors
    actions.setStatus({});
  }, [fetchDispatch, currentStep, resetPasswordUserId]);

  const resendConfirmation = useCallback(async () => {
    if (user?.email) {
      await fetchDispatch(resetPasswordThunk(user.email));
      showNotification('success', AppStrings.resetPasswordResendSuccess);
    }
  }, [user, fetchDispatch]);

  const backToLogin = useCallback(() => {
    history.push(AppUrls.user.login);
  }, [history]);

  const currentScreen = useMemo(() => {
    switch(currentStep) {
      case ResetPasswordSteps.EnterEmail:
        return <InitResetPassword userError={userError?.[UserErrorKeys.resetPassword]} />;
      case ResetPasswordSteps.CheckEmail:
        return (
          <CheckEmail
            texts={checkEmailText}
            resendClickHandler={resendConfirmation}
            CTAClickHandler={backToLogin}
          />);
      case ResetPasswordSteps.NewPassword:
        return <EnterNewPassword userError={userError?.[UserErrorKeys.confirmResetPassword]} />;
      default: return <div>Nothing</div>;
    }
  }, [currentStep, resendConfirmation, userError]);

  useEffect(() => (() => {
    dispatch(resetPasswordSetUserId(null));
    // Clear error state.
    dispatch(setUserErrorState(UserErrorKeys.resetPassword, null));
    // Clear prefilled email.
    dispatch(setUserTempPayload('resetPassword', { prefilledEmail: null }));
  }), []);

  const formikInitialValues: ResetPasswordValues = useMemo(() => ({
    ...initialValues,
    email: tempPayload.resetPassword?.prefilledEmail || initialValues.email,
  }), []);

  return (
    <Formik
      initialValues={formikInitialValues}
      validationSchema={ResetPasswordValidationSchemas[currentStep]}
      onSubmit={submitHandler}
    >
      <ResetPasswordSC.Form id={`reset-password-${currentStep}`}>
        { currentScreen }
      </ResetPasswordSC.Form>
    </Formik>
  );
}
