import styled, { css } from 'styled-components';

import { MediaQueries } from 'units//landing-page/config';

const GeneralSC = css`
  position: absolute;
  height: auto;
`;

const Root = styled.div`
  width: 100%;
  height: 100%;
`;

const Background = styled.img`
  ${GeneralSC}
  top: 0;
  left: 16%;

  @media (max-width: ${MediaQueries.tablet}) {
    max-width: 654px;
    left: 12%;
  }

  @media (max-width: ${MediaQueries.mobile}) {
    max-width: 444px;
    left: 15%;
    top: 25px;
  }
`;

const RootImage = styled.img`
  ${GeneralSC}
  top: 123px;
  left: -5%;

  @media (max-width: ${MediaQueries.tablet}) {
    top: 83px;
    left: -18%;
    max-width: 615px;
  }

  @media (max-width: ${MediaQueries.mobile}) {
    top: 79px;
    left: -5%;
    max-width: 394px;
  }
`;

const GraphOne = styled.img`
  ${GeneralSC}
  top: 0;
  left: 24%;
  @media (max-width: ${MediaQueries.tablet}) {
    top: -59px;
    left: 22%;
    max-width: 286px;
  }
  @media (max-width: ${MediaQueries.mobile}) {
    top: -10px;
    left: 24%;
    max-width: 180px;
  }
`;

const GraphTwo = styled.img`
  ${GeneralSC}
  bottom: -180px;
  left: 36%;
  @media (max-width: ${MediaQueries.tablet}) {
    bottom: -28px;
    left: 39%;
    max-width: 361px;
  }

  @media (max-width: ${MediaQueries.mobile}) {
    bottom: 236px;
    left: 36%;
    max-width: 228px;
  }
`;

export const LPAnalyticSC = {
  Root,
  Background,
  RootImage,
  GraphOne,
  GraphTwo
}
