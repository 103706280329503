import React, {FC } from 'react';

type Props = React.InputHTMLAttributes<HTMLInputElement>;

export const BaseStepInput: FC<Props> = (props) => (
  <input
    {...props}
    type="number"
    onKeyDown={e => e.preventDefault()}
  />
);
