import { AsyncAppThunk } from 'redux/root-types';
import {
  editProductSaveProduct,
  editProductSavePictures,
  editProductUpdateProduct,
  editProductFetchProduct,
  editProductDeleteImageById,
} from './services';
/* import { Alert } from 'units//common/components/toaster-notification/alert'; */
import { history } from 'helpers/history';
import { AppUrls } from 'app/urls';
import { AppStrings } from 'config/strings';
import { prepareEditProductForm } from 'units/products/tools/parsers';
import { populateFormData, editProductIsLoaded, setEditProductError, setIsPublished } from './action-creators';
import { transformErrorFromApi } from 'redux/tools/error-handling';
import { EditProductError } from './types';
import { CompanyLanguages } from 'units/app/redux/types';
import { NotificationStatusType } from 'shared/components/ui';

export const saveEditProductThunk = (callback: (type:NotificationStatusType, text:string) => void): AsyncAppThunk => async (
  dispatch,
  getState,
  ) => {
  const { editProduct: { form } } = getState();
  try {
    const productId = await editProductSaveProduct(form);
    await editProductSavePictures(productId, form.commonFields.pictures);
    callback('success', 'Product was saved');
    history.push(AppUrls.products.index);
  } catch {
    callback('error', AppStrings.errorNotification);
  }
};

export const updateProductThunk = (id:string, callback: (type:NotificationStatusType, text:string) => void): AsyncAppThunk => async (
  dispatch,
  getState,
  ) => {
  const { editProduct: { form, imagesToRemove } } = getState();
  dispatch(setEditProductError(null));
  try {
    await editProductUpdateProduct(form, id);

    for (const imageId of imagesToRemove) {
      editProductDeleteImageById(imageId, id);
    }

    const { pictures } = form.commonFields;
    // ???
    const newImages = pictures.filter(image => 'name' in image);
    
    // ???
    if(newImages && newImages.length) {
      await editProductSavePictures(id, pictures);
    }

    callback('success', 'Product was updated');
    history.push(AppUrls.products.index);
  } catch(e) {
    dispatch(setEditProductError(transformErrorFromApi<EditProductError>(e)));
    callback('error', AppStrings.errorNotification);
  }
};

export const fetchEditProductThunk = (
  productId: string, onSuccess?: (languageKeys: Array<CompanyLanguages>) => void
  ): AsyncAppThunk => async (dispatch) => {
  try {
    const { data } = await editProductFetchProduct(productId);

    const form = prepareEditProductForm(data);
    dispatch(populateFormData(form));
    dispatch(editProductIsLoaded());
    dispatch(setIsPublished(data.published));
    onSuccess && onSuccess(Object.keys(form.languages) as Array<CompanyLanguages>); 
  } catch (error) {}
};
