import React, { FC, useMemo } from 'react';
import { useFormikContext } from 'formik';

import { AuthSC } from 'units/common/styles/auth/auth.styles';
import { ErrorValue } from 'redux/types';
import { CreatePasswordInput } from 'units/user/components/create-password-input.component/create-password-input.component';
import { useIsSubmitEnabled } from 'units/common/hooks/useIsSubmitEnabled';
import { FormikUserInput } from 'units/common/components/formik/input/user-input.component';
import { useFormikApiErrors } from 'units/common/components/formik/hooks/useFormikApiErrors';

interface Props {
  userError?: ErrorValue,
}

interface NewPasswordFields {
  email?: string;
  password: string;
  repeatPassword: string;
}

export const EnterNewPassword: FC<Props> = ({ userError }) => {
  const { errors, touched, values } = useFormikContext<NewPasswordFields>();

  const apiErrors = useFormikApiErrors(userError);

  // TODO: change to useFieldErrors but keep logic.
  const fieldError = useMemo(() => ({
    password: apiErrors?.password?.[0],
    repeatPassword: touched.password ? errors.repeatPassword : undefined,
  }), [errors, touched, apiErrors]);

  const isSubmitEnabled = useIsSubmitEnabled(errors, values, { notRequired: ['email']});
  
  return (
    <AuthSC.AuthPageContainer>
      <AuthSC.AuthPageTitle>Enter new password</AuthSC.AuthPageTitle>
      <AuthSC.AuthPageForm>
        <AuthSC.AuthPageInputList>
          <CreatePasswordInput
            name="password"
            label="New password"
            placeholder="Enter new password"
            type="password"
            error={fieldError.password}
          />
          <FormikUserInput
            name="repeatPassword"
            label="Repeat new password"
            placeholder="Enter new password once again"
            type="password"
            error={fieldError.repeatPassword}
          />
        </AuthSC.AuthPageInputList>
        <AuthSC.AuthPagePrimaryButton type="submit" disabled={!isSubmitEnabled}>
          Confirm
        </AuthSC.AuthPagePrimaryButton>
      </AuthSC.AuthPageForm>
      <AuthSC.AuthPageAdditionalLink to="/sign-in">Back to Login</AuthSC.AuthPageAdditionalLink>
    </AuthSC.AuthPageContainer>
  );
}
