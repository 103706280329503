import React, { useCallback, FC } from 'react';
import { useFormikContext, FormikHelpers } from 'formik';

import { Accordion } from 'units//common/components/accordion/accordion.component';
import { CMSHomePageFormikCustomizationSettings } from 'units//cms/store/types/customization/homepage';
import { CMSHomepageHeroAccordionContainer } from '../hero/hero-accordion.container';
import { CMSCustomizationLanguageProgressContainerProps } from '../../../progress-container/progress-container';
import { SectionProgress } from 'units//cms/helpers/prepare-customization-progress';
import { CMSCustomizationHomepageSectionName } from 'units//cms/types';
import { CMSHomepageUSPAccordionContainer } from '../usp/usp-accordion.container';
import { CMSHomepageTextBlockAccordionContainer } from '../text-block/text-block-accordion.container';
import { CMSHomepageProductCardAccordionContainer } from '../product-card/product-card-accordion.container';
import { CMSHomepageNewsletterAccordionContainer } from '../newsletter/newsletter-accordion.container';
import { CMSHomepageFooterAccordionContainer } from '../footer/footer-accordion.container';
import { useTranslation } from 'react-i18next';

type SectionCommonProps = Pick<
    CMSCustomizationLanguageProgressContainerProps,
    'selectedLanguage' | 'onLanguageChange'
>;

type Props = SectionCommonProps & {
    sectionProgress: SectionProgress<CMSCustomizationHomepageSectionName, any>;
    activeSections: Array<string>;
    handleAccordionExpandedChange: (
        expanded: Array<string>,
        languageSettings: CMSHomePageFormikCustomizationSettings,
        { resetForm }: Pick<FormikHelpers<CMSHomePageFormikCustomizationSettings>, 'resetForm'>,
    ) => void;
};

export const CMSCustomizationHomepageAccordions: FC<Props> = ({
    handleAccordionExpandedChange,
    sectionProgress,
    activeSections,
    ...sectionCommonProps
}) => {
    const { t } = useTranslation();
    const { values, resetForm } = useFormikContext<CMSHomePageFormikCustomizationSettings>();

    const accordionOnChange = useCallback(
        (expanded: Array<string>) => {
            handleAccordionExpandedChange(expanded, values, { resetForm });
        },
        [handleAccordionExpandedChange, values, resetForm],
    );

    return (
        <Accordion
            onChange={accordionOnChange}
            items={[
                {
                    header: t('customization_accordion_hero'),
                    uuid: 'hero',
                    content: (
                        <CMSHomepageHeroAccordionContainer
                            initialProgress={sectionProgress.hero}
                            {...sectionCommonProps}
                        />
                    ),
                },
                {
                    header: t('customization_accordion_usp'),
                    uuid: 'usp',
                    content: (
                        <CMSHomepageUSPAccordionContainer
                            initialProgress={sectionProgress.usp}
                            {...sectionCommonProps}
                        />
                    ),
                },
                {
                    header: t('customization_accordion_text_block'),
                    uuid: 'textBlock',
                    content: (
                        <CMSHomepageTextBlockAccordionContainer
                            initialProgress={sectionProgress.textBlock}
                            {...sectionCommonProps}
                        />
                    ),
                },
                {
                    header: t('customization_accordion_product_cards'),
                    uuid: 'productCards',
                    content: (
                        <CMSHomepageProductCardAccordionContainer
                            initialProgress={sectionProgress.productCards}
                            {...sectionCommonProps}
                        />
                    ),
                },
                {
                    header: t('customization_accordion_newsletter'),
                    uuid: 'newsletterOption',
                    content: (
                        <CMSHomepageNewsletterAccordionContainer
                            initialProgress={sectionProgress.newsletterOption}
                            {...sectionCommonProps}
                        />
                    ),
                },
                {
                    header: t('customization_accordion_footer'),
                    uuid: 'footer',
                    content: (
                        <CMSHomepageFooterAccordionContainer
                            initialProgress={sectionProgress.footer}
                            withDevider={false}
                            {...sectionCommonProps}
                        />
                    ),
                },
            ]}
            expanded={activeSections}
            preExpanded={['hero']}
        />
    );
};
