import React, { FC } from 'react';

import { Styled } from './requirements-panel.styles';
import { IconSC, IconUnicodes } from 'components/styles/icons.styles';
import { Color } from 'components/styles/constants.styles';

export type CreatePasswordInputRequirement = {
  id: string,
  title: string,
  isCorrect: boolean,
}

interface Props {
  requirements: Array<CreatePasswordInputRequirement>;
}

export const CreatePasswordInputRequirementsPanel: FC<Props> = ({ requirements }) => (
  <Styled.Container>
    <Styled.Title>Requirements to the password:</Styled.Title>
    <Styled.RequirementList>
      {requirements.map(r => (
        <Styled.Requirement key={r.id}>
          {r.isCorrect ? (
            <IconSC name={IconUnicodes.check_circle_filled} color={Color.lightish_blue} size={24} />
          ) : (
            <IconSC name={IconUnicodes.minus_circle_filled} color={Color.watermelon} size={24} />
          )}
          <Styled.RequirementName isCorrect={r.isCorrect}>{r.title}</Styled.RequirementName>
        </Styled.Requirement>
      ))}
    </Styled.RequirementList>
  </Styled.Container>
);
