import React, { FC, ButtonHTMLAttributes, ReactNode } from 'react';

import { TransparentButtonSC } from './transparent.styles';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  children?: string | ReactNode;
}

export const TransparentButton: FC<Props> = ({ children, ...props }) => (
  <TransparentButtonSC {...props}>
    {children}
  </TransparentButtonSC>
);
