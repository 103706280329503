import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useDeviceDetector } from 'units/landing-page/hooks';

import { useStateToggle } from 'units/common/hooks/useStateToggle';
import { getImage } from 'components/styles/images';

import { LPButton, LPTypography } from '../../ui';

import { controlList } from './control-description.mocks';
import { LPControlDescriptionSC } from './control-description.styles';
import { LandingPageSC } from 'units/landing-page/index.styles';

export const LPControlDescription: FC = () => {
  const { t } = useTranslation();
  const { device } = useDeviceDetector();
  const [showAll, { toggle }] = useStateToggle(false);

  return (
    <LPControlDescriptionSC.Root>
      <LandingPageSC.SectionSeparator />
      <LPTypography.H2>
        {t('lp_control_title')}
      </LPTypography.H2>
      <LPTypography.Text styles={LPControlDescriptionSC.TextCss}>
        {t('lp_control_text')}
      </LPTypography.Text>
      <LPControlDescriptionSC.CardList>
        {controlList.slice(0, (showAll || device !== 'mobile' ? controlList.length : 3)).map((item, index) => (
          <LPControlDescriptionSC.CardContainer key={`LPControlDescription-card-${index}`}>
            <LPControlDescriptionSC.CardBody>
              <LPControlDescriptionSC.CardLogo>
                {getImage(item.logo)}
              </LPControlDescriptionSC.CardLogo>
              <LPTypography.H3>
                {t(item.title)}
              </LPTypography.H3>
              <LPTypography.Text styles={LPControlDescriptionSC.CardTextCss}>
                {t(item.description)}
              </LPTypography.Text>
            </LPControlDescriptionSC.CardBody>
          </LPControlDescriptionSC.CardContainer>
        ))}
      </LPControlDescriptionSC.CardList>
      {device === 'mobile' && (
        <LPButton.Outline 
          styles={LPControlDescriptionSC.MoreBtnCss}
          onClick={toggle}>
            {showAll ? t('lp_control_less_btn_text') : t('lp_control_more_btn_text')}
        </LPButton.Outline>
      )}
    </LPControlDescriptionSC.Root>
  )
}
