import React from 'react';
import { Redirect, Switch } from 'react-router-dom';

import { UserRoutes } from 'units/user/routes';
import { PrivateRoute } from 'app/navigation/PrivateRoute';
import { ImmoUrls } from 'shared/themes/immo/urls';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/root-types';

export const NotAuthorizedRouter = () => {
    const isAuthenticated = !!useSelector<RootState, string>(state => state.user.token);

    return (
        <Switch>
            <Redirect exact from="/" to={ImmoUrls.user.login} />
            {UserRoutes.map(r => (
                <PrivateRoute
                    key={`route-${r.path}`}
                    exact={r.exact}
                    path={r.path}
                    component={r.component}
                    isAllowed={!isAuthenticated}
                    redirectTo={ImmoUrls.overview}
                />
            ))}
        </Switch>
    );
};
