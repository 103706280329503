import styled from 'styled-components';

import { Color, sizes } from 'components/styles/constants.styles';
import { FormInputLabelText, UserDropdownText, TextStyle27 } from 'components/styles/text.styles';

interface RadioButtonProps {
  withBackground?: boolean;
  disabled?: boolean;
}

const GroupContainer = styled.div<{ margin?: string }>`
  margin: 0 ${sizes.grid_margin} ${sizes.md_padding};
  ${({ margin }) => margin && `
    margin: ${margin};
  `}

  & > legend {
    ${FormInputLabelText};
    margin-bottom: 18px;
  }
  & > div {
    display: flex;
  }
`;

const Checkmark = styled.span<RadioButtonProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${Color.white};

  ${({ withBackground }) => withBackground && `
    background-color: ${Color.pale_grey};
  `}

  &::after {
    content: "";
    display: none;
    position: absolute;
    top: 4px;
    left: 4px;
    width: 8px;
    height: 8px;
    border-radius: 50%;

    background: ${Color.lightish_blue};
  }
`;

const RadioButtonContainer = styled('label')<RadioButtonProps>`
  ${UserDropdownText}
  display: block;
  position: relative;
  padding: 0 ${sizes.md_padding};
  user-select: none;
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer' };

  input {
    position: absolute;
    cursor: inherit;
    height: 100%;
    width: 100%;
    opacity: 0;
  }

  label:hover {
    cursor: inherit;
  }
  
  input:checked ~ ${Checkmark}:after {
    display: block;
  }

  input:checked ~ label {
    ${TextStyle27};
    color: ${Color.black};
  }

  label {
    ${({ disabled }) => disabled && `
      color: ${Color.disabled_text_grey} !important;
    `}
  }
`;


export const RadioSC = {
  GroupContainer,
  RadioButtonContainer,
  Checkmark,
};
