import React, { FC } from 'react';

import { CmsAcordionSection } from 'units/cms/components/cms-acordion-section/cmsAcordionSection.component';
import { FormikFormInput } from 'units/common/components/formik/input/form-input.component';
import { TrackProgressField } from 'units/common/hooks/useTrackProgress';
import { FormikFormTextArea } from 'units/common/components/formik/textarea/form-textarea.comonent';
import { CMSRootSC } from 'units/cms/styles';

type Props = {
    updateProgress: (field: TrackProgressField) => void;
};

export const CMSShopModalsLogin1Accordion: FC<Props> = ({ updateProgress }) => (
    <CmsAcordionSection sectionMargin={32} withDevider={false}>
        <CMSRootSC.SectionTitle>
            This form will show up when your user tries to log in.
        </CMSRootSC.SectionTitle>
        <FormikFormInput
            name="login1.title"
            label="Title"
            placeholder="Login"
            paddingBottom="0"
            afterChange={({ target }) => {
                updateProgress({ name: 'title', isFilled: !!target.value });
            }}
        />
        <FormikFormTextArea
            subLabel="Optional"
            name="login1.subtitle"
            label="Subtitle"
            placeholder="Enter a subtitle text if needed"
        />
        <FormikFormInput
            name="login1.button"
            label="Login button text"
            placeholder="Login"
            paddingBottom="0"
            afterChange={({ target }) => {
                updateProgress({ name: 'button', isFilled: !!target.value });
            }}
        />
    </CmsAcordionSection>
);
