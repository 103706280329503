import styled from 'styled-components';

import { TextStyle15 } from 'components/styles/text.styles';
import { Color } from 'components/styles/constants.styles';

const Text = styled.div`
    ${TextStyle15};
    text-align: center;
`;

const ButtonsWrapper = styled.div`
    justify-content: center;
    display: flex;

    button {
        margin: 0 13px;
    }
`;

const RowContainer = styled.div`
    margin-top: 36px;
    margin-bottom: 36px;
    display: flex;
    align-items: center;
    padding-left: 40px;
    padding-right: 40px;

    input {
        background-color: ${Color.pale_grey};
    }
`;

const AdditionalBlock = styled.div`
    color: ${Color.charcoal_grey};
`;

export const RefundModalSC = {
    Text,
    ButtonsWrapper,
    AdditionalBlock,
    RowContainer
}
