import styled from 'styled-components';

import { Color, sizes } from 'components/styles/constants.styles';
import { TextStyle11, TextStyle3 } from 'components/styles/text.styles';
import { MediaQueries } from 'units/landing-page/config';
interface RequirementProps {
  isCorrect: boolean;
}

const Container = styled.div`
  position: absolute;
  left: 95%;
  top: 80%;
  padding: 12px 16px;
  width: 254px;
  z-index: 1;

  border-radius: ${sizes.border_radius};
  background-color: ${Color.white};

  @media (max-width: ${MediaQueries.tablet}) {
    transform: translateX(-50%)
  }
  @media (max-width: ${MediaQueries.mobile}) {
    transform: translateX(-95%)
  }
`;

const Title = styled.p`
  margin: 0;
  margin-bottom: 16px;

  ${TextStyle11}
  text-align: center;
`;

const RequirementList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const Requirement = styled.li`
  display: flex;
  align-items: center;
  /* padding-left: 32px; */
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const RequirementName = styled.span<RequirementProps>`
  margin-left: 8px;

  ${TextStyle3}
  color: ${({ isCorrect }: RequirementProps) => (isCorrect ? Color.black : Color.watermelon)};
  font-size: 14px;
`;

export const Styled = {
  Container,
  Title,
  RequirementList,
  Requirement,
  RequirementName,
};
