import React, { useEffect, useState } from 'react';

import { api } from 'api';

import { Icon } from 'shared/components/ui';
import { FileUpload } from 'shared/themes/immo/ui';

import { CMSUploadHeroImagesSC } from './upload-hero-images.styles';

type ImageItemType = {
    id: string;
    key: string;
    image: string;
};

export const UploadHeroImages = () => {
    const [images, setImages] = useState<(File | ImageItemType)[]>([]);
    const [removeIds, setRemoveIds] = useState<string[]>([]);

    const onSelect = (data: any) => {
        setImages([...images, ...data]);
    };

    const save = () => {
        const toLoad: File[] = [];

        images.forEach((item: ImageItemType | File) => {
            if (item instanceof File) toLoad.push(item);
        });

        if (toLoad.length) api.customizationService.uploadAuctionImages(toLoad);
        if (removeIds.length) api.customizationService.removeAuctionImages(removeIds);
    };

    useEffect(() => {
        api.customizationService.getAuctionImages().then(({ data }) => setImages(data));
    }, []);

    useEffect(() => {
        const submit = document.getElementById('submit-button');
        submit?.addEventListener('click', save);
        return () => {
            submit?.removeEventListener('click', save);
        };
    }, [save]);

    const onRemove = (index: number) => {
        if (images && images.length) {
            setImages(images.filter((_, i) => index !== i));

            if ((images[index] as ImageItemType)?.id) {
                setRemoveIds([...removeIds, ...[(images[index] as ImageItemType).id]]);
            }
        }
    };

    return (
        <>
            <FileUpload onSelect={onSelect} accept=".png, .jpg, .jpeg">
                <CMSUploadHeroImagesSC.UploadLabel>Select images</CMSUploadHeroImagesSC.UploadLabel>
            </FileUpload>
            <CMSUploadHeroImagesSC.List>
                {images.map((image, index) => (
                    <ImageItem
                        key={image instanceof File ? image.name : image.id}
                        data={image}
                        onRemove={onRemove.bind(null, index)}
                    />
                ))}
            </CMSUploadHeroImagesSC.List>
        </>
    );
};

type ImageItemProps = {
    data: File | ImageItemType;
    onRemove: () => void;
};

const ImageItem: React.FC<ImageItemProps> = ({ data, onRemove }) => (
    <CMSUploadHeroImagesSC.Item>
        <CMSUploadHeroImagesSC.Image
            src={data instanceof File ? URL.createObjectURL(data) : data.image}
            alt={data instanceof File ? data.name : 'image'}
        />
        <CMSUploadHeroImagesSC.Remove onClick={onRemove}>
            <Icon name="close" />
        </CMSUploadHeroImagesSC.Remove>
    </CMSUploadHeroImagesSC.Item>
);
