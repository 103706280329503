import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { LPButton, LPLanguageSwitcher } from '../../ui';

import { usePageScroll } from 'helpers/hooks';
import { useIsLandingPage } from 'units/landing-page/hooks';

import { Navigation } from 'units/landing-page/mocks';
import { LPMobileMenuSC } from './mobile-menu.styles';

type LPMobileMenuProps = {
    isOpen:boolean;
    closeMenu: () => void;
}

export const LPMobileMenu:React.FC<LPMobileMenuProps> = ({ isOpen, closeMenu }) => {
    const { t } = useTranslation();
    const { scrollToSection } = usePageScroll();
    const { takeMeTo } = useIsLandingPage();

    const {location: { pathname }} = useHistory();
    const isLandingPage = pathname === '/landing';

    const onLinkClick = (section:string) => {
        closeMenu();
        setTimeout(scrollToSection.bind(null, section), 300);

        if(!isLandingPage) {
            takeMeTo(section, scrollToSection);
        }
    }

    return (
        <LPMobileMenuSC.Root isOpen={isOpen} length={Navigation.header.length}>
            <LPMobileMenuSC.Wrapper>
                <LPMobileMenuSC.Navigation>
                    <ul>
                        {Navigation.header.map((link, index) => (
                            link.section && <LPMobileMenuSC.Link
                                key={`mobile-menu-link-${index}`}
                                onClick={() => link.section && onLinkClick(link.section)}>
                                    {t(link.translation)}
                            </LPMobileMenuSC.Link>
                        ))}
                    </ul>
                </LPMobileMenuSC.Navigation>
                <LPButton.Outline
                    onClick={() => window.open('https://ecatch.tawk.help', '_blank')}
                    styles={LPMobileMenuSC.HelpBtnCss}>
                        {t('lp_mobile_menu_button')}
                </LPButton.Outline>
                <LPMobileMenuSC.LangWrapper>
                    <LPLanguageSwitcher />
                </LPMobileMenuSC.LangWrapper>
            </LPMobileMenuSC.Wrapper>
        </LPMobileMenuSC.Root>
    )
}
