import styled from 'styled-components';

import { sizes, Color } from '../../../../../components/styles/constants.styles';

const Root = styled.aside`
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-shrink: 0;
  width: ${sizes.sidebar_width};
  margin: 8px 0 8px 8px;
  border-radius: ${sizes.border_radius};
  
  background: ${Color.white};
`;

const Logo = styled.div`
  margin-left: ${sizes.lg_margin};
  margin-top: ${sizes.md_margin};
`;

const SupportWrapper = styled.div`
  margin: 0 ${sizes.xs_margin};
`;

export const SidebarSC = {
  Root,
  Logo,
  SupportWrapper,
}
