import styled from 'styled-components';

import { sizes } from 'components/styles/constants.styles';
import { PrimaryButtonStyle } from 'components/styles/button.styles';
import { TextStyle15, TextStyle13 } from 'components/styles/text.styles';

const LanguageForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const PageInfo = styled.div`
  ${TextStyle15};
  max-width: 935px;
`;

const InputRequirement = styled.div`
  ${TextStyle13}
  margin-top: 24px;
`;

const SaveButton = styled.button`
  width: 135px;
  margin: 16px ${sizes.grid_margin} 0 auto;

  ${PrimaryButtonStyle};
  text-transform: none;
  line-height: 16px;
`;

export const LanguageSC = {
    PageInfo,
    LanguageForm,
    InputRequirement,
    SaveButton,
};
