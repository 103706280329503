import React, { memo, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import {
    cmsCustomizationThemeSelector,
    cmsCustomizationThemeStylesSelector,
    cmsSelectedLanguageSelector,
    cmsSelectedModalSelector,
    cmsSelectedPageSelector,
    cmsSelectedThemeSelector,
    cmsThemeStylesSelector,
} from 'units/cms/store/reducer';

import { CMSCustomizationPage, CMSThemeId } from 'units/cms/store/types';
import {
    CMSCustomizationShopModalsSectionName,
    PreviewMessage,
    ThemeTemplates,
} from 'units/cms/types';

const getPage = (page: CMSCustomizationPage) => {
    if (page === CMSCustomizationPage.MissedAuctions) {
        return '/missed-auctions';
    }

    if (page === CMSCustomizationPage.ProductDetail) {
        return '/auction/test';
    }

    return '/';
};

const prepareUrl = (page: CMSCustomizationPage, modal: CMSCustomizationShopModalsSectionName) => {
    let url = getPage(page);

    if (page === CMSCustomizationPage.ShopModals) {
        if (modal === 'login1') url += '#login';
        if (modal === 'login2') url += '#bid-login';
        if (modal === 'register') url += '#registration';
        if (modal === 'resetPassword') url += '#reset-password';
        if (modal === 'offerDenial') url += '#bid-losing';
        if (modal === 'offerWon') url += '#bid-winning';
        if (modal === 'offerConfirmation') url += '#confirm-bid';
    }

    return url;
};

const prepareTemplate = (theme: CMSThemeId): ThemeTemplates => {
    switch (theme) {
        case CMSThemeId.Traditional:
            return 'traditional';
        case CMSThemeId.Funky:
            return 'funky';
        case CMSThemeId.Luxury:
            return 'classic';
        case CMSThemeId.Minimal:
            return 'minimal';
        case CMSThemeId.Modern:
            return 'modern';
        case CMSThemeId.Estate:
            return 'estate';
        default:
            return 'traditional';
    }
};

export const CMSThemesOverview = memo(() => {
    const iframe = useRef<HTMLIFrameElement>(null);
    const [isLoaded, setIsLoading] = useState(false);

    const page = useSelector(cmsSelectedPageSelector);
    const theme = useSelector(cmsSelectedThemeSelector);
    const styles = useSelector(cmsThemeStylesSelector);
    const customization = useSelector(cmsCustomizationThemeSelector);
    const modal = useSelector(cmsSelectedModalSelector);
    const language = useSelector(cmsSelectedLanguageSelector);
    const customizationStyles = useSelector(cmsCustomizationThemeStylesSelector);

    useEffect(() => {
        if (isLoaded) {
            const data: PreviewMessage = {
                event: 'change-page',
                value: prepareUrl(page, modal),
            };

            iframe.current?.contentWindow?.postMessage(data, '*');
        }
    }, [page, modal, isLoaded]);

    useEffect(() => {
        if (isLoaded) {
            const data: PreviewMessage = {
                event: 'change-theme',
                value: { ...styles, ...{ template: prepareTemplate(theme) } },
            };

            iframe.current?.contentWindow?.postMessage(data, '*');
        }
    }, [theme, styles, isLoaded, customizationStyles]);

    useEffect(() => {
        if (isLoaded) {
            const data: PreviewMessage = {
                event: 'change-customization',
                value: customization,
            };

            iframe.current?.contentWindow?.postMessage(data, '*');
        }
    }, [customization, isLoaded]);

    useEffect(() => {
        if (isLoaded) {
            const data: PreviewMessage = {
                event: 'change-language',
                value: language,
            };

            iframe.current?.contentWindow?.postMessage(data, '*');
        }
    }, [language, isLoaded]);

    return (
        <iframe
            ref={iframe}
            name="preview"
            title="preview"
            onLoad={setIsLoading.bind(null, true)}
            //src="http://localhost:3001"
            src={process.env.REACT_APP_PREVIEW_URL}
            frameBorder={0}
        />
    );
});
