import React, { FC, useCallback } from 'react';

import {
  CMSCustomizationLanguageProgressContainer,
  CMSCustomizationLanguageProgressContainerProps,
  CMSProgressBeforeUpdate,
} from '../../../progress-container/progress-container';
import { CmsAcordionSection } from 'units//cms/components/cms-acordion-section/cmsAcordionSection.component';
import { CMSHomepageFooterAccordion } from './footer-accordion.component';

type Props = Pick<CMSCustomizationLanguageProgressContainerProps, 'selectedLanguage' | 'onLanguageChange' | 'initialProgress'> & {
  withDevider?: boolean;
};

const SocialLinks = ['facebook.isActive', 'instagram.isActive', 'twitter.isActive',
'snapchat.isActive', 'linkedin.isActive', 'tiktok.isActive'];

const FooterLinks = ['footerLinks.[0].name', 'footerLinks.[1].name', 'footerLinks.[2].name', 'footerLinks.[3].name'];

export const CMSHomepageFooterAccordionContainer: FC<Props> = ({ withDevider, ...props }) => {
  const handleBeforeUpdateProgress = useCallback((...[field, { updateProgressFields }]: Parameters<CMSProgressBeforeUpdate>) => {
    if (SocialLinks.includes(field.name)) {
      updateProgressFields([{
        // Take name of social link (instagram.isActive -> instagram)
        key: `${field.name.split('.')[0]}.url`,
        operation: field.isFilled ? 'add' : 'delete',
      }]);

      return false;
    }    

    if (FooterLinks.includes(field.name)) {
      updateProgressFields([{
        // Take footer link id (footerLinks.[0].name -> footerLinks.[0])
        key: `${field.name.split('.').slice(0, 2).join('.')}.url`,
        operation: field.isFilled ? 'add' : 'delete',
      }]);

      return false;
    }

    return true;
  }, []);

  return (
    <CmsAcordionSection lastSection withDevider={withDevider}>
      <CMSCustomizationLanguageProgressContainer
        {...props}
        beforeUpdate={handleBeforeUpdateProgress}
      >
        {({ updateCurrentProgress }) => (
          <CMSHomepageFooterAccordion updateProgress={updateCurrentProgress} />
        )}
      </CMSCustomizationLanguageProgressContainer>
    </CmsAcordionSection>
  )
}
