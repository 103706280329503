import styled from 'styled-components';

const ToggleLabel = styled.div`
  display: flex;
  align-items: center;

  img {
    margin-right: 8px;
  }
`;

export const CMSFooterSocialLinksSC = {
  ToggleLabel,
};
