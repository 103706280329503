import { css } from 'styled-components';

import { Color, sizes } from 'components/styles/constants.styles';

export const CommonInputStyles = css`
  height: 40px;
  background-color: ${Color.white};
  border-radius: ${sizes.border_radius};
  border: none;
  outline-color: ${Color.lightish_blue};

  &::placeholder {
    color: ${Color.cloudy_blue};
  }

  &:disabled {
    background-color: ${Color.disabled_bg_grey};
    color: ${Color.disabled_text_grey};
    cursor: not-allowed;
  }
`;

export const CommonTextAreaStyles = css`
  ${CommonInputStyles}

  height: 150px;
`;
