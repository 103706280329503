import { ApiClient, apiPath } from 'config/api/api';
import {
    RegistrationUser,
    AppUser,
    EmailConfirmation,
    LoginUser,
    ConfirmResetPassword,
    SignOut,
    UpdateUserAccountData,
    FetchUserAccountData,
    FetchUserAccountDataResponse,
    AcceptInvitationDataReq,
    LoginWelcome,
    LoginUserResponse,
} from './types';

const authPath = '/auth';

const registration = async (user: RegistrationUser): Promise<{ data: AppUser }> => {
    const api = ApiClient.getInstance();

    const response = await api.post<AppUser>(`${authPath}/signup/`, {
        email: user.email,
        company: user.companyName,
        password: user.password,
        re_password: user.repeatPassword,
        user_type: user.type,
    });

    return { data: response.data };
};

const confirmEmail = async ({ uid, token }: EmailConfirmation): Promise<void> => {
    const api = ApiClient.getInstance();

    await api.post<void>(`${authPath}/user-activation/`, { uid, token });
};

const resendConfirmEmail = async (email: string): Promise<void> => {
    const api = ApiClient.getInstance();

    await api.post<void>(`${authPath}/user-activation/resend/`, { email });
};

const loginUser = async ({ password, email }: LoginUser) => {
    const api = ApiClient.getInstance();

    const { data } = await api.post<LoginUserResponse>(`${authPath}/signin/`, {
        password,
        email,
    });

    return { data };
};

const signOut = async ({ token, email }: SignOut): Promise<void> => {
    const api = ApiClient.getInstance();

    await api.post<void>(`${authPath}/signout/`, { token, email });
};

const resetPassword = async (email: string): Promise<void> => {
    const api = ApiClient.getInstance();

    await api.post<void>(`${authPath}/password/reset-confirmation/`, { email });
};

const confirmResetPassword = async ({
    uid,
    token,
    password,
    repeatPassword,
}: ConfirmResetPassword): Promise<void> => {
    const api = ApiClient.getInstance();

    await api.post<void>(`${authPath}/password/reset/`, {
        uid,
        token,
        password,
        re_password: repeatPassword,
    });
};

const resetPasswordCheckToken = async (token: string): Promise<void> => {
    const api = ApiClient.getInstance();

    await api.get<void>(`${authPath}/check-expiration/activation-token/${token}`);
};

const fetchUserAccountData = async (): Promise<{ data: FetchUserAccountData }> => {
    const api = ApiClient.getInstance();

    const { data } = await api.get<FetchUserAccountDataResponse>(`${apiPath}/users/me/`);
    return {
        data: {
            firstName: data.first_name,
            lastName: data.last_name,
            company: data.company,
            companyName: data.company.name,
            phone: data.phone,
            id: data.id,
            role: data.role,
            lastLogin: new Date(data.last_login),
            user_type: data.user_type,
        },
    };
};

const updateUserAccountData = async (newAccountData: UpdateUserAccountData): Promise<void> => {
    const api = ApiClient.getInstance();

    await api.put<AppUser>(`${apiPath}/users/me/`, {
        first_name: newAccountData.firstName,
        last_name: newAccountData.lastName,
        phone: newAccountData.phone,
        company: {
            name: newAccountData.companyName,
        },
        email: newAccountData.email,
    });
};

const storeWelcomeFlowData = async (values: LoginWelcome) => {
    const api = ApiClient.getInstance();

    await api.post('/auth/welcome/', {
        first_name: values.firstName,
        last_name: values.lastName,
        required_languages: values.languages,
    });
};

const acceptInvitation = async (values: AcceptInvitationDataReq) => {
    const api = ApiClient.getInstance();

    await api.post<void>(`${apiPath}/users/accept-invitation/`, {
        token: values.token,
        password: values.password,
        re_password: values.repeatPassword,
    });
};

const acceptInvitationCheckToken = async (token: string): Promise<void> => {
    const api = ApiClient.getInstance();

    await api.get<void>(`${authPath}/check-expiration/invitation-token/${token}`);
};

// Debug

export const DEBUG_removeUserByEmail = async (email: string) => {
    const api = ApiClient.getInstance();

    await api.delete<void>(`${apiPath}/debug/delete-user/${email}`);
};

export const userService = {
    registration,
    confirmEmail,
    resendConfirmEmail,
    loginUser,
    resetPassword,
    confirmResetPassword,
    resetPasswordCheckToken,
    signOut,
    fetchUserAccountData,
    updateUserAccountData,
    acceptInvitation,
    acceptInvitationCheckToken,
    storeWelcomeFlowData,
};
