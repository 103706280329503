import { ApiClient, apiPath } from 'config/api/api';
import {
    CompanySettingsResponse,
    CompanySettings,
    ThirdPartyIntegration,
    ThirdPartyIntegrationSource,
} from './types';

const companyPath = `${apiPath}/companies`;

export const appFetchCompanySettings = async () => {
    const api = ApiClient.getInstance();

    const { data } = await api.get<CompanySettingsResponse>(`${companyPath}/company-settings/`);

    return data;
};

export const updateCompanySettings = async (languages: CompanySettings['languages']) => {
    const api = ApiClient.getInstance();

    const { data } = await api.patch<CompanySettingsResponse>(`${companyPath}/company-settings/`, {
        required_languages: languages,
    });

    return data;
};

export const appFetchThirdPartyIntegrations = async () => {
    const api = ApiClient.getInstance();

    const { data } = await api.get<Array<ThirdPartyIntegration>>(`${apiPath}/third_party/list/`);

    return data;
};

export const appCreateThirdPartyIntegration = async (integration: ThirdPartyIntegration) => {
    const api = ApiClient.getInstance();

    const { data } = await api.post<ThirdPartyIntegration>(
        `${apiPath}/third_party/create/`,
        integration,
    );

    return data;
};

export const appUpdateThirdPartyIntegration = async (
    type: ThirdPartyIntegrationSource,
    integration: ThirdPartyIntegration,
) => {
    const api = ApiClient.getInstance();

    const { data } = await api.patch<ThirdPartyIntegration>(
        `${apiPath}/third_party/${type}/`,
        integration,
    );

    return data;
};
