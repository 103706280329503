import React, { FC, useEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { useLocation } from 'react-router-dom';
import { OptionsType } from 'react-select';

import { ProductSelectSC } from './product-select.styles';
import { FormikSelect } from 'units/common/components/formik/select/select.component';
import { ProductItem } from '../product-item/product-item.component';
import { IOption } from 'units/common/components/select/select.component';
import { AuctionValues } from '../types';
import { Product } from 'units/products/api/types';
import { useWorkingMode } from '../hooks';
import { getAuctionStatuses } from '../helpers';

interface Props {
    products: Array<Product>;
    options: OptionsType<IOption>;
    onChange: () => void;
}

export const ProductSelect: FC<Props> = ({ products, options, onChange }) => {
    const { values, setFieldValue, setFieldTouched } = useFormikContext<AuctionValues>();
    const selectedProduct = useMemo(() => products.find(item => item.id === values.productId), [
        values.productId,
        products,
    ]);
    const { isActive } = getAuctionStatuses(values);
    const { isViewMode } = useWorkingMode(useLocation());

    const onDelete = async () => {
        await setFieldValue('productId', '');
        await setFieldTouched('productId', true);
    };

    useEffect(() => {
        setFieldValue('uvp', selectedProduct?.uvp || '');
    }, [selectedProduct]);

    return (
        <>
            {!isViewMode && (
                <ProductSelectSC.Wrapper>
                    <FormikSelect
                        name="productId"
                        options={options}
                        label="Product name"
                        placeholder="Choose a product from a product list"
                        isDisabled={isActive}
                        onChange={onChange}
                    />
                </ProductSelectSC.Wrapper>
            )}

            <ProductItem product={selectedProduct} onDelete={onDelete} />
        </>
    );
};
