import React from 'react';
import { useTranslation } from 'react-i18next';

import { useStateToggle } from 'units/common/hooks/useStateToggle';

import { useDeviceDetector } from 'units/landing-page/hooks';

import { LPLanguageSwitcherSC } from './language-switcher.styles';

const fullLangname: { [key in string]: string } = {
    en: 'English',
    de: 'Deutsch',
    it: 'Italiana',
    fr: 'Français',
};

export const LPLanguageSwitcher = () => {
    const { device } = useDeviceDetector();
    const { i18n } = useTranslation();
    const [isOpen, { turnIn, turnOff, toggle }] = useStateToggle(false);

    if (!i18n.languages) {
        return null;
    }

    const onSelectLang = (e: React.MouseEvent<HTMLLIElement, MouseEvent>, lang: string) => {
        i18n.changeLanguage(lang);
        localStorage.setItem('lp_language', lang);
        turnOff();
        e.stopPropagation();
    };

    const open = () => {
        turnIn();
    };

    return (
        <LPLanguageSwitcherSC.Root
            {...(device !== 'desktop' ? { onClick: toggle } : { onMouseEnter: open })}
            isOpen={isOpen}
            onMouseLeave={turnOff}
        >
            <LPLanguageSwitcherSC.Selected>
                {device === 'mobile' ? fullLangname[i18n.language] : i18n.language}
                <LPLanguageSwitcherSC.Icon />
            </LPLanguageSwitcherSC.Selected>
            <LPLanguageSwitcherSC.Dropdown>
                {i18n.languages.map(
                    lang =>
                        lang !== i18n.language && (
                            <li key={lang} onClick={e => onSelectLang(e, lang)}>
                                {device === 'mobile' ? fullLangname[lang] : lang}
                            </li>
                        ),
                )}
            </LPLanguageSwitcherSC.Dropdown>
        </LPLanguageSwitcherSC.Root>
    );
};
