import React, { FC } from 'react';

import { BaseFormInputLayout, IBaseFormInputLayout } from './base-form-input-layout.component';
import { FormInputSC } from './form-input.styles';

export interface IBaseFormTextArea extends IBaseFormInputLayout, React.InputHTMLAttributes<HTMLTextAreaElement> {
  postfix?: string;
  withBackground?: boolean;
  paddingBottom?: string;
}

export const BaseFormTextArea: FC<IBaseFormTextArea> = React.memo(({
  // BaseFormTextArea props
  postfix,
  withBackground,
  // BaseFormInputLayout props
  label,
  subLabel,
  error,
  withMargins,
  widthPercents,
  paddingBottom,
  disabled,
  // textarea props
  ...textAreaProps
}) => (
  <BaseFormInputLayout
    label={label}
    subLabel={subLabel}
    error={error}
    withMargins={withMargins}
    widthPercents={widthPercents}
    paddingBottom={paddingBottom}
    disabled={disabled}
  >
    <FormInputSC.BaseTextArea
      {...textAreaProps}
      withBackground={withBackground}
      disabled={disabled}
    />
    { postfix && <FormInputSC.InputPostfix>{ postfix }</FormInputSC.InputPostfix> }
  </BaseFormInputLayout>
));
