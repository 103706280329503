import React, { InputHTMLAttributes } from 'react';
import clsx from 'clsx';

import styles from './CheckboxToggle.module.scss';

type ImmoCheckboxToggleProps = {
    className?: string;
};

export const ImmoCheckboxToggle: React.FC<
    InputHTMLAttributes<HTMLInputElement> & ImmoCheckboxToggleProps
> = ({ className, children, ...props }) => {
    return (
        <label className={clsx(styles.root, className)}>
            <input className={styles.input} type="checkbox" {...props} />
            <div className={styles.slider} />
            {children}
        </label>
    );
};
