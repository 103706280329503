import update from 'immutability-helper';

import { AppState, AppActions } from './types';
import * as actionTypes from './action-types';
import { RootState } from 'redux/root-types';

const initialState: AppState = {
  isFetching: [],
  isAppInitializedWithAuthorizedUser: false,
  companySettings: {
    languages: [],
  },
  integrations: []
};

export function appReducer(state: AppState = initialState, action: AppActions) {
  switch(action.type) {
    // Is fetching
    case actionTypes.APP_ADD_IS_FETCHING: {
      if (state.isFetching.includes(action.key)) {
        return state;
      }
      return update<AppState>(state, {
        isFetching: {
          $push: [action.key],
        }
      });
    }
    case actionTypes.APP_REMOVE_IS_FETCHING: {
      return update<AppState>(state, {
        isFetching: {
          $set: state.isFetching.filter(f => f !== action.key),
        }
      });
    }
    
    case actionTypes.APP_SET_INITIALIZED_WITH_AUTHORIZED_USER: {
      return update<AppState>(state, {
        isAppInitializedWithAuthorizedUser: { $set: action.status }
      });
    }
    // third party integrations

    case actionTypes.APP_SET_THIRD_PARTY_INTEGRATIONS: {
      return update<AppState>(state, {
        integrations: { $set: action.integrations }
      });
    }   
    case actionTypes.APP_SET_THIRD_PARTY_INTEGRATION: {
      return update<AppState>(state, {
        integrations: { $push: [action.integration] }
      });
    }
    case actionTypes.APP_UPDATE_THIRD_PARTY_INTEGRATION: {
      return { ...state, integrations: state.integrations.map(item => item.source !== action.integration.source ? item : action.integration)  };
    }
    // company settings

    case actionTypes.APP_SET_COMPANY_SETTINGS: {
      return update(state, {
        companySettings: {
          $set: action.settings,
        }
      });
    }

    case actionTypes.APP_SET_COMPANY_LANGUAGES: {
      return update(state, {
        companySettings: {
          languages: {
            $set: action.languages,
          }
        }
      })
    }

    // clearing

    case actionTypes.APP_CLEAR_REDUCER: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

export const appSelectedThirdPartyIntegrationSelector = (state:RootState) => state.app.integrations;