import React, { FC } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { CMSUrls } from './urls';
import { CMSThemesOverview } from './pages/themes/components/overview';
import { CMSThemesSidebar } from './pages/themes/components/sidebar';
import { CMSThemeSettingsSidebar } from './pages/theme-settings/components/sidebar';
import { CMSCustomizationSidebar } from './pages/customization/components/sidebar';
import { NotFoundPage } from '../common/pages/not-found/not-found.component';
import { Sidebar } from '../common/components/layout/sidebar/sidebar.component';

export const CMSOverviewRoutes: FC = () => (
  <Switch>
    <Redirect exact path={CMSUrls.index} to={CMSUrls.themes} />
    <Route exact path={CMSUrls.themes} component={CMSThemesOverview} />
    <Route exact path={CMSUrls.themeSettings} component={CMSThemesOverview} />
    <Route exact path={CMSUrls.customization} component={CMSThemesOverview} />
    <Route path="*" component={NotFoundPage} />
  </Switch>
);

export const CMSSidebarRoutes: FC = () => (
  <Switch>
    <Redirect exact path={CMSUrls.index} to={CMSUrls.themes} />
    <Route exact path={CMSUrls.themes} component={CMSThemesSidebar} />
    <Route exact path={CMSUrls.themeSettings} component={CMSThemeSettingsSidebar} />
    <Route exact path={CMSUrls.customization} component={CMSCustomizationSidebar} />
    <Route path="*" component={Sidebar} />
  </Switch>
);
