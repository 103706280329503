import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Typography } from 'shared/components/ui';
import { useNavigate } from 'shared/hooks';
import { ObjectPreviewItemType } from 'shared/types';
import { ImmoUrls } from '../../urls';

import styles from './ObjectItem.module.scss';

type ObjectItemProps = {
    number: number;
    isInactive: boolean;
    data: ObjectPreviewItemType;
    onDelete: (id: string) => void;
};

export const ObjectItem: React.FC<ObjectItemProps> = ({ data, isInactive, number, onDelete }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <div className={styles.root}>
            <div className={styles.label}>
                {t(`object_label_${data.type.replaceAll(' ', '_').toLowerCase()}`)}
            </div>
            <ItemOptions
                isInactive={isInactive}
                onDelete={onDelete.bind(null, data.id)}
                id={data.id}
            />
            <img src={data.image || ''} alt="" />
            <div className={styles.body}>
                <Typography.Title htmlElement="h3">
                    #{number > 9 ? number : `0${number}`} {data.name}
                </Typography.Title>
                <Typography.Text>{`${data.address}, ${data.postCode} ${data.city}`}</Typography.Text>
                {isInactive && (
                    <Button
                        className={styles.button}
                        type="immo-bordered"
                        onClick={() => navigate(ImmoUrls.objects.update.replace(':id', data.id))}
                    >
                        {t('object_item_button')}
                    </Button>
                )}
            </div>
        </div>
    );
};

type ItemOptionsProps = {
    id: string;
    isInactive: boolean;
    onDelete: () => void;
};

const ItemOptions: React.FC<ItemOptionsProps> = ({ id, isInactive, onDelete }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <div className={styles.options}>
            <div className={styles['options-icon']}>
                <div className={styles.dot} />
                <div className={styles.dot} />
                <div className={styles.dot} />
            </div>
            <div className={styles.dropdown}>
                <ul>
                    <li onClick={() => navigate(ImmoUrls.objects.update.replace(':id', id))}>
                        {t('object_item_edit')}
                    </li>
                    {isInactive && <li onClick={onDelete}>{t('object_item_delete')}</li>}
                </ul>
            </div>
        </div>
    );
};
