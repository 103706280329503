import * as yup from 'yup';

import { UserInputValidationErrors } from 'units/common/constants';

export const billingValidationSchema = yup.object({
    street: yup.string().required(UserInputValidationErrors.billing.street.required),
    street_number: yup.string().required(UserInputValidationErrors.billing.number.required),
    country: yup.string().required(UserInputValidationErrors.billing.country.required),
    city: yup.string().required(UserInputValidationErrors.billing.city.required),
    zip_code: yup
        .string()
        .matches(/^\d+$/, UserInputValidationErrors.billing.zipCode.notValid)
        .max(5, UserInputValidationErrors.billing.zipCode.max)
        .required(UserInputValidationErrors.billing.zipCode.required),
});
