import React from 'react';
import { useTranslation } from 'react-i18next';

import { Container } from '../../components/grid';
import { LPTypography } from '../../components/ui';

import { LPImprintSC } from './imprint.styles';

export const LPImprint = () => {
  const { t } = useTranslation();

  return (
      <Container styles={LPImprintSC.ContainerCSS}>
        <LPTypography.H2 styles={LPImprintSC.TitleCSS}>{t('lp_imprint_title')}</LPTypography.H2>

        <LPImprintSC.BoxContainer>
          <LPImprintSC.Box>
            <LPTypography.H3 styles={LPImprintSC.SubTitleCSS}>{t('lp_imprint_contact_address')}</LPTypography.H3>
            <ul>
              <li>mindnow AG</li>
              <li>Okenstr. 6</li>
              <li>8037 {t('lp_imprint_town')}</li>
              <li>{t('lp_imprint_state')}</li>
              <li><a href="mailto:">hello@mindnow.io</a></li>
            </ul>
          </LPImprintSC.Box>

          <LPImprintSC.Box>
            <LPTypography.H3 styles={LPImprintSC.SubTitleCSS}>{t('lp_imprint_auth_representatives')}</LPTypography.H3>
            <ul>
              <li>Jakob Heval Kaya</li>
              <li>Daniel Grossen</li>
              <li>Lorenz Furrer</li>
              <li>Klaus Abele</li>
            </ul>
          </LPImprintSC.Box>
        </LPImprintSC.BoxContainer>

        <LPImprintSC.BoxContainer>
          <LPImprintSC.Box>
            <LPTypography.H3 styles={LPImprintSC.SubTitleCSS}>{t('lp_imprint_comm_register')}</LPTypography.H3>
            <ul>
              <li>{t('lp_imprint_company_name')}: mindnow AG</li>
              <li>{t('lp_imprint_number')}: CHE-271.622.601</li>
              <li>{t('lp_imprint_office')}</li>
            </ul>
          </LPImprintSC.Box>

          <LPImprintSC.Box>
            <LPTypography.H3 styles={LPImprintSC.SubTitleCSS}>{t('lp_imprint_tax_number_title')}</LPTypography.H3>
            <LPTypography.Text styles={LPImprintSC.ParagraphCSS}>CHE-271.622.601 {t('lp_imprint_tax_number')}</LPTypography.Text>
          </LPImprintSC.Box>
        </LPImprintSC.BoxContainer>

        <LPTypography.H3 styles={LPImprintSC.SubTitleCSS}>{t('lp_imprint_disclaimer')}</LPTypography.H3>

        <LPImprintSC.DisclaimerContainer>
          <LPTypography.Text styles={LPImprintSC.ParagraphCSS}>{t('lp_imprint_disclaimer_first_row')}</LPTypography.Text>
          <LPTypography.Text styles={LPImprintSC.ParagraphCSS}>{t('lp_imprint_disclaimer_second_row')}</LPTypography.Text>
          <LPTypography.Text styles={LPImprintSC.ParagraphCSS}>{t('lp_imprint_disclaimer_third_row')}</LPTypography.Text>
        </LPImprintSC.DisclaimerContainer>

        <LPImprintSC.DefaultContainer>
          <LPTypography.H3 styles={LPImprintSC.SubTitleCSS}>{t('lp_imprint_liability_title')}</LPTypography.H3>
          <LPTypography.Text styles={LPImprintSC.ParagraphCSS}>{t('lp_imprint_liability')}</LPTypography.Text>
        </LPImprintSC.DefaultContainer>

        <LPImprintSC.DefaultContainer>
          <LPTypography.H3 styles={LPImprintSC.SubTitleCSS}>{t('lp_imprint_copyrights_title')}</LPTypography.H3>
          <LPTypography.Text styles={LPImprintSC.ParagraphCSS}>{t('lp_imprint_copyrights')}</LPTypography.Text>
        </LPImprintSC.DefaultContainer>
      </Container>
  );
};
