import styled from 'styled-components';

import { sizes } from 'components/styles/constants.styles';
import { FormSectionText } from 'components/styles/text.styles';

const Form = styled.div<{ padding?: string }>`
  padding: ${({ padding }) => padding || '8px 0 0 0'};
`;

const InputsGrid = styled.div<{ marginTop?: string }>`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -${sizes.grid_margin};
  ${({ marginTop }) => marginTop && `
    margin-top: ${marginTop};
  `}
`;


const SectionTitle = styled.h2`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  margin-top: 0;
  ${FormSectionText}
`;

export const GeneralFormSC = {
  Form,
  InputsGrid,
  SectionTitle,
};
