import styled from 'styled-components';

import { sizes, Color } from 'components/styles/constants.styles';
import { MediaQueries } from 'units/landing-page/config';

const Wrapper = styled.div`
    overflow: hidden;
    position: relative;
    z-index: 0;
    padding-top: 170px;
    margin: -24px -${sizes.lg_padding};
    background-color: ${Color.white};

    @media (max-width: ${MediaQueries.tablet}) {
        padding-top: 105px;
    }

    @media (max-width: ${MediaQueries.mobile}) {
        padding-top: 145px;
    }
`;

export const LPLayout = {
    Wrapper
};
