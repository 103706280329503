import React, { FC } from 'react';

import { BaseFormikWrapper, IBaseFormikWrapper } from '../wrapper/base-formik-wrapper.component';
import { BaseFormInput, IBaseFormInput } from '../../form/fields/form-input/base-form-input.component';

export type FormikFormInputProps = IBaseFormInput & Omit<IBaseFormikWrapper<HTMLInputElement>, 'component'>;

export const FormikFormInput: FC<FormikFormInputProps> = (props) => (
  <BaseFormikWrapper {...props} component={BaseFormInput} />
);

