import React, { FC, useMemo } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { PrivateRoute } from './PrivateRoute';
import { RootState } from 'redux/root-types';
import { Overview } from 'units/overview/pages/overview.component';
import { Analytics } from 'units/analytics/pages/analytics.component';
/* import { SettingsUrls } from 'units/settings/urls'; */
/* import { SettingsRoutes } from 'units/settings/routes'; */
import { FormsPage } from 'pages/forms-page/forms-page.component';
import { UserUrls } from 'units/user/urls';
//import { UserRoutes } from 'units/user/routes';
import { NotFoundPage } from 'units/common/pages/not-found/not-found.component';
import { DevPage } from 'units/dev/dev-page.component';
import { AppUrls } from '../urls';
import { ProductsRoutes } from 'units/products/routes';
import { AuctionRoutes } from 'units/auctions/routes';
import { CMSUrls } from 'units/cms/urls';

import { LandingPage } from 'units/landing-page';

import { UserState } from 'units/user/redux/types';
import { CMSRoot } from 'units/cms';
import { Welcome } from 'units/user/pages/sign-in-welcome/welcome-form/welcome.component';
import { OrderRoutes } from 'units/orders/routes';

// refactored code here
import { AppUrls as NewAppUrls } from 'shared/appUrls';

import { SettingsRouter } from 'shared/routers';
import { UserRoleId } from 'components/types/enums';

// Urls that shared between guest and users. Allow signed-in user to go there.
//const sharedUserUrls = [UserUrls.acceptInvitation];

export const Router: FC = () => {
    const isAuthenticated = !!useSelector<RootState, string>(state => state.user.token);
    const { user } = useSelector<RootState, UserState>(state => state.user);
    const isWelcomeflowCompeted = user?.isWelcomeflowCompeted;

    const privateIsAllowed = useMemo(() => isAuthenticated && isWelcomeflowCompeted, [
        isAuthenticated,
        isWelcomeflowCompeted,
    ]);

    const privateRedirectTo = useMemo(
        () => (isWelcomeflowCompeted ? UserUrls.login : UserUrls.signInWelcome),
        [isWelcomeflowCompeted],
    );

    const notForUser = useMemo(() => !!(user && user.role && user.role.id !== UserRoleId.User), [
        user,
    ]);

    const userRedirectTo = useMemo(() => {
        if (!privateIsAllowed) return privateRedirectTo;
        if (!notForUser) return AppUrls.products.index;
    }, [privateIsAllowed, notForUser, privateRedirectTo]);

    return (
        <Switch>
            <Redirect exact from="/" to={AppUrls.overview} />
            <PrivateRoute
                exact
                path={AppUrls.overview}
                component={Overview}
                isAllowed={privateIsAllowed}
                redirectTo={privateRedirectTo}
            />
            <PrivateRoute
                path={AppUrls.products.index}
                component={ProductsRoutes}
                isAllowed={privateIsAllowed}
                redirectTo={privateRedirectTo}
            />
            <PrivateRoute
                path={AppUrls.auctions.index}
                component={AuctionRoutes}
                isAllowed={privateIsAllowed}
                redirectTo={privateRedirectTo}
            />
            {OrderRoutes.map(r => (
                <PrivateRoute
                    key={`route-${r.path}`}
                    exact={r.exact}
                    path={r.path}
                    component={r.component}
                    isAllowed={notForUser}
                    redirectTo={userRedirectTo}
                />
            ))}
            <PrivateRoute
                exact
                path={AppUrls.analytics}
                component={Analytics}
                isAllowed={privateIsAllowed}
                redirectTo={privateRedirectTo}
            />
            <PrivateRoute
                path={NewAppUrls.settings.index}
                component={SettingsRouter}
                isAllowed={privateIsAllowed}
                redirectTo={privateRedirectTo}
            />
            <PrivateRoute
                path={CMSUrls.index}
                component={CMSRoot}
                isAllowed={privateIsAllowed}
                redirectTo={privateRedirectTo}
            />
            {/* {UserRoutes.map(r => (
                <PrivateRoute
                    key={`route-${r.path}`}
                    exact={r.exact}
                    path={r.path}
                    component={r.component}
                    isAllowed={sharedUserUrls.includes(r.path) ? true : !isAuthenticated}
                    redirectTo={sharedUserUrls.includes(r.path) ? '/' : AppUrls.overview}
                />
            ))} */}
            <PrivateRoute path={AppUrls.landing.landingPage} component={LandingPage} isAllowed />
            <PrivateRoute
                exact
                path={UserUrls.signInWelcome}
                component={Welcome}
                isAllowed={isAuthenticated && !isWelcomeflowCompeted}
                redirectTo={UserUrls.login}
            />
            {/* temporarily */}
            <PrivateRoute exact path="/forms" component={FormsPage} />
            <Route exact path="/dev" component={DevPage} />
            <Route exact path="*" component={NotFoundPage} />
        </Switch>
    );
};
