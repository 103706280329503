import styled, { css } from 'styled-components';

import { Color } from 'components/styles/constants.styles';
import { MediaQueries } from 'units/landing-page/config';

const DescriptionTitleCss = css`
    font-size: 32px;
    line-height: 32px;
    color: ${Color.white};
    text-align: left;

    @media (max-width: ${MediaQueries.tablet}) {
        font-size: 24px;
        line-height: 24px;
    }

    @media (max-width: ${MediaQueries.mobile}) {
        font-size: 20px;
        line-height: 20px;
    }
`;

const DescriptionTextCss = css`
    margin-top: 40px;
    color: ${Color.white};
    text-align: left;
    opacity: 0.5;

    @media (max-width: ${MediaQueries.tablet}) {
        margin-top: 25px;
    }

    @media (max-width: ${MediaQueries.mobile}) {
        margin-top: 16px;
    }
`;

const Root = styled.div`
    padding: 100px 32px 84px;
    color: ${Color.white};
    background-color: ${Color.black};

    @media (max-width: ${MediaQueries.tablet}) {
        padding-bottom: 55px;
        padding-top: 56px;
    }

    @media (max-width: ${MediaQueries.mobile}) {
        padding-bottom: 40px;
        padding-top: 40px;
    }
`;

const Footer = styled.footer`
    max-width: 1170px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
`;

const Content = styled.div`
    display: flex;

    @media (max-width: ${MediaQueries.tablet}) {
        flex-wrap: wrap;
    }
`;

const Description = styled.div`
    width: 33.4%;
    padding-right: 7%;

    @media (max-width: ${MediaQueries.tablet}) {
        width: 45%;
        padding-right: 10%;
    }

    @media (max-width: ${MediaQueries.mobile}) {
        width: 100%;
        padding-right: 0;
        margin-bottom: 36px;
    }
`;

const Navigation = styled.nav<{ isSub?: boolean }>`
    margin-top: 10px;
    width: ${props => (props.isSub ? '19%' : '21%')};

    ul {
        li {
            margin-top: 22px;
            font-weight: 700;

            span {
                cursor: pointer;
            }

            a {
                color: ${Color.white};
                text-decoration: none;
            }

            &:first-child {
                margin-top: 0;
            }

            a,
            span {
                transition: opacity 300ms ease-out;
                &:hover {
                    opacity: 0.4;
                }
            }
        }
    }

    @media (max-width: ${MediaQueries.tablet}) {
        width: 27%;
        ul {
            li {
                font-size: 14px;
                margin-top: 16px;
            }
        }
    }

    @media (max-width: ${MediaQueries.mobile}) {
        width: 50%;

        ul {
            li {
                font-size: 12px;
            }
        }
    }
`;

const Payments = styled.div`
    width: 27%;
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;

    @media (max-width: ${MediaQueries.tablet}) {
        width: 100%;
        margin-top: 22px;
        justify-content: flex-start;
    }

    @media (max-width: ${MediaQueries.mobile}) {
        margin-top: 55px;
    }
`;

const PaymentsWrapper = styled.div`
    width: 237px;
`;

const PaymentsTitle = styled.div`
    font-weight: 700;

    @media (max-width: ${MediaQueries.tablet}) {
        font-size: 14px;
    }

    @media (max-width: ${MediaQueries.mobile}) {
        font-size: 12px;
    }
`;

const PaymentsList = styled.div`
    margin-top: 38px;
    img {
        margin-left: 17px;
        cursor: pointer;
        opacity: 0.5;
        transition: opacity 300ms ease-out;
        &:first-child {
            margin-left: 0;
        }
        &:hover {
            opacity: 1;
        }
    }

    @media (max-width: ${MediaQueries.tablet}) {
        margin-top: 28px;
    }

    @media (max-width: ${MediaQueries.mobile}) {
        margin-top: 19px;
    }
`;

const Bottom = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 92px;

    @media (max-width: ${MediaQueries.tablet}) {
        font-size: 14px;
        margin-top: 78px;
    }

    @media (max-width: ${MediaQueries.mobile}) {
        font-size: 12px;
        flex-direction: column-reverse;
        align-items: flex-start;
        margin-top: 40px;
    }
`;

const MadeBy = styled.div`
    opacity: 0.5;
`;

const Stripe = styled.div`
    display: flex;
    align-items: center;
    span {
        opacity: 0.5;
        padding-right: 16px;
    }

    @media (max-width: ${MediaQueries.mobile}) {
        margin-bottom: 18px;

        span {
            padding-right: 10px;
        }

        img {
            max-width: 48px;
        }
    }
`;

export const LPFooterSC = {
    Root,
    Footer,
    Content,
    Description,
    Navigation,
    Payments,
    PaymentsWrapper,
    PaymentsTitle,
    PaymentsList,
    Bottom,
    MadeBy,
    Stripe,
    DescriptionTitleCss,
    DescriptionTextCss,
};
