import React, { FC, useCallback, useMemo } from 'react';
import { useFormikContext } from 'formik';

import { CmsAcordionSection } from 'units/cms/components/cms-acordion-section/cmsAcordionSection.component';
import { TrackProgressField } from 'units/common/hooks/useTrackProgress';
import { FormikToggleButton } from 'units/common/components/formik/toggle/formik-toggle';
import { CMSRootSC } from 'units/cms/styles';
import { FormikRadioButtonGroup } from 'units/common/components/formik/radio/radio-group.component';
import { CMSUploader } from 'units/cms/components/cms-uploader/cms-uploader.component';
import { useCMSFormikImageUploadHelper } from 'units/cms/hooks/useCMSImageUploadHelper';
import { FormikFormInput } from 'units/common/components/formik/input/form-input.component';
import { FormikFormTextArea } from 'units/common/components/formik/textarea/form-textarea.comonent';
import { CMSHomePageFormikCustomizationSettings } from 'units/cms/store/types/customization/homepage';
import { useTranslation } from 'react-i18next';

type Props = {
    updateProgress: (field: TrackProgressField) => void;
};

const pictureMode = {
    id: 'picture',
    label: 'Picture',
};

const titleMode = {
    id: 'title',
    label: 'Title',
};

export const CMSHomepageTextBlockAccordion: FC<Props> = ({ updateProgress }) => {
    const { t } = useTranslation();
    const {
        values: { textBlock },
    } = useFormikContext<CMSHomePageFormikCustomizationSettings>();
    const [picture, pictureHelper] = useCMSFormikImageUploadHelper('textBlock.picture');

    const isSectionActive = useMemo(() => textBlock.isActive, [textBlock.isActive]);
    const isTextLinkActive = useMemo(() => textBlock.textLink.isActive, [
        textBlock.textLink.isActive,
    ]);

    const handlePictureSave = useCallback(
        (files: Array<File>) => {
            pictureHelper.storeFirstImage(files);
            updateProgress({ name: 'picture', isFilled: true });
        },
        [updateProgress],
    );

    const handlePictureDelete = useCallback(
        (id: string) => {
            pictureHelper.deleteImage(id);
            updateProgress({ name: 'picture', isFilled: false });
        },
        [updateProgress],
    );

    return (
        <>
            <CmsAcordionSection sectionMargin={24} withDevider={false}>
                <CMSRootSC.SectionTitle>
                    {t('customization_text_block_subtitle')}
                </CMSRootSC.SectionTitle>
                <FormikToggleButton
                    name="textBlock.isActive"
                    label={t('active')}
                    afterChange={({ target }) => {
                        updateProgress({ name: 'textBlock.isActive', isFilled: !!target.checked });
                    }}
                />
            </CmsAcordionSection>
            <CmsAcordionSection sectionMargin={24} withDevider={false}>
                <FormikRadioButtonGroup
                    name="textBlock.renderMode"
                    radioList={[pictureMode]}
                    margin="0 0 0 0"
                    disabled={!isSectionActive}
                    afterChange={({ target }) => {
                        updateProgress({ name: 'renderMode.picture', isFilled: !!target.value });
                    }}
                />
                <CMSUploader
                    title={t('customization_text_block_picture_description')}
                    subtitle={t('theme_settings_max_size', { width: 150, height: 150 })}
                    loadingTitle="Picture is loading"
                    saveFiles={handlePictureSave}
                    deleteFile={handlePictureDelete}
                    previewFile={picture || undefined}
                    maxDimension={{
                        height: 150,
                        width: 150,
                    }}
                    dimensionErrorMessage={t('theme_settings_max_size', {
                        width: 150,
                        height: 150,
                    })}
                    error={pictureHelper.error}
                    disabled={!isSectionActive || textBlock.renderMode !== 'picture'}
                />
            </CmsAcordionSection>
            <CmsAcordionSection sectionMargin={0} withDevider={false}>
                <FormikRadioButtonGroup
                    name="textBlock.renderMode"
                    radioList={[titleMode]}
                    margin="0 0 0 0"
                    disabled={!isSectionActive}
                    afterChange={({ target }) => {
                        updateProgress({ name: 'renderMode.title', isFilled: !!target.value });
                    }}
                />
                <FormikFormInput
                    name="textBlock.title"
                    placeholder={t('customization_text_block_title_placeholder')}
                    paddingBottom="0"
                    disabled={!isSectionActive || textBlock.renderMode !== 'title'}
                    afterChange={({ target }) => {
                        updateProgress({ name: 'title', isFilled: !!target.value });
                    }}
                />
            </CmsAcordionSection>
            <CmsAcordionSection sectionMargin={16} withDevider={false}>
                <FormikFormTextArea
                    name="textBlock.description"
                    label={t('customization_text_block_title_description')}
                    placeholder={t('customization_text_block_title_description_placeholder')}
                    height="528px"
                    disabled={!isSectionActive}
                    afterChange={({ target }) => {
                        updateProgress({ name: 'description', isFilled: !!target.value });
                    }}
                />
            </CmsAcordionSection>
            <CmsAcordionSection
                title={t('customization_text_block_text_link_title')}
                titleStyle="black"
                withDevider={false}
            >
                <FormikToggleButton
                    name="textBlock.textLink.isActive"
                    label={t('active')}
                    disabled={!isSectionActive}
                    afterChange={({ target }) => {
                        updateProgress({ name: 'textLink.isActive', isFilled: !!target.checked });
                    }}
                />
                <FormikFormInput
                    name="textBlock.textLink.name"
                    label={t('customization_text_block_text_link_name')}
                    placeholder={t('customization_text_block_text_link_name_placeholder')}
                    paddingBottom="0"
                    disabled={!isSectionActive || !isTextLinkActive}
                    afterChange={({ target }) => {
                        updateProgress({ name: 'textLink.name', isFilled: !!target.value });
                    }}
                />
                <FormikFormInput
                    name="textBlock.textLink.target"
                    label={t('customization_text_block_text_link_target')}
                    placeholder={t('customization_text_block_text_link_target_placeholder')}
                    disabled={!isSectionActive || !isTextLinkActive}
                    afterChange={({ target }) => {
                        updateProgress({ name: 'textLink.target', isFilled: !!target.value });
                    }}
                />
            </CmsAcordionSection>
        </>
    );
};
