import styled from 'styled-components';

import { TextStyle8 } from 'components/styles/text.styles';
import { Color } from 'components/styles/constants.styles';

const dividerColor = 'rgba(193, 203, 222, 0.5)';

const Container = styled.div<{ withDivider?: boolean }>`
  /* margin-bottom: 40px; */
  position: relative;
  padding-right: 32px;

  ${({ withDivider }) => withDivider && `
    margin-bottom: 24px;
    padding-bottom: 8px;
    border-bottom: 1px ${dividerColor} solid;
  `}
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  margin-top: 12px;
  margin-bottom: 24px;

  background-color: ${dividerColor};
`;

const RemoveButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 24px;
  outline: none;
  cursor: pointer;
  width: 1px;
  border-left: 1px ${dividerColor} solid;

  span {
    position: absolute;
    top: 38%;
    left: -8px;
    
    background-color: ${Color.pale_grey};
    padding: 18px 0;
    
    ${TextStyle8}
  }
`;

export const DescriptionFieldSetSC = {
  Container,
  Divider,
  RemoveButton,
};
