import React, { FC, useState, ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';

import { getImage, ImageName } from 'components/styles/images';
import { SecondaryButton } from 'units/common/components/buttons/secondary/secondary.component';
import { BaseFormInput } from 'units/common/components/form/fields/form-input/base-form-input.component';
import { ThirdPartyIntegrationSource } from 'units/app/redux/types';
import { createThirdPartyIntegrationThunk } from 'units/app/redux/thunks';
import { MailchimpSectionSC } from './mailchimp-connect.styles';

export const MailchimpConnect: FC<{ integrated: boolean }> = ({ children, integrated }) => {
    const [addAPIkey, setAddAPIkey] = useState(false);
    const [APIkey, setAPIKey] = useState('');
    const dispatch = useDispatch();

    const onConnect = () => {
        if (addAPIkey) {
            dispatch(createThirdPartyIntegrationThunk({
                source: ThirdPartyIntegrationSource.mailchimp,
                config: { api_key: APIkey },
                active: true
            }));
        } else {
            setAddAPIkey(true);
        }
    };

    const onChange = (event: ChangeEvent<HTMLInputElement>) => setAPIKey(event.currentTarget.value);

    return (!integrated ? (
        <MailchimpSectionSC.Container>
            <div>{getImage(ImageName.mailchimp)}</div>
            {addAPIkey ? (
                <>
                    <MailchimpSectionSC.TextStep2>
                        We  create and update subscribers in Mailchimp when someone fills out the opt-in.
                    </MailchimpSectionSC.TextStep2>
                    <BaseFormInput
                        withBackground
                        placeholder="Enter API key"
                        value={APIkey}
                        onChange={onChange}
                    />
                </>
            ) : (
                <MailchimpSectionSC.TextStep1>
                    In order to activate the newsletter opt in you must connect Mailchimp first.
                </MailchimpSectionSC.TextStep1>
            )}
            <SecondaryButton
                disabled={addAPIkey && !APIkey}
                isWide
                onClick={onConnect}
                type="button"
            >
                Connect
            </SecondaryButton>
        </MailchimpSectionSC.Container>
        ) : <>{children}</>
    );
};

