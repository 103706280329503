import React from 'react';

export enum ImageName {
  cards = 'cards',
  domains = 'domains',
  hand = 'hand',
  paper_plane = 'paper_plane',
  settings = 'settings',
  users = 'users',
  card_in_hand = 'card_in_hand',
  no_users = 'no_users',
  no_products = 'no_products',
  no_auctions = 'no_auctions',
  logo = 'logo',
  lp_customer = 'customer',
  lp_launch = 'launch',
  lp_payment = 'payment',
  lp_control_1 = 'lp_control_1',
  lp_control_2 = 'lp_control_2',
  lp_control_3 = 'lp_control_3',
  lp_control_4 = 'lp_control_4',
  lp_control_5 = 'lp_control_5',
  lp_control_6 = 'lp_control_6',
  lp_control_7 = 'lp_control_7',
  lp_control_8 = 'lp_control_8',
  lp_control_9 = 'lp_control_9',
  check_red = 'check-red',
  stripe = 'stripe',
  visa = 'visa',
  mastercard = 'mc',
  amex = 'amex',
  apple = 'apple',
  android = 'android',
  support = 'support',
  achievement = 'achievement',
  high_five = 'high_five',
  shuttle = 'shuttle',
  success = 'success',
  language = 'language',
  instagram = 'instagram',
  facebook = 'facebook',
  snapchat = 'snapchat',
  twitter = 'twitter',
  tiktok = 'tiktok',
  linkedin = 'linkedin',
  target = 'target',
  tablet = 'tablet',
  mailchimp = 'mailchimp',
  clear = 'clear',
  empty_box = 'empty_box',
  dashboard = 'dashboard',
}

export const getImage = (name: ImageName, key?:string, onClick?: (e:React.MouseEvent<HTMLImageElement, MouseEvent>) => void) => (
  <img alt="logo" src={`/icons/colored/${name}.svg`} key={key} onClick={onClick} />
);
