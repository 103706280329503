import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, Switch, Route } from 'react-router-dom';
import { TransitionGroup, Transition } from 'react-transition-group';

import { AppUrls } from './urls';
import { RootState } from 'redux/root-types';
import { UserState } from 'units/user/redux/types';
import { CMSUrls } from 'units/cms/urls';
import { Sidebar } from 'units/common/components/layout/sidebar/sidebar.component';
import { CMSSidebarRoot } from 'units/cms/components/sidebar-root';
import {
    SidebarSlideAnimationContainer,
    SidebarSlideAnimationWrapper,
} from 'components/animations/sidebar-animation';
import { UserUrls } from 'units/user/urls';

const pagesWithoutSidebar = [
    AppUrls.landing.landingPage,
    AppUrls.landing.imprintPage,
    AppUrls.landing.privacyPolicy,
    AppUrls.landing.termsAndConditions,
    UserUrls.signInWelcome,
];

export const AppSidebar: FC<{ disabled?: boolean }> = ({ disabled }) => {
    const { token } = useSelector<RootState, UserState>(state => state.user);
    const location = useLocation();

    const isCMSSidebar = useMemo(() => location.pathname.includes(CMSUrls.index), [location]);
    const isVisible = useMemo(
        () => !pagesWithoutSidebar.find(i => location.pathname.search(i) >= 0),
        [location.pathname],
    );

    if (!token || !isVisible || disabled) {
        return null;
    }

    return (
        <SidebarSlideAnimationContainer cmsSidebar={isCMSSidebar} duration={450}>
            <TransitionGroup component={null}>
                <Transition timeout={250} key={isCMSSidebar ? 'cms_sidebar' : 'sidebar'}>
                    {state => (
                        <SidebarSlideAnimationWrapper status={state} duration={250}>
                            <Switch location={location}>
                                <Route path={CMSUrls.index} component={CMSSidebarRoot} />
                                <Route path="*" component={Sidebar} />
                            </Switch>
                        </SidebarSlideAnimationWrapper>
                    )}
                </Transition>
            </TransitionGroup>
        </SidebarSlideAnimationContainer>
    );
};
