import React from 'react';
import { Form, Formik, FormikHelpers } from 'formik';

import { PrimaryButton } from 'units/common/components/buttons/primary/primary.component';
import { TransparentButton } from 'units/common/components/buttons/transparent/transparent.component';
import { FormikFormInput } from 'units/common/components/formik/input/form-input.component';
import { Modal } from 'units/common/components/modal/modal.component';

import { RefundOrder } from 'shared/types';

import { RefundModalSC } from './RefundModal.styles';

import { validation } from './validation';

type RefundModalProps = {
    maxValue:number;
    isOpen:boolean;
    onClose: () => void;
    onSubmit: (values:RefundOrder, helpers:FormikHelpers<RefundOrder>) => void;
}

export const RefundModal:React.FC<RefundModalProps> = ({ onClose, maxValue, isOpen, onSubmit }) => (
    <Modal isOpen={isOpen} onCloseModal={onClose} title='You are about to issue a refund'>
        <RefundModalSC.Text>
            Please enter the refund amount.
        </RefundModalSC.Text>
        <Formik
            validationSchema={validation(maxValue)}
            initialValues={{ amount: maxValue.toString() }}
            onSubmit={onSubmit}
        >
            <Form>
                <RefundModalSC.RowContainer>
                    <FormikFormInput
                        label='Amount'
                        name='amount'
                        placeholder='00.00'
                        withMargins
                        withRounding
                        type='money'
                    />
                    <RefundModalSC.AdditionalBlock>CHF</RefundModalSC.AdditionalBlock>
                </RefundModalSC.RowContainer>
                    <RefundModalSC.ButtonsWrapper>
                    <TransparentButton onClick={onClose}>
                        Cancel
                    </TransparentButton>
                    <PrimaryButton type="submit">
                        Confirm
                    </PrimaryButton>
                </RefundModalSC.ButtonsWrapper>
            </Form>
        </Formik>
    </Modal>
)