import React from 'react';

import { getImage, ImageName } from 'components/styles/images';

import { LogoSC } from './logo.styles';

type ImageProps = {
    onClick?: (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => void;
};

const Image: React.FC<ImageProps> = ({ onClick }) => getImage(ImageName.logo, '', onClick);

const Link = () => (
    <LogoSC to="/">
        <Image />
    </LogoSC>
);

export const EcatchLogo = {
    Link,
    Image,
};
