import React, { FC } from 'react';

import { BaseFormikWrapper, IBaseFormikWrapper } from '../wrapper/base-formik-wrapper.component';
import { BaseFormTextArea, IBaseFormTextArea } from '../../form/fields/form-input/base-form-textarea.component';

export type FormikFormTextAreaProps = IBaseFormTextArea & Omit<IBaseFormikWrapper<HTMLTextAreaElement>, 'component'>;

export const FormikFormTextArea: FC<FormikFormTextAreaProps> = (props) => (
  <BaseFormikWrapper {...props} component={BaseFormTextArea} />
);

