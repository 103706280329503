import styled from 'styled-components';

import { Link } from 'react-router-dom';

import { Font } from 'components/types/enums';
import { Color, sizes } from 'components/styles/constants.styles';

export const WebsiteButtonSC = styled(Link)`
  background: ${Color.pale_grey};
  max-width: 140px;
  margin-top: 19px;
  margin-bottom: 130px;
  width: 100%;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: ${sizes.border_radius};
  padding: 17px 15px;
  margin-left: auto;
  margin-right: auto;
  text-decoration: none;
  color: ${Color.lightish_blue};
  font-family: ${Font.bold};
  span {
    width: 20px;
    height: 20px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: ${Color.lightish_blue};
  }
`;
