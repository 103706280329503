import React, { useEffect, FC, useContext } from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';

import { AuthSC } from 'units/common/styles/auth/auth.styles';
import { EmailActivationSC } from './email-activation.styles';
import { confirmSignUpEmailThunk } from '../../redux/thunks';
import { useAsyncDispatch } from 'helpers/hooks/useAsyncDispatch';
import { Appi18nStrings } from 'config/strings';
import { UserUrls } from 'units/user/urls';
import { AltResponsiveRegistrationView } from '../../components/check-email/alt-responsive-registration-view/alt-responsive-registration-view.component';
import { NotificationContext } from 'shared/providers';

type EmailActivationParams = {
    uid: string;
    token: string;
};

const SignUpEmailActivationRedirect: FC<EmailActivationParams> = ({ uid, token }) => {
    const { dispatch, isFetching } = useAsyncDispatch();
    const { showNotification } = useContext(NotificationContext);

    useEffect(() => {
        dispatch(confirmSignUpEmailThunk({ uid, token }, showNotification));
    }, [dispatch, token, uid]);

    return (
        <AuthSC.AuthPageContainer>
            <EmailActivationSC.SubTitle>
                {isFetching
                    ? 'Processing...'
                    : 'You will be automatically redirected to the login page, or you can click the button.'}
            </EmailActivationSC.SubTitle>
            <EmailActivationSC.Button as={Link} to="/sign-in">
                {Appi18nStrings.login}
            </EmailActivationSC.Button>
        </AuthSC.AuthPageContainer>
    );
};

export const SignUpEmailActivation: FC<RouteComponentProps<EmailActivationParams>> = ({
    match: {
        params: { uid, token },
    },
    history,
}) => (
    <AltResponsiveRegistrationView
        uid={uid}
        token={token}
        onFlip={() => history.push(UserUrls.login)}
    >
        <SignUpEmailActivationRedirect uid={uid} token={token} />
    </AltResponsiveRegistrationView>
);
