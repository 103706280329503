import { FC, useEffect, useContext } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { resetPasswordCheckTokenThunk } from '../../redux/thunks';
import { useAsyncDispatch } from 'helpers/hooks/useAsyncDispatch';
import { NotificationContext } from 'shared/providers';

type ResetPasswordConfirmationParams = {
  uid: string,
  token: string,
}

export const ResetPasswordConfirmation: FC<RouteComponentProps<ResetPasswordConfirmationParams>> = ({
  match: {
    params: {
      uid, token,
    },
}}) => {
  const { dispatch } = useAsyncDispatch();
  const { showNotification } = useContext(NotificationContext);

  useEffect(() => {
    dispatch(resetPasswordCheckTokenThunk(token, uid, showNotification));
  }, [dispatch, token, uid]);

  return null;
};
