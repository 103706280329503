import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
// import { useHistory } from 'react-router-dom';
// import { useDispatch } from 'react-redux';

import { LPButton, LPFormElement } from '../../ui';

import { LPHeroEmailFormSC } from './hero-form.styles';
import { EmailContactRequestValues, sendContactRequest } from 'units/landing-page/api';
import { NotificationContext } from 'shared/providers';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { InputError } from 'shared/components/ui';

export const LPHeroEmailForm: FC = () => {
    const { showNotification } = useContext(NotificationContext);

    const { t } = useTranslation();
    // const history = useHistory();
    // const dispatch = useDispatch();

    const EmailInputValidationSchema = Yup.object().shape({
        email: Yup.string()
            .email(t('lp_hero_input_email_not_valid'))
            .required(t('lp_hero_input_email_empty')),
    });

    const handleFormSubmit = async (data: any) => {
        const values: EmailContactRequestValues = {
            email: data.email,
            message: 'I want to test ecatch.',
        };
        const { status } = await sendContactRequest(values);

        if (status === 200) {
            showNotification('success', t('lp_form_hero_success'));
        }
    };

    return (
        <LPHeroEmailFormSC.Root id="hero-section">
            <Formik
                initialValues={{ email: '' }}
                onSubmit={handleFormSubmit}
                validationSchema={EmailInputValidationSchema}
            >
                {({ values, errors, touched, handleChange, handleSubmit, handleBlur }) => (
                    <Form onSubmit={handleSubmit}>
                        <LPFormElement.Input
                            placeholder={t('lp_hero_input_email')}
                            styles={LPHeroEmailFormSC.InputCSS}
                            type="email"
                            name="email"
                            value={values.email}
                            id="email-contact-input"
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        <LPHeroEmailFormSC.ButtonWrapper>
                            <LPButton.Primary htmlTypes="submit">
                                {t('lp_hero_submit_email')}
                            </LPButton.Primary>
                        </LPHeroEmailFormSC.ButtonWrapper>
                        {errors.email && touched.email && <InputError>{errors.email}</InputError>}
                    </Form>
                )}
            </Formik>
        </LPHeroEmailFormSC.Root>
    );
};
