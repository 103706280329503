import React, { useCallback, useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Formik, FormikHelpers } from 'formik';
import { useSelector, useDispatch } from 'react-redux';

import { LayoutSC } from 'units/common/components/layout/layout-components.styles';
import { BackButton } from 'units/common/components/back-button/back-button.component';
import { SettingsUrls } from '../../urls';
import { accountSettingsValidationSchema } from './validation';
import { AccountForm } from './form/account-form.component';
import { UpdateUserAccountData, UserState } from 'units/user/redux/types';
import { updateMyAccountDataThunk } from 'units/user/redux/thunks';
import { RootState } from 'redux/root-types';

import { useAsyncDispatch } from 'helpers/hooks/useAsyncDispatch';
import { setUserErrorState } from 'units/user/redux/action-creators';
import { UserErrorKeys } from 'units/user/constants';
import { UserRoleId } from 'components/types/enums';
import { NotificationContext } from 'shared/providers';

const emptyInitialValues: UpdateUserAccountData = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  companyName: '',
};

export const AccountPage = () => {
  const { dispatch } = useAsyncDispatch();
  const syncDispatch = useDispatch();
  const { showNotification } = useContext(NotificationContext);
  const { user } = useSelector<RootState, UserState>((state) => state.user);

  const userRole: UserRoleId = user?.role?.id || UserRoleId.User;

  const submitForm = useCallback(async (values: UpdateUserAccountData, helpers: FormikHelpers<UpdateUserAccountData>) => {
    await dispatch(updateMyAccountDataThunk(values, showNotification));
    // clear hiddenErrors
    helpers.setStatus({});
  }, [dispatch]);

  const getInitialValues = (): UpdateUserAccountData => ({
    firstName: user?.firstName || emptyInitialValues.firstName,
    lastName: user?.lastName || emptyInitialValues.lastName,
    email: user?.email || emptyInitialValues.email,
    phone: user?.phone || emptyInitialValues.phone,
    companyName: user?.companyName || emptyInitialValues.companyName,
  });

  useEffect(() => () => {
    syncDispatch(setUserErrorState(UserErrorKeys.updateMyAccountData, null));
  },[]);

  return (
    <>
      <Helmet>
        <title>Account settings</title>
      </Helmet>
      <BackButton destinationText={'Settings'} to={SettingsUrls.index} />
      <LayoutSC.PageTitle smallPadding>Account settings</LayoutSC.PageTitle>
      <Formik
        initialValues={getInitialValues()}
        onSubmit={submitForm}
        validationSchema={accountSettingsValidationSchema}
      >
        <AccountForm
          allowEditCompany={userRole === UserRoleId.Owner}
          allowEditEmail={[UserRoleId.Owner, UserRoleId.Admin].includes(userRole)}
        />
      </Formik>
    </>
  );
};
