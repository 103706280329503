import styled from 'styled-components';

import { Color, sizes } from 'components/styles/constants.styles';
import { ColorPickerLabelText } from 'components/styles/text.styles';

const Container = styled.div`
    padding-top: 32px;
    padding-bottom: 32px;
    width: 272px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: ${sizes.border_radius};
    background-color: ${Color.white};
`;

const TextStep1 = styled.div`
    ${ColorPickerLabelText}

    width: 235px;
    height: 48px;
    text-align: center;
    margin: 24px 18px;
`;

const TextStep2 = styled.div`
    ${ColorPickerLabelText}

    width: 199px;
    height: 48px;
    text-align: center;
    margin: 24px auto;
`;

export const MailchimpSectionSC = {
    Container,
    TextStep1,
    TextStep2,
};
