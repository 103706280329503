import React, { FC, useMemo } from 'react';
import { Form, useFormikContext } from 'formik';

import { GeneralFormSC } from 'units/common/components/form/form.styles';
import { UserEditSC } from '../user-edit.styles';
import { PrimaryButton } from 'units/common/components/buttons/primary/primary.component';
import { TransparentButton } from 'units/common/components/buttons/transparent/transparent.component';
import { InvitedUser, UpdateInvitedUserError } from '../../api/types';
import { useIsFormikSubmitEnabled } from 'units/common/hooks/useIsSubmitEnabled';
import { useFieldErrors } from 'units/common/hooks/useFieldErrors';
import { FormikRadioButtonGroup } from 'units/common/components/formik/radio/radio-group.component';
import { UserRole } from 'components/types';
import { FormikFormInput } from 'units/common/components/formik/input/form-input.component';
import { useFormikApiErrors } from 'units/common/components/formik/hooks/useFormikApiErrors';

interface Props {
  deleteUserClickHandler: () => void;
  additionalErrors?: UpdateInvitedUserError | null;
  allowedRoles: Array<UserRole>;
}

export const UserEditForm: FC<Props> = ({
  deleteUserClickHandler,
  additionalErrors,
  allowedRoles
}) => {
  const { values, errors, touched } = useFormikContext<InvitedUser>();

  const apiErrors = useFormikApiErrors(additionalErrors);
  const fieldErrors = useFieldErrors<InvitedUser>(
    values, errors, touched, {
      notAllowed: ['phone'],
      additionalErrors: apiErrors,
    });
  
  const isSubmitDissabled = useIsFormikSubmitEnabled({ notRequired: ['phone'] });
  const roles = useMemo(() => allowedRoles.map(r => ({ id: `${r.id}`, label: r.role })), [allowedRoles]);
 
  return (
    <GeneralFormSC.Form as={Form}>
      <FormikRadioButtonGroup radioList={roles} name='roleId' margin="0 0 24px" />
      <GeneralFormSC.InputsGrid>
        <FormikFormInput
          label="First name"
          name="firstName"
          placeholder="Enter first name"
          widthPercents={50}
          withMargins
          error={fieldErrors.firstName}
        />
        <FormikFormInput
          label="Last name"
          name="lastName"
          placeholder="Enter last name"
          widthPercents={50}
          withMargins
          error={fieldErrors.lastName}
        />
        <FormikFormInput
          label="Email"
          name="email"
          placeholder="Enter email"
          type="email"
          widthPercents={50}
          withMargins
          error={fieldErrors.email}
        />
        <FormikFormInput
          label="Phone (optional)"
          name="phone"
          placeholder="Enter phone number"
          widthPercents={50}
          withMargins
          error={fieldErrors.phone}
        />
      </GeneralFormSC.InputsGrid>
      <UserEditSC.CTAContainer>
        <TransparentButton type="button" onClick={deleteUserClickHandler}>
          Delete user
        </TransparentButton>
        <PrimaryButton type="submit" disabled={!isSubmitDissabled}>
          Save
        </PrimaryButton>
      </UserEditSC.CTAContainer>
    </GeneralFormSC.Form>
  )
};

