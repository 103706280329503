import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'redux/root-types';
import { CompanyLanguages } from 'units/app/redux/types';

export const useEditProductPageTitle = (
  isNewProduct: boolean,
  selectedLanguage: CompanyLanguages,
) => {
  const productName = useSelector<RootState, string>(({ editProduct }) => (
    editProduct.form.languages[selectedLanguage]?.generalInfo.productName || 'product'
  ));

  return useMemo(() => {
    if (!isNewProduct) {
      return `Edit ${productName}`;
    }

    return 'Create new product';
  }, [productName, isNewProduct]);
};
