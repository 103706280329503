import styled, { css } from 'styled-components';

import { Color, sizes } from 'components/styles/constants.styles';
import { ColorPickerLabelText, FormInputText } from 'components/styles/text.styles';
import { CommonInputStyles } from 'units/common/components/form/fields/common-input.styles';

interface ColorProps {
  color: string;
}

const PreviewField = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
`;

const SelectedColor = styled.div<ColorProps>`
  width: 24px;
  height: 24px;
  border-radius: 4px;
  margin-right: ${sizes.sm_margin};
  background: ${({ color }) => `${color}`};
`;

const Label = styled.div`
  ${ColorPickerLabelText};
`;

const Container = styled.div`
  z-index: 1;
  width: 282px;
  padding: 10px;
  margin-top: -5px;
  position: absolute;
  background: ${Color.white};
  border-radius: ${sizes.border_radius};
`;

const InputWrapper = styled.div`
  display: flex;

  div:first-child {
    padding-bottom: 4px;
  }

  input:first-child {
    width: 100px;
    margin-right: 8px;
  }

  input:disabled {
    cursor: auto;
    background-color: ${Color.pale_grey};
  }

  input::placeholder {
    color: ${Color.charcoal_grey};
  }
`;

const PickerInput = styled.div`
  input {
    ${FormInputText};
    ${CommonInputStyles};
    width: 130px !important;
    padding-left: 20px;
    background-color: ${Color.pale_grey};
  }
`;

const Close = styled.div`
  color: ${Color.bluey_grey};
  position: absolute;
  font-size: 10px;
  padding: 5px;
  right: 5px;
  top: 7px;

  &:hover {
    cursor: pointer;
  }
`;

const Palette = styled.div`
  display: flex;
`;

const Hue = styled.div`
  height: 10px;
  position: relative;
`;

const Saturation = styled.div`
  width: 114px;
  height: 114px;
  margin-bottom: 10px;
  position: relative;
`;

const Colors = styled.div`
  display: grid;
  row-gap: 4px;
  column-gap: 4px;
  margin-left: 8px;
  grid-template-columns: repeat(6, 1fr);
`;

const ColorItem = styled.div<ColorProps>`
  width: 20px;
  height: 20px;
  cursor: pointer;
  background: ${({ color }) => `${color}`};
`;

const Wrapper = styled.div<{ disabled?: boolean }>`
  ${({ disabled }) => disabled && css`
    ${PreviewField} {
      cursor: not-allowed;
    }

    ${Label} {
      color: ${Color.disabled_text_grey};
    }
  `}
`;

export const ColorPickerSC = {
  PreviewField,
  SelectedColor,
  Label,
  Container,
  InputWrapper,
  PickerInput,
  Close,
  Palette,
  Hue,
  Saturation,
  Colors,
  ColorItem,
  Wrapper,
};
