import {
    CompanySettingsResponse,
    CompanySettings,
    ThirdPartyIntegration,
    ThirdPartyIntegrationSourceData,
} from './types';

export const parseCompanySettingsResponse = (
    settings: CompanySettingsResponse,
): CompanySettings => ({
    languages: settings.required_languages,
});

export const parseThirdPartyIntegrationsResponse = (
    integrations: Array<ThirdPartyIntegration>,
): ThirdPartyIntegrationSourceData[] =>
    integrations.map(({ source, config, active }) => ({
        source,
        key: config.api_key || config.tracking_id || '',
        isActive: active,
    }));
