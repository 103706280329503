import styled from 'styled-components';

import { CircleProgressWrapper } from './circle-progress.components';
import { Color } from 'components/styles/constants.styles';
import { TextStyle6 } from 'components/styles/text.styles';

export const CircleProgress = styled(CircleProgressWrapper)`
  cursor: pointer;

  .CircularProgressbar-trail {
    stroke: ${Color.cloudy_blue};
  }

  .CircularProgressbar-path {
    stroke: ${Color.lightish_blue};
  }

  .CircularProgressbar-text {
    ${TextStyle6}
    fill: ${({ value }) => value === 0 ? Color.white : Color.lightish_blue};
    font-size: 27px;
    transform: translateY(3px);
    text-transform: uppercase;
  }

  .CircularProgressbar-background {
    fill: ${({ value }) => value === 0 ? Color.cloudy_blue : 'none'};
  }
`

const Wrapper = styled.div`
  width: 60px;
  position: relative;
`;

const CompleteMark = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    z-index: 2;
  }
`;

const CompleteMarkBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;

  border-radius: 50%;
  background-color: ${Color.white};
`;

export const CircleProgressSC = {
  Wrapper,
  CompleteMark,
  CompleteMarkBg,
};
