import React, { InputHTMLAttributes, useEffect, useRef } from 'react';
import Inputmask from 'inputmask';
import clsx from 'clsx';

import styles from './Input.module.scss';

type ImmoInputProps = {
    before?: string;
    onlyNumbers?: boolean;
    mask?: string;
    maskOptions?: Inputmask.Options;
};

export const ImmoInput: React.FC<InputHTMLAttributes<HTMLInputElement> & ImmoInputProps> = ({
    before,
    onlyNumbers = false,
    className,
    mask,
    maskOptions,
    ...props
}) => {
    const ref = useRef<null | HTMLInputElement>(null);
    const hasOneDot = useRef(false);

    useEffect(() => {
        if (ref.current) {
            if (mask) {
                new Inputmask(mask, maskOptions).mask(ref.current);
                return;
            }
            if (maskOptions) {
                new Inputmask(maskOptions).mask(ref.current);
                return;
            }
        }
    }, [mask]);

    return (
        <div className={styles.root}>
            {before && <div className={styles.before}>{before}</div>}
            <input
                className={clsx(styles.input, className, { [styles['have-before']]: !!before })}
                {...props}
                ref={ref}
                {...(onlyNumbers && {
                    onKeyPress: event => {
                        if (event.key === '.' ? hasOneDot.current : !/[.0-9]/.test(event.key))
                            event.preventDefault();
                        if (event.key === '.') hasOneDot.current = true;
                    },
                    onKeyUp: e => {
                        if (!e.currentTarget.value.includes('.')) hasOneDot.current = false;
                    },
                })}
            />
        </div>
    );
};
