import { CMSThemeId, CMSGoogleFonts, CMSCustomizationPage, CMSTheme } from './types';
import * as actionTypes from './action-types';
import { CMSThemeSettingsConfig, CMSThemeSettingsOverallConfig } from './types/theme-settings';
import { CMSCustomizationConfig, CMSCustomizationPageConfig } from './types/customization';
import { CMSShopModalsCustomizationSettings } from './types/customization/shop-modals';
import { CMSCustomizationShopModalsSectionName } from '../types';
import { CMSMissedAuctionsCustomizationConfig } from './types/customization/missed-auctions';
import { CompanyLanguages } from 'units/app/redux/types';
import { CMSProductDetailPageCustomizationConfig } from './types/customization/product-detail-page';
import { CMSHomePageCustomizationConfig } from './types/customization/homepage';

// Themes tab

export const cmsSetAvailableThemes = (themes: Array<CMSTheme>) =>
    ({
        type: actionTypes.CMS_SET_AVAILABLE_THEMES,
        themes,
    } as const);

export const cmsSelectTheme = (themeId: CMSThemeId) =>
    ({
        type: actionTypes.CMS_SELECT_THEME,
        themeId,
    } as const);

export const setHomeHeroSectionAuctions = (auctions: string[]) =>
    ({
        type: actionTypes.SET_IMMO_HERO_SECTION_AUCTIONS,
        auctions,
    } as const);

export const cmsSelectedModal = (modal: CMSCustomizationShopModalsSectionName) =>
    ({
        type: actionTypes.CMS_SET_SELECTED_MODAL,
        modal,
    } as const);

export const cmsSelectActiveTheme = () =>
    ({
        type: actionTypes.CMS_SELECT_ACTIVE_THEME,
    } as const);

export const cmsSetActiveTheme = (themeId: CMSThemeId) =>
    ({
        type: actionTypes.CMS_SET_ACTIVE_THEME,
        themeId,
    } as const);

// Theme settings tab

export const cmsPopulateOverallThemeSettingsConfig = (
    config: CMSThemeSettingsOverallConfig,
    preselectedTheme?: CMSThemeId,
) =>
    ({
        type: actionTypes.CMS_POPULATE_OVERALL_THEME_SETTINGS_CONFIG,
        config,
        preselectedTheme,
    } as const);

export const cmsSetThemeSettingsConfig = (
    themeSettingsConfig: CMSThemeSettingsConfig,
    theme?: CMSThemeId,
) =>
    ({
        type: actionTypes.CMS_SET_THEME_SETTINGS_CONFIG,
        themeSettingsConfig,
        theme,
    } as const);

// Customization tab

export const cmsCustomizationSetPage = (page: CMSCustomizationPage) =>
    ({
        type: actionTypes.CMS_CUSTOMIZATION_SET_PAGE,
        page,
    } as const);

export const cmsPopulateCustomizationConfig = (config: CMSCustomizationConfig) =>
    ({
        type: actionTypes.CMS_POPULATE_CUSTOMIZATION_CONFIG,
        config,
    } as const);

export const cmsUpdateShopModalCustomizationConfig = (
    config: CMSShopModalsCustomizationSettings,
    selectedLanguage: CompanyLanguages,
) =>
    ({
        type: actionTypes.CMS_UPDATE_SHOP_MODAL_CUSTOMIZATION_CONFIG,
        config,
        selectedLanguage,
    } as const);

export const cmsUpdateMissedAuctionsCustomizationConfig = (
    missedAuctions: CMSMissedAuctionsCustomizationConfig,
) =>
    ({
        type: actionTypes.CMS_UPDATE_MISSED_AUCTIONS_CUSTOMIZATION_CONFIG,
        missedAuctions,
    } as const);

export const cmsUpdateProductDetailtCustomizationConfig = (
    data: CMSProductDetailPageCustomizationConfig,
) =>
    ({
        type: actionTypes.CMS_UPDATE_PRODUCT_DETAIL_CUSTOMIZATION_CONFIG,
        data,
    } as const);

export const cmsUpdateHomeCustomizationConfig = (data: CMSHomePageCustomizationConfig) =>
    ({
        type: actionTypes.CMS_UPDATE_HOME_CUSTOMIZATION_CONFIG,
        data,
    } as const);

export const cmsUpdateSelectedLanguage = (language: CompanyLanguages) =>
    ({
        type: actionTypes.CMS_UPDATE_SELECTED_LANGUAGE,
        language,
    } as const);

export const cmsSetCustomizationPageConfig = (
    page: CMSCustomizationPage,
    config: CMSCustomizationPageConfig,
) =>
    ({
        type: actionTypes.CMS_SET_CUSTOMIZATION_PAGE_CONFIG,
        page,
        config,
    } as const);

// Additional

export const cmsInformThemeOverviewIsLoading = () =>
    ({
        type: actionTypes.CMS_THEME_OVERIVEW_IS_LOADING,
    } as const);

export const cmsInformThemeOverviewLoadingIsEnded = () =>
    ({
        type: actionTypes.CMS_THEME_OVERVIEW_LOADING_ENDED,
    } as const);

export const cmsSetConfigSaveStatus = (isSaved: boolean) =>
    ({
        type: actionTypes.CMS_SET_CONFIG_SAVE_STATUS,
        isSaved,
    } as const);

export const cmsMarkImagesToDelete = (imageIds: Array<string>) =>
    ({
        type: actionTypes.CMS_MARK_IMAGES_TO_DELETE,
        imageIds,
    } as const);

export const cmsDirtyState = (value: boolean) =>
    ({
        type: actionTypes.CMS_DIRTY_STATE,
        value,
    } as const);

export const cmsSetGoogleFonts = (items: CMSGoogleFonts) =>
    ({
        type: actionTypes.CMS_SET_GOOGLE_FONTS,
        items,
    } as const);

// Clearing

export const cmsClearTempPayload = (theme?: CMSThemeId) =>
    ({
        type: actionTypes.CMS_CLEAR_TEMP_PAYLOAD,
        theme,
    } as const);
