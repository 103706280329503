import { useState, useCallback, useMemo, useEffect } from 'react';

export const useTrackProgress: UseTrackProgress = ({
    trackList,
    progressFields,
    commonFields,
}: TrackOptions) => {
    const [progressStore, setProgressStore] = useState<ProgressStore>({});

    // Initialize progress store and reinitialize when something in trackList changed
    useEffect(() => {
        setProgressStore(store => initializeProgressStore(trackList, store));
    }, [trackList]);

    const updateByKey = (key: string, name: string, add: boolean) => {
        // setProgressStore(store =>
        //     update(store, {
        //         [key]: {
        //             $set: [...newProgress],
        //         },
        //     }),
        // );
        setProgressStore(store => {
            return add
                ? { ...store, [key]: [...store[key], name] }
                : { ...store, [key]: [...store[key].filter(item => item !== name)] };
        });
    };
    const tryUpdateByKey: UpdateProgress = useCallback(
        (key, { name, isFilled }) => {
            const progressByKey = progressStore[key] || [];

            if (isFilled && !progressByKey.includes(name)) {
                updateByKey(key, name, true);
                return true;
            }

            if (!isFilled && progressByKey.includes(name)) {
                updateByKey(key, name, false);
                return true;
            }

            return false;
        },
        [progressStore],
    );

    const updateKeyProgress: UpdateProgress = useCallback(
        (key, field) => {
            const { name } = field;
            // const isEligible = progressFields.includes(name); REMOVED 11.02.21

            if (commonFields.includes(name)) {
                Object.keys(progressStore).forEach(progressKey =>
                    tryUpdateByKey(progressKey, field),
                );
                return;
            }

            tryUpdateByKey(key, field);
        },
        [tryUpdateByKey, commonFields, progressFields, progressStore],
    );

    const progressList: Array<TrackProgressItem> = useMemo(() => {
        return Object.entries(progressStore).map(([progressKey, filledFields]) => ({
            key: progressKey,
            value: getProgressValue(progressFields, filledFields),
        }));
    }, [progressStore, progressFields]);

    return [progressList, updateKeyProgress];
};

const getProgressValue = (progressFields: Array<string>, filledFields?: Array<string>) => {
    if (!progressFields.length) {
        return 1;
    } else {
        return filledFields
            ? filledFields.filter(f => progressFields.includes(f)).length / progressFields.length
            : 0;
    }
};

export type TrackProgressField = {
    name: string;
    isFilled: boolean;
};

export type TrackProgressItem = {
    key: string;
    value: number;
};

export type UpdateProgress = (key: string, field: TrackProgressField) => void;

export type TrackListItem = {
    key: string;
    initialFields?: Array<string>;
};

type TrackOptions = {
    progressFields: Array<string>;
    commonFields: Array<string>;
    trackList: Array<TrackListItem>;
};

type UseTrackProgress = (options: TrackOptions) => [Array<TrackProgressItem>, UpdateProgress];

type ProgressStore = {
    [progress_key: string]: Array<string>;
};

const initializeProgressStore = (trackList: Array<TrackListItem>, store?: ProgressStore) =>
    trackList.reduce((fields, { key, initialFields }) => {
        const list = [...(initialFields || [])];
        const storeList = store?.[key];
        if (storeList) {
            list.push(...storeList);
        }

        return {
            ...fields,
            // only uniq
            [key]: list.filter((v, i, a) => a.indexOf(v) === i),
        };
    }, {} as ProgressStore);
