import React, { FC } from 'react'

import { LPMobileHamburgerSC } from './mobile-hamburger.styles';

type LPMobileHamburgerProps = {
    onClick: () => void;
    isOpen: boolean;
    isScroll:boolean;
}

export const LPMobileHamburger: FC<LPMobileHamburgerProps> = ({ onClick, isOpen, isScroll }) => (
    <LPMobileHamburgerSC.Root onClick={onClick} isOpen={isOpen} isScroll={isScroll}>
        <LPMobileHamburgerSC.Line />
        <LPMobileHamburgerSC.Line />
    </LPMobileHamburgerSC.Root>
)
