import styled from 'styled-components';

import { sizes, Color } from 'components/styles/constants.styles';

const PictureGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;

  margin-bottom: 32px;
`;

const HoverContainer = styled.div`
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;

  border-radius: ${sizes.border_radius};
  background-color: ${Color.modal_overlay};
`;

const PictureWrapper = styled.div`
  position: relative;
  height: 260px;
  overflow: hidden;
  border-radius: 8px;

  &:hover ${HoverContainer} {
    display: block;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
`;

const HoverButtonContainer = styled.div`
  position: absolute;
  display: grid;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  button {
    color: ${Color.white};
    border: solid 2px ${Color.white};
    margin: 8px;

    &:hover {
      background-color: unset;
    }
  }

  span {
    margin-right: 5px;
  }
`;

export const ProductPicturesFormSC = {
  PictureGrid,
  PictureWrapper,
  HoverContainer,
  HoverButtonContainer,
};
