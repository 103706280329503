import React, { FC } from 'react';
import ReactPaginate from 'react-paginate';

import { PaginationSC } from './pagination.styles';
import { IconSC, IconUnicodes } from 'components/styles/icons.styles';

interface Props {
    onPageChange: (selectedItem: { selected: number }) => void;
    // itemsCount: number;
    pageCount: number;
    pageSize?: number;
}

export const Pagination: FC<Props> = ({ onPageChange, pageCount, pageSize }) => {
    if (pageCount <= 1) {
        return null;
    }

    return (
        <PaginationSC>
            <ReactPaginate
                previousLabel={<IconSC name={IconUnicodes.angle_left} />}
                nextLabel={<IconSC name={IconUnicodes.angle_right} />}
                breakLabel={'...'}
                breakClassName={'break'}
                pageCount={pageCount}
                marginPagesDisplayed={1}
                pageRangeDisplayed={2}
                onPageChange={onPageChange}
                containerClassName={'pagination'}
                activeClassName={'active'}
            />
        </PaginationSC>
    );
};
