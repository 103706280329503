import React, { useCallback, FC, useState, useContext } from 'react';
import { useDropzone, FileRejection } from 'react-dropzone';

import { PrimaryButton } from 'units/common/components/buttons/primary/primary.component';
import { UploadPictureSC } from './upload-picture.style';
import { ProductPictureFakeUploading } from './fake-uploader';
import { Appi18nStrings, AppStrings } from 'config/strings';
import { NotificationContext } from 'shared/providers';
import { useTranslation } from 'react-i18next';

const maxSize = 1572864; // bytes in 1.5 MB
const acceptTypes = 'image/jpeg, image/png, image/jpg, image/webp';

interface Props {
    storePictures: (pictures: Array<File>) => void;
    hasError: boolean;
}

let timeoutRef: number;

enum PictureUploadErrorStrings {
    PICTURE_IS_LARGER = 'file-too-large',
    FILE_IS_NOT_PICTURE = 'file-invalid-type',
}

export const UploadPicture: FC<Props> = ({ storePictures, hasError }) => {
    const { t } = useTranslation();
    const { showNotification } = useContext(NotificationContext);
    const [isFakeUploading, setFakeUploading] = useState(false);

    const onDropAccepted = useCallback(
        (acceptedFiles: Array<File>) => {
            setFakeUploading(true);

            timeoutRef = window.setTimeout(() => {
                setFakeUploading(false);
                storePictures(acceptedFiles);
            }, 1000);
        },
        [storePictures],
    );

    const onDropRejected = useCallback((rejectedFiles: Array<FileRejection>) => {
        const errorCode = rejectedFiles[0].errors[0].code;
        let errorMessage = AppStrings.errorNotification;

        if (errorCode === PictureUploadErrorStrings.PICTURE_IS_LARGER) {
            errorMessage = Appi18nStrings.pictureUploader.pictureIsLarger;
        } else if (errorCode === PictureUploadErrorStrings.FILE_IS_NOT_PICTURE) {
            errorMessage = Appi18nStrings.pictureUploader.invalidType;
        }

        showNotification('error', errorMessage);
    }, []);

    const cancelUploadingHandler = useCallback(() => {
        clearTimeout(timeoutRef);
        setFakeUploading(false);
    }, [timeoutRef]);

    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
        onDropAccepted,
        onDropRejected,
        maxSize,
        accept: acceptTypes,
    });

    return (
        <>
            {isFakeUploading ? (
                <UploadPictureSC.Container hasError={hasError}>
                    <UploadPictureSC.Title>Picture is loading</UploadPictureSC.Title>
                    <ProductPictureFakeUploading />
                    <PrimaryButton type="button" onClick={cancelUploadingHandler} isLow={true}>
                        {t('cancel')}
                    </PrimaryButton>
                </UploadPictureSC.Container>
            ) : (
                <UploadPictureSC.Container
                    hasError={hasError}
                    {...getRootProps({
                        isDragActive,
                        isDragAccept,
                        isDragReject,
                    })}
                >
                    <input {...getInputProps({ id: 'picture-uploader' })} />
                    <UploadPictureSC.Title>
                        {t('theme_settings_favicon_description')}
                    </UploadPictureSC.Title>
                    <label htmlFor="picture-uploader">
                        <PrimaryButton type="button" isLow={true}>
                            {t('choose_file')}
                        </PrimaryButton>
                    </label>
                </UploadPictureSC.Container>
            )}
        </>
    );
};
