import React, { useCallback, FC } from 'react';
import { useFormikContext, FormikHelpers } from 'formik';

import { Accordion } from 'units/common/components/accordion/accordion.component';
import { CMSShopModalsCustomizationSettings } from 'units/cms/store/types/customization/shop-modals';
import { CMSShopModalsSectionHeaders } from '..';
import { CMSCustomizationLanguageProgressContainerProps } from '../../../progress-container/progress-container';
import { SectionProgress } from 'units/cms/helpers/prepare-customization-progress';
import { CMSCustomizationShopModalsSectionName } from 'units/cms/types';
import { CMSShopModalsAccordionContainer } from './shop-modals-accordion.container'
import { CMSShopModalsGeneralAccordion } from '../general/general-accordion.component';
import { CMSShopModalsRegisterAccordion } from '../register/register-accordion.component';
import { CMSShopModalsLogin1Accordion } from '../login1/login1-accordion.component';
import { CMSShopModalsLogin2Accordion } from '../login2/login2-accordion.component';
import { CMSShopModalsOfferConfirmationAccordion } from '../offer-confirmation/offer-confirmation-accordion.component';
import { CMSShopModalsOfferDenialAccordion } from '../offer-denial/offer-denial-accordion.component';
import { CMSShopModalsOfferWonAccordion } from '../offer-won/offer-won-accordion.component';
import { CMSShopModalsResetPasswordAccordion } from '../reset-password/reset-password-accordion.component';

type SectionCommonProps = Pick<CMSCustomizationLanguageProgressContainerProps, 'selectedLanguage' | 'onLanguageChange'>;

type Props = SectionCommonProps & {
  sectionProgress: SectionProgress<CMSCustomizationShopModalsSectionName, any>;
  activeSections: Array<string>;
  handleAccordionExpandedChange: (
    expanded: Array<string>,
    languageSettings: CMSShopModalsCustomizationSettings,
    { resetForm }: Pick<FormikHelpers<CMSShopModalsCustomizationSettings>, 'resetForm'>,
  ) => void;
}

export const CMSCustomizationShopModalsAccordions: FC<Props> = ({
  handleAccordionExpandedChange,
  sectionProgress,
  activeSections,
  ...sectionCommonProps
}) => {
  const { values, resetForm } = useFormikContext<CMSShopModalsCustomizationSettings>();

  const accordionOnChange = useCallback((expanded: Array<string>) => {
    handleAccordionExpandedChange(expanded, values, { resetForm });
  }, [handleAccordionExpandedChange, values, resetForm]);

  return (
    <Accordion
      onChange={accordionOnChange}
      items={[
        { header: CMSShopModalsSectionHeaders.general, uuid: 'general', content: (
          <CMSShopModalsAccordionContainer
            Accordion={CMSShopModalsGeneralAccordion}
            initialProgress={sectionProgress.general}
            hideProgress
            {...sectionCommonProps}
          />) },
          { header: CMSShopModalsSectionHeaders.register, uuid: 'register', content: (
            <CMSShopModalsAccordionContainer
              Accordion={CMSShopModalsRegisterAccordion}
              initialProgress={sectionProgress.register}
              {...sectionCommonProps}
          />
          ) },
        { header: CMSShopModalsSectionHeaders.login1, uuid: 'login1', content: (
          <CMSShopModalsAccordionContainer
            Accordion={CMSShopModalsLogin1Accordion}
            initialProgress={sectionProgress.login1}
            {...sectionCommonProps}
          />) },
        { header: CMSShopModalsSectionHeaders.login2, uuid: 'login2', content: (
          <CMSShopModalsAccordionContainer
            Accordion={CMSShopModalsLogin2Accordion}
            initialProgress={sectionProgress.login2}
            {...sectionCommonProps}
          />
        ) },
        { header: CMSShopModalsSectionHeaders.offerConfirmation, uuid: 'offerConfirmation', content: (
          <CMSShopModalsAccordionContainer
            Accordion={CMSShopModalsOfferConfirmationAccordion}
            initialProgress={sectionProgress.offerConfirmation}
            {...sectionCommonProps}
          />
        ) },
        { header: CMSShopModalsSectionHeaders.offerDenial, uuid: 'offerDenial', content: (
          <CMSShopModalsAccordionContainer
            Accordion={CMSShopModalsOfferDenialAccordion}
            initialProgress={sectionProgress.offerDenial}
            {...sectionCommonProps}
          />
        ) },
        { header: CMSShopModalsSectionHeaders.offerWon, uuid: 'offerWon', content: (
          <CMSShopModalsAccordionContainer
            Accordion={CMSShopModalsOfferWonAccordion}
            initialProgress={sectionProgress.offerWon}
            withDevider={false}
            {...sectionCommonProps}
          />
        ) },
        { header: CMSShopModalsSectionHeaders.resetPassword, uuid: 'resetPassword', content: (
          <CMSShopModalsAccordionContainer
            Accordion={CMSShopModalsResetPasswordAccordion}
            initialProgress={sectionProgress.resetPassword}
            withDevider={false}
            {...sectionCommonProps}
          />
        ) },
      ]}
      expanded={activeSections}
      preExpanded={['general']}
    />
  );
}
