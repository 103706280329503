import styled, { css } from 'styled-components';

import { MediaQueries } from 'units/landing-page/config';

const GeneralSC = css`
  position: absolute;
  height: auto;
`;

const Root = styled.div`
  width: 100%;
  height: 100%;
`;

const Background = styled.img`
  ${GeneralSC}
  top: 0;
  right: 16%;

  @media (max-width: ${MediaQueries.tablet}) {
    max-width: 687px;
    right: 8%;
  }

  @media (max-width: ${MediaQueries.mobile}) {
    max-width: 393px;
    right: 15%;
  }
`;

const RootImage = styled.img`
  ${GeneralSC}
  z-index: 2;
  top: 60px;
  right: -1%;

  @media (max-width: ${MediaQueries.tablet}) {
    max-width: 610px;
    top: 40px;
    right: -13%;
  }

  @media (max-width: ${MediaQueries.mobile}) {
    max-width: 393px;
    top: 20px;
    right: -1%;
  }
`;

const Timer = styled.img`
  ${GeneralSC}
  z-index: 3;
  right: 3%;
  bottom: -160px;

  @media (max-width: ${MediaQueries.tablet}) {
    right: -8%;
    bottom: -10px;
    max-width: 345px;
  }

  @media (max-width: ${MediaQueries.mobile}) {
    bottom: initial;
    right: 3%;
    top: 150px;
    max-width: 224px;
  }
`;

const Notification = styled.img`
  ${GeneralSC}
  z-index: 3;
  right: 28%;
  top: 30px;

  @media (max-width: ${MediaQueries.tablet}) {
    right: 27%;
    top: -11px;
    max-width: 276px;
  }

  @media (max-width: ${MediaQueries.mobile}) {
    top: -14px;
    max-width: 185px;
  }
`;

const SubTimer = styled.img`
  ${GeneralSC}
  z-index: 1;
  bottom: -19px;
  right: 55%;

  @media (max-width: ${MediaQueries.tablet}) {
    bottom: 11px;
    right: 65%;
    max-width: 99px;
  }

  @media (max-width: ${MediaQueries.mobile}) {
    top: 222px;
    right: 55%;
    max-width: 62px;
  }
`;

export const LPWhiteLabelSC = {
  Root,
  Background,
  RootImage,
  Timer,
  SubTimer,
  Notification
}
