import { useField } from 'formik';
import React, {FC, useCallback, useEffect, useState } from 'react';
import NumberFormat, { NumberFormatProps, NumberFormatValues } from 'react-number-format';

type Props = React.InputHTMLAttributes<HTMLInputElement> & NumberFormatProps;

export const BaseNumberInput: FC<Props> = ({withRounding, value, ...props}) => {
  const [inputValue, setInputValue] = useState<string | number | undefined>('');
  const [,, { setValue, setTouched }] = useField({ name: props.name || '' });

  useEffect(() => {
    withRounding && setInputValue(value);
  }, [value]);

  const roundToFive = useCallback((values: NumberFormatValues) => {
    if (values.formattedValue) {
      setInputValue(Math.ceil(parseFloat(values.formattedValue) / 5) * 5)
    }
  }, []);

  const onBlurInput = () => {
    setTouched(true);
    if(withRounding) setValue(inputValue);
  }

  return (
    <NumberFormat
      isNumericString
      {...props}
      type="text"
      value={withRounding ? inputValue : value}
      onValueChange={withRounding ? roundToFive : undefined}
      onBlur={onBlurInput} 
    />
  );
}
