import React, { FC, useMemo, useEffect } from 'react';

import { useDeviceDetector } from 'units/landing-page/hooks';
import { SmallScreenTemplate } from './SmallScreenTemplate';
import { ImageName } from 'components/styles/images';

type Props = {
    onFlip?: () => void;
    disabled?: boolean;
    setOtherScreenActive?: (isActive: boolean) => void;
}

export const OtherScreens: FC<Props> = ({ children, onFlip, disabled, setOtherScreenActive }) => {
    const { device, isDashboardUsableOnFlip } = useDeviceDetector(onFlip);

    useEffect(() => {
        setOtherScreenActive && setOtherScreenActive((device === 'mobile') || (device === 'tablet'));
    }, [device]);

    const content = useMemo(() => {
        const defaultContent = <>{children}</>;
        if (disabled) {
            return defaultContent;
        } else if (isDashboardUsableOnFlip) {
            return (
                    <SmallScreenTemplate
                        message="Please, tilt your screen to use the dashboard"
                        imageName={ImageName.tablet}
                    />
            );
        } else if ((device === 'mobile') || (device === 'tablet')) {
            return (
                    <SmallScreenTemplate
                        message="Dear admin, your dashboard is only available on a desktop."
                        imageName={ImageName.dashboard}
                    />
            );
        } else {
            return defaultContent;
        }
    }, [device, isDashboardUsableOnFlip, children]);

    return content;
};

