import React from 'react';
import clsx from 'clsx';

import styles from './InputWrapper.module.scss';

type ImmoInputWrapperProps = {
    className?: string;
    label?: string;
};

export const ImmoInputWrapper: React.FC<ImmoInputWrapperProps> = ({
    className,
    children,
    label,
}) => (
    <div className={clsx(styles.root, className)}>
        {label && <div className={styles.label}>{label}</div>}
        {children}
    </div>
);
