import { ApiClient, apiPath } from 'config/api/api';
import { InvitedUserRes, InvitedUserResp, InviteUserReq, InviteUserValues, InvitedUserReq, ResendInvitationRes } from './types';
import { parseInvitedUser } from '../tools/parsers/invitedUser';
import { UserRole } from 'components/types';
import { UserEditFormValues } from '../user/user-edit.component';

export const getInvitedUserById = async (id: string) => {
  const api = ApiClient.getInstance();

  const { data } = await api.get<InvitedUserRes>(`${apiPath}/users/invited-users/${id}/`);
  
  return parseInvitedUser(data);
};

export const updateInvitedUserById = async (id: string, user: UserEditFormValues) => {
  const api = ApiClient.getInstance();

  const userForRequest: InvitedUserReq = {
    email: user.email,
    first_name: user.firstName,
    last_name: user.lastName,
    phone: user.phone,
    role: +user.roleId,
  };
  
  await api.put<void>(`${apiPath}/users/invited-users/${id}/`, userForRequest);
};

export const deleteInvitedUserById = async (id: string) => {
  const api = ApiClient.getInstance();

  await api.delete<void>(`${apiPath}/users/invited-users/${id}/`);
}

export const getInvitedUsers = async (): Promise<Array<InvitedUserResp>> => {
  const api = ApiClient.getInstance();
  const response = await api.get(`${apiPath}/users/invited-users/`);

  return response.data;
};

export const inviteUser = async (user: InviteUserValues): Promise<Array<InviteUserReq>> => {
  const api = ApiClient.getInstance();
  const response = await api.post(`${apiPath}/users/invite-user/`, {
    role_id: user.roleId,
    first_name: user.firstName,
    last_name: user.lastName,
    email: user.email,
  });

  return response.data;
};

export const getRoleList = async () => {
  const api = ApiClient.getInstance();

  const { data } = await api.get<Array<UserRole>>(`${apiPath}/users/roles-list/`);
  return data;
};

export const resendInvitationLink = async (userEmail: string): Promise<ResendInvitationRes> => {
  const api = ApiClient.getInstance();

  const response = await api.post<ResendInvitationRes>(`${apiPath}/users/invited-users/reinvite/`, { email: userEmail });
  
  return response.data;
}
