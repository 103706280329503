import { useState, useCallback, FC, useRef, useEffect } from 'react';
import { useDropzone, FileRejection, DropzoneRootProps, DropzoneInputProps } from 'react-dropzone';

import { TimeoutType } from 'components/types';
import { generateId } from 'helpers/general';

export type BaseUploaderProps = {
  minSize?: number;
  maxSize?: number;
  maxFiles?: number;
  multiple?: boolean;
  acceptTypes?: string | Array<string>;
  onFileUploaded: (files: Array<File>) => void;
  onFileRejected: (files: Array<FileRejection>) => void;

  fakeUploading?: boolean;
  fakeUploadingDuration?: number;
}

export type BaseUploaderContainerProps = {
  isUploading: boolean;
  cancelUploadingHandler: () => void;
  uploaderId: string;
  rootProps: DropzoneRootProps;
  inputProps: DropzoneInputProps;
}

export enum FileUploaderErrors {
  FILE_TOO_LARGE = 'file-too-large',
  FILE_INVALID_TYPE = 'file-invalid-type',
  FILE_TOO_SMALL = 'file-too-small',
  FILE_TOO_MANY = 'too-many-files',
}

type Props = BaseUploaderProps & {
  renderUploader: (props: BaseUploaderContainerProps) => JSX.Element;
}

export const BaseUploader: FC<Props> = ({
  minSize,
  maxFiles,
  maxSize,
  multiple,
  acceptTypes,

  fakeUploading,
  fakeUploadingDuration,

  onFileUploaded,
  onFileRejected,

  renderUploader,
}) => {
  const [isUploading, setUploading] = useState(false);
  const fakeUploadingTimeout = useRef<TimeoutType | null>(null);
  const uploaderId = useRef<string>(generateId());

  const onDropAccepted = useCallback((acceptedFiles: Array<File>) => {
    if (fakeUploading) {
      setUploading(true);

      fakeUploadingTimeout.current = setTimeout(() => {
        setUploading(false);
        onFileUploaded(acceptedFiles);
      }, fakeUploadingDuration ?? 1000);
    } else {
      onFileUploaded(acceptedFiles);
    }
  }, [onFileUploaded]);

  const clearUploadingTimeout = useCallback(() => {
    if (fakeUploadingTimeout.current) {
      clearTimeout(fakeUploadingTimeout.current);
    }
  }, []);

  const cancelUploadingHandler = useCallback(() => {
    clearUploadingTimeout();
    setUploading(false);
  }, [fakeUploadingTimeout]);

  // clear timeout
  useEffect(() => () => clearUploadingTimeout(), []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDropAccepted,
    onDropRejected: onFileRejected,
    multiple,
    maxSize,
    minSize,
    maxFiles,
    accept: acceptTypes,
  });

  return renderUploader({
    isUploading,
    cancelUploadingHandler,
    uploaderId: uploaderId.current,
    rootProps: {
      ...getRootProps({
        isDragActive,
        isDragAccept,
        isDragReject,
      }),
    },
    inputProps: {
      ...getInputProps({ id: uploaderId.current }),
    },
  });
};
