import React from 'react';
/* eslint-disable import/named */
import { FlattenSimpleInterpolation } from 'styled-components';
/* eslint-enable import/named */

import { LPTextLabelSC } from './text-label.styles';

type LPTextLabelProps = {
    styles?:FlattenSimpleInterpolation;
}

export const LPTextLabel:React.FC<LPTextLabelProps> = ({ children, styles }) => (
    <LPTextLabelSC.Root styles={styles}>
        {children}
    </LPTextLabelSC.Root>
)
