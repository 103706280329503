import { UserUrls } from './urls';
import { SignUp } from './pages/sign-up/sign-up.component';
import { SignIn } from './pages/sign-in/sign-in.component';
import { ResetPassword } from './pages/reset-password/reset-password.component';
import { SignUpEmailActivation } from './pages/email-activation/email-activation.component';
import { ResetPasswordConfirmation } from './pages/reset-password-confirmation/reset-password-confirmation.component';
import { AcceptInvitation } from './pages/accept-invitation/accept-invitation.component';
import { CreatePassword } from './pages/create-password/create-password.component';

export const UserRoutes = [
  { exact: true, path: UserUrls.registration, component: SignUp },
  { exact: true, path: UserUrls.login, component: SignIn },
  { exact: true, path: UserUrls.resetPassword, component: ResetPassword },
  { exact: true, path: UserUrls.confirmEmail, component: SignUpEmailActivation },
  { exact: true, path: UserUrls.confirmResetPassword, component: ResetPasswordConfirmation },
  { exact: true, path: UserUrls.acceptInvitation, component: AcceptInvitation },
  { exact: true, path: UserUrls.createPassword, component: CreatePassword },
];
