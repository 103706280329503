import React, { useCallback, FC } from 'react';

import {
    CMSCustomizationLanguageProgressContainer,
    CMSProgressBeforeUpdate,
    CMSCustomizationLanguageProgressContainerProps,
} from '../../../progress-container/progress-container';
import { CmsAcordionSection } from 'units//cms/components/cms-acordion-section/cmsAcordionSection.component';
import { CMSHomepageUSPAccordion } from './usp-accordion.component';

type Props = Pick<
    CMSCustomizationLanguageProgressContainerProps,
    'selectedLanguage' | 'onLanguageChange' | 'initialProgress'
>;

const USPProgressList: Array<string> = [
    'usp1.title',
    'usp1.icon',
    'usp2.title',
    'usp2.icon',
    'usp3.title',
    'usp3.icon',
];

export const CMSHomepageUSPAccordionContainer: FC<Props> = props => {
    const handleBeforeUpdateProgress = useCallback(
        (...[field, { updateProgressFields }]: Parameters<CMSProgressBeforeUpdate>) => {
            if (field.name === 'usp.isActive') {
                updateProgressFields(
                    USPProgressList.map(uspKey => ({
                        key: uspKey,
                        operation: field.isFilled ? 'add' : 'delete',
                    })),
                );

                return false;
            }
            return true;
        },
        [],
    );

    return (
        <CmsAcordionSection lastSection>
            <CMSCustomizationLanguageProgressContainer
                beforeUpdate={handleBeforeUpdateProgress}
                {...props}
            >
                {({ updateCurrentProgress }) => (
                    <CMSHomepageUSPAccordion updateProgress={updateCurrentProgress} />
                )}
            </CMSCustomizationLanguageProgressContainer>
        </CmsAcordionSection>
    );
};
