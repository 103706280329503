import { CMSImage, CMSCustomizationPage, CMSUploadedImage, CMSImageToSave, CMSImageToDelete } from '../types';
import { cmsRootServices } from '../services';

export enum CMSImageKey {
  logo = 'logo',
  favicon = 'favicon',
  conversationStickerIcon = 'conversationStickerIcon',
  usp1Icon = 'usp1Icon',
  usp2Icon = 'usp2Icon',
  usp3Icon = 'usp3Icon',
  textBlockPicture = 'textBlockPicture',
  newsletterIcon = 'newsletterIcon',
  confirmButtonIcon = 'confirmButtonIcon',
  denyButtonIcon = 'denyButtonIcon',
  winButtonIcon = 'winButtonIcon',
  sentEmailIcon = 'sentEmailIcon',
  enterPasswordIcon = 'enterPasswordIcon',
}

// To define if image is saved.
export const isCMSSavedImage = (image: CMSImage | File | null): image is CMSImage => image != null && (image as CMSImage).id !== undefined;

export const getConfigPageName = (page: CMSCustomizationPage) => {
  switch (page) {
    case CMSCustomizationPage.Home:
      return 'homepage';
    case CMSCustomizationPage.ProductDetail:
      return 'productDetailPage';
    case CMSCustomizationPage.MissedAuctions:
      return 'missedAuctions';
    case CMSCustomizationPage.ShopModals:
      return 'shopModals';
    default:
      return '';
  }
}

export const findCMSUploadedImage = (uploadedImages: Array<CMSUploadedImage>, key: CMSImageKey): CMSImage | undefined => {
  const image = uploadedImages.find(img => img.key === key);
  return image ? { id: image.id, src: image.src } : undefined;
}

export const handleCMSImageStoring = (imagesToSave: Array<CMSImageToSave>, imagesToDelete: Array<CMSImageToDelete>) => {
  if (imagesToDelete.length) {
    cmsRootServices.deleteCMSImages(imagesToDelete);
  }

  return cmsRootServices.uploadCMSImages(imagesToSave);
};

export const isEmptyObject = (obj: { [key in string]: any }) => Object.keys(obj).length === 0 && obj.constructor === Object;
