import { AxiosResponse } from 'axios';

import { ApiClient, apiPath } from 'config/api/api';
import { OrderDetailType, OrdersListType } from '../types';

const getAll = async(page:number, size:number):Promise<AxiosResponse<OrdersListType>> => {
    const api = ApiClient.getInstance();
    return api.get<OrdersListType>(`${apiPath}/orders/?page=${page}&page_size=${size}`);
}

const get = async(id:string):Promise<AxiosResponse<OrderDetailType>> => {
    const api = ApiClient.getInstance();
    return api.get<OrderDetailType>(`${apiPath}/orders/${id}`);
}

export const OrdersService = {
    getAll,
    get
}
