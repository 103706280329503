import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

const getTimeFromDate = (date: string): string => {
    return dayjs(date).format('HH:mm');
};

const getDateFromDate = (date: string): string => {
    return dayjs(date).format('YYYY-MM-DD');
};

const convertUtcToLocal = (date: string | null): Date => {
    const data = dayjs(dayjs.utc(date || new Date()).toDate()).local();

    return data.toDate();
};

export const DateHelper = {
    getDateFromDate,
    getTimeFromDate,
    convertUtcToLocal,
};
