import styled from 'styled-components';

import { FormInputText } from 'components/styles/text.styles';
import { Color } from 'components/styles/constants.styles';

type DropzoneStateForStyle = {
  isDragAccept?: boolean,
  isDragReject?: boolean,
  isDragActive?: boolean,
}

type ContainerProps = DropzoneStateForStyle & {
  hasError: boolean;
}

const getDropzoneColor = (props: DropzoneStateForStyle) => {
  // if (props.isDragAccept) {
  //     return '#00e676';
  // }
  // if (props.isDragReject) {
  //     return '#ff1744';
  // }
  if (props.isDragActive) {
      return Color.transparent_hover;
  }
  return 'unset';
}

/* eslint-disable max-len */
/* eslint-disable quotes */

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  outline: none;

  height: 260px;
  border-radius: 8px;
  
  overflow: unset;

  /* https://kovart.github.io/dashed-border-generator/ */
  ${({ hasError }) => hasError
    ? `background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23F51010FF' stroke-width='2' stroke-dasharray='5%2c 5' stroke-dashoffset='8' stroke-linecap='square'/%3e%3c/svg%3e");`
    : `background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23C8D2E1FF' stroke-width='2' stroke-dasharray='5%2c 5' stroke-dashoffset='8' stroke-linecap='square'/%3e%3c/svg%3e");`
  }

  background-color: ${(props: DropzoneStateForStyle) => getDropzoneColor(props)}px;
`;

/* eslint-enable max-len */
/* eslint-enable quotes */

const Title = styled.div`
  ${FormInputText}
  margin-bottom: 24px;
`;

const LoadedPart = styled.div`
  @keyframes loading {
    from { width: 0%; }
    to { width: 100%; margin-right: 0 }
  }

  height: 100%;
  width: 0%;
  margin-right: 5px;
  border-radius: 6px;

  background-color: #fd9d40;
  
  animation: loading forwards;
  animation-duration: 1s;
`;

const UnLoadedPart = styled.div`
  @keyframes hiding {
    from { width: 100%; }
    to { width: 0%; }
  }

  height: 100%;
  width: 100%;
  border-radius: 6px;
  
  background-color: #ecf2fd;
  
  animation: hiding forwards;
  animation-duration: 1s;
`;

const Loading = styled.div`
  display: flex;
  width: 206px;
  height: 8px;
  overflow-x: hidden;
  border-radius: 6px;
  margin-bottom: 32px;
`;

export const UploadPictureSC = {
  Title,
  Container,
  Loading,
  LoadedPart,
  UnLoadedPart,
};
