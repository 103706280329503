import React, { FC, memo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useParallax } from 'units/landing-page/hooks';

import { LPTypography } from '../../ui';
import { LPHeroEmailForm } from '../../grid';

import main from '../../../images/hero-main.png';
import preview from '../../../images/hero-preview.png';
import support from '../../../images/hero-support.png';

import mainRetina from '../../../images/hero-main@retina.png';
import previewRetina from '../../../images/hero-preview@retina.png';
import supportRetina from '../../../images/hero-support@retina.png';

import { LPHeroSC } from './hero.styles';

export const LPHero: FC = memo(() => {
    const ref = useRef<HTMLDivElement>(null);
    const { t } = useTranslation();
    const { value } = useParallax(ref);

    return (
        <LPHeroSC.Root>
            <LPTypography.H2 styles={LPHeroSC.TitleCss}>{t('lp_hero_title')}</LPTypography.H2>
            <LPHeroEmailForm />
            <LPTypography.Text styles={LPHeroSC.TextCss}>{t('lp_hero_subtitle')}</LPTypography.Text>
            <LPHeroSC.OverviewContainer ref={ref}>
                <LPHeroSC.OverviewImage
                    srcSet={`${main} 1x, ${mainRetina} 2x`}
                    src={main}
                    alt="overview"
                />
                <LPHeroSC.SupportImage
                    style={{ transform: `translateY(${-value / 16}px)` }}
                    srcSet={`${support} 1x, ${supportRetina} 2x`}
                    src={support}
                    alt="support"
                />
                <LPHeroSC.PreviewImage
                    style={{ transform: `translateY(${-value / 13}px)` }}
                    srcSet={`${preview} 1x, ${previewRetina} 2x`}
                    src={preview}
                    alt="preview"
                />
            </LPHeroSC.OverviewContainer>
        </LPHeroSC.Root>
    );
});
