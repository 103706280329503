import styled from 'styled-components';

import { sizes } from 'components/styles/constants.styles';
import { PrimaryButtonStyle } from 'components/styles/button.styles';

export interface PrimaryButtonStyleProps {
    isLarge?: boolean;
    isLow?: boolean;
}

export const PrimaryButtonSC = styled('button')<PrimaryButtonStyleProps>`
    ${PrimaryButtonStyle};
    text-transform: none;
    min-width: 135px;
    line-height: 120%;

    ${({ isLarge }) =>
        isLarge &&
        `
    padding: ${sizes.md_padding} 0;
    text-transform: uppercase;
    min-width: 278px;
    height: 72px;
  `}

    ${({ isLow }) =>
        isLow &&
        `
    height: 40px;
  `}
`;
