import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/root-types';

import { CmsAcordionSection } from 'units/cms/components/cms-acordion-section/cmsAcordionSection.component';
import { CMSUploader } from 'units/cms/components/cms-uploader/cms-uploader.component';
import { useCMSFormikImageUploadHelper } from 'units/cms/hooks/useCMSImageUploadHelper';
import { UserState } from 'units/user/redux/types';

export const CMSFaviconAccordion: FC = () => {
    const { t } = useTranslation();
    const [favicon, faviconHelper] = useCMSFormikImageUploadHelper('favicon');
    const { user } = useSelector<RootState, UserState>(state => state.user);

    const maxDimension = useMemo(() => {
        if (user?.user_type?.slug === 'immo') {
            return {
                height: 32,
                width: 32,
            };
        }

        return {
            height: 16,
            width: 16,
        };
    }, [user]);

    const handleSaveFavicon = useCallback(
        (files: Array<File>) => faviconHelper.storeImage(files[0]),
        [faviconHelper.storeImage],
    );

    return (
        <CmsAcordionSection title="" lastSection>
            <CMSUploader
                title={t('theme_settings_favicon_description')}
                subtitle={t('theme_settings_max_size', {
                    height: maxDimension.height,
                    width: maxDimension.width,
                })}
                loadingTitle={t('theme_settings_favicon_loading_title')}
                saveFiles={handleSaveFavicon}
                deleteFile={faviconHelper.deleteImage}
                previewFile={favicon || undefined}
                maxDimension={maxDimension}
                dimensionErrorMessage={t('theme_settings_max_size', {
                    height: maxDimension.height,
                    width: maxDimension.width,
                })}
            />
        </CmsAcordionSection>
    );
};
