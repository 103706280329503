import React, { FC } from 'react';
import ReactModal from 'react-modal';

import { Color } from 'components/styles/constants.styles';
import { IconSC, IconUnicodes } from 'components/styles/icons.styles';
import { ReactModalSC } from './modal.styles';

export interface ModalGeneralProps {
  isOpen: boolean;
  title: string;
  onCloseModal: () => void;
}

interface Props extends ModalGeneralProps {
  titlePosition?: 'left' | 'right' | 'center',
  children?: React.ReactNode;
  success?: boolean;
}

ReactModal.setAppElement('#root');

export const Modal: FC<Props> = ({
  title,
  success,
  isOpen,
  titlePosition,
  children,
  onCloseModal: closeModalHandler,
}) => (
  <ReactModalSC.Container as={ReactModal}
    isOpen={isOpen}
    className="Modal"
    onRequestClose={closeModalHandler}
    shouldCloseOnOverlayClick={false}
    style={{
      overlay: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        position: 'fixed',
        backgroundColor: Color.modal_overlay,
      },
    }}
  >
    <ReactModalSC.Title className={titlePosition}>
      <ReactModalSC.Text success={success}>
        {title.split('\n').map(str => <p key={str}>{str}</p>)}
      </ReactModalSC.Text>
      <ReactModalSC.Close onClick={closeModalHandler}>
        <IconSC name={IconUnicodes.close} />
      </ReactModalSC.Close>
    </ReactModalSC.Title>
    {children}
  </ReactModalSC.Container>
);
