import styled from 'styled-components';

import { Color } from 'components/styles/constants.styles';
import { TextStyle3 } from 'components/styles/text.styles';

const ErrorText = styled.span<React.CSSProperties>`
  ${TextStyle3}

  ${({ marginLeft }) => marginLeft && `
    margin-left: ${marginLeft}px;
  `}
  color: ${Color.watermelon};
`;

export const GeneralSC = {
  ErrorText,
};
