import styled from 'styled-components';

import { Font } from 'components/types/enums';
import { Color } from 'components/styles/constants.styles';


const Root = styled.span`
    color: #000;
    tbody {
        tr {
            cursor: pointer;
            &:hover {
                background-color: ${Color.pale_grey}
            }
        }
    }
`;

const Black = styled.span`
    color: #000;
`;

const Bold = styled.span`
    color: #000;
    font-family: ${Font.bold};
    display: inline-block;
    width: 100%;
    text-align: left;
`;

const Empty = styled.div`
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
        margin-bottom: 20px;
    }
`;

export const OrdersSC = {
    Root,
    Black,
    Bold,
    Empty
}
