import { http } from 'api/http';
import { ObjectMapper } from 'api/mappers';
import { ResponseDataType } from 'api/types';
import { ObjectFormValuesType, ObjectImageType, ObjectPreviewItemType } from 'shared/types';

const create = (data: any): Promise<ResponseDataType<{ id: string }>> => {
    const result = ObjectMapper.prepareServerObject(data);

    return http.post('/products/create/', result, {
        Accept: 'application/json',
    });
};

const update = (
    data: any,
    id: string,
): Promise<ResponseDataType<{ id: string; images: ObjectImageType[] }>> => {
    const result = ObjectMapper.prepareServerObject(data);

    return http.put(`/products/${id}/`, result, {
        Accept: 'application/json',
    });
};

const getAll = async (query?: string): Promise<ObjectPreviewItemType[]> => {
    const { data } = await http.get<ObjectPreviewItemType[]>(`/products/list/${query || ''}`);
    return data?.map(ObjectMapper.prepareObjectPreview) || [];
};

const get = async (id: string): Promise<ObjectFormValuesType> => {
    const { data } = await http.get(`/products/${id}`);

    return ObjectMapper.prepareObjectClientData(data);
};

const remove = async (id: string) => {
    http.remove(`/products/${id}/`);
};

const removeImage = async (id: string, productId: string) => {
    http.remove(`/products/${productId}/images/delete/${id}/`);
};

const uploadImages = async (productId: string, pictures: File[]) => {
    const picFormData = new FormData();

    pictures.forEach(async (file, index) => {
        if (file instanceof File) picFormData.append(`picture-${index}`, file, file.name);
    });

    return http.post(`/products/${productId}/images/create/`, picFormData, {
        Accept: 'application/json',
    });
};

const makeImagePrimary = async (id: string) => {
    return http.post(`/products/images/set-primary/${id}/`);
};

export const createObjectService = () => ({
    create,
    update,
    getAll,
    remove,
    removeImage,
    get,
    uploadImages,
    makeImagePrimary,
});
