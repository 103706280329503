import React, { useCallback, PropsWithChildren } from 'react';
import { useField } from 'formik';

import { useFormikHideApiError } from '../hooks/useFormikHideApiError';

export interface IBaseFormikWrapper<T = HTMLInputElement> {
  name: string;
  component: React.ElementType;
  afterChange?: (e: React.ChangeEvent<T>) => void;
  onChange?: (e: React.ChangeEvent<T>) => void;
}

export const BaseFormikWrapper = <T, >({
  afterChange,
  onChange,
	component: Component,
	...props
}: PropsWithChildren<IBaseFormikWrapper<T>>) => {
  const [{ onChange: onChangeDefault, value, ...field}, { error, touched }] = useField(props.name);
  const [hideApiError, setHideApiError] = useFormikHideApiError(props.name);

	const handleChange = useCallback((evt) => {
    onChange ? onChange(evt) : onChangeDefault(evt);

    if (!hideApiError) {
      setHideApiError(true);
    }

    if (afterChange) {
      afterChange(evt);
    }
  }, [afterChange, hideApiError, setHideApiError]);

	return (
    <Component
      {...field}
      value={value ?? ''}
      onChange={handleChange}
      error={touched && error}
      {...props}
    />
	)
}
