const settingsRootUrl = '/settings';

export const AppUrls = {
    settings: {
        index: settingsRootUrl,
        payment: `${settingsRootUrl}/payment`,
        domain: `${settingsRootUrl}/domain`,
        thirdParty: `${settingsRootUrl}/third-party-integrations`,
        emailTemplates: {
            index: `${settingsRootUrl}/email-templates`,
            edit: `${settingsRootUrl}/email-templates/:templateId`,
        },
        account: `${settingsRootUrl}/account`,
        languages: `${settingsRootUrl}/languages`,
        billing: `${settingsRootUrl}/billing`,
        users: {
            index: `${settingsRootUrl}/users`,
            edit: `${settingsRootUrl}/users/:userId`,
        },
    },
};
