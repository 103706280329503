import * as yup from 'yup';

export const signInValidationSchema = yup.object({
  email: yup.string()
    .email('Please input a valid email address')
    .required('Email is required'),
  password: yup.string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters long'),
});
