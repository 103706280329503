import React, { FC, ChangeEvent, useCallback } from 'react';
import { Form, useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import debounce from 'lodash.debounce';

import { SignInSC } from '../sign-in.styles';
import { AuthSC } from 'units/common/styles/auth/auth.styles';
import { RootState } from 'redux/root-types';
import { UserState } from 'units/user/redux/types';
import { SignInValues } from '../sign-in.component';
import { GeneralSC } from 'units/common/styles/general/general.styles';
import { UserErrorKeys } from 'units/user/constants';
import { UserUrls } from 'units/user/urls';
import { useIsFormikSubmitEnabled } from 'units/common/hooks/useIsSubmitEnabled';
import { FormikUserInput } from 'units/common/components/formik/input/user-input.component';
import { useFormikFieldErrors } from 'units/common/hooks/useFieldErrors';
import { Appi18nStrings } from 'config/strings';

interface Props {
    savePrefilledEmail: (email: string) => void;
}

export const SignInForm: FC<Props> = ({ savePrefilledEmail }) => {
    const { status = {} } = useFormikContext<SignInValues>();
    const { error: userError } = useSelector<RootState, UserState>(state => state.user);

    const fieldError = useFormikFieldErrors<SignInValues>();
    const isSubmitEnabled = useIsFormikSubmitEnabled();

    const emailAfterChangeHandler = useCallback(
        debounce(
            (e: ChangeEvent<HTMLInputElement>) => {
                if (e.target) {
                    savePrefilledEmail(e.target.value);
                }
            },
            500,
            { trailing: true },
        ),
        [savePrefilledEmail],
    );

    // Show api error if exist and email or password haven't changed after failure request.
    const showApiError =
        userError?.[UserErrorKeys.login] &&
        !(status.hiddenErrors?.includes('email') || status.hiddenErrors?.includes('password'));

    return (
        <AuthSC.AuthPageForm as={Form}>
            <AuthSC.AuthPageInputList marginBottom="26px">
                <FormikUserInput
                    name="email"
                    label="Email"
                    placeholder="Enter your email"
                    type="email"
                    error={fieldError.email}
                    afterChange={emailAfterChangeHandler}
                />
                <FormikUserInput
                    autoComplete="new-password"
                    name="password"
                    label="Password"
                    placeholder="Enter your password"
                    type="password"
                    error={fieldError.password}
                />
                {/* Start fix chrome autocomplete "off" */}
                <input style={{ display: 'none' }} type="password" name="fakepasswordremembered" />
                {/* End fix chrome autocomplete "off" */}
                {showApiError && (
                    <GeneralSC.ErrorText>
                        {userError?.[UserErrorKeys.login]?.detail[0]}
                    </GeneralSC.ErrorText>
                )}
            </AuthSC.AuthPageInputList>
            <SignInSC.ForgotPasswordLink>
                <Link tabIndex={-1} to={UserUrls.resetPassword}>
                    Forgot password?
                </Link>
            </SignInSC.ForgotPasswordLink>
            <AuthSC.AuthPagePrimaryButton type="submit" disabled={!isSubmitEnabled}>
                {Appi18nStrings.login}
            </AuthSC.AuthPagePrimaryButton>
        </AuthSC.AuthPageForm>
    );
};
