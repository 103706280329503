import { AppUrls } from 'app/urls';

export type NavigationItem = {
    translation:string;
    section?:string | null | undefined;
    url?:string;
}

const header:Array<NavigationItem> = [
    {
        translation: 'lp_header_first_link',
        section: 'benefits'
    },
    {
        translation: 'lp_header_second_link',
        section: 'features'
    },
    {
        translation: 'lp_header_third_link',
        section: 'price'
    },
    {
        translation: 'lp_header_fourth_link',
        section: 'demo' 
    }
]

const footer:Array<NavigationItem> = [...header, ...[
    {
        translation: 'lp_footer_about_link',
        url: 'https://mindnow.io'
    },
    {
        translation: 'lp_footer_help_link',
        url: 'http://ecatch.tawk.help'
    }
]];

const footerSubNav:Array<NavigationItem> = [
    {
        url: AppUrls.landing.imprintPage,
        translation: 'lp_footer_submenu_first_link',
    }, {
        url: AppUrls.landing.termsAndConditions,
        translation: 'lp_footer_submenu_second_link',
    }, {
        url: AppUrls.landing.privacyPolicy,
        translation: 'lp_footer_submenu_third_link',
    }
]

export const Navigation = {
    header,
    footer,
    footerSubNav
}
