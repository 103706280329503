export const EDIT_PRODUCT_UPDATE_LANGUAGE_TAB_FORM = 'EDIT_PRODUCT_UPDATE_LANGUAGE_TAB_FORM';
export const EDIT_PRODUCT_UPDATE_FORM_COMMON_FIELDS = 'EDIT_PRODUCT_UPDATE_FORM_COMMON_FIELDS';
export const EDIT_PRODUCT_INITIALIZE_REDUCER = 'EDIT_PRODUCT_INITIALIZE_REDUCER';
export const EDIT_PRODUCT_CLEAR_REDUCER = 'EDIT_PRODUCT_CLEAR_REDUCER';
export const EDIT_PRODUCT_UPDATE_PICTURES = 'EDIT_PRODUCT_UPDATE_PICTURES';
export const EDIT_PRODUCT_UPDATE_COMMON_FIELDS = 'EDIT_PRODUCT_UPDATE_COMMON_FIELDS';
export const EDIT_PRODUCT_POPULATE_API_DATA = 'EDIT_PRODUCT_POPULATE_API_DATA';
export const EDIT_PRODUCT_MARK_IMAGE_TO_REMOVE = 'EDIT_PRODUCT_MARK_IMAGE_TO_REMOVE';

export const EDIT_PRODUCT_ADD_DESCRIPTIONS_FIELDSET = 'EDIT_PRODUCT_ADD_DESCRIPTIONS_FIELDSET';
export const EDIT_PRODUCT_REMOVE_DESCRIPTIONS_FIELDSET_BY_INDEX = 'EDIT_PRODUCT_REMOVE_DESCRIPTIONS_FIELDSET_BY_INDEX';

export const EDIT_PRODUCT_IS_LOADED = 'EDIT_PRODUCT_IS_LOADED';
export const EDIT_PRODUCT_SET_ERROR = 'EDIT_PRODUCT_SET_ERROR';
export const EDIT_PRODUCT_SET_IS_PUBLISHED = 'EDIT_PRODUCT_SET_IS_PUBLISHED';
