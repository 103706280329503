import { useState, useMemo, useCallback, useContext } from 'react';
import { FormikHelpers, FormikErrors } from 'formik';
import { useDispatch } from 'react-redux';

import { useCMSCustomizationLanguage } from 'units/cms/hooks/customization-tab/useCMSCustomizationLanguage';
import { useCMSCustomizationSettings } from 'units/cms/hooks/selectors/useCMSCustomizationSettings';
import {
    useCMSCustomizationFormikSettings, CreateOverallUpdatesParams, CMSOverallFieldToUpdate
} from 'units/cms/hooks/customization-tab/useCMSCustomizationFormikSettings';
import { CMSCustomizationPage } from 'units/cms/store/types';
import { CompanyLanguages } from 'units/app/redux/types';
import { saveCMSCustomizationSettingsConfigThunk, saveAndPublishCMSCustomizationSettingsConfigThunk } from 'units/cms/store/thunks';
import { isEmptyObject } from 'units/cms/store/helpers';
import { scrollTop } from 'helpers/general';
import { CMSCustomizationOverallSettings } from 'units/cms/store/types/customization';
import { SectionProgress } from 'units/cms/helpers/prepare-customization-progress'
import { NotificationContext } from 'shared/providers';

export type CMSCustomizationSubmitHandler = (
    values: any,
    helpers: Pick<FormikHelpers<any>, 'setSubmitting' | 'validateForm' | 'setErrors' | 'setTouched'>
  ) => void;

type CMSPageProps<D> = {
    defaultActiveSection: string;
    createOverallUpdatesForCMS: (params: CreateOverallUpdatesParams<D>) => Array<CMSOverallFieldToUpdate>,
    preparePageProgress: (overalSettings: CMSCustomizationOverallSettings<D>) => { sectionProgress?: undefined }
        | { sectionProgress: SectionProgress<string, string> }
    CMSPageSectionHeaders: { [key in string]: string },
    page: CMSCustomizationPage
}

export function useCMSPage<D>({
    defaultActiveSection,
    createOverallUpdatesForCMS,
    preparePageProgress,
    CMSPageSectionHeaders,
    page
}: CMSPageProps<D>) {
  const { showNotification } = useContext(NotificationContext);
  const [activeSections, setActiveSections] = useState<Array<string>>([defaultActiveSection]);
  const [selectedLanguage, { changeLanguage, defaultLanguage }] = useCMSCustomizationLanguage();
  const initialPageOverallSettings = useCMSCustomizationSettings(page);
  const [overallSettings, { updateSettings }] = useCMSCustomizationFormikSettings<D>(
    initialPageOverallSettings as D,
    createOverallUpdatesForCMS,
  );
  const dispatch = useDispatch();
  // ----- Memoized values
  const sectionProgress = useMemo(
    () => preparePageProgress(initialPageOverallSettings as D).sectionProgress,
    [initialPageOverallSettings],
  );
  // Formik values from overall settings based on selected language.
  const formikInitialValues = useMemo(
    () => overallSettings[selectedLanguage],
    [selectedLanguage, overallSettings],
  );

  // ----- Form language
  const handleLanguageChange = useCallback((
    language: CompanyLanguages,
    selectedLanguageSettings: D,
    { resetForm }: Pick<FormikHelpers<any>, 'resetForm'>,
  ) => {
    if (language === selectedLanguage) {
      return;
    }
    
    const updatedOverallSettings = updateSettings(selectedLanguageSettings, selectedLanguage);
    // Reset form with settings of new selected language
    resetForm({
      values: updatedOverallSettings[language],
    });
    changeLanguage(language);
  }, [selectedLanguage, updateSettings, changeLanguage]);

  // ----- Submitting
  // Call direct from save button. Need to alow save in any time and without filling all required fields.
  // Skiping formik pre-validation and validation.
  const handleSaveForm: CMSCustomizationSubmitHandler = useCallback((values, helpers) => {
    helpers.setSubmitting(true);
  
    const finalOverallSettings = updateSettings(values, selectedLanguage);
    dispatch(saveCMSCustomizationSettingsConfigThunk(page, finalOverallSettings, showNotification));

    helpers.setSubmitting(false);
  }, [selectedLanguage, updateSettings]);


  // Call direct from save and publish button.
  // Need to validate each language form and find first language and section error and show user.
  const handleSaveAndPublishForm: CMSCustomizationSubmitHandler = useCallback(async (values, helpers) => {
    helpers.setSubmitting(true);
    helpers.setTouched(values);
    
    const finalOverallSettings = updateSettings(values, selectedLanguage);

    const languageErrorList = await Promise.all(Object.entries(finalOverallSettings)
      .map(([lng, settings]) => new Promise<{ lng: CompanyLanguages, errors: FormikErrors<any> }>((res) => {
        helpers.validateForm(settings).then(errors => res({
          lng: lng as CompanyLanguages,
          errors,
        }))
      }))
    );

    // Find selected language errors or fist from list.
    const languageFormError = languageErrorList.find(le => le.lng === selectedLanguage && !isEmptyObject(le.errors))
      || languageErrorList.find(le => !isEmptyObject(le.errors));
    
    if (languageFormError) {
      const { errors, lng } = languageFormError;
      const firstSectionWithErrors = Object.keys(languageFormError.errors)[0];

      // Set errors and touched after language changed.
      setTimeout(() => {
        helpers.setErrors(errors);
        helpers.setTouched(errors as any);
      }, 0);

      scrollTop();

      // If section with error is not expanded or there are several expanded sections.
      if (!activeSections.every(s => s === firstSectionWithErrors)) {
        setActiveSections([firstSectionWithErrors]);
      }

      changeLanguage(lng as CompanyLanguages);

      showNotification('error', `Please, update ${(CMSPageSectionHeaders as { [key in string]: any })[firstSectionWithErrors]} before publish`);
    } else {
      dispatch(saveAndPublishCMSCustomizationSettingsConfigThunk(page, finalOverallSettings, showNotification));
    }

    helpers.setSubmitting(false);
  }, [updateSettings, selectedLanguage, changeLanguage, setActiveSections, activeSections]);  

  // ----- Handle accordions
  const handleAccordionExpandedChange = useCallback((
    expanded: Array<string>,
    languageSettings: D,
    helpers: Pick<FormikHelpers<D>, 'resetForm'>,
  ) => {
      setActiveSections(expanded as Array<string>);
      handleLanguageChange(defaultLanguage, languageSettings, helpers);
    },
    [setActiveSections, defaultLanguage, handleLanguageChange],
  );

  return {
    formikInitialValues,
    selectedLanguage,
    activeSections,
    initialPageOverallSettings,
    handleSaveForm,
    handleSaveAndPublishForm,
    sectionProgress,
    handleAccordionExpandedChange,
    handleLanguageChange
  }
}
