import React, { FC } from 'react';
import { StatusCard, Typography } from '../../../../shared/components/ui';
import WalletIcon from '../../../../assets/images/wallet.svg';
import EarthIcon from '../../../../assets/images/earth.svg';
import PersonIcon from '../../../../assets/images/person.svg';
import styles from './ShortInfo.module.scss';
import { AuctionData } from 'units/analytics/pages/types';
import clsx from 'clsx';

interface ShortInfoProps {
    data: AuctionData | null;
}

export const ShortInfo: FC<ShortInfoProps> = ({ data }) => {
    return (
        <div className={styles.grid}>
            <StatusCard
                title={'Optimal price span'}
                mainLabel={data?.optimal_price ? `CHF ${data?.optimal_price || '0.00'}` : 'No data'}
                icon={WalletIcon}
                className={clsx(styles.card, !data?.optimal_price && styles['is-empty'])}
                footer={
                    data?.winning_price && data?.status?.display_name === 'Ended' ? (
                        <Typography.Text className={styles.balance}>
                            {`Winning bid CHF ${data?.winning_price}`}
                        </Typography.Text>
                    ) : null
                }
            />
            <StatusCard
                title={'Optimal age span'}
                mainLabel={data?.optimal_age_span ? `${data?.optimal_age_span} y.o.` : 'No data'}
                icon={PersonIcon}
                className={clsx(styles.card, !data?.optimal_age_span && styles['is-empty'])}
                footer={
                    data?.age ? (
                        <Typography.Text className={styles.balance}>
                            {`${data?.age?.setup} of ${data?.age?.total_number} set the age`}
                        </Typography.Text>
                    ) : null
                }
            />
            <StatusCard
                title={'Highest paying region'}
                mainLabel={`${data?.highest_bidding_region || 'No data'}`}
                icon={EarthIcon}
                className={clsx(styles.card, !data?.highest_bidding_region && styles['is-empty'])}
            />
        </div>
    );
};
