import * as React from 'react';
import { Switch, Route, RouteComponentProps } from 'react-router-dom';

import { AuctionUrls } from './urls';
import { Auctions } from './pages/index/auctions.component';
import { CreateAuction } from './pages/create-auction/create-auction.component';

export const AuctionRoutes: React.FC<RouteComponentProps> = () => (
  <Switch>
    <Route exact path={AuctionUrls.index} component={Auctions} />
    <Route exact path={AuctionUrls.createAuction} component={CreateAuction} />
    <Route exact path={AuctionUrls.editAuction} component={CreateAuction} />
    <Route exact path={AuctionUrls.viewAuction} component={CreateAuction} />
  </Switch>
);
