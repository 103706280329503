import React, { FC, useMemo } from 'react';
import { Form, useFormikContext, FormikValues } from 'formik';
import deepEqual from 'deep-equal';

import { Checkbox } from 'units/common/components/checkbox/choose-languages-checkboxes/choose-languages-checkboxes';
import { languages } from 'units/app/constants';

import { LanguageSC } from './language.styles';
import { useIsSubmitEnabled } from 'units/common/hooks/useIsSubmitEnabled';

interface Props {
  savedValues: Array<string>;
}

export const ChooseLanguage: FC<Props> = ({ savedValues = [] }) => {
  const { values, errors } = useFormikContext<FormikValues>();
  const isSubmitEnabled = useIsSubmitEnabled(errors, values);
  const isChanged = useMemo(() => !deepEqual([...savedValues].sort(), [...values.languages].sort()), [savedValues, values]);
  const disabled = useMemo(() => !isSubmitEnabled || !isChanged, [isSubmitEnabled, isChanged]);
  return (
    <>
      <LanguageSC.InputRequirement>
          Min. one language is required
      </LanguageSC.InputRequirement>
      <LanguageSC.LanguageForm as={Form}>
        <Checkbox languages={languages} noErrorMsg />
        <LanguageSC.SaveButton type="submit" disabled={disabled}>Save</LanguageSC.SaveButton>
      </LanguageSC.LanguageForm>
    </>
  );
};
