import { ApiClient, apiPath } from 'config/api/api';
import { CMSTheme, CMSThemeId, CMSImageToSave, CMSUploadedImage } from './types';
import { CMSThemeSettings, CMSThemeSettingsResponse } from './types/theme-settings';
import { limitFileName } from 'helpers/general';
import { CMSFetchCustomizationConfigResponse, CMSCustomizationConfig } from './types/customization';

const cmsPath = `${apiPath}/cms`;

type CMSFetchGoogleFontsResponse = {
    items: Array<{
        category: string;
        family: string;
        variants: string[];
    }>;
    kind: string;
};

type CMSFetchThemeListResponse = Array<CMSTheme>;

const fetchCMSGoogleFonts = async () => {
    const api = ApiClient.getInstance();
    // const apiKey = 'AIzaSyAawkJ_k9ecLP-lvREwbJ1w1eFpvYISVPo';
    const apiKey = 'AIzaSyD3d0i3PQJPx3jxCcMzLdM4otTdGtsYyoY';
    const { data } = await api.get<CMSFetchGoogleFontsResponse>(
        `https://www.googleapis.com/webfonts/v1/webfonts?key=${apiKey}`,
    );
    return data;
};

// -------------------------
// Themes tab

const fetchThemeList = async () => {
    const api = ApiClient.getInstance();

    const { data } = await api.get<CMSFetchThemeListResponse>(`${cmsPath}/themes-list/`);
    return data;
};

const saveActiveTheme = async (themeId: CMSThemeId) => {
    const api = ApiClient.getInstance();

    await api.put<Array<void>>(`${cmsPath}/active-theme/`, { theme: themeId });
};

// Theme settings tab

const fetchThemeSettingsList = async () => {
    const api = ApiClient.getInstance();

    const { data } = await api.get<Array<CMSThemeSettingsResponse>>(
        `${cmsPath}/draft-theme-settings/list/`,
    );
    return data;
};

const saveThemeSettings = async (themeId: CMSThemeId, themeSettings: CMSThemeSettings) => {
    const api = ApiClient.getInstance();

    const { data } = await api.put<CMSThemeSettingsResponse>(
        `${cmsPath}/draft-theme-settings/${themeId}/`,
        {
            settings: themeSettings,
        },
    );

    return data;
};

const publishThemeSettings = async (themeId: CMSThemeId, themeSettings: CMSThemeSettings) => {
    const api = ApiClient.getInstance();

    const { data } = await api.put<CMSThemeSettingsResponse>(
        `${cmsPath}/published-theme-settings/${themeId}/`,
        {
            settings: themeSettings,
        },
    );

    return data;
};

// Customization tab

const fetchCustomizationConfig = async () => {
    const api = ApiClient.getInstance();

    const { data } = await api.get<CMSFetchCustomizationConfigResponse>(
        `${cmsPath}/draft-page-customization/`,
    );
    return data;
};

const saveCustomizationConfig = async (config: Partial<CMSCustomizationConfig>) => {
    const api = ApiClient.getInstance();

    api.patch(`${cmsPath}/draft-page-customization/`, { settings: config });
};

const saveAndPublishCustomizationConfig = async (config: Partial<CMSCustomizationConfig>) => {
    const api = ApiClient.getInstance();

    api.patch(`${cmsPath}/published-page-customization/`, { settings: config });
};

// Additional services

const uploadCMSImages = async (images: Array<CMSImageToSave>) => {
    if (!images.length) {
        return [];
    }

    const api = ApiClient.getInstance();
    const formData = new FormData();

    images.forEach(async ({ key, image }) => {
        const name = limitFileName(image.name);
        formData.set(key, image, name);
    });

    const { data } = await api.post<Array<CMSUploadedImage>>(`${cmsPath}/upload-images/`, formData);

    return data;
};

const deleteCMSImages = async (imageIds: Array<string>) => {
    const api = ApiClient.getInstance();

    await api.delete<void>(`${cmsPath}/delete-images/`, {
        data: {
            ids: imageIds,
        },
    });
};

// -------------------------

export const cmsRootServices = {
    fetchCMSGoogleFonts,
    fetchThemeList,
    fetchThemeSettingsList,
    fetchCustomizationConfig,
    saveActiveTheme,
    saveThemeSettings,
    saveCustomizationConfig,
    saveAndPublishCustomizationConfig,
    publishThemeSettings,
    uploadCMSImages,
    deleteCMSImages,
};
