import styled from 'styled-components';

import { TransparentButton } from 'units/common/components/buttons/transparent/transparent.component';
import { FormInputSubLabelText } from 'components/styles/text.styles';

const ArrayIndicator = styled.span`
  ${FormInputSubLabelText}

  font-size: 16px;
  line-height: 16px;
  margin-left: 8px;
`;

const AddDescriptionButton = styled(TransparentButton)`
  position: absolute;
  right: -22px;
  top: -12px;
  font-size: 16px;
`;

export const ProductDescriptionSC = {
  AddDescriptionButton,
  ArrayIndicator,
};
