import styled from 'styled-components';

import { LogoText, TextStyle8, TextStyle13, TextStyle14 } from 'components/styles/text.styles';
import { Color, sizes } from 'components/styles/constants.styles';

const HoveredContainer = styled.div`
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: ${sizes.border_radius};
  background-color: ${Color.modal_overlay};
  top: 0;
`;

const ButtonContainer = styled.div`
  position: absolute;
  display: grid;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  button {
    color: ${Color.white};
    border: solid 2px ${Color.white};
    margin: 8px;

    &:hover {
      background-color: unset;
    }
  }

  span {
    margin-right: 5px;
  }
`;

const Container = styled.div`
  /* max-width: 370px; */
  position: relative;
  background: ${Color.white};
  border-radius: ${sizes.border_radius};

  &:hover ${HoveredContainer} {
    display: block;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 240px;
  display: block;
  object-fit: cover;
  border-top-left-radius: ${sizes.border_radius};
  border-top-right-radius: ${sizes.border_radius};
`;

const Details = styled.div`
  padding: 10px 20px 20px 20px;
`;

const Name = styled.h1`
  ${LogoText};
  margin-bottom: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 25px;
`;

const Status = styled.div`
  ${TextStyle8};
  color: white;
  width: fit-content;
  border-radius: ${sizes.border_radius};
  padding: 3px 8px;
  line-height: normal;
  margin-bottom: 10px;

  &.published {
    background: ${Color.orange};
  }

  &.unpublished {
    background: ${Color.watermelon};
  }
`;

const NumberBlock = styled.div`
  ${TextStyle14};
  min-width: 48px;
  height: 48px;
  padding: 0 8px;

  line-height: 48px;
  text-align: center;
  
  background: ${Color.pale_grey};
  border-radius: ${sizes.border_radius};
`;

const Info = styled.div`
  ${TextStyle13};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  color: ${Color.black};

  &.no-margin {
    margin: 0;
  }
`;

const Timer = styled.div`
  display: flex;
  align-items: center;

  .colon {
    ${TextStyle14};
    margin: 2px;
  }
`;

export const ProductItemSC = {
  Container,
  Image,
  Details,
  Name,
  Status,
  Info,
  NumberBlock,
  Timer,
  HoveredContainer,
  ButtonContainer,
};
