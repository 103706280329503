import React, { FC } from 'react';

import {
  CMSCustomizationLanguageProgressContainer,
  CMSCustomizationLanguageProgressContainerProps,
} from '../../../progress-container/progress-container';
import { CMSProductDetailPageHeroAccordion } from './hero-accordion.component';
import { CmsAcordionSection } from 'units/cms/components/cms-acordion-section/cmsAcordionSection.component';

type Props = Pick<CMSCustomizationLanguageProgressContainerProps, 'selectedLanguage' | 'onLanguageChange' | 'initialProgress'>;

export const CMSProductDetailPageHeroAccordionContainer: FC<Props> = (props) => (
    <CmsAcordionSection lastSection>
      <CMSCustomizationLanguageProgressContainer
        {...props}
      >
        {({ updateCurrentProgress }) => (
          <CMSProductDetailPageHeroAccordion updateProgress={updateCurrentProgress} />
        )}
      </CMSCustomizationLanguageProgressContainer>
    </CmsAcordionSection>
);
