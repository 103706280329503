import React, { FC } from 'react';

import { CircleProgressGroupSC } from './circle-progress-group.style';
import { CircleProgress } from '../circle-progress.style';

export type CircleProgressType = {
  id: string,
  value: number;
  text: string;
}

export interface CircleProgressGroupProps {
  progressList: Array<CircleProgressType>;
  onClick: (id: string) => void;
  selectedId?: string;
  circleMargin?: string;
}

const correctProgressValue = (value: number) => {
  if (!value || value < 0) {
    return 0;
  } else if (value > 1) {
    return 1;
  }

  return value;
}

export const CircleProgressGroup: FC<CircleProgressGroupProps> = ({
  progressList,
  onClick,
  selectedId,
  circleMargin,
}) => (
  <CircleProgressGroupSC.Group>
    { progressList.map((p) => (
      <CircleProgressGroupSC.CircleWrapper
        onClick={onClick.bind(null, p.id)}
        key={`progress-${p.id}`}
        isSelected={selectedId === p.id}
        marginRight={circleMargin}
      >
        <CircleProgress
          value={correctProgressValue(p.value)}
          text={p.text}
        />
      </CircleProgressGroupSC.CircleWrapper>
    ))}
  </CircleProgressGroupSC.Group>
);
