export const generateId = () => Math.random().toString(36).substr(2, 9);

type GetImgSizeCallback = (size: { width: number; height: number }) => void;

// Returns the original dimensions of an image file via callback
export const getImgSize = (imgSrc: string, callback: GetImgSizeCallback) => {
    const newImg = new Image();

    newImg.onload = function () {
        const height = newImg.height;
        const width = newImg.width;
        callback({ width, height });
    };

    // This must be done AFTER setting onload
    newImg.src = imgSrc;
};

export const scrollTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
};

export const limitFileName = (name: string, maxLength = 90) => {
    const fileNamePieces = name.split('.');
    const extension = fileNamePieces.pop();
    let fileName = fileNamePieces.join('');

    if (fileName.length > maxLength) {
        fileName = fileName.substring(0, maxLength);
    }

    return `${fileName}.${extension}`;
};
