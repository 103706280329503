import React, { useCallback, FC } from 'react';
import { useFormikContext, FormikHelpers } from 'formik';

import { Accordion } from 'units/common/components/accordion/accordion.component';
import { CMSMissedAuctionsCustomizationSettings } from 'units/cms/store/types/customization/missed-auctions';
import { CMSCustomizationLanguageProgressContainerProps } from '../../../progress-container/progress-container';
import { SectionProgress } from 'units/cms/helpers/prepare-customization-progress';
import { CMSCustomizationMissedAuctionsSectionName } from 'units/cms/types';
import { CMSMissedAuctionsGeneralAccordionContainer } from './general-accordion.container';
import { CMSMissedAuctionsSectionHeaders } from '..';

type SectionCommonProps = Pick<CMSCustomizationLanguageProgressContainerProps, 'selectedLanguage' | 'onLanguageChange'>;

type Props = SectionCommonProps & {
  sectionProgress: SectionProgress<CMSCustomizationMissedAuctionsSectionName, any>;
  activeSections: Array<string>;
  handleAccordionExpandedChange: (
    expanded: Array<string>,
    languageSettings: CMSMissedAuctionsCustomizationSettings,
    { resetForm }: Pick<FormikHelpers<CMSMissedAuctionsCustomizationSettings>, 'resetForm'>,
  ) => void;
}

export const CMSCustomizationMissedAuctionsAccordions: FC<Props> = ({
  handleAccordionExpandedChange,
  sectionProgress,
  activeSections,
  ...sectionCommonProps
}) => {
  const { values, resetForm } = useFormikContext<CMSMissedAuctionsCustomizationSettings>();

  const accordionOnChange = useCallback((expanded: Array<string>) => {
    handleAccordionExpandedChange(expanded, values, { resetForm });
  }, [handleAccordionExpandedChange, values, resetForm]);

  return (
    <Accordion
      onChange={accordionOnChange}
      items={[
        {
          header: CMSMissedAuctionsSectionHeaders.general,
          uuid: 'general',
          content: (
            <CMSMissedAuctionsGeneralAccordionContainer
              initialProgress={sectionProgress.general}
              {...sectionCommonProps}
            />
          )
        }
      ]}
      preExpanded={['general']}
    />
  );
}
