import * as yup from 'yup';

import { UserInputValidationErrors } from 'units/common/constants';

export const resetPasswordEmailValidationSchema = yup.object({
  email: yup.string()
    .email('Please input a valid email address')
    .required('Email is required'),
});

export const resetPasswordNewPasswordValidationSchema = yup.object({
  password: yup.string()
    .min(8, UserInputValidationErrors.password.min)
    .required(UserInputValidationErrors.password.required)
    .matches(/[0-9]/, UserInputValidationErrors.password.oneNumber)
    .matches(/[a-z]/, UserInputValidationErrors.password.oneLowerCase)
    .matches(/[A-Z]/, UserInputValidationErrors.password.oneUpperCase)
    .matches(/(?=.*?[#?!@$%^&*-])/, UserInputValidationErrors.password.oneSpecialCharacter),
  repeatPassword: yup.string()
    .oneOf([yup.ref('password')], UserInputValidationErrors.repeatPassword.match)
    .required(UserInputValidationErrors.repeatPassword.required),
});
