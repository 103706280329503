import { useFormikContext } from 'formik';

// import { ErrorState } from redux/types';

type Errors = {
  [key: string]: Array<string> | undefined;
} | null | undefined;

export const useFormikApiErrors = (errors: Errors) => {
  const { status } = useFormikContext();

  const hiddenApiErrors: Array<string> = status?.hiddenErrors || [];

  if (!errors || !hiddenApiErrors.length) {
    return errors;
  }
  
  const apiErrors = Object.keys(errors).reduce((result, errKey) => {
    if (hiddenApiErrors.includes(errKey)) {
      return result;
    }

    return {
      ...result,
      [errKey]: errors[errKey],
    }
  }, {});

  return apiErrors;
};
