import React from 'react';
import { AccountSC } from '../../account/account.styles';
import { Form, useFormikContext } from 'formik';
import { FormikFormInput } from '../../../../common/components/formik/input/form-input.component';
import { FormikSelect } from '../../../../common/components/formik/select/select.component';
import { BillingData } from '../types';
import { BillingSC } from './billing-form.styles';
import { useIsSubmitEnabled } from '../../../../common/hooks/useIsSubmitEnabled';
import { useCountries } from 'units/common/hooks/useCountries';
// import { useSelector } from 'react-redux';
// import { RootState } from '../../../../../redux/root-types';
// import { useFormikApiErrors } from '../../../../common/components/formik/hooks/useFormikApiErrors';
// import { UserErrorKeys } from '../../../../user/constants';
// import { useFormikFieldErrors } from '../../../../common/hooks/useFieldErrors';

export const BillingForm = () => {
    const { values, errors } = useFormikContext<BillingData>();
    // const { error: userError } = useSelector<RootState, UserState>((state) => state.user);
    //
    // const apiErrors = useFormikApiErrors(userError?.[UserErrorKeys.updateMyAccountData]);
    // const fieldError = useFormikFieldErrors<UpdateUserAccountData>({ additionalErrors: apiErrors });
    const isSubmitEnabled = useIsSubmitEnabled(errors, values);
    const countries = useCountries();
    return (
        <AccountSC.FormElement as={Form}>
            <FormikFormInput
                name="street"
                label="Street"
                placeholder="Enter your street name"
                withMargins
                widthPercents={80}
                // error={fieldError.firstName}
            />
            <FormikFormInput
                name="street_number"
                label="Number"
                placeholder="Enter a building number"
                withMargins
                widthPercents={20}
                // error={fieldError.firstName}
            />
            <BillingSC.SelectWrap>
                <FormikSelect
                    options={countries}
                    label="Country"
                    name="country"
                    placeholder="Choose your country"
                />
            </BillingSC.SelectWrap>

            <FormikFormInput
                name="city"
                label="City"
                placeholder="Enter your city"
                withMargins
                widthPercents={33.3}
                // error={fieldError.firstName}
            />
            <FormikFormInput
                name="zip_code"
                label="Zip Code"
                placeholder="Enter your ZIP code"
                withMargins
                widthPercents={33.3}
                // error={fieldError.firstName}
            />
            <AccountSC.SaveButton type="submit" disabled={!isSubmitEnabled}>
                Save
            </AccountSC.SaveButton>
        </AccountSC.FormElement>
    );
};
