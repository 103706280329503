import styled from 'styled-components';

import { FormSubLabelText } from 'components/styles/text.styles';

const Subtitle = styled.h2`
  ${FormSubLabelText}

  margin: 0;
  margin-bottom: 16px;
`;

export const ProductSeoFormSC = {
  Subtitle,
};
