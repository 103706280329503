import { useState, useCallback, useEffect } from 'react';
import update from 'immutability-helper';

import { CompanyLanguages } from 'units/app/redux/types';
import { CMSCustomizationOverallSettings } from '../../store/types/customization';
import { updateSpecObj } from 'units/cms/store/helpers/customization';

export const useCMSCustomizationFormikSettings = <S>(
    initialOverallSettings: CMSCustomizationOverallSettings<S>,
    createOverallUpdates: (params: CreateOverallUpdatesParams<S>) => Array<CMSOverallFieldToUpdate>,
): CMSCustomizationFormikSettingsReturn<S> => {
    const [overallSettings, setOverallSettings] = useState<CMSCustomizationOverallSettings<S>>(
        initialOverallSettings,
    );

    // Reinitialize
    useEffect(() => {
        setOverallSettings(initialOverallSettings);
    }, [initialOverallSettings]);

    // internal handlers

    const _updateOverallSettings = useCallback(
        (overallSettings: CMSCustomizationOverallSettings<S>) => {
            setOverallSettings(overallSettings);
            return overallSettings;
        },
        [],
    );

    const _updateSettingsByLanguage = useCallback(
        (lng: CompanyLanguages, languageSettings: S) => {
            const overallSettingsUpdated = { ...overallSettings };
            overallSettingsUpdated[lng] = languageSettings;

            //const overallSettingsUpdated = update(overallSettings, { [lng]: { $set: languageSettings }});
            //console.log('_updateSettingsByLanguage', overallSettingsUpdated, overallSettings, languageSettings, lng);
            return _updateOverallSettings(overallSettingsUpdated);
        },
        [overallSettings, _updateOverallSettings],
    );

    // api handlers

    const getSettings = useCallback((lng: CompanyLanguages) => overallSettings[lng] || null, [
        overallSettings,
    ]);

    const handleUpdateSettings = useCallback(
        (newSettings: S, lng: CompanyLanguages) => {
            const currentSettings = overallSettings[lng];

            if (!currentSettings) {
                return _updateSettingsByLanguage(lng, newSettings);
            }

            const overallUpdates: Array<CMSOverallFieldToUpdate> = createOverallUpdates({
                currentSettings,
                newSettings,
            });

            let updatedOverallSettings = _updateSettingsByLanguage(lng, newSettings);

            if (overallUpdates.length) {
                updatedOverallSettings = getUpdatedFieldsInOverallSettings(
                    updatedOverallSettings,
                    overallUpdates,
                );

                updatedOverallSettings = _updateOverallSettings(updatedOverallSettings);
            }

            return updatedOverallSettings;
        },
        [overallSettings, _updateSettingsByLanguage, _updateOverallSettings, createOverallUpdates],
    );

    return [
        overallSettings,
        {
            updateSettings: handleUpdateSettings,
            getSettings,
        },
    ];
};

// ----- Types

export type CreateOverallUpdatesParams<S> = {
    currentSettings: S;
    newSettings: S;
};

export type CMSOverallFieldToUpdate = {
    path: string;
    value: any;
};

type CMSCustomizationFormikSettingsReturn<S> = [
    CMSCustomizationOverallSettings<S>,
    {
        updateSettings: (settings: S, lng: CompanyLanguages) => CMSCustomizationOverallSettings<S>;
        getSettings: (lng: CompanyLanguages) => S | null;
    },
];

// ----- Helpers

// Returns updated fields in overall settings for each language
const getUpdatedFieldsInOverallSettings = <S>(
    overallSettings: CMSCustomizationOverallSettings<S>,
    updates: Array<CMSOverallFieldToUpdate>,
) => {
    const spec = {} as { [key in string]: any };
    Object.keys(overallSettings).forEach(lang => {
        overallSettings[lang as CompanyLanguages] &&
            updates.forEach(update => {
                const orgPath = `${lang}.${update.path}`;
                updateSpecObj(spec, overallSettings, orgPath, update.value);
            });
    });
    return update(overallSettings, spec);
};
