import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { userReducer } from 'units/user/redux/reducer';
import { appReducer } from 'units/app/redux/reducer';
import { editProductReducer } from 'units/products/pages/product/store/reducer';
import { cmsRootReducer } from 'units/cms/store/reducer';

import { settingsReducer } from 'store/reducers';

const persistAuthReducerConfig = {
    key: 'user',
    storage,
    whitelist: ['token', 'user', 'tempPayload'],
};

export const rootReducer = combineReducers({
    app: appReducer,
    user: persistReducer(persistAuthReducerConfig, userReducer),
    editProduct: editProductReducer,
    cms: cmsRootReducer,
    settings: settingsReducer,
});
