import React from 'react';

import { CmsAcordionSection } from 'units/cms/components/cms-acordion-section/cmsAcordionSection.component';
import { FormikColorPicker } from 'units/common/components/formik/color-picker/color-picker.component';

export const CMSShopModalsGeneralAccordion = () => (
  <CmsAcordionSection title="Format your forms" withDevider={false}>
      <FormikColorPicker
        label="Placeholder text color"
        name="general.placeholder"
      />
      <FormikColorPicker
        label="Border"
        name="general.border"
      />
      <FormikColorPicker
        label="Background"
        name="general.background"
      />
  </CmsAcordionSection>
);
