import React, { FC } from 'react';

import { CmsAcordionSection } from 'units/cms/components/cms-acordion-section/cmsAcordionSection.component';
import { TrackProgressField } from 'units/common/hooks/useTrackProgress';
/* import { FormikFormTextArea } from 'units/common/components/formik/textarea/form-textarea.comonent';
import { CMSUploader } from 'units/cms/components/cms-uploader/cms-uploader.component';
import { useCMSFormikImageUploadData } from 'units/cms/hooks/useCMSFormikImageUploadData'; */
import { FormikFormInput } from 'units/common/components/formik/input/form-input.component';

type Props = {
  updateProgress: (field: TrackProgressField) => void;
}

export const CMSShopModalsResetPasswordAccordion: FC<Props> = ({ updateProgress }) => (
      <CmsAcordionSection
        sectionMargin={32}
        withDevider={false}
        title="Enter password screen"
      >
       <FormikFormInput
          name="resetPassword.title"
          label="Title"
          placeholder="Reset password"
          paddingBottom="0"
          afterChange={({ target }) => {
            updateProgress({ name: 'title', isFilled: !!target.value });
          }}
        />
        <FormikFormInput
          name="resetPassword.button"
          label="Reset password button text"
          placeholder="Reset password"
          paddingBottom="0"
          afterChange={({ target }) => {
            updateProgress({ name: 'button', isFilled: !!target.value });
          }}
        />
      </CmsAcordionSection>
    );
