/* eslint-disable import/named */
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
/* eslint-enable import/named */

import { Color } from 'components/styles/constants.styles';
import { LPButtonTextStyle } from 'components/styles/text.styles';
import { MediaQueries } from 'units/landing-page/config';

type ButtonsProps = {
  styles?: FlattenSimpleInterpolation;
}

const BorderRadius = css`
  border-radius: 16px;

  @media (max-width: ${MediaQueries.mobile}) {
    border-radius: 12px;
  }
`;

const DefaultStyles = css`
  ${BorderRadius}
  outline: none;
  cursor: pointer;
  ${LPButtonTextStyle}

  @media (max-width: ${MediaQueries.tablet}) {
    font-size: 14px;
  }
`;

const Primary = styled.button<ButtonsProps>`
  position: relative;
  
  transform: scale(1);
  border: none;
  cursor: pointer;
  outline: none;
  background-color: transparent;
  
  color: ${Color.white};

  span {
    position: relative;
    display: block;
    padding: 25px 35px;

    background-color: ${Color.watermelon};
    ${DefaultStyles}
    cursor: inherit;
  }

  &:after {
    content: '';

    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    transition: all 300ms ease-out;
    transform-origin: left;
    background-color: ${Color.black};
    ${BorderRadius}
  }

  &:hover {
    &:after {
      transform: skewY(2deg);
    }
  }

  &:disabled {
    cursor: not-allowed;
    opacity: .5;

    &:hover:after {
      transform: unset;
    }
  }

  @media (max-width: ${MediaQueries.tablet}) {
    span {
      padding: 16px 40px;
    }
  }

  @media (max-width: ${MediaQueries.mobile}) {
    span {
      padding: 8px 40px;
    }
  }

  ${props => props.styles}
`;

const Outline = styled.button<ButtonsProps>`
  position: relative;
  border: 2px solid ${Color.black};
  color: ${Color.black};
  overflow: hidden;
  background-color: transparent;
  padding: 14px 30px;

  ${DefaultStyles}

  &:after {
    content: '';
    position: absolute;
    ${BorderRadius}
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    transition: all 300ms ease-out;
    transform: translate(-50%, -50%);
    background-color: ${Color.black};
  }

  span {
    position: relative;
    z-index: 5;
    transition: color 300ms ease-out;
    display: flex;
    align-items: center;
  }

  &:hover {
    span {
      color: ${Color.white};
      transition: color 300ms ease-out;
    }
    &:after {
      width: 105%;
      height: 105%;
    }
  }

  @media (max-width: ${MediaQueries.tablet}) {
    padding: 6px 23px;
  }

  ${props => props.styles}
`;

const OutlineHoverBg = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  transform: translate(-50%, -50%);
  background-color: ${Color.black};
`

export const LPButtonSC = {
    Primary,
    Outline,
    OutlineHoverBg
}
