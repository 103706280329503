import { http } from 'api/http';
import { ResponseDataType } from 'api/types';

const getAuctionImages = async (): Promise<ResponseDataType<any>> => {
    return http.get('/cms/get-images/picture/');
};

const uploadAuctionImages = async (images: File[]): Promise<ResponseDataType<any>> => {
    const picFormData = new FormData();

    images.forEach(async (file, index) => {
        if (file instanceof Blob) picFormData.set(`picture-${index}`, file, file.name);
    });

    return http.post('/cms/upload-images/', picFormData, {
        Accept: 'application/json',
    });
};

const removeAuctionImages = async (ids: string[]): Promise<ResponseDataType<any>> => {
    return http.remove('/cms/delete-images/', { ids });
};

export const customizationService = () => ({
    getAuctionImages,
    uploadAuctionImages,
    removeAuctionImages,
});
