import React from 'react';
import clsx from 'clsx';

import { Icon } from 'shared/components/ui';

import { useNavigate } from 'shared/hooks';

import styles from './BackLink.module.scss';
import { useTranslation } from 'react-i18next';

type BackLinkProps = {
    className?: string;
    backTo: string;
    url: string;
};

export const BackLink: React.FC<BackLinkProps> = ({ className, url, backTo }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <div className={clsx(styles.root, className)} onClick={() => navigate(url)}>
            <Icon name="arrow-left" />
            <span>
                {t('settings_go_back')} {backTo}
            </span>
        </div>
    );
};
