import styled from 'styled-components';

import { Color, sizes } from 'components/styles/constants.styles';
import { TextStyle13 } from 'components/styles/text.styles';

export const PaginationSC = styled.div`
    .pagination {
        ${TextStyle13};
        display: flex;
        margin: ${sizes.md_padding} 0;
        list-style: none;
        justify-content: center;
        margin-top: auto;

        a {
            padding: 12px;
            color: ${Color.bluey_grey};

            &:focus {
                outline: none;
            }
        }

        li {
            cursor: pointer;

            &.active a,
            &.next:not(.disabled) a,
            &.previous:not(.disabled) a {
                color: ${Color.black};
                font-weight: 700;
            }

            &.disabled a {
                color: ${Color.bluey_grey};
            }

            &.next a,
            &.previous a {
                font-size: 14px;
            }
        }
    }
`;
