import React, { FC } from 'react';
import { Card, Typography } from 'shared/components/ui';
import styles from './PieChart.module.scss';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Pie } from 'react-chartjs-2';
import { AuctionData } from 'units/analytics/pages/types';
import { ChartLegend } from '../ChartLegend/ChartLegend';
import { StatusBar } from '../StatusBar/StatusBar';
import clsx from 'clsx';
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

interface PieChartProps {
    data: AuctionData | null;
    empty: boolean;
}

const options = {
    layout: { padding: 40 },
    elements: {
        arc: {
            borderWidth: 0,
        },
    },
    plugins: {
        legend: {
            display: false,
        },
        datalabels: {
            display: true,
            color: '#000',
            anchor: 'end' as const,
            align: 'end' as const,
            offset: 12,
            formatter: (value: any) => (value ? `${value}%` : ''),
        },
    },
    maintainAspectRatio: false,
};

export const PieChart: FC<PieChartProps> = ({ data, empty }) => {
    const chartData = data?.bid_percentage_by_gender;

    const initialData = {
        labels: ['Male', 'Female', 'Unspecified'],
        datasets: [
            {
                data: [chartData?.male, chartData?.female, chartData?.unspecified],
                backgroundColor: ['#4B67F8', '#F85563', '#FD9D40'],
            },
        ],
    };

    return (
        <Card className={clsx(styles.card, !empty && chartData ? '' : styles['is-empty'])}>
            <div className={styles.title}>
                <Typography.Title htmlElement={'h4'}>Bid Sum</Typography.Title>
                {!empty && chartData && <ChartLegend />}
            </div>
            {!empty && chartData ? (
                <div className={styles.chart}>
                    <Pie redraw={false} data={initialData} options={options} />
                </div>
            ) : (
                <Typography.Title
                    htmlElement="h3"
                    className={clsx(styles.total, styles['empty-label'])}
                >
                    No data
                </Typography.Title>
            )}
            {!empty && chartData && (
                <StatusBar
                    breakpoints={[chartData?.male, chartData?.female, chartData?.unspecified]}
                />
            )}
        </Card>
    );
};
