import styled from 'styled-components';

import { sizes, Color } from '../../../../../../components/styles/constants.styles';
import { TextStyle12 } from 'components/styles/text.styles';

export const NavbarSC = styled.nav`
  display: flex;
  flex-direction: column;
  max-width: 160px;
  margin-top: 28px;

  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    text-transform: capitalize;
    margin-bottom: 10px;
    padding: 11px 8px 11px 32px;

    ${TextStyle12};

    > span:first-child {
      margin-right: 20px;
      font-size: 18px;
    }

    &.active {
      background-color: ${Color.pale_grey};
      border-radius: 0 ${sizes.border_radius} ${sizes.border_radius} 0;
      color: ${Color.charcoal_grey};

      > span:first-child {
        color: ${Color.watermelon};
      }
    }
  }
`;
