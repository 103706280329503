import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '../Icon/Icon';

import styles from './LanguageSwitcher.module.scss';

export const LanguageSwitcher = () => {
    const { i18n } = useTranslation();

    const changeLanguage = (lang: string) => {
        i18n.changeLanguage(lang);
        localStorage.setItem('lp_language', lang);
    };

    return (
        <div className={styles.root}>
            <div className={styles.wrapper}>
                <span className={styles.language}>{i18n.language}</span>
                <Icon name="dropdown-arrow" />
            </div>

            <div className={styles.dropdown}>
                <div className={styles['dropdown-body']}>
                    <ul>
                        {i18n.language !== 'en' && (
                            <li onClick={() => changeLanguage('en')}>English</li>
                        )}
                        {i18n.language !== 'de' && (
                            <li onClick={() => changeLanguage('de')}>Deutsch</li>
                        )}
                    </ul>
                </div>
            </div>
        </div>
    );
};
