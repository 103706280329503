import { UserUrls } from 'units/user/urls';
import { SettingsUrls } from 'units/settings/urls';
import { ProductsUrls } from 'units/products/urls';
import { AuctionUrls } from 'units/auctions/urls';
import { LandingPageUrls } from 'units/landing-page/urls';
import { OrderUrls } from 'units/orders/urls';

export const AppUrls = {
    index: '/',
    home: '/',
    overview: '/overview',
    website: '/website',
    products: ProductsUrls,
    auctions: AuctionUrls,
    orders: OrderUrls,
    analytics: '/analytics',
    settings: SettingsUrls,
    user: UserUrls,
    landing: LandingPageUrls,
};
