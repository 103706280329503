import styled, { css } from 'styled-components';

import { Color } from 'components/styles/constants.styles';
import { MediaQueries } from 'units//landing-page/config';

const TitleCss = css`
  text-align: left;

  @media (max-width: ${MediaQueries.tablet}) {
    margin-bottom: 55px;
  }

  @media (max-width: ${MediaQueries.mobile}) {
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 30px;
  }
`;

const Container = styled.div`
  &.Modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    max-width: 884px;
    width: 91%;
    padding: 80px 100px;

    outline: none;
    overflow: auto;
    background: ${Color.white};
    border-radius: 20px;

    @media (max-width: ${MediaQueries.tablet}) {
      padding-left: 80px;
      padding-right: 80px;
    }
  
    @media (max-width: ${MediaQueries.mobile}) {
      padding: 41px 20px;
      max-width: 311px;
    }
  }
`;

const Close = styled.div`
  position: absolute;
  top: 35px;
  right: 35px;
  padding: 5px;

  font-size: 16px;
  color: ${Color.black};
  &:hover {
    cursor: pointer;
  }

  @media (max-width: ${MediaQueries.mobile}) {
    top: 14px;
    right: 14px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${MediaQueries.mobile}) {
    flex-direction: column;
    align-items: center;
  }
`;

const Picture = styled.img`
  width: 43%;

  @media (max-width: ${MediaQueries.mobile}) {
    width: 50%;
  }
`;

const CTAContainer = styled.div`
  width: 46%;
  display: flex;
  margin-top: 42px;
  flex-direction: column;

  button {
    margin-bottom: 20px;

    span {
      display: flex;
      justify-content: center;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media (max-width: ${MediaQueries.mobile}) {
    width: 90%;
    margin-top: 30px;

    button {
      margin-bottom: 16px;
    }
  }
`;

const ButtonIcon = styled.img<React.CSSProperties>`
  margin-right: 16px;
  max-width: 40px;

  ${({ padding }) => padding && `padding: ${padding};`}

  @media (max-width: ${MediaQueries.mobile}) {
    max-width: 24px;
    margin-right: 8px;
  }
`;

export const LPPricingModalSC = {
  Container,
  Close,
  ContentContainer,
  Picture,
  CTAContainer,
  ButtonIcon,

  TitleCss
};
