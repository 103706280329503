import styled from 'styled-components';

import { LogoText, TextStyle13, ItemTitleText } from 'components/styles/text.styles';
import { Color, sizes } from 'components/styles/constants.styles';

const ProductContainer = styled.div`
  width: 100%;
  height: 255px;
  display: flex;
  position: relative;
  align-items: center;
  background: ${Color.white};
  margin-bottom: ${sizes.lg_padding};
  border-radius: ${sizes.border_radius};
`;

const ProductImg = styled.img`
  height: 100%;
  max-width: 370px;
  border-top-left-radius: ${sizes.border_radius};
  border-bottom-left-radius: ${sizes.border_radius};
  padding: ${sizes.sm_padding} 0 ${sizes.sm_padding} ${sizes.sm_padding};
`;

const ProductDetails = styled.div`
  margin: ${sizes.sm_padding} ${sizes.lg_padding} ${sizes.sm_padding} ${sizes.sm_padding};
`;

const ProductTitle = styled.div`
  ${LogoText};
  margin: ${sizes.sm_padding} 0 ${sizes.md_padding};
`;

const ProductPrice = styled.div`
  margin-bottom: 20px;
  span {
    margin-left: 20px;
    ${LogoText};
  }
`;

const ProductDate = styled.div`
  ${TextStyle13};
  margin-bottom: ${sizes.sm_padding};

  span {
    margin-left: 20px;
    ${ItemTitleText};
    color: ${Color.bluey_grey};
  }
`;

const Close = styled.div`
  color: ${Color.bluey_grey};
  position: absolute;
  font-size: 10px;
  padding: 5px;
  right: ${sizes.sm_padding};
  top: ${sizes.sm_padding};

  &:hover {
    cursor: pointer;
  }
`;

export const ProductItemSC = {
  ProductContainer,
  ProductImg,
  ProductDetails,
  ProductTitle,
  ProductPrice,
  ProductDate,
  Close,
};
