
import { useHistory } from 'react-router-dom';

 export const useIsLandingPage = () => {
    const router = useHistory();
    
    const takeMeTo = (section: string, callback: (section: string) => void) => {
      router.push('/landing');
      
      const timeout = setTimeout(() => {
        section && callback(section);
      }, 870);
  
      return () => clearTimeout(timeout);
    }

    return { takeMeTo };
  }
  

