import { CMSThemeSettings } from './store/types/theme-settings';
import { CMSImage } from './store/types';
import { CMSCustomizationConfig } from './store/types/customization';
import { CompanyLanguages } from 'units/app/redux/types';

export const ThemeTemplatesData = [
    'traditional',
    'modern',
    'minimal',
    'funky',
    'classic',
    'estate',
] as const;

export type ThemeTemplates = typeof ThemeTemplatesData[number];

export type ThemeConfigs = {
    template: ThemeTemplates;
};

export type PreviewMessage = {
    event: 'change-theme' | 'change-page' | 'change-customization' | 'change-language';
    value: ThemeSettingsType | string | CMSCustomizationConfig | CompanyLanguages;
};

export type ThemeSettingsType = {
    template: ThemeTemplates;
    typography: ThemeSettingsTypographyType | null;
    colors: ThemeSettingsColorsType | null;
    logo: CMSImage | null;
    favicon: CMSImage | null;
};

export type ThemeSettingsColorsType = {
    background: ThemeSettingsColorsBackgroundType;
    buttons: ThemeSettingsColorsButtonType;
    forms: ThemeSettingsColorsFormType;
    text: ThemeSettingsColorsTextType;
};

export type ThemeSettingsColorsBackgroundType = {
    footer: string;
    primary: string;
    secondary: string;
    tertiary: string;
};

export type ThemeSettingsColorsButtonType = {
    primary: string;
    secondary: string;
    textLink: string;
};

export type ThemeSettingsColorsFormType = {
    background: string;
    border: string;
    placeholder: string;
};

export type ThemeSettingsColorsTextType = {
    body: string;
    heading: string;
    heroAndNavigation: string;
    salePrive: string;
};

export type ThemeSettingsTypographyType = {
    heading: ThemeSettingsTypographyHeadingType;
    paragraphBody: ThemeSettingsTypographyParagraphType;
};

export type ThemeSettingsTypographyHeadingType = {
    fontFamily: string;
    fontSizes: ThemeSettingsTypographyHeadingTitleType;
};

export type ThemeSettingsTypographyHeadingTitleType = {
    h1: string;
    h2: string;
    h3: string;
};

export type ThemeSettingsTypographyParagraphType = {
    fontFamily: string;
    fontSize: string;
};

export enum CMSTabId {
    themes = 'themes',
    themeSettings = 'theme-settings',
    customization = 'customization',
}

export type CMSTab = {
    id: CMSTabId;
    url: string;
    title: string;
};

export type CMSSubmitType = 'save' | 'save & publish';

export type CMSFormikThemeSettings = CMSThemeSettings & {
    logo: CMSImage | File | null;
    favicon: CMSImage | File | null;
};

export type CMSCustomizationHomepageSectionName =
    | 'hero'
    | 'usp'
    | 'textBlock'
    | 'productCards'
    | 'newsletterOption'
    | 'footer';

export type CMSCustomizationHomepageProgressField =
    | 'icon'
    | 'usp1.title'
    | 'usp1.description'
    | 'usp1.icon'
    | 'usp2.title'
    | 'usp2.description'
    | 'usp2.icon'
    | 'usp3.title'
    | 'usp3.description'
    | 'usp3.icon'
    | 'CTAcopy'
    | 'footerTitle'
    | 'textLink.target'
    | 'textLink.name'
    | 'title'
    | 'picture'
    | 'description'
    | 'conversationSticker.icon'
    | 'sectionTitle'
    | 'loadMoreLink'
    | 'CTAText'
    | 'disclaimer'
    | 'termsAndConditions'
    | 'dataProtection'
    | 'facebook.url'
    | 'instagram.url'
    | 'twitter.url'
    | 'snapchat.url'
    | 'linkedin.url'
    | 'tiktok.url'
    | 'footerLinks.[0].url'
    | 'footerLinks.[1].url'
    | 'footerLinks.[2].url'
    | 'footerLinks.[3].url';

export type CMSCustomizationProductDetailPageSectionName =
    | 'hero'
    | 'relatedProducts'
    | 'newsletterOptIn';

export type CMSCustomizationProductDetailPageProgressField =
    | 'CTAcopy'
    | 'graph1.title'
    | 'graph2.title'
    | 'sectionTitle';

export type CMSCustomizationMissedAuctionsSectionName = 'general';

export type CMSCustomizationMissedAuctionsProgressField = 'title';

export type CMSCustomizationShopModalsSectionName =
    | 'general'
    | 'register'
    | 'login1'
    | 'login2'
    | 'offerConfirmation'
    | 'offerDenial'
    | 'offerWon'
    | 'resetPassword';

export type CMSCustomizationShopModalsProgressField =
    | 'title'
    | 'button'
    | 'bindingNotification'
    | 'mainContent'
    | 'additionalContent';
