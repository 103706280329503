import styled, { css } from 'styled-components';

import { MediaQueries } from 'units/landing-page/config';

const GeneralSC = css`
  position: absolute;
  height: auto;
`;

const Root = styled.div`
  width: 100%;
  height: 100%;
`;

const Background = styled.img`
  ${GeneralSC}
  top: 0;
  right: 16%;

  @media (max-width: ${MediaQueries.tablet}) {
    max-width: 656px;
    top: -16px;
    right: 11%;
  }

  @media (max-width: ${MediaQueries.mobile}) {
    max-width: 396px;
    top: -5px;
    right: 19%;
  }
`;

const RootImage = styled.img`
  ${GeneralSC}
  top: 55px;
  right: -1%;

  @media (max-width: ${MediaQueries.tablet}) {
    top: 25px;
    right: -11%;
    max-width: 617px;
  }

  @media (max-width: ${MediaQueries.mobile}) {
    right: -1%;
    max-width: 391px;
  }
`;

const Notification = styled.img`
  ${GeneralSC}
  top: 40px;
  right: 14%;

  @media (max-width: ${MediaQueries.tablet}) {
    top: -39px;
    right: 10%;
    max-width: 366px;
  }

  @media (max-width: ${MediaQueries.mobile}) {
    top: -16px;
    right: 14%;
    max-width: 233px;
  }
`;

const Product = styled.img`
  ${GeneralSC}
  bottom: -200px;
  right: 16%;

  @media (max-width: ${MediaQueries.tablet}) {
    bottom: -65px;
    right: 12%;
    max-width: 346px;
  }

  @media (max-width: ${MediaQueries.mobile}) {
    bottom: 214px;
    right: 16%;
    max-width: 220px;
  }
`;

export const LPCommerceSC = {
  Root,
  Background,
  RootImage,
  Notification,
  Product
}
