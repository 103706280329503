// Themes tab

export const CMS_SET_AVAILABLE_THEMES = 'CMS_SET_AVAILABLE_THEMES';
export const CMS_SELECT_THEME = 'CMS_SELECT_THEME';
export const CMS_SELECT_ACTIVE_THEME = 'CMS_SELECT_ACTIVE_THEME';
export const CMS_SET_ACTIVE_THEME = 'CMS_SET_ACTIVE_THEME';

// Theme settings tab

export const CMS_POPULATE_OVERALL_THEME_SETTINGS_CONFIG =
    'CMS_POPULATE_OVERALL_THEME_SETTINGS_CONFIG';
export const CMS_SET_THEME_SETTINGS_CONFIG = 'CMS_SET_THEME_SETTINGS_CONFIG';
export const CMS_SET_THEME_CUSTOMIZATION_CONFIG = 'CMS_SET_THEME_CUSTOMIZATION_CONFIG';
export const CMS_SET_PREVIEW = 'CMS_SET_PREVIEW';

// Customization tab

export const CMS_CUSTOMIZATION_SET_PAGE = 'CMS_CUSTOMIZATION_SET_PAGE';
export const CMS_POPULATE_CUSTOMIZATION_CONFIG = 'CMS_POPULATE_CUSTOMIZATION_CONFIG';
export const CMS_SET_CUSTOMIZATION_PAGE_CONFIG = 'CMS_SET_CUSTOMIZATION_PAGE_CONFIG';
export const CMS_SET_SELECTED_MODAL = 'CMS_SET_SELECTED_MODAL';
export const SET_IMMO_HERO_SECTION_AUCTIONS = 'SET_IMMO_HERO_SECTION_AUCTIONS';

// update data

export const CMS_UPDATE_SELECTED_LANGUAGE = 'CMS_UPDATE_SELECTED_LANGUAGE';
export const CMS_UPDATE_SHOP_MODAL_CUSTOMIZATION_CONFIG =
    'CMS_UPDATE_SHOP_MODAL_CUSTOMIZATION_CONFIG';
export const CMS_UPDATE_PRODUCT_DETAIL_CUSTOMIZATION_CONFIG =
    'CMS_UPDATE_PRODUCT_DETAIL_CUSTOMIZATION_CONFIG';
export const CMS_UPDATE_MISSED_AUCTIONS_CUSTOMIZATION_CONFIG =
    'CMS_UPDATE_MISSED_AUCTIONS_CUSTOMIZATION_CONFIG';
export const CMS_UPDATE_HOME_CUSTOMIZATION_CONFIG = 'CMS_UPDATE_HOME_CUSTOMIZATION_CONFIG';

// Additional

export const CMS_THEME_OVERIVEW_IS_LOADING = 'CMS_THEME_OVERIVEW_IS_LOADING';
export const CMS_THEME_OVERVIEW_LOADING_ENDED = 'CMS_THEME_OVERVIEW_LOADING_ENDED';

export const CMS_SET_CONFIG_SAVE_STATUS = 'CMS_SET_CONFIG_SAVE_STATUS';
export const CMS_SET_GOOGLE_FONTS = 'CMS_SET_GOOGLE_FONTS';
export const CMS_MARK_IMAGES_TO_DELETE = 'CMS_MARK_IMAGES_TO_DELETE';
export const CMS_DIRTY_STATE = 'CMS_DIRTY_STATE';

// Clearing

export const CMS_CLEAR_TEMP_PAYLOAD = 'CMS_CLEAR_TEMP_PAYLOAD';
