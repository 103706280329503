import React, { FC } from 'react';

import { ProgressSticksSC } from './progress-steps.styles';
export interface Props {
    numberOfSteps?: number;
    step: number;
}

export const ProgressSteps: FC<Props> = ({ step, numberOfSteps = 3 }) => {
    const setSteps = (steps: number) => {
        const arr = [];
        if(steps) {
          for(let i = 1; i <= steps; i++) {
            arr.push(i)
          } 
        }
        return arr;
      }

    const steps = setSteps(numberOfSteps);
    const select = setSteps(step);

    return (    
        <ProgressSticksSC.Container>
            {steps.map((id) => (
                <ProgressSticksSC.Stick 
                    key={id} 
                    selected={select}
                />
            ))}
        </ProgressSticksSC.Container>
    )  
}
