import React, { FC } from 'react';

import { CmsAcordionSection } from 'units/cms/components/cms-acordion-section/cmsAcordionSection.component';
import { FormikFormInput } from 'units/common/components/formik/input/form-input.component';
import { TrackProgressField } from 'units/common/hooks/useTrackProgress';
import { FormikColorPicker } from 'units/common/components/formik/color-picker/color-picker.component';
import { FormikToggleButton } from 'units/common/components/formik/toggle/formik-toggle';

type Props = {
  updateProgress: (field: TrackProgressField) => void;
}

export const CMSProductDetailPageHeroAccordion: FC<Props> = ({ updateProgress }) => (
    <>
      <CmsAcordionSection sectionMargin={32}>
        <FormikFormInput
          name="hero.CTAcopy"
          placeholder="Add CTA copy here"
          label="CTA copy"
          paddingBottom="0"
          afterChange={({ target }) => {
            updateProgress({ name: 'CTAcopy', isFilled: !!target.value });
          }}
        />
        <CmsAcordionSection
          title="Conversion sticker"
          titleStyle="black"
          withDevider={false}
        >
          <FormikToggleButton
            name="hero.conversationSticker.isActive"
            label="Active"
            disabled={true}
          />
        </CmsAcordionSection>
       

    </CmsAcordionSection>
    <CmsAcordionSection title="Customize the graphs">
      <FormikColorPicker
        label="Color graph 1"
        name="hero.graph1.color"
      />
      <FormikFormInput
         name="hero.graph1.title"
         placeholder="Current price"
         label="Graph 1 title"
         paddingBottom="0"
         afterChange={({ target }) => {
          updateProgress({ name: 'graph1.title', isFilled: !!target.value });
         }}
      />  
    </CmsAcordionSection>
    <FormikColorPicker
        label="Color graph 2"
        name="hero.graph2.color"
      />
      <FormikFormInput
         name="hero.graph2.title"
         placeholder="Your max. offer"
         label="Graph 2 title"
         paddingBottom="0"
         afterChange={({ target }) => {
          updateProgress({ name: 'graph2.title', isFilled: !!target.value });
         }}
      /> 
    </>
);
