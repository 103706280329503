import React, { FC } from 'react';

import { CmsAcordionSection } from 'units//cms/components/cms-acordion-section/cmsAcordionSection.component';
import { FormikFormInput } from 'units//common/components/formik/input/form-input.component';
import { TrackProgressField } from 'units//common/hooks/useTrackProgress';
import { CMSRootSC } from 'units//cms/styles';
import { CMSFooterSocialLinks } from './social-link/footer-social-links.component';
import { CMSFooterLinks } from './footer-links/footer-links.component';
import { useTranslation } from 'react-i18next';

type Props = {
    updateProgress: (field: TrackProgressField) => void;
};

export const CMSHomepageFooterAccordion: FC<Props> = ({ updateProgress }) => {
    const { t } = useTranslation();

    return (
        <>
            <CMSRootSC.SectionTitle marginBottom="24px">
                {t('customization_footer_subtitle')}
            </CMSRootSC.SectionTitle>
            <CmsAcordionSection>
                <FormikFormInput
                    name="footer.title"
                    label={t('customization_footer_title')}
                    placeholder={t('customization_footer_title_placeholder')}
                    paddingBottom="0"
                    afterChange={({ target }) => {
                        updateProgress({ name: 'footerTitle', isFilled: !!target.value });
                    }}
                />
            </CmsAcordionSection>
            <CMSFooterLinks updateProgress={updateProgress} />
            <CmsAcordionSection title={t('customization_footer_mandatory')}>
                <FormikFormInput
                    name={`footer.staticLinks.disclaimer.name`}
                    label={t('customization_footer_disclaimer')}
                    placeholder={t('customization_footer_disclaimer')}
                    paddingBottom="0"
                />
                <FormikFormInput
                    name={`footer.staticLinks.disclaimer.url`}
                    label={t('customization_footer_add_url')}
                    placeholder="https://www.google.ch"
                    paddingBottom="0"
                />
            </CmsAcordionSection>
            <CmsAcordionSection>
                <FormikFormInput
                    name={`footer.staticLinks.termsAndConditions.name`}
                    label={t('customization_footer_terms_and_conditions')}
                    placeholder={t('customization_footer_terms_and_conditions')}
                    paddingBottom="0"
                />
                <FormikFormInput
                    name={`footer.staticLinks.termsAndConditions.url`}
                    label={t('customization_footer_add_url')}
                    placeholder="https://www.google.ch"
                    paddingBottom="0"
                />
            </CmsAcordionSection>
            <CmsAcordionSection>
                <FormikFormInput
                    name={`footer.staticLinks.dataProtection.name`}
                    label={t('customization_footer_data_protection')}
                    placeholder={t('customization_footer_data_protection')}
                    paddingBottom="0"
                />
                <FormikFormInput
                    name={`footer.staticLinks.dataProtection.url`}
                    label={t('customization_footer_add_url')}
                    placeholder="https://www.google.ch"
                    paddingBottom="0"
                />
            </CmsAcordionSection>
            {/*       <FormikFormInput
            name="footer.staticLinks.disclaimer"
            label="Disclaimer"
            placeholder="Disclaimer"
            paddingBottom="0"
            afterChange={({ target }) => {
              updateProgress({ name: 'disclaimer', isFilled: !!target.value });
            }}
          />
          <FormikFormInput
            name="footer.staticLinks.termsAndConditions"
            label="Terms & conditions"
            placeholder="AGB"
            paddingBottom="0"
            afterChange={({ target }) => {
              updateProgress({ name: 'termsAndConditions', isFilled: !!target.value });
            }}
          />
          <FormikFormInput
            name="footer.staticLinks.dataProtection"
            label="Data protection"
            placeholder="Data protection"
            paddingBottom="0"
            afterChange={({ target }) => {
              updateProgress({ name: 'dataProtection', isFilled: !!target.value });
            }}
          />    */}
            <CMSFooterSocialLinks updateProgress={updateProgress} />
        </>
    );
};
