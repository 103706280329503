import { yup } from 'config/yup';
import { CommonVM } from 'units/common/constants';

export const CMSCustomizationShopModalsValidationSchema = yup.object({
    register: yup.object({
        title: yup.string().max(35, CommonVM.maxSymbols(35)),
        // .required(CommonVM.required('Title')),
        subtitle: yup.string().max(250, CommonVM.maxSymbols(250)),
        button: yup.string().max(20, CommonVM.maxSymbols(20)),
        // .required(CommonVM.required('Registration button text')),
    }),
    login1: yup.object({
        title: yup.string().max(35, CommonVM.maxSymbols(35)),
        // .required(CommonVM.required('Title')),
        subtitle: yup.string().max(250, CommonVM.maxSymbols(250)),
        button: yup.string().max(20, CommonVM.maxSymbols(20)),
        // .required(CommonVM.required('Login button text')),
    }),
    login2: yup.object({
        title: yup.string().max(35, CommonVM.maxSymbols(35)),
        // .required(CommonVM.required('Title')),
        subtitle: yup.string().max(250, CommonVM.maxSymbols(250)),
        button: yup.string().max(20, CommonVM.maxSymbols(20)),
        // .required(CommonVM.required('Login button text')),
    }),
    offerConfirmation: yup.object({
        title: yup.string().max(35, CommonVM.maxSymbols(35)),
        // .required(CommonVM.required('Title')),
        subtitle: yup.string().max(250, CommonVM.maxSymbols(250)),
        bindingNotification: yup.string().max(50, CommonVM.maxSymbols(50)),
        // .required(CommonVM.required('Binding notification text')),
        button: yup.string().max(20, CommonVM.maxSymbols(20)),
        // .required(CommonVM.required('Confirmation button text')),
    }),
    offerDenial: yup.object({
        title: yup.string().max(35, CommonVM.maxSymbols(35)),
        // .required(CommonVM.required('Title')),
        subtitle: yup.string().max(250, CommonVM.maxSymbols(250)),
        button: yup.string().max(20, CommonVM.maxSymbols(20)),
        // .required(CommonVM.required('Denial button text')),
    }),
    offerWon: yup.object({
        title: yup.string().max(35, CommonVM.maxSymbols(35)),
        // .required(CommonVM.required('Title')),
        subtitle: yup.string().max(250, CommonVM.maxSymbols(250)),
        button: yup.string().max(20, CommonVM.maxSymbols(20)),
        // .required(CommonVM.required('Won button text')),
    }),
    resetPassword: yup.object({
        /* mainContent: yup.string().max(250, CommonVM.maxSymbols(250)).required(CommonVM.required('Main content')),
    additionalContent: yup.string().max(250, CommonVM.maxSymbols(250)), */
        title: yup.string().max(35, CommonVM.maxSymbols(35)),
        // .required(CommonVM.required('Title')),
        button: yup.string().max(20, CommonVM.maxSymbols(20)),
        // .required(CommonVM.required('Reset password button text')),
    }),
});
