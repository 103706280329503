/* eslint-disable no-throw-literal */
import React, { FC, useMemo, useCallback, useState, useContext, memo } from 'react';
import { Formik, FormikHelpers, FormikErrors } from 'formik';

import { useCMSCustomizationFormikSettings } from 'units/cms/hooks/customization-tab/useCMSCustomizationFormikSettings';
import { useCMSCustomizationLanguage } from 'units/cms/hooks/customization-tab/useCMSCustomizationLanguage';
import { CMSHomePageFormikCustomizationSettings } from 'units/cms/store/types/customization/homepage';
import { CompanyLanguages } from 'units/app/redux/types';
import { CMSCustomizationSidebarForm } from '../../sidebar-form/cms-sidebar-form.component';
import { prepareHomePageProgress } from 'units/cms/helpers/prepare-customization-progress/homepage';
import { useCMSCustomizationSettings } from 'units/cms/hooks/selectors/useCMSCustomizationSettings';
import { CMSCustomizationPage } from 'units/cms/store/types';
import {
    saveCMSCustomizationSettingsConfigThunk,
    saveAndPublishCMSCustomizationSettingsConfigThunk,
    updateCMSHomeAuctionsThunk,
} from 'units/cms/store/thunks';
import { useAsyncDispatch } from 'helpers/hooks';
import { CMSCustomizationHomePageValidationSchema } from './validationSchema';
import { CMSCustomizationHomepageSectionName as SectionId } from 'units//cms/types';
import { EmptyFunction } from 'units/common/constants';
import { scrollTop } from 'helpers/general';
import { createOverallUpdatesForCMSHomepage } from 'units/cms/helpers/local-settings/homepage';
import { CMSCustomizationHomepageAccordions } from './accordions/homepage-accordions.component';
import { isEmptyObject } from 'units/cms/store/helpers';
import { NotificationContext } from 'shared/providers';

export const CMSHomepageSectionHeaders: { [key in SectionId]: string } = {
    hero: 'Hero',
    usp: 'USP section',
    textBlock: 'Text block',
    productCards: 'Product cards',
    newsletterOption: 'Newsletter opt in',
    footer: 'Footer',
};

export type CMSCustomizationSubmitHandler = (
    values: any,
    helpers: Pick<
        FormikHelpers<any>,
        'setSubmitting' | 'validateForm' | 'setErrors' | 'setTouched'
    >,
) => void;

export const CMSCustomizationHomepageSidebar: FC = memo(() => {
    const { showNotification } = useContext(NotificationContext);
    const { dispatch: asyncDispatch } = useAsyncDispatch();

    const [selectedLanguage, { changeLanguage, defaultLanguage }] = useCMSCustomizationLanguage();
    const [activeSections, setActiveSections] = useState<Array<SectionId>>(['hero']);

    const initialHomePageOverallSettings = useCMSCustomizationSettings(CMSCustomizationPage.Home);
    const [overallSettings, { updateSettings }] = useCMSCustomizationFormikSettings<
        CMSHomePageFormikCustomizationSettings
    >(initialHomePageOverallSettings, createOverallUpdatesForCMSHomepage);

    // ----- Memoized values

    const sectionProgress = useMemo(
        () => prepareHomePageProgress(initialHomePageOverallSettings).sectionProgress,
        [initialHomePageOverallSettings],
    );

    // Formik values from overall settings based on selected language.
    const formikInitialValues = useMemo(() => overallSettings[selectedLanguage], [
        selectedLanguage,
        overallSettings,
    ]);

    //console.log(overallSettings, selectedLanguage);

    // ----- Form language

    const handleLanguageChange = useCallback(
        (
            language: CompanyLanguages,
            selectedLanguageSettings: CMSHomePageFormikCustomizationSettings,
            { resetForm }: Pick<FormikHelpers<any>, 'resetForm'>,
        ) => {
            if (language === selectedLanguage) {
                return;
            }

            const updatedOverallSettings = updateSettings(
                selectedLanguageSettings,
                selectedLanguage,
            );
            // Reset form with settings of new selected language
            resetForm({
                values: updatedOverallSettings[language],
            });
            changeLanguage(language);
        },
        [selectedLanguage, updateSettings, changeLanguage],
    );

    // ----- Submitting

    // Call direct from save button. Need to alow save in any time and without filling all required fields.
    // Skiping formik pre-validation and validation.
    const handleSaveForm: CMSCustomizationSubmitHandler = useCallback(
        (values, helpers) => {
            helpers.setSubmitting(true);

            const finalOverallSettings = updateSettings(values, selectedLanguage);
            asyncDispatch(
                saveCMSCustomizationSettingsConfigThunk(
                    CMSCustomizationPage.Home,
                    finalOverallSettings,
                    showNotification,
                ),
            );

            helpers.setSubmitting(false);
        },
        [selectedLanguage, updateSettings],
    );

    // Call direct from save and publish button.
    // Need to validate each language form and find first language and section error and show user.
    const handleSaveAndPublishForm: CMSCustomizationSubmitHandler = useCallback(
        async (values, helpers) => {
            helpers.setSubmitting(true);
            helpers.setTouched(values);

            const finalOverallSettings = updateSettings(values, selectedLanguage);

            const languageErrorList = await Promise.all(
                Object.entries(finalOverallSettings).map(
                    ([lng, settings]) =>
                        new Promise<{ lng: CompanyLanguages; errors: FormikErrors<any> }>(res => {
                            helpers.validateForm(settings).then(errors =>
                                res({
                                    lng: lng as CompanyLanguages,
                                    errors,
                                }),
                            );
                        }),
                ),
            );

            // Find selected language errors or fist from list.
            const languageFormError =
                languageErrorList.find(
                    le => le.lng === selectedLanguage && !isEmptyObject(le.errors),
                ) || languageErrorList.find(le => !isEmptyObject(le.errors));

            if (languageFormError) {
                const { errors, lng } = languageFormError;
                const firstSectionWithErrors = Object.keys(
                    languageFormError.errors,
                )[0] as SectionId;

                // Set errors and touched after language changed.
                setTimeout(() => {
                    helpers.setErrors(errors);
                    helpers.setTouched(errors as any);
                }, 0);

                scrollTop();

                // If section with error is not expanded or there are several expanded sections.
                if (!activeSections.every(s => s === firstSectionWithErrors)) {
                    setActiveSections([firstSectionWithErrors]);
                }

                changeLanguage(lng as CompanyLanguages);

                showNotification(
                    'error',
                    `Please, update ${CMSHomepageSectionHeaders[firstSectionWithErrors]} before publish`,
                );
            } else {
                asyncDispatch(
                    saveAndPublishCMSCustomizationSettingsConfigThunk(
                        CMSCustomizationPage.Home,
                        finalOverallSettings,
                        showNotification,
                    ),
                );
            }

            helpers.setSubmitting(false);
        },
        [updateSettings, selectedLanguage, changeLanguage, setActiveSections, activeSections],
    );

    // ----- Handle accordions

    const handleAccordionExpandedChange = useCallback(
        (
            expanded: Array<string>,
            languageSettings: CMSHomePageFormikCustomizationSettings,
            helpers: Pick<FormikHelpers<CMSHomePageFormikCustomizationSettings>, 'resetForm'>,
        ) => {
            setActiveSections(expanded as Array<SectionId>);
            handleLanguageChange(defaultLanguage, languageSettings, helpers);
        },
        [setActiveSections, defaultLanguage, handleLanguageChange],
    );

    const onUpdate = useCallback(
        async (values: CMSHomePageFormikCustomizationSettings) => {
            asyncDispatch(updateCMSHomeAuctionsThunk(values, selectedLanguage));
        },
        [selectedLanguage],
    );

    // ----- Render

    //console.log(formikInitialValues, sectionProgress);

    if (!formikInitialValues || !sectionProgress) {
        return null;
    }

    return (
        <Formik
            initialValues={formikInitialValues}
            validationSchema={CMSCustomizationHomePageValidationSchema}
            enableReinitialize
            onSubmit={EmptyFunction}
            validate={onUpdate}
        >
            <CMSCustomizationSidebarForm
                selectedLanguage={selectedLanguage}
                savedSettings={initialHomePageOverallSettings}
                onSave={handleSaveForm}
                onSaveAndPublish={handleSaveAndPublishForm}
            >
                <CMSCustomizationHomepageAccordions
                    selectedLanguage={selectedLanguage}
                    onLanguageChange={handleLanguageChange}
                    sectionProgress={sectionProgress}
                    activeSections={activeSections}
                    handleAccordionExpandedChange={handleAccordionExpandedChange}
                />
            </CMSCustomizationSidebarForm>
        </Formik>
    );
});

/* eslint-enable no-throw-literal */
