import styled from 'styled-components';

import { Color } from 'components/styles/constants.styles';
import { TextStyle3, TextStyle4 } from 'components/styles/text.styles';
import { CommonInputStyles } from '../common-input.styles';

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const Label = styled.label`
    display: block;
    margin-bottom: 8px !important;

    ${TextStyle3}
`;

const Error = styled.p`
    ${TextStyle3}
    font-size: 14px;
    color: ${Color.watermelon};
    margin: 8px 0 0 0;
`;

const InputWrapper = styled.div`
    position: relative;
    display: flex;
`;

type InputProps = {
    highlightError?: boolean;
};

const Input = styled.input<InputProps>`
    flex: 1;
    padding: 8px 64px 8px 32px;

    ${CommonInputStyles};
    ${TextStyle4}
    color: ${Color.black};
    ${({ highlightError }) => (highlightError ? `outline: 1px auto ${Color.watermelon}` : '')}
`;

const ShowPasswordButton = styled.button`
    position: absolute;
    top: 4px;
    right: 32px;
    display: flex;
    padding: 4px;

    cursor: pointer;
    border: none;
    outline: none;
    background-color: transparent;
`;

export const UserInputSC = {
    InputContainer,
    Label,
    InputWrapper,
    Input,
    ShowPasswordButton,
    Error,
};
