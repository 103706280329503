import React, { FC } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

interface Props extends RouteProps {
  isAllowed?: boolean;
  redirectTo?: string;
  component: FC<any>;
}

export const PrivateRoute: FC<Props> = ({
  component,
  isAllowed = true,
  redirectTo = '/',
  render,
  ...rest
}) => (
  <Route
    {...rest}
    render={routeProps => {
      if (!isAllowed) {
        return <Redirect to={redirectTo} />
      } else if (component) {
        return React.createElement(component, {...routeProps});
      } else if (render) {
        return render(routeProps);
      }
    }}
  />
);
