import React, { FC } from 'react';

import { ProductSeoFormSC } from './product-seo.style';
import { FormikFormInput } from 'units//common/components/formik/input/form-input.component';
import { FormikFormTextArea } from 'units//common/components/formik/textarea/form-textarea.comonent';
import { useFormikFieldErrors } from 'units//common/hooks/useFieldErrors';
import { EditProductSeo } from '../store/types';

export const ProductSeoForm: FC = () => {
  const fieldError = useFormikFieldErrors<EditProductSeo>();

  return (
    <>
      <ProductSeoFormSC.Subtitle>
        Add title and description to make this product easier to find (for search engines)
      </ProductSeoFormSC.Subtitle>
      <FormikFormInput
        name="seoPageTitle"
        label="Page title"
        placeholder="Enter a page title"
        error={fieldError.seoPageTitle}
      />
      <FormikFormTextArea
        name="seoPageDescription"
        label="Page description"
        placeholder="Enter a page description"
        error={fieldError.seoPageDescription}
      />
    </>    
  );
}
