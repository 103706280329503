import styled from 'styled-components';

import { sizes } from 'components/styles/constants.styles';

export const InviteUserSC = styled.div`
  width: 100%;
  margin: ${sizes.sm_padding} ${sizes.grid_margin} 0;

  button {
    float: right;
  }
`;
