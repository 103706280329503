import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { LPTextLabel, LPTypography } from '../../ui';

import { featuresList } from './features.mocks';
import { LandingPageSC } from 'units//landing-page/index.styles';
import { LPFeaturesSC } from './features.styles';

export const LPFeatures: FC = () => {
  const { t } = useTranslation();

  return (
    <LPFeaturesSC.Root id="features">
      <LandingPageSC.SectionSeparator  />
      <LPTypography.H2 styles={LPFeaturesSC.TitleCss}>
        {t('lp_feature_title')}
      </LPTypography.H2>
      <LPFeaturesSC.List>
        {featuresList.map((item, index) => (
          <LPFeaturesSC.Item key={`LPFeatures-item-${index}`}>
            <LPFeaturesSC.ImageWrapper>
              {React.createElement(item.illustration)}
            </LPFeaturesSC.ImageWrapper>
            <LPFeaturesSC.Body>
              <LPTextLabel>
                {t(item.label)}
              </LPTextLabel>
              <LPTypography.H2 styles={LPFeaturesSC.ItemTitleCss}>
                {t(item.title)}
              </LPTypography.H2>
              <LPTypography.Text>
                {t(item.text)}
              </LPTypography.Text>
            </LPFeaturesSC.Body>
          </LPFeaturesSC.Item>
        ))}
      </LPFeaturesSC.List>
    </LPFeaturesSC.Root>
  )
}
