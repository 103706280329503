import { useCallback, useMemo } from 'react';
import { useField } from 'formik';
import { useDispatch } from 'react-redux';

import { CMSImage } from '../store/types';
import { isCMSSavedImage } from '../store/helpers';
import { cmsMarkImagesToDelete } from '../store/action-creators';

type FormikHook = (name: string) => [
  CMSImage | null,
  { storeImage: (image: File) => void;
    storeFirstImage: (images: Array<File>) => void;
    deleteImage: (imageId: string) => void;
    error: string | undefined,
  }
];

// Hook to work with images with formik
export const useCMSFormikImageUploadHelper: FormikHook = (name: string) => {
  const dispatch = useDispatch();
  const [{ value: formikImage }, { error, touched }, helpers] = useField<CMSImage | File | null>(name);

  const image = useMemo(() => {
    if (!formikImage) {
      return null;
    }

    return isCMSSavedImage(formikImage) ? formikImage : {
      id: 'mock',
      src: URL.createObjectURL(formikImage),
    }
  }, [formikImage]);

  const storeImage = useCallback((newImage: File) => {
    helpers.setValue(newImage);
    helpers.setTouched(true, false);
  }, []);

  const storeFirstImage = useCallback((images: Array<File>) => {
    storeImage(images[0]);
  }, [storeImage]);

  const deleteImage = useCallback(() => {
    if (isCMSSavedImage(formikImage)) {
      dispatch(cmsMarkImagesToDelete([formikImage.id]));
    } else if (formikImage) {
      URL.revokeObjectURL(image?.src || '');
    }

    helpers.setValue(null);
  }, [formikImage, image]);

  return [image, {
    storeImage,
    storeFirstImage,
    deleteImage,
    error: touched ? error : undefined,
  }];
};
