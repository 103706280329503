import { InvitedUserRes, InvitedUser, UpdateInvitedUserErrorRes, UpdateInvitedUserError } from '../../api/types';

export const parseInvitedUser = (user: InvitedUserRes): InvitedUser => ({
  email: user.email,
  phone: user.phone || '',
  firstName: user.first_name,
  lastName: user.last_name,
  roleId: user.role,
  isActive: user.is_active,
});

export const parseInvitedUserError = (errors: UpdateInvitedUserErrorRes): UpdateInvitedUserError => ({
  email: errors.email,
  phone: errors.phone,
  firstName: errors.first_name,
  lastName: errors.last_name,
});
