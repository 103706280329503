import React, { InputHTMLAttributes, FC, useState } from 'react';

import { UserInputSC } from './user-input.styles';
import { IconSC, IconUnicodes } from 'components/styles/icons.styles';
import { Color } from 'components/styles/constants.styles';

export interface IUserInput extends InputHTMLAttributes<HTMLInputElement> {
  label?: string,
  error?: string,
  highlightError?: boolean;
}

export const UserInput: FC<IUserInput> = ({ label, type, error, highlightError, ...props }) => {
  const [showFieldValue, setShowFieldValue] = useState(type !== 'password');

  return (
    <UserInputSC.InputContainer className={props.className}>
      {label && <UserInputSC.Label>{label}</UserInputSC.Label>}
      <UserInputSC.InputWrapper>
        <UserInputSC.Input
          highlightError={highlightError && !!error}
          type={showFieldValue ? 'text' : 'password'}
          {...props}
        />
        {type === 'password' && (
          <UserInputSC.ShowPasswordButton
            onClick={() => setShowFieldValue(!showFieldValue)}
            type="button"
            tabIndex={-1}
          >
            {showFieldValue ? (
              <IconSC color={Color.cloudy_blue} name={IconUnicodes.eye_off} size={25} />
            ) : (
              <IconSC color={Color.cloudy_blue} name={IconUnicodes.eye} size={25} />
            )}
          </UserInputSC.ShowPasswordButton>
        )}
      </UserInputSC.InputWrapper>
      {error && <UserInputSC.Error>{error}</UserInputSC.Error>}
    </UserInputSC.InputContainer>
  );
};
