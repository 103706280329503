const index = '/cms';
const themes = `${index}/themes`;
const themeSettings = `${index}/theme-settings`;
const customization = `${index}/customization`;

export const CMSUrls = {
  index,
  themes,
  themeSettings,
  customization,
};
