import { AppUser } from 'units/user/redux/types';
import dayjs from 'dayjs';

// Extend dayjs to use "from now" option
import * as relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime as any);

const preparePrice = (price: string) => {
    const result = price.split('.')[1];

    if (result === undefined) return price + '.00';
    if (result.length <= 1) return price + '0';
    return price;
};

const getFullName = (user: AppUser | null) => {
    return `${user?.firstName} ${user?.lastName}`;
};

const numberSign = (number: number) => {
    if (number > 0) {
        return '+';
    } else if (number < 0) {
        return '-';
    }
    return '';
};

const formatCurrency = (number: number, fractionDigitsNum = 2) => {
    return new Intl.NumberFormat('de-CH', {
        style: 'currency',
        currency: 'CHF',
        minimumFractionDigits: fractionDigitsNum,
        maximumFractionDigits: fractionDigitsNum,
    })
        .format(number)
        .replace('-', '');

    // .replace(',', `’`)
    // .replace('-', '');
};

const formatDateVariants = (date: string) => {
    return dayjs(date).locale('en').fromNow();
};

const capitalizeFirstLetter = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
};

const parsePrice = (price: string) => {
    return Number(price.replaceAll('’', ''));
};

export const ParseHelper = {
    getFullName,
    formatCurrency,
    numberSign,
    preparePrice,
    formatDateVariants,
    capitalizeFirstLetter,
    parsePrice,
};
