import React, { FC, useRef, useEffect, useCallback } from 'react';
import { CustomPicker } from 'react-color';
import { Hue, Saturation, EditableInput } from 'react-color/lib/components/common';

import { BaseFormInput } from 'units/common/components/form/fields/form-input/base-form-input.component';
import { IconSC, IconUnicodes } from 'components/styles/icons.styles';
import { useStateToggle } from 'units/common/hooks/useStateToggle';
import { ColorPickerSC } from './color-picker.styles';

interface Props {
  hex?: string;
  color: string;
  label?: string;
  onChange: (color: any) => void;
  disabled?: boolean;
}

const defaultColors = [
  '#ff5722', '#ff8a65', '#ffccbc', '#f44336', '#e57373', '#ffcdd2',
  '#e91e63', '#f06292', '#f8bbd0', '#9c27b0', '#ba68c8', '#e1bee7',
  '#673ab7', '#9575cd', '#d1c4e9', '#3f51b5', '#7986cb', '#c5cae9',
  '#03a9f4', '#4fc3f7', '#b2ebf2', '#4caf50', '#81c784', '#c8e6c9',
  '#8bc34a', '#aed581', '#dcedc8', '#ffeb3b', '#fff9c4', '#ffc107',
  '#ffecb3', '#ff9800', '#05060b', '#474747', '#9da6b7', '#ffffff',
];

const CustomColorPicker: FC<Props> = ({
  color,
  label,
  onChange,
  disabled,
  ...props
}) => {
  const [isOpened, handlePickerModal] = useStateToggle(false);
  const ref = useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback((event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      handlePickerModal.turnOff();
    }
  }, [ref]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleChangeDefaultColor = (color: string) => {
    onChange({ hex: color });
    handlePickerModal.turnOff();
  };

  const handleOpenColorPicker = useCallback(() => {
    if (!disabled) {
      handlePickerModal.turnIn();
    }
  }, [disabled, handlePickerModal.turnIn]);

  return (
    <ColorPickerSC.Wrapper ref={ref} disabled={disabled}>
      <ColorPickerSC.PreviewField onClick={handleOpenColorPicker}>
        <ColorPickerSC.SelectedColor color={color} />
        <ColorPickerSC.Label>{label}</ColorPickerSC.Label>
      </ColorPickerSC.PreviewField>

      {isOpened && (
        <ColorPickerSC.Container>
          <ColorPickerSC.Close onClick={handlePickerModal.turnOff}>
            <IconSC name={IconUnicodes.close} />
          </ColorPickerSC.Close>

          <ColorPickerSC.InputWrapper>
            <BaseFormInput
              placeholder='Hex'
              disabled
              withBackground
            />
            <ColorPickerSC.PickerInput>
              <EditableInput
                value={props.hex?.replace('#', '')}
                onChange={onChange}
              />
            </ColorPickerSC.PickerInput>
          </ColorPickerSC.InputWrapper>

          <ColorPickerSC.Palette>
            <div>
              <ColorPickerSC.Saturation>
                <Saturation {...props} onChange={onChange} />
              </ColorPickerSC.Saturation>
              
              <ColorPickerSC.Hue>
                <Hue {...props} onChange={onChange} />
              </ColorPickerSC.Hue>
            </div>

            <ColorPickerSC.Colors>
              {defaultColors.map(color => (
                <ColorPickerSC.ColorItem
                  key={color}
                  color={color}
                  onClick={() => handleChangeDefaultColor(color)}
                />
              ))}
            </ColorPickerSC.Colors>
          </ColorPickerSC.Palette>
        </ColorPickerSC.Container>
      )}
    </ColorPickerSC.Wrapper>
  );
};

export const ColorPicker = CustomPicker(CustomColorPicker);
