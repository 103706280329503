import styled from 'styled-components';

import { TextStyle13 } from 'components/styles/text.styles';
import { Color } from 'components/styles/constants.styles';

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;

  button {
    &:first-child {
      margin-right: 16px;
    }
  }
`;

const Subtitle = styled.div`
  ${TextStyle13}

  color: ${Color.charcoal_grey};
  line-height: 20px;
  text-align: center;
  margin-bottom: 24px;
`;

export const ConfirmModalSC = {
  ButtonsContainer,
  Subtitle,
};
