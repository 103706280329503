import moment from 'moment';
import dayjs from 'dayjs';
import { FormikErrors, FormikValues } from 'formik';

import { Status } from './constants';
import { AuctionValues } from './types';

export const timeInterval = 5;

export const isDaysEqual = (firDate: Date | undefined, secDate: Date | undefined) => (
  dayjs(firDate).startOf('day').diff(dayjs(secDate).startOf('day'), 'day') === 0
);

export const isTimeValid = (firTime: Date | null | undefined, secTime: Date | null | undefined, canBeEqual?: boolean) => {
  const commonDate = { year: 2020, month: 12, date: 1, second: 0 };
  const firstTime = moment(firTime).set(commonDate);
  const secondTime = moment(secTime).set(commonDate);

  if (canBeEqual) {
    return moment(firstTime).diff(moment(secondTime), 'minute') >= 0 &&
      moment(firstTime).diff(moment(secondTime), 'second') >= 0;
  }

  return moment(firstTime).diff(moment(secondTime), 'minute') > 0;
};

export const roundTime = (date: Date | undefined) => {
  const rounding = timeInterval*60*1000;
  return Math.ceil((dayjs(date).valueOf()) / rounding) * rounding;
};

export const getAuctionStatuses = (values: AuctionValues) => {
  const { auctionStatus, isArchived } = values;

  return {
    isActive: auctionStatus === Status.active,
    isScheduled: auctionStatus === Status.scheduled,
    isDraft: auctionStatus === Status.draft,
    isEnded: auctionStatus === Status.ended,
    isArchived, 
  };
};

export const isCustomFieldValid = (
  errors: FormikErrors<FormikValues>,
  field: string,
) => {
  let isValid = true;
  const discountErr = errors[field];

  if ((typeof discountErr === 'string') && !discountErr.includes('required')) {
    isValid = false;
  }

  return isValid;
};
