import React, { FC } from 'react';

import { Field, ErrorMessage } from 'formik';

import { LanguagesSC } from './choose-languages.styles';
import { CompanyLanguages } from 'units/app/redux/types';
interface Props {
  languages: Array<{
    id: CompanyLanguages;
    title: string;
  }>;
  noErrorMsg?: boolean;
}

export const Checkbox: FC<Props> = ({ languages, noErrorMsg }) => (
  <LanguagesSC.CheckboxContainer>
    {languages.map(({ id, title }) => (
      <label key={id} htmlFor={id}>
        <Field type="checkbox" name="languages" value={id} id={id}/>
        { title }
        <span></span>
      </label>
    ))}
    <LanguagesSC.ErrorMessageContainer>
      {!noErrorMsg && <ErrorMessage name="languages" />}
    </LanguagesSC.ErrorMessageContainer>
  </LanguagesSC.CheckboxContainer>
);
