import React, { useEffect, useState } from 'react';
import { Card, Typography } from 'shared/components/ui';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

import styles from './BarChart.module.scss';
import { Bar } from 'react-chartjs-2';
import { ChartLegend } from '../ChartLegend/ChartLegend';
import { Select } from 'units/common/components/select/select.component';
import { AnalyticsService } from 'units/analytics/api/analytics-service';
import clsx from 'clsx';
import { DatasetType, SelectData } from 'units/analytics/pages/types';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const options = {
    elements: {
        arc: {
            borderWidth: 0,
        },
    },
    plugins: {
        legend: {
            display: false,
        },
        datalabels: {
            display: false,
        },
    },
    scales: {
        x: {
            stacked: true,
            grid: {
                color: 'transparent',
                borderColor: 'transparent',
            },
            ticks: {
                color: '#B6C3D9',
                font: {
                    size: 12,
                    family: "'GT Walsheim Pro Regular', 'sans-serif'",
                    weight: '400',
                },
            },
        },
        y: {
            stacked: true,
            grid: {
                borderDash: [4, 4],
                color: '#ECF2FD',
                borderColor: 'transparent',
            },
            ticks: {
                color: '#B6C3D9',
                font: {
                    size: 12,
                    family: "'GT Walsheim Pro Regular', 'sans-serif'",
                    weight: '400',
                },
            },
        },
    },
    barThickness: 14,
    borderRadius: { topLeft: 7, topRight: 7, bottomLeft: 7, bottomRight: 7 },
    borderSkipped: false,
    borderWidth: 2.5,
    borderColor: 'transparent',
    maintainAspectRatio: false,
};

type BarChartProps = {
    auctionId: string;
    productId: string;
    selectedAuction: SelectData;
    empty: boolean;
};

export const BarChart: React.FC<BarChartProps> = ({
    auctionId,
    productId,
    selectedAuction,
    empty,
}) => {
    const [chartData, setChartData] = useState<any>();
    const [selectedOption, setSelectedOption] = useState();
    const [labels, setLabels] = useState<string[]>([]);
    const [datasets, setDatasets] = useState<DatasetType[]>([]);

    const handleChange = async (e: any) => {
        setSelectedOption(e);
        if (selectedAuction.value === '') return;

        if (e.value === 'age') {
            let response;
            if (selectedAuction.value === 'all') {
                response = await AnalyticsService.getProductChartAge(productId);
                setChartData(response.data?.analytics_bid_sum_by_age);
            } else {
                response = await AnalyticsService.getChartAge(auctionId);
                setChartData(response.data?.analytics_by_age_in_amount);
            }
        }
        if (e.value === 'daytime') {
            let response;
            if (selectedAuction.value === 'all') {
                response = await AnalyticsService.getProductChartDaytime(productId);
                setChartData(response.data?.analytics_bid_sum_by_daytime);
            } else {
                response = await AnalyticsService.getChartDaytime(auctionId);
                setChartData(response.data?.analytics_by_daytime_in_amount);
            }
        }
        if (e.value === 'weekday') {
            let response;
            if (selectedAuction.value === 'all') {
                response = await AnalyticsService.getProductChartWeekday(productId);
                setChartData(response.data?.analytics_bid_sum_by_weekdays);
            } else {
                response = await AnalyticsService.getChartWeekday(auctionId);

                setChartData(response.data?.analytics_by_weekdays_in_amount);
            }
        }
        if (e.value === 'region') {
            let response;
            if (selectedAuction.value === 'all') {
                response = await AnalyticsService.getProductChartRegions(productId);
                setChartData(response.data?.region_bid_sum);
            } else {
                response = await AnalyticsService.getChartRegions(auctionId);
                setChartData(response.data?.region_bid_sum);
            }
        }
    };

    useEffect(() => {
        if (selectedOption) {
            handleChange(selectedOption);
        } else {
            handleChange({ value: 'age', label: 'Age' });
        }
    }, [auctionId, selectedOption, productId]);

    useEffect(() => {
        parseData();
    }, [chartData]);

    const parseData = () => {
        const labels = [];
        let male: DatasetType = { label: 'Male', backgroundColor: '#4B67F8dd', data: [] };
        let female: DatasetType = { label: 'Female', backgroundColor: '#F85563dd', data: [] };
        let unspecified: DatasetType = {
            label: 'Unspecified',
            backgroundColor: '#FD9D40dd',
            data: [],
        };

        for (const key in chartData) {
            if (!['age_not_setup_amount', 'age_not_setup_number'].includes(key)) labels.push(key);
            male = { ...male, data: [...male?.data, chartData[key].male] };
            female = { ...female, data: [...female?.data, chartData[key].female] };
            unspecified = {
                ...unspecified,
                data: [...unspecified?.data, chartData[key].unspecified],
            };
        }
        setLabels(labels);
        setDatasets([male, female, unspecified]);
    };

    return (
        <Card className={clsx(styles.card, !empty ? '' : styles['is-empty'])}>
            <div className={styles.titleWrap}>
                <div className={styles.title}>Bid Sum</div>
                {!empty && (
                    <Select
                        options={[
                            { value: 'age', label: 'Age' },
                            { value: 'daytime', label: 'Daytime' },
                            { value: 'weekday', label: 'Weekday' },
                            { value: 'region', label: 'Region' },
                        ]}
                        placeholder="Choose"
                        onChange={handleChange}
                        padding={'0px'}
                        className={styles.select}
                        defaultValue={{ value: 'age', label: 'Age' }}
                    />
                )}
            </div>
            {!empty ? (
                <>
                    <div className={styles.chart}>
                        <Bar redraw={false} data={{ labels, datasets }} options={options} />
                    </div>
                    <ChartLegend className={styles.legend} />
                </>
            ) : (
                <Typography.Title
                    htmlElement="h3"
                    className={clsx(styles.total, styles['empty-label'])}
                >
                    No data
                </Typography.Title>
            )}
        </Card>
    );
};
