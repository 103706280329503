import styled from 'styled-components';

import { Color } from 'components/styles/constants.styles';
import { Font } from 'components/types/enums';

const StripeButton = styled.button<{ margin?: string }>`
    min-width: 185px;
    height: 48px;
    margin: ${({ margin }) => margin};
    border: none;
    border-radius: 8px;
    background: ${Color.watermelon};
    color: ${Color.white};
    font-family: ${Font.bold};
    font-size: 12px;
    font-weight: 700;
    outline: none;
    cursor: pointer;

    &:disabled {
        cursor: not-allowed;
        background-color: ${Color.bluey_grey};
    }
`;

export const ConnectStripeSC = {
    StripeButton,
};
