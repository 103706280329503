import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { BackButton } from '../../../../../common/components/back-button/back-button.component';
import { SettingsUrls } from '../../../../urls';
import { LayoutSC } from '../../../../../common/components/layout/layout-components.styles';
import { useParams } from 'react-router-dom';
import { TemplatesService } from '../../api';
import { EmailTemplateType, EmailUpdateType } from '../../types';
import { FormikFormInput } from '../../../../../common/components/formik/input/form-input.component';
import { Form, Formik } from 'formik';
import { FormikFormTextArea } from '../../../../../common/components/formik/textarea/form-textarea.comonent';
import clsx from 'clsx';
import styles from './TemplateEdit.module.scss';
import { PrimaryButton } from '../../../../../common/components/buttons/primary/primary.component';
import { TransparentButton } from '../../../../../common/components/buttons/transparent/transparent.component';
import styled from 'styled-components';
import { NotificationContext } from '../../../../../../shared/providers';

const FormElement = styled.form``;

export const TemplateEditPage = () => {
    const params = useParams<any>();
    const [email, setEmail] = useState<EmailTemplateType>();
    const { showNotification } = useContext(NotificationContext);
    const [isLoading, setIsLoading] = useState(false);

    const fetchEmailData = async (uuid: string) => {
        const { data } = await TemplatesService.getEmailTemplate(uuid);
        setEmail(data);
    };

    useEffect(() => {
        fetchEmailData(params.templateId);
    }, [params.templateId]);

    const submitForm = (emailData: EmailUpdateType) => {
        setIsLoading(true);
        TemplatesService.updateEmailTemplate(params.templateId, emailData)
            .then(resp => {
                setEmail(resp.data);
                showNotification('success', 'Your data has been successfully updated');
            })
            .catch(err => {
                if (err?.response) {
                    showNotification('error', err?.response.data.body[0]);
                } else {
                    showNotification('error', 'Oops, something went wrong. Please try again.');
                }
                console.log(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleRestore = () => {
        setIsLoading(true);
        TemplatesService.resetEmailTemplate(params.templateId)
            .then(resp => {
                if (email) {
                    setEmail({ ...email, subject: '', body: '' });
                }
                setEmail(resp.data);
                showNotification('success', 'Your data has been successfully updated');
            })
            .catch(err => {
                if (err?.response) {
                    showNotification('error', err?.response.data.body[0]);
                } else {
                    showNotification('error', 'Oops, something went wrong. Please try again.');
                }
                console.log(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <>
            <Helmet>
                <title>{email?.subject || ''}</title>
            </Helmet>
            <BackButton destinationText={'Email Templates'} to={SettingsUrls.templates.index} />
            <LayoutSC.PageTitle smallPadding={true}>{email?.subject || ''}</LayoutSC.PageTitle>
            <div className={styles.content}>
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        subject: email?.subject || '',
                        body: email?.body || '',
                    }}
                    onSubmit={submitForm}
                >
                    <FormElement as={Form} className={styles.cell}>
                        <FormikFormInput
                            name="subject"
                            label="Subject"
                            placeholder="Enter a Subject"
                        />
                        <FormikFormTextArea label={'Email text'} name={'body'} height={'384px'} />
                        <div className={styles.buttons}>
                            <TransparentButton
                                type={'button'}
                                onClick={() => handleRestore()}
                                disabled={isLoading}
                            >
                                Restore to default option
                            </TransparentButton>
                            <PrimaryButton type="submit" disabled={isLoading}>
                                Save
                            </PrimaryButton>
                        </div>
                    </FormElement>
                </Formik>
                <div
                    className={clsx(styles.cell, styles.description)}
                    dangerouslySetInnerHTML={{
                        __html: `Only html &lt;a&gt;, &lt;strong&gt;, &lt;br&gt;, &lt;em&gt; tags are allowed. Please no images, divs, etc. Sent to members that you create on the site manually or via webhooks. The following variables are available: {{member}}, {{username}}, {{password}}, {{site.title}}, and {{site_login_url}}`,
                    }}
                />
            </div>
        </>
    );
};
