import { useCallback } from 'react';

import { useCMSFormikImageUploadHelper } from './useCMSImageUploadHelper';
import { TrackProgressField } from 'units/common/hooks/useTrackProgress';

type Props = {
    imageKey: string,
    updateProgress?: (field: TrackProgressField) => void;
}

export function useCMSFormikImageUploadData({ imageKey, updateProgress }: Props) {
    const [icon, iconHelper] = useCMSFormikImageUploadHelper(imageKey);
  
    const handleIconSave = useCallback((files: Array<File>) => {
      iconHelper.storeFirstImage(files);
      updateProgress && updateProgress({ name: imageKey, isFilled: true })
    }, [updateProgress]);

    const handleIconDelete = useCallback((id: string) => {
      iconHelper.deleteImage(id);
      updateProgress && updateProgress({ name: imageKey, isFilled: false })
    }, [updateProgress]);

    return {
      previewFile: icon || undefined,
      saveFiles: handleIconSave,
      deleteFile: handleIconDelete,
      error: iconHelper.error
    };
}
