import styled, { css } from 'styled-components';

import { MediaQueries } from 'units/landing-page/config';

const GeneralSC = css`
  position: absolute;
  height: auto;
`;

const Root = styled.div`
  width: 100%;
  height: 100%;
`;

const Background = styled.img`
  ${GeneralSC}
  top: 0;
  left: 19%;

  @media (max-width: ${MediaQueries.tablet}) {
    max-width: 650px;
    left: 13%;
  }

  @media (max-width: ${MediaQueries.mobile}) {
    max-width: 392px;
    left: 13%;
  }
`;

const RootImage = styled.img`
  ${GeneralSC}
  top: 52px;
  left: -5%;
  z-index: 2;

  @media (max-width: ${MediaQueries.tablet}) {
    top: 43px;
    left: -17%;
    max-width: 611px;
  }

  @media (max-width: ${MediaQueries.mobile}) {
    top: 22px;
    left: -4%;
    max-width: 392px;
  }
`;

const ColorPicker = styled.img`
  ${GeneralSC}
  top: -20px;
  left: 14%;
  z-index: 3;

  @media (max-width: ${MediaQueries.tablet}) {
    top: -33px;
    left: 10%;
    max-width: 315px;
  }

  @media (max-width: ${MediaQueries.mobile}) {
    top: -26px;
    left: 16%;
    max-width: 205px;
  }
`;

const Timer = styled.img`
  ${GeneralSC}
  left: 28%;
  bottom: -150px;
  z-index: 3;
  @media (max-width: ${MediaQueries.tablet}) {
    bottom: -37px;
    left: 30%;
    max-width: 341px;
  }

  @media (max-width: ${MediaQueries.mobile}) {
    bottom: 240px;
    left: 30%;
    max-width: 219px;
  }
`;

const Template = styled.img`
  ${GeneralSC}
  bottom: -12px;
  left: 19%;
  @media (max-width: ${MediaQueries.tablet}) {
    bottom: 11px;
    left: 17%;
    max-width: 147px;
  }

  @media (max-width: ${MediaQueries.mobile}) {
    bottom: 273px;
    left: 21%;
    max-width: 94px;
  }
`;

export const LPOptimizationSC = {
  Root,
  Background,
  ColorPicker,
  RootImage,
  Timer,
  Template
}
