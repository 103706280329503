import React, { FC, Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { ProductItemSC } from './product-item.styles';
import { TransparentButton } from 'units/common/components/buttons/transparent/transparent.component';
import { IconSC, IconUnicodes } from 'components/styles/icons.styles';
import { ProductsUrls } from '../../../urls';
import { Product } from '../../../api/types';
import { DateHelper } from 'shared/helpers';

interface Props extends Product {
  deleteProduct: (id: string) => void,
}

const twoDigitFormat = (value: number) => {
  let formatted: number | string = value;

  if (formatted < 10) {
    formatted = `0${formatted}`
  }

  return formatted.toString();
};

const calculateTimeLeft = (startTime: string | null, endTime: string | null, isPublished: boolean) => {
  const timeLeft = ['-', '-', '-'];
  const now = +new Date();
  const start = startTime ? +DateHelper.convertUtcToLocal(startTime) : null;
  const end = endTime ? +DateHelper.convertUtcToLocal(endTime) : null;
  const isActive = (start && end) && (now > start && now < end);

  if (isPublished && isActive && end) {
    const difference = end - now;
    timeLeft[0] = twoDigitFormat(Math.floor((difference / (1000 * 60 * 60))));
    timeLeft[1] = twoDigitFormat(Math.floor((difference / 1000 / 60) % 60));
    timeLeft[2] = twoDigitFormat(Math.floor((difference / 1000) % 60));
  }

  return timeLeft;
};

export const ProductItem: FC<Props> = ({
  id,
  name,
  images,
  status,
  quantity,
  published,
  endTime,
  startTime,
  deleteProduct,
}) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(startTime, endTime, published));

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(startTime, endTime, published));
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <ProductItemSC.Container>
      <ProductItemSC.Image src={images[0] && images[0].image} alt={id.toString()} />
      <ProductItemSC.Details>
        <ProductItemSC.Name>
          {name}
        </ProductItemSC.Name>

        <ProductItemSC.Status className={status.toLowerCase()}>
          {status}
        </ProductItemSC.Status>

        <ProductItemSC.Info>
          <span>Quantity:</span>
          <ProductItemSC.NumberBlock>
            {quantity}
          </ProductItemSC.NumberBlock>
        </ProductItemSC.Info>

        <ProductItemSC.Info className='no-margin'>
          <span>Time remaining:</span>
          <ProductItemSC.Timer>
            {timeLeft.map((item, index) => (
              <Fragment key={`product_rt_${index}`}>
                <ProductItemSC.NumberBlock key={item}>
                  {item}
                </ProductItemSC.NumberBlock>
                {index !== 2 && <span className='colon'>:</span>}
              </Fragment>
            ))}
          </ProductItemSC.Timer>
        </ProductItemSC.Info>
      </ProductItemSC.Details>

      <ProductItemSC.HoveredContainer>
        <ProductItemSC.ButtonContainer>
          <Link to={`${ProductsUrls.index}/${id}`}>
            <TransparentButton>
              <IconSC name={IconUnicodes.pensil} />
              Edit
            </TransparentButton>
          </Link>
          {!published && (
            <TransparentButton onClick={() => deleteProduct(id)}>
              <IconSC name={IconUnicodes.trash} />
              Delete
            </TransparentButton>
          )}
        </ProductItemSC.ButtonContainer>
      </ProductItemSC.HoveredContainer>
    </ProductItemSC.Container>
  )
};
