import React, { useEffect, useState } from 'react';
import { Form, useFormikContext } from 'formik';
import { useSelector } from 'react-redux';

import { AuthSC } from 'units/common/styles/auth/auth.styles';
import { CreatePasswordInput } from '../../../components/create-password-input.component/create-password-input.component';
import { SignUpFormValues } from '../types';
import { RootState } from 'redux/root-types';
import { UserState } from 'units/user/redux/types';
import { useFormikFieldErrors } from 'units/common/hooks/useFieldErrors';
import { FormikUserInput } from 'units/common/components/formik/input/user-input.component';
import { FormikSelect } from 'units/common/components/formik/select/select.component';
import { useFormikApiErrors } from 'units/common/components/formik/hooks/useFormikApiErrors';
import { UserErrorKeys } from 'units/user/constants';
import { api } from 'api';
import { OptionsType } from 'react-select';
import { IOption } from 'units/common/components/select/select.component';

export const SignUpForm = () => {
    const [accountTypes, setAccountTypes] = useState<OptionsType<IOption<string>>>();
    const { error: userError } = useSelector<RootState, UserState>(state => state.user);
    const { setFieldValue, isValid } = useFormikContext();

    useEffect(() => {
        api.authService.getAccountType().then(({ data }) => {
            const result = data?.map(item => ({ label: item.label, value: item.slug }));
            if (result) {
                setAccountTypes(result);
                setFieldValue('type', result[0].value);
            }
        });
    }, []);

    const apiErrors = useFormikApiErrors(userError?.[UserErrorKeys.registration]);
    const fieldError = useFormikFieldErrors<SignUpFormValues>({
        additionalErrors: apiErrors,
    });

    /*     const isSubmitEnabled = useIsFormikSubmitEnabled(); */

    return (
        <AuthSC.AuthPageForm as={Form}>
            <AuthSC.AuthPageInputList>
                <FormikUserInput
                    name="companyName"
                    label="Company name"
                    placeholder="Enter your company name"
                    error={fieldError.companyName}
                />
                <FormikUserInput
                    name="email"
                    label="Email"
                    placeholder="Enter your email"
                    type="email"
                    error={fieldError.email}
                />
                <CreatePasswordInput
                    name="password"
                    label="Password"
                    placeholder="Enter your password"
                    type="password"
                    error={fieldError.password}
                />
                <FormikUserInput
                    name="repeatPassword"
                    label="Repeat password"
                    placeholder="Enter your password once again"
                    type="password"
                    highlightError
                    error={fieldError.repeatPassword}
                />
                {accountTypes && (
                    <FormikSelect label="Account type" name="type" options={accountTypes} />
                )}
            </AuthSC.AuthPageInputList>
            <AuthSC.AuthPagePrimaryButton disabled={!isValid} type="submit">
                Register
            </AuthSC.AuthPagePrimaryButton>
        </AuthSC.AuthPageForm>
    );
};
