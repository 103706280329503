import styled from 'styled-components';
import 'react-datepicker/dist/react-datepicker.css';

import { FormInputLabelText, FormSubLabelText, FormInputErrorText } from 'components/styles/text.styles';
import { Color, sizes } from 'components/styles/constants.styles';

const Container = styled.div`
  margin: 0 20px ${sizes.sm_padding} 0;
`;

const PickerContainer = styled.div`
  .react-datepicker__triangle {
    left: 50% !important;
  }

  input {
    width: 270px;
    border: none;
    padding: ${sizes.xs_padding} 20px;
    border-radius: ${sizes.border_radius};

    &:focus {
      outline: none;
    }

    &::placeholder {
      ${FormSubLabelText};
    }

    &:disabled {
      background-color: ${Color.semi_grey};
      cursor: not-allowed;
      color: initial;
    }
  }
`;

const LabelContainer = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

const Label = styled.label`
  ${FormInputLabelText};
`;

const Error = styled.p`
  ${FormInputErrorText}

  margin: 8px 0 0 0;
`;

export const DatePickerSC = {
  Container,
  PickerContainer,
  LabelContainer,
  Label,
  Error,
};
