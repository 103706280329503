import styled from 'styled-components';

import { Color, sizes } from 'components/styles/constants.styles';
import { FormInputLabelText } from 'components/styles/text.styles';

const Label = styled.p<{ disabled?: boolean }>`
  margin-bottom: 8px;
  display: flex;

  ${FormInputLabelText}

  ${({ disabled }) => disabled && `color: ${Color.disabled_text_grey}`};
`;

const Wrapper = styled.div``;

const Container = styled.div<{ disabled?: boolean }>`
  position: relative;

  ${({ disabled }) => disabled && 'opacity: 0.4;'};

  &::after {
    display: ${({ disabled }) => disabled ? 'block' : 'none'};
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: not-allowed;
  }
`;

const PreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 20px;
  min-height: 224px;
  
  border-radius: ${sizes.border_radius};

  background-color: ${Color.white};
`;

const PreviewImage = styled.img`
  max-width: 50%;
  margin-bottom: 32px;
`;

const ErrorLabel = styled.span`
  margin-left: 8px;

  color: ${Color.watermelon};
`;

export const CMSUploaderSC = {
  Wrapper,
  Container,
  PreviewContainer,
  PreviewImage,
  Label,
  ErrorLabel,
};
