import React, { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';

import {
    CMSCustomizationLanguageProgressContainer,
    CMSCustomizationLanguageProgressContainerProps,
    CMSProgressBeforeUpdate,
} from '../../../progress-container/progress-container';
import { CmsAcordionSection } from 'units/cms/components/cms-acordion-section/cmsAcordionSection.component';
import { CMSHomepageNewsletterAccordion } from './newsletter-accordion.component';
import { ThirdPartyIntegrationSource } from 'units/app/redux/types';
import { RootState } from 'redux/root-types';

type Props = Pick<
    CMSCustomizationLanguageProgressContainerProps,
    'selectedLanguage' | 'onLanguageChange' | 'initialProgress'
> & {
    withDevider?: boolean;
};

export const CMSHomepageNewsletterAccordionContainer: FC<Props> = ({ withDevider, ...props }) => {
    const mailchimpIntegrated = useSelector<RootState, boolean>(
        ({ app: { integrations } }) =>
            !!integrations.find(item => item.source === ThirdPartyIntegrationSource.mailchimp),
    );
    const handleBeforeUpdateProgress = useCallback(
        (...[field, { updateProgressFields }]: Parameters<CMSProgressBeforeUpdate>) => {
            if (field.name === 'isActive') {
                updateProgressFields([
                    {
                        key: 'title',
                        operation: field.isFilled ? 'add' : 'delete',
                    },
                    {
                        key: 'icon',
                        operation: field.isFilled ? 'add' : 'delete',
                    },
                ]);

                return false;
            }

            return true;
        },
        [],
    );

    return (
        <CmsAcordionSection lastSection withDevider={withDevider}>
            <CMSCustomizationLanguageProgressContainer
                hideProgress={!mailchimpIntegrated}
                {...props}
                beforeUpdate={handleBeforeUpdateProgress}
            >
                {({ updateCurrentProgress }) => (
                    <CMSHomepageNewsletterAccordion
                        updateProgress={updateCurrentProgress}
                        mailchimpIntegrated={mailchimpIntegrated}
                    />
                )}
            </CMSCustomizationLanguageProgressContainer>
        </CmsAcordionSection>
    );
};
