export const UserErrorKeys = {
  registration: 'user_registration_error',
  login: 'user_login_error',
  confirmEmail: 'user_confirm_email_error',
  resendConfirmEmail: 'user_resend_confirm_email_error',
  resetPassword: 'user_reset_password_error',
  confirmResetPassword: 'user_confirm_reset_password_error',
  signOut: 'sign_out_error',
  fetchMyAccountData: 'user_fetch_my_account_data_error',
  updateMyAccountData: 'user_update_account_data_error',
  acceptInvitation: 'user_accept_invitation_error',
  loginWelcome: 'welcome_flow_error',
};

export const loginWelcome = {
  url: '/sign-in/welcome'
};
