import styled, { css } from 'styled-components';

import { PrimaryButton } from 'units/common/components/buttons/primary/primary.component';

import { Font } from 'components/types/enums';

import { SectionTitleText } from 'components/styles/text.styles';
import { Color } from 'components/styles/constants.styles';

const ContentPartsCSS = css`
    width: 50%;
    padding-left: 10px;
    padding-right: 10px;
`;

const BlockCSS = css`
    background-color: #fff;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 32px;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 19px;
    align-items: center;
`;

const Status = styled.div`
    display: flex;
    margin-bottom: 22px;
    align-items: center;
`;

const Title = styled.div<{ marginRight?: number }>`
    ${SectionTitleText}
    ${({ marginRight }) => marginRight && `margin-right: ${marginRight}px`};
`;

const Text = styled.div`
    color: ${Color.bluey_grey};
    line-height: 22px;
    font-size: 14px;
    a {
        color: ${Color.bluey_grey};
        &:hover {
            text-decoration: none;
        }
    }
`;

const FullFillBtn = styled(PrimaryButton)`
    margin-left: 15px;
`;

const PriceWrapper = styled.div`
    display: flex;
    margin-top: 18px;
    align-items: center;
`;

const Price = styled.div`
    margin-left: 27px;
    font-size: 24px;
    font-family: ${Font.bold};
`;

const NoHistory = styled.div`
    opacity: .3;
    padding-top: 10px;
`;

const IconWrapper = styled.div`
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: ${Color.pale_grey};
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ProductBlock = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: -5px;
    ${BlockCSS}
`;

const CustomerBlock = styled.div`
    ${BlockCSS}
`;

const PaymentBlock = styled.div`
    ${BlockCSS}
`;

const HistoryBlock = styled.div`
    ${BlockCSS}
`;

const CustomerName = styled.div`
    font-size: 24px;
    font-family: ${Font.bold};
    margin-top: 9px;
    margin-bottom: 15px;
`;

const Content = styled.div`
    display: flex;
    margin-left: -10px;
    margin-right: -10px;
`;

const CustomerInfo = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: -5px;
    align-items: flex-end;
`;

const HistoryBody = styled.div`
    margin-top: 14px;
    max-height: 266px;
    overflow-y: auto;
`;

const HistoryLineRight = styled.div`
    position: relative;
    width: 41%;
    text-align: left;
    font-size: 14px;
    line-height: 21px;
    &:before {
        content: '';
        position: absolute;
        left: -24px;
        width: 9px;
        height: 9px;
        top: 50%;
        margin-top: -4px;
        border-radius: 50%;
        background-color: ${Color.lightish_blue};
    }
    &:after {
        content: '';
        height: 47px;
        width: 2px;
        position: absolute;
        left: -21px;
        top: 13px;
        background-color: ${Color.lightish_blue};
    }
`;

const HistoryLine = styled.div`
    display: flex;
    padding: 15px 0;
    justify-content: space-between;
    &:last-child {
        ${HistoryLineRight}:after {
            display: none;
        }
    }
`;

const ContentLeft = styled.div`
    ${ContentPartsCSS}
`;

const ContentRight = styled.div`
    ${ContentPartsCSS}
`;

const Block = styled.div`
    background-color: #fff;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 32px;
`;

const PaymentBlockTitle = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 27px;
`;

const PaymentStatus = styled.div<{ isSuccess:boolean }>`
    font-size: 14px;
    color: ${Color.success_green};
    margin-left: 10px;
    ${({ isSuccess }) => `color: ${isSuccess ? Color.success_green : Color.error_red}`}
`;

const PaymentLine = styled.div<{ isTotal?:boolean }>`
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    ${({ isTotal }) => `
        margin-bottom: ${!isTotal ? '21px': '40px'};
        ${isTotal && `
            div {
                color: ${Color.lightish_blue};
                font-family: ${Font.bold};
            }
        `}
    `}
`;

const PaymentValue = styled.div`
    font-family: ${Font.bold};
`;

const PaymentFooter = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const DetailSC = {
    Header,
    Title,
    Text,
    Content,
    ContentLeft,
    ContentRight,
    Block,
    FullFillBtn,
    Status,
    PriceWrapper,
    Price,
    IconWrapper,
    ProductBlock,
    CustomerBlock,
    CustomerName,
    CustomerInfo,
    HistoryBlock,
    HistoryLine,
    HistoryLineRight,
    HistoryBody,
    PaymentBlock,
    PaymentBlockTitle,
    PaymentStatus,
    PaymentLine,
    PaymentValue,
    PaymentFooter,
    NoHistory
}
