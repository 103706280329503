import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Preloader } from 'shared/components/ui';
import { SelectData } from 'units/analytics/pages/types';
import { Select } from 'units/common/components/select/select.component';
import styles from './ProductAuctionSelect.module.scss';

export interface ProductAuctionSelectProps {
    products?: any;
    auctions?: any;
    isLoading: boolean;
    onProductSelect: (e: any) => void;
    onAuctionSelect: (e: any) => void;
    selectedAuction: SelectData;
}

export const ProductAuctionSelect: FC<ProductAuctionSelectProps> = ({
    products,
    auctions,
    isLoading,
    onProductSelect,
    onAuctionSelect,
    selectedAuction,
}) => {
    const { t } = useTranslation();

    return (
        <div className={styles.selects}>
            <div className={styles.selectWrapper}>
                <Select
                    options={products}
                    label={t('analytics_page_product_label')}
                    onChange={onProductSelect}
                />
            </div>
            <div className={styles.selectWrapper}>
                <Select
                    options={
                        auctions
                            ? [
                                  { label: `${t('analytics_page_all_auctions')}`, value: 'all' },
                                  ...auctions,
                              ]
                            : []
                    }
                    label={t('analytics_page_auction_label')}
                    onChange={onAuctionSelect}
                    isDisabled={!auctions}
                    noOptionsMessage={() => t('analytics_page_no_auctions_text')}
                    value={!auctions ? { value: '', label: '' } : selectedAuction}
                    // defaultValue={{ value: 'all', label: `${t('analytics_page_all_auctions')}` }}
                />
                {isLoading && (
                    <div className={styles.loader}>
                        <Preloader.Content height={38} />
                    </div>
                )}
            </div>
        </div>
    );
};
