import { useSelector } from 'react-redux';

import { RootState } from 'redux/root-types';
import { CMSGoogleFonts } from 'units/cms/store/types/index';

export const useCMSGoogleFonts = () => {
  const fonts = useSelector<RootState, CMSGoogleFonts>(
    ({ cms: { googleFonts } }) => googleFonts,
  );

  return fonts;
};
