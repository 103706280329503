import React, { FC } from 'react';
import { FieldProps } from 'formik';

import { RadioSC } from './radio.styles';

interface Props extends FieldProps {
  id: string;
  label?: string;
  error?: string;
  disabled?: boolean;
  withBackground?: boolean;
}

export const RadioButton: FC<Props> = ({
  id,
  label,
  disabled,
  withBackground,
  field: { value, ...restField },
}) => (
  <RadioSC.RadioButtonContainer withBackground={withBackground} disabled={disabled}>
    <input
      {...restField}
      id={id}
      value={id}
      type="radio"
      disabled={disabled}
      checked={id === value}
    />
    <label htmlFor={id}>{label}</label>
    <RadioSC.Checkmark withBackground={withBackground} />
  </RadioSC.RadioButtonContainer>
);
