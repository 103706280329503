import get from 'lodash.get';

import {
    prepareCustomizationProgress as helpers,
    CMSProgressInitialSection,
    CMSUpdateFilledProgress,
    CMSCustomizationCommonProgress,
} from '../prepare-customization-progress';
import {
    CMSCustomizationHomepageSectionName,
    CMSCustomizationHomepageProgressField,
} from '../../types';
import { CMSHomePageFormikCustomizationSettings } from '../../store/types/customization/homepage';
import { CompanyLanguages } from 'units/app/redux/types';
import { CMSCustomizationOverallSettings } from '../../store/types/customization';

// Alias
type HP_SN = CMSCustomizationHomepageSectionName;
type HP_PK = CMSCustomizationHomepageProgressField;

export const prepareHomePageProgress = (
    overallSettings: CMSCustomizationOverallSettings<CMSHomePageFormikCustomizationSettings>,
) => {
    // Hold one language settings which gonna be used in common comparison.
    // Take the first one who is already published or, if it's first time work, take first one.
    const commonSettings =
        Object.values(overallSettings).find(lngSettings => lngSettings?.meta?.hasBeenPublished) ||
        Object.values(overallSettings)[0];

    if (!commonSettings) {
        return {};
    }

    const languages = Object.keys(overallSettings) as Array<CompanyLanguages>;
    // Holds sections with fields that is required for progress.
    const requiredProgress = helpers.initializeRequiredProgress<HP_SN, HP_PK>(
        homepageInitialSections,
    );
    // Holds sections with fields that already filled based on overallSettings.
    const filledProgress = helpers.initializeFilledProgress<HP_SN, HP_PK>(
        homepageInitialSections.map(s => ({
            ...s,
            initialProgress: [],
        })),
        languages,
    );

    // Optional required progress
    // Hero section

    if (commonSettings.hero.conversationSticker.isActive) {
        helpers.updateRequiredProgress<HP_SN, HP_PK>(requiredProgress, 'hero', [
            'conversationSticker.icon',
        ]);
    }

    // USP section
    if (commonSettings.usp.isActive) {
        helpers.updateRequiredProgress<HP_SN, HP_PK>(requiredProgress, 'usp', USPProgressList);
    }

    // Text block section
    const textBlockSection = commonSettings.textBlock;
    if (textBlockSection.isActive) {
        helpers.updateRequiredProgress<HP_SN, HP_PK>(requiredProgress, 'textBlock', [
            'description',
        ]);

        if (textBlockSection.renderMode === 'picture') {
            helpers.updateRequiredProgress<HP_SN, HP_PK>(requiredProgress, 'textBlock', [
                'picture',
            ]);
        } else {
            helpers.updateRequiredProgress<HP_SN, HP_PK>(requiredProgress, 'textBlock', ['title']);
        }

        if (textBlockSection.textLink.isActive) {
            helpers.updateRequiredProgress<HP_SN, HP_PK>(requiredProgress, 'textBlock', [
                'textLink.name',
                'textLink.target',
            ]);
        }
    }

    // Newsletter option
    if (commonSettings.newsletterOption.isActive) {
        helpers.updateRequiredProgress(requiredProgress, 'newsletterOption', ['title', 'icon']);
    }

    // Footer section
    // If social link is active = url is required
    const socialLinks: Array<HP_PK> = Object.entries(commonSettings.footer.socialLinks).reduce(
        (res, [linkKey, link]) => (link.isActive ? [...res, `${linkKey}.url` as HP_PK] : res),
        [] as Array<HP_PK>,
    );

    if (socialLinks.length) {
        helpers.updateRequiredProgress<HP_SN, HP_PK>(requiredProgress, 'footer', socialLinks);
    }

    // If footer link has filled name = url is required
    const footerLinks: Array<HP_PK> = commonSettings.footer.footerLinks.reduce(
        (res, link, index) => (link.name ? [...res, `footerLinks.[${index}].url` as HP_PK] : res),
        [] as Array<HP_PK>,
    );

    if (footerLinks.length) {
        helpers.updateRequiredProgress<HP_SN, HP_PK>(requiredProgress, 'footer', footerLinks);
    }

    // Prefilled progress
    for (const [lng, lngSettings] of Object.entries(overallSettings)) {
        if (!lngSettings) {
            continue;
        }

        const language = lng as CompanyLanguages;
        const updates = prepareHomePageFilledProgress(lngSettings);
        helpers.updateFilledProgress(filledProgress, language, updates);
    }

    return {
        sectionProgress: helpers.prepareSectionProgress(
            requiredProgress,
            filledProgress,
            commonFields,
        ),
    };
};

const prepareHomePageFilledProgress = ({
    hero,
    textBlock,
    footer,
    productCards,
    usp,
    newsletterOption,
}: CMSHomePageFormikCustomizationSettings) => {
    const updates: Array<CMSUpdateFilledProgress<HP_SN, HP_PK>> = [];

    // Hero section
    let sectionKeys: Array<HP_PK> = [];

    hero.CTAcopy && sectionKeys.push('CTAcopy');
    hero.conversationSticker.icon && sectionKeys.push('conversationSticker.icon');
    updates.push({ sectionName: 'hero', progressKeys: sectionKeys });

    // USP section
    sectionKeys = [];

    USPProgressList.forEach(key => {
        get(usp, key) && sectionKeys.push(key);
    });
    updates.push({ sectionName: 'usp', progressKeys: sectionKeys });

    // Text block section
    sectionKeys = [];

    textBlock.description && sectionKeys.push('description');
    textBlock.picture && sectionKeys.push('picture');
    textBlock.title && sectionKeys.push('title');
    textBlock.textLink.name && sectionKeys.push('textLink.name');
    textBlock.textLink.target && sectionKeys.push('textLink.target');
    updates.push({ sectionName: 'textBlock', progressKeys: sectionKeys });

    // Product card section
    sectionKeys = [];

    productCards.CTAText && sectionKeys.push('CTAText');
    productCards.loadMoreLink && sectionKeys.push('loadMoreLink');
    productCards.sectionTitle && sectionKeys.push('sectionTitle');
    updates.push({ sectionName: 'productCards', progressKeys: sectionKeys });

    // newsletter section
    sectionKeys = [];

    newsletterOption.title && sectionKeys.push('title');
    newsletterOption.icon && sectionKeys.push('icon');
    updates.push({ sectionName: 'newsletterOption', progressKeys: sectionKeys });

    // footer section
    sectionKeys = [];

    footer.title && sectionKeys.push('footerTitle');
    footer.staticLinks.dataProtection && sectionKeys.push('dataProtection');
    footer.staticLinks.disclaimer && sectionKeys.push('disclaimer');
    footer.staticLinks.termsAndConditions && sectionKeys.push('termsAndConditions');

    footer.socialLinks.instagram.url && sectionKeys.push('instagram.url');
    footer.socialLinks.facebook.url && sectionKeys.push('facebook.url');
    footer.socialLinks.twitter.url && sectionKeys.push('twitter.url');
    footer.socialLinks.snapchat.url && sectionKeys.push('snapchat.url');
    footer.socialLinks.linkedin.url && sectionKeys.push('linkedin.url');
    footer.socialLinks.tiktok.url && sectionKeys.push('tiktok.url');

    footer.footerLinks.forEach(
        (fl, index) => fl.url && sectionKeys.push(`footerLinks.[${index}].url` as HP_PK),
    );
    updates.push({ sectionName: 'footer', progressKeys: sectionKeys });

    return updates;
};

const homepageInitialSections: Array<CMSProgressInitialSection<HP_SN, HP_PK>> = [
    {
        name: 'hero',
        initialProgress: ['CTAcopy'],
    },
    {
        name: 'usp',
        initialProgress: [],
    },
    {
        name: 'textBlock',
        initialProgress: [],
    },
    {
        name: 'productCards',
        initialProgress: ['CTAText', 'loadMoreLink', 'sectionTitle'],
    },
    {
        name: 'newsletterOption',
        initialProgress: [],
    },
    {
        name: 'footer',
        initialProgress: ['footerTitle', 'disclaimer', 'dataProtection', 'termsAndConditions'],
    },
];

export const commonFields: CMSCustomizationCommonProgress<HP_SN, HP_PK> = {
    hero: ['conversationSticker.icon'],
    usp: ['usp1.icon', 'usp2.icon', 'usp3.icon'],
    textBlock: ['picture', 'textLink.target'],
    productCards: [],
    newsletterOption: ['icon'],
    footer: [
        'footerLinks.[0].url',
        'footerLinks.[1].url',
        'footerLinks.[2].url',
        'footerLinks.[3].url',
        'instagram.url',
        'facebook.url',
        'twitter.url',
        'snapchat.url',
        'linkedin.url',
        'tiktok.url',
    ],
};

export const USPProgressList: Array<HP_PK> = [
    'usp1.title',
    'usp1.icon',
    'usp2.title',
    'usp2.icon',
    'usp3.title',
    'usp3.icon',
];
