import React, { useState, useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

import { AuctionUrls } from '../../urls';
import { LayoutSC } from 'units/common/components/layout/layout-components.styles';
import { SecondaryButton } from 'units/common/components/buttons/secondary/secondary.component';
import { TabSwitch } from 'units/common/components/tab-switch/tab-switch.component';
import { AuctionsList } from './components/auctions-list/auctions-list.component';
import { AuctionsSC } from './auctions.styles';
import {
    deleteAuction,
    archiveAuction,
    fetchCurrentAuctions,
    fetchArchivedAuctions,
} from './api/auctions.service';
import { AuctionList, TableData } from './types';
import { StatusComp } from './components/status/status.component';
import { Actions } from './components/actions/actions.component';
import { AppStrings } from 'config/strings';
import { NotificationContext } from 'shared/providers';
import { Preloader } from 'shared/components/ui';

const tabs = [
    { id: 'current_auctions', title: 'Current auctions' },
    { id: 'archived_auctions', title: 'Archived auctions' },
];

const columns = [
    {
        Header: 'Auction',
        accessor: 'productName',
        sortBy: true,
    },
    {
        Header: 'Start date',
        accessor: 'launchDate',
        sortBy: true,
    },
    {
        Header: 'Duration',
        accessor: 'duration',
        sortBy: true,
    },
    {
        Header: 'End date',
        accessor: 'endDate',
        sortBy: true,
    },
    {
        Header: 'Status',
        accessor: 'status',
        sortBy: true,
    },
    {
        Header: '',
        accessor: 'actions',
        sortBy: false,
    },
];

const ITEMS_PER_PAGE = 10;

export const Auctions = () => {
    const { showNotification } = useContext(NotificationContext);
    const [selectedTab, setSelectedTab] = useState(0);
    const [currentAuctionsData, setCurrentAuctionsData] = useState<Array<TableData>>([]);
    const [archivedAuctionsData, setArchivedAuctionsData] = useState<Array<TableData>>([]);
    const [pagesLength, setPagesLength] = useState<number>(0);
    const [loading, setLoading] = useState(true);
    const [filters, setFilters] = useState('');
    const [sorting, setSorting] = useState('');

    useEffect(() => {
        const currTab = localStorage.getItem('archivedTab');
        if (currTab) {
            setSelectedTab(Number(currTab));
        }
        localStorage.setItem('archivedTab', '');
        getAuctions(currTab ? Number(currTab) : 0);
    }, []);

    const fetchAuctions = async (selectedTab: number, page: number) => {
        if (selectedTab === 0) {
            const data = await fetchCurrentAuctions(1, page, filters, sorting);
            setPagesLength(Math.ceil(data.count / ITEMS_PER_PAGE));
            setCurrentAuctionsData(prepareTableData(data.results));
        } else if (selectedTab === 1) {
            const data = await fetchArchivedAuctions(1, page, filters, sorting);
            setPagesLength(Math.ceil(data.count / ITEMS_PER_PAGE));
            setArchivedAuctionsData(prepareTableData(data.results));
        }
    };

    useEffect(() => {
        fetchAuctions(selectedTab, 1);
    }, [selectedTab]);

    useEffect(() => {
        if (sorting || filters) {
            fetchAuctions(selectedTab, 1);
        }
        if (!sorting) {
            fetchAuctions(selectedTab, 1);
        }
    }, [filters, sorting]);

    const getAuctions = async (selectedTab: number) => {
        await fetchAuctions(selectedTab, 1);
        setLoading(false);
    };

    const onDelete = async (id: string) => {
        try {
            await deleteAuction(id);
            showNotification('success', AppStrings.auctionDeleteSuccess);
            getAuctions(0);
        } catch {
            showNotification('error', AppStrings.errorNotification);
        }
    };

    const onArchive = async (id: string) => {
        try {
            await archiveAuction(id);
            showNotification('success', AppStrings.auctionArchiveSuccess);
            getAuctions(0);
        } catch {
            showNotification('error', AppStrings.errorNotification);
        }
    };

    const prepareTableData = (data: AuctionList) =>
        data?.map(item => ({
            productName: `${item.auctionName}`,
            launchDate: item.launchDate,
            duration: <AuctionsSC.Text>{item.duration}</AuctionsSC.Text>,
            endDate: item.endDate,
            status: <StatusComp status={item.status} />,
            actions: <Actions data={item} onDelete={onDelete} onArchive={onArchive} />,
        }));

    const onPaginationChange = (selectedItem: number) => {
        fetchAuctions(selectedTab, selectedItem + 1);
    };

    const filterAuctions = (filters: string[]) => {
        filters = filters.map(f => (f === 'Unscheduled' ? 'Draft' : f));
        const filt = filters.length > 0 ? filters.join(',') : 'null';
        setFilters(filt);
    };

    const sortAuctions = (sort: any) => {
        if (sort.length > 0) {
            const sortString = mapSortStrings(sort[0].id);
            sort[0].desc ? setSorting(`${sortString}_desc`) : setSorting(sortString);
        } else {
            setSorting('');
        }
    };

    const mapSortStrings = (id: string) => {
        switch (id) {
            case 'launchDate':
                return 'starts_at';
            case 'duration':
                return 'duration';
            case 'endDate':
                return 'ends_at';
            case 'status':
                return 'status';
            default:
                return 'auction_name';
        }
    };

    const renderTabContent = () => {
        const data = selectedTab === 0 ? currentAuctionsData : archivedAuctionsData;

        return (
            <AuctionsList
                columns={columns}
                data={data}
                numOfPages={pagesLength}
                onPageChange={onPaginationChange}
                onFilterChange={filterAuctions}
                onSorting={sortAuctions}
            />
        );
    };

    if (loading) return <Preloader.Page height={620} />;

    return (
        <>
            <Helmet>
                <title>Auctions</title>
            </Helmet>
            <LayoutSC.PageTitleContainer>
                <h1>Auctions</h1>
                <Link to={AuctionUrls.createAuction}>
                    <SecondaryButton isWide>+ Create New Auction</SecondaryButton>
                </Link>
            </LayoutSC.PageTitleContainer>
            <TabSwitch tabs={tabs} selectedTabIndex={selectedTab} onTabClick={setSelectedTab} />
            <AuctionsSC.Container>{renderTabContent()}</AuctionsSC.Container>
        </>
    );
};
