import { AxiosError } from 'axios';

import { ErrorState, ErrorValue } from '../types';
import { history } from 'helpers/history';
import { CreateResetPasswordErrorRes } from 'units/user/redux/types';
import { UserUrls } from 'units/user/urls';

export const updateErrorState = <T>(errors: ErrorState, key: string, value: T | ErrorValue):any => ({
  ...errors,
  [key]: value,
});

export const transformErrorFromApi = <ErrorData>(error: AxiosError<ErrorData>) => {
  if (error.response) {
    return error.response.data;
  }

  throw error;
};

export const handleTokenError = (
  errors: CreateResetPasswordErrorRes,
  fallbackMessage: string,
): void => {
  if (errors.detail) {
    history.push(UserUrls.login);
  } else if (errors.token || errors.uid) {}
};

// Todo wrapper around service call in thunk action.
// const handleApiCall = <T>(apiCall: Promise<T>, resolvers: {
//   success: (data: T) => void,
//   failure: (error: AxiosError<T>) => void,
// }): void => {
//   apiCall.then((response) => resolvers.success(response)).catch((error) => resolvers.failure(error));
// };

