import { UserInputValidationErrors } from 'units/common/constants';
import { yup } from 'config/yup';

export const userEditValidationSchema = yup.object({
  email: yup.string()
    .email(UserInputValidationErrors.email.notValid)
    .required(UserInputValidationErrors.email.required),
  lastName: yup.string()
    .required(UserInputValidationErrors.lastName.required),
  firstName: yup.string()
    .required(UserInputValidationErrors.firstName.required),
  phone: yup.string()
    .phone()
    .nullable(),
});
