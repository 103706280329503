import React, { useCallback, useState, FC, useEffect, useMemo, useContext } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { SignUpSC } from './sign-up.styles';
import { AuthSC } from 'units/common/styles/auth/auth.styles';
import { SignUpForm } from './form/sign-up-form.component';
import { SignUpFormValues } from './types';
import { signUpValidationSchema } from './validation';
import { signUpThunk, resendConfirmSignUpEmailThunk } from '../../redux/thunks';
import { RootState } from 'redux/root-types';
import { UserState } from '../../redux/types';
import { CheckEmail } from '../../components/check-email/check-email.component';
import { AppStrings, Appi18nStrings } from 'config/strings';
import { useAsyncDispatch } from 'helpers/hooks/useAsyncDispatch';
import { AppUrls } from 'app/urls';
import { setUserErrorState, setUserTempPayload } from '../../redux/action-creators';
import { UserErrorKeys } from '../../constants';
import { useDeviceDetector } from 'units/landing-page/hooks';
import { NotificationContext } from 'shared/providers';

const initialValues: SignUpFormValues = {
    companyName: '',
    email: '',
    password: '',
    repeatPassword: '',
    type: '',
};

export const SignUp: FC = () => {
    const { showNotification } = useContext(NotificationContext);
    const [showConfirmEmailScreen, setShowConfirmEmailScreen] = useState(false);
    const { device } = useDeviceDetector();
    const { user, tempPayload } = useSelector<RootState, UserState>(state => state.user);
    const { dispatch } = useAsyncDispatch();
    const syncDispatch = useDispatch();
    const history = useHistory();

    const emailConfirmationText = useMemo(
        () => ({
            title: 'Registration successful',
            subTitle:
                'We’ve sent you a verification email to your email account. Please follow the link to activate your account.',
            CTA: `Back to ${device === 'mobile' || device === 'tablet' ? 'landing page' : 'login'}`,
        }),
        [device],
    );

    const submitRegistration = useCallback(
        async (values: SignUpFormValues, helpers: FormikHelpers<SignUpFormValues>) => {
            await dispatch(signUpThunk(values, setShowConfirmEmailScreen.bind(null, true)));
            // clear hiddenErrors
            helpers.setStatus({});
        },
        [dispatch],
    );

    const resendConfirmEmail = useCallback(() => {
        if (user?.email) {
            dispatch(resendConfirmSignUpEmailThunk(user.email, showNotification));
        } else {
            showNotification('error', AppStrings.errorNotification);
        }
    }, [dispatch, user]);

    const goToPage = useCallback(() => {
        history.push(
            device === 'mobile' || device === 'tablet'
                ? AppUrls.landing.landingPage
                : AppUrls.user.login,
        );
    }, [history, device]);

    useEffect(
        () => () => {
            // Clear error state
            syncDispatch(setUserErrorState(UserErrorKeys.registration, null));
            syncDispatch(setUserTempPayload('registration', { prefilledEmail: null }));
        },
        [],
    );

    const formikInitialValues = useMemo(
        () => ({
            ...initialValues,
            email: tempPayload.registration?.prefilledEmail || initialValues.email,
        }),
        [tempPayload],
    );

    return (
        <>
            {showConfirmEmailScreen ? (
                <CheckEmail
                    texts={emailConfirmationText}
                    resendClickHandler={resendConfirmEmail}
                    CTAClickHandler={goToPage}
                />
            ) : (
                <AuthSC.AuthPageContainer mobileMarginTop="8px" tabletMarginTop="64px">
                    <AuthSC.AuthPageTitle>Registration</AuthSC.AuthPageTitle>
                    <Formik
                        initialValues={formikInitialValues}
                        onSubmit={submitRegistration}
                        validationSchema={signUpValidationSchema}
                    >
                        <SignUpForm />
                    </Formik>
                    <SignUpSC.LoginText>
                        Already got an account?{' '}
                        <SignUpSC.LoginLink to="/sign-in">
                            {Appi18nStrings.login}
                        </SignUpSC.LoginLink>
                    </SignUpSC.LoginText>
                </AuthSC.AuthPageContainer>
            )}
        </>
    );
};
