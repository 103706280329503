import styled from 'styled-components';

import { PrimaryButton } from 'units/common/components/buttons/primary/primary.component';
import { TextStyle9, LogoText, UserDropdownText } from 'components/styles/text.styles';
import { Color, sizes } from 'components/styles/constants.styles';

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  position: relative;
  height: 200px;
  width: 100%;
  border-radius: ${sizes.border_radius};
  background: linear-gradient(to left top, #1819d1, #c933d1);
`;

const MainBlock = styled.div`
  top: 0;
  height: 100%;
  margin: 20px;
  position: relative;
`;

const Button = styled(PrimaryButton)`
  ${TextStyle9};
  width: 100%;
  bottom: 40px;
  height: 40px;
  padding: unset;
  text-align: center;
  position: absolute;
  background-color: ${Color.white};

  &:hover {
    background-color: ${Color.white};
  }
`;

const Title = styled.div`
  ${LogoText};
  font-size: 20px;
  text-align: left;
  color: ${Color.white};
`;

const Subtitle = styled.div`
  ${UserDropdownText};
  width: 55%;
  opacity: 0.8;
  margin-top: 10px;
  text-align: left;
  line-height: 1.33;
  color: ${Color.white};
`;

const Image = styled.div`
  position: absolute;
  bottom: -3px;
  right: 0;

  img {
    border-radius: ${sizes.border_radius};
  }
`;

export const SupportSC = {
  Container,
  Content,
  MainBlock,
  Button,
  Title,
  Subtitle,
  Image,
};
