import React, { FC, useMemo } from 'react';
import { Form, useFormikContext } from 'formik';

import { InviteUserSC } from './invite-user.styles';
import { AccountSC } from '../../account/account.styles';

import { FormikRadioButtonGroup } from 'units/common/components/formik/radio/radio-group.component';
import { PrimaryButton } from 'units/common/components/buttons/primary/primary.component';
import { TransparentButton } from 'units/common/components/buttons/transparent/transparent.component';
import { useFieldErrors } from 'units/common/hooks/useFieldErrors';
import { InviteUserValues, InviteUserError } from '../api/types';
import { UserRole } from 'components/types';
import { FormikFormInput } from 'units/common/components/formik/input/form-input.component';
import { useFormikApiErrors } from 'units/common/components/formik/hooks/useFormikApiErrors';

interface Props {
  myRole: UserRole;
  roles: Array<UserRole>;
  onCloseModal: () => void;
  additionalErrors?: InviteUserError | null;
}

export const InviteUserForm: FC<Props> = ({ myRole, roles, additionalErrors, onCloseModal }) => {
  const { values, errors, touched } = useFormikContext<InviteUserValues>();

  const apiErrors = useFormikApiErrors(additionalErrors);
  
  const fieldErrors = useFieldErrors<InviteUserValues>(
    values, errors, touched, {
      additionalErrors: apiErrors,
  });
    
    
  const allowedRoles = useMemo(() => {
    let rolesBuf = roles.map(item => ({ id: `${item.id}`, label: item.role }))

    if (myRole.role === 'Admin') {
      rolesBuf = rolesBuf.filter(item => item.label === 'User');
    }
    
    return rolesBuf;
  }, [roles]);

  return (
    <AccountSC.FormElement as={Form}>
      <FormikRadioButtonGroup radioList={allowedRoles} name='roleId' withBackground margin="0 10px 24px" />
      <FormikFormInput
        name="firstName"
        label="First name"
        placeholder="Enter user first name"
        withMargins
        withBackground
        error={fieldErrors.firstName}
      />
      <FormikFormInput
        name="lastName"
        label="Last name"
        placeholder="Enter user last name"
        withMargins
        withBackground
        error={fieldErrors.lastName}
      />
      <FormikFormInput
        name="email"
        label="Email"
        placeholder="Enter user email"
        withMargins
        withBackground
        type="email"
        error={fieldErrors.email}
      />
      <InviteUserSC>
        <PrimaryButton type="submit">Invite</PrimaryButton>
        <TransparentButton type="button" onClick={onCloseModal}>Cancel</TransparentButton>
      </InviteUserSC>
    </AccountSC.FormElement>
  );
}
