import styled from 'styled-components';

const StatusComponent = styled.span`
  color: #000;
  display: inline-block;
  &::first-letter{
     text-transform: uppercase;
  }
`;


const LinkComponent = styled.div`
  a{
     font-weight: bold;
     color: #000;
     text-decoration: none;
     font-family: 'GT Walsheim Pro Bold', sans-serif;
     &:hover{
        text-decoration: underline;
     }
  }
`



export const UserEditSC = {
   StatusComponent,
   LinkComponent
};