import React, { FC, useCallback, useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, FormikHelpers } from 'formik';

import { SignInSC } from './sign-in.styles';
import { AuthSC } from 'units/common/styles/auth/auth.styles';
import { RootState } from 'redux/root-types';
import { UserState, LoginUser } from '../../redux/types';
import { resendConfirmSignUpEmailThunk, signInThunk } from '../../redux/thunks';
import { GeneralSC } from 'units/common/styles/general/general.styles';
import { UserErrorKeys } from '../../constants';
import { SignInForm } from './form/sign-in-form.component';
import { signInValidationSchema } from './validation';
import { AppStrings } from 'config/strings';
import { useAsyncDispatch } from 'helpers/hooks/useAsyncDispatch';
import { setUserErrorState, setUserTempPayload } from '../../redux/action-creators';
import { DEBUG_removeUserByEmail } from '../../redux/service';
import { NotificationContext } from 'shared/providers';

export type SignInValues = LoginUser;

const initialValues: SignInValues = {
    email: '',
    password: '',
};

export const SignIn: FC = () => {
    const { dispatch } = useAsyncDispatch();
    const syncDispatch = useDispatch();
    const { showNotification } = useContext(NotificationContext);
    const {
        tempPayload: { registration },
        error,
    } = useSelector<RootState, UserState>(state => state.user);

    const submitLogin = useCallback(
        async (values: SignInValues, { setStatus }: FormikHelpers<SignInValues>) => {
            // debug option to remove user by email
            if (values.password === 'DEBUG_REMOVE_USER_BY_EMAIL') {
                try {
                    await DEBUG_removeUserByEmail(values.email);
                    showNotification('success', `${values.email} has been removed`);
                } catch (error) {
                    showNotification('error', AppStrings.errorNotification);
                }
            } else {
                // Common sign in request
                await dispatch(signInThunk(values));
                // clear hiddenErrors
                setStatus({});
            }
        },
        [dispatch],
    );

    const resendConfirmEmail = useCallback(() => {
        if (registration?.email) {
            dispatch(resendConfirmSignUpEmailThunk(registration.email, showNotification));
        } else {
            showNotification('error', AppStrings.errorNotification);
        }
    }, [dispatch, registration]);

    const savePrefilledEmail = useCallback(email => {
        syncDispatch(setUserTempPayload('resetPassword', { prefilledEmail: email }));
    }, []);

    // Clear login error when we leave the page.
    useEffect(
        () => () => {
            syncDispatch(setUserErrorState(UserErrorKeys.login, null));
        },
        [],
    );

    return (
        <AuthSC.AuthPageContainer>
            <AuthSC.AuthPageTitle>Login</AuthSC.AuthPageTitle>
            <Formik
                initialValues={initialValues}
                onSubmit={submitLogin}
                validationSchema={signInValidationSchema}
            >
                <SignInForm savePrefilledEmail={savePrefilledEmail} />
            </Formik>
            <SignInSC.RegistrationText>
                {error?.[UserErrorKeys.confirmEmail] && registration?.email ? (
                    <>
                        <GeneralSC.ErrorText>This link expired.</GeneralSC.ErrorText>{' '}
                        <AuthSC.AuthPageAdditionalLink as="button" onClick={resendConfirmEmail}>
                            Send again
                        </AuthSC.AuthPageAdditionalLink>
                    </>
                ) : (
                    <>
                        Don't have an account?{' '}
                        <AuthSC.AuthPageAdditionalLink tabIndex={-1} to="/sign-up">
                            Register now
                        </AuthSC.AuthPageAdditionalLink>
                    </>
                )}
            </SignInSC.RegistrationText>
        </AuthSC.AuthPageContainer>
    );
};
