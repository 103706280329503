import React, { memo, useRef } from 'react';

import { useParallax } from 'units/landing-page/hooks';

import background from '../../../images/feature-illustrations/right-bg.jpg';
import root from '../../../images/feature-illustrations/optimization-root.png'
import timer from '../../../images/feature-illustrations/optimization-timer.png';
import template from '../../../images/feature-illustrations/optimization-template.jpg';
import colorPicker from '../../../images/feature-illustrations/optimization-color-picker.png';

import backgroundRetina from '../../../images/feature-illustrations/right-bg@retina.jpg';
import rootRetina from '../../../images/feature-illustrations/optimization-root@retina.png'
import timerRetina from '../../../images/feature-illustrations/optimization-timer@retina.png';
import templateRetina from '../../../images/feature-illustrations/optimization-template@retina.jpg';
import colorPickerRetina from '../../../images/feature-illustrations/optimization-color-picker@retina.png';

import { LPOptimizationSC } from './optimization.styles';

export const LPOptimization = memo(() => {
    const ref = useRef<HTMLDivElement>(null);
    const { value } = useParallax(ref);

    return (
        <LPOptimizationSC.Root ref={ref}>
            <LPOptimizationSC.Background
                srcSet={`${background} 1x, ${backgroundRetina} 2x`}
                src={background}
            />
            <LPOptimizationSC.RootImage
                srcSet={`${root} 1x, ${rootRetina} 2x`}
                src={root}
            />
            <LPOptimizationSC.Timer
                srcSet={`${timer} 1x, ${timerRetina} 2x`}
                style={{transform: `translateY(${-value / 12}px)`}}
                src={timer}
            />
            <LPOptimizationSC.Template
                srcSet={`${template} 1x, ${templateRetina} 2x`}
                style={{transform: `translateY(${-value / 25}px)`}}
                src={template}
            />
            <LPOptimizationSC.ColorPicker
                srcSet={`${colorPicker} 1x, ${colorPickerRetina} 2x`}
                style={{transform: `translateY(${-value / 12}px)`}}
                src={colorPicker}
            />
        </LPOptimizationSC.Root>
    )
})
