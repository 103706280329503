import React, { FC, useCallback } from 'react';

import {
  CMSCustomizationLanguageProgressContainer,
  CMSCustomizationLanguageProgressContainerProps,
  CMSProgressBeforeUpdate
} from '../../../progress-container/progress-container';
import { TrackProgressField } from 'units/common/hooks/useTrackProgress';
import { CmsAcordionSection } from 'units/cms/components/cms-acordion-section/cmsAcordionSection.component';

type Props = Pick<CMSCustomizationLanguageProgressContainerProps, 'selectedLanguage' | 'onLanguageChange' | 'initialProgress'> & {
  Accordion: FC<{ updateProgress: (field: TrackProgressField) => void; }>;
  withDevider?: boolean;
  pictures?: Array<string>;
  hideProgress?: boolean;
};

export const CMSShopModalsAccordionContainer: FC<Props> = ({ Accordion, withDevider, pictures, ...props }) => {
  const handleBeforeUpdateProgress = useCallback((...[field, { updateProgressFields }]: Parameters<CMSProgressBeforeUpdate>) => {
    if (pictures && pictures.length) {
      updateProgressFields(pictures.map(pictureKey => ({
        key: pictureKey,
        operation: field.isFilled ? 'add' : 'delete',
      })));
    }
    return true;
  }, []);
  return (
  <CmsAcordionSection lastSection withDevider={withDevider}>
    <CMSCustomizationLanguageProgressContainer
      beforeUpdate={handleBeforeUpdateProgress}
      {...props}
    >
      {({ updateCurrentProgress }) => (
          <Accordion updateProgress={updateCurrentProgress} />
        )}
    </CMSCustomizationLanguageProgressContainer>
  </CmsAcordionSection>
  );
};
