import { useField } from 'formik';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/root-types';

import { CmsAcordionSection } from 'units/cms/components/cms-acordion-section/cmsAcordionSection.component';
import { CMSUploader } from 'units/cms/components/cms-uploader/cms-uploader.component';
import { SelectAuctions } from 'units/cms/components/select-auctions/select-auctions.component';
import { useCMSFormikImageUploadHelper } from 'units/cms/hooks/useCMSImageUploadHelper';
import { FormikColorPicker } from 'units/common/components/formik/color-picker/color-picker.component';
import { FormikFormInput } from 'units/common/components/formik/input/form-input.component';
import { FormikToggleButton } from 'units/common/components/formik/toggle/formik-toggle';
import { TrackProgressField } from 'units/common/hooks/useTrackProgress';
import { UserState } from 'units/user/redux/types';

type Props = {
    updateProgress: (field: TrackProgressField) => void;
};

export const CMSHomepageHeroAccordion: FC<Props> = ({ updateProgress }) => {
    const { t } = useTranslation();
    const { user } = useSelector<RootState, UserState>(state => state.user);
    const [{ value: isStickerActive }] = useField('hero.conversationSticker.isActive');
    const [icon, iconHelper] = useCMSFormikImageUploadHelper('hero.conversationSticker.icon');

    const handleIconSave = useCallback(
        (files: Array<File>) => {
            iconHelper.storeFirstImage(files);
            updateProgress({ name: 'conversationSticker.icon', isFilled: true });
        },
        [updateProgress],
    );

    const handleIconDelete = useCallback(
        (id: string) => {
            iconHelper.deleteImage(id);
            updateProgress({ name: 'conversationSticker.icon', isFilled: false });
        },
        [updateProgress],
    );

    return (
        <>
            <CmsAcordionSection sectionMargin={32} withDevider={false}>
                <FormikFormInput
                    name="hero.CTAcopy"
                    placeholder={t('customization_hero_cta_placeholder')}
                    label={t('customization_hero_cta')}
                    paddingBottom="0"
                    afterChange={({ target }) => {
                        updateProgress({ name: 'CTAcopy', isFilled: !!target.value });
                    }}
                />
                <FormikFormInput
                    name="hero.timerTitle"
                    placeholder={t('customization_hero_timer_placeholder')}
                    label={t('customization_hero_timer')}
                    paddingBottom="0"
                    /*           afterChange={({ target }) => {
            updateProgress({ name: 'timerTitle', isFilled: !!target.value });
          }} */
                />
            </CmsAcordionSection>
            <CmsAcordionSection
                title={t('customization_hero_select_auctions')}
                sectionMargin={32}
                titleStyle="black"
                withDevider={false}
            >
                <SelectAuctions />
            </CmsAcordionSection>

            {user && user.user_type && user.user_type.slug !== 'immo' && (
                <CmsAcordionSection
                    title={t('customization_hero_conversion_sticker')}
                    titleStyle="black"
                    withDevider={false}
                >
                    <CmsAcordionSection sectionMargin={32} withDevider={false}>
                        <FormikToggleButton
                            name="hero.conversationSticker.isActive"
                            label={t('active')}
                            disabled={true}
                            afterChange={({ target }) => {
                                updateProgress({
                                    name: 'conversationSticker.isActive',
                                    isFilled: !!target.checked,
                                });
                            }}
                        />
                        <FormikColorPicker
                            label={t('customization_hero_sticker_color')}
                            name="hero.conversationSticker.color"
                            disabled={!isStickerActive}
                        />
                    </CmsAcordionSection>
                    <CMSUploader
                        label={t('customization_hero_icon')}
                        title={t('customization_hero_icon_description')}
                        subtitle={t('theme_settings_max_size', { width: 40, height: 40 })}
                        loadingTitle={t('customization_hero_icon_loading')}
                        saveFiles={handleIconSave}
                        deleteFile={handleIconDelete}
                        previewFile={icon || undefined}
                        maxDimension={{
                            height: 40,
                            width: 40,
                        }}
                        dimensionErrorMessage={t('theme_settings_max_size', {
                            width: 40,
                            height: 40,
                        })}
                        error={iconHelper.error}
                        disabled={!isStickerActive}
                    />
                </CmsAcordionSection>
            )}
        </>
    );
};
