import React, { FC } from 'react';

import { FileUploaderContainerProps } from '../file-uploader.component';
import { BaseUploaderSC } from '../uploader.styles';
import { ProgressBar } from '../../progress-bar/progress-bar.component';
import { PrimaryButton } from '../../buttons/primary/primary.component';
import { useTranslation } from 'react-i18next';

export const FileUploaderPrimaryContainer: FC<FileUploaderContainerProps> = ({
    rootProps,
    inputProps,
    isUploading,

    title,
    subtitle,
    loadingTitle,

    uploaderId,
    fakeUploadingDuration,
    cancelUploadingHandler,
    hasError,
}) => {
    const { t } = useTranslation();

    return (
        <BaseUploaderSC.PrimaryContainer
            {...rootProps}
            isUploading={isUploading}
            hasError={hasError}
        >
            {isUploading ? (
                <>
                    <BaseUploaderSC.PrimaryTitle>
                        {loadingTitle || 'Loading'}
                    </BaseUploaderSC.PrimaryTitle>
                    <BaseUploaderSC.ProgressBarWrapper>
                        <ProgressBar.Fake duration={fakeUploadingDuration ?? 1000} />
                    </BaseUploaderSC.ProgressBarWrapper>
                    <PrimaryButton type="button" onClick={cancelUploadingHandler} isLow={true}>
                        {t('cancel')}
                    </PrimaryButton>
                </>
            ) : (
                <>
                    <input {...inputProps} />
                    <BaseUploaderSC.PrimaryTitle>
                        {title || 'Drag and drop a file here or'}
                    </BaseUploaderSC.PrimaryTitle>
                    <label htmlFor={uploaderId}>
                        <PrimaryButton type="button" isLow={true}>
                            {t('choose_file')}
                        </PrimaryButton>
                    </label>
                    {subtitle && <BaseUploaderSC.Subtitle>{subtitle}</BaseUploaderSC.Subtitle>}
                </>
            )}
        </BaseUploaderSC.PrimaryContainer>
    );
};
