import React, { FC, useCallback, useEffect } from 'react';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';

import { CMSCustomizationShopModalsSectionName, CMSCustomizationShopModalsSectionName as SectionId } from 'units/cms/types';
import { useCMSPage } from 'units/cms/hooks/selectors/useCMSPage';
import { createOverallUpdatesForCMSShopModals } from 'units/cms/helpers/local-settings/shop-modals';
import { preparShopModalsProgress } from 'units/cms/helpers/prepare-customization-progress/shop-modals';
import { CMSCustomizationPage } from 'units/cms/store/types';
import { CMSCustomizationSidebarForm } from '../../sidebar-form/cms-sidebar-form.component';
import { CMSCustomizationShopModalsValidationSchema } from './validationSchema';
import { EmptyFunction } from 'units/common/constants';
import { CMSCustomizationShopModalsAccordions } from './accordions/shop-modals-accordions.component';
import { useAsyncDispatch } from 'helpers/hooks';
import { CMSShopModalsCustomizationSettings } from 'units/cms/store/types/customization/shop-modals';
import { updateCMSCustomizationSettingsConfigThunk } from 'units/cms/store/thunks';
import { cmsSelectedModal } from 'units/cms/store/action-creators';

export const CMSShopModalsSectionHeaders: { [key in SectionId]: string } = {
  'general': 'General',
  'register': 'Register',
  'login1': 'Login 1',
  'login2': 'Login 2',
  'offerConfirmation': 'Offer confirmation',
  'offerDenial': 'Offer denial',
  'offerWon': 'Offer won',
  'resetPassword': 'Reset password'
};

export const CMSCustomizationShopModalsPage: FC = () => {
  const {
    formikInitialValues,
    selectedLanguage,
    activeSections,
    initialPageOverallSettings,
    handleSaveForm,
    handleSaveAndPublishForm,
    sectionProgress,
    handleAccordionExpandedChange,
    handleLanguageChange
  } = useCMSPage({
    defaultActiveSection: 'general',
    createOverallUpdatesForCMS: createOverallUpdatesForCMSShopModals,
    preparePageProgress: preparShopModalsProgress,
    CMSPageSectionHeaders: CMSShopModalsSectionHeaders,
    page: CMSCustomizationPage.ShopModals
  });
  const dispatch = useDispatch();
  const { dispatch: asyncDispatch } = useAsyncDispatch();

  const onUpdate = useCallback(async (values:CMSShopModalsCustomizationSettings) => {
    await asyncDispatch(updateCMSCustomizationSettingsConfigThunk(values, selectedLanguage));
}, [selectedLanguage]);

  useEffect(() => {
    //if(activeSections[0] !== 'general') {
      dispatch(cmsSelectedModal(activeSections[0] as CMSCustomizationShopModalsSectionName));
    //}
  }, [activeSections])

  if (!formikInitialValues || !sectionProgress) {
    return null;
  }

  return (
    <Formik
      initialValues={formikInitialValues}
      validationSchema={CMSCustomizationShopModalsValidationSchema}
      enableReinitialize
      onSubmit={EmptyFunction}
      validate={onUpdate}
    >
      <CMSCustomizationSidebarForm
        selectedLanguage={selectedLanguage}
        savedSettings={initialPageOverallSettings}
        onSave={handleSaveForm}
        onSaveAndPublish={handleSaveAndPublishForm}
      >
        <CMSCustomizationShopModalsAccordions
          selectedLanguage={selectedLanguage}
          onLanguageChange={handleLanguageChange}
          sectionProgress={sectionProgress}
          activeSections={activeSections}
          handleAccordionExpandedChange={handleAccordionExpandedChange}
        />
      </CMSCustomizationSidebarForm>
    </Formik>
  );
};
