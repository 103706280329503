import React, { FC, useCallback } from 'react';
import { useField } from 'formik';

import { CmsAcordionSection } from 'units/cms/components/cms-acordion-section/cmsAcordionSection.component';
import { FormikFormInput } from 'units/common/components/formik/input/form-input.component';
import { TrackProgressField } from 'units/common/hooks/useTrackProgress';
import { FormikToggleButton } from 'units/common/components/formik/toggle/formik-toggle';
import { CMSUploader } from 'units/cms/components/cms-uploader/cms-uploader.component';
import { useCMSFormikImageUploadHelper } from 'units/cms/hooks/useCMSImageUploadHelper';
import { MailchimpConnect } from './mailchimp-connect.component';

type Props = {
    updateProgress: (field: TrackProgressField) => void;
    mailchimpIntegrated: boolean;
};

export const CMSHomepageNewsletterAccordion: FC<Props> = ({
    updateProgress,
    mailchimpIntegrated,
}) => {
    const [{ value: isSectionActive }] = useField('newsletterOption.isActive');
    const [icon, iconHelper] = useCMSFormikImageUploadHelper('newsletterOption.icon');

    const handleIconSave = useCallback(
        (files: Array<File>) => {
            iconHelper.storeFirstImage(files);
            updateProgress({ name: 'icon', isFilled: true });
        },
        [updateProgress],
    );

    const handleIconDelete = useCallback(
        (id: string) => {
            iconHelper.deleteImage(id);
            updateProgress({ name: 'icon', isFilled: false });
        },
        [updateProgress],
    );

    return (
        <CmsAcordionSection withDevider={false}>
            <MailchimpConnect integrated={mailchimpIntegrated}>
                <FormikToggleButton
                    name="newsletterOption.isActive"
                    label="Active"
                    afterChange={({ target }) => {
                        updateProgress({ name: 'isActive', isFilled: target.checked });
                    }}
                />
                <FormikFormInput
                    name="newsletterOption.title"
                    label="Title"
                    placeholder="Keep in touch"
                    paddingBottom="0"
                    afterChange={({ target }) => {
                        updateProgress({ name: 'title', isFilled: !!target.value });
                    }}
                    disabled={!isSectionActive}
                />
                <FormikFormInput
                    name="newsletterOption.subTitle"
                    label="Subtitle"
                    placeholder="Enter subtitle"
                    paddingBottom="0"
                    afterChange={({ target }) => {
                        updateProgress({ name: 'subtitle', isFilled: !!target.value });
                    }}
                    disabled={!isSectionActive}
                />
                <FormikFormInput
                    name="newsletterOption.fieldPlaceholder"
                    label="Field placeholder"
                    placeholder="Place your email here"
                    paddingBottom="0"
                    afterChange={({ target }) => {
                        updateProgress({ name: 'fieldPlaceholder', isFilled: !!target.value });
                    }}
                    disabled={!isSectionActive}
                />
                <CMSUploader
                    label="Promotional lable icon"
                    title="Drag and drop a picture here or"
                    subtitle="Max size 48 px * 48 px"
                    loadingTitle="Icon is loading"
                    saveFiles={handleIconSave}
                    deleteFile={handleIconDelete}
                    previewFile={icon || undefined}
                    maxDimension={{
                        height: 48,
                        width: 48,
                    }}
                    dimensionErrorMessage="Max size 48 px * 48 px"
                    error={iconHelper.error}
                    disabled={!isSectionActive}
                />
            </MailchimpConnect>
        </CmsAcordionSection>
    );
};
