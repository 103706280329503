import * as actionTypes from './action-types';
import { CompanySettings, CompanyLanguages, ThirdPartyIntegrationSourceData } from './types';

export const addIsFetching = (key: string) => ({ type: actionTypes.APP_ADD_IS_FETCHING, key } as const);

export const removeIsFetching = (key: string) => ({ type: actionTypes.APP_REMOVE_IS_FETCHING, key } as const);

export const setAppInitializedWithAuthorizedUser = (status: boolean) => ({ 
  type: actionTypes.APP_SET_INITIALIZED_WITH_AUTHORIZED_USER ,
  status,
} as const);

// third party integrations

export const setThirdPartyIntegrations = (integrations: Array<ThirdPartyIntegrationSourceData>) => (
  { type: actionTypes.APP_SET_THIRD_PARTY_INTEGRATIONS, integrations } as const
);

export const setThirdPartyIntegration = (integration: ThirdPartyIntegrationSourceData) => (
  { type: actionTypes.APP_SET_THIRD_PARTY_INTEGRATION, integration } as const
);

export const updateThirdPartyIntegration = (integration: ThirdPartyIntegrationSourceData) => (
  { type: actionTypes.APP_UPDATE_THIRD_PARTY_INTEGRATION, integration } as const
);

// company settings

export const setCompanySettings = (settings: CompanySettings) => ({ type: actionTypes.APP_SET_COMPANY_SETTINGS, settings } as const);

export const setCompanyLanguages = (languages: Array<CompanyLanguages>) => ({
  type: actionTypes.APP_SET_COMPANY_LANGUAGES,
  languages
} as const);

// clearing

export const clearAppReducer = () => ({ type: actionTypes.APP_CLEAR_REDUCER } as const);
