import React, { useEffect, useState } from 'react';
import { Card, Typography } from 'shared/components/ui';
import { Select } from 'units/common/components/select/select.component';
import styles from './LineChart.module.scss';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { ChartLegend } from '../ChartLegend/ChartLegend';
import { LineDatasetType, SelectData } from 'units/analytics/pages/types';
import { AnalyticsService } from 'units/analytics/api/analytics-service';
import clsx from 'clsx';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

type LineChartProps = {
    auctionId: string;
    productId: string;
    selectedAuction: SelectData;
    empty: boolean;
};

export const LineChart: React.FC<LineChartProps> = ({
    auctionId,
    productId,
    selectedAuction,
    empty,
}) => {
    const [chartData, setChartData] = useState<any>();
    const [selectedOption, setSelectedOption] = useState<SelectData>();
    const [labels, setLabels] = useState<string[]>([]);
    const [datasets, setDatasets] = useState<LineDatasetType[]>([]);

    const options = {
        responsive: true,
        scales: {
            x: {
                offset: true,
                title: {
                    display: false,
                    text: selectedOption?.label,
                    color: '#2B303A',
                    padding: {
                        top: 20,
                    },
                },
                grid: {
                    borderDash: [4, 4],
                    color: '#ECF2FD',
                    borderColor: 'transparent',
                },
                ticks: {
                    color: '#9DA7B7',
                    font: {
                        size: 10,
                    },
                },
            },
            y: {
                min: 0,
                // max: 100,
                offset: true,
                title: {
                    display: false,
                    text: 'Bid Sum, CHF',
                    color: '#2B303A',
                    backgroundColor: '#3662b9',
                    padding: {
                        bottom: 20,
                    },
                },
                grid: {
                    borderDash: [4, 4],
                    color: '#ECF2FD',
                    borderColor: 'transparent',
                },
                ticks: {
                    color: '#9DA7B7',
                    font: {
                        size: 10,
                    },
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            datalabels: {
                display: false,
            },
        },
        maintainAspectRatio: false,
    };

    const handleChange = async (e: any) => {
        setSelectedOption(e);
        if (selectedAuction.value === '') return;
        if (e.value === 'age') {
            let response;
            if (selectedAuction.value === 'all') {
                response = await AnalyticsService.getProductChartAge(productId);
                setChartData(response.data?.analytics_bid_sum_by_age);
            } else {
                response = await AnalyticsService.getChartAge(auctionId);

                setChartData(response.data?.analytics_by_age_in_amount);
            }
        }
        if (e.value === 'daytime') {
            let response;
            if (selectedAuction.value === 'all') {
                response = await AnalyticsService.getProductChartDaytime(productId);
                setChartData(response.data?.analytics_bid_sum_by_daytime);
            } else {
                response = await AnalyticsService.getChartDaytime(auctionId);
                setChartData(response.data?.analytics_by_daytime_in_amount);
            }
        }
        if (e.value === 'weekday') {
            let response;
            if (selectedAuction.value === 'all') {
                response = await AnalyticsService.getProductChartWeekday(productId);
                setChartData(response.data?.analytics_bid_sum_by_weekdays);
            } else {
                response = await AnalyticsService.getChartWeekday(auctionId);

                setChartData(response.data?.analytics_by_weekdays_in_amount);
            }
        }
        if (e.value === 'region') {
            let response;
            if (selectedAuction.value === 'all') {
                response = await AnalyticsService.getProductChartRegions(productId);
                setChartData(response.data?.region_bid_sum);
            } else {
                response = await AnalyticsService.getChartRegions(auctionId);
                setChartData(response.data?.region_bid_sum);
            }
        }
    };

    useEffect(() => {
        if (selectedOption) {
            handleChange(selectedOption);
        } else {
            handleChange({ value: 'age', label: 'Age' });
        }
    }, [auctionId, selectedOption, productId]);

    useEffect(() => {
        parseData();
    }, [chartData]);

    const parseData = () => {
        const labels = [];
        let male: LineDatasetType = {
            label: 'Male',
            backgroundColor: '#4B67F8dd',
            borderColor: '#4B67F8dd',
            data: [],
        };
        let female: LineDatasetType = {
            label: 'Female',
            backgroundColor: '#F85563dd',
            borderColor: '#F85563dd',
            data: [],
        };
        let unspecified: LineDatasetType = {
            label: 'Unspecified',
            backgroundColor: '#FD9D40dd',
            borderColor: '#FD9D40dd',
            data: [],
        };

        for (const key in chartData) {
            if (!['age_not_setup_amount', 'age_not_setup_number'].includes(key)) labels.push(key);
            male = {
                ...male,
                data: [...male?.data, chartData[key].male === 0 ? null : chartData[key].male],
            };
            female = {
                ...female,
                data: [...female?.data, chartData[key].female === 0 ? null : chartData[key].female],
            };
            unspecified = {
                ...unspecified,
                data: [
                    ...unspecified?.data,
                    chartData[key].unspecified === 0 ? null : chartData[key].unspecified,
                ],
            };
        }
        setLabels(labels);
        setDatasets([male, female, unspecified]);
    };

    return (
        <Card className={clsx(styles.card, !empty ? '' : styles['is-empty'])}>
            <div className={styles.titleWrap}>
                <Typography.Title htmlElement={'h4'}>Bid Sum</Typography.Title>
                {!empty && (
                    <>
                        <ChartLegend className={styles.legend} />
                        <Select
                            options={[
                                { value: 'age', label: 'Age' },
                                { value: 'daytime', label: 'Daytime' },
                                { value: 'weekday', label: 'Weekday' },
                                { value: 'region', label: 'Region' },
                            ]}
                            placeholder="Choose"
                            onChange={handleChange}
                            padding={'0px'}
                            className={styles.select}
                            defaultValue={{ value: 'age', label: 'Age' }}
                        />
                    </>
                )}
            </div>
            {!empty ? (
                <>
                    <div className={styles.chart}>
                        <Line
                            options={options}
                            data={{ labels, datasets }}
                            height="100%"
                            width="100%"
                        />
                    </div>
                    <div className={clsx(styles.label, styles['is-left'])}>
                        <span> Bid Sum, CHF</span>
                    </div>
                    <div className={clsx(styles.label, styles['is-bottom'])}>
                        {selectedOption?.label}
                    </div>
                </>
            ) : (
                <Typography.Title
                    htmlElement="h3"
                    className={clsx(styles.total, styles['empty-label'])}
                >
                    No data
                </Typography.Title>
            )}
        </Card>
    );
};
