import styled from 'styled-components';

import { TextStyle8, FormInputPostfixText, FormSubLabelText } from 'components/styles/text.styles';
import { sizes } from 'components/styles/constants.styles';

const Label = styled.div`
  ${TextStyle8};
  margin-bottom: ${sizes.sm_padding};
`;

const RowContainer = styled.div`
  display: flex;
  
  > div:first-child {
    width: 270px;
    margin-left: 0;
    margin-right: 0;
  }
`;

const AdditionalBlock = styled.div`
  ${FormInputPostfixText};
  margin-left: ${sizes.sm_padding};
  margin-top: 35px;

  span {
    ${FormSubLabelText};
  }
`;

export const AuctionConfigurationSC = {
  Label,
  RowContainer,
  AdditionalBlock,
};
