import { css } from 'styled-components';

import { Color, transition, sizes } from './constants.styles';
import { TextStyle6 } from './text.styles';

export const PrimaryButtonStyle = css`
  padding: 16px;

  border: none;
  border-radius: ${sizes.border_radius};
  background-color: ${Color.lightish_blue};
  outline: none;
  cursor: pointer;
  text-transform: uppercase;
  transition: ${transition};

  &:hover {
    background-color: ${Color.windows_blue};
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${Color.bluey_grey};
  }

  ${TextStyle6};
`;
