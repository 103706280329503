import { UpdateUserAccountErrorRes } from '../../redux/types';

export type UpdateUserAccountError = {
  firstName?: Array<string>;
  lastName?: Array<string>;
  companyName?: Array<string>;
  email?: Array<string>;
  phone?: Array<string>;
}

export const parseUpdateUserAccountDataError = (errData: UpdateUserAccountErrorRes): UpdateUserAccountError => ({
  firstName: errData.first_name,
  lastName: errData.last_name,
  companyName: errData.company,
  email: errData.email,
  phone: errData.phone  
});
