import { convertToRaw } from 'draft-js';

import { EditProductSavedPicture, ProductItem, EditProductForm, EditProduct } from './types';
import { ApiClient, apiPath } from 'config/api/api';
import { limitFileName } from 'helpers/general';

const prepareLanguageFormForReq = ({ generalInfo, seo }: EditProduct) => ({
  product_name: generalInfo.productName,
  USP: {
    USP_1: generalInfo.usp1,
    USP_2: generalInfo.usp2,
    USP_3: generalInfo.usp3,
  },
  product_catch_phrase: generalInfo.productCatchPhrase,
  description: generalInfo.descriptions.map(descr => ({
    description_type: descr.type,
    description_body: JSON.stringify(convertToRaw(descr.description.getCurrentContent())),
  })),
  SEO: {
    page_title: seo.seoPageTitle,
    page_body: seo.seoPageDescription,
  },
});

const prepareEditProductForReq = (form: EditProductForm) => ({
  uvp: form.commonFields.uvp,
  shipping_cost: form.commonFields.shippingCost,
  days_for_delivery: form.commonFields.deliveryDays,
  quantity: form.commonFields.quantityAtStock,
  product_info: Object.entries(form.languages).reduce((productInfo, [lng, lngForm]) => ({
    ...productInfo,
    [lng.toLowerCase()]: lngForm ? prepareLanguageFormForReq(lngForm) : undefined,
  }), {}),
})

export const editProductSaveProduct = async (productForm: EditProductForm) => {
  const api = ApiClient.getInstance();
  const reqBody = prepareEditProductForReq(productForm);

  const { data } = await api.post(`${apiPath}/products/create/`, reqBody);
  return data.id;
};

export const editProductUpdateProduct = async (productForm: EditProductForm, productId: string) => {
  const api = ApiClient.getInstance();
  const reqBody = prepareEditProductForReq(productForm);

  await api.put(`${apiPath}/products/${productId}/`, reqBody);
};

export const editProductDeleteImageById = async (imageId: string, productId: string) => {
  const api = ApiClient.getInstance();

  await api.delete(`${apiPath}/products/${productId}/images/delete/${imageId}/`);
};

export const editProductFetchProduct = async (productId: string) => {
  const api = ApiClient.getInstance();
  return api.get<ProductItem>(`${apiPath}/products/${productId}`);
};

export const editProductSavePictures = async (productId: string, pictures: Array<File | EditProductSavedPicture>) => {
  const api = ApiClient.getInstance();
  const picFormData = new FormData();

  pictures.forEach(async (file, index) => {
    if (file instanceof Blob) {
      const name = limitFileName(file.name);
      picFormData.set(`picture-${index}`, file, name);
    }
  });

  await api.post(`${apiPath}/products/${productId}/images/create/`, picFormData);
};
