import { AuctionValues } from './types';

export enum Status {
    draft = 1,
    scheduled = 2,
    active = 3,
    ended = 4,
}

export enum Discount {
    currency = '1',
    persentage = '2',
}

export enum Auction {
    buyNow = '1',
    auction = '2',
}

export const initValues: AuctionValues = {
    auctionId: null,
    auctionType: Auction.buyNow,
    auctionStatus: null,
    productId: '',
    startDate: undefined,
    startTime: undefined,
    endDate: undefined,
    endTime: undefined,
    endDateCopy: undefined,
    endTimeCopy: undefined,
    uvp: null,
    discount: null,
    discountType: Discount.currency,
    minOffer: null,
    minProdPieces: null,
    offerInterval: null,
    conditions: [
        { value: null, place: null },
        { value: null, place: null },
        { value: null, place: null },
        { value: null, place: null },
    ],
    isArchived: false,
    quantity: 0,
    auctionName: '',
};
