/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useCallback } from 'react';
import { useField } from 'formik';
import { EditorState, EditorProps } from 'react-draft-wysiwyg';

import { useFormikHideApiError } from '../hooks/useFormikHideApiError';
import { BaseRichTextEditor } from '../../rich-text-editor/rich-text-editor.styles';
import { IBaseFormInputLayout } from '../../form/fields/form-input/base-form-input-layout.component';

type Props = Omit<EditorProps, 'wrapperClassName' | 'editorClassName' | 'toolbarClassName'> &
    IBaseFormInputLayout & {
        name: string;
        afterEditorStateChange?: (state: EditorState) => void;
    };

export const FormikRichTextEditor = ({ name, afterEditorStateChange, ...props }: Props) => {
    const [{ value }, _, { setValue, setTouched }] = useField<EditorState>(name);
    const [hideApiError, setHideApiError] = useFormikHideApiError(name);

    const handleChange = useCallback(
        value => {
            setValue(value);

            if (!hideApiError) {
                setHideApiError(true);
            }

            if (afterEditorStateChange) {
                afterEditorStateChange(value);
            }
        },
        [afterEditorStateChange, hideApiError, setHideApiError],
    );

    const handleBlur = useCallback(() => {
        setTouched(true);
    }, []);

    return (
        <BaseRichTextEditor
            handleEditorStateChange={handleChange}
            editorState={value}
            onBlur={handleBlur}
            {...props}
        />
    );
};

/* eslint-enable @typescript-eslint/no-unused-vars */
