import React from 'react';

import { EcatchLogo } from '../../logo/logo.component';
import { Navbar } from './navbar/navbar.component';
import { WebsiteButton } from './website-button/website-button.component';
import { Support } from './support/support.component';

import { SidebarSC } from './sidebar.styles';

export const Sidebar = () => (
  <SidebarSC.Root>
    <SidebarSC.Logo>
      <EcatchLogo.Link />
    </SidebarSC.Logo>
    <Navbar />
    <WebsiteButton />
    <SidebarSC.SupportWrapper>
      <Support />
    </SidebarSC.SupportWrapper>
  </SidebarSC.Root>
);
