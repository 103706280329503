import React, { FC } from 'react';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import { useLocation } from 'react-router-dom';

import { AuctionValues } from '../types';
import { IconSC, IconUnicodes } from 'components/styles/icons.styles';
import { ProductItemSC } from './product-item.styles';
import { Product } from 'units/products/api/types';
import { getAuctionStatuses } from '../helpers';
import { useWorkingMode } from '../hooks';

interface Props {
    product: Product | undefined;
    onDelete: () => void;
}

export const ProductItem: FC<Props> = ({ product, onDelete }) => {
    const {
        values,
        values: { startDate, startTime, endDate, endTime },
    } = useFormikContext<AuctionValues>();
    const { isScheduled, isDraft } = getAuctionStatuses(values);
    const { isEditMode, isCreateMode } = useWorkingMode(useLocation());

    if (!product) {
        return null;
    }

    return (
        <ProductItemSC.ProductContainer>
            <ProductItemSC.ProductImg src={product.images[0]?.image} alt="productItem" />

            <ProductItemSC.ProductDetails>
                <ProductItemSC.ProductTitle>{product.name}</ProductItemSC.ProductTitle>
                <ProductItemSC.ProductPrice>
                    UVP<span>CHF {product.uvp}</span>
                </ProductItemSC.ProductPrice>
                <ProductItemSC.ProductDate>
                    Started
                    <span>
                        {startDate && dayjs(startDate).format('DD.MM.YYYY')}{' '}
                        {startTime && dayjs(startTime).format('HH:mm')}
                    </span>
                </ProductItemSC.ProductDate>
                <ProductItemSC.ProductDate>
                    Ends
                    <span>
                        {endDate && dayjs(endDate).format('DD.MM.YYYY')}{' '}
                        {endTime && dayjs(endTime).format('HH:mm')}
                    </span>
                </ProductItemSC.ProductDate>
            </ProductItemSC.ProductDetails>

            {((isEditMode && (isScheduled || isDraft)) || isCreateMode) && (
                <ProductItemSC.Close onClick={onDelete}>
                    <IconSC name={IconUnicodes.close} />
                </ProductItemSC.Close>
            )}
        </ProductItemSC.ProductContainer>
    );
};
