import styled from 'styled-components';

const Wrapper = styled.div``;

const AuctionItem = styled.div`
    height: 20px;
    display: flex;
    cursor: pointer;
    margin-top: 8px;
    &:first-child {
        margin-top: 0;
    }
`;

export const CMSSelectAuctionsSC = {
    Wrapper,
    AuctionItem,
};
