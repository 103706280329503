import React, { FC } from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';

import { DatePickerSC } from './date-picker.styles';

interface Props extends ReactDatePickerProps {
  label?: string;
  error?: string;
}

export const DatePicker: FC<Props> = ({ label, error, ...props }) => (
  <DatePickerSC.Container>
    {label && (
      <DatePickerSC.LabelContainer>
        <DatePickerSC.Label>{label}</DatePickerSC.Label>
      </DatePickerSC.LabelContainer>
    )}
    <DatePickerSC.PickerContainer>
      <ReactDatePicker
        autoComplete='off'
        {...props}
      />
    </DatePickerSC.PickerContainer>
    {error && <DatePickerSC.Error>{error}</DatePickerSC.Error>}
  </DatePickerSC.Container>
);
