import styled from 'styled-components';

import { Color, sizes } from 'components/styles/constants.styles';
import { TextStyle13 } from 'components/styles/text.styles';

type TabProps = {
  active?: boolean;
  highlighted?: boolean;
}

const TabsContainer = styled.ul`
  display: flex;
`;

const LeftPart = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${sizes.lg_margin};
`;

const DomainLink = styled.div`
  cursor: pointer;
  font-size: 14px;
  margin-left: 23px;
  color: ${Color.lightish_blue};
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
`;

const Tab = styled.li<TabProps>`
  position: relative;
  margin-right: ${sizes.sm_margin};
  border: none;
  
  cursor: pointer;
  user-select: none;
  
  ${TextStyle13}
  
  ${({ active }) => active && `
    color: ${Color.lightish_blue};
    border-bottom: 2px ${Color.lightish_blue} solid;
  `}

  &::before {
    display: ${({ highlighted }) => highlighted ? 'block' : 'none'};
    content: '';
    position: absolute;
    top: -4px;
    left: 50%;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    transform: translateX(-50%);

    background-color: ${Color.watermelon};
  }
`;

export const CMSTabSwitchSC = {
  TabsContainer,
  Tab,
  LeftPart,
  DomainLink
};
