import React from 'react';
import clsx from 'clsx';

import styles from './Button.module.scss';
import { PreloaderIcon } from '../PreloaderIcon/PreloaderIcon';

type ButtonProps = {
    className?: string;
    htmlType?: 'button' | 'submit';
    type?: 'primary' | 'red' | 'transparent' | 'immo' | 'immo-white' | 'immo-bordered';
    size?: 'small' | 'medium';
    isDisabled?: boolean;
    isInProgress?: boolean;
    onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export const Button: React.FC<ButtonProps> = ({
    children,
    htmlType = 'button',
    type = 'primary',
    size = 'small',
    isDisabled = false,
    isInProgress = false,
    onClick,
    className,
}) => (
    <button
        className={clsx(
            styles.root,
            styles[`is-${type}`],
            styles[`is-${size}`],
            className,
            { [styles['is-disabled']]: isDisabled },
            { [styles['in-progress']]: isInProgress },
        )}
        type={htmlType}
        disabled={isDisabled}
        onClick={onClick}
    >
        <span>
            {!isInProgress && children}
            {isInProgress && (
                <PreloaderIcon
                    className={styles['progress-icon']}
                    color={isDisabled ? '#2b303a' : '#ffffff'}
                />
            )}
        </span>
    </button>
);
