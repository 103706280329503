import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { TextStyle9, UserDropdownText, TextStyle27 } from 'components/styles/text.styles';
import { Color, sizes, box_shadow } from 'components/styles/constants.styles';
import { PrimaryButtonStyle } from 'components/styles/button.styles';
import { MediaQueries } from 'units/landing-page/config';

const Wrapper = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${MediaQueries.mobile}) {
    justify-content: center;
  }
`;

const UserInfo = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 160px;
  margin-left: auto;
  padding-left: 10px;
  cursor: pointer;
`;

const UserAvatar = styled.img`
  border-radius: 50%;
  width: 40px;
`;

const UserEmail = styled.div`
  padding: 0 12px;

  ${TextStyle9}
`;

const UserDropdown = styled.div`
  position: absolute;
  width: 214px;
  background-color: ${Color.white};
  border-radius: ${sizes.border_radius};
  padding: 4px 20px 0;
  box-shadow: ${box_shadow};
  top: 40px;
  right: 0;
  z-index: 10;

  > a {
    display: block;
    padding: 8px 0;
    text-decoration: none;

    ${UserDropdownText}

    &.active {
      color: ${Color.lightish_blue};
    }
  }
`;

const SignOut = styled.div`
  margin-top: 4px;
  padding: 12px 0;
  border-top: 1px solid ${Color.cloudy_blue};

  ${UserDropdownText}
`;

export const LogoWrapper = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
`;

export const RegisterButton = styled(Link)`
  ${PrimaryButtonStyle}

  text-decoration: none;
  text-transform: capitalize;
  padding-left: 40px;
  padding-right: 40px;
`;

export const LoginButton = styled(Link)`
  ${TextStyle27};

  padding: 16px 40px;
  text-decoration: none;
`;

export const HeaderSC = {
  Wrapper,
  UserInfo,
  UserAvatar,
  UserEmail,
  UserDropdown,
  SignOut,
  LogoWrapper,
  LoginButton,
  RegisterButton,
};
