import React from 'react';
import { useTranslation } from 'react-i18next';

import { CmsAcordionSection } from 'units/cms/components/cms-acordion-section/cmsAcordionSection.component';
import { FormikColorPicker } from 'units/common/components/formik/color-picker/color-picker.component';

export const CMSColorsAccordion = () => {
    const { t } = useTranslation();
    return (
        <>
            <CmsAcordionSection title={t('theme_settings_text')}>
                <FormikColorPicker
                    label={t('theme_settings_text_navigation')}
                    name={'colors.text.heroAndNavigation'}
                />
                <FormikColorPicker
                    label={t('theme_settings_text_heading')}
                    name={'colors.text.heading'}
                />
                <FormikColorPicker
                    label={t('theme_settings_text_body')}
                    name={'colors.text.body'}
                />
                <FormikColorPicker
                    label={t('theme_settings_text_sale')}
                    name={'colors.text.salePrive'}
                />
                <FormikColorPicker
                    label={t('theme_settings_text_footer')}
                    name={'colors.text.footer'}
                />
            </CmsAcordionSection>

            <CmsAcordionSection title={t('theme_settings_buttons')}>
                <FormikColorPicker
                    label={t('theme_settings_buttons_primary')}
                    name={'colors.buttons.primary'}
                />
                <FormikColorPicker
                    label={t('theme_settings_buttons_secondary')}
                    name={'colors.buttons.secondary'}
                />
                <FormikColorPicker
                    label={t('theme_settings_buttons_text_link')}
                    name={'colors.buttons.textLink'}
                />
            </CmsAcordionSection>

            <CmsAcordionSection title={t('theme_settings_background')}>
                <FormikColorPicker
                    label={t('theme_settings_background_primary')}
                    name={'colors.background.primary'}
                />
                <FormikColorPicker
                    label={t('theme_settings_background_secondary')}
                    name={'colors.background.secondary'}
                />
                <FormikColorPicker
                    label={t('theme_settings_background_tertiary')}
                    name={'colors.background.tertiary'}
                />
                <FormikColorPicker
                    label={t('theme_settings_background_footer')}
                    name={'colors.background.footer'}
                />
            </CmsAcordionSection>

            <CmsAcordionSection title={t('theme_settings_forms')} lastSection>
                <FormikColorPicker
                    label={t('theme_settings_forms_placeholder')}
                    name={'colors.forms.placeholder'}
                />
                <FormikColorPicker
                    label={t('theme_settings_forms_border')}
                    name={'colors.forms.border'}
                />
                <FormikColorPicker
                    label={t('theme_settings_forms_background')}
                    name={'colors.forms.background'}
                />
            </CmsAcordionSection>
        </>
    );
};
