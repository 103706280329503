import React, { useCallback, FC } from 'react';
import { useField } from 'formik';
import { useDispatch } from 'react-redux';

import { ProductPicturesFormSC } from './product-pictures.style';
import { GeneralFormSC } from 'units/common/components/form/form.styles';
import { UploadPicture } from './upload-picture/upload-picture.component';
import { TransparentButton } from 'units/common/components/buttons/transparent/transparent.component';
import { IconSC, IconUnicodes } from 'components/styles/icons.styles';
import { addProductImageToRemove } from '../../store/action-creators';
import { EditProductSC } from '../../product.styles';
import { TrackProgressField } from 'units/common/hooks/useTrackProgress';

interface Props {
    updateProgress: (field: TrackProgressField, options?: { forAllLanguages: boolean }) => void;
}

// Type for picture from response. Need to move to redux.store/types when implement edit product.
type SavedPicture = {
    id: string;
    src: string;
};

type ProductPicture = File | SavedPicture;

// To define the type of picture.
const isSavedPicture = (pic: ProductPicture): pic is SavedPicture =>
    (pic as SavedPicture).id !== undefined;

export const ProductPicturesForm: FC<Props> = ({ updateProgress }) => {
    const dispatch = useDispatch();
    const [field, meta, helpers] = useField<Array<ProductPicture>>('pictures');

    const storePicture = useCallback(
        (pictureToStore: Array<File>) => {
            updateProgress({
                name: 'pictures',
                isFilled: true,
            });
            helpers.setValue([...field.value, ...pictureToStore]);

            if (!meta.touched) {
                helpers.setTouched(true);
            }
        },
        [field.value, updateProgress, meta.touched],
    );

    const removePicture = useCallback(
        (picIndex: number) => {
            const image = field.value[picIndex];

            if ('id' in image) {
                dispatch(addProductImageToRemove(image.id));
            }

            const newPictureArray = [...field.value];
            newPictureArray.splice(picIndex, 1);

            if (!newPictureArray.length) {
                updateProgress({
                    name: 'pictures',
                    isFilled: false,
                });
            }

            helpers.setValue(newPictureArray);
        },
        [field.value, updateProgress],
    );

    // When selected tab changed values can be without 'pictures'
    if (!field.value) {
        return null;
    }

    const showError = !!(meta.touched && meta.error);

    return (
        <>
            <GeneralFormSC.SectionTitle>
                Pictures
                {showError && (
                    <EditProductSC.ErrorText marginLeft={12}>{meta.error}</EditProductSC.ErrorText>
                )}
            </GeneralFormSC.SectionTitle>

            <ProductPicturesFormSC.PictureGrid>
                {field.value.map((p, index) => {
                    const key = isSavedPicture(p) ? p.id : `picture_${index}`;
                    const src = isSavedPicture(p) ? p.src : URL.createObjectURL(p);

                    return (
                        <ProductPicturesFormSC.PictureWrapper key={key}>
                            <img src={src} alt="" />
                            <ProductPicturesFormSC.HoverContainer>
                                <ProductPicturesFormSC.HoverButtonContainer>
                                    <TransparentButton
                                        type="button"
                                        onClick={removePicture.bind(null, index)}
                                    >
                                        <IconSC name={IconUnicodes.trash} />
                                        Delete
                                    </TransparentButton>
                                </ProductPicturesFormSC.HoverButtonContainer>
                            </ProductPicturesFormSC.HoverContainer>
                        </ProductPicturesFormSC.PictureWrapper>
                    );
                })}
                <UploadPicture storePictures={storePicture} hasError={showError} />
            </ProductPicturesFormSC.PictureGrid>
        </>
    );
};
