import { useMemo } from 'react';
import { FormikErrors, FormikTouched, useFormikContext } from 'formik';

type UseFieldErrorsOptions<Values> = {
  // What the purpose of notAllowed field?
  notAllowed?: Array<string>;
  // Array of additional errors (basicaly api errors)
  additionalErrors?: {
    [key in keyof Values]?: Array<string> | undefined;
  } | null;
}

type ArrayOfErrors<T> = Array<{ [key in keyof T]: string }>;
type HookReturn<Values> = {
  [key in keyof Values]: Values[key] extends Array<(infer R)> ? ArrayOfErrors<R> : string;
}

/**
 * Logic to create an error object to pass to input.
 * Actual fields name should be same in all the required params: values, errors, touched and optional additionalErrors.
 */
export const useFieldErrors = <Values>(
  values: Values,
  errors: FormikErrors<Values>,
  touched: FormikTouched<Values>,
  options?: UseFieldErrorsOptions<Values>
): HookReturn<Values> => (
  useMemo<HookReturn<Values>>(() => {
    const keys = Object.getOwnPropertyNames(values) as Array<keyof Values>;

    return keys.reduce((res, key) => ({
      ...res,
      [key]: (touched[key] && errors[key]) || options?.additionalErrors?.[key]?.[0], // take first error from additionalErrors by key
    }), {} as HookReturn<Values>)
  }, [values, errors, touched, options])
);

export const useFormikFieldErrors = <Values>(options?: UseFieldErrorsOptions<Values>) => {
  const { values, errors, touched } = useFormikContext<Values>();

  return useFieldErrors<Values>(values, errors, touched, options);
};
