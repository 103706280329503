import { useSelector } from 'react-redux';

import { RootState } from 'redux/root-types';
import { CMSThemeId } from '../../store/types';
import { CMSThemesTitle } from '../../constants';

// Hook to retrieve active theme id and title.
// Active theme is saved to db theme which gonna be used in storefront.
export const useCMSActiveTheme = () => {
  const activeTheme = useSelector<RootState, CMSThemeId>(({ cms }) => cms.config.themes.activeTheme);

  return {
    id: activeTheme,
    title: CMSThemesTitle[activeTheme],
  };
};
