import styled from 'styled-components';

import { Color } from 'components/styles/constants.styles';

const Container = styled.div`
    margin: 8px 0;
`;

const Text = styled.span`
    color: ${Color.black};
`;

export const AuctionsSC = {
    Container,
    Text,
};
