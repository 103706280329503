import React, { FC, useCallback } from 'react';
import { useFormikContext } from 'formik';

import { CmsAcordionSection } from 'units/cms/components/cms-acordion-section/cmsAcordionSection.component';
import { FormikFormInput } from 'units/common/components/formik/input/form-input.component';
import { TrackProgressField } from 'units/common/hooks/useTrackProgress';
import { CMSHomePageFormikCustomizationSettings } from 'units/cms/store/types/customization/homepage';
import { useTranslation } from 'react-i18next';

type LinkProps = {
    index: number;
    updateProgress: (field: TrackProgressField) => void;
};

const FooterLink: FC<LinkProps> = ({ index, updateProgress }) => {
    const { t } = useTranslation();
    const handleURLAfterChange = useCallback(
        ({ target }: React.ChangeEvent<HTMLInputElement>) => {
            updateProgress({ name: `footerLinks.[${index}].url`, isFilled: !!target.value });
        },
        [updateProgress, index],
    );

    const handleNameAfterChange = useCallback(
        ({ target }: React.ChangeEvent<HTMLInputElement>) => {
            updateProgress({ name: `footerLinks.[${index}].name`, isFilled: !!target.value });
        },
        [updateProgress, index],
    );

    return (
        <CmsAcordionSection>
            <FormikFormInput
                name={`footer.footerLinks.[${index}].name`}
                label={`Link ${index + 1}`}
                placeholder="Register"
                paddingBottom="0"
                afterChange={handleNameAfterChange}
            />
            <FormikFormInput
                name={`footer.footerLinks.[${index}].url`}
                label={t('customization_footer_add_url')}
                placeholder="https://www.google.ch"
                paddingBottom="0"
                afterChange={handleURLAfterChange}
            />
        </CmsAcordionSection>
    );
};

type LinkListProps = Pick<LinkProps, 'updateProgress'>;

export const CMSFooterLinks: FC<LinkListProps> = ({ updateProgress }) => {
    const {
        values: { footer },
    } = useFormikContext<CMSHomePageFormikCustomizationSettings>();
    return (
        <>
            {footer.footerLinks.map((link, index) => (
                <FooterLink
                    key={`footer-link-${index + 1}`}
                    index={index}
                    updateProgress={updateProgress}
                />
            ))}
        </>
    );
};
