import React from 'react';
import clsx from 'clsx';

import styles from './ColorIcon.module.scss';

export type ColorIconName =
  | 'cards'
  | 'domains'
  | 'hand'
  | 'paper_plane'
  | 'settings'
  | 'users'
  | 'card_in_hand'
  | 'no_users'
  | 'no_products'
  | 'no_auctions'
  | 'logo'
  | 'customer'
  | 'launch'
  | 'payment'
  | 'lp_control_1'
  | 'lp_control_2'
  | 'lp_control_3'
  | 'lp_control_4'
  | 'lp_control_5'
  | 'lp_control_6'
  | 'lp_control_7'
  | 'lp_control_8'
  | 'lp_control_9'
  | 'check-red'
  | 'stripe'
  | 'visa'
  | 'mc'
  | 'amex'
  | 'apple'
  | 'android'
  | 'support'
  | 'achievement'
  | 'high_five'
  | 'shuttle'
  | 'success'
  | 'language'
  | 'instagram'
  | 'facebook'
  | 'snapchat'
  | 'twitter'
  | 'tiktok'
  | 'linkedin'
  | 'target'
  | 'tablet'
  | 'mailchimp'
  | 'clear'
  | 'empty_box'
  | 'dashboard';

type ColorIconProps = {
  name: ColorIconName;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};

export const ColorIcon: React.FC<ColorIconProps> = ({ name, className, onClick }) => (
    <img src={`/icons/colored/${name}.svg`} onClick={onClick} className={clsx(styles.root, className)} alt="icon" />
);