import styled from 'styled-components';

import { sizes } from 'components/styles/constants.styles';
import { TextStyle8 } from 'components/styles/text.styles';

const SectionTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -${sizes.sm_padding};

  h2 {
    margin: 0;
    padding: ${sizes.sm_padding} 0;
  }
`;

const ConditionContainer = styled.div`
  display: grid;
  grid-gap: 20px;
  margin: 20px 0;
  grid-template-columns: 1fr 1fr;
`;

const Subtitle = styled.div`
  ${TextStyle8};
`;

export const AuctionConditionSC = {
  ConditionContainer,
  SectionTitle,
  Subtitle,
};
