import React, { useCallback, FC } from 'react';
import { useFormikContext } from 'formik';

import {
  CMSCustomizationLanguageProgressContainer,
  CMSProgressBeforeUpdate,
  CMSCustomizationLanguageProgressContainerProps,
  CMSRequiredProgressUpdate,
} from '../../../progress-container/progress-container';
import { CmsAcordionSection } from 'units//cms/components/cms-acordion-section/cmsAcordionSection.component';
import { CMSHomePageFormikCustomizationSettings } from 'units//cms/store/types/customization/homepage';
import { CMSHomepageTextBlockAccordion } from './text-block-accordion.component';

type Props = Pick<CMSCustomizationLanguageProgressContainerProps, 'selectedLanguage' | 'onLanguageChange' | 'initialProgress'>;


export const CMSHomepageTextBlockAccordionContainer: FC<Props> = (props) => {
  const { values: { textBlock } } = useFormikContext<CMSHomePageFormikCustomizationSettings>();

  const handleBeforeUpdateProgress = useCallback((...[field, { updateProgressFields }]: Parameters<CMSProgressBeforeUpdate>) => {
    const updates: Array<CMSRequiredProgressUpdate> = [];

    switch(field.name) {
      case 'textBlock.isActive': {
        updates.push({
          key: 'description',
          operation: field.isFilled ? 'add' : 'delete',
        });
  
        if (textBlock.renderMode === 'picture') {
          updates.push({
            key: 'picture',
            operation: field.isFilled ? 'add' : 'delete',
          })
        } else {
          updates.push({
            key: 'title',
            operation: field.isFilled ? 'add' : 'delete',
          })
        }
  
        if (textBlock.textLink.isActive) {
          updates.push({
            key: 'textLink.target',
            operation: field.isFilled ? 'add' : 'delete',
          }, {
            key: 'textLink.name',
            operation: field.isFilled ? 'add' : 'delete',
          });
        }
  
        updateProgressFields(updates);
        return false;
      }
      
      case 'renderMode.picture': {
        updates.push({
          key: 'picture',
          operation: field.isFilled ? 'add' : 'delete',
        }, {
          key: 'title',
          operation: field.isFilled ? 'delete' : 'add',
        });

        updateProgressFields(updates);
        return false;
      }

      case 'renderMode.title': {
        updates.push({
          key: 'title',
          operation: field.isFilled ? 'add' : 'delete',
        }, {
          key: 'picture',
          operation: field.isFilled ? 'delete' : 'add',
        });
  
        updateProgressFields(updates);
        return false;
      }

      case 'textLink.isActive': {
        updates.push({
          key: 'textLink.target',
          operation: field.isFilled ? 'add' : 'delete',
        }, {
          key: 'textLink.name',
          operation: field.isFilled ? 'add' : 'delete',
        });

        updateProgressFields(updates);
        return true;
      }

      default: return true;
    }
  }, [textBlock]);

  return (
    <CmsAcordionSection lastSection>
      <CMSCustomizationLanguageProgressContainer
        beforeUpdate={handleBeforeUpdateProgress}
        {...props}
      >
        {({ updateCurrentProgress }) => (
          <CMSHomepageTextBlockAccordion updateProgress={updateCurrentProgress} />
        )}
      </CMSCustomizationLanguageProgressContainer>
    </CmsAcordionSection>
  )
}
