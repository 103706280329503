import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { RootState } from 'redux/root-types';
import { CMSTabId } from '../../types';
import { useCMSTabsNavigation } from '../useCMSTabsNavigation';
import { useCMSCustomizationPageNavigation } from '../useCMSCustomizationPageNavigation';
import { CMSUrls } from '../../urls';
import { useCMSActiveTheme } from './useCMSActiveTheme';
import { CMSCustomizationPage } from '../../store/types';
import { getConfigPageName } from '../../store/helpers';

// Hook to retrieve publish status of config part.
// For now it's only for theme settings.
export const useCMSConfigPublishedStatus = (tabId?: CMSTabId, customizationPage?: CMSCustomizationPage) => {
  const { selectedTab } = useCMSTabsNavigation();
  const { id: activeThemeId } = useCMSActiveTheme();
  const { selectedPage } = useCMSCustomizationPageNavigation();
  
  const observedTab = useMemo(
    () => tabId || selectedTab.id,
    [tabId, selectedTab],
  );

  const observedCustomizationPage = useMemo(
    () => customizationPage || selectedPage,
    [customizationPage, selectedPage],
  );

  const isPublished = useSelector<RootState, boolean>(({ cms: { config } }) => {
    switch(observedTab) {
      case CMSTabId.themeSettings: {
        return !!config.themeSettings[activeThemeId]?.isPublished;
      }
      case CMSTabId.customization: {
        const configPageName = observedCustomizationPage ? getConfigPageName(observedCustomizationPage) : '';

        if (!configPageName) {
          return false;
        }

        return !!config.customization[configPageName]?.isPublished;
      }
      default:
        return true;
    }
  });

  return isPublished;
};

// Hook to retrieve saved status
export const useCMSConfigSavedStatus = () => {
  const location = useLocation();

  const configSaveStatus = useSelector<RootState, boolean>(
    ({cms: { tempPayload }}) => tempPayload.isSaved
  );

  // we don't need config save status on themes page.
  return location.pathname.includes(CMSUrls.themes) ? true : configSaveStatus;
}
