import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'redux/root-types';
import { CMSCustomizationPage } from 'units/cms/store/types/index';
import { cmsCustomizationSetPage } from 'units/cms/store/action-creators';

type useCMSCustomizationPageNavType = () => {
  selectedPage: CMSCustomizationPage;
  setSelectedPage: (page: CMSCustomizationPage) => void;
  resetSelectedPage: () => void;
  meta: {
    isHomePage: boolean;
    isShopModalsPage: boolean;
    isProductDetailPage: boolean;
    isMissedAuctionsPage: boolean;
  };
}

export const useCMSCustomizationPageNavigation: useCMSCustomizationPageNavType = () => {
  const selectedPage = useSelector<RootState, CMSCustomizationPage>(({ cms }) => cms.tempPayload.selectedPage);
  const dispatch = useDispatch();

  const setSelectedPage = useCallback((page: CMSCustomizationPage) => {
    dispatch(cmsCustomizationSetPage(page));
  }, [dispatch]);

  const resetSelectedPage = useCallback(() => {
    dispatch(cmsCustomizationSetPage(CMSCustomizationPage.Home));
  }, [dispatch]);

  const meta = useMemo(() => ({
    isHomePage: selectedPage === CMSCustomizationPage.Home,
    isShopModalsPage: selectedPage === CMSCustomizationPage.ShopModals,
    isProductDetailPage: selectedPage === CMSCustomizationPage.ProductDetail,
    isMissedAuctionsPage: selectedPage === CMSCustomizationPage.MissedAuctions,
  }), [selectedPage]);

  return {
    meta,
    selectedPage,
    setSelectedPage,
    resetSelectedPage,
  }
};
