import React, { useCallback, FC } from 'react';

import {
  CMSCustomizationLanguageProgressContainer,
  CMSProgressBeforeUpdate,
  CMSCustomizationLanguageProgressContainerProps,
} from '../../../progress-container/progress-container';
import { CMSHomepageHeroAccordion } from './hero-accordion.component';
import { CmsAcordionSection } from 'units//cms/components/cms-acordion-section/cmsAcordionSection.component';

type Props = Pick<CMSCustomizationLanguageProgressContainerProps, 'selectedLanguage' | 'onLanguageChange' | 'initialProgress'>;

export const CMSHomepageHeroAccordionContainer: FC<Props> = (props) => {
  const handleBeforeUpdateProgress = useCallback((...[field, { updateProgressFields }]: Parameters<CMSProgressBeforeUpdate>) => {
    if (field.name === 'conversationSticker.isActive') {
      updateProgressFields([{
        key: 'conversationSticker.icon',
        operation: field.isFilled ? 'add' : 'delete',
      }]);

      return false;
    }

    return true;
  }, []);

  return (
    <CmsAcordionSection lastSection>
      <CMSCustomizationLanguageProgressContainer
        beforeUpdate={handleBeforeUpdateProgress}
        {...props}
      >
        {({ updateCurrentProgress }) => (
          <CMSHomepageHeroAccordion updateProgress={updateCurrentProgress} />
        )}
      </CMSCustomizationLanguageProgressContainer>
    </CmsAcordionSection>
  )
}
