/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect } from 'react';
import { useTable, usePagination, Column, useSortBy } from 'react-table';

import { TableSC } from './table.styles';
import { Pagination } from 'units/common/components/pagination/pagination.component';
import { TableSort } from './table-sort.component';
import clsx from 'clsx';
import styles from './table-sort.module.scss';

interface Props {
    columns: Array<Column>;
    data: Array<any>;
    pageIndex?: number;
    pageSize?: number;
    numOfPages?: number;
    havePadding?: boolean;
    onRowClick?: (data: any) => void;
    onPageChange?: (selectedItem: number) => void;
    onSort?: (data: any) => void;
}

export const Table: FC<Props> = ({
    columns,
    data,
    pageIndex = 0,
    pageSize = 10,
    onRowClick,
    onPageChange,
    onSort,
    numOfPages,
    havePadding = true,
}) => {
    const {
        getTableBodyProps,
        getTableProps,
        headerGroups,
        prepareRow,
        pageCount,
        gotoPage,
        page,
        state: { sortBy },
    } = useTable(
        {
            data,
            columns,
            manualSortBy: true,
            initialState: { pageIndex, pageSize, sortBy: [{ id: 'endDate', desc: true }] },
        },
        useSortBy,
        usePagination,
    );

    useEffect(() => {
        if (onSort) onSort(sortBy);
    }, [onSort, sortBy]);

    if (!data || !data.length) {
        return null;
    }

    const handlePageClick = (selectedItem: { selected: number }) => {
        onPageChange && onPageChange(selectedItem.selected);
        gotoPage(selectedItem.selected);
    };

    return (
        <>
            <TableSC.TableBox
                style={{
                    padding: !havePadding ? '0px' : '',
                    marginLeft: !havePadding ? '-7px' : '',
                }}
            >
                <TableSC.Table {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup: any) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column: any) => (
                                    <th
                                        className={clsx(
                                            `table-th-${column.id}`,
                                            column.isSorted && styles.activeCol,
                                        )}
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                    >
                                        {column.render('Header')}
                                        {onSort && column.render('sortBy') && (
                                            <TableSort column={column} />
                                        )}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row: any, i: number) => {
                            prepareRow(row);
                            return (
                                <tr
                                    {...row.getRowProps()}
                                    onClick={onRowClick?.bind(null, row.original)}
                                >
                                    {row.cells.map((cell: any) => {
                                        return (
                                            <td
                                                className={`table-td-${cell.column.id}`}
                                                {...cell.getCellProps()}
                                            >
                                                {cell.render('Cell')}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </TableSC.Table>
            </TableSC.TableBox>
            <Pagination
                pageSize={pageSize}
                pageCount={numOfPages || pageCount}
                // itemsCount={data.length}
                onPageChange={handlePageClick}
            />
        </>
    );
};
/* eslint-enable @typescript-eslint/no-explicit-any */
/* eslint-enable no-nested-ternary */
