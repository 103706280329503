import dayjs from 'dayjs';

import { ApiClient, apiPath } from 'config/api/api';
import { AuctionListResp, AuctionList, AuctionInfo } from '../types';
import { DateHelper } from 'shared/helpers';

const formatDateTime = (datetime: string | null) => {
    if (!datetime) {
        return '';
    }

    return dayjs(datetime).format('DD.MM.YYYY HH:mm');
};

const getDurationLabel = (startDate: string | null, endDate: string | null) => {
    const duration =
        startDate && endDate
            ? dayjs(endDate, 'YYYY-MM-DD').diff(dayjs(startDate, 'YYYY-MM-DD'), 'days')
            : '';
    let dayLabel = '';

    if (duration === 1) {
        dayLabel = 'day';
    } else if (duration === 0 || duration) {
        dayLabel = 'days';
    }

    return `${duration} ${dayLabel}`;
};

const prepareData = (data: AuctionListResp): AuctionList =>
    data.map(item => {
        return {
            id: item.id,
            auctionType: {
                value: item.type.value,
                name: item.type.display_name,
            },
            auctionName: item.auction_name,
            productName: item.product.name,
            status: {
                value: item.status.value,
                name: item.status.display_name,
            },
            endDate: formatDateTime(item.ends_at),
            launchDate: formatDateTime(item.starts_at),
            duration: getDurationLabel(
                DateHelper.getDateFromDate(item.starts_at || ''),
                DateHelper.getDateFromDate(item.ends_at || ''),
            ),
            isArchived: item.is_archived,
        };
    });

export const fetchAuctions = async () => {
    const api = ApiClient.getInstance();
    const { data } = await api.get(`${apiPath}/auctions/list/`);

    return prepareData(data);
};

export const fetchCurrentAuctions = async (
    page: number,
    pageSize?: number,
    filters?: string,
    sorting?: string,
): Promise<AuctionInfo> => {
    const api = ApiClient.getInstance();
    const { data } = await api.get(
        `${apiPath}/auctions/list/current/?page=${page}${pageSize ? `&page_size=${pageSize}` : ''}${
            filters ? `&status=${filters}` : ''
        }${sorting ? `&order_by=${sorting}` : ''}`,
    );

    return { ...data, results: prepareData(data.results) };
};

export const fetchArchivedAuctions = async (
    page: number,
    pageSize?: number,
    filters?: string,
    sorting?: string,
): Promise<AuctionInfo> => {
    const api = ApiClient.getInstance();
    const { data } = await api.get(
        `${apiPath}/auctions/list/archived/?page=${page}${
            pageSize ? `&page_size=${pageSize}` : ''
        }${filters ? `&status=${filters}` : ''}${sorting ? `&order_by=${sorting}` : ''}`,
    );

    return { ...data, results: prepareData(data.results) };
};

export const deleteAuction = async (id: string) => {
    const api = ApiClient.getInstance();

    await api.delete(`${apiPath}/auctions/delete/${id}/`);
};

export const archiveAuction = async (id: string) => {
    const api = ApiClient.getInstance();

    await api.patch(`${apiPath}/auctions/update/${id}/`, {
        is_archived: true,
    });
};
