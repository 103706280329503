import React, { FC, InputHTMLAttributes, TextareaHTMLAttributes } from 'react';
/* eslint-disable import/named */
import { FlattenSimpleInterpolation } from 'styled-components';
/* eslint-enable import/named */

import { LPFormElementSC } from './form-element.styles';

type BaseElementProps = {
    styles?:FlattenSimpleInterpolation;
}

const BaseElement:FC<BaseElementProps> = ({ children, styles }) => (
    <LPFormElementSC.Root styles={styles} >
        {children}
        <LPFormElementSC.BG />
    </LPFormElementSC.Root>
)

type ElementProps = {
    name?:string;
    placeholder?:string;
}

type TextareaProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
    rows?:number;
}

const Textarea:FC<BaseElementProps & ElementProps & TextareaProps> = ({ rows, ...props }) => (
    <BaseElement>
        <textarea rows={rows} {...props} />
    </BaseElement>
)

type InputProps = InputHTMLAttributes<HTMLInputElement> & {
    type?:'text' | 'email';
}

const Input:FC<BaseElementProps & ElementProps & InputProps> = ({ styles, type = 'text', ...inputProps }) => (
    <BaseElement styles={styles}>
        <input type={type} {...inputProps} />
    </BaseElement>
);

export const LPFormElement = {
    Input,
    Textarea
}
