import { createGlobalStyle } from 'styled-components';

import { sizes, Color } from './constants.styles';
import { fonts } from './helpers.styles';

export const GlobalStyle = createGlobalStyle`
  *, h1, :after, :before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  html {
    font-size: ${sizes.base_font_size};
    font-family: ${fonts.regular};
    background-color: ${Color.pale_grey};
  }
`;
