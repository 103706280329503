import styled from 'styled-components';

import { UserDropdownText } from 'components/styles/text.styles';

const RadioWrapper = styled.div`
  input:checked ~ label {
    ${UserDropdownText};
    font-size: 14px;
    font-weight: normal;
  }

  label {
    font-size: 14px;
  }

  .checkmark {
    border: 2px solid #d0ddf2;

    &:after {
      top: 2px;
      left: 2px;
    }
  }
`;

export const CmsTypographySC = {
  RadioWrapper,
};
