import React, { FC, useCallback, useState, useMemo } from 'react';
import { useFormikContext, FormikHelpers, FormikValues } from 'formik';

import { CompanyLanguages } from 'units/app/redux/types';
import { CircleProgressGroup } from 'units/products/components/circle-progress/circle-progress-group/circle-progress-group.component';
import {
    useTrackProgress,
    TrackListItem,
    UpdateProgress,
    TrackProgressField,
} from 'units/common/hooks/useTrackProgress';

type Helpers = Pick<FormikHelpers<any>, 'resetForm'>;

export type CMSCustomizationLanguageChangeHandler = (
    language: CompanyLanguages,
    values: any,
    { resetForm }: Helpers,
) => void;

export type CMSRequiredProgressUpdate = {
    key: string;
    operation: 'add' | 'delete';
};

type ChildrenFunc = ({
    updateProgress,
    updateCurrentProgress,
}: {
    updateProgress: UpdateProgress;
    updateCurrentProgress: (field: TrackProgressField) => void;
}) => React.ReactNode;

export type CMSProgressBeforeUpdate = (
    field: TrackProgressField,
    {
        updateProgressFields,
    }: {
        updateProgressFields: (updates: Array<CMSRequiredProgressUpdate>) => void;
    },
) => boolean;

export type CMSCustomizationLanguageProgressContainerProps = {
    selectedLanguage: CompanyLanguages;
    onLanguageChange: CMSCustomizationLanguageChangeHandler;
    initialProgress: {
        progressFields: Array<string>;
        trackList: Array<TrackListItem>;
        commonFields: Array<string>;
    };
    beforeUpdate?: CMSProgressBeforeUpdate;
    hideProgress?: boolean;
    children: ChildrenFunc;
};

export const CMSCustomizationLanguageProgressContainer: FC<CMSCustomizationLanguageProgressContainerProps> = ({
    children,
    initialProgress,
    hideProgress = false,
    selectedLanguage,
    onLanguageChange,
    beforeUpdate,
}) => {
    const { values, resetForm } = useFormikContext<FormikValues>();
    const [progressFields, setProgressFields] = useState(initialProgress.progressFields);
    const [progressList, updateProgress] = useTrackProgress({
        trackList: initialProgress.trackList,
        progressFields: progressFields,
        commonFields: initialProgress.commonFields || [],
    });

    const handleUpdateProgressFields = useCallback(
        (updates: Array<{ key: string; operation: 'delete' | 'add' }>) => {
            setProgressFields(fields => {
                const fieldsToAdd = updates.reduce(
                    (res, u) =>
                        u.operation === 'add' && !fields.includes(u.key) ? [...res, u.key] : res,
                    [] as Array<string>,
                );

                const fieldsToDelete = updates.reduce(
                    (res, u) =>
                        u.operation === 'delete' && fields.includes(u.key) ? [...res, u.key] : res,
                    [] as Array<string>,
                );

                return [...fields.filter(f => !fieldsToDelete.includes(f)), ...fieldsToAdd];
            });
        },
        [setProgressFields],
    );

    const handleUpdateProgress = useCallback(
        (...[key, field]: Parameters<UpdateProgress>) => {
            if (!beforeUpdate) {
                updateProgress(key, field);
            } else {
                const isAllowed = beforeUpdate(field, {
                    updateProgressFields: handleUpdateProgressFields,
                });
                isAllowed && updateProgress(key, field);
            }
        },
        [beforeUpdate, setProgressFields, updateProgress],
    );

    // Put selected language in scope automaticaly.
    const handleUpdateCurrentProgress = useCallback(
        (field: TrackProgressField) => handleUpdateProgress(selectedLanguage, field),
        [selectedLanguage, handleUpdateProgress],
    );

    const languageProgress = useMemo(() => {
        return progressList.map(progress => ({
            id: progress.key,
            value: progress.value,
            text: progress.key,
        }));
    }, [progressList]);

    const handleLanguageChange = useCallback(
        (languageId: string) => {
            onLanguageChange(languageId as CompanyLanguages, values, { resetForm });
        },
        [values, onLanguageChange],
    );

    return (
        <>
            {children({
                updateProgress: handleUpdateProgress,
                updateCurrentProgress: handleUpdateCurrentProgress,
            })}
            {!hideProgress && (
                <CircleProgressGroup
                    circleMargin="10px"
                    onClick={handleLanguageChange}
                    selectedId={selectedLanguage}
                    progressList={languageProgress}
                />
            )}
        </>
    );
};
