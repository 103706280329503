import styled from 'styled-components';

const SelectWrap = styled.div`
  width: calc(33.3% - 10px - 10px);
  margin-left: 10px;
  margin-right: 10px;
`

export const BillingSC = {
    SelectWrap
}