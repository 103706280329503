import * as yup from 'yup';

import { Discount, Status } from './constants';
import { RegularAuctionValues, BuyNowAuctionValues } from './types';
import { isTimeValid, isDaysEqual } from './helpers';

function validateProductQuantity(this: any, value: number | null | undefined) {
    const { quantity } = this.options.context as RegularAuctionValues;

    if (value && quantity >= value) {
        return true;
    }
    return this.createError({
        path: this.path,
        message: `Not enough products in stock. Maximum possible products: ${quantity}`,
    });
}

function validateCurrAndPrevCondition(
    this: any,
    value: number | null | undefined,
    fieldName: string,
) {
    const { conditions } = this.options.context as RegularAuctionValues;
    const currIndex = Number(this.path.match(/\d+/)?.[0]);
    const prevCondition = conditions[currIndex - 1];

    if (value && currIndex > 0) {
        if (!Number(prevCondition[fieldName])) {
            return this.createError({
                path: this.path,
                message: 'Previous condition is empty or invalid',
            });
        } else if (Number(prevCondition[fieldName]) >= Number(value)) {
            return this.createError({
                path: this.path,
                message: 'Should be greater than previous value',
            });
        }
    }

    return true;
}

const basicValidationSchema = yup.object({
    productId: yup.string().required('Product is required'),
    auctionName: yup.string().trim().required('Auction name is required'),
    startDate: yup.date().required('Start date is required'),
    startTime: yup
        .date()
        .required('Start time is required')
        .test(
            'check startTime and the past',
            'Invalid time: value can not be in the past',
            function (value: Date | null | undefined) {
                const { startDate, auctionStatus } = this.options.context as RegularAuctionValues;
                const today = new Date();
                if (auctionStatus === Status.active) {
                    return true;
                }
                if (isDaysEqual(today, startDate) && !isTimeValid(value, today, true)) {
                    return false;
                }
                return true;
            },
        ),
    endDate: yup.date().required('End date is required'),
    endTime: yup
        .date()
        .required('End time is required')
        .test(
            'check startTime and endTime',
            'Invalid time: value can not be equal or before the start time',
            function (value: Date | null | undefined) {
                const { startDate, startTime, endDate } = this.options
                    .context as RegularAuctionValues;
                if (isDaysEqual(startDate, endDate) && !isTimeValid(value, startTime)) {
                    return false;
                }
                return true;
            },
        )
        .test(
            'check endTime if auction is active',
            'End time of an active auction can not be reduced',
            function (value: Date | null | undefined) {
                const values = this.options.context as RegularAuctionValues;
                if (
                    values.auctionStatus === Status.active &&
                    isDaysEqual(values.endDateCopy, values.endDate) &&
                    !isTimeValid(value, values.endTimeCopy, true)
                ) {
                    return false;
                }
                return true;
            },
        ),
});

export const buyNowValidationSchema = basicValidationSchema.shape({
    uvp: yup.number(),
    discountType: yup.string(),
    discount: yup
        .number()
        .integer()
        .required('Discount is required')
        .nullable()
        .when('discountType', {
            is: val => val === Discount.currency,
            then: yup.number().min(1, 'Discount must be greater than 0'),
            otherwise: yup.number().max(100, "Discount value can't be greater than 100%"),
        })
        .test('compare discount with uvp', 'Discount value can\'t be greater than "UVP"', function (
            val: number | null | undefined,
        ) {
            const { uvp, discountType } = this.options.context as BuyNowAuctionValues;
            if (
                discountType === Discount.persentage ||
                (discountType === Discount.currency && Number(val) <= Number(uvp))
            ) {
                return true;
            }
            return this.createError({ path: this.path });
        }),
});

export const auctionValidationSchema = basicValidationSchema.shape({
    minOffer: yup
        .number()
        .required('Minimum offer is required')
        .nullable()
        .test(
            'Minimum offer max value',
            "Minimum offer can't be equal or more than UVP.",
            function (val: number | null | undefined) {
                if (this.parent.uvp === undefined) return true;
                return val ? val < this.parent.uvp : false;
            },
        ),
    minProdPieces: yup
        .number()
        .required('Minimum product pieces field is required')
        .min(1, 'Should be greater than 0')
        .integer('Should be an integer')
        .nullable()
        .test('compare value and product quantity', '', validateProductQuantity),
    offerInterval: yup
        .number()
        .required('Offer interval is required')
        .min(5, 'Offer interval should be greater than 5')
        .integer('Offer interval should be an integer')
        .nullable(),
    conditions: yup.array().of(
        yup.object().shape({
            value: yup
                .number()
                .integer('Should be an integer')
                .nullable()
                .test(
                    'compare value with minOffer',
                    'Should be greater than "Minimum offer"',
                    function (val: number | null | undefined) {
                        const { minOffer } = this.options.context as RegularAuctionValues;
                        if (val && minOffer && val > minOffer) {
                            return true;
                        }
                        return this.createError({ path: this.path });
                    },
                )
                .test('compare current value with the previous one', '', function (
                    value: number | null | undefined,
                ) {
                    return validateCurrAndPrevCondition.call(this, value, 'value');
                }),
            place: yup
                .number()
                .integer('Place value should be an integer')
                .nullable()
                .test(
                    'compare "place" with "Minimum product pieces"',
                    'Should be greater than "Minimum product pieces"',
                    function (place: number | null | undefined) {
                        const { minProdPieces } = this.options.context as RegularAuctionValues;
                        if (place && minProdPieces && place > Number(minProdPieces)) {
                            return true;
                        }
                        return this.createError({ path: this.path });
                    },
                )
                .test('compare current value with the previous one', '', function (
                    value: number | null | undefined,
                ) {
                    return validateCurrAndPrevCondition.call(this, value, 'place');
                })
                .test('compare value and product quantity', '', validateProductQuantity),
        }),
    ),
});
