import styled from 'styled-components';

import { Color } from 'components/styles/constants.styles';
import { PrimaryButtonStyle } from 'components/styles/button.styles';

export const TransparentButtonSC = styled('button')`
    ${PrimaryButtonStyle};
    background-color: ${Color.transparent};
    color: ${Color.lightish_blue};
    text-transform: none;
    min-width: 135px;
    line-height: 120%;

    &:hover {
        background-color: ${Color.transparent_hover};
    }

    &:disabled {
        background-color: unset;
    }
`;
