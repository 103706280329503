import styled from 'styled-components';

import { TextStyle5 } from 'components/styles/text.styles';
import { Color } from 'components/styles/constants.styles';
import { PrimaryButtonStyle } from 'components/styles/button.styles';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const IconWrapper = styled.div`
  margin-top: 64px;
  margin-bottom: 32px;
`;

const Subtitle = styled.p`
  margin-bottom: 24px;

  ${TextStyle5}
  line-height: 24px;
  color: ${Color.black};
`;

const Button = styled.button`
  ${PrimaryButtonStyle}
  text-decoration: none;
  padding: 16px 23px;
`;

export const NotFoundSC = {
  Container,
  IconWrapper,
  Subtitle,
  Button,
};
