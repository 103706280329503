import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import { BillingForm } from './form/billing-form.component';
import { LayoutSC } from 'units/common/components/layout/layout-components.styles';
import { billingValidationSchema } from './validation';
import { BillingData } from './types';
import { SettingsUrls } from '../../urls';
import { BackButton } from '../../../common/components/back-button/back-button.component';
// import { PastUsage } from './components/past-usage/past-usage.component';
import { BillingService } from './api';
import { NotificationContext } from 'shared/providers';
import { PastUsage } from './components/past-usage/past-usage.component';
import { Button, Icon, Modal, Typography } from 'shared/components/ui';
import SadEmoji from '../../../../assets/images/emoji-sad.svg';
import CheckList from '../../../../assets/images/check-list.svg';
import JP from '../../../../assets/images/jp.svg';
import HandIcon from '../../../../assets/images/hand.svg';
import PencilIcon from '../../../../assets/images/pencil.svg';

import styles from './billing.module.scss';
import { ConfirmModal } from 'shared/components/modals';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/root-types';
import { UserState } from 'units/user/redux/types';
import { signOutThunk } from 'units/user/redux/thunks';
import { useAsyncDispatch } from 'helpers/hooks';

const emptyInitialValues: BillingData = {
    street: '',
    street_number: '',
    country: '',
    city: '',
    zip_code: '',
};

export const BillingPage = () => {
    const { t } = useTranslation();
    const [billing, setBilling] = useState(emptyInitialValues);
    const [invoices, setInvoices] = useState([]);
    const { showNotification } = useContext(NotificationContext);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [sorryModalOpen, setSorryModalOpen] = useState(false);
    const [notAllowedModal, setNotAllowedModalOpen] = useState(false);
    const [planModal, setPlanModal] = useState(false);
    const [contactModal, setContactModalOpen] = useState(false);

    const { user } = useSelector<RootState, UserState>(state => state.user);
    const { dispatch } = useAsyncDispatch();
    const [feedback, setFeedback] = useState('');

    useEffect(() => {
        BillingService.getBilling().then(resp => {
            setBilling(resp.data);
        });
        BillingService.getInvoices().then(resp => {
            setInvoices(resp.data);
        });
    }, []);

    const getInitialValues = (): BillingData => ({
        street: billing?.street || emptyInitialValues.street,
        street_number: billing?.street_number || emptyInitialValues.street_number,
        country: billing?.country || emptyInitialValues.country,
        city: billing?.city || emptyInitialValues.city,
        zip_code: billing?.zip_code || emptyInitialValues.zip_code,
    });

    const submitForm = (data: any) => {
        BillingService.updateBilling(data)
            .then(resp => {
                showNotification('success', 'Your data has been successfully updated');
            })
            .catch(err => {
                showNotification('error', 'Oops, something went wrong. Please try again.');
                console.log(err);
            });
    };

    const signOut = useCallback(() => {
        dispatch(signOutThunk(showNotification));
        showNotification('success', t('deleted_account_message'));
    }, [dispatch]);

    const isDeletable = async () => {
        const response = await BillingService.isDeletable();
        if (response.data.is_account_deletable) {
            setConfirmModalOpen(true);
        } else {
            setNotAllowedModalOpen(true);
        }
    };

    const deleteAccount = async () => {
        await BillingService.deleteAccount(feedback);
        setSorryModalOpen(false);
        signOut();
    };

    const DemoLink =
        'https://app.prosperworks.com/public/meeting-scheduler/mindnow%20AG/benedict/293702:c044038e-106c-4860-ac3a-244965018ac9';

    const navigateToDemoLink = useCallback(() => {
        const win = window.open(DemoLink, '_blank');
        win?.focus();
    }, []);

    return (
        <>
            <Helmet>
                <title>{t('billing_page_title')}</title>
            </Helmet>
            <BackButton destinationText={'Settings'} to={SettingsUrls.index} />
            <LayoutSC.PageTitleContainer>
                <h1>{t('billing_page_title')}</h1>
                <Button
                    onClick={isDeletable.bind(null)}
                    type="transparent"
                    className={styles['cancel-button']}
                >
                    {t('billing_page_cancel_account_button')}
                </Button>
            </LayoutSC.PageTitleContainer>
            <Formik
                enableReinitialize={true}
                initialValues={getInitialValues()}
                onSubmit={submitForm}
                validationSchema={billingValidationSchema}
            >
                <BillingForm />
            </Formik>
            <LayoutSC.PageTitleContainer>
                <h2>Past usage</h2>
            </LayoutSC.PageTitleContainer>
            <PastUsage invoices={invoices} />
            <ConfirmModal
                isOpen={confirmModalOpen}
                title={t('billing_page_cancel_account_title')}
                text={t('billing_page_cancel_account_text')}
                cancelButtonText={t('billing_page_cancel_account_cancel')}
                confirmButtonText={t('billing_page_cancel_account_confirm')}
                onCancel={() => {
                    setConfirmModalOpen(false);
                    setSorryModalOpen(true);
                }}
                onClose={setConfirmModalOpen.bind(null, false)}
                onConfirm={setConfirmModalOpen.bind(null, false)}
            />
            <ConfirmModal
                isOpen={sorryModalOpen}
                title={`${t('billing_page_sorry_modal_title_hey')} ${user?.firstName}, ${t(
                    'billing_page_sorry_modal_title',
                )}`}
                icon={SadEmoji}
                text={t('billing_page_sorry_modal_text')}
                confirmButtonText={t('billing_page_sorry_modal_confirm')}
                onCancel={() => {
                    setSorryModalOpen(false);
                    setPlanModal(true);
                }}
                onClose={setSorryModalOpen.bind(null, false)}
                onConfirm={deleteAccount.bind(null)}
            >
                <div className={styles['comment-section']}>
                    <Typography.Text className={styles.label}>
                        {t('billing_page_sorry_modal_question')}
                    </Typography.Text>
                    <textarea
                        value={feedback}
                        className={styles.textarea}
                        placeholder={t('billing_page_sorry_modal_let_us_know')}
                        onChange={e => setFeedback(e.target.value)}
                    ></textarea>
                </div>
            </ConfirmModal>
            <ConfirmModal
                isOpen={notAllowedModal}
                title={t('billing_page_not_allowed_title')}
                text={t('billing_page_not_allowed_text')}
                cancelButtonText={t('billing_page_cancel_account_cancel')}
                confirmButtonText={t('billing_page_not_allowed_button')}
                onClose={setNotAllowedModalOpen.bind(null, false)}
                onConfirm={setNotAllowedModalOpen.bind(null, false)}
            />
            <Modal
                className={styles['plan-modal']}
                isOpen={planModal}
                maxWidth={620}
                onClose={() => {
                    setPlanModal(false);
                }}
            >
                <div className={styles.title}>{t('billing_page_current_plan_title')}</div>
                <div className={styles.subtitle}>{t('billing_page_current_plan_subtitle')}</div>
                <img className={styles.image} src={CheckList} alt="checklist icon" />
                <div className={styles.items}>
                    <div className={styles.item}>
                        <span className={styles.check}>
                            <Icon name="checkbox" />
                        </span>
                        {t('billing_page_current_plan_auction')}
                    </div>
                    <div className={styles.item}>
                        <span className={styles.check}>
                            <Icon name="checkbox" />
                        </span>
                        {t('billing_page_current_plan_dashboard')}
                    </div>
                    <div className={styles.item}>
                        <span className={styles.check}>
                            <Icon name="checkbox" />
                        </span>
                        {t('billing_page_current_plan_templates')}
                    </div>
                    <div className={styles.item}>
                        <span className={styles.check}>
                            <Icon name="checkbox" />
                        </span>
                        {t('billing_page_current_plan_support')}
                    </div>
                </div>
                <Button
                    className={styles.button}
                    onClick={() => {
                        setPlanModal(false);
                        setContactModalOpen(true);
                    }}
                >
                    {t('billing_page_current_plan_button')}
                </Button>
            </Modal>
            <Modal
                className={styles['plan-modal']}
                isOpen={contactModal}
                maxWidth={620}
                onClose={setContactModalOpen.bind(null, false)}
            >
                <div className={styles.title}>{t('billing_page_contact_title')}</div>
                <img className={styles.jp} src={JP} alt="checklist icon" />
                <div className={styles.buttons}>
                    <Button
                        className={styles['contact-button']}
                        onClick={() => window.open(`tel:+41 43 508 98 43`)}
                    >
                        <img src={HandIcon} alt="" />
                        <span>+41 43 508 98 43</span>
                    </Button>
                    <Button
                        className={styles['contact-button']}
                        onClick={() => window.open(`mailto:jean-paul@mindnow.io`)}
                    >
                        <img src={PencilIcon} alt="" />
                        jean-paul@mindnow.io
                    </Button>
                    <Button
                        onClick={navigateToDemoLink.bind(null)}
                        className={styles['schedule-button']}
                    >
                        {t('billing_page_contact_schedule_call')}
                    </Button>
                </div>
            </Modal>
        </>
    );
};
